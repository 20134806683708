import React from 'react';
import { FormQuestionProps } from 'src/interfaces/IQuestion';

import { QuestionWrapper } from 'src/questionsForm/components/QuestionWrapper/QuestionWrapper';
import MoneyInput from './MoneyInput/MoneyInput';

const MoneyInputQuestion: React.FC<FormQuestionProps> = props => (
  <QuestionWrapper {...props} inputComponent={MoneyInput} />
);

export default MoneyInputQuestion;
