import { css } from '@emotion/react';

import { mq } from 'src/theme/variables';
import { text, text12 } from 'src/theme/typography';

export const disclosuresPageTitleCSS = css`
  margin-bottom: 16px;
  text-align: center;

  ${mq[1]} {
    margin-bottom: 20;
  }
`;

export const disclosuresPageSubtitleCSS = css`
  font-weight: bold;
  margin-bottom: 32px;

  ${mq[1]} {
    margin-bottom: 40px;
  }
`;

export const disclosuresPageTextCSS = css`
  ${text12};

  ${mq[1]} {
    ${text};

    div {
      div {
        ${text};
      }
    }
  }
`;

export const disclosuresPageActionsCSS = css`
  margin-top: 20px;
  text-align: center;

  ${mq[0]} {
    margin-top: 32px;
  }

  ${mq[1]} {
    margin-top: 40px;
  }
`;
