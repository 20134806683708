import { css, SerializedStyles } from '@emotion/react';
import { mq, sizes } from 'src/theme/variables';

const fullWidthContainer = css`
  margin-left: -${sizes.paddingBodyHorizontalMobileDense}px;
  margin-right: -${sizes.paddingBodyHorizontalMobileDense}px;
  padding-left: ${sizes.paddingBodyHorizontalMobileDense}px;
  padding-right: ${sizes.paddingBodyHorizontalMobileDense}px;
  ${mq[0]} {
    margin-left: -${sizes.paddingBodyHorizontalTablet}px;
    margin-right: -${sizes.paddingBodyHorizontalTablet}px;
    padding-left: ${sizes.paddingBodyHorizontalTablet}px;
    padding-right: ${sizes.paddingBodyHorizontalTablet}px;
  }
`;

export const container = (fullWidth?: boolean): SerializedStyles => css`
  margin-left: 0;
  margin-right: 0;
  ${fullWidth && fullWidthContainer};
`;

export const summary = css`
  display: flex;
  align-items: center;
  flex-direction: column;
  ${mq[0]} {
    flex-direction: row;
  }
`;

export const logo = css`
  margin-top: 8px;
  ${mq[0]} {
    margin-top: 0;
  }
`;

export const description = css`
  flex-grow: 1;
  text-align: left;
  margin: 24px 0;
  ${mq[0]} {
    margin: 0 16px;
  }
`;

export const phoneNumber = css`
  white-space: nowrap;
`;
