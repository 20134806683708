import { css } from '@emotion/react';

export const detailsHeading = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const detailsHeadingItem = css`
  &:first-of-type {
    margin-bottom: 8px;
  }
`;
