import { UseBaseMutationResult, useMutation, useQueryClient } from 'react-query';
import { ResponseError } from 'src/interfaces/IResponse';
import { QUERY_CACHE_KEYS } from 'src/constants/queryCacheKeys';
import api from 'src/api/api';
import { API_URLS } from 'src/constants/api';
import { getApiUrl } from 'src/utils/api.helpers';
import { Questions } from 'src/interfaces/questions.types';

const useRefetchQuestion = (gid: string): UseBaseMutationResult<Questions, ResponseError, any> => {
  const client = useQueryClient();

  return useMutation((): Promise<Questions> => api.put(getApiUrl(API_URLS.RESET_QUESTION_STEP, gid)), {
    onSuccess: async (data: Questions) => {
      await client.refetchQueries([QUERY_CACHE_KEYS.SESSION]);
      client.setQueryData([QUERY_CACHE_KEYS.QUESTIONS, gid], data);
    }
  });
};

export default useRefetchQuestion;
