/** @jsxImportSource @emotion/react */
import React, { Fragment } from 'react';
import { SavingsQuote } from 'src/interfaces/IQuotes';
import { ICurrentPolicy } from 'src/interfaces/IQuotes';
import smallRectangle from 'src/assets/svg/small-rectangle.svg';
import {
  carrierDesktopPremiumsCSS,
  carrierLogoCSS,
  carrierMobilePremiumsCSS,
  carrierOldPremiumCSS,
  carrierRowCSS,
  carrierSavingCSS,
  carrierSavingMsgCSS,
  carrierSectionCSS,
  carrierUpdatedPremiumCSS,
  dottedLineCSS,
  oldPremiumValueCSS,
  rectangleIconCSS,
  saveTextCSS,
  preScoreSavingCSS,
  savingsCSS,
  descriptionCSS,
  scrollLinkCSS
} from './SavingsCarrier.style';
import Format from 'src/utils/format';
import { getPercentRangeValue } from 'src/utils/percentageCalculation';
import { SerializedStyles } from '@emotion/react';
import { scrollToNode } from 'src/utils/scroll';
import { FS_SENSITIVE_DATA_CLASS } from 'src/constants/fullStory';

interface Props {
  bestQuote: SavingsQuote;
  currentPolicy: ICurrentPolicy;
  showQuoteRange?: boolean;
  customCSS?: SerializedStyles;
  shouldRenderBundleDiscount?: boolean;
  customRange?: { below: number; above: number };
  isToggleOn?: boolean;
  isPreScoreVariation?: boolean;
  estimatedSavings?: number | null;
  predictedPremium?: number | null;
}

const SavingsCarrier: React.FC<Props> = ({
  bestQuote: { carrier },
  bestQuote,
  currentPolicy,
  showQuoteRange = false,
  customRange,
  customCSS,
  shouldRenderBundleDiscount,
  isToggleOn = false,
  isPreScoreVariation,
  estimatedSavings,
  predictedPremium
}) => {
  const carrierLogo = carrier.logo_url;
  const carrierName = carrier.name;
  const premium = bestQuote.premium.value;
  const savingsValue = bestQuote.saving_value;
  const [below, above] = customRange ? [customRange.below, customRange.above] : [30, 15];
  const bundledAnnualPremium = Math.round(premium - premium * 0.15);
  const bundle15PerPremium = currentPolicy.premium! - bundledAnnualPremium;
  const premiumToRender = shouldRenderBundleDiscount && isToggleOn ? bundledAnnualPremium : premium;

  const onLinkClick = () => {
    scrollToNode('premium-chart');
  };

  return (
    <div css={[carrierSectionCSS(isPreScoreVariation), customCSS]}>
      <div css={carrierRowCSS(isPreScoreVariation)}>
        <div>
          <img css={carrierLogoCSS} src={carrierLogo} alt={carrierName} />

          <div css={carrierDesktopPremiumsCSS(isPreScoreVariation)}>
            {isPreScoreVariation ? (
              <Fragment>
                <div css={carrierOldPremiumCSS}>Est. renewal premium: ${predictedPremium}</div>
                <div css={carrierOldPremiumCSS}>Estimated quote: ${premiumToRender}</div>
              </Fragment>
            ) : (
              <Fragment>
                <div css={carrierUpdatedPremiumCSS}>
                  <img css={rectangleIconCSS} src={smallRectangle} width="6" alt="small-rectangle" />
                  <span>${premiumToRender} Updated Premium</span>
                </div>
                <div css={carrierOldPremiumCSS} className={FS_SENSITIVE_DATA_CLASS.MASK}>
                  ${currentPolicy.premium} Your Current Premium
                </div>
              </Fragment>
            )}
          </div>
        </div>

        <div css={carrierSavingMsgCSS}>
          {isPreScoreVariation ? (
            <div>
              <div css={preScoreSavingCSS}>
                <span css={savingsCSS}>${estimatedSavings}</span>/yr
              </div>
              <b css={descriptionCSS}> Your estimated savings at Renewal</b>
              <div onClick={onLinkClick} css={scrollLinkCSS} role="presentation">
                See how we estimated it.
              </div>
            </div>
          ) : (
            <Fragment>
              <div css={saveTextCSS}>Save</div>
              <div css={carrierSavingCSS}>
                {showQuoteRange && savingsValue > 0
                  ? getPercentRangeValue(savingsValue, above, below)
                  : shouldRenderBundleDiscount
                    ? Format.money(bundle15PerPremium, 0, '$')
                    : Format.money(savingsValue, 0, '$')}
              </div>
              <div css={saveTextCSS}>Per Year</div>
            </Fragment>
          )}
        </div>
      </div>

      {!isPreScoreVariation && (
        <div css={carrierMobilePremiumsCSS}>
          <div css={carrierUpdatedPremiumCSS}>
            <span>Updated Premium</span>
            <span css={dottedLineCSS} />
            <div>
              <img css={rectangleIconCSS} src={smallRectangle} width="6" alt="small-rectangle" />
              <span>${premiumToRender}</span>
            </div>
          </div>
          <div css={carrierOldPremiumCSS}>
            <span>Your Current Premium</span>
            <span css={dottedLineCSS} />
            <span css={oldPremiumValueCSS} className={FS_SENSITIVE_DATA_CLASS.MASK}>
              ${currentPolicy.premium}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default SavingsCarrier;
