/** @jsxImportSource @emotion/react */
import { FC } from 'react';
import Dialog from 'src/kit/Dialog/Dialog';
import { BundleCoveragesModalProps } from './BundleCoveragesModal.types';
import { getHomeQuoteCardViewCoverages } from 'src/utils/quotesPage';
import Button from 'src/kit/Button/Button';
import { buttonWrapperCSS, coverageCSS, coveragesContainerCSS } from './BundleCoveragesModal.style';
import QuoteCoverage from 'src/components/Quotes/QuoteCoverages/QuoteCoverage/QuoteCoverage';
import { ButtonSize } from 'src/kit/Button/types';

const BundleCoveragesModal: FC<BundleCoveragesModalProps> = ({ isOpen, coverages, onClose }) => (
  <Dialog title="Policy Coverages" isOpen={isOpen} onRequestClose={onClose} shouldCloseOnOverlayClick shouldCloseOnEsc>
    <div css={coveragesContainerCSS}>
      {getHomeQuoteCardViewCoverages(coverages, true).map(c => (
        <QuoteCoverage
          key={c.title}
          customCSS={coverageCSS}
          title={c.title}
          type={c.type}
          value={c.value}
          description={c.description}
          shouldRenderDescription
        />
      ))}
    </div>
    <div css={buttonWrapperCSS}>
      <Button size={ButtonSize.Large} onClick={onClose}>
        Close
      </Button>
    </div>
  </Dialog>
);

export default BundleCoveragesModal;
