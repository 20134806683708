/** @jsxImportSource @emotion/react */
import React, { useState, Fragment } from 'react';
import Button from 'src/kit/Button/Button';
import Popover from '@mui/material/Popover';
import CloseIcon from 'src/assets/svg/close.svg?react';
import {
  wrapperCSS,
  headerWrapperCSS,
  headerTextCSS,
  buttonCSS,
  iconCSS,
  popoverCSS,
  liveHelpButtonCSS,
  anchorCSS,
  contactLinkCSS,
  anchorHeaderCSS,
  iconHoverCSS
} from './LiveHelpComponents.style';
import CalendarIcon from 'src/assets/svg/calendar-icon.svg?react';
import HandsetIcon from 'src/assets/svg/handset.svg';
import ColoredHandset from 'src/assets/svg/phone.svg';
import AgentIcon from 'src/assets/svg/user-with-chat.svg?react';
import useResponsive from 'src/hooks/useResponsive';
import Anchor from 'src/kit/Anchor/Anchor';
import { useCustomNavigate } from 'src/hooks/useCustomNavigate';
import { useParams, useLocation } from 'react-router-dom';
import { DefaultLocation, Flows } from 'src/interfaces/IPage';
import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';
import { PhoneButtonClickProps, LiveHelpComponentProps } from './LiveHelpComponents.types';
import { SavingsContactLocations } from 'src/components/Savings/Savings.types';
import userEventDataService, { UserEvents } from 'src/api/userEventsData/userEventsData';
import { FS_SENSITIVE_DATA_CLASS } from 'src/constants/fullStory';

export const PhoneButtonClick: React.FC<PhoneButtonClickProps> = ({ phone, location, isFullWidth }) => {
  const { gid, flow } = useParams() as DefaultLocation;
  const onButtonClick = () =>
    analytics.track(SEGMENT.PHONE_CONVERSATION_INITIATED, gid, flow, {
      location,
      page: SEGMENT.PAGES.LANDING
    });

  return (
    <Anchor customCSS={contactLinkCSS(isFullWidth)} href={`tel:${phone}`} onClick={onButtonClick}>
      <img src={HandsetIcon} width={16} alt="Handset" />
      {phone}
    </Anchor>
  );
};

const LiveHelpComponent: React.FC<LiveHelpComponentProps> = ({ phoneNumber, userName }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { gid, flow } = useParams() as DefaultLocation;
  const navigate = useCustomNavigate();
  const { search } = useLocation();
  const { isMobile } = useResponsive();

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    analytics.track(SEGMENT.LIVE_HELP_CLICKED, gid, flow);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onCallUsButtonClick = (location: string) => {
    analytics.track(SEGMENT.PHONE_CONVERSATION_INITIATED, gid, flow, {
      location,
      page: SEGMENT.PAGES.LANDING
    });
  };

  const onScheduleCallButtonClick = async () => {
    analytics.track(SEGMENT.CONVERSATION_SCHEDULING_INITIATED, gid, flow, {
      location: SEGMENT.LOCATIONS.HEADER,
      page: SEGMENT.PAGES.LANDING
    });

    try {
      await userEventDataService.callUserEventReport(UserEvents.ScheduleCall, gid);
    } catch {}

    if (flow === Flows.Life) {
      return navigate({ pathname: `/${flow}/${gid}/confirmation`, search });
    }

    return navigate(`/${flow}/${gid}/confirmation`);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      {isMobile ? (
        <Fragment>
          <Anchor
            css={[anchorCSS(), anchorHeaderCSS]}
            href={`tel:${phoneNumber}`}
            onClick={() => onCallUsButtonClick(SavingsContactLocations.MobileHeader)}
          >
            <img src={ColoredHandset} width={20} alt="Handset" />
            Call Us
          </Anchor>
        </Fragment>
      ) : (
        <Fragment>
          <Button customCSS={liveHelpButtonCSS} variant="secondary" onClick={handleClick}>
            <AgentIcon css={iconHoverCSS} /> <span>Live Help</span>
          </Button>

          <Popover
            id={id}
            css={popoverCSS}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <div css={wrapperCSS}>
              <div css={headerWrapperCSS}>
                <div>
                  <div className={FS_SENSITIVE_DATA_CLASS.MASK} css={headerTextCSS}>
                    Hi {userName && userName},
                  </div>
                  <div>Here’s how we can help: </div>
                </div>
                <CloseIcon onClick={handleClose} css={iconCSS} />
              </div>
              <Button customCSS={buttonCSS}>
                <Anchor
                  customCSS={anchorCSS(true)}
                  href={`tel:${phoneNumber}`}
                  onClick={() => onCallUsButtonClick(SavingsContactLocations.LiveHelpButton)}
                >
                  <img src={HandsetIcon} width={20} alt="Handset" />
                  <span>Call: {phoneNumber}</span>
                </Anchor>
              </Button>
              <Button variant="secondary" css={buttonCSS} onClick={onScheduleCallButtonClick}>
                <CalendarIcon css={iconHoverCSS} /> Schedule a Consultation
              </Button>
            </div>
          </Popover>
        </Fragment>
      )}
    </div>
  );
};

export default LiveHelpComponent;
