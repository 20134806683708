import { css, SerializedStyles } from '@emotion/react';
import { colors, mq } from 'src/theme/variables';
import { text, text14, text20 } from 'src/theme/typography';

export const container = (): SerializedStyles => css`
  display: flex;
  justify-content: space-between;
`;

export const divider = css`
  border-bottom: 1px solid ${colors.dove};
  margin: 8px; 0;
  ${mq[0]} {
    margin: 24px 0;
  }
`;

export const images = css`
  display: flex;
  align-items: center;
`;

export const icon = css`
  width: 24px;
  margin-right: 12px;
  ${mq[1]} {
    margin-right: 24px;
  }
`;

export const premiumContainer = css`
  margin-left: 12px;
  text-align: right;
  ${mq[1]} {
    margin-left: 24px;
  }
`;

export const premiumValue = css`
  ${text};
  font-weight: 700;
  margin-bottom: 4px;
  ${mq[0]} {
    ${text20};
  }
`;

export const premiumLabel = css`
  color: ${colors.warmGray};
  ${text14};
`;
