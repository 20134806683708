/** @jsxImportSource @emotion/react */

import React, { Fragment } from 'react';
import { inspectionHeadingCSS, inspectionMessageCSS, inspectionTextCSS } from './UploadFileInputFlorida.style';
import SIRV from 'src/constants/sirv';
import UploadFilesComponent from 'src/components/UploadFilesComponent/UploadFilesComponent';
import { UploadFilesComponentProps } from 'src/components/UploadFilesComponent/UploadFilesComponent.types';
import { FormQuestionProps } from 'src/interfaces/IQuestion';

const UploadFileInputFlorida: React.FC<FormQuestionProps> = ({ uploadFilesWrapperParams }) => {
  return (
    <Fragment>
      <div css={inspectionHeadingCSS}>Do you have any recent home inspections?</div>
      <div css={inspectionMessageCSS}>
        <img src={SIRV.INFO_ICON} alt={'info-icon'} />
        <div css={inspectionTextCSS}>
          Florida homes older than 20 years typically require a four point inspection within the past year to be
          insured. While a wind mitigation inspection may not be required, it can help greatly lower your annual
          premium.
        </div>
      </div>
      <UploadFilesComponent {...(uploadFilesWrapperParams as UploadFilesComponentProps)} />
    </Fragment>
  );
};

export default UploadFileInputFlorida;
