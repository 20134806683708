import { AllFunnelResponse } from 'src/interfaces/IAllFunnel';
import api from 'src/api/api';
import { API_URLS } from 'src/constants/api';
import { getApiUrl } from 'src/utils/api.helpers';

const allFunnelService = {
  createLead(gid: string): Promise<AllFunnelResponse> {
    return api.post(getApiUrl(API_URLS.AF_CREATE_LEAD, gid));
  }
};

export default allFunnelService;
