/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';

import { CoverageOptionsTitleProps } from './CoverageOptionsTitle.types';
import { actions, button, container, dialog, label } from './CoverageOptionsTitle.style';
import noop from 'src/utils/noop';
import Dialog from 'src/kit/Dialog/Dialog';
import CoverageComparison from 'src/components/Quotes/AutoQuotes/CoverageOptions/CoverageComparison/CoverageComparison';
import useResponsive from 'src/hooks/useResponsive';
import Button from 'src/kit/Button/Button';

const CoverageOptionsTitle: React.FC<CoverageOptionsTitleProps> = ({ customCSS, assets, onActionClick = noop }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isMobile } = useResponsive();

  const onLearnMore = () => {
    onActionClick();
    setIsModalOpen(true);
  };

  return (
    <div css={[container, customCSS]} data-testid="coverage-options-label">
      <div css={label}>Coverage Options{isMobile ? null : ':'}</div>
      <Button variant="text" css={button} type="button" onClick={onLearnMore}>
        {isMobile ? 'Learn More' : 'learn about coverage types'}
      </Button>

      <Dialog
        customCSS={{ body: dialog }}
        isOpen={isModalOpen}
        fullscreen={isMobile}
        showHeader
        onRequestClose={() => setIsModalOpen(false)}
      >
        <CoverageComparison assets={assets} />
        {!isMobile && (
          <div css={actions}>
            <Button onClick={() => setIsModalOpen(false)}>Close</Button>
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default CoverageOptionsTitle;
