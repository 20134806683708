import api from 'src/api/api';
import { UploadFile } from 'src/shared/components/FileUploadsList/FileUploadListItem/FileUploadListItem.types';
import { API_URLS } from 'src/constants/api';
import { getApiUrl } from 'src/utils/api.helpers';

const documentUploadService = {
  uploadDocument(gid: string, body: { file_name: string; base64_content: string }): Promise<any> {
    return api.post(getApiUrl(API_URLS.ATTACHMENTS_UPLOAD, gid), { body }, false);
  },
  getUploadedDocuments(gid: string): Promise<UploadFile[]> {
    return api.get(getApiUrl(API_URLS.ATTACHMENTS, gid), {}, false);
  },
  deleteUploadedDocument(
    gid: string,
    attachment_id: string
  ): Promise<{ attachment_id: string; byte_size: number; file_name: string }> {
    return api.delete(getApiUrl(API_URLS.ATTACHMENTS_DELETE, gid), { body: { attachment_id } }, false);
  }
};

export default documentUploadService;
