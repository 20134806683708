import api from 'src/api/api';
import { API_URLS } from 'src/constants/api';
import { LookupResponse } from 'src/interfaces/ILookup';

const lookupService = {
  //TODO do we need two methods with difference in single params?
  checkLoanNumberStatus(key: string): Promise<LookupResponse> {
    return api.get(API_URLS.LOOKUP, {
      params: {
        key
      }
    });
  },
  findCustomer(key: string, zip: string, test_mode: boolean): Promise<LookupResponse> {
    return api.get(API_URLS.LOOKUP, {
      params: {
        key,
        zip,
        test_mode
      }
    });
  }
};

export default lookupService;
