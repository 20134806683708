/** @jsxImportSource @emotion/react */
import React from 'react';
import AFBundleToggleComponent from 'src/components/AFBundleToggleComponent/AFBundleToggleComponent';
import useResponsive from 'src/hooks/useResponsive';
import {
  descriptionCopyCSS,
  descriptionCSS,
  headingCSS,
  iconBundleCSS
} from 'src/components/Quotes/QuotesPageHero/QuotesHeroContentSavings/QuotesHeroContentSavings.style';
import { contentHeroCSS, descriptionTextHeroCSS } from 'src/components/Quotes/QuotesPageHero/QuotesPageHero.style';
import houseIcon from 'src/assets/svg/bundle-savings-hero.svg?react';
import skiesIcon from 'src/assets/svg/skies.svg?react';
import { AUTOMATION_TEST_IDS } from 'src/constants/automationTestIds';

interface QuotesHeroBundleContentSavingsProps {
  firstName?: string;
  setAFToggleOn: (isToggleOn: boolean) => void;
}

const QuotesHeroBundleContentSavings: React.FC<QuotesHeroBundleContentSavingsProps> = ({
  firstName = 'Hey',
  setAFToggleOn
}) => {
  const { isDesktop } = useResponsive();
  const BundleIcon = isDesktop ? houseIcon : skiesIcon;

  return (
    <div css={contentHeroCSS}>
      <div>
        <div css={headingCSS} data-testid={AUTOMATION_TEST_IDS.QUOTES_PAGE.HEADING}>
          {`${firstName}, save up to 20%* by bundling!`}
        </div>
        <BundleIcon css={iconBundleCSS} />

        <div css={descriptionCopyCSS}>*Average savings for users that bundle</div>

        <div css={[descriptionTextHeroCSS, descriptionCSS]}>
          <div>
            <AFBundleToggleComponent setAFToggleOn={setAFToggleOn} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuotesHeroBundleContentSavings;
