import { css } from '@emotion/react';
import { mq } from 'src/theme/variables';
import { text32, text48 } from 'src/theme/typography';

export const formWrapperCSS = css`
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 88px 15px 0;
`;

export const headingCSS = css`
  ${text32};
  font-weight: 700;
  margin: 0;
  padding: 8px;

  ${mq[0]} {
    ${text48};
    padding-bottom: 20px;
  }
`;

export const buttonWrapperCSS = css`
  width: 340px;

  ${mq[0]} {
    width: 410px;
  }
`;

export const buttonCSS = css`
  font-weight: 700;
  margin-top: 10px;
`;

export const privacyMattersCSS = css`
  max-width: 300px;
`;
