import { css, SerializedStyles } from '@emotion/react';

import { mq, colors } from 'src/theme/variables';
import { text48, text, text12 } from './../../../theme/typography';
import { disclaimerBase } from 'src/components/Landing/GoogleReviews/GoogleReview.style';
import { GoogleReviewsLayoutVariant } from './GoogleReviewRating.types';

export const wrapperCSS = (layoutVariant: GoogleReviewsLayoutVariant): SerializedStyles => css`
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: ${layoutVariant === GoogleReviewsLayoutVariant.Horizontal ? 'row' : 'column'};
`;

export const ratingCSS = css`
  font-size: 20px;
  margin-bottom: 4px;
`;

export const ratingValueCSS = (layoutVariant: GoogleReviewsLayoutVariant): SerializedStyles => css`
  font-size: 64px;
  line-height: 1.4;
  font-weight: 900;

  ${layoutVariant === GoogleReviewsLayoutVariant.Horizontal &&
  css`
    ${text48};
  `}
`;

export const starsCSS = css`
  margin-bottom: 4px;
`;

export const starCSS = (layoutVariant: GoogleReviewsLayoutVariant): SerializedStyles => css`
  margin-right: 8px;
  &:last-child {
    margin-right: 0;
  }

  ${layoutVariant === GoogleReviewsLayoutVariant.Horizontal &&
  css`
    width: 16px;

    ${mq[0]} {
      width: 21px;
    }
  `}
`;

export const linkCSS = css`
  ${text};
  font-weight: bold;
  border-bottom: 0;
`;

export const descriptionCSS = css`
  ${text12};

  font-weight: 400;
  color: ${colors.black};

  ${mq[0]} {
    ${text};
  }
`;

export const disclaimerCSS = css`
  ${disclaimerBase};
  margin-top: 8px;
`;

export const reviewsWrapperCSS = (layoutVariant: GoogleReviewsLayoutVariant): SerializedStyles => css`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-left: 8px;

  ${layoutVariant === GoogleReviewsLayoutVariant.Horizontal &&
  css`
    align-items: flex-start;
  `}
`;
