import { css } from '@emotion/react';
import { colors, mq } from 'src/theme/variables';

export const desktopContainerCSS = css`
  display: none;
  ${mq[0]} {
    display: flex;
    flex-shrink: 0;
    margin-left: 80px;
  }
`;

export const hoursCSS = css`
  color: ${colors.warmGray};
  text-align: right;
`;

export const callActionCSS = css`
  font-weight: bold;
  text-align: right;
  margin-left: 12px;
`;

export const paragraphCSS = css`
  margin: 0;
  font-size: 16px;
  line-height: 1.3;
  &:first-of-type {
    margin-bottom: 8px;
  }
`;

export const mobileContainerCSS = css`
  flex-shrink: 0;
  margin-left: 24px;
  ${mq[0]} {
    display: none;
  }
`;

export const callActionMobileCSS = css`
  display: flex;
  text-decoration: none;
  color: inherit;
  align-items: center;
  svg {
    margin-right: 4px;
  }
`;

export const phoneImageCSS = css`
  width: 24px;
`;

export const phoneNumberCSS = css`
  &:hover {
    color: ${colors.azure};
  }
`;
