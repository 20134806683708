const HistoricalBarCustomAxisTick: React.FC<any> = ({ x, y, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="middle"
        fill="black"
        fontFamily="freight-sans-pro, sans-serif"
        fontSize="12px"
        fontWeight="700"
      >
        {payload.value}
      </text>
    </g>
  );
};

export default HistoricalBarCustomAxisTick;
