import React, { useEffect, useState } from 'react';
import documentUploadService from './documentUpload.service';
import { FileStatusUpload } from 'src/shared/components/FileUploadsList/FileUploadList.types';
import { UploadFile } from 'src/shared/components/FileUploadsList/FileUploadListItem/FileUploadListItem.types';

const useDocumentUpload = (gid: string): [UploadFile[], React.Dispatch<React.SetStateAction<UploadFile[]>>] => {
  const [files, setFiles] = useState<UploadFile[]>([]);

  useEffect(() => {
    (async () => {
      const remoteFiles = await documentUploadService.getUploadedDocuments(gid);
      remoteFiles &&
        setFiles(
          remoteFiles.map((file: Omit<UploadFile, 'status'>) => ({ ...file, status: FileStatusUpload.SUCCESS }))
        );
    })();
  }, [gid]);

  return [files, setFiles];
};

export default useDocumentUpload;
