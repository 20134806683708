import { css, SerializedStyles } from '@emotion/react';
import { colors, mq } from 'src/theme/variables';
import { buttonStyle } from 'src/kit/Button/Button';
import { text, text20, text24, text32 } from 'src/theme/typography';

const containerPadding = 32;
const containerBorderRadius = 8;

export const quotesBannerAnimationOffsets = {
  filledCircle: 60,
  curve: 40,
  curveDesktop: 110,
  lines: 15
};

const getOrangeCircleStyles = (size: number): SerializedStyles => css`
  width: ${size}px;
  height: ${size}px;
  left: -${size / 2}px;
  bottom: -${size / 2 + quotesBannerAnimationOffsets.filledCircle}px;
`;

export const container = css`
  position: relative;
  background-color: ${colors.yellow};
  border-radius: ${containerBorderRadius}px;
  text-align: center;
  padding: 34px 16px 0;
  box-sizing: border-box;
  ${mq[0]} {
    min-height: 248px;
    padding: ${containerPadding}px ${containerPadding}px ${containerPadding}px 260px;
    text-align: left;
  }
  ${mq[1]} {
    min-height: 262px;
    padding-left: 427px;
    text-align: left;
  }
`;

export const header = css`
  ${mq[0]} {
    padding-right: 70px;
  }
  ${mq[1]} {
    padding-right: 0;
  }
`;

export const heading = css`
  ${text24};
  font-weight: bold;
  ${mq[1]} {
    ${text32};
  }
`;

export const message = css`
  ${text};
  margin-top: 4px;
  ${mq[0]} {
    margin-top: 8px;
  }
  ${mq[1]} {
    ${text20};
    margin-top: 4px;
  }
`;

export const agent = css`
  position: relative;
  padding-top: 20px;
  overflow: hidden;
  ${mq[0]} {
    position: absolute;
    left: 0;
    bottom: 0;
    border-radius: ${containerBorderRadius}px;
  }
  ${mq[1]} {
    padding-right: 60px;
  }
`;

export const agentPhoto = css`
  position: relative;
  margin: 0 auto;
  max-width: 200px;
  display: block;
  z-index: 1;
  ${mq[0]} {
    max-width: 270px;
    margin-left: -10px;
  }
  ${mq[1]} {
    margin-left: 70px;
  }
`;

export const agentCircle = css`
  position: absolute;
  content: '';
  display: block;
  border-radius: 50%;
  background-color: ${colors.orange};
  ${getOrangeCircleStyles(320)}
  ${mq[0]} {
    ${getOrangeCircleStyles(384)}
  }
`;

export const curveIconDesktop = css`
  display: none;
  position: absolute;
  right: 10px;
  top: ${32 + quotesBannerAnimationOffsets.curveDesktop}px;
  height: 90px;
  ${mq[0]} {
    display: block;
  }
  ${mq[1]} {
    height: 104px;
    right: 0;
  }
`;

export const footer = css`
  position: relative;
  margin-top: 24px;
  ${mq[0]} {
    margin-top: 20px;
    display: flex;
    align-items: center;
  }
  ${mq[1]} {
    margin-top: 24px;
  }
`;

export const button = css`
  ${buttonStyle(true, 'secondary')};
  padding: 14px 20px;
  ${text20};
  display: inline-block;
  text-decoration: none;
  color: ${colors.black};
  background-color: transparent;
  border-color: ${colors.black};
  &:focus,
  &:active,
  &:hover {
    color: ${colors.yellow};
    background-color: ${colors.black};
    border-color: ${colors.black};
  }
  ${mq[0]} {
    ${text};
    padding: 10px 12px;
  }
  ${mq[1]} {
    ${text20};
    padding: 14px 20px;
  }
`;

export const contacts = css`
  margin-top: 24px;
  ${mq[0]} {
    margin-top: 0;
    margin-left: 20px;
  }
`;

export const phone = css`
  ${text24};
  font-weight: bold;
  border-bottom: none;
  ${mq[0]} {
    ${text20};
  }
  ${mq[1]} {
    ${text24};
  }
`;

export const workingHours = css`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  ${mq[0]} {
    line-height: 20px;
  }
  ${mq[1]} {
    line-height: 24px;
  }
`;

export const mailIcon = css`
  display: block;
  margin: 4px auto 0;
  ${mq[0]} {
    position: absolute;
    right: 22px;
    top: 21px;
    width: 71px;
  }
  ${mq[1]} {
    width: 128px;
    top: auto;
    bottom: 0;
    right: 30px;
  }
`;

export const linesIcon = css`
  position: absolute;
  left: 24px;
  top: -${16 - quotesBannerAnimationOffsets.lines}px;
  ${mq[0]} {
    width: 45px;
  }
  ${mq[1]} {
    width: 65px;
  }
`;

export const curveIcon = css`
  position: absolute;
  right: 76%;
  top: ${32 + quotesBannerAnimationOffsets.curve}px;
  ${mq[0]} {
    display: none;
  }
`;

export const circleIcon = css`
  display: none;
  position: absolute;
  left: -22px;
  bottom: -22px;
  z-index: 1;
  ${mq[0]} {
    display: block;
  }
  ${mq[1]} {
    width: 64px;
  }
`;
