import { css } from '@emotion/react';
import { mq } from 'src/theme/variables';
import { text20, text, text40, text24, text14, text48 } from 'src/theme/typography';
import HouseBg from 'src/assets/svg/house-bg.svg';
import Ellipse from 'src/assets/svg/ellipse.svg';

export const headerCSS = css`
  display: flex;
  justify-content: space-between;
  margin: 30px auto 29px;
  align-items: center;
  font-weight: 900;
  flex-direction: column;
  text-align: center;
  ${text40};

  ${mq[0]} {
    max-width: 70%;
    margin: 10px auto 29px;
    ${text48};
    line-height: 50px;
  }

  ${mq[1]} {
    margin: 80px auto 29px;
    font-size: 64px;
  }
`;

export const subHeaderCSS = css`
  ${text20};
`;

export const textCSS = css`
  ${text20};
  text-align: center;
  margin-top: 50px;

  ${mq[1]} {
    text-align: left;
    margin-top: 0;
  }
`;

export const descriptionCSS = css`
  ${text14};
  margin-bottom: 56px;
`;

export const carrierCarouselWrapperCSS = css`
  margin: 0 auto;
  text-align: center;
  ${text24};
  font-weight: bold;

  ${mq[1]} {
    ${text40};
    margin-top: 50px;
  }
`;

export const imageDivCSS = css`
  background-image: url('https://maticinsurance.sirv.com/illustrations/mobileBG.png');
  background-repeat: no-repeat;
  background-size: contain;
  text-align: center;
  height: fit-content;
  width: 100%;
  height: 300px;
`;

export const bgImgCSS = css`
  display: flex;
  background-repeat: round;
  background-size: contain;
  text-align: center;
  height: fit-content;

  ${mq[0]} {
    background-image: url(${HouseBg});
    height: 500px;
    background-size: cover;
    margin-top: 30px;
  }

  ${mq[1]} {
    background-size: contain;
    margin-top: 0;
    height: 700px;
  }

  ${mq[2]} {
    background-repeat: round;
    background-size: cover;
  }
`;

export const buttonCSS = css`
  margin-top: 20px;
  padding: 16px 24px;
  width: 100%;

  ${mq[0]} {
    text-align: center;
    ${text20};
    width: unset;
    margin-top: 30px;
  }
`;

export const carrierGridWrapperCSS = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px auto 0;
  max-width: 85%;
`;

export const carrierCSS = css`
  margin: 6px 24px;
`;

export const bundleBlockCSS = css`
  margin: 40px 0 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 4px;
  border: 2px solid #e6e6e6;
  text-align: center;
  ${text24};

  font-weight: bold;

  img {
    margin-bottom: 20px;
    width: 250px;

    ${mq[0]} {
    unset;
    }
  }

  ${mq[0]} {
    ${text40};
    padding: 80px;
  }
`;

export const googleReviewsWrapperCSS = css`
  background-color: #f2f2f2;
  padding: 80px 0;
  margin: 40px 0;

  ${mq[0]} {
    margin-bottom: 80px;
  }
`;

export const buttonWrapperCSS = css`
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  ${mq[0]} {
    flex-direction: row;
  }

  button {
    width: 100%;

    ${mq[0]} {
      margin: 40px 20px 0;
      width: 230px;
    }
  }
`;

export const cardsWrapperCSS = css`
  background-image: url(${Ellipse});
  background-repeat: no-repeat;
  background-size: contain;
  margin: 20px 0 0;

  ${mq[0]} {
    margin: 80px 0 0;
    padding: 60px;
  }

  ${mq[1]} {
    background-size: cover;
  }
`;

export const cardWrapperCSS = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #b3b3b3;
  text-align: center;
  box-shadow: 2px 4px 0px 0px rgba(0, 0, 0, 0.12);
  padding: 30px;
  max-width: 100%;
  background-color: white;
  margin-top: 20px;
  ${text};

  ${mq[0]} {
    width: 100%;
    height: 280px;
  }

  ${mq[1]} {
    max-width: 25%;
    height: 340px;
  }

  ${mq[2]} {
    max-width: 25%;
    margin-bottom: 0px;
    padding: 40px;
  }

  img {
    width: 150px;

    ${mq[2]} {
      width: 200px;
    }
  }
`;

export const cardsRowWrapperCSS = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 0px;
  flex-direction: column;

  ${mq[1]} {
    flex-direction: row;
  }
`;

export const cardLabelCSS = css`
  font-weight: bold;
  ${text20};
  margin: 20px 0 12px;
`;

export const dividerCSS = css`
  margin: 48px auto 48px;
  width: 114px;
`;

export const cardSectionCSS = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  ${text20}
`;

export const carouselWrapperCSS = css`
  margin: 20px auto 0;
  overflow-x: hidden;
  height: 300px;
`;

export const cardSectionLabelCSS = css`
  ${text24};
  font-weight: bold;
  margin-bottom: 20px;

  ${mq[0]} {
    ${text40};
    margin-bottom: 28px;
  }
`;
