import { css } from '@emotion/react';
import { mq } from 'src/theme/variables';
import { text20, text48, text32, text, text40, text24, text14 } from 'src/theme/typography';
import Ellipse from 'src/assets/svg/ellipse.svg';

export const mainBlockWrapperCSS = css`
  display: flex;
  align-items: center;
  flex-direction: column;
  ${text20};
  text-align: center;
  margin-top: 56px;
  padding-top: 56px;
  position: relative;

  ${mq[0]} {
    margin-top: 88px;
    padding-top: 88px;
  }

  ${mq[1]} {
    margin-top: 50px;
    padding-top: 100px;
    ${text32};
  }
`;

export const headerCSS = css`
  display: flex;
  justify-content: space-between;
  margin: 56px 0 32px 0;
  align-items: center;
  font-weight: 700;
  flex-direction: column;
  text-align: center;
  ${text32};

  ${mq[0]} {
    margin: 56px 0;
    ${text40};
  }

  ${mq[1]} {
    margin-bottom: 20px;
    text-align: left;
    ${text48};
    flex-direction: row;
  }
`;

export const carrierButtonCSS = css`
  color: #156eea;
  padding-bottom: 2px;
  ${text};
  text-align: center;
  margin-top: 20px;

  ${mq[0]} {
    ${text20}
  }

  ${mq[1]} {
    margin-top: 0;
  }
`;

export const textCSS = css`
  ${text};
  text-align: center;
  margin-top: 50px;

  ${mq[0]} {
    margin-bottom: 80px;
  }

  ${mq[1]} {
    ${text20};
    text-align: left;
    margin-top: 0;
  }
`;

export const descriptionCSS = css`
  ${text14};
  margin-bottom: 80px;
`;

export const carrierCarouselWrapperCSS = css`
  margin: 80px auto;
  text-align: center;
  ${text24};
  font-weight: bold;

  ${mq[0]} {
    ${text32};
  }
`;

export const bgImgCSS = css`
  background-image: url(${Ellipse});
  background-repeat: no-repeat;
  background-size: contain;

  ${mq[1]} {
    background-size: cover;
  }
`;

export const buttonCSS = css`
  margin-top: 30px;
  padding: 16px 24px;
  width: 100%;

  ${mq[0]} {
    ${text20};
    width: unset;
  }
`;

export const carrierGridWrapperCSS = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px auto 0;
  max-width: 85%;
`;

export const carrierCSS = css`
  margin: 6px 24px;
`;
export const arrowCSS = css`
  position: absolute;
  top: -18px;
  left: 5px;
  width: 100px;
  transform: scaleX(1);

  ${mq[0]} {
    width: unset;
    left: 40px;
  }

  ${mq[1]} {
    left: 240px;
  }

  ${mq[2]} {
    left: 325px;
  }
`;

export const maticLogoCSS = css`
  max-width: 120px;

  ${mq[0]} {
    max-width: unset;
  }
`;

export const carouselWrapperCSS = css`
  margin-top: 20px;
  overflow-x: hidden;
  height: 258px;
`;

export const headerTitleCSS = css`
  ${mq[0]} {
    font-weight: 700;
    max-width: 60%;
  }

  ${mq[1]} {
    font-weight: 900;
    max-width: 60%;
  }
`;
