/** @jsxImportSource @emotion/react */
import React, { useState, useCallback } from 'react';

import ChevronIcon from 'src/assets/svg/chevron.svg';
import { chevron, loadingLineCSS, navigation, navigationIndicator, navigationName } from './NavigationItem.style';
import { activeElementReset } from 'src/theme/helpers';
import { NavigationItemProps } from './NavigationItem.types';
import SkeletonLoader from 'src/shared/components/SkeletonLoader/SkeletonLoader';

const NavigationItem: React.FC<NavigationItemProps> = ({
  name,
  key_name,
  isCompleted = false,
  isCurrent = false,
  isUnavailable = false,
  isLoading = false,
  asyncDetailsComponent: AsyncDetailsComponent,
  details,
  onItemClick
}) => {
  const [expanded, setExpanded] = useState(true);
  const [detailsHasData, setDetailsHasData] = useState(false);
  const renderExpandButton = (AsyncDetailsComponent && detailsHasData) || details;

  const onExpand = () => {
    setExpanded(expanded => !expanded);
  };

  const onDetailsReady = useCallback(() => {
    setDetailsHasData(true);
  }, [setDetailsHasData]);

  return (
    <div css={navigation(isCompleted)} data-testid="navigation-block">
      <div css={navigationIndicator(isCompleted, isCurrent)} data-testid="navigation-indicator" />
      {isLoading ? (
        <SkeletonLoader customCSS={loadingLineCSS} height={20} minWidth={70} maxWidth={90} />
      ) : (
        <button
          css={navigationName(!isUnavailable)}
          aria-current={isCurrent ? 'step' : undefined}
          disabled={isUnavailable || isLoading}
          data-testid="section-button"
          onClick={onItemClick}
        >
          {name}
        </button>
      )}

      {renderExpandButton && (
        <button onClick={onExpand} data-testid="expand-button" css={activeElementReset} aria-expanded={expanded}>
          <img src={ChevronIcon} alt={`More on ${name}`} css={chevron(expanded)} data-testid="navigation-chevron" />
        </button>
      )}
      {AsyncDetailsComponent && expanded && <AsyncDetailsComponent keyName={key_name} onDataReady={onDetailsReady} />}
      {expanded && details}
    </div>
  );
};

export default NavigationItem;
