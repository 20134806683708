import { Entity } from 'src/interfaces/IPage';
import { AUTO_FORM_PAGE_KEYS, RELATIONSHIP_TO_POLICYHOLDER_OPTIONS } from 'src/constants/hardcoded';

const MAXIMUM_VEHICLE_QUANTITY = 6;

export const isPrimaryDriver = (formPageKeyName: string, relation: string): boolean =>
  [AUTO_FORM_PAGE_KEYS.DRIVERS, AUTO_FORM_PAGE_KEYS.BUNDLE_DRIVERS].includes(formPageKeyName) &&
  relation === RELATIONSHIP_TO_POLICYHOLDER_OPTIONS.SELF;

export const isAddingDriverVehicleDisabled = (fieldKeyName: string, entities: Entity[]): boolean =>
  [AUTO_FORM_PAGE_KEYS.VEHICLES, AUTO_FORM_PAGE_KEYS.DRIVERS].includes(fieldKeyName) &&
  entities.length >= MAXIMUM_VEHICLE_QUANTITY;
