/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';

import ReactModal from 'react-modal';

import {
  closeIconCSS,
  contentCSS,
  overlayCSS,
  containerCSS,
  titleContainerCSS,
  subtitleContainerCSS,
  spaceCSS,
  headerCSS
} from './Dialog.style';
import { DialogProps } from './types';
import IconButton from 'src/kit/IconButton/IconButton';
import close from 'src/assets/svg/close.svg';
import Header from 'src/kit/Header/Header';
import { onIosTouchMoveFocusedInputScrollFix } from 'src/utils/scroll';

const TRANSITION_DURATION = 200;

export const setAppElementForDialog = ReactModal.setAppElement;

const Dialog: React.FC<DialogProps> = ({
  customCSS = {},
  title,
  subTitle,
  ariaKey = 'key',
  size = 'default',
  onRequestClose,
  fullscreen = false,
  showHeader = false,
  partnerLogo,
  partnerName,
  children,
  isOpen,
  shouldCloseOnOverlayClick,
  shouldHideCloseIcon = false,
  transitionDuration = TRANSITION_DURATION,
  ...props
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [isOpen]);

  const addSpaceForExistingHeader = !showHeader && fullscreen;

  return (
    <ReactModal
      isOpen={isOpen}
      aria={{
        labelledby: `${ariaKey}-dialog-heading`,
        describedby: `${ariaKey}-dialog-description`
      }}
      style={{
        content: contentCSS(size, fullscreen, customCSS.dialogInline?.content),
        overlay: overlayCSS(fullscreen, addSpaceForExistingHeader, customCSS.dialogInline?.overlay)
      }}
      closeTimeoutMS={transitionDuration}
      css={customCSS.dialog}
      {...(shouldCloseOnOverlayClick && { onRequestClose })}
      {...props}
    >
      {onRequestClose && !shouldHideCloseIcon && (
        <IconButton
          css={[closeIconCSS(fullscreen), customCSS.closeButton]}
          src={close}
          onClick={onRequestClose}
          iconText="Close"
          alt="Close icon"
        />
      )}

      {/*TODO review this header, mb we need remove it from here and just make space for header from root div*/}
      {fullscreen && showHeader && (
        <Header customCSS={headerCSS} wrapperTag="div" partnerLogo={partnerLogo} partnerName={partnerName} />
      )}

      <div css={[containerCSS(fullscreen), customCSS.body]} onTouchMove={onIosTouchMoveFocusedInputScrollFix}>
        {title && (
          <div css={[titleContainerCSS, customCSS.title]} id={`${ariaKey}-dialog-heading`}>
            {title}
          </div>
        )}
        {(title || subTitle) && <div css={spaceCSS} />}
        {subTitle && (
          <div css={subtitleContainerCSS} id={`${ariaKey}-dialog-description`}>
            {subTitle}
          </div>
        )}
        {children}
      </div>
    </ReactModal>
  );
};

export default Dialog;
