import api from 'src/api/api';
import { API_URLS } from 'src/constants/api';
import { getApiUrl } from 'src/utils/api.helpers';

const callMeNowService = {
  scheduleCall(gid: string): Promise<any> {
    return api.post(getApiUrl(API_URLS.SCHEDULE_CALL, gid));
  }
};

export default callMeNowService;
