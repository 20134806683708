import { TouchEventHandler } from 'react';
import { isIos } from './device';

export const scrollToNode = (id: string): void => {
  const node = document.getElementById(id);
  node?.scrollIntoView({ behavior: 'smooth' });
};

export const onIosTouchMoveFocusedInputScrollFix: TouchEventHandler = event => {
  if (isIos()) {
    const inputs = event.currentTarget.getElementsByTagName('input');

    for (let i = 0; i < (inputs || []).length; i++) {
      inputs[i].blur();
    }
  }
};
