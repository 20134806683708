/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useState } from 'react';
import SEGMENT from 'src/constants/segment';

import QuotesCarriers from 'src/components/Quotes/QuotesCarriers/QuotesCarriers';
import TalkToAdvisorSection from 'src/components/Quotes/TalkToAdvisorSection/TalkToAdvisorSection';
import DividerIcon from 'src/assets/svg/curvy-divider.svg';
import analytics from 'src/utils/analytics';
import { useCustomNavigate } from 'src/hooks/useCustomNavigate';
import { DefaultLocation } from 'src/interfaces/IPage';
import { faqHorizontalScrollFixContainer } from 'src/theme/shared-styles';
import FAQ from 'src/components/FAQ/FAQ';
import Container from 'src/kit/Container/Container';
import { containerCSS, quoteCardCSS } from 'src/components/Quotes/StandardHouseQuotes/StandardHouseQuotes.style';
import { carriersList, dividerCSS, faq, quotesContainer, talkToAdvisor } from 'src/components/Quotes/Quotes.style';
import SavingsHead from 'src/components/SavingsHero/SavingsHead/SavingsHead';
import { useParams } from 'react-router-dom';
import { useQuotes } from 'src/api/quotes';
import { HomeQuote, HomeQuotesResponse, ICurrentPolicy, SavingsQuote } from 'src/interfaces/IQuotes';
import { useQuestions } from 'src/api/questions';
import { Person } from 'src/interfaces/IAllFunnel';
import QuoteBenefits from 'src/components/QuoteBenefits/QuoteBenefits';
import { QuoteBenefitsCustomCSSProps } from 'src/components/QuoteBenefits/QuoteBenefits.types';
import { getSavingsBenefitsList } from 'src/components/SavingsHero/SavingHero.utils';
import { benefitsTitleCSS, benefitsWrapperCSS } from 'src/components/SavingsHero/SavingsHero.style';
import { heroInfoCSS, heroWrapperCSS } from 'src/components/SavingsHero/SavingsOffer/SavingsOffer.style';
import { Card } from 'src/shared/components/Card/Card';
import Format from 'src/utils/format';
import {
  additionalHeroCSS,
  benefitsWrapperAdditionalCSS,
  customButtonCardCSS,
  singleQuotesContainerCSS
} from './AfSingleQuote.style';
import Button from 'src/kit/Button/Button';
import { QuoteSelectData, QuotesProps, SelectedQuoteState } from 'src/components/Quotes/Quotes.types';
import HomeCard from 'src/components/Quotes/QuoteCard/HomeCard/HomeCard';
import AfSingleQuoteCard from './AfSingleQuoteCard/AfSingleQuoteCard';
import useResponsive from 'src/hooks/useResponsive';
import QuoteDetailsDialog from 'src/components/Quotes/QuoteDetailsDialog/QuoteDetailsDialog';
import { getHomeQuotesCoveragesGroups, getPremiumLabelByType } from 'src/utils/quotesPage';
import QuoteCoverages from 'src/components/Quotes/QuoteCoverages/QuoteCoverages';
import BBBIcon from 'src/components/BBBIcon/BBBIcon';
import { BBBIconVariation } from 'src/components/BBBIcon/BBBIcon.types';
import { useConfig } from 'src/api/config';
import userEventDataService, { UserEvents } from 'src/api/userEventsData/userEventsData';
import { AUTOMATION_TEST_IDS } from 'src/constants/automationTestIds';
import { heroSectionCSS } from 'src/components/SavingsHero/BundleMsgHero/BundleMsgHero.style';
import { getMultiPolicyDiscountTag } from 'src/constants/quoteTag';

const AfSingleQuote: React.FC<QuotesProps> = ({ selectQuote, isFreedomSFVariation }) => {
  const { isMobile } = useResponsive();

  const navigate = useCustomNavigate();
  const { flow, gid } = useParams() as DefaultLocation;
  const { data: quotes } = useQuotes<HomeQuotesResponse>(flow, gid);
  const { data: questions } = useQuestions(gid);
  const { data: config } = useConfig(gid);

  const [isOtherQuotesShown, setIsOtherQuotesShown] = useState(false);
  const [isMobileDetailsOpen, setIsMobileDetailsOpen] = useState(false);
  const [selectedQuote, setSelectedQuote] = useState<SelectedQuoteState<HomeQuote> | null>(null);

  const bestQuote = quotes?.quotes[0] as SavingsQuote;
  const availableQuotes = quotes?.quotes.slice(1, 4) as SavingsQuote[];
  const confirmationPagePath = `/${flow}/${gid}/confirmation`;
  const MPDQuoteAvailable = availableQuotes.some(quote => quote.multi_policy_discount_premium);
  const saving = Format.money(bestQuote.saving_value, 0, '$');

  useEffect(() => {
    if (MPDQuoteAvailable || !!bestQuote?.multi_policy_discount_premium) {
      analytics.track(SEGMENT.AF_BUNDLE.MPD_QUOTE_SHOWED, gid, flow);
    }
  }, [MPDQuoteAvailable, flow, gid, bestQuote?.multi_policy_discount_premium]);

  const onTalkToAdvisorClick = async (ordinal: number, clickReason: string) => {
    analytics.track(SEGMENT.TALK_TO_AN_ADVISOR_CLICKED, gid, flow, {
      ordinal,
      click_reason: clickReason
    });

    analytics.track(SEGMENT.CONVERSATION_SCHEDULING_INITIATED, gid, flow, {
      page: SEGMENT.PAGES_KEY.QUOTES,
      location: SEGMENT.LOCATIONS.QUOTES_HERO
    });

    try {
      await userEventDataService.callUserEventReport(UserEvents.ScheduleCall, gid);
    } catch {}

    navigate(confirmationPagePath);
  };

  const getQuoteSelectData = (quote: SavingsQuote | HomeQuote): QuoteSelectData => {
    return {
      gid: quote.gid,
      carrier: quote.carrier,
      onlineBindUrl: quote.online_bind_url,
      flow,
      premium_value: quote.premium.value,
      savingsAmount: quote.saving_value!,
      digital_profile_url: quote.digital_profile_url
    };
  };

  const geQuotesBenefit = (customCSS?: QuoteBenefitsCustomCSSProps) => (
    <QuoteBenefits
      title="Benefits of switching with Matic"
      benefits={getSavingsBenefitsList(config!.partner.name, config!.partner.key || '', true)}
      customCSS={customCSS}
    />
  );

  const changeOtherQuoteState = () => {
    setIsOtherQuotesShown(prevState => {
      const isShown = !prevState;
      analytics.track(SEGMENT.OTHER_QUOTES_SHOWN, gid, flow);
      return isShown;
    });
  };

  const onCardViewed = useCallback(
    (quote: HomeQuote, index: number) => {
      if (quote.online_bind_url) {
        analytics.track(SEGMENT.ONLINE_BIND_OFFERED, gid, flow, {
          ordinal: index + 2,
          carrier_key: quote.carrier.key
        });
      }
    },
    [flow, gid]
  );

  useEffect(() => {
    analytics.page(SEGMENT.PAGES.QUOTES, {
      flow_type: flow,
      partner_key: config?.servicer_key,
      session_gid: gid
    });
  }, [config, flow, gid]);

  useEffect(() => {
    onCardViewed(bestQuote, -1);
  }, [bestQuote, onCardViewed]);

  const trackQuoteReviewed = async (quote: HomeQuote, ordinal: number) => {
    analytics.track(SEGMENT.QUOTE_REVIEWED, gid, flow, {
      carrier_key: quote.carrier.key,
      ordinal
    });

    try {
      await userEventDataService.callUserEventReport(UserEvents.QuoteDetailsClicked, gid);
    } catch {}
  };

  const showMobileDetails = (quote: HomeQuote, ordinal: number) => {
    trackQuoteReviewed(quote, ordinal);
    setSelectedQuote({ quote, ordinal });
    setIsMobileDetailsOpen(o => !o);
  };

  const onShowQuoteDetailClick = (quote: HomeQuote, ordinal: number) => {
    if (isMobile) {
      showMobileDetails(quote, ordinal);
    } else {
      trackQuoteReviewed(quote, ordinal);
    }
  };

  const onQuoteSelectFromDetailsPage = () => {
    if (selectedQuote) {
      const { quote, ordinal } = selectedQuote;
      selectQuote(ordinal, getQuoteSelectData(quote));
    }
  };

  const getMobileDetailsPopup = () => {
    if (selectedQuote) {
      const { quote: q } = selectedQuote as { quote: HomeQuote };
      const annualPremium = q.premium.value;

      return (
        <QuoteDetailsDialog
          logo={{
            url: q.carrier.logo_url,
            label: q.carrier.name
          }}
          price={{
            value: Format.money(annualPremium, 0, '$'),
            label: getPremiumLabelByType(q.premium.type)
          }}
          contentComponent={<QuoteCoverages groups={getHomeQuotesCoveragesGroups(q.coverages)} expandable />}
          onClose={() => {
            setIsMobileDetailsOpen(false);
            setTimeout(() => setSelectedQuote(null), 100);
          }}
          onActionClick={onQuoteSelectFromDetailsPage}
          isOpen={isMobileDetailsOpen}
        />
      );
    }
  };

  const getQuoteCoverTag = ({ discount }: { discount: number | null }) => {
    if (discount) {
      return [getMultiPolicyDiscountTag(discount)];
    }

    return null;
  };

  return (
    <div aria-live="polite">
      <section css={heroSectionCSS}>
        <Container>
          <div css={heroWrapperCSS(!isMobile ? 'flex-start' : '')}>
            <div css={[heroInfoCSS(), additionalHeroCSS]}>
              <SavingsHead
                person={{ first_name: questions?.answers.person_first_name } as Person}
                currentPolicy={quotes!.current_policy as ICurrentPolicy}
                activeQuote={bestQuote}
                showExpirationDate={false}
              />

              {!isMobile && (
                <>
                  {geQuotesBenefit({
                    container: benefitsWrapperCSS,
                    title: benefitsTitleCSS
                  })}
                  <BBBIcon colorVariation={BBBIconVariation.Blue} />
                </>
              )}
            </div>

            <AfSingleQuoteCard
              selectQuote={() => selectQuote(1, getQuoteSelectData(bestQuote))}
              bestQuote={bestQuote}
              currentPolicy={quotes?.current_policy}
              answers={questions?.answers}
              saving={saving}
              premiumToRender={bestQuote.premium.value}
              trackQuoteReviewed={() => trackQuoteReviewed(bestQuote, 1)}
            />

            {isMobile && (
              <>
                {geQuotesBenefit({
                  container: [benefitsWrapperCSS, benefitsWrapperAdditionalCSS],
                  title: benefitsTitleCSS
                })}
                <BBBIcon colorVariation={BBBIconVariation.Blue} />
              </>
            )}
          </div>
        </Container>
      </section>

      <Container dense customCSS={[quotesContainer, containerCSS, singleQuotesContainerCSS]}>
        {isOtherQuotesShown && !isFreedomSFVariation ? (
          <>
            {availableQuotes?.map((quote, index) => (
              <HomeCard
                key={quote.gid}
                customCSS={quoteCardCSS(index === 0)}
                index={index}
                tags={getQuoteCoverTag({
                  discount: quote.multi_policy_discount_percentage
                })}
                quote={quote}
                highlightPremium={index === 0}
                onCardViewed={onCardViewed}
                useMultiPolicyDiscount
                onShowDetailsClicked={() => onShowQuoteDetailClick(quote, index + 2)}
                onQuoteSelect={() => selectQuote(index + 2, getQuoteSelectData(quote))}
              />
            ))}
          </>
        ) : (
          !!availableQuotes?.length &&
          !isFreedomSFVariation && (
            <Card customCSS={customButtonCardCSS}>
              <Button
                variant="secondary"
                onClick={changeOtherQuoteState}
                data-testid={AUTOMATION_TEST_IDS.SINGLE_QUOTE.SEE_OTHER_QUOTES_BUTTON}
              >
                See Other Quotes We Found
              </Button>
            </Card>
          )
        )}

        <img css={dividerCSS} src={DividerIcon} alt="Divider" />
        <TalkToAdvisorSection customCSS={talkToAdvisor} onTalkToAdvisorClick={onTalkToAdvisorClick} />
        <div css={faqHorizontalScrollFixContainer}>
          <FAQ page={SEGMENT.PAGES.QUOTES} customCSS={faq} />
        </div>
        <QuotesCarriers customCSS={carriersList} />
      </Container>
      {getMobileDetailsPopup()}
    </div>
  );
};

export default AfSingleQuote;
