import { css } from '@emotion/react';
import { mq } from 'src/theme/variables';
import { text, text20 } from 'src/theme/typography';

export const container = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const header = css`
  display: flex;
  flex-basis: 100%;
  flex-shrink: 0;
  align-items: center;
  ${text};
  font-weight: bold;
  margin-bottom: 4px;

  span {
    width: 100%;
  }

  ${mq[0]} {
    ${text20};
  }
`;

export const headerImage = css`
  max-width: 24px;
  max-height: 24px;
  margin-right: 8px;
`;

export const coverage = css`
  width: 100%;
  margin-top: 12px;
  ${mq[0]} {
    width: auto;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(50% - 33px);
    margin-top: 16px;
  }
`;
