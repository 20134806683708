import analytics from 'src/utils/analytics';
import { useEffect, useState } from 'react';
import fullStoryService from 'src/api/fullStory/fullStory.service';

const useTrackFullStorySession = (sessionGid?: string): void => {
  const [fsUrl, setFsUrl] = useState<string | null>();

  useEffect(() => {
    if (import.meta.env.PROD) {
      analytics.getFSUrl().then(url => {
        setFsUrl(url);
      });
    }
  }, []);

  useEffect(() => {
    if (sessionGid && fsUrl) {
      try {
        fullStoryService.setFullStorySessionUrl(sessionGid, fsUrl);
      } catch (error) {}
    }
  }, [sessionGid, fsUrl]);
};

export default useTrackFullStorySession;
