import { Flows } from 'src/interfaces/IPage';
import homeImg from 'src/assets/svg/flow-card-icons/home.svg';
import autoImg from 'src/assets/svg/flow-card-icons/auto.svg';
import homeAutoImg from 'src/assets/svg/flow-card-icons/home-auto.svg';
import { FlowCardInfo } from './LandingFlowCards.types';

export const FLOW_CARDS: Record<string, FlowCardInfo> = {
  [Flows.Home]: {
    name: 'Home Insurance',
    url: Flows.Home,
    img: homeImg,
    imgAlt: 'Home',
    position: 0
  },
  [Flows.Auto]: {
    name: 'Auto Insurance',
    url: Flows.Auto,
    img: autoImg,
    imgAlt: 'Auto',
    position: 1
  },
  [Flows.AutoBundle]: {
    name: 'Home & Auto Bundle',
    url: Flows.AutoBundle,
    img: homeAutoImg,
    imgAlt: 'Auto in garage',
    position: 2
  }
};
