import { css } from '@emotion/react';
import { colors, mq } from 'src/theme/variables';
import { text } from 'src/theme/typography';

export const wrapperCSS = css`
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${mq[0]} {
    max-width: 600px;
  }
`;

export const singleSliderWrapperCSS = css`
  padding: 8px; 0;
`;

export const sliderOverridesCSS = css`
  .MuiSlider-rail {
    height: 18px;
    border-radius: 0;
    background: ${colors.tundora};
  }
  .MuiSlider-track {
    background: ${colors.mintTulip};
    border-radius: 0;
    height: 17px;
    border-top: 1px solid ${colors.mintTulip};
    border-bottom: 1px solid ${colors.mintTulip};
  }

  .MuiSlider-thumb {
    height: 30px;
    width: 30px;
    background: ${colors.aquamarineBlue};
    border: 4px solid ${colors.tradewind};

    ${mq[0]} {
      height: 35px;
      width: 35px;
    }
  }

  .MuiSlider-markLabel {
    ${text};
    margin-top: 10px;
  }

  .MuiSlider-markActive,
  .MuiSlider-mark {
    opacity: 1;
    height: 20px;
    width: 7px;
    background-color: ${colors.white};
  }
`;

export const optionsWrapperCSS = css`
  display: flex;
  justify-content: space-between;
`;
