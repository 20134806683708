import { css, SerializedStyles } from '@emotion/react';

import { formHolder, indicatorNegativeLine } from 'src/theme/shared-styles';
import { colors, mq, typography } from 'src/theme/variables';
import { text20, text24, text32 } from 'src/theme/typography';

export const form = (hasHiddenLine: boolean, isLifeVariation?: boolean): SerializedStyles => css`
  ${!isLifeVariation && formHolder};
  ${hasHiddenLine &&
  !isLifeVariation &&
  css`
    &::before {
      ${indicatorNegativeLine};
      bottom: -120px;
      content: '';
      top: 19px;
      ${mq[0]} {
        bottom: -200px;
      }
    }
    // Workaround for https://bugs.chromium.org/p/chromium/ssissues/detail?id=1150774
    &::after {
      ${indicatorNegativeLine};
      bottom: -120px;
      content: '';
      left: 0;
      opacity: 0;
      top: 19px;

      ${mq[0]} {
        bottom: -200px;  
      }
    }
  }`}

  ${isLifeVariation &&
  css`
    appearance: none;
    border: none;
    margin: 0;
    position: relative;
  `}
`;

export const scrollPoint = css`
  position: absolute;
  top: calc(var(--header-height) * -1 - 20px);
  ${mq[0]} {
    top: calc(var(--header-height) * -1);
  }
`;

export const dimmed = css`
  opacity: 0.5;
  pointer-events: none;
`;

export const withMargin = css`
  margin-bottom: 12px;
  ${mq[0]} {
    margin-bottom: 20px;
  }
`;

export const subHeading = css`
  ${text20};
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 12px;
  margin-top: 0;
  ${mq[0]} {
    margin-bottom: 20px;
  }
  a {
    color: ${colors.azure};
  }
`;

export const toolTip = css`
  background-color: ${colors.warmGray};
  border: none;
  border-radius: 50%;
  color: ${colors.white};
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: ${typography.fontFamily};
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  height: 16px;
  line-height: 18px;
  margin-left: 6px;
  padding: 0;
  text-align: center;
  vertical-align: middle;
  width: 16px;
  top: -3px;
  position: relative;
  &:hover {
    background-color: ${colors.cornflower};
  }
  ${mq[0]} {
    font-size: 16px;
    height: 24px;
    line-height: 24px;
    margin-left: 12px;
    width: 24px;
    top: -5px;
  }
`;

export const heading = css`
  font-weight: 700;
  ${text24};
  ${mq[1]} {
    ${text32};
  }
`;

export const toolTipCloseBtn = css`
  margin-top: 10px;
`;

export const modalCloseBtnCSS = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;

  img {
    width: unset;
  }
`;

export const questionsWrapperCSS = (isLife: boolean): SerializedStyles => css`
  ${isLife &&
  css`
    display: flex;
    flex-direction: column;
    margin: auto;
    align-items: flex-start;
    justify-content: center;
    width: 100%;

    ${mq[0]} {
      flex-direction: row;
      align-items: baseline;
      //max-width: 90%;
      flex-wrap: wrap;
    }

    > div {
      ${mq[0]} {
        margin: 8px 15px;
      }
    }

    > div:last-of-type {
      margin-right: 0;
    }
  `}
`;

export const buttonCSS = (isDisabled: boolean, isButtonLoading: boolean): SerializedStyles => css`
  height: 44px;
  font-size: 16px;
  width: 100%;
  margin-top: 20px;

  ${mq[0]} {
    width: 172px;
    margin: 8px 15px 8px 40px;
  }

  ${isDisabled &&
  css`
    cursor: not-allowed;
    color: ${colors.azure} !important;
    border-color: ${colors.azure}!important;
    background-color: white !important;
  `}

  ${isButtonLoading &&
  css`
    color: ${colors.azure} unset;
    border-color: unset;
    background-color: ${colors.azure} !important;
  `}
`;
