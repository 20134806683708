import { css } from '@emotion/react';
import { text, text20, text24 } from 'src/theme/typography';
import { mq } from 'src/theme/variables';

export const diffContainerCSS = css`
  margin-bottom: 40px;
`;

export const diffHeadingCSS = css`
  ${text20};
  text-align: center;

  ${mq[0]} {
    ${text24};
    line-height: 32px;
  }
`;

export const diffWrapperCSS = css`
  display: flex;
  flex-wrap: wrap;
  ${text};
  text-align: center;
  justify-content: space-between;
  gap: 25px;

  h3 {
    margin: 0;
    ${text};
  }

  ${mq[0]} {
    gap: 20px;
    ${text20};

    h3 {
      ${text20};
    }
  }

  ${mq[1]} {
    gap: 10px;
  }
`;

export const diffPointCSS = css`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 0 auto;

  ${mq[0]} {
    max-width: 373px;
  }
`;

export const differenceTitleCSS = css`
  ${text20};
  font-weight: 700;
`;

export const dividerCSS = css`
  display: block;
  margin: 20px auto;
  width: 114px;

  ${mq[0]} {
    margin: 40px auto;
  }
`;
