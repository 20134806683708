import { SerializedStyles, css } from '@emotion/react';
import { mq, colors as c } from 'src/theme/variables';
import { text, text14, text20, text24, text40 } from 'src/theme/typography';
import wavyBackgroundBlue from 'src/assets/images/wavy-bg-blue.png';

export const containerCSS = css`
  text-align: center;

  ${mq[0]} {
    text-align: left;
  }
`;

export const nameCSS = css`
  ${text};
  line-height: 24px;
  margin-bottom: 4px;

  ${mq[0]} {
    ${text20};
    margin-bottom: 8px;
  }
`;

export const headerCSS = css`
  ${text24};
  font-weight: 700;

  ${mq[1]} {
    ${text40};
  }
`;

export const savingsCSS = css`
  color: ${c.green};
`;

export const headerWrapperCSS = css`
  margin: 24px 0;
  text-align: center;

  ${mq[0]} {
    max-width: 50%;
    margin: 0 60px 0 0;
    text-align: left;
  }
`;

export const benefitsTitleCSS = css`
  ${text};

  ${mq[0]} {
    ${text};
  }
`;

export const benefitsWrapperCSS = css`
  ${mq[0]} {
    margin-top: 40px;
  }
`;

export const heroSectionCSS = css`
  background-image: none;

  ${mq[0]} {
    background: url(${wavyBackgroundBlue}) 20% 100% no-repeat;
    background-size: cover;
  }
`;

export const mobileBenefitsWrapperCSS = css`
  padding: 32px 0;
`;

export const BBBIconCSS = css`
  margin-top: 20px;
  ${text14};

  ${mq[0]} {
    margin-top: 32px;
  }
`;

export const heroWrapperCSS = (alignment?: string): SerializedStyles => css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 0 0;
  align-items: center;

  ${alignment &&
  css`
    align-items: ${alignment};
  `}

  ${mq[0]} {
    flex-direction: row;
    padding: 55px 0;
  }
`;

export const modalBodyCSS = css`
  padding: 12px var(--side-paddings) !important;
  --side-paddings: 16px;

  ${mq[0]} {
    margin: auto;
    max-width: 466px;
    --side-paddings: 24px;
  }
`;

export const modalContentCSS = (isMobile?: boolean): React.CSSProperties => {
  return {
    marginTop: isMobile ? '34px' : 'auto'
  };
};

export const privacyMattersCSS = (isUserPhonePrefilled = false): SerializedStyles => css`
  background-color: ${isUserPhonePrefilled ? c.grayFive : c.blueFife};
  padding: 16px 29px 16px 24px;
  margin-top: ${isUserPhonePrefilled ? 0 : '8px'};
  max-width: 100%;
  margin-top: 7px;

  ${mq[0]} {
    max-width: 100%;
    border: ${isUserPhonePrefilled ? `1px solid ${c.mercury}` : 'none'};
    border-top: none;
    margin-left: 0;
  }
`;

export const buttonWrapperCSS = css`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 24px;
  position: relative;

  :after {
    content: '';
    width: calc(100% + var(--side-paddings) * 2);
    height: 1px;
    position: absolute;
    top: -12px;
    left: calc(var(--side-paddings) * -1);
    box-shadow: inset 0px 1px 0px #f0f0f0;
  }
`;

export const dialogTitleCSS = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
  position: relative;

  :after {
    content: '';
    width: calc(100% + var(--side-paddings) * 2);
    height: 1px;
    position: absolute;
    bottom: 12px;
    left: calc(var(--side-paddings) * -1);
    box-shadow: inset 0px -1px 0px #f0f0f0;
  }

  h2 {
    margin: 0;
    ${text24};
  }

  svg {
    cursor: pointer;
    path {
      stroke: ${c.black};
    }
  }
`;
