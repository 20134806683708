import { IQuotesGroup, IQuote } from 'src/interfaces/quotesView/IQuote';

type UseRecommendedQuoteResponse =
  | {
      bestQuote: IQuote;
      alternativeQuotes: IQuote[];
    }
  | undefined;

const useRecommendedQuotes = (quotesGroups: IQuotesGroup[] | undefined): UseRecommendedQuoteResponse => {
  if (!quotesGroups) {
    return undefined;
  }

  const quotes = quotesGroups.map(({ quotes }) => quotes[0]);

  return {
    bestQuote: quotes[0],
    alternativeQuotes: [quotes[1], quotes[2]].filter(Boolean)
  };
};

export default useRecommendedQuotes;
