export const getAnnualMileage = (x: number): number => {
  switch (true) {
    case x <= 6000:
      return 9000;
    case x <= 7500:
      return 10000;
    case x <= 10000:
      return 12000;
    default:
      return Math.round(x / 100) * 100;
  }
};
