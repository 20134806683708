export enum PropertyTypeAnswers {
  Yes = 'yes',
  No = 'no'
}

export type PropertyData = Record<PropertyType, PropertyTypeAnswers | null>;

export enum PropertyType {
  OpenClaims = 'property_has_open_claims',
  IsUsedForBusiness = 'property_is_used_for_business',
  HasPool = 'property_has_pool',
  PoolAboveGround = 'property_has_pool_above_ground',
  PoolHasFence = 'property_pool_has_fence',
  PoolHasDivingBoard = 'property_pool_has_diving_board',
  PoolHasSlide = 'property_pool_has_slide',
  HasTrampoline = 'property_has_trampoline',
  TrampolineSecured = 'property_trampoline_secured',
  TrampolineHasFence = 'property_trampoline_has_fence',
  TrampolineHasNet = 'property_trampoline_has_net',
  HeatingHasWood = 'property_heating_secondary_source_is_solid',
  HeatingProfessional = 'property_heating_secondary_professional',
  HasCircuitBreakers = 'property_has_circuit_breakers',
  HasDog = 'person_has_dog',
  BiteHistory = 'person_dog_has_bite_history'
}

export const propertyDataStore: PropertyData = {
  property_has_open_claims: null,
  property_is_used_for_business: PropertyTypeAnswers.No,
  property_has_pool: PropertyTypeAnswers.No,
  property_has_pool_above_ground: PropertyTypeAnswers.No,
  property_pool_has_fence: PropertyTypeAnswers.No,
  property_pool_has_diving_board: PropertyTypeAnswers.No,
  property_pool_has_slide: PropertyTypeAnswers.No,
  property_has_trampoline: PropertyTypeAnswers.No,
  property_trampoline_has_fence: PropertyTypeAnswers.No,
  property_trampoline_has_net: PropertyTypeAnswers.No,
  property_heating_secondary_source_is_solid: PropertyTypeAnswers.No,
  property_heating_secondary_professional: PropertyTypeAnswers.No,
  property_has_circuit_breakers: PropertyTypeAnswers.No,
  person_has_dog: null,
  person_dog_has_bite_history: PropertyTypeAnswers.No,
  property_trampoline_secured: PropertyTypeAnswers.No
};

export const propertyQuestionCheckboxes = [
  {
    label: 'I run a business out of my home',
    value: PropertyType.IsUsedForBusiness
  },
  {
    label: 'My home has a pool',
    value: PropertyType.HasPool,
    subQuestions: [
      { value: PropertyType.PoolAboveGround, label: 'It is above ground' },
      { value: PropertyType.PoolHasFence, label: 'It has a fence that is at least 4 feet tall' },
      { value: PropertyType.PoolHasDivingBoard, label: 'It has a diving board' },
      { value: PropertyType.PoolHasSlide, label: 'It has a slide' }
    ]
  },
  {
    label: 'My home has a trampoline',
    value: PropertyType.HasTrampoline,
    subQuestions: [
      { value: PropertyType.TrampolineSecured, label: 'It is secured to the ground' },
      { value: PropertyType.TrampolineHasFence, label: 'It is fenced in' },
      { value: PropertyType.TrampolineHasNet, label: 'It has a safety net' }
    ]
  },
  {
    label: 'My home has a wood or pellet stove as a secondary heat source',
    value: PropertyType.HeatingHasWood,
    subQuestions: [{ value: PropertyType.HeatingProfessional, label: 'It was professionally installed' }]
  },
  {
    label: 'My home’s electrical system has not been upgraded with circuit breakers',
    value: PropertyType.HasCircuitBreakers
  }
];
