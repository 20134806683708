import { QueryObserverResult, useQuery } from 'react-query';
import api from 'src/api/api';
import { IQuote } from 'src/interfaces/quotesView/IQuote';

const useQuote = (personGid: string | null, quoteGid: string | null): QueryObserverResult<IQuote> => {
  return useQuery(
    ['quotes_view_quote', personGid, quoteGid],
    () => api.get(`/api/quote_pdf/people/${personGid}/quotes/${quoteGid}`),
    {
      enabled: !!personGid && !!quoteGid
    }
  );
};

export default useQuote;
