import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { DefaultLocation, Flows } from 'src/interfaces/IPage';
import BaseSession from './BaseSession/BaseSession';
import AllFunnelLayout from 'src/layouts/AllFunnelLayout/AllFunnelLayout';
import SavingsLayout from 'src/layouts/SavingsLayout/SavingsLayout';
import DisclosureLayout from 'src/layouts/DisclosuresLayout/DisclosuresLayout';
import LifeLayout from 'src/layouts/LifeLayout/LifeLayout';
import LookupLayout from 'src/layouts/LookupLayout/LookupLayout';
import ROUTES from 'src/constants/routes';

const Session: React.FC = () => {
  const { flow } = useParams() as DefaultLocation;

  const getSession = () => {
    switch (flow) {
      case Flows.AllFunnel:
        return <AllFunnelLayout />;
      case Flows.Savings:
        return <SavingsLayout />;
      case Flows.Lookup:
        return <LookupLayout />;
      case Flows.Accord:
        return <DisclosureLayout />;
      case Flows.Life:
        return <LifeLayout />;
      case Flows.Florida:
      case Flows.Home:
      case Flows.Auto:
      case Flows.AutoBundle:
        return <BaseSession />;
      default:
        return <Navigate to={ROUTES.PROBLEM} />;
    }
  };

  return getSession();
};

export default Session;
