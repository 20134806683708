import { QueryObserverResult, useQuery } from 'react-query';
import api from 'src/api/api';
import { IPerson } from 'src/interfaces/quotesView/IPerson';

const usePerson = (personGid: string | null): QueryObserverResult<IPerson> => {
  return useQuery(['quotes_view_person', personGid], () => api.get(`/api/quote_pdf/people/${personGid}`), {
    enabled: !!personGid
  });
};

export default usePerson;
