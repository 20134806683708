/** @jsxImportSource @emotion/react */

import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Answers } from 'src/interfaces/IAnswer';
import Question from 'src/questionsForm/components/Question/Question';
import { formCSS, submitErrorCSS } from './SessionTimeoutForm.style';
import { SESSION_TIMEOUT_INPUTS, SESSION_TIMEOUT_FORM_ID } from './SessionTimeoutForm.utils';
import errorIcon from 'src/assets/svg/error-icon.svg';
import queryString from 'query-string';
import { sessionService } from 'src/api/session';
import { useParams } from 'react-router-dom';
import { DefaultLocation } from 'src/interfaces/IPage';
import { useCustomNavigate } from 'src/hooks/useCustomNavigate';
import { SessionUnlock, SessionUnlockStatus } from 'src/interfaces/session.types';
import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';
import { SessionTimeoutFormProps } from './SessionTimeoutForm.types';
import { UNLOCK_ATTEMPTS_LIMIT } from 'src/components/SessionTimeout/SessionTimeout.utils';

const SessionTimeoutForm: React.FC<SessionTimeoutFormProps> = ({ setForeverLocked }) => {
  const { gid, flow } = useParams() as DefaultLocation;
  const navigate = useCustomNavigate();
  const { handleSubmit } = useFormContext();
  const [err, setErr] = useState<SessionUnlock>();

  const onSubmit = async (data: Answers) => {
    try {
      const response = await sessionService.unlockSession(gid, queryString.stringify(data));

      if (response.status === SessionUnlockStatus.Invalid) {
        response.locked_attempts === UNLOCK_ATTEMPTS_LIMIT && setForeverLocked(response);

        setErr(response);
        analytics.track(SEGMENT.INACTIVE_SESSION_UNSUCCESS_ATTEMPT, gid, flow);
        return;
      }

      analytics.track(SEGMENT.INACTIVE_SESSION_UNLOCKED, gid, flow);
      navigate(`/${flow}/${gid}`, { replace: true });
    } catch (err) {}
  };

  const renderError = () => {
    const parsedLockMessage = err?.message!.split('{count}');

    return (
      <div css={submitErrorCSS}>
        <img src={errorIcon} alt="Error icon" />
        <div>
          {parsedLockMessage?.[0]}
          <span>{err?.remain_attempts}</span>
          {parsedLockMessage?.[1]}
        </div>
      </div>
    );
  };

  return (
    <>
      {err && renderError()}
      <form onSubmit={handleSubmit(onSubmit)} id={SESSION_TIMEOUT_FORM_ID} css={formCSS}>
        {SESSION_TIMEOUT_INPUTS.map(input => (
          <Question {...input} key={input.key_name} />
        ))}
      </form>
    </>
  );
};

export default SessionTimeoutForm;
