/** @jsxImportSource @emotion/react */
import React from 'react';

import { useParams } from 'react-router-dom';

import { faq as faqCSS, contentWrapperCSS, headingCSS, accordionCSS, accordionKinWrapper } from './FAQ.style';
import FAQConstants from './FAQ.constants';

import SEGMENT from 'src/constants/segment';
import analytics from 'src/utils/analytics';

import Accordion from 'src/shared/components/Accordion/Accordion';
import noop from 'src/utils/noop';
import { FAQProps } from './FAQ.types';

import { DefaultLocation } from 'src/interfaces/IPage';
import { AccordionItem } from 'src/shared/components/Accordion/Accordion.types';

const FAQKin: React.FC<FAQProps> = ({
  page,
  customCSS,
  onFaqClick = noop,
  heading = 'Quick questions we can answer',
  accordionItems = []
}) => {
  const { flow, gid } = useParams() as DefaultLocation;

  const onAccordionClick = ({ title }: AccordionItem, ordinal: number) => {
    analytics.track(SEGMENT.FAQ_CLICKED, gid, flow, {
      ordinal,
      question_text: title,
      page
    });

    onFaqClick({ title, ordinal });
  };

  return (
    <section css={[faqCSS, customCSS]}>
      <h2 css={headingCSS}>{heading}</h2>

      <div css={contentWrapperCSS}>
        <img
          src="https://maticinsurance.sirv.com/customer-experience/office-with-plane.png"
          alt="Agents Working"
          loading="lazy"
        />

        <div css={accordionKinWrapper}>
          <Accordion
            customCSS={accordionCSS}
            items={accordionItems?.length > 0 ? accordionItems : FAQConstants.items}
            onChange={onAccordionClick}
          />
        </div>
      </div>
    </section>
  );
};

export default FAQKin;
