/** @jsxImportSource @emotion/react */
import React, { Fragment } from 'react';

import { useParams } from 'react-router-dom';

import useDisclosures from 'src/api/disclosures/useDisclosures';
import templater from 'src/utils/stringTemplater';
import { DefaultLocation } from 'src/interfaces/IPage';
import { consentCSS, paragraphCSS } from './Consent.style';
import { ConsentProps } from './Consent.types';
import { Disclosure, DisclosureKind } from 'src/interfaces/disclosures.types';
import SERVICERS from 'src/constants/servicers';
import useConfig from 'src/api/config/useConfig';
import CustomTCPAConsent from 'src/components/CustomTCPAConsent/CustomTCPAConsent';
import SEGMENT from 'src/constants/segment';
import { MATIC_LICENSE_DISCLAIMER } from 'src/constants/hardcoded';

const mapDisclosures = (disclosures: Disclosure[], CTAText: string | null = null, agentPhone: string | undefined) =>
  disclosures?.map((disclosure, i) => {
    const CTAReplaced = templater(disclosure.content, { '{cta_text}': CTAText || 'Get Your Quotes' });
    const ContactPhoneReplaced = templater(CTAReplaced, { '{agent_phone}': agentPhone }).split(
      new RegExp(/\{\{(\w*)\}\}/g)
    );

    return (
      <p css={paragraphCSS} key={i}>
        {ContactPhoneReplaced.map((t, y) => (
          <span key={y} dangerouslySetInnerHTML={{ __html: t }} />
        ))}
      </p>
    );
  });

// TODO refactor Need to avoid using disclosures and config hooks here CX-3352
const Consent: React.FC<ConsentProps> = ({ customCSS, disclosures = [], CTAText }) => {
  const { gid } = useParams() as DefaultLocation;
  const { data: config } = useConfig(gid);
  const { data } = useDisclosures(gid);
  const agentPhone = config?.partner.agent_phone;
  const nonTCPADisclosures = data?.disclosures?.filter((disclosure: Disclosure) => {
    return disclosure.kind !== DisclosureKind.CX_TCPA;
  });

  const TCPADisclosures = data?.disclosures?.filter((disclosure: Disclosure) => {
    return disclosure.kind === DisclosureKind.CX_TCPA;
  });

  const disclosureData = disclosures?.length > 0 ? disclosures : nonTCPADisclosures || [];

  const shouldAppendMrCooperDisclaimer =
    [SERVICERS.MR_COOPER, SERVICERS.MR_COOPER_NRZ].includes(config?.partner?.key as string) &&
    config?.partner.partner_disclaimer.length;

  return (
    <div css={[consentCSS, customCSS]} data-testid="consent">
      {mapDisclosures(disclosureData, CTAText, agentPhone)}
      {!!TCPADisclosures?.length &&
        !disclosures.length &&
        TCPADisclosures.map((_disclosure, i) => {
          return (
            <div css={paragraphCSS} key={i}>
              <CustomTCPAConsent
                location={SEGMENT.PAGES_KEY.QUESTIONS}
                shouldHideIcon
                shouldRenderMaticPoliciesDisclosure={false}
                customCSS={paragraphCSS}
                buttonLabel={CTAText || 'Get Your Quotes'}
              />
              {MATIC_LICENSE_DISCLAIMER}
            </div>
          );
        })}
      {shouldAppendMrCooperDisclaimer && (
        <Fragment>
          {config?.partner?.partner_disclaimer.map((t, i) => (
            <p css={paragraphCSS} key={i} dangerouslySetInnerHTML={{ __html: t }} />
          ))}
        </Fragment>
      )}
    </div>
  );
};
export default Consent;
