import { css } from '@emotion/react';

import { mq } from 'src/theme/variables';
import { text12 } from 'src/theme/typography';

export const benefits = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${mq[1]} {
    flex-direction: row;
    margin-top: 40px;
  }
`;

export const benefitsItem = css`
  align-items: center;
  display: flex;
  margin-top: 8px;

  ${mq[1]} {
    display: block;
    margin-top: 0;
    max-width: 185px;
    text-align: center;
  }
`;

export const imgWrapper = css`
  align-items: center;
  display: flex;
  flex: 0 0 60px;
  height: 60px;
  justify-content: center;
  text-align: center;
  width: 60px;

  ${mq[1]} {
    flex: 0 0 120px;
    height: 120px;
    justify-content: center;
    margin: auto;
    width: 120px;
  }
`;

export const img = css`
  max-height: 100%;
  max-width: 100%;
`;

export const title = css`
  font-weight: bold;
  margin-top: 16px;
`;

export const description = css`
  ${text12};
  margin-top: 4px;
`;

export const info = css`
  margin-left: 20px;

  ${mq[1]} {
    margin-left: 0;
  }
`;
