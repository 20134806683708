import { css, SerializedStyles } from '@emotion/react';
import { text } from 'src/theme/typography';

export const groupCSS = (first?: boolean): SerializedStyles => css`
  margin-top: ${first ? '0' : '32px'};
`;

export const buttonCSS = css`
  ${text};
  font-weight: bold;
  margin-top: 16px;
  margin-bottom: 4px;
`;
