import { css } from '@emotion/react';
import { colors as c } from 'src/theme/variables';

export const textHighlightCSS = css`
  color: ${c.warmGray};
  padding-left: 22px;
  position: relative;

  &::before {
    border-left: 2px solid ${c.warmGray};
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    top: 0;
  }

  p {
    margin-bottom: 8px;
  }

  a {
    color: ${c.warmGray};
  }
`;
