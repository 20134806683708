import { SerializedStyles, css } from '@emotion/react';
import { AnchorTypeT, CustomTooltipCSSProps } from './CustomChartTooltip';

const anchors: Record<AnchorTypeT, (left: number) => SerializedStyles> = {
  right: () => css`
    border-width: 6px 0 6px 6px;
    border-color: transparent transparent transparent #156eea;
    right: -6px;
    top: 11px;
  `,
  bottom: (left: number) => css`
    bottom: -6px;
    left: ${left}px;
    border-width: 6px 6px 0 6px;
    border-color: #156eea transparent transparent transparent;
  `,
  left: () => css`
    border-width: 6px 6px 6px 0;
    border-color: transparent #156eea transparent transparent;
    left: -6px;
    top: 11px;
  `
};

export const customTooltipCSS = ({
  left,
  top,
  anchorPosition,
  anchorType,
  width
}: CustomTooltipCSSProps): SerializedStyles => css`
  padding: 5px;
  background: #156eea;
  color: white;
  font-size: 12px;
  font-weight: 700;
  width: fit-content;
  position: absolute;
  left: ${left}px;
  top: ${top}px;
  z-index: 1;
  padding: 8px;
  border-radius: 2px;

  ${!!width &&
  css`
    min-width: ${width}px;
  `}

  :after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;

    ${anchors[anchorType](anchorPosition)}
  }
`;
export const historicalPremiumChartWrapperCSS = css`
  position: relative;
  padding-bottom: 29px;
`;

export const historicalChartFakePaddingCSS = css`
  height: 30px;
  width: 100%;
  background-color: #156eea;
  opacity: 0.2;
`;
