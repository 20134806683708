/** @jsxImportSource @emotion/react */
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';

import { useForm, FormProvider, useFormContext } from 'react-hook-form';

import { useLocation, useParams } from 'react-router-dom';

import PageHeader from 'src/components/PageHeader/PageHeader';
import Navigation from 'src/components/Navigation/Navigation';
import QuestionsForm from 'src/components/QuestionsForm/QuestionsForm';
import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';
import Page from 'src/components/Page/Page';
import { DefaultLocation, Flows, Entity } from 'src/interfaces/IPage';
import { Answers } from 'src/interfaces/IAnswer';
import useSession from 'src/api/session/useSession';

import {
  contentCSS,
  formCSS,
  leftColumnCSS,
  navigationCSS,
  questionsHeaderCSS,
  skipButtonCSS,
  buttonWrapperCSS
} from './QuestionsLayout.style';
import { visuallyHidden } from 'src/theme/helpers';
import { fromAddress } from 'react-geocode';
import { getDefaultValueString } from 'src/questionsForm/components/Question/AddressQuestion/Address.utils';
import { AddressAnswer } from 'src/interfaces/IQuestion';
import useResponsive from 'src/hooks/useResponsive';
import Button from 'src/kit/Button/Button';
import { useCustomNavigate } from 'src/hooks/useCustomNavigate';
import { useMutateSession } from 'src/api/session';
import { SkipToHomeQuotesButtonProps, QuestionsWrapperProps } from './QuestionsLayout.types';
import useQuestions from 'src/api/questions/useQuestions';
import { AUTOMATION_TEST_IDS } from 'src/constants/automationTestIds';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useConfig } from 'src/api/config';
import FullPageLoader from 'src/shared/components/FullPageLoader/FullPageLoader';
import useTrackFullStorySession from 'src/hooks/useTrackFullStorySession';
import useTrackAdBlocker from 'src/hooks/useTrackAdBlocker';
import queryString from 'query-string';
import { validLicensePattern } from 'src/utils/driversLicenseValidation';
import { FEATURE_TOGGLES_IDS_ENUM as FT } from 'src/interfaces/experiment.types';
import useFeatureToggle from 'src/hooks/useFeatureToggle';

const emptyObject = {};

const SkipToHomeQuotesButton: React.FC<SkipToHomeQuotesButtonProps> = ({ flow, gid }) => {
  const { getValues } = useFormContext();
  const navigate = useCustomNavigate();
  const { mutateAsync: mutateSession, isLoading } = useMutateSession(flow, gid);

  const onSkipButtonClick = () => {
    analytics.track(SEGMENT.SKIP_TO_HOME_BUTTON_CLICKED, gid, flow);
    const answersData = { ...getValues() };

    const driversLicensesToMap = answersData.drivers as Entity[];
    driversLicensesToMap?.forEach((driver, i) => {
      if (!driver.driver_license_number || !validLicensePattern.test(driver.driver_license_number as string)) {
        delete (answersData.drivers as Entity[])[i].driver_license_number;
      }
    });
    mutateSession({
      step: 'auto_start',
      answers: { person_is_interested_in_auto_insurance: 'no', ...answersData },
      quotes_request: true
    }).then(() => {
      navigate({ pathname: `/${flow}/${gid}/interstitial` });
    });
  };

  return (
    <Button
      isLoading={isLoading}
      customCSS={skipButtonCSS}
      onClick={onSkipButtonClick}
      variant="secondary"
      data-testid={AUTOMATION_TEST_IDS.SKIP_TO_HOME_QUOTES_BUTTON}
    >
      Skip To Home Quotes →
    </Button>
  );
};

const QuestionsWrapper: React.FC<QuestionsWrapperProps> = React.memo(({ children, CSTPhone }) => {
  const { gid, flow } = useParams() as DefaultLocation;
  const { data: questions } = useQuestions(gid);
  const shouldCheckAddress = useRef(true);
  const answers = questions?.answers || (emptyObject as Answers);
  const [contactPhone, setContactPhone] = useState('');

  useTrackFullStorySession(gid);
  useTrackAdBlocker(gid);

  const defaultValues: Answers = useMemo(
    () => ({ ...answers, ...(shouldCheckAddress.current && { property_address: emptyObject }) }),
    [answers]
  );
  const methods = useForm({
    defaultValues,
    shouldUnregister: false,
    shouldFocusError: false
  });

  useEffect(() => {
    methods.reset(defaultValues);
  }, [defaultValues, methods]);

  useEffect(() => {
    const address = answers.property_address;

    if (answers?.person_is_policyholder === 'yes' && CSTPhone) {
      setContactPhone(CSTPhone);
    } else if (answers?.person_is_policyholder === 'no' && contactPhone) {
      setContactPhone('');
    }

    if (address && Object.keys(address as AddressAnswer).length && shouldCheckAddress.current) {
      (async () => {
        try {
          await fromAddress(getDefaultValueString(address));
          methods.setValue('property_address', address);
        } catch (e) {
          methods.setValue('property_address', {});
        } finally {
          shouldCheckAddress.current = false;
        }
      })();
    }
  }, [methods, answers, CSTPhone, contactPhone]);

  return (
    <Fragment>
      {flow === Flows.Home && (
        <PageHeader page={SEGMENT.PAGES_KEY.QUESTIONS} customCSS={questionsHeaderCSS(true)} phone={contactPhone} />
      )}
      <FormProvider {...methods}>{children}</FormProvider>
      {/* {import.meta.env.DEV && <DevTool control={methods.control} />} */}
    </Fragment>
  );
});

QuestionsWrapper.displayName = 'QuestionsWrapper';

const PAGE_TITLE = 'Matic Insurance Instant Quotes Form';

const QuestionsLayout: React.FC = () => {
  const { flow, gid } = useParams() as DefaultLocation;
  const { data: session } = useSession(flow, gid);
  const { data: config, isLoading: isConfigLoading } = useConfig(gid);
  const shouldRenderSkipButton = !!session?.render_skip_to_home_quotes_button;
  const { isMobile } = useResponsive();
  const { search } = useLocation();

  const { source, source_placement } = queryString.parse(search.replace('?', ''));
  const flowStartedAlreadyTracked = useRef(false);
  const { isTestVaritionA: directSalesToDigitalProfileTestVariationA } = useFeatureToggle(
    FT.DIRECT_SALES_TO_DIGITAL_PROFILE_STEP1
  );

  useEffect(() => {
    document.title = PAGE_TITLE;

    analytics.page(SEGMENT.PAGES.QUESTIONS, {
      flow_type: flow,
      session_gid: gid
    });
  }, [flow, gid]);

  useEffect(() => {
    if (!flowStartedAlreadyTracked.current && flow !== Flows.AllFunnel) {
      analytics.track(SEGMENT.FLOW_STARTED, gid, flow, {
        ...(source && { source }),
        ...(source_placement && { placement: source_placement })
      });
      flowStartedAlreadyTracked.current = true;
    }
  }, [gid, flow, source, source_placement]);

  if (isConfigLoading) {
    return <FullPageLoader />;
  }

  const renderLeftColumn = () => {
    return (
      <nav css={[leftColumnCSS, navigationCSS]}>
        {!directSalesToDigitalProfileTestVariationA && <Navigation />}
        {!isMobile && shouldRenderSkipButton && <SkipToHomeQuotesButton gid={gid} flow={flow} />}
      </nav>
    );
  };

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={config?.integrations?.recaptcha?.site_key_v3 ?? ''}
      container={{
        parameters: {
          badge: 'bottomright' // optional, default undefined
        }
      }}
    >
      <Page>
        {flow !== Flows.Home && <PageHeader page={SEGMENT.PAGES_KEY.QUESTIONS} customCSS={questionsHeaderCSS()} />}
        <main css={contentCSS}>
          <QuestionsWrapper CSTPhone={config?.partner?.cst_phone}>
            {renderLeftColumn()}
            <div css={formCSS(isMobile && shouldRenderSkipButton)}>
              <h1 css={visuallyHidden}>{PAGE_TITLE}</h1>
              {isMobile && shouldRenderSkipButton && (
                <div css={buttonWrapperCSS}>
                  <SkipToHomeQuotesButton gid={gid} flow={flow} />
                </div>
              )}
              <QuestionsForm />
            </div>
          </QuestionsWrapper>
        </main>
      </Page>
    </GoogleReCaptchaProvider>
  );
};

export default QuestionsLayout;
