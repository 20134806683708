import { css } from '@emotion/react';

import { mq } from './variables';

export const text12 = css`
  font-size: 12px;
  line-height: 20px;
`;

export const text14 = css`
  font-size: 14px;
  line-height: 18px;
`;

export const text = css`
  font-size: 16px;
  line-height: 20px;
`;

export const text20 = css`
  font-size: 20px;
  line-height: 28px;
`;

export const text24 = css`
  font-size: 24px;
  line-height: 34px;
`;

export const text32 = css`
  font-size: 32px;
  line-height: 44px;
`;

export const text40 = css`
  font-size: 40px;
  line-height: 52px;
`;

export const text48 = css`
  font-size: 48px;
  line-height: 68px;
`;

export const headingXL = css`
  font-weight: 700;
  ${text32};
  ${mq[0]} {
    ${text40};
  }
`;

export const headingL = css`
  font-weight: 700;
  ${text24};
  ${mq[0]} {
    ${text32};
  }
`;

export const headingM = css`
  font-weight: 700;
  ${text};
  ${mq[0]} {
    ${text24}
  }
`;

export const hiddenSM = css`
  display: none;
  ${mq[0]} {
    display: block;
  }
`;

export const hiddenMD = css`
  display: block;
  ${mq[0]} {
    display: none;
  }
`;
