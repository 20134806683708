import { css } from '@emotion/react';
import { borderRadius, boxShadow, colors, mq } from 'src/theme/variables';
import { text, text14, text20 } from 'src/theme/typography';

export const assistanceCSS = css`
  display: flex;
  border: 1px solid ${colors.silver};
  border-radius: ${borderRadius}px;
  box-shadow: ${boxShadow.container};
  padding: 16px 12px;
  flex-direction: column;
  align-items: center;

  ${mq[0]} {
    flex-direction: row;
    padding: 32px;
  }
`;

export const imageCSS = css`
  display: block;
  width: 96px;
  height: 96px;
  flex-shrink: 0;
  margin-right: 20px;
`;

export const assistanceContentCSS = css`
  ${text14};

  ${mq[0]} {
    ${text};
    display: flex;
  }
`;

export const assistanceTextCSS = css`
  margin-bottom: 20px;

  ${mq[0]} {
    text-align: left;
    max-width: 268px;
    margin-right: 44px;
    margin-bottom: 0;
  }

  ${mq[1]} {
    margin-right: 76px;
  }
`;

export const assistanceHeadingCSS = css`
  ${text};
  font-weight: bold;
  margin-bottom: 4px;

  ${mq[0]} {
    ${text20};
  }
`;

export const assistanceDescriptionCSS = css`
  color: ${colors.warmGray100};
`;

export const assistanceContactsCSS = css`
  ${mq[0]} {
    flex-shrink: 0;
  }
`;

export const contactsHeadingCSS = css`
  ${text};
  font-weight: bold;
  color: ${colors.dove};
  margin-bottom: 8px;

  ${mq[0]} {
    ${text20};
  }
`;

export const linkCSS = css`
  display: flex;
  align-items: center;
  border-bottom: unset;
  font-weight: bold;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const contactIconCSS = css`
  width: 20px;
  color: ${colors.dove};
  margin-right: 8px;
`;

export const phoneIconCSS = css`
  transform: rotate(-112.86deg);
`;
