import { HomeQuote, ICurrentPolicy } from 'src/interfaces/IQuotes';
import { Flows } from 'src/interfaces/IPage';

export interface ComparisonModalProps {
  gid: string;
  flow: Flows;
  isOpen: boolean;
  quote: HomeQuote;
  currentPolicy: ICurrentPolicy;
  onModalClose: () => void;
  onQuoteSelect: (quote: HomeQuote, ordinal: number) => void;
}

export interface CoveragesComponentsProps {
  quote: HomeQuote;
  currentPolicy: ICurrentPolicy;
}

export enum CoverageHomeKeys {
  Dwelling = 'dwelling',
  OtherStructures = 'other_structures',
  PersonalProperty = 'personal_property',
  MedicalPayments = 'medical_payments',
  PersonalLiability = 'personal_liability',
  LossOfUse = 'loss_of_use'
}

export type CoverageInfoHash = {
  [key in CoverageHomeKeys]: string;
};
