/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';

import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import {
  checkBoxWrapperCSS,
  mainCheckBoxCSS,
  checkBoxCSS,
  borderCSS
} from 'src/components/EditInfoSideModal/EditInfoSideModal.style';
import { PropertyTypeAnswers } from './../EditInfoScheme';
import { CheckBoxesProps } from './../EditInfoSideModal.types';

export const CheckBoxes: React.FC<CheckBoxesProps> = ({ item, onPropertyUpdate }) => {
  const [answer, setAnswer] = useState<boolean>(false);
  const [shouldRenderExtendedQuestion, setShouldRenderExtendedQuestion] = useState(false);

  const handleChange = (value: boolean) => {
    setAnswer(value);
    onPropertyUpdate(item.value, value ? PropertyTypeAnswers.Yes : PropertyTypeAnswers.No);
  };

  useEffect(() => {
    if (answer) {
      setShouldRenderExtendedQuestion(true);
    } else {
      setShouldRenderExtendedQuestion(false);
    }
  }, [answer]);

  return (
    <FormControl css={[checkBoxWrapperCSS, borderCSS(answer)]}>
      <FormControlLabel
        css={mainCheckBoxCSS(shouldRenderExtendedQuestion && !!item?.subQuestions?.length)}
        onChange={(_ev, value) => handleChange(value)}
        control={<Checkbox />}
        label={item.label}
      />
      {shouldRenderExtendedQuestion && item?.subQuestions?.length && (
        <div>
          {item?.subQuestions.map((item, i) => (
            <FormControlLabel
              key={i}
              css={checkBoxCSS}
              label={item.label}
              onChange={(_event, value) =>
                onPropertyUpdate(item.value, value ? PropertyTypeAnswers.Yes : PropertyTypeAnswers.No)
              }
              control={<Checkbox />}
            />
          ))}
        </div>
      )}
    </FormControl>
  );
};
