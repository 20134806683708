import { mq, colors } from 'src/theme/variables';
import { text, text20 } from 'src/theme/typography';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const inputWrapperCSS = css`
  border: 0;
  ${text};

  ${mq[0]} {
    ${text20}
  }

  .checkbox-input-mark {
    left: 0;
  }
`;

export const disclosuresCheckboxCSS = css`
  border: 0;
  padding: 0 0 0 12px;
  margin-bottom: 4px;

  ${mq[0]} {
    justify-content: flex-start;
  }

  &:before {
    display: none;
  }
`;

const ConsentWrapperCSS = styled.div`
  position: absolute;
  background-color: ${colors.white};
  bottom: 30px;
  text-align: left;
  box-shadow: 0 4px 15px rgb(0 0 0 / 20%);
  visibility: hidden;

  &::after {
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    border-top: 16px solid ${colors.white};
    bottom: -12px;
    content: '';
    left: 130px;
    position: absolute;
    transform: translateX(-50%);
  }
`;

export const wrapperCSS = css`
  position: relative;
  margin: 44px 0 0px;
  height: 50px;
  cursor: pointer;

  ${mq[0]} {
    &:hover {
      ${ConsentWrapperCSS} {
        visibility: visible;
      }
    }
  }
`;

export const consentCSS = css`
  ${mq[0]} {
    margin-top: 0;
    padding: 16px;
  }
`;

export const errorCSS = css`
  text-align: left;
  margin-left: 24px;
`;

export const underlineCSS = css`
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0;
  border-bottom: 2px solid ${colors.azure};
`;
