import { css } from '@emotion/react';
import { mq, colors } from 'src/theme/variables';
import { text12 } from 'src/theme/typography';

export const cardSummaryCSS = css`
  display: flex;
`;

export const tagsCSS = css`
  display: flex;
  align-items: center;
  margin-top: -8px;
  margin-bottom: -8px;

  ${mq[1]} {
    margin-left: 24px;
  }
`;

export const tagCSS = css`
  margin-top: 8px;
`;

export const suffixCSS = css`
  font-size: 12px;
  font-weight: normal;
`;

export const tagWrapperCSS = css`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  text-align: center;

  margin-bottom: 40px;

  ${mq[0]} {
    margin-bottom: 0;
  }
`;

export const quoteCardDisclaimerCSS = css`
  ${text12};

  color: ${colors.dove};
`;
