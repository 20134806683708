import { NavigateOptions, To, useLocation, useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import searchParams from 'src/constants/searchParams';

const SEARCH_DIVIDER = '&';

export const useCustomNavigate = (): ((path: To, options?: NavigateOptions) => void) => {
  const navigate = useNavigate();
  const location = useLocation();

  return useCallback(
    (path: To, options?: NavigateOptions) => {
      const currentSearch = location?.search || '';
      const currentPath = location?.pathname || '';
      const experimentsSearch = getAllowedParamsByKeys(currentSearch, [
        searchParams.experimentKey,
        searchParams.testMode
      ]);
      const isStateDataPassed = Object.keys(options?.state ?? {}).length !== 0;

      const navigateOptions: NavigateOptions = {
        ...((isStateDataPassed || currentPath) && {
          state: {
            ...(currentPath && { prevLocation: currentPath }),
            ...(isStateDataPassed && { ...options?.state })
          }
        }),
        ...(options?.replace !== undefined && { replace: options.replace })
      };

      const navigateOptionsExist = Object.keys(navigateOptions).length !== 0;

      if (typeof path === 'string') {
        const navigateObject: To = {
          pathname: path
        };

        if (experimentsSearch.trim().length > 0) {
          navigateObject.search = experimentsSearch;
        }

        navigateOptionsExist ? navigate(navigateObject, navigateOptions) : navigate(navigateObject);
      } else {
        navigateOptionsExist ? navigate({ ...path }, navigateOptions) : navigate({ ...path });
      }
    },
    [navigate, location]
  );
};

const getAllowedParamsByKeys = (search: string, allowedKeys: string[]): string => {
  let tempSearch = '';
  search.split(SEARCH_DIVIDER).forEach(el => {
    if (allowedKeys.some(v => el.includes(v))) {
      tempSearch += el + SEARCH_DIVIDER;
    }
  });

  return tempSearch.substring(0, tempSearch.length - 1);
};
