import { css } from '@emotion/react';
import { borderRadius, colors, mq } from 'src/theme/variables';

export const formCSS = css`
  padding: 20px;
  margin-top: 8px;
  border: 1px solid ${colors.black};
  border-radius: ${borderRadius}px;
`;

export const actionsCSS = css`
  ${mq[0]} {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
`;

export const buttonCSS = css`
  width: 100%;
  margin-top: 8px;

  ${mq[0]} {
    margin-top: 0;
    width: auto;
  }
`;

export const addCSS = css`
  ${buttonCSS};
  ${mq[0]} {
    margin-left: 20px;
  }
`;
