import { css } from '@emotion/react';
import { mq } from 'src/theme/variables';

export const coverageContentWrapperCSS = css`
  margin: 42px 24px 50px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  ${mq[0]} {
    flex-direction: row;
  }

  @media print {
    flex-direction: row;
  }
`;

export const copyWrapperCSS = css`
  display: flex;
  margin-top: 22px;

  ${mq[0]} {
    max-width: 48%;
    margin-top: 12px;
  }

  @media print {
    max-width: 48%;
    margin-top: 12px;
  }
`;

export const coverageCSS = css`
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: bold;
`;

export const additionalSavingsCSS = css`
  font-size: 20px;
  font-weight: bold;
`;

export const quoteWrapperCSS = css`
  min-width: 46%;
  background-color: #ffffff;
  border: 1px solid #b2b2b2;
  border-radius: 4px;
  box-shadow: 2px 4px 0px 0px #0000001f;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  hr {
    margin: 22px 0;
  }
`;

export const quoteCardCSS = css`
  padding: 16px;
`;

export const copyItemWrapperCSS = css`
  margin-bottom: 34px;
`;

export const copyItemTitleCSS = css`
  margin-bottom: 6px;
  font-weight: bold;
  font-size: 18px;
`;

export const copyItemDescriptionCSS = css`
  color: #333333;
`;

export const carrierLogoCSS = css`
  display: block;
  margin: 24px auto;
`;

export const otherQuotesWrapperCSS = css`
  margin: 42px 24px;
`;

export const premiumWrapperCSS = css`
  background-color: #f2f2f2;
  height: 60px;
  padding: 5px;
  font-size: 18px;
  text-align: center;
`;

export const premiumCSS = css`
  font-size: 40px;
  font-weight: bold;

  sup {
    font-weight: normal;
  }
`;

export const detailsItemCSS = css`
  width: 100%;
  margin-right: 28px;
  margin-top: 12px;
  &:last-child {
    margin-right: 0;
  }

  ${mq[0]} {
    margin-right: 0;
    margin-top: 12px;
  }
`;

export const deductibleWrapperCSS = css`
  display: flex;
  justify-content: space-between;
  margin: 24px 0 6px;

  ${mq[0]} {
    margin-bottom: 60px;
  }

  @media print {
    margin-bottom: 60px;
  }
`;

export const adjustQuoteCSS = css`
  background-color: #e0edff;
  color: #333333;
  border-radius: 12px;
  padding: 16px;
  height: 100px;

  ${mq[0]} {
    font-size: 18px;
  }

  @media print {
    font-size: 18px;
  }
`;

export const otherItemsWrapperCSS = css`
  display: flex;
  justify-content: space-between;
`;

export const otherQuotesPremiumCSS = css`
  font-weight: bold;
  font-size: 24px;
`;

export const otherItemWrapperCSS = css`
  background-color: #f2f2f2;
  padding: 12px;
  min-width: 42%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    width: 90px;
  }

  ${mq[0]} {
    min-width: 45%;

    img {
      width: 140px;
    }
  }

  @media print {
    min-width: 45%;

    img {
      width: 140px;
    }
  }
`;
