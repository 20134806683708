/** @jsxImportSource @emotion/react */
import React, { Fragment } from 'react';

import { WhyTrustMaticProps } from './WhyTrustMatic.props';
import {
  defaultContainerCSS,
  headingCSS,
  factHeadingCSS,
  factWrapperCSS,
  factsWrapperCSS
} from './WhyTrustMatic.style';

import { getWhyMaticFacts } from './WhyTrustMatic.utils';

const WhyTrustMatic: React.FunctionComponent<WhyTrustMaticProps> = ({ savings, isNewRez, customCSS }) => (
  <div css={[defaultContainerCSS, customCSS]}>
    <div>
      <h2 css={headingCSS}>Why Trust Matic</h2>
      <div css={factsWrapperCSS}>
        {getWhyMaticFacts(savings, isNewRez).map(({ heading, description }, index) => (
          <div css={factWrapperCSS} key={heading}>
            <div css={factHeadingCSS}>{heading}</div>
            <div>
              {description.map((subst, j) => (
                <Fragment key={`${index}-${j}`}>
                  {j > 0 && <br />}
                  {subst}
                </Fragment>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default WhyTrustMatic;
