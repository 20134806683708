import { Asset, Coverage, HomeCoverages } from './ICoverages';
import { CoverageType } from './ICoverageTypes';

interface QuoteBase {
  gid: string;
  premium: QuotePremium;
  online_bind_url?: string | null;
  carrier: Carrier;
  digital_profile_url?: string | null;
  multi_policy_discount_percentage: number | null;
  multi_policy_discount_premium: number | null;
}

export enum QuotesResponseStatus {
  Pending = 'pending',
  InProgress = 'in_progress',
  Finished = 'finished'
}

export interface Carrier {
  name: string;
  key: string;
  logo_url: string;
  description: string;
  top_rated?: boolean;
  foundation?: string;
  rating?: string;
  location?: string;
}

export interface Vehicle {
  gid: string;
  year: number;
  make: string;
  model: string;
  coverages: Coverage[];
}

export interface AutoQuote extends QuoteBase {
  assets: Vehicle[];
}

export enum QuotePremiumType {
  Annual = 'annual',
  Semiannual = 'semiannual',
  Monthly = 'monthly'
}

export interface QuotePremium {
  type: QuotePremiumType;
  value: number;
}

export interface BundleQuote {
  best_value: boolean;
  bundle_discount: boolean;
  premium: QuotePremium;
  quotes: {
    auto: {
      gid: string;
      carrier: Carrier;
      premium: QuotePremium;
      assets: Vehicle[];
    };
    home: {
      gid: string;
      carrier: Carrier;
      premium: QuotePremium;
      coverages: HomeCoverages;
      online_bind_url?: string | null;
      digital_profile_url?: string | null;
    };
  };
}

export interface HomeQuote extends QuoteBase {
  deductible: number;
  coverages: HomeCoverages;
  bundle_only?: boolean;
  eligibility?: string | null;
  saving_value?: number | null;
  digital_profile_url?: string | null;
  benefits?: string[];
}

export interface SavingsQuote extends QuoteBase {
  coverages: HomeCoverages;
  deductible: number;
  saving_value: number;
  carrier: Carrier;
  property_address: string;
  days_till_quote_expired: number;
  bundle_only?: boolean;
}

export interface LifeQuote {
  gid: string;
  premium: QuotePremium;
  carrier: Carrier;
  eligibility: string;
  policy_name: string;
  policy_type: string;
  value_propositions?: [];
}

export interface AutoQuoteGroups {
  [CoverageType.Basic]: AutoQuote[];
  [CoverageType.Recommended]: AutoQuote[];
  [CoverageType.Premium]: AutoQuote[];
}

export enum BundleStatus {
  Success = 'success',
  AutoOnly = 'auto_only',
  Failed = 'failed',
  HomeOnly = 'home_only'
}

export enum BundleStatusToReport {
  Auto = 'auto',
  Home = 'home'
}

export interface QuotesResponse {
  status: QuotesResponseStatus;
  allow_editing_details?: boolean;
  landlord_quotes_page?: boolean;
  bundle_status?: BundleStatus | null;
  recommended_quote_savings?: number | null;
}

export interface BundleQuotesResponse extends QuotesResponse {
  quotes: BundleQuote[];
}

export interface AutoQuotesResponse extends QuotesResponse {
  assets: Asset[];
  quotes: AutoQuoteGroups;
}

export interface ICurrentPolicy {
  premium?: number;
  deductible?: number;
  dwelling?: number;
  expiration_date?: string;
  days_till_policy_expired?: number;
  carrier_name: string;
  carrier_url: string;
  loss_of_use?: number;
  medical_payments?: number;
  other_structures?: number;
  personal_liability?: number;
  personal_property?: number;
}

interface ManualHomeCarrier {
  key: string;
  logo_url: string;
  name: string;
  description: string;
}

export interface ManualQuote {
  carrier: ManualHomeCarrier;
  gid: string;
  digital_profile_url?: string | null;
}

export interface HomeQuotesResponse extends QuotesResponse {
  quotes: HomeQuote[];
  current_policy?: ICurrentPolicy;
  recommended_quote_savings: number;
  show_quotes_price?: boolean;
  manual_quotes: ManualQuote[];
}

export interface LifeManualQuote {
  carrier: ManualHomeCarrier;
  gid: string;
  policy_type?: string;
}

export interface LifeQuotesResponse extends QuotesResponse {
  quotes: LifeQuote[];
  manual_quotes: LifeManualQuote[];
  status: QuotesResponseStatus;
}

export enum BundleType {
  HomeBundle = 'home_bundle',
  AutoBundle = 'auto_bundle'
}
