import { css } from '@emotion/react';
import { mq } from 'src/theme/variables';
import { headingM } from 'src/theme/typography';

export const containerCSS = css`
  text-align: center;
`;

export const headingCSS = css`
  ${headingM};
`;

export const imageCSS = css`
  margin-bottom: 36px;
  margin-top: 36px;
  ${mq[0]} {
    margin-bottom: 48px;
    margin-top: 48px;
  }
`;
