import { css, SerializedStyles } from '@emotion/react';
import { indicatorLine } from 'src/theme/shared-styles';
import { mq, questionsForm } from 'src/theme/variables';

export const entityDetailsForm = css`
  position: relative;
  padding-bottom: ${questionsForm.formHolderBottomSpaceMobile}px;
  ${mq[0]} {
    padding-bottom: ${questionsForm.formHolderBottomSpaceDesktop}px;
  }
  &::before {
    ${indicatorLine};

    content: '';
    top: -111px;
  }
`;

export const scrollPoint = css`
  position: relative;
  top: calc(var(--header-height) * -1);
`;

export const questions = css`
  margin-top: 48px;
  ${mq[0]} {
    margin-top: 40px;
  }
  ${mq[1]} {
    margin-top: 56px;
  }
`;

export const entityGreeting = (isFirst: boolean): SerializedStyles => css`
  ${isFirst &&
  css`
    margin-top: 48px;
    ${mq[0]} {
      margin-top: 108px;
    }
    ${mq[1]} {
      margin-top: 128px;
    }
  `}
`;

export const form = (isLast: boolean): SerializedStyles => css`
  ${isLast &&
  css`
    padding-bottom: 0;
    ${mq[0]} {
      padding-bottom: 0;
    }
  `}
`;
