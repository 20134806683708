import React from 'react';
import Page from 'src/components/Page/Page';
import PageHeader from 'src/components/PageHeader/PageHeader';
import SEGMENT from 'src/constants/segment';
import Footer from 'src/components/Footer/Footer';
import LookupLanding from 'src/components/LookupLanding/LookupLanding';

const LookupLayout: React.FC = () => {
  return (
    <Page>
      <PageHeader page={SEGMENT.PAGES_KEY.LOOKUP} />
      <main>
        <LookupLanding />
      </main>
      <Footer page={SEGMENT.PAGES_KEY.LOOKUP} withConfetti />
    </Page>
  );
};

export default LookupLayout;
