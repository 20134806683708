import React from 'react';

import { Controller, RegisterOptions } from 'react-hook-form';

import { QuestionWrapper } from 'src/questionsForm/components/QuestionWrapper/QuestionWrapper';
import { FormQuestionProps } from 'src/interfaces/IQuestion';
import VehicleSelect from './VehicleSelect';

const VehicleSelectQuestion: React.FC<FormQuestionProps> = props => (
  <Controller
    name={props.key_name}
    rules={{
      ...(props.validation?.static as RegisterOptions),
      validate: value => {
        return value ? Object.keys(value).length > 0 : true;
      }
    }}
    render={({ field: { onChange, value, onBlur, ref } }) => (
      <QuestionWrapper
        {...props}
        componentRef={ref}
        inputComponent={VehicleSelect}
        onValidEntry={onChange}
        value={value}
        onBlur={onBlur}
      />
    )}
  />
);

export default VehicleSelectQuestion;
