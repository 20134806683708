import { css, SerializedStyles } from '@emotion/react';
import { mq, colors as c } from 'src/theme/variables';
import { text20, text, text24, text14, text32 } from 'src/theme/typography';

export const stepsWrapperCSS = (isCallMeNowVariation?: boolean): SerializedStyles => css`
  min-width: 205px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: baseline;

  ${mq[1]} {
    min-width: 700px;
    flex-direction: ${isCallMeNowVariation ? 'column' : 'row'};
    padding-top: 0;
  }
`;

export const headingCSS = (isCallMeNowVariation?: boolean): SerializedStyles => css`
  ${text24};
  margin-top: 0;
  margin-bottom: 20px;
  padding-top: 12px;

  ${mq[0]} {
    ${text32};
    padding-top: 0;
    margin-bottom: 24px;
  }

  ${isCallMeNowVariation &&
  css`
    text-align: center;
  `}
`;

export const stepItemCSS = (isCallMeNowVariation?: boolean): SerializedStyles => css`
  display: flex;
  min-width: 100%;

  ${mq[0]} {
    padding: 0 4px;
  }

  ${mq[1]} {
    align-items: center;
    flex: 1 1 30%;
    flex-direction: column;
    flex-direction: ${isCallMeNowVariation ? 'row' : 'column'};
    padding: 0 28px;
    min-width: auto;
  }

  ${isCallMeNowVariation &&
  css`
    img {
      width: 120px;
      margin-right: 60px;
    }
  `}
`;

export const stepWrapperCSS = (isLastElement: boolean, isCallMeNowVariation?: boolean): SerializedStyles => css`
  margin-right: 12px;
  position: relative;

  &::before {
    background-color: ${c.cheriseLight};
    bottom: 0;
    content: ${isCallMeNowVariation ? 'none' : ''};
    height: 100%;
    left: 50%;
    position: absolute;
    right: auto;
    transform: translateX(-50%);
    width: ${isLastElement ? '0' : '1px'};
  }

  &:last-child {
    &::before {
      content: none;
    }
  }

  ${mq[0]} {
    min-height: 200px;
  }

  ${mq[1]} {
    margin-right: 36px;
  }
`;

export const stepIndexCSS = css`
  ${text20};
  background-color: ${c.white};
  border: 4px solid ${c.cheriseLight};
  border-radius: 50%;
  box-sizing: border-box;
  color: ${c.black};
  position: relative;
  text-align: center;
  min-width: 40px;
  height: 40px;
  z-index: 1;
  display: inline-block;
  margin-right: 0;
  font-weight: normal;

  ${mq[0]} {
    margin-right: 12px;
  }
`;

export const mobileImgCSS = css`
  width: 120px;
  margin: 0 auto;
`;

export const imageWrapperCSS = css`
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  min-width: 105px;
  height: 150px;
  padding-right: 40px;
  margin-bottom: 20px;
  display: flex;

  ${mq[0]} {
    width: 125px;
    margin-left: 20px;
  }

  ${mq[1]} {
    padding-right: 0;
    width: 205px;
    margin-left: 0;
  }
`;

export const iconCSS = css`
  max-width: 100%;
  max-height: 100%;
`;

export const stepInfoCSS = css`
  ${mq[1]} {
    max-width: 510px;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    max-width: 100%;
  }
`;

export const stepHeadingCSS = (isCallMeNowVariation?: boolean): SerializedStyles => css`
  ${text};
  margin-bottom: 12px;
  padding-top: 4px;
  font-weight: bold;
  display: flex;
  align-items: center;

  ${mq[0]} {
    padding-top: 0;
    ${text20};

    ${isCallMeNowVariation && text24}
  }
`;

export const stepDescriptionCSS = css`
  margin-bottom: 20px;
  line-height: 24px;
  ${mq[0]} {
    line-height: 20px;
  }
`;

export const stepSubHeadingCSS = css`
  ${text14}
`;
