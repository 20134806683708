/** @jsxImportSource @emotion/react */
import React from 'react';
import { SerializedStyles } from '@emotion/react';
import { reviewCard, quoteMark, description, authorWrapper } from './GoogleReviewCard.style';

type Props = {
  text: string;
  author: string;
  customCSS?: SerializedStyles;
};

const GoogleReviewCard: React.FC<Props> = ({ text, author, customCSS }) => (
  <div css={[reviewCard, customCSS]}>
    <div css={quoteMark}>“</div>
    <div css={description}>{text}</div>
    <div css={authorWrapper}>{`~ ${author}`}</div>
  </div>
);

export default GoogleReviewCard;
