import { LocationStateKeys, LocationState } from './FloridaNoOptions.types';

export const isObjectInstanceOfLocationState = (object: unknown): object is LocationState => {
  return (
    typeof object === 'object' &&
    object !== null &&
    Object.prototype.hasOwnProperty.call(object, LocationStateKeys.UserName) &&
    Object.prototype.hasOwnProperty.call(object, LocationStateKeys.ContactPhone) &&
    Object.prototype.hasOwnProperty.call(object, LocationStateKeys.IneligibleType)
  );
};
