import { css } from '@emotion/react';
import { colors, mq } from 'src/theme/variables';
import { text20 } from 'src/theme/typography';

export const reviewCard = css`
  position: relative;
  background: ${colors.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 260px;
  padding: 58px 16px 24px;
  border: 1px solid #b3b3b3;
  border-radius: 8px;
  box-shadow: -2px 4px 0 #f2f2f2;
  box-sizing: border-box;

  ${mq[0]} {
    max-width: 348px;
    min-height: 300px;
    padding: 58px 20px 20px;
  }
`;

export const quoteMark = css`
  position: absolute;
  top: 16px;
  left: 24px;
  color: ${colors.cherise};
  font-size: 60px;
  line-height: 1;
`;

export const description = css`
  min-height: 142px;
  text-align: left;
`;

export const authorWrapper = css`
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
  color: ${colors.warmGray};
  font-size: 16px;
  font-weight: 400;
  ${mq[0]} {
    ${text20};
  }
`;
