/** @jsxImportSource @emotion/react */
import React from 'react';

import { useParams } from 'react-router-dom';

import MaticFooter from 'src/kit/Footer/Footer';
import useConfig from 'src/api/config/useConfig';
import { DefaultLocation, Flows } from 'src/interfaces/IPage';

import { SocialLinkType } from 'src/kit/Footer/types';
import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';
import { disclaimersWrapperCSS, termsAndPolicyLinkCSS } from './Footer.style';
import { SerializedStyles } from '@emotion/react';
import Anchor from 'src/kit/Anchor/Anchor';
import patternReplacer from 'src/utils/patternReplacer';
import { AF_LANDING_DISCLAIMER, HOME_DISCLAIMER } from './Footer.constants';
import { MATIC_LICENSE_DISCLAIMER } from 'src/constants/hardcoded';
import { AUTOMATION_TEST_IDS } from 'src/constants/automationTestIds';

interface Props {
  phone?: string;
  page: string;
  withConfetti?: boolean;
  customCSS?: SerializedStyles;
}

const Footer: React.FC<Props> = ({ phone, page, withConfetti, customCSS }) => {
  const { gid, flow } = useParams() as DefaultLocation;
  const { data } = useConfig(gid);

  const shouldRenderAdditionalDisclosure = [
    SEGMENT.PAGES_KEY.ALL_FUNNEL,
    SEGMENT.PAGES_KEY.SAVINGS,
    SEGMENT.PAGES_KEY.LIFE
  ].includes(page);

  const disclaimers = data?.partner?.partner_disclaimer || [];

  const shouldRenderSpecificDisclaimers = (page: string) => {
    return [
      SEGMENT.PAGES_KEY.INTERSTITIAL,
      SEGMENT.PAGES_KEY.QUOTES,
      SEGMENT.PAGES_KEY.ALL_FUNNEL,
      SEGMENT.PAGES_KEY.LOOKUP,
      SEGMENT.PAGES_KEY.SAVINGS,
      SEGMENT.PAGES_KEY.NO_OPTIONS,
      SEGMENT.PAGES_KEY.CONFIRMATION
    ].includes(page);
  };

  const renderAdditionalDisclosures = () => (
    <div css={disclaimersWrapperCSS}>
      By using the Matic website you agree to our{' '}
      <Anchor href="https://matic.com/terms-of-use/" target="_blank" rel="noreferrer" customCSS={termsAndPolicyLinkCSS}>
        Terms of Use
      </Anchor>{' '}
      and{' '}
      <Anchor
        href="https://matic.com/privacy-policy/"
        target="_blank"
        rel="noreferrer"
        customCSS={termsAndPolicyLinkCSS}
      >
        Privacy Policy
      </Anchor>
      .
    </div>
  );

  const programTerms = (index?: number) => (
    <Anchor
      key={index}
      href="https://go.matic.com/mba-opens-doors-donation"
      target="_blank"
      rel="noreferrer"
      customCSS={termsAndPolicyLinkCSS}
    >
      See program terms & conditions here
    </Anchor>
  );

  const disclaimersListToRender = () => {
    const disclaimersArray = [
      data?.partner?.partner_disclaimer,
      data?.partner?.matic_disclaimer,
      data?.partner?.matic_license_disclaimer
    ];

    const disclaimersToRender = shouldRenderSpecificDisclaimers(page)
      ? disclaimersArray
      : disclaimersArray.splice(1, 1);

    if (flow === Flows.Home) {
      disclaimersToRender.push([HOME_DISCLAIMER]);
    }

    if (page === SEGMENT.PAGES_KEY.ALL_FUNNEL) {
      disclaimersToRender.push([AF_LANDING_DISCLAIMER]);
    }

    return (
      <div data-testid={AUTOMATION_TEST_IDS.DISCLAIMERS}>
        {shouldRenderAdditionalDisclosure && renderAdditionalDisclosures()}
        {disclaimersToRender.map(disclaimer => {
          return disclaimer?.map((item, index) => (
            <div key={index} css={disclaimersWrapperCSS}>
              {patternReplacer(item, { program_terms: programTerms(index) })}
            </div>
          ));
        })}
      </div>
    );
  };

  const alternativeDisclaimersRender = () => {
    const disclaimersArray = [disclaimers, [MATIC_LICENSE_DISCLAIMER]];
    return (
      <div>
        {renderAdditionalDisclosures()}
        {disclaimersArray.map(disclaimer => {
          return disclaimer?.map((item, index) => (
            <div key={index} css={disclaimersWrapperCSS}>
              {patternReplacer(item, { program_terms: programTerms(index) })}
            </div>
          ));
        })}
      </div>
    );
  };

  const getCopy = (): string | JSX.Element => {
    switch (flow) {
      case Flows.Home:
      case Flows.Auto:
      case Flows.AllFunnel:
      case Flows.AutoBundle:
      case Flows.Savings:
      case Flows.Lookup:
      case Flows.Florida:
        return disclaimersListToRender();
      case Flows.Life:
      case Flows.Accord:
        return alternativeDisclaimersRender();
      default:
        return patternReplacer(disclaimers.join('\n\n'), { program_terms: programTerms() }) as string;
    }
  };

  const agentPhone = phone || data?.partner?.agent_phone;

  const handleSocialLinkClick = (type: SocialLinkType) => {
    const pageToReport = [SEGMENT.PAGES_KEY.ALL_FUNNEL, SEGMENT.PAGES_KEY.SAVINGS].includes(page)
      ? SEGMENT.PAGES_KEY.LANDING
      : page;

    if (type === 'phone') {
      analytics.track(SEGMENT.PHONE_CONVERSATION_INITIATED, gid, flow, {
        location: SEGMENT.LOCATIONS.FOOTER,
        page: pageToReport
      });
    }
  };

  return (
    <MaticFooter
      copy={getCopy()}
      phone={agentPhone}
      withConfetti={withConfetti}
      onSocialLinkClick={handleSocialLinkClick}
      customCSS={customCSS}
    />
  );
};

export default Footer;
