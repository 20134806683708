import { css, SerializedStyles } from '@emotion/react';
import { colors, mq } from 'src/theme/variables';
import { text, text14 } from './../../theme/typography';

export const bbbRatingCSS = (whiteStyled: boolean): SerializedStyles => css`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-top: 20px;
  width: 100%;

  ${whiteStyled &&
  css`
    color: ${colors.white};
    align-items: start;
    font-weight: 200;
    width: 285px;
    margin: 20px auto 0;
    text-align: left;
    justify-content: center;

    ${mq[0]} {
      margin: 20px 0 0;
      width: 445px;
      justify-content: left;
    }

    a {
      color: ${colors.white};
    }

    img {
      width: 80px;

      ${mq[0]} {
        width: 115px;
      }
    }
  `}
`;

export const bbbRatingLinkCSS = (whiteStyled: boolean, isSimplifiedVersion: boolean): SerializedStyles => css`
  margin-left: 10px;
  font-weight: 400;
  ${text14};

  ${mq[0]} {
    ${text};

    ${isSimplifiedVersion &&
    css`
      ${text14};
      margin-bottom: 14px;

      a {
        border-bottom: 1px solid ${colors.azure};
      }
    `}
  }

  ${whiteStyled &&
  css`
    ${mq[0]} {
      max-width: 46%;
    }

    ${mq[1]} {
      max-width: unset;
    }
  `}
`;
