/** @jsxImportSource @emotion/react */
import React from 'react';

import { CheckboxIconProps } from './CheckboxIcon.types';
import { container, icon } from './CheckboxIcon.style';
import CheckboxInputMark from 'src/assets/svg/checkbox-input-mark.svg?react';

const CheckboxIcon: React.FC<CheckboxIconProps> = ({ customCSS, checked = false, disabled = false }) => {
  return (
    <span className="checkbox-input-mark" css={[container(checked, disabled), customCSS]} data-testid="checkbox-icon">
      <CheckboxInputMark css={icon(checked)} />
    </span>
  );
};

export default CheckboxIcon;
