import React from 'react';
import { RadioFormQuestionProps } from 'src/interfaces/IQuestion';

import { QuestionWrapper } from 'src/questionsForm/components/QuestionWrapper/QuestionWrapper';
import RadioButtons from './RadioButtons';
import { QuestionLabelWrapper } from 'src/questionsForm/components/QuestionWrapper/QuestionLabel/QuestionLabel.types';

const RadioQuestion: React.FC<RadioFormQuestionProps> = props => (
  <QuestionWrapper {...props} inputComponent={RadioButtons} labelTag={QuestionLabelWrapper.Div} />
);
export default RadioQuestion;
