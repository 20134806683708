/** @jsxImportSource @emotion/react */
import React from 'react';

import { QuotesHeaderProps } from './QuotesHeader.types';
import { container, header, subheader, params, preHeaderText } from './QuotesHeader.style';

const QuotesHeader: React.FC<QuotesHeaderProps> = ({ customCSS, children, heading, subheading, preHeadingText }) => {
  return (
    <div css={[customCSS, container]}>
      {preHeadingText && <div css={preHeaderText}>{preHeadingText}</div>}
      <div css={header}>{heading}</div>
      {subheading && <div css={subheader}>{subheading}</div>}
      {children && (
        <ul css={params} data-testid="quotes-header-params">
          {children}
        </ul>
      )}
    </div>
  );
};

export default QuotesHeader;
