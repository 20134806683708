import { css } from '@emotion/react';
import { borderRadius, colors, mq } from 'src/theme/variables';
import { text, text14 } from 'src/theme/typography';

export const container = css`
  background: ${colors.cherise};
  border-bottom-right-radius: 30px;
  border-top-left-radius: ${borderRadius}px;
  box-shadow: inset 0 -4px 4px ${colors.cheriseDark};
  color: white;
  top: -1px;
  left: -1px;
  padding: 2px 10px;
  position: absolute;
  text-align: center;
  width: 140px;
  ${text14};
  ${mq[0]} {
    ${text};
    font-weight: 700;
    width: 200px;
    padding: 4px 10px;
  }
`;
