/** @jsxImportSource @emotion/react */
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import CheckmarkInCircleIcon from 'src/assets/svg/checkmark-in-circle.svg?react';
import {
  cellCSS,
  headCSS,
  headingCellCSS,
  hideContentCSS,
  nameCellCSS,
  rowCSS,
  tableCSS
} from './ComparisonTable.style';
import { LifePolicies } from './ComparisonTable.types';
import { lifeComparisonTableContent } from './ComparisonTable.content';

const ComparisonTable = () => {
  return (
    <TableContainer component={Paper} css={tableCSS}>
      <Table aria-label="Life comparison table">
        <TableHead css={headCSS}>
          <TableRow>
            <TableCell css={nameCellCSS}>
              <span css={hideContentCSS}>Name column</span>
            </TableCell>
            <TableCell align="center" css={headingCellCSS}>
              Term Life
            </TableCell>
            <TableCell align="center" css={headingCellCSS}>
              Accidental Death
            </TableCell>
            <TableCell align="center" css={headingCellCSS}>
              Whole Life
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lifeComparisonTableContent.map((row, i) => (
            <TableRow key={row.name} css={rowCSS((i + 1) % 2 === 0)}>
              <TableCell component="th" scope="row" css={nameCellCSS}>
                {row.name}
              </TableCell>
              <TableCell align="center" css={cellCSS}>
                {row.polices_include.includes(LifePolicies.TERM_LIFE) && <CheckmarkInCircleIcon />}
              </TableCell>
              <TableCell align="center" css={cellCSS}>
                {row.polices_include.includes(LifePolicies.ACCIDENTAL_DEATH) && <CheckmarkInCircleIcon />}
              </TableCell>
              <TableCell align="center" css={cellCSS}>
                {row.polices_include.includes(LifePolicies.WHOLE_LIFE) && <CheckmarkInCircleIcon />}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ComparisonTable;
