import { EXPERIMENT_VARIANTS_ENUM, FEATURE_TOGGLES_IDS_ENUM } from 'src/interfaces/experiment.types';
import useInitFeatureToggles from './useInitFeatureToggles/useInitFeatureToggles';
import { useRef } from 'react';

const useFeatureToggle = (experimentName: FEATURE_TOGGLES_IDS_ENUM) => {
  const isTracked = useRef(false);
  const features = useInitFeatureToggles();
  const feature = features?.[experimentName];

  const isEnabled = feature?.isEnabled;
  const isControlVariation = isEnabled && feature.variation === EXPERIMENT_VARIANTS_ENUM.CONTROL;
  const isTestVaritionA = isEnabled && feature.variation === EXPERIMENT_VARIANTS_ENUM.TEST_A;
  const isTestVariationB = isEnabled && feature.variation === EXPERIMENT_VARIANTS_ENUM.TEST_B;
  const isTestVariationC = isEnabled && feature.variation === EXPERIMENT_VARIANTS_ENUM.TEST_C;

  if (!isTracked.current && feature && isEnabled) {
    feature?.track();
    isTracked.current = true;
  }

  return { isEnabled, isControlVariation, isTestVaritionA, isTestVariationB, isTestVariationC };
};

export default useFeatureToggle;
