import { CoverageType, CoverageTypeOption } from 'src/interfaces/ICoverageTypes';

export const CoverageTypeOptions: CoverageTypeOption[] = [
  {
    value: CoverageType.Basic,
    label: 'Basic',
    description: 'Coverage on a budget to get you on the road.'
  },
  {
    value: CoverageType.Recommended,
    label: 'Standard',
    description: 'Based on your vehicle’s age, existing policy, and more. '
  },
  {
    value: CoverageType.Premium,
    label: 'Premium',
    description: 'For when you want a little bit more protection'
  }
];
