import { css, keyframes, SerializedStyles } from '@emotion/react';
import { colors } from 'src/theme/variables';

const pulls = keyframes`
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
`;

const centeredCSS = css`
  margin-left: auto;
  margin-right: auto;
`;

export const loaderCSS = (height = 20, width = 100, centered = false): SerializedStyles => css`
  content: '';
  background-color: ${colors.mercury};
  height: ${height}px;
  border-radius: 4px;
  width: ${width}%;
  transition: width 1s;
  animation: ${pulls} 3s ease-in-out infinite;
  ${centered && centeredCSS};
`;
