import React, { SVGProps, useCallback, useState } from 'react';
import { Area, AreaProps } from 'recharts';
import deepEqual from 'deep-equal';
import { Point } from 'recharts/types/shape/Curve';

type CustomAreaProps = React.ComponentProps<typeof Area> & CustomComponentAreaProps;

class CustomArea extends Area {
  constructor(props: CustomAreaProps) {
    super(props);
    props.onUpdatePoints(props.points);
  }

  componentDidUpdate(this: any, prevProps: Readonly<CustomAreaProps>) {
    if (!deepEqual(prevProps.points, this.props.points)) {
      this.props.onUpdatePoints(this.props.points);
    }
  }
}

CustomArea.getBaseValue = Area.getBaseValue;
CustomArea.displayName = 'Area';

type AreaLibProps = Omit<SVGProps<SVGElement>, 'type'> & AreaProps;

export interface AreaPointItem extends Point {
  value?: number | number[];
  payload?: any;
}

interface CustomComponentAreaProps {
  onUpdatePoints(points?: AreaPointItem[]): void;
}

export const CustomComponentArea = CustomArea as unknown as React.FC<CustomComponentAreaProps & AreaLibProps>;

export const useStaticTooltip = (): {
  setPoints: (newPoints?: AreaPointItem[]) => void;
  staticPointItem: AreaPointItem[];
} => {
  const [points, setPoints] = useState<AreaPointItem[]>([]);

  const handleSetPoints = useCallback(
    (newPoints?: AreaPointItem[]) => {
      if (newPoints && !deepEqual(newPoints, points)) setPoints(newPoints);
    },
    [setPoints, points]
  );

  return {
    setPoints: handleSetPoints,
    staticPointItem: points
  };
};

export default {};
