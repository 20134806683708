import { css, SerializedStyles } from '@emotion/react';
import { colors, mq, sizes } from 'src/theme/variables';
import { text, text20 } from 'src/theme/typography';

export const table = css`
  display: grid;
  grid-template-columns: minmax(150px, 250px) auto;
  grid-template-rows: auto;
  ${mq[0]} {
    grid-template-columns: minmax(150px, 250px) repeat(3, 1fr);
  }
`;

export const tableTh = css`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const tableTd = (even: boolean, label?: boolean): SerializedStyles => css`
  font-weight: bold;
  padding: 5px ${sizes.paddingBodyHorizontalMobile}px;
  text-align: ${label ? 'left' : 'right'};
  background-color: ${even ? colors.white : colors.concrete};
  color: ${label ? colors.warmGray100 : 'inherit'};
  ${mq[0]} {
    text-align: ${label ? 'left' : 'center'};
    color: ${label ? colors.mineshaft : 'inherit'};
    padding: 8px; 0;
  }
`;

export const coverageLabelDescription = css`
  color: ${colors.dove};
`;

export const title = css`
  ${text20};
  font-weight: bold;
  align-items: flex-start;
`;

export const coverageOptionIcon = css`
  height: 40px;
  margin-bottom: 8px;
`;

export const coverageOptionLabel = css`
  ${text};
  ${mq[0]} {
    ${text20};
  }
`;

export const heading = (first: boolean): SerializedStyles => css`
  display: flex;
  align-items: center;
  grid-column-end: 3;
  grid-column-start: 1;
  padding: ${first ? 24 : 32}px ${sizes.paddingBodyHorizontalMobile}px 16px;
  ${mq[0]} {
    grid-column-end: 5;
    padding: 24px 0 16px;
  }
`;

export const headingLabel = css`
  ${text};
  font-weight: bold;
  ${mq[0]} {
    ${text20};
  }
`;

export const headingIcon = css`
  margin-right: 12px;
  width: 24px;
`;

export const delimiter = css`
  content: '';
  border-bottom: solid 1px ${colors.warmGray};
  margin: 20px 0 0;
  grid-column-end: 5;
  grid-column-start: 1;
`;
