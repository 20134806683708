import { BundleStatus } from './../../interfaces/IQuotes';
import { CoverageType } from 'src/interfaces/ICoverageTypes';
import {
  BundleQuotesResponse,
  AutoQuotesResponse,
  Carrier,
  HomeQuotesResponse,
  LifeQuotesResponse
} from 'src/interfaces/IQuotes';
import { Flows } from 'src/interfaces/IPage';

export interface QuoteSelectData {
  gid: string;
  carrier: Carrier;
  onlineBindUrl?: string | null;
  flow: Flows;
  coverageType?: CoverageType;
  savingsAmount?: number;
  premium_value: number;
  digital_profile_url?: string | null;
  coverage_amount?: number;
  term?: number;
}

export interface LifeQuoteSelectData {
  gid: string;
  carrier: Carrier;
  flow: Flows;
  premium_value: number;
  policy_name: string;
  policy_type: string;
}

export interface BundleQuotesHeaderProps {
  vehiclesNumberLabel: string;
  bundle_status?: BundleStatus | null;
  addressValue: string;
  userName: string;
  driversNumberLabel: string;
  isEditingAllowed: boolean;
}

export interface QuotesProps {
  selectQuote(ordinal: number, quoteData: QuoteSelectData, bundledQuoteData?: QuoteSelectData | null): void;
  shouldRenderNonSavingsVariation?: boolean;
  isFreedomSFVariation?: boolean;
  setShouldRenderOLBOnConfirmation?(shouldRender: boolean): void;
  userHasBundlePreference?: boolean;
}

export type PossibleQuotesResponse =
  | BundleQuotesResponse
  | AutoQuotesResponse
  | HomeQuotesResponse
  | LifeQuotesResponse;

export interface SelectedQuoteState<QuoteType> {
  quote: QuoteType;
  ordinal: number;
}

enum LocationStateKeys {
  RENDER_SIMPLE_INTERSTITIAL_AF = 'isAFRedirected',
  SHOULD_SHOW_NON_SAVINGS = 'showNonSavings'
}

interface LocationState {
  isAFRedirected: boolean;
  showNonSavings?: boolean;
}

export const isObjectInstanceOfLocationState = (object: unknown): object is LocationState => {
  return (
    typeof object === 'object' &&
    object !== null &&
    (Object.prototype.hasOwnProperty.call(object, LocationStateKeys.RENDER_SIMPLE_INTERSTITIAL_AF) ||
      Object.prototype.hasOwnProperty.call(object, LocationStateKeys.SHOULD_SHOW_NON_SAVINGS))
  );
};
