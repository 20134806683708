import { css } from '@emotion/react';
import { mq, colors as c } from 'src/theme/variables';
import { text, text24 } from 'src/theme/typography';

export const containerCustomCSS = css`
  margin: auto;
  padding: 20px 12px 20px;
  max-width: 80%;

  ${mq[0]} {
    max-width: 95%;
  }

  ${mq[1]} {
    max-width: 1100px;
  }
`;

export const floridaWrapperCSS = css`
  display: flex;
  box-shadow: 2px 4px 0 ${c.mercury};
  border-radius: 8px;
  padding: 12px;
  border: 1px solid ${c.nobel};
  margin-top: 20px;
  flex-direction: column;
  ${mq[0]} {
    flex-direction: row;
    margin-top: 80px;
    padding: 32px;
  }
`;

export const verticalDividerCSS = css`
  display: none;
  ${mq[0]} {
    display: flex;
    margin: 0 28px;
  }
`;

export const floridaImgWrapperCSS = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 215px;
  img {
    margin-bottom: 12px;
  }
`;

export const cardsWrapperCSS = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${mq[0]} {
    flex-direction: row;
    margin-top: 48px;
    justify-content: space-between;
  }
`;

export const floridaTextWrapperCSS = css`
  h3,
  ul {
    ${text};
  }
  li {
    padding: 4px 0;
  }
  ${mq[0]} {
    h3 {
      ${text24};
    }
    ul {
      ${text};
      line-height: 24px;
    }
  }
  p {
    ${text};
    font-style: italic;
    font-weight: bold;
    margin-top: 12px;
  }
`;

export const advisorCardCSS = css`
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: space-evenly;
  min-height: 529px;
  padding: 24px;
  margin-top: 12px;
  max-width: 515px;
  min-width: 100px;
  img {
    margin: 0 auto;
    width: 160px;
  }
  h3 {
    ${text24};
  }
`;

export const specialistCSS = css`
  ${text};
`;
