import React, { useEffect } from 'react';

import Footer from 'src/components/Footer/Footer';
import PageHeader from 'src/components/PageHeader/PageHeader';
import GenericError from 'src/components/GenericError/GenericError';
import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';
import Page from 'src/components/Page/Page';
import useConfig from 'src/api/config/useConfig';
import { useParams } from 'react-router-dom';
import { DefaultLocation } from 'src/interfaces/IPage';
import ErrorFallback from 'src/components/ErrorFallback/ErrorFallback';

const GenericErrorLayout: React.FC = () => {
  const { gid } = useParams() as DefaultLocation;
  const { isError } = useConfig(gid);

  useEffect(() => {
    analytics.page(SEGMENT.PAGES.ERROR);
  });

  if (isError) {
    return <ErrorFallback />;
  }

  return (
    <Page>
      <PageHeader page={SEGMENT.PAGES_KEY.ERROR} />
      <main>
        <GenericError />
      </main>
      <Footer page={SEGMENT.PAGES_KEY.ERROR} />
    </Page>
  );
};

export default GenericErrorLayout;
