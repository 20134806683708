/** @jsxImportSource @emotion/react */
import React from 'react';

import { css } from '@emotion/react';

import Container from 'src/kit/Container/Container';
import { hero, heroWaveLine } from './Hero.style';
import { HeroProps } from './Hero.types';

const Hero: React.FC<HeroProps> = ({ children, flowsLength = 0 }) => (
  <section css={hero(flowsLength)} data-testid="hero-container">
    <div css={heroWaveLine} />
    <Container
      customCSS={css`
        width: 100%;
      `}
    >
      {children}
    </Container>
  </section>
);

export default Hero;
