/** @jsxImportSource @emotion/react */
import React from 'react';

import { BundleQuotesHeaderProps } from 'src/components/Quotes/Quotes.types';
import { useParams } from 'react-router-dom';
import { DefaultLocation, Flows } from 'src/interfaces/IPage';
import { BundleStatus } from 'src/interfaces/IQuotes';
import Container from 'src/kit/Container/Container';
import { quotesContainer, quotesHeaderEditLink } from 'src/components/Quotes/Quotes.style';
import QuotesHeaderParam from 'src/components/Quotes/QuotesHeader/QuotesHeaderParam/QuotesHeaderParam';
import QuotesHeader from 'src/components/Quotes/QuotesHeader/QuotesHeader';
import { getBundleQuotesHeading } from 'src/utils/quotesPage';
import ROUTES from 'src/constants/routes';
import { FS_SENSITIVE_DATA_CLASS } from 'src/constants/fullStory';
import Button from 'src/kit/Button/Button';
import { useCustomNavigate } from 'src/hooks/useCustomNavigate';
import { useRefetchQuestions } from 'src/api/questions';

const BundleQuotesHeader: React.FC<BundleQuotesHeaderProps> = ({
  vehiclesNumberLabel,
  driversNumberLabel,
  addressValue,
  userName,
  bundle_status,
  isEditingAllowed
}) => {
  const { flow, gid } = useParams() as DefaultLocation;
  const navigate = useCustomNavigate();

  const { mutateAsync } = useRefetchQuestions(gid);

  const hasNoQuotes = bundle_status === BundleStatus.Failed;
  const autoOnly = bundle_status === BundleStatus.AutoOnly;
  const homeOnly = bundle_status === BundleStatus.HomeOnly;
  const editDetailsLink = ROUTES.QUESTIONS.replace(':flow', flow).replace(':gid', gid);
  const heading = getBundleQuotesHeading(userName, hasNoQuotes, autoOnly, homeOnly);

  const onEditDetailsClicked = async () => {
    //HACK mutateAsync should receive variables but in this case we don`t want to provide a variables
    flow === Flows.Home && (await mutateAsync(false));
    navigate(editDetailsLink);
  };

  return (
    <Container customCSS={quotesContainer} isAriaLivePolite>
      <QuotesHeader heading={heading}>
        <QuotesHeaderParam className={FS_SENSITIVE_DATA_CLASS.MASK}>{addressValue}</QuotesHeaderParam>
        <QuotesHeaderParam>{vehiclesNumberLabel}</QuotesHeaderParam>
        <QuotesHeaderParam>{driversNumberLabel}</QuotesHeaderParam>
        {isEditingAllowed && (
          <QuotesHeaderParam>
            <Button css={quotesHeaderEditLink} variant="text" onClick={onEditDetailsClicked} data-testid="edit-details">
              Edit Details
            </Button>
          </QuotesHeaderParam>
        )}
      </QuotesHeader>
    </Container>
  );
};

export default BundleQuotesHeader;
