import { AnswerValue } from 'src/interfaces/IQuestion';
const VALIDATION_REGEXP = '^(\\([2-9][0-9]{2}\\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$';
export const PHONE_MASK = '(999) 999-9999';

export const isPhoneValid = (phone: string): boolean => {
  const regExp = new RegExp(VALIDATION_REGEXP);
  return regExp.test(phone);
};

export const isPhoneHidden = (phone: string): boolean => phone.includes('*');
export const shouldBlockPhoneUpdate = (dataPhone?: AnswerValue | null): boolean => {
  if (typeof dataPhone === 'string' && isPhoneHidden(dataPhone as string)) {
    return true;
  }
  return false;
};

export const normalizePhoneNumber = (phoneNumber: string): string => phoneNumber.replace(/[^\d]/g, '');
