import { css, SerializedStyles } from '@emotion/react';
import * as React from 'react';
import { colors, mq, typography } from 'src/theme/variables';
import { DialogSize } from './types';

export const overlayCSS = (
  fullscreen: boolean,
  addSpaceForExistingHeader: boolean,
  customCSS: React.CSSProperties = {}
): React.CSSProperties => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: fullscreen ? colors.white : 'rgba(0,0,0,.3)',
  overflowY: 'auto',
  padding: fullscreen ? '0' : '10px 14px 20px',
  top: '0',
  right: '0',
  bottom: '0',
  left: '0',
  zIndex: 9999,
  marginTop: addSpaceForExistingHeader ? 'var(--header-height)' : 0,
  ...customCSS
});

const getContentMaxWidth = (size: DialogSize, fullscreen: boolean) => {
  if (fullscreen) {
    return 'initial';
  }

  return size && size === 'small' ? '800px' : '970px';
};

export const contentCSS = (
  size: DialogSize,
  fullscreen: boolean,
  customCSS: React.CSSProperties = {}
): React.CSSProperties => ({
  position: 'relative',
  inset: 'auto',
  padding: 0,
  border: 'unset',
  background: 'transparent',
  width: '100%',
  height: fullscreen ? '100%' : 'auto',
  maxWidth: getContentMaxWidth(size, fullscreen),
  alignSelf: 'center',
  boxSizing: 'border-box',
  margin: 'auto',
  outline: 'none',
  ...customCSS
});

export const closeIconCSS = (fullscreen: boolean): SerializedStyles => css`
  margin-left: auto;
  margin-bottom: 12px;
  width: 36px;
  height: 36px;

  ${mq[0]} {
    margin-bottom: 20px;
    width: 40px;
    height: 40px;
  }

  ${fullscreen &&
  css`
    position: absolute;
    top: 12px;
    right: 16px;
    z-index: 1;

    ${mq[0]} {
      top: 22px;
      right: 32px;
    }
  `}

  img {
    width: 50%;
    display: inline-flex;
    margin: auto;
  }
`;

export const containerCSS = (fullscreen: boolean): SerializedStyles => css`
  margin-left: auto;
  background-color: ${colors.white};
  border: 1px solid ${colors.mercury};
  box-sizing: border-box;
  border-radius: 8px;
  padding: 24px 16px;

  ${mq[0]} {
    padding: 40px;
  }

  ${fullscreen &&
  css`
    border-width: 0;
    padding: 16px;
    ${mq[0]} {
      padding: 36px;
    }
  `}
`;

export const titleContainerCSS = css`
  font-size: ${typography.fontSizeLarge};
  font-weight: bold;
  line-height: ${typography.lineHeightLarge};
`;

export const subtitleContainerCSS = css`
  color: ${colors.dove};
`;

export const spaceCSS = css`
  content: '';
  margin-bottom: 8px;
`;

export const headerCSS = css`
  position: relative;

  &:after {
    content: '';
    box-shadow: 0 2px 1px 0 #0000001f;
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: 0;
  }
`;
