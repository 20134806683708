/** @jsxImportSource @emotion/react */
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  headCSS,
  buttonCustomCSS,
  dropFilesCSS,
  acceptanceWrapperCSS,
  uploadInputContainer
} from './UploadFilesInput.style';
import Button from 'src/kit/Button/Button';
import UploadArrow from 'src/assets/svg/upload-arrow.svg?react';
import UploadFile from 'src/assets/svg/upload-file.svg';
import { errorMessageByCode, getFieldsErrors, getValidationRequirementsText } from './UploadFilesInput.utils';
import { UploadFilesInputProps, ValidateCodes } from './UploadFilesInput.types';
import { AUTOMATION_TEST_IDS } from 'src/constants/automationTestIds';
import { oneMB } from 'src/shared/constants/measures';

const UploadFilesInput: React.FC<UploadFilesInputProps> = ({ setFileUploaded, validation = {}, currentFilesCount }) => {
  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      setFileUploaded(acceptedFiles);
    },
    [setFileUploaded]
  );
  const disabled = typeof validation.maxFiles === 'number' && currentFilesCount >= validation.maxFiles;

  const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
    onDrop,
    disabled,
    ...validation,
    ...(validation.maxSize && { maxSize: validation.maxSize * oneMB }),
    ...(validation.maxFiles && { maxFiles: validation.maxFiles - currentFilesCount })
  });

  const errors = getFieldsErrors(fileRejections);

  const rootProps = {
    ...getRootProps({
      css: uploadInputContainer,
      id: 'drag-and-drop',
      role: undefined
    })
  };

  const inputProps = {
    ...getInputProps({
      'aria-labelledby': 'drag-and-drop',
      'data-testid': AUTOMATION_TEST_IDS.DRAG_AND_DROP_INPUT
    })
  };

  delete rootProps.role;

  return (
    <div {...rootProps}>
      <input {...inputProps} />

      <p css={headCSS}>Drag and drop your files here</p>
      <p css={headCSS}>or</p>

      <Button variant={'secondary'} customCSS={buttonCustomCSS} disabled={disabled}>
        <UploadArrow />
        Upload a File
      </Button>

      {Object.entries(validation).map(([key, requirement], i) => {
        const validateCode = key as ValidateCodes;
        return (
          <div key={`requirement_${i}`} css={acceptanceWrapperCSS(errors[validateCode])}>
            <p>{errors[validateCode] && errorMessageByCode[validateCode]}</p>
            <p>{getValidationRequirementsText(validateCode, requirement, disabled)}</p>
          </div>
        );
      })}

      {isDragActive && (
        <div css={dropFilesCSS}>
          <img src={UploadFile} alt={'Upload files icon'} />
          Drop your files here
        </div>
      )}
    </div>
  );
};

export default UploadFilesInput;
