import React from 'react';

import { QuestionTypeKey } from 'src/constants/schemaKeys';
import CarrierSliderInput from './CarrierSliderInput/CarrierSliderInput';
import { QuestionWrapper } from 'src/questionsForm/components/QuestionWrapper/QuestionWrapper';
import QuestionInput from 'src/questionsForm/components/Question/QuestionInput/QuestionInput';

const SliderQuestion: React.FC<any> = props => {
  const getSliderComponents = (key: QuestionTypeKey): JSX.Element => {
    switch (key) {
      case QuestionTypeKey.CarrierSlider:
        return <CarrierSliderInput {...props} />;
      default:
        return <QuestionWrapper {...props} inputComponent={QuestionInput} />;
    }
  };

  return getSliderComponents(props.key_name as QuestionTypeKey);
};

export default SliderQuestion;
