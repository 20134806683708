/** @jsxImportSource @emotion/react */
import * as React from 'react';

import { useForm, FormProvider } from 'react-hook-form';

import Button from 'src/kit/Button/Button';
import { Entity, EntityBase, EntityList as EntityListInterface } from 'src/interfaces/IPage';
import VehicleSelectQuestion from 'src/questionsForm/components/Question/VehicleSelectQuestion/VehicleSelectQuestion';
import TextInputQuestion from 'src/questionsForm/components/Question/TextInputQuestion/TextInputQuestion';
import { FormQuestionProps, QuestionTypes } from 'src/interfaces/IQuestion';
import { actionsCSS, addCSS, buttonCSS, formCSS } from './EntityForm.style';

interface EntityFormProps {
  entity?: Entity;
  list: EntityListInterface;
  onHide(): void;
  onSubmit(entity: Partial<Entity>): void;
}

const getEntityFormFields = (type: QuestionTypes | string): React.ComponentType<FormQuestionProps> => {
  switch (type) {
    case QuestionTypes.VehicleSelect:
      return VehicleSelectQuestion;
    case QuestionTypes.TextInput:
    default:
      return TextInputQuestion;
  }
};

const EntityForm: React.FC<EntityFormProps> = ({ list, onHide, onSubmit, entity }) => {
  const methods = useForm({
    mode: 'onChange',
    defaultValues: entity
  });

  const onAdd = (values: EntityBase) => {
    onSubmit({ ...values });
    onHide();
  };

  return (
    <FormProvider {...methods}>
      <form css={formCSS} onSubmit={methods.handleSubmit(onAdd)}>
        {list.fields.map((f, i) => {
          const Field = getEntityFormFields(f.type);

          return <Field {...f} key={i} validation={{ static: { required: 'This field is required' } }} />;
        })}

        <div css={actionsCSS}>
          <Button type="button" css={buttonCSS} onClick={onHide} variant="secondary">
            Cancel
          </Button>

          <Button
            type="submit"
            data-testid="add-new-entity-form-button"
            disabled={!methods.formState.isValid}
            customCSS={addCSS}
          >
            Add
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default EntityForm;
