import React from 'react';
import { Controller, RegisterOptions } from 'react-hook-form';

import { AnswerValue, FieldProps } from 'src/interfaces/IQuestion';
import dynamicValidations from 'src/utils/dynamicValidations';
import Input from 'src/shared/components/Input/Input';
import { FS_SENSITIVE_DATA_CLASS } from 'src/constants/fullStory';

interface InputProps extends FieldProps {
  hasError?: boolean;
  onChange?: (...event: any[]) => void;
}

const QuestionInput: React.FC<InputProps> = ({
  hasError = false,
  key_name,
  isDisabled,
  inputId,
  type = 'search',
  validation,
  tracking_forbidden = false,
  ...props
}) => {
  const rules = {
    ...validation?.static,
    validate: {
      isNotEmptyString: (value: AnswerValue) =>
        typeof value === 'string'
          ? value
            ? !!(value as string).trim() || 'This value cannot consist of empty spaces'
            : true
          : true,
      ...(validation?.dynamic && { [validation.dynamic]: dynamicValidations[validation.dynamic] })
    }
  };

  if (rules?.pattern) {
    rules.pattern.value = new RegExp(rules.pattern.value);
  }

  return (
    <Controller
      rules={rules as RegisterOptions}
      name={key_name}
      render={({ field }) => (
        <Input
          {...props}
          {...field}
          value={field.value || ''}
          className={tracking_forbidden ? FS_SENSITIVE_DATA_CLASS.MASK : ''}
          onChange={e => {
            props.onChange && props.onChange(e);
            field.onChange(e);
          }}
          hasError={hasError}
          id={inputId}
          autoComplete="off"
          name={key_name}
          type={type}
          disabled={isDisabled}
          ref={r => field.ref(r)}
        />
      )}
    />
  );
};

export default QuestionInput;
