/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';

import { CarriersGridProps } from './CarriersGrid.types';
import { logo, logos, moreCarriersButton } from './CarriersGrid.style';
import SIRV from 'src/constants/sirv';
import { Carriers } from 'src/interfaces/ICarriers';
import SirvImage from 'src/kit/SirvImage/SirvImage';
import Button from 'src/kit/Button/Button';

const CARRIERS = SIRV.CARRIERS as Carriers;
const MAX_COLLAPSED_CARRIERS = 6;

const CarriersGrid: React.FC<CarriersGridProps> = ({ customCSS, expanded = false, withBorder = true }) => {
  const [showAll, setShowAll] = useState(expanded);
  return (
    <div css={customCSS}>
      <div css={logos}>
        {Object.keys(CARRIERS)
          .filter((_, i) => showAll || i < MAX_COLLAPSED_CARRIERS)
          .map((carrier: string, i) => (
            <div key={i} css={logo(!withBorder)}>
              <SirvImage url={CARRIERS[carrier].url} width={130} alt={CARRIERS[carrier].alt} />
            </div>
          ))}
      </div>

      {!showAll && (
        <Button customCSS={moreCarriersButton} variant="text" onClick={() => setShowAll(true)}>
          More carriers
        </Button>
      )}
    </div>
  );
};

export default CarriersGrid;
