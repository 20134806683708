import { Answers } from 'src/interfaces/IAnswer';
import { AnswerValue, Option } from 'src/interfaces/IQuestion';
import { getNestedObjectValueByString } from 'src/utils/general';

export enum RuleNames {
  Is = 'conditions_is',
  Not = 'conditions_not',
  BoolNot = 'conditions_boolean_not',
  GreaterThen = 'conditions_greater_than',
  LessThen = 'conditions_less_than',
  Include = 'conditions_include',
  NotInclude = 'conditions_not_include'
}

type RuleValueType = AnswerValue | null | (string | number)[];
enum RuleKeys {
  IS = 'is',
  NOT = 'not',
  LESS = 'less',
  INCLUDE = 'include',
  GREATER = 'greater',
  NOT_INCLUDE = 'not_include'
}

export type Rule = {
  type: RuleNames | string;
  question_key_name: string;
  value: RuleValueType;
};

interface ValidationsFormat {
  [key: string]: (actual: RuleValueType, expected: RuleValueType) => boolean;
}

const rules: Record<RuleKeys, (actual: RuleValueType, expected: RuleValueType) => boolean> = {
  is: (actual, expected) => actual === expected,
  not: (actual, expected) => actual !== expected,
  less: (actual, expected) => (actual as number) < (expected as number),
  greater: (actual, expected) => (actual as number) > (expected as number),
  include: (actual, expected) => (expected as any[]).includes(actual),
  not_include: (actual, expected) => !(expected as any[]).includes(actual)
};

const isOption = (val: any) => val && typeof val === 'object' && val.hasOwnProperty('value');

const validate = (actual: RuleValueType, expected: RuleValueType, rule: RuleKeys) =>
  isOption(actual) ? rules[rule]((actual as Option).value, expected) : rules[rule](actual, expected);

const Validations: ValidationsFormat = {
  [RuleNames.Is]: (actual, expected) => validate(actual, expected, RuleKeys.IS),
  [RuleNames.Not]: (actual, expected) => validate(actual, expected, RuleKeys.NOT),
  [RuleNames.BoolNot]: (actual, expected) => !!actual !== expected,
  [RuleNames.GreaterThen]: (actual, expected) => validate(actual, expected, RuleKeys.GREATER),
  [RuleNames.LessThen]: (actual, expected) => validate(actual, expected, RuleKeys.LESS),
  [RuleNames.Include]: (actual, expected) => validate(actual, expected, RuleKeys.INCLUDE),
  [RuleNames.NotInclude]: (actual, expected) => validate(actual, expected, RuleKeys.NOT_INCLUDE),
  default: () => true
};

const validateRule = (rule: string | RuleNames = '', actual: RuleValueType, expected: RuleValueType): boolean =>
  (Validations[rule.toLowerCase()] || Validations.default)(actual, expected);

const validateRules = (values: Answers, andRules?: (Rule | Rule[])[]): boolean => {
  if (!andRules) return true;

  const rules = andRules.filter(r =>
    Array.isArray(r) ? r : r.type === RuleNames.BoolNot || values.hasOwnProperty(r.question_key_name.split('.')[0])
  );

  return (
    !!rules.length &&
    rules.every(r => {
      return Array.isArray(r)
        ? (r as Rule[]).some(orRule =>
            validateRule(orRule.type, getActualValue(values, orRule) as RuleValueType, orRule.value)
          )
        : validateRule(r.type, getActualValue(values, r) as RuleValueType, r.value);
    })
  );
};

const getActualValue = (values: Answers, rule: Rule) => {
  const isNested = rule.question_key_name.split('.').length > 1;
  return isNested ? getNestedObjectValueByString(rule.question_key_name, values) : values[rule.question_key_name];
};

export default validateRules;
