/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from 'react';

import { fromAddress } from 'react-geocode';

import { AddressSuggestion } from 'src/interfaces/IStreets';
import { AddressAnswer } from 'src/interfaces/IQuestion';
import MAP_CONFIG from './mapConfig';
import { frameCSS, mapCSS } from './AddressMap.style';
import * as Sentry from '@sentry/browser';
import googleMaps from 'src/utils/googleMaps';
import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';
import { useParams } from 'react-router-dom';
import { DefaultLocation } from 'src/interfaces/IPage';

interface MapInstance {
  map: any;
  marker: any;
}

interface Props {
  id?: string;
  address?: AddressAnswer;
}

const AddressMap: React.FC<Props> = ({ id = 'map', address }) => {
  const mapInstance = useRef<MapInstance>({ map: null, marker: null });
  const [mapsReady, setReady] = useState<boolean | null>(false);
  const { gid, flow } = useParams() as DefaultLocation;

  useEffect(() => {
    googleMaps.loader
      .load()
      .then(google => {
        const position = { lat: 0, lng: 0 };
        const map = new google.maps.Map(document.getElementById(id) as HTMLDivElement, MAP_CONFIG);
        analytics.track(SEGMENT.MAP_API_CALLED, gid, flow);
        const marker = new google.maps.Marker({ position, map });
        mapInstance.current = { map, marker };
        setReady(true);
      })
      .catch(e => {
        Sentry.captureException('Failed to load map', e);
      });
  }, [id, flow, gid]);

  useEffect(() => {
    const suggestion = answerToSuggestion(address);

    if (mapsReady && suggestion) {
      analytics.track(SEGMENT.MARKER_MAP_API_CALLED, gid, flow);
      setMarkerByAddress(suggestion);
    }
  }, [address, mapsReady, gid, flow]);

  const answerToSuggestion = (answer?: AddressAnswer): AddressSuggestion | null =>
    answer?.line1
      ? {
          street_line: answer.line1,
          secondary: '',
          city: answer.city,
          state: answer.state,
          zipcode: answer.zip,
          entries: '0'
        }
      : null;

  const setMarkerByAddress = async ({ street_line, city, state, zipcode }: AddressSuggestion) => {
    const { results } = await fromAddress(`${street_line} ${city} ${state} ${zipcode}`);

    if (results.length) {
      mapInstance.current.map.setCenter(results[0].geometry.location!);
      mapInstance.current.marker.setPosition(results[0].geometry.location);
    } else {
      throw new Error('Address from SmartyStreets was not found in Google Maps Geocode API.');
    }
  };

  return (
    <div css={frameCSS} data-testid="address-map-frame">
      <div css={mapCSS} id={id} data-testid="address-map" role="presentation" aria-hidden="true" />
    </div>
  );
};

export default AddressMap;
