import { css } from '@emotion/react';

export const containerCSS = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const actionButtonCSS = css`
  white-space: nowrap;
`;

export const detailsButtonCSS = css`
  margin-top: 16px;
  font-weight: 700;
  white-space: nowrap;
`;
