import { DynamicValidationTypes, QuestionTypes } from 'src/interfaces/IQuestion';

export const SESSION_TIMEOUT_INPUTS = [
  {
    type: QuestionTypes.TextInput,
    key_name: 'last_name',
    placeholder: 'last name',
    label: 'Last Name',
    validation: { static: { required: 'This field is required' } }
  },
  {
    type: QuestionTypes.Date,
    key_name: 'date_of_birth',
    placeholder: 'MM/DD/YYYY',
    mask: '99-99-9999',
    label: 'Date of Birth',
    validation: { static: { required: 'This field is required' }, dynamic: DynamicValidationTypes.DateOfBirth }
  },
  {
    type: QuestionTypes.TextInput,
    key_name: 'zip',
    placeholder: 'XXXXX',
    label: 'Zip Code',
    validation: { static: { required: 'This field is required' } }
  }
];

export const SESSION_TIMEOUT_FORM_ID = 'session-timeout';
