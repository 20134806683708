import { css, SerializedStyles } from '@emotion/react';
import { text14, text20 } from 'src/theme/typography';
import { colors as c, borderRadius } from 'src/theme/variables';

export const uploadInputContainer = css`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23666666FF' stroke-width='2' stroke-dasharray='6%2c 7' stroke-dashoffset='51' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: ${borderRadius}px;
  padding: 28px 0;
  z-index: 0;
`;

export const headCSS = css`
  font-weight: 700;
  margin-bottom: 8px;
  ${text20};

  &:last-of-type {
    margin-bottom: 15px;
  }
`;

export const acceptanceWrapperCSS = (isError: boolean): SerializedStyles => css`
  color: ${isError ? c.red : c.dove};
  text-align: center;
  ${text14};
  margin-bottom: 8px;

  &:last-of-type {
    margin: 0;
  }
`;

export const buttonCustomCSS = css`
  margin-bottom: 20px;

  & > span {
    display: flex;
    align-items: center;

    & svg {
      margin-right: 15px;
    }

    & svg path {
      transition: fill 0.3s;
    }
  }

  &:hover svg path,
  &:focus svg path {
    fill: ${c.white};
  }

  &:disabled svg path {
    fill: ${c.dove};
  }
`;

export const dropFilesCSS = css`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${c.azure};
  font-weight: 700;
  z-index: 2;
  background-color: ${c.grayFive};
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23156EEAFF' stroke-width='4' stroke-dasharray='6%2c 7' stroke-dashoffset='51' stroke-linecap='square'/%3e%3c/svg%3e");
  ${text20};

  & > img {
    margin-bottom: 15px;
  }
`;
