import { SerializedStyles, css } from '@emotion/react';
import { card } from 'src/shared/components/Card/Card.style';
import { colors, mq, sizes } from 'src/theme/variables';

export const containerCSS = css`
  ${card};
  position: relative;
  overflow: hidden;
  padding: 32px ${sizes.paddingBodyHorizontalMobileDense}px;
  ${mq[0]} {
    padding: 20px ${sizes.paddingBodyHorizontalTablet}px;
  }
  ${mq[1]} {
    padding: ${sizes.paddingBodyHorizontalTablet}px;
  }
`;

export const logoCSS = css`
  flex-shrink: 0;
  justify-content: center;
`;

export const cardInnerCSS = css`
  display: flex;
  flex-direction: column;
  ${mq[0]} {
    justify-content: space-between;
    flex-direction: row;
  }
`;

export const cardInnerContentCSS = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  ${mq[0]} {
    flex-direction: row;
    width: auto;
    margin-top: 0;
  }
`;

export const valuesAndActionsCSS = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${mq[1]} {
    flex-direction: row;
    width: auto;
  }
`;

export const keyValuesCSS = css`
  ${mq[1]} {
    margin-right: 8px;
  }
`;

export const tagsWrapperCSS = css`
  padding-bottom: 8px;
  ${mq[0]} {
    padding-bottom: 0;
    margin-right: 12px;
  }
  ${mq[1]} {
    margin-right: 0;
  }
`;

export const actionsCSS = css`
  margin-top: 16px;
  ${mq[0]} {
    margin-top: 24px;
  }
  ${mq[1]} {
    margin-top: 0;
  }
`;

export const detailsCSS = css`
  padding-top: 24px;
  margin-bottom: 20px;
`;

export const hideDetailsButtonCSS = css`
  display: block;
  margin: 0 auto 0;
  font-weight: 700;
`;

export const coverageDetailsExpanderCSS = (isOpen = false): SerializedStyles => css`
  display: inline-flex;
  justify-content: center;
  width: 100%;
  font-weight: 700;
  color: ${colors.azure};
  padding: 12px 0;
  position: relative;
  margin-top: 24px;

  :before {
    content: '';
    position: absolute;
    left: -32px;
    right: 0;
    top: 0;

    height: 1px;
    width: calc(100% + 64px);
    background-color: ${colors.mercury} !important;
  }

  :hover {
    cursor: pointer;

    img {
      opacity: 0.65;
    }
  }

  img {
    opacity: 1;
    transform: rotate(${isOpen ? 180 : 0}deg);
    transition: opacity 0.3s;
  }

  span {
    display: inline-flex;
    justify-content: center;
    gap: 4px;
  }
`;
