import React from 'react';

import { FormQuestionProps } from 'src/interfaces/IQuestion';
import QuestionWrapper from 'src/questionsForm/components/QuestionWrapper/QuestionWrapper';
import CheckboxInput from './CheckboxInput/CheckboxInput';

const CheckboxQuestion: React.FC<FormQuestionProps> = props => {
  return <QuestionWrapper {...props} inputComponent={CheckboxInput} hideLabel />;
};

export default CheckboxQuestion;
