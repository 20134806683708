/** @jsxImportSource @emotion/react */
import React, { Fragment, useCallback, useEffect, useState, useRef } from 'react';

import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import Button from 'src/kit/Button/Button';

import TextHighlight from 'src/components/TextHighlight/TextHighlight';
import { DefaultLocation } from 'src/interfaces/IPage';
import templater from 'src/utils/stringTemplater';
import sessionService from 'src/api/session/session.service';

import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';
import FullPageLoader from 'src/shared/components/FullPageLoader/FullPageLoader';
import {
  disclosuresPageActionsCSS,
  disclosuresPageSubtitleCSS,
  disclosuresPageTextCSS,
  disclosuresPageTitleCSS
} from './DisclosuresPage.style';
import useDisclosures from 'src/api/disclosures/useDisclosures';
import { useCustomNavigate } from 'src/hooks/useCustomNavigate';
import useConfig from 'src/api/config/useConfig';
import { ButtonSize } from 'src/kit/Button/types';
import CustomTCPAConsent from 'src/components/CustomTCPAConsent/CustomTCPAConsent';
import { Disclosure, DisclosureKind } from 'src/interfaces/disclosures.types';
import { MATIC_LICENSE_DISCLAIMER } from 'src/constants/hardcoded';

const actionButtonText = 'Get Quotes';

const DisclosuresPage: React.FC = () => {
  const queryClient = useQueryClient();
  const { gid, flow } = useParams() as DefaultLocation;
  const { data: config } = useConfig(gid);
  const { data: disclosuresData, isLoading: isDisclosuresLoading } = useDisclosures(gid);
  const [startQuotingLoading, setStartQuotingLoading] = useState(false);
  const navigate = useCustomNavigate();
  const flowStartedReported = useRef(false);
  const disclosures = disclosuresData?.disclosures || [];

  const nonTCPADisclosures = disclosures?.filter((disclosure: Disclosure) => {
    return disclosure.kind !== DisclosureKind.CX_TCPA;
  });

  const TCPADisclosures = disclosures?.filter((disclosure: Disclosure) => {
    return disclosure.kind === DisclosureKind.CX_TCPA;
  });

  useEffect(() => {
    if (!flowStartedReported.current) {
      analytics.track(SEGMENT.FLOW_STARTED, gid, flow, {
        partner_key: config?.servicer_key
      });
      flowStartedReported.current = true;
    }
  }, [flow, gid, config?.servicer_key]);

  const disclosuresAccepted = disclosuresData?.disclosures_accepted || false;

  const startQuoting = useCallback(async () => {
    queryClient.removeQueries(['quotes', flow, gid]);
    await sessionService.startQuoting(flow, gid);

    navigate(`/${flow}/${gid}/quotes`);
  }, [queryClient, gid, navigate, flow]);

  const trackDisclosuresAccepted = useCallback(() => {
    disclosuresData?.disclosures.forEach(disclosure => {
      analytics.track(SEGMENT.DISCLOSURE_ACCEPTED, gid, flow, {
        disclosure_gid: disclosure.gid,
        page: SEGMENT.PAGES_KEY.DISCLOSURES
      });
    });
  }, [disclosuresData, gid, flow]);

  useEffect(() => {
    if (disclosuresAccepted) {
      trackDisclosuresAccepted();
      startQuoting();
    }
  }, [disclosuresAccepted, startQuoting, trackDisclosuresAccepted]);

  const getDisclosures = () => nonTCPADisclosures.map(d => templater(d.content, { '{cta_text}': actionButtonText }));

  const onActionButtonClick = async () => {
    analytics.track(SEGMENT.PRIMARY_CTA_CLICKED, gid, flow);
    setStartQuotingLoading(true);
    trackDisclosuresAccepted();
    await startQuoting();
  };

  if (isDisclosuresLoading || disclosuresAccepted) {
    return <FullPageLoader />;
  }

  return (
    <Fragment>
      <h1 css={disclosuresPageTitleCSS} data-testid="disclosure-page-title">
        Get Homeowners Insurance
      </h1>
      <p css={disclosuresPageSubtitleCSS}>
        Homeowners insurance is required to complete the loan process and must be in effect by the date the loan funds.
        Click &quot;{actionButtonText}&quot; to get started.
      </p>
      <TextHighlight customCSS={disclosuresPageTextCSS}>
        {getDisclosures().map((text, i) => (
          <p key={i} dangerouslySetInnerHTML={{ __html: text }} />
        ))}
        {TCPADisclosures.map((_disclosure, i) => {
          return (
            <p key={i}>
              <CustomTCPAConsent
                location={SEGMENT.PAGES_KEY.QUESTIONS}
                shouldHideIcon
                shouldRenderMaticPoliciesDisclosure={false}
                buttonLabel="Get Quotes"
                customCSS={disclosuresPageTextCSS}
              />
            </p>
          );
        })}
        {MATIC_LICENSE_DISCLAIMER}
      </TextHighlight>
      <div css={disclosuresPageActionsCSS}>
        <Button type="button" isLoading={startQuotingLoading} onClick={onActionButtonClick} size={ButtonSize.Large}>
          {actionButtonText}
        </Button>
      </div>
    </Fragment>
  );
};

export default DisclosuresPage;
