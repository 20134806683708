import { css } from '@emotion/react';
import { borderRadius, colors, sizes } from 'src/theme/variables';
import { activeElementReset } from 'src/theme/helpers';

export const entityListItem = css`
  background-color: ${colors.white};
  border: 1px solid ${colors.black};
  border-radius: ${borderRadius}px;
  padding-left: 56px;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  margin-top: 8px;
  height: ${sizes.controlHeight}px;
`;

export const checkbox = css`
  position: absolute;
  width: 24px;
  height: 24px;
  left: 20px;
  path {
    fill: ${colors.green};
  }
`;

export const text = css`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
`;

export const textInner = css`
  display: block;
  max-height: 100%;
  overflow: hidden;
`;

export const action = css`
  ${activeElementReset};
  color: ${colors.azure};
  flex-shrink: 0;
  min-height: 100%;
  padding-left: 16px;
  padding-right: 20px;
  font-weight: bold;
`;
