import { css, SerializedStyles } from '@emotion/react';
import { indicatorLine } from 'src/theme/shared-styles';
import { text24, text32 } from 'src/theme/typography';
import { mq } from 'src/theme/variables';

export const entityList = (isExtended = false): SerializedStyles => css`
  position: relative;

  &::before {
    ${indicatorLine};

    bottom: calc(100% - 20px) !important;
    content: '';
    top: -250px;
  }

  ${isExtended &&
  css`
    &::after {
      ${indicatorLine};
      content: '';
    }
  `};
`;

export const entityListButton = css`
  width: 100%;
  margin-top: 24px;
  ${mq[1]} {
    margin-top: 32px;
  }
`;

export const entityListHeading = css`
  ${text24};
  font-weight: 700;
  margin-top: 18px;
  ${mq[0]} {
    ${text32};
    margin-top: 40px;
  }
  ${mq[1]} {
    margin-top: 56px;
  }
`;
