import { SerializedStyles, css } from '@emotion/react';
import { text, text12, text14, text32 } from 'src/theme/typography';
import { colors, mq } from 'src/theme/variables';

export const quoteCardCSS = (isFeatured: boolean): SerializedStyles => css`
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
  height: fit-content;
  overflow: hidden;

  ${isFeatured &&
  css`
    border-top: none;
  `}
`;

export const quoteContentCSS = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  padding: 20px;

  ${mq[1]} {
    flex-direction: row;
    gap: 20px;
    padding: 32px;
  }

  @media screen and (min-width: 1300px) {
    gap: 40px;
  }
`;

export const quotePieceCSS = css`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  width: 100%;

  ${mq[1]} {
    flex-direction: row;
    gap: 32px;
    width: auto;
  }
`;

export const quoteInfoCSS = css`
  ${text14};
  font-weight: 500;
  text-align: center;

  p {
    color: ${colors.dove};
    margin-bottom: 4px;
  }

  p:last-of-type {
    margin-bottom: 0;
  }

  ${mq[1]} {
    text-align: start;
    ${text14};
  }
`;

export const dividerCSS = css`
  border-color: ${colors.nobel};
`;

export const buttonWrapperCSS = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

export const textButtonCustomCSS = css`
  ${text};
  font-weight: 700;
`;

export const priceCSS = css`
  ${text32};
  color: ${colors.darkGreen};
  font-weight: 700;

  span {
    ${text};
  }
`;

export const priceDescriptionCSS = css`
  color: ${colors.dove};
  ${text12};
  text-align: center;
  margin-top: 4px;
`;

export const featuredOfferCSS = css`
  color: ${colors.white};
  background-color: ${colors.azure62};
  ${text};
  padding: 12px 16px;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const productNameCSS = css`
  font-size: 20px;
  color: ${colors.black};
  font-weight: 700;
  line-height: 28px;
`;

export const policyTypeCSS = css`
  ${text14};
  margin-bottom: 12px;
`;

export const dividerTextCSS = css`
  text-align: center;
  ${text};
  color: ${colors.dove};
`;

export const quoteDividerCSS = css`
  margin: 56px auto 32px;
  width: 76px;
`;

export const wrapperCSS = (isFeatured: boolean): SerializedStyles => css`
  ${isFeatured &&
  css`
    display: flex;
    flex-direction: column;
  `}
`;

export const detailsWrapperCSS = css`
  padding: 0 32px 32px;
  display: flex;
  flex-direction: column;
  color: ${colors.dove};
  ${text};
`;

export const detailsLabelCSS = css`
  font-weight: 700;
  color: ${colors.black};
  margin-bottom: 20px;
`;

export const carrierDetailsWrapperCSS = css`
  display: flex;
  flex-direction: column;

  div {
    margin: 10px 0;
  }

  ${mq[0]} {
    text-align: start;
    flex-direction: row;
    ${text14};

    div {
      margin: 0 40px 0 0;
    }
  }
`;

export const carrierDetailCSS = css`
  display: flex;
  align-items: center;

  svg {
    margin-right: 12px;
  }
`;
