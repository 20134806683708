/** @jsxImportSource @emotion/react */
import React from 'react';

import MaskedInput from 'react-input-mask';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { isValid, parseISO } from 'date-fns';

import { FormFieldProps, Validation } from 'src/interfaces/IQuestion';
import dynamicValidations from 'src/utils/dynamicValidations';
import { focusHelperCSS, inputCSS, inputWrapper, prefilledValueCSS } from 'src/shared/components/Input/Input.style';
import { FS_SENSITIVE_DATA_CLASS } from 'src/constants/fullStory';
import { normalizeDateValue, parseDateValue } from 'src/utils/date';
import { useParams } from 'react-router-dom';
import { DefaultLocation, Flows } from 'src/interfaces/IPage';

interface MaskInputProps extends FormFieldProps {
  hasError?: boolean;
  mask?: string;
  validation?: Validation;
}

const DateInput: React.FC<MaskInputProps> = ({
  hasError = false,
  key_name,
  isDisabled,
  inputId,
  type = 'text',
  placeholder,
  mask,
  validation,
  tracking_forbidden = false
}) => {
  const { flow } = useParams() as DefaultLocation;
  const isLifeFlowVariant = flow === Flows.Life;
  const { getValues } = useFormContext();

  const rules = {
    ...validation?.static,
    validate: {
      isValidDate: (value: string | null): string | boolean =>
        !value || isValid(parseISO(value || '')) || 'Please enter a valid date',
      ...(validation?.dynamic && {
        [validation.dynamic]: (value: string) =>
          dynamicValidations[validation.dynamic!](value, { maxAge: getValues().person_tobacco_use === 'yes' ? 70 : 74 })
      })
    }
  };

  if (rules?.pattern?.value) {
    rules.pattern.value = new RegExp(rules.pattern.value);
  }

  return (
    <Controller
      name={key_name}
      key={key_name}
      rules={rules as RegisterOptions}
      render={({ field: { onChange, value, onBlur, name, ref } }) => (
        <div css={inputWrapper}>
          <MaskedInput
            className={tracking_forbidden ? FS_SENSITIVE_DATA_CLASS.MASK : ''}
            css={[
              inputCSS(hasError, isDisabled, false, isLifeFlowVariant, true),
              prefilledValueCSS(!!value, isLifeFlowVariant)
            ]}
            id={inputId}
            inputRef={(el: HTMLInputElement) => {
              ref(el);
            }}
            mask={mask as string}
            name={name}
            type={type}
            onChange={e => {
              const value = normalizeDateValue(e.target.value);
              onChange(value);
              if (e.target.value !== value) {
                onBlur();
              }
            }}
            value={parseDateValue(value || '')}
            disabled={isDisabled}
            placeholder={placeholder}
            onBlur={onBlur}
          />
          <div css={focusHelperCSS} className="input-focus-helper" />
        </div>
      )}
    />
  );
};

export default DateInput;
