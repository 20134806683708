import { css } from '@emotion/react';
import { colors, mq } from 'src/theme/variables';

export const formCSS = css`
  width: 100%;

  & > div:first-of-type {
    margin-top: 24px;
  }

  & > div {
    margin-top: 16px;
  }
`;

export const submitErrorCSS = css`
  width: 100%;
  border-radius: 4px;
  border: 1px solid ${colors.red};
  padding: 12px;
  box-sizing: border-box;
  margin-top: 32px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  font-weight: 500;

  span {
    color: ${colors.red};
    font-weight: 700;
  }

  img {
    margin-right: 8px;
  }

  ${mq[0]} {
    padding: 20px 24px;

    img {
      margin-right: 14px;
    }
  }
`;
