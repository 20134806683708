import { css, SerializedStyles } from '@emotion/react';
import { mq, sizes, colors } from 'src/theme/variables';
import { text14, text20, text, text12 } from 'src/theme/typography';

export const containerCSS = css`
  padding: 28px 34px 0;

  ${mq[0]} {
    padding-top: 32px;
  }

  ${mq[1]} {
    padding-top: 68px;
  }
`;

export const listBlockCSS = css`
  display: flex;
  flex-direction: column;
  ${mq[1]} {
    flex-direction: row;
  }
`;

export const userProfileDetailsCSS = css`
  margin-bottom: 40px;
  margin-left: -${sizes.paddingBodyHorizontalMobileDense}px;
  margin-right: -${sizes.paddingBodyHorizontalMobileDense}px;
  ${mq[0]} {
    margin-left: 0;
    margin-right: 0;
  }
  ${mq[1]} {
    width: 21%;
    max-width: 300px;
    flex-shrink: 0;
    margin-bottom: 0;
    margin-right: 40px;
  }
  ${mq[2]} {
    width: 300px;
  }
`;

export const contentWrapperCSS = css`
  flex-grow: 1;
`;

export const listWrapperCSS = css`
  margin-bottom: -12px;
  ${mq[1]} {
    margin-bottom: -16px;
  }
`;

export const quoteCardCSS = (recommended: boolean): SerializedStyles => css`
  margin-bottom: 12px;
  margin-left: -${sizes.paddingBodyHorizontalMobileDense}px;
  margin-right: -${sizes.paddingBodyHorizontalMobileDense}px;

  ${mq[0]} {
    margin-left: 0;
    margin-right: 0;
  }
  ${mq[1]} {
    margin-bottom: ${(recommended ? 5 : 4) * 4}px;
  }
`;

export const otherCarriersCSS = css`
  padding-top: 40px;
  ${mq[1]} {
    padding-top: 60px;
  }
`;

export const calMeNowWrapperCSS = css`
  margin: 52px 0;
`;

export const bundleWrapperCSS = css`
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
`;

export const bundleDiscountLabelCSS = css`
  ${mq[0]} {
    ${text20};
  }
`;

export const heroContainerCSS = (shouldRender: boolean): SerializedStyles => css`
  ${shouldRender &&
  css`
    padding: 0;
  `}
`;

export const bndlMsgBannerCSS = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
  gap: 16px;
  background-color: ${colors.paleBlue};
  padding: 16px;
  border-radius: 4px;
  font-weight: 500;
  position: relative;
  width: 100%;
  text-align: center;
  left: ${-sizes.paddingBodyHorizontalMobileDense}px;

  ${mq[0]} {
    flex-direction: row;
    margin-left: 0;
    margin-right: 0;
    left: 0;
    width: unset;
    text-align: left;
  }
`;

export const accordBndlWrapperCSS = css`
  background-color: ${colors.babyBlue};
  padding: 24px 16px 20px;
  border-radius: 8px;
  ${text};
  color: ${colors.mineshaft};
  font-weight: bold;

  span {
    ${text14};
    font-weight: 500;
    margin-top: 8px;
    line-height: 20px;
  }

  ${mq[0]} {
    ${text20};
    padding: 24px;

    span {
      ${text};
    }
  }

  img {
    width: 66px;
    margin-left: 4px;

    ${mq[0]} {
      width: 76px;
    }
  }
`;

export const tagCSS = css`
  padding: 2px 3px 2px 5px;
  text-align: center;
  background-color: #1cb27c;
  color: white;
  border-radius: 11px;
  ${text12};
  font-weight: 700;
  width: 81px;
  margin-bottom: 8px;

  ${mq[0]} {
    ${text14};
    width: 96px;
  }
`;

export const bndlImgsWrapperCSS = css`
  display: flex;
  align-items: end;

  ${mq[0]} {
    margin-right: 14px;
  }
`;

export const bndlHeaderCSS = css`
  margin-bottom: 8px;
`;

export const phoneNumberCSS = css`
  font-weight: bold;
  color: ${colors.azure};
  border-bottom: 1px solid ${colors.azure};

  &:hover {
    color: ${colors.azure};
  }
`;
