import { BaseForm, Form } from 'src/interfaces/form.types';
import { Answers } from 'src/interfaces/IAnswer';
import { Option, QuestionSwitchProps, QuestionTypes } from 'src/interfaces/IQuestion';

import validateRules, { Rule } from './ruleValidator.utils';
import * as Sentry from '@sentry/browser';

const getFirstProperty = (obj: Record<string, any>) => Object.keys(obj)[0];

export const getVisibleFormPart = <T extends { visibility_conditions?: Rule[] }>(
  formPart: T[],
  answers: Answers
): T[] => formPart.filter(part => validateRules(answers, part.visibility_conditions));

const getDimmedForms = (forms: BaseForm[], answers: Answers): Form[] => {
  let isPreviousDimmed = false;

  return forms.map((form, i) => {
    if (isPreviousDimmed || (i !== 0 && !hasAnswers(forms[i - 1].fields, answers))) {
      isPreviousDimmed = true;
      return { isDimmed: true, ...form };
    } else {
      return form;
    }
  });
};

export const getQuestionForms = (answers: Answers, schemaForms?: BaseForm[]): Form[] => {
  if (!schemaForms) return [];

  const visibleForms = getVisibleFormPart<BaseForm>(schemaForms, answers);

  return getDimmedForms(visibleForms, answers);
};

export const hasAnswers = (fields: QuestionSwitchProps[], answers: Answers): boolean => {
  // TODO Change this case to `!f.static.validation.required` when all required fields will have validation.required
  const optionalFieldsKeys = ['driver_license_number', 'driver_license_state'];
  const filteredRequiredFields = fields.filter(f => !optionalFieldsKeys.includes(f.key_name));

  return (
    filteredRequiredFields
      // TODO Change this case to `!f.static.validation.required` when all required fields will have validation.required
      .filter(
        f =>
          ![QuestionTypes.Checkbox, QuestionTypes.Slider, QuestionTypes.UploadFileInputFlorida].includes(
            f.type as QuestionTypes
          )
      )
      .every(f => {
        switch (true) {
          case typeof answers[f.key_name] === 'object' && answers[f.key_name] !== null:
            return Object.keys(answers[f.key_name] as Partial<Option>).length > 0;
          default:
            return !!answers[f.key_name] || answers[f.key_name] === 0;
        }
      })
  );
};

// TODO: Need refactoring. Add to all required fields validation in schema. Rewrite this function
export const questionHasRequiredFields = (question: Form): boolean => {
  const OPTIONAL_FIELDS = [QuestionTypes.Checkbox, QuestionTypes.Slider, QuestionTypes.UploadFileInputFlorida];
  return question.fields.some(f => !OPTIONAL_FIELDS.includes(f.type));
};

export const scrollToError = (errors: Record<string, any>): void => {
  const firstErrorKey = getFirstProperty(errors);
  let inputKey;
  if (Array.isArray(errors[firstErrorKey])) {
    const index = errors[firstErrorKey].findIndex((el: Record<string, string>) => !!el);
    inputKey = `${firstErrorKey}[${index}].${getFirstProperty(errors[firstErrorKey][index])}`;
  } else {
    inputKey = firstErrorKey;
  }
  let inputToScroll = document.getElementById(inputKey);
  if (!inputToScroll) {
    /** If it is radio button, each input has id as "key" + "index" */
    inputToScroll = document.getElementById(`${inputKey}[0]`);
  }

  if (inputToScroll) {
    inputToScroll.focus({ preventScroll: true });
    inputToScroll.scrollIntoView({ behavior: 'smooth', block: 'center' });
  } else {
    Sentry.captureException(new Error('Failed to scroll to error input'), {
      extra: { inputKey }
    });
  }
};
