import ProtectiveDevices from 'src/assets/svg/underwriting-details/protective-devices.svg';
import FireAlarm from 'src/assets/svg/underwriting-details/fire-alarm.svg';
import SecuritySystem from 'src/assets/svg/underwriting-details/security-system.svg';
import SprinklerSystem from 'src/assets/svg/underwriting-details/sprinkler-system.svg';
import SwimmingPool from 'src/assets/svg/underwriting-details/swimming-pool.svg';
import Trampoline from 'src/assets/svg/underwriting-details/trampoline.svg';
import PawPrint from 'src/assets/svg/underwriting-details/paw-print.svg';
import Key from 'src/assets/svg/underwriting-details/key.svg';
import PalmTree from 'src/assets/svg/underwriting-details/palm-tree.svg';

import { UserProfileDetailWithIcon } from './UserProfileDetailsIconList.types';
import { DISCOUNTS, KNOCKOUT_QUESTIONS } from 'src/constants/hardcoded';

export const getDetailsDataByKey = (key: string): UserProfileDetailWithIcon => {
  switch (key) {
    case DISCOUNTS.FIRE_ALARM:
      return { key, label: 'Fire alarm', icon: FireAlarm, iconAlt: 'Alarm' };
    case DISCOUNTS.PROTECTIVE_DEVICES:
      return { key, label: 'Protective devices', icon: ProtectiveDevices, iconAlt: 'Extinguisher' };
    case DISCOUNTS.SECURITY_SYSTEM:
      return { key, label: 'Security system', icon: SecuritySystem, iconAlt: 'Finger scanner' };
    case DISCOUNTS.SPRINKLER_SYSTEM:
      return { key, label: 'Sprinkler system', icon: SprinklerSystem, iconAlt: 'Sprinkler' };
    case KNOCKOUT_QUESTIONS.DANGEROUS_TRAMPOLINE:
      return { key, label: 'Trampoline without fence or net', icon: Trampoline, iconAlt: 'Trampoline' };
    case KNOCKOUT_QUESTIONS.DANGEROUS_POOL:
      return {
        key,
        label: 'Swimming pool without fence',
        icon: SwimmingPool,
        iconAlt: 'Swimming pool'
      };
    case KNOCKOUT_QUESTIONS.DANGEROUS_DOG:
      return { key, label: 'Dog on the property', icon: PawPrint, iconAlt: 'Paw print' };
    case KNOCKOUT_QUESTIONS.TENANTS:
      return { key, label: 'Tenants on property', icon: Key, iconAlt: 'Key' };
    case KNOCKOUT_QUESTIONS.SECONDARY_HOME:
      return { key, label: 'Secondary or vacation home', icon: PalmTree, iconAlt: 'Palm tree' };
    default:
      return { key, label: key, icon: '', iconAlt: '' };
  }
};
