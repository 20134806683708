import { Person } from './IAllFunnel';

export interface LookupResponse extends Partial<Person> {
  status: LookupStatusKeys;
  session_gid: string | null;
}

export enum LookupStatusKeys {
  CUSTOMER_NOT_FOUND = 'customer_not_found',
  SUCCESS = 'success',
  SESSION_LOCKED = 'session_locked'
}
