import { Greeting as GreetingProps, Page, PageAction } from 'src/interfaces/IPage';
import { BaseForm } from 'src/interfaces/form.types';
import { UploadFilesComponentProps } from 'src/components/UploadFilesComponent/UploadFilesComponent.types';

export enum QuestionsFormPages {
  Entity = 'pages_entity',
  Default = 'pages_default',
  Consent = 'pages_consent'
}

export interface QuestionsFormPageProps extends Page {
  greeting?: GreetingProps;
  questions?: BaseForm[];
  action: PageAction;
  isCurrent: boolean;
  show_consent?: boolean;
  key_name: string;
  triggerSchemaUpdate?(): void;
  onPageSubmit(): Promise<any>;
  uploadFilesWrapperParams?: UploadFilesComponentProps;
  isFormSubmitting: boolean;
}
