import React, { Fragment, useEffect } from 'react';

import { NavigationItemDetailsComponentProps } from 'src/shared/components/Navigation/NavigationItem/NavigationItem.types';
import { useWatch } from 'react-hook-form';
import { DriverEntity, EducationKeys, MaritalStatusKeys } from 'src/constants/schemaKeys';
import NavigationItemDetails from 'src/shared/components/Navigation/NavigationItemDetails/NavigationItemDetails';
import { scrollToNode } from 'src/utils/scroll';
import PersonIcon from 'src/assets/svg/person.svg';
import { getAge } from 'src/utils/date';
import { capitalize, formatPlurality } from 'src/utils/stringHelpers';
import { DriverDetailsViolations } from './DriverDetails.types';

const DriverDetails: React.FC<NavigationItemDetailsComponentProps> = ({ keyName, onDataReady }) => {
  const drivers = useWatch({ name: keyName });

  useEffect(() => {
    if (drivers?.length) {
      onDataReady();
    }
  }, [drivers, onDataReady]);

  const getDriverDetails = (driver: DriverEntity, violations?: DriverDetailsViolations): string[] => {
    const details: string[] = [];

    if (driver.person_date_of_birth || driver.person_gender) {
      const age = getAge((driver.person_date_of_birth as string) || '');
      const res = `${typeof age === 'number' ? `${age}, ` : ''}${capitalize(driver.person_gender as string)}`;
      res && details.push(res);
    }
    driver.person_marital_status &&
      details.push(MaritalStatusKeys[driver.person_marital_status as keyof typeof MaritalStatusKeys]);
    driver.person_education_level &&
      details.push(EducationKeys[driver.person_education_level as keyof typeof EducationKeys]);

    if (violations) {
      details.push(
        `${formatPlurality('Accident', violations.accidentsSum)},
        ${formatPlurality('Claim', violations.claims)},
        ${formatPlurality('Violation', violations.violationsSum)}`
      );
    }

    return details;
  };

  if (!drivers?.length) {
    return null;
  }

  return (
    <Fragment>
      {drivers.map((driver: DriverEntity, index: number) => {
        const hasAccidentsOrViolations = driver.driver_accidents_or_violations === 'no';
        const accidentsSum = hasAccidentsOrViolations
          ? 0
          : (driver.driver_accidents_accdamocaf_count as number) + (driver.driver_accidents_accnaf_count as number);
        const claims = hasAccidentsOrViolations ? 0 : (driver.driver_claims_othernonacc_count as number);
        const violationsSum = hasAccidentsOrViolations
          ? 0
          : (driver.driver_violations_dwi_count as number) +
            (driver.driver_violations_other_count as number) +
            (driver.driver_violations_reckless_count as number) +
            (driver.driver_violations_speed25_count as number) +
            (driver.driver_violations_speed19_count as number);
        const isAccViolationsMessageVisible = !isNaN(accidentsSum) && !isNaN(claims) && !isNaN(violationsSum);
        const violations = isAccViolationsMessageVisible ? { accidentsSum, claims, violationsSum } : undefined;
        return (
          <NavigationItemDetails
            key={index}
            testId="driver-scroll-button"
            hasSensitiveData
            iconSrc={PersonIcon}
            header={`${driver.person_first_name} ${driver.person_last_name}`}
            details={getDriverDetails(driver, violations)}
            onDetailClick={() => scrollToNode(`drivers.${index}`)}
          />
        );
      })}
    </Fragment>
  );
};

export default DriverDetails;
