/** @jsxImportSource @emotion/react */
import React, { Fragment } from 'react';

import { links, paragraph, socialLinks } from './FooterLinks.style';
import { EXTERNAL_URLS, SVGUrls } from 'src/constants/urls';
import IconButton from 'src/kit/IconButton/IconButton';
import { FooterLinksProps } from 'src/kit/Footer/types';
import Anchor from 'src/kit/Anchor/Anchor';
import noop from 'src/utils/noop';

const FooterLinks: React.FC<FooterLinksProps> = ({ phone, onSocialLinkClick = noop, customCSS }) => {
  return (
    <Fragment>
      <div css={[customCSS, links]}>
        <p css={paragraph}>
          <Anchor anchorType="single-color" target="_blank" href={EXTERNAL_URLS.MATIC_WEBSITE}>
            We
          </Anchor>{' '}
          Have Solutions for{' '}
          <Anchor anchorType="single-color" target="_blank" href={EXTERNAL_URLS.WEBSITE_CONSUMERS}>
            Consumers
          </Anchor>
          ,{' '}
          <Anchor anchorType="single-color" target="_blank" href={EXTERNAL_URLS.WEBSITE_ORIGINATORS}>
            Originators
          </Anchor>
          ,{' '}
          <Anchor anchorType="single-color" target="_blank" href={EXTERNAL_URLS.WEBSITE_SERVICERS}>
            Servicers
          </Anchor>
          .
        </p>
        <p css={paragraph}>
          View Our{' '}
          <Anchor anchorType="single-color" target="_blank" href={EXTERNAL_URLS.WEBSITE_PRIVACY_POLICY}>
            Privacy Policy
          </Anchor>
          ,{' '}
          <Anchor anchorType="single-color" target="_blank" href={EXTERNAL_URLS.WEBSITE_LICENSE_NUMBERS}>
            License Numbers
          </Anchor>
          ,{' '}
          <Anchor anchorType="single-color" target="_blank" href={EXTERNAL_URLS.WEBSITE_TERMS}>
            Terms of Use
          </Anchor>
          .
        </p>
      </div>
      <div css={socialLinks}>
        <IconButton
          wrapperElement="a"
          target="_blank"
          href={EXTERNAL_URLS.SOCIAL_TWITTER}
          src={SVGUrls.Twitter}
          iconText="Twitter (opens a new window)"
          iconType="single-color"
          alt="Twitter link"
        />
        <IconButton
          wrapperElement="a"
          target="_blank"
          href={EXTERNAL_URLS.SOCIAL_LINKEDIN}
          src={SVGUrls.Linkedin}
          iconText="Linkedin (opens a new window)"
          iconType="single-color"
          alt="Linkedin link"
        />
        <IconButton
          wrapperElement="a"
          target="_blank"
          href={EXTERNAL_URLS.SOCIAL_FACEBOOK}
          src={SVGUrls.Facebook}
          iconText="Facebook (opens a new window)"
          iconType="single-color"
          alt="Facebook link"
        />
        <IconButton
          wrapperElement="a"
          target="_blank"
          href={EXTERNAL_URLS.SOCIAL_INSTAGRAM}
          src={SVGUrls.Instagram}
          iconText="Instagram (opens a new window)"
          iconType="single-color"
          alt="Instagram link"
        />
        <IconButton
          wrapperElement="a"
          href={`tel:${phone}`}
          src={SVGUrls.Phone}
          iconText="Phone number"
          iconType="single-color"
          onClick={() => onSocialLinkClick('phone')}
          alt="Phone link"
        />
      </div>
    </Fragment>
  );
};

export default FooterLinks;
