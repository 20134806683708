/** @jsxImportSource @emotion/react */

import React from 'react';
import {
  containerCSS,
  headingCSS,
  rowWrapperCSS,
  headingWrapperCSS,
  dividerCSS,
  itemCSS,
  descriptionWrapperCSS,
  cardWrapperCSS,
  itemHeadingCSS,
  subHeadingCSS,
  subHeaderCSS
} from './SoftStopPage.style';
import SIRV from 'src/constants/sirv';
import SoftStopImg from 'src/assets/svg/soft-stop-image.svg';
import HorizontalDivider from 'src/assets/svg/horisontal-divider.svg';
import useResponsive from 'src/hooks/useResponsive';

interface SoftStopPageProps {
  userName: string | undefined | null;
}

const SoftStopPage: React.FC<SoftStopPageProps> = ({ userName }) => {
  const { isMobile } = useResponsive();

  return (
    <div css={containerCSS} data-testid="no-quotes-soft-stop">
      <div css={rowWrapperCSS}>
        <div css={headingWrapperCSS}>
          <div css={headingCSS}> {userName}, we&apos;re sorry!</div>
          <div css={subHeaderCSS}>
            We don&apos;t seem to have any home insurance options for you in our network at the moment.{' '}
          </div>
        </div>

        <img css={dividerCSS} src={isMobile ? HorizontalDivider : SIRV.VERTICAL_DIVIDER} alt="Vertical divider" />
        <div css={descriptionWrapperCSS}>
          Reasons for this include the age of your home, your property&apos;s natural disaster risk, or limited carrier
          availability in your area.
        </div>
      </div>
      <div>
        <div css={subHeadingCSS}>What should I do next...</div>
        <div css={cardWrapperCSS}>
          <div>
            <div css={itemCSS}>
              <div css={itemHeadingCSS()}>
                Keep an eye out for our RateReview<sup>®</sup> alerts
              </div>
              Matic continues to work for you. If a home insurance option comes available in our carrier network,
              we&apos;ll notify you through our rate monitoring service.
            </div>
            <div css={itemCSS}>
              <div css={itemHeadingCSS(true)}>Check your local options</div>
              You can also work with state-run providers and local agencies to understand your home insurance options.
            </div>
          </div>
          <img src={SoftStopImg} alt="Scales And Magnifying Glass" />
        </div>
      </div>
    </div>
  );
};

export default SoftStopPage;
