import { css } from '@emotion/react';

export const visuallyHidden = css`
  height: 1px;
  left: -10000px;
  overflow: hidden;
  position: absolute;
  top: auto;
  width: 1px;
`;

export const activeElementReset = css`
  background: transparent;
  border: 0;
  cursor: pointer;
  font-family: inherit;
  outline: none;
  padding: 0;
`;
