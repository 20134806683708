import React, { useEffect } from 'react';
import useInitCalendly from 'src/hooks/useInitCalendly';
import { useParams } from 'react-router-dom';
import { DefaultLocation } from 'src/interfaces/IPage';
import useConfig from 'src/api/config/useConfig';
import useQuestions from 'src/api/questions/useQuestions';

const ScheduleCallPopupWidget: React.FC = () => {
  const { flow, gid } = useParams() as DefaultLocation;
  const { data: config } = useConfig(gid);
  const { data: questions, isLoading } = useQuestions(gid);

  const { url } = config?.integrations?.calendly || {};

  const first_name = questions?.answers?.person_first_name as string;
  const last_name = questions?.answers?.person_last_name as string;
  const phone = questions?.answers?.person_phone as string;
  const email = questions?.answers?.person_email as string;

  const { calendlyInit } = useInitCalendly({
    gid,
    flow,
    url,
    name: `${first_name} ${last_name}`,
    phone,
    email,
    isPopup: true
  });

  useEffect(() => {
    if (!isLoading) {
      calendlyInit();
    }
  }, [calendlyInit, isLoading]);

  return <div data-testid="calendly-popup-widget" />;
};

export default ScheduleCallPopupWidget;
