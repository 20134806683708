/** @jsxImportSource @emotion/react */
import React from 'react';

import Wrapper from 'src/kit/Wrapper/Wrapper';
import { containerCSS, containerSingleColor, image, imageSingleColor } from './IconButton.style';
import { IconButtonProps } from './types';
import { visuallyHidden } from 'src/theme/helpers';

const IconButton: React.FC<IconButtonProps> = ({
  iconType = 'default',
  src,
  alt,
  wrapperElement = 'button',
  iconText,
  target,
  customCSS,
  ...props
}) => {
  const singleColor = iconType === 'single-color';

  const elProps = {
    ...props,
    rel: wrapperElement === 'a' && target === '_blank' ? 'noopener noreferrer' : undefined,
    type: wrapperElement === 'button' ? 'button' : undefined,
    target
  };

  return (
    <Wrapper tag={wrapperElement} css={[customCSS, containerCSS, singleColor && containerSingleColor]} {...elProps}>
      <img src={src} css={[image, singleColor && imageSingleColor]} alt={alt} />
      {iconText && <span css={visuallyHidden}>{iconText}</span>}
    </Wrapper>
  );
};

export default IconButton;
