import { QuotePremiumType } from 'src/interfaces/IQuotes';

export const LIFE_QUOTES = [
  {
    gid: '123',
    carrier: {
      key: 'matic',
      name: 'Matic',
      logo_url: 'https://maticinsurance.sirv.com/matic-logo/matic-header-logo-for-consumers.png',
      description: 'The Specific Loan Name Goes Here',
      foundation: '1999'
    },
    premium: {
      type: QuotePremiumType.Annual,
      value: 0
    },
    policy_name: 'Policy name',
    policy_type: 'Policy type',
    eligibility: 'eligible'
  },
  {
    gid: '1234',
    carrier: {
      key: 'matic',
      name: 'Matic',
      logo_url: 'https://maticinsurance.sirv.com/matic-logo/matic-header-logo-for-consumers.png',
      description: 'The Specific Loan Name Goes Here'
    },
    premium: {
      type: QuotePremiumType.Annual,
      value: 0
    },
    policy_name: 'Policy name',
    policy_type: 'Policy type',
    eligibility: 'eligible'
  },
  {
    gid: '12345',
    carrier: {
      key: 'matic',
      name: 'Matic',
      logo_url: 'https://maticinsurance.sirv.com/matic-logo/matic-header-logo-for-consumers.png',
      description: 'The Specific Loan Name Goes Here'
    },
    premium: {
      type: QuotePremiumType.Annual,
      value: 0
    },
    policy_name: 'Policy name',
    policy_type: 'Policy type',
    eligibility: 'eligible'
  }
];
