import { css, SerializedStyles } from '@emotion/react';
import { actionColors, colors, mq, sizes } from 'src/theme/variables';
import { text } from 'src/theme/typography';

export const container = (): SerializedStyles => css``;

export const tabs = css`
  background-color: ${colors.mercury};
  padding: 0 ${sizes.paddingBodyHorizontalMobile}px;
  margin: 0 -${sizes.paddingBodyHorizontalMobile}px 24px;
  ${mq[0]} {
    padding: 0 24px;
    margin: 0 -24px 24px;
  }
`;

export const tabsInner = css`
  position: relative;
`;

export const buttonWrapperCSS = (renderRevers?: boolean): SerializedStyles => css`
  display: flex;

  ${renderRevers &&
  css`
    flex-direction: row-reverse;
    justify-content: left;
  `};
`;

export const tabButton = (selected: boolean): SerializedStyles => css`
  ${text};
  font-weight: ${selected ? 700 : 500};
  min-width: 120px;
  padding: 20px 0;
  margin-left: 16px;
  border: unset;
  background-color: transparent;
  cursor: pointer;
  &:first-of-type {
    margin-left: 0;
  }
`;

export const tabIndicator = (left: number, width: number): SerializedStyles => css`
  display: block;
  content: '';
  position: absolute;
  left: ${left}px;
  bottom: 0;
  width: ${width}px;
  height: 8px;
  background-color: ${actionColors.primary};
  transition: left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;
