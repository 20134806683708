/** @jsxImportSource @emotion/react */
import React, { Fragment, useState } from 'react';

import CoverageInfo from 'src/components/Quotes/AutoQuotes/CoverageOptions/CoverageInfo/CoverageInfo';
import { CoverageTypeOptions } from 'src/constants/coverageTypes';
import { CoverageAutoKeys } from 'src/constants/coveragesAuto';
import { CoverageType, CoverageTypeOption } from 'src/interfaces/ICoverageTypes';
import { Asset } from 'src/interfaces/ICoverages';
import CoverageShields from 'src/assets/svg/coverages-sprite.svg';
import CoverageAuto from 'src/assets/svg/coverage-auto.svg';
import useResponsive from 'src/hooks/useResponsive';
import CoverageComparisonSelect from 'src/components/Quotes/AutoQuotes/CoverageOptions/CoverageComparisonSelect/CoverageComparisonSelect';
import {
  coverageOptionIcon,
  coverageOptionLabel,
  coverageLabelDescription,
  title,
  delimiter,
  heading,
  headingIcon,
  headingLabel,
  table,
  tableTd,
  tableTh
} from './CoverageComparison.style';
import { getCoveragePrintValue, isEmptyCoverage } from 'src/utils/quotesPage';
import { CoverageComparisonProps } from './CoverageComparison.types';

const CoverageComparison: React.FC<CoverageComparisonProps> = ({ assets }) => {
  const [type, setType] = useState<CoverageType>(CoverageType.Basic);
  const [expanded, setExpanded] = useState<string | null>(null);
  const { isMobile } = useResponsive();

  const isEven = (index: number): boolean => index % 2 === 0;

  const renderTableHeaderRow = () => {
    return (
      <Fragment>
        <div css={[tableTh, title]}>Coverage Options</div>
        {CoverageTypeOptions.map(({ value, label }) => (
          <div css={tableTh} key={value}>
            <img css={coverageOptionIcon} src={`${CoverageShields}#${value}red`} alt={`${label} Coverage`} />
            <span css={coverageOptionLabel}>{label}</span>
          </div>
        ))}
        <div css={delimiter} />
      </Fragment>
    );
  };

  const renderCoverages = (asset: Asset) => {
    const filterByType = (typeOption: CoverageTypeOption): boolean => {
      if (isMobile) {
        return typeOption.value === type;
      }
      return true;
    };

    return asset.coverages.map((coverage, i) => (
      <Fragment key={`${asset.asset_gid}-${coverage.key}`}>
        <div css={tableTd(isEven(i), true)}>
          <CoverageInfo
            customCSS={{ expander: coverageLabelDescription }}
            coverageKey={coverage.key as CoverageAutoKeys}
            expandKey={`${asset.asset_gid}-${coverage.key}`}
            isExpandable={!isMobile}
            isOpen={`${asset.asset_gid}-${coverage.key}` === expanded}
            onExpand={setExpanded}
          />
        </div>

        {CoverageTypeOptions.filter(filterByType).map(({ value }) => (
          <div css={tableTd(isEven(i))} key={`${value}-${coverage.key}`}>
            {!coverage[value] || isEmptyCoverage(coverage[value])
              ? 'Not Covered'
              : getCoveragePrintValue(coverage[value])}
          </div>
        ))}
      </Fragment>
    ));
  };

  return (
    <Fragment>
      {isMobile && <CoverageComparisonSelect type={type} onSelectType={setType} />}
      <div css={table}>
        {!isMobile && renderTableHeaderRow()}
        {assets.map((asset, i) => (
          <Fragment key={asset.asset_gid}>
            <div css={heading(i === 0)}>
              <img css={headingIcon} src={CoverageAuto} alt="Vehicle" />
              <span css={headingLabel}>{asset.label}</span>
            </div>
            {renderCoverages(asset)}
          </Fragment>
        ))}
      </div>
    </Fragment>
  );
};

export default CoverageComparison;
