/** @jsxImportSource @emotion/react */
import React, { useEffect, Fragment } from 'react';
import useLanding from 'src/api/landing/useLanding';
import Container from 'src/kit/Container/Container';
import {
  headerCSS,
  bgImgCSS,
  buttonCSS,
  carrierButtonCSS,
  textCSS,
  carrierCarouselWrapperCSS,
  mainBlockWrapperCSS,
  descriptionCSS,
  carrierGridWrapperCSS,
  carrierCSS,
  arrowCSS,
  maticLogoCSS,
  carouselWrapperCSS,
  headerTitleCSS
} from './UnsupportedCarrierLanding.style';
import Logo from 'src/kit/Logo/Logo';
import Button from 'src/kit/Button/Button';
import SirvImage from 'src/kit/SirvImage/SirvImage';
import Arrow from 'src/assets/svg/arrow.svg';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import Anchor from 'src/kit/Anchor/Anchor';
import { useCustomNavigate } from 'src/hooks/useCustomNavigate';
import CarriersCarousel from 'src/components/CarriersCarousel/CarriersCarousel';
import useResponsive from 'src/hooks/useResponsive';
import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';
import { landingCarriersLogoList, unsupportedCarrierDisclosure } from 'src/components/Landing/Landing.utils';

const UnsupportedCarrierLanding: React.FC = () => {
  const { search } = useLocation();
  const navigate = useCustomNavigate();
  const { isMobile } = useResponsive();
  const urlParams = queryString.parse(search?.replace('?', ''));
  const { data } = useLanding(urlParams?.carrier ? (urlParams?.carrier as string) : '');

  const carrierName = data?.carrier_name || '';
  const carrierURL = data?.carrier_url;

  useEffect(() => {
    analytics.page(SEGMENT.PAGES.LANDING, {
      partner_key: data?.partner_key
    });
  }, [data]);

  const onCarrierURlClick = () => {
    analytics.track(SEGMENT.LANDING_CONTINUE_TO_CARRIER_CLICKED, undefined, undefined, {
      carrier_name: carrierName
    });
  };

  const onFlowSelect = () => {
    analytics.track(SEGMENT.LANDING_COMPARE_QUOTES_CLICKED, undefined, undefined, {
      carrier_name: carrierName
    });
    navigate({
      pathname: '/home',
      search
    });
  };

  return (
    <Fragment>
      <Container>
        {carrierURL && (
          <Fragment>
            <div css={headerCSS}>
              <div css={headerTitleCSS}>Click here to continue to {carrierName}</div>

              <div css={carrierButtonCSS}>
                <Anchor onClick={onCarrierURlClick} href={carrierURL}>
                  Continue to {carrierName}
                </Anchor>
              </div>
            </div>
            <div css={textCSS}>Or see below to find the best offer for your needs.</div>
          </Fragment>
        )}
      </Container>

      <div css={bgImgCSS}>
        <Container>
          <div>
            <div css={mainBlockWrapperCSS}>
              <img src={Arrow} css={arrowCSS} alt="Arrow" />
              <Logo customCSS={maticLogoCSS} />
              <div>
                Shop 40+ A-rated carriers and <b>save over $500.*</b> Start your quotes now.
              </div>
              <Button css={buttonCSS} onClick={onFlowSelect}>
                Compare Quotes Now
              </Button>
            </div>
            <div css={carrierCarouselWrapperCSS}>
              <div>Our network of top carriers</div>

              {isMobile ? (
                <div css={carouselWrapperCSS}>
                  <CarriersCarousel />
                </div>
              ) : (
                <div css={carrierGridWrapperCSS}>
                  {landingCarriersLogoList.map((carrier, i) => (
                    <div key={i}>
                      <SirvImage css={carrierCSS} url={carrier.url} width={130} alt={carrier.alt} />
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div css={descriptionCSS}>{unsupportedCarrierDisclosure}</div>
          </div>
        </Container>
      </div>
    </Fragment>
  );
};

export default UnsupportedCarrierLanding;
