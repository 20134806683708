import { css } from '@emotion/react';
import { text, text20 } from 'src/theme/typography';
import { mq } from 'src/theme/variables';

export const heading = css`
  ${text};
  font-weight: 700;
  display: none;

  ${mq[0]} {
    display: block;
    margin-top: 8px;
  }

  ${mq[1]} {
    ${text20};
    margin-top: 12px;
    margin-bottom: 12px;
  }
`;

export const list = css`
  margin: 0;
  padding: 0;
`;

export const item = css`
  position: relative;
  margin-top: 8px;
  list-style: none;
`;

export const icon = css`
  position: absolute;
  left: 0;
  top: 0;
`;

export const label = css`
  ${text};
  display: block;
  font-weight: 500;
  padding-left: 28px;
`;
