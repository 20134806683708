export const getCardText = (showConfirmLayout: boolean): { heading: string; description: string } => {
  const heading = showConfirmLayout ? 'Start Over?' : 'Pick up where you left off';
  const description = showConfirmLayout
    ? 'This will discard all the answered questions and you will start again from the beginning.'
    : 'For security purposes, we have paused your session. To finalize quote, please confirm your identity below.';

  return { heading, description };
};

export const UNLOCK_ATTEMPTS_LIMIT = 4;
