import React, { Fragment, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { NavigationItemDetailsComponentProps } from 'src/shared/components/Navigation/NavigationItem/NavigationItem.types';
import { useWatch } from 'react-hook-form';
import { PropertyTypeKeys } from 'src/constants/schemaKeys';
import NavigationItemDetails from 'src/shared/components/Navigation/NavigationItemDetails/NavigationItemDetails';
import { scrollToNode } from 'src/utils/scroll';
import { AddressAnswer } from 'src/interfaces/IQuestion';
import { isObjectEmpty } from 'src/utils/general';
import HomeIcon from 'src/assets/svg/home-small.svg';
import { getReadableAddress } from 'src/utils/homeDetails';
import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';
import { DefaultLocation } from 'src/interfaces/IPage';

const HomeDetails: React.FC<NavigationItemDetailsComponentProps> = ({ onDataReady }) => {
  const arrayToWatch = ['property_address', 'property_type', 'property_square_feet'];

  const data = useWatch({ name: arrayToWatch });
  const address = data[0] as AddressAnswer;
  const propertyType = data[1] as PropertyTypeKeys;
  const squareFeet = data[2] as string;
  const { gid, flow } = useParams() as DefaultLocation;

  useEffect(() => {
    if (!isObjectEmpty(address) || propertyType || squareFeet) {
      onDataReady();
    }
  }, [address, propertyType, squareFeet, onDataReady]);

  const onEditDetailsClicked = () => {
    scrollToNode('section-home_details');

    analytics.track(SEGMENT.QUESTIONS_SIDE_BARD_EDIT_CLICKED, gid, flow, {
      location: 'home_details'
    });
  };

  const getDetails = (): string[] => {
    const res: string[] = [];
    propertyType === PropertyTypeKeys.home && res.push('Home');
    propertyType === PropertyTypeKeys.condo && res.push('Condo');
    squareFeet && res.push(`${squareFeet} Sq ft.`);
    return res;
  };

  if (isObjectEmpty(address) && !propertyType && !squareFeet) {
    return null;
  }

  return (
    <Fragment>
      <NavigationItemDetails
        testId="property-address-scroll-button"
        hasSensitiveData
        iconSrc={HomeIcon}
        header={getReadableAddress(address)}
        details={getDetails()}
        onDetailClick={onEditDetailsClicked}
      />
    </Fragment>
  );
};

export default HomeDetails;
