import { css } from '@emotion/react';
import SIRV from 'src/constants/sirv';
import { mq, colors } from 'src/theme/variables';
import { text32 } from 'src/theme/typography';

export const faqCSS = css`
  overflow-x: hidden;
`;

export const heroCSS = css`
  background-position-y: -200px;
  background-image: url(${SIRV.AF_MOBILE_BANNER});
  background-position: 99% 98%;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 660px;
  text-align: center;

  ${mq[0]} {
    background-image: url(${SIRV.AF_DESKTOP_BANNER});
  }
`;

export const componentsPaddingCSS = css`
  padding: 12px;

  ${mq[0]} {
    padding: 60px 20px;
  }

  ${mq[1]} {
    padding: 60px 12px;
  }
`;

export const componentsBackgroundCSS = css`
  background-color: ${colors.concrete};
`;

export const userProfileCSS = css`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  ${mq[0]} {
    flex-direction: row;
  }
`;

export const profileHeadingCss = css`
  display: none;

  ${mq[0]} {
    display: block;
    ${text32};
  }
`;

export const googleReviewsCSS = css`
  ${mq[1]} {
    padding: 0;
  }
`;
