import React, { useEffect, useState } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { DefaultLocation, Flows } from 'src/interfaces/IPage';

import Address from './Address';
import { QuestionWrapper } from 'src/questionsForm/components/QuestionWrapper/QuestionWrapper';

import { FormQuestionProps, AddressAnswer } from 'src/interfaces/IQuestion';
import AddressMap from './AddressMap';
import { useQuestions } from 'src/api/questions';
import compareObj from 'src/utils/compareObj';
import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';
import { FEATURE_TOGGLES_IDS_ENUM as FT } from 'src/interfaces/experiment.types';
import useInitFeatureToggles from 'src/hooks/useInitFeatureToggles/useInitFeatureToggles';

const addressValidate = (value: AddressAnswer) => (value && !!value.line1) || 'Please enter a valid address.';
const rules = { validate: addressValidate };

const AddressQuestion: React.FC<FormQuestionProps> = props => {
  const { trigger, clearErrors } = useFormContext();
  const { flow, gid } = useParams() as DefaultLocation;
  const { getValues } = useFormContext();
  const { data: questions } = useQuestions(gid);
  const [shouldRenderMap, setShouldRenderMap] = useState(true);
  const features = useInitFeatureToggles();

  const addressValue = getValues(props.key_name);

  useEffect(() => {
    const hideGoogleMapFT = features?.[FT.HIDE_GOOGLE_MAP_FT];

    if (hideGoogleMapFT?.isEnabled) {
      const shouldRender = questions?.answers[props.key_name]
        ? !compareObj(addressValue, questions?.answers[props.key_name] as AddressAnswer)
        : true;

      setShouldRenderMap(prevState => {
        if (prevState !== shouldRender) {
          analytics.track(SEGMENT.MAPS_VISIBILITY_CHANGED, gid, flow, { state: shouldRender ? 'visible' : 'hidden' });
        }

        return shouldRender;
      });
    }
  }, [addressValue, features, flow, gid, props.key_name, questions?.answers]);

  return (
    <Controller
      name={props.key_name}
      rules={rules}
      render={({ field: { onChange, value, ref } }) => {
        const onSelectChange = (v: AddressAnswer) => {
          flow === Flows.Home && clearErrors(props.key_name);
          onChange(v);
        };

        //TODO: temporary fix untill we decide if we need to set onBlur validations for more fields on BE level
        const onAddressBlur = () => {
          flow === Flows.Home && trigger(props.key_name);
        };

        return (
          <>
            {shouldRenderMap && <AddressMap id={`map-${props.key_name}`} address={value} />}
            <QuestionWrapper
              {...props}
              inputComponent={Address}
              onValidEntry={onSelectChange}
              value={value}
              onBlur={onAddressBlur}
              componentRef={ref}
            />
          </>
        );
      }}
    />
  );
};

export default AddressQuestion;
