/** @jsxImportSource @emotion/react */
import React from 'react';
import {
  wrapperCSS,
  contentWrapperCSS,
  whyWrapperCSS,
  itemWrapperCSS,
  itemCopyWrapperCSS,
  itemsWrapperCSS,
  itemDescriptionCSS,
  whyMaticCSS,
  headingCSS,
  contactBlockCSS,
  agentInfoWrapperCSS,
  agentImgCSS,
  agentInfoCSS,
  anchorsCSS
} from './AlmostDone.style';

import CheckMark from 'src/assets/svg/checkmark.svg';
import CallAgent from 'src/assets/svg/call-agent.svg';
import CallAgentMB from 'src/assets/svg/call-agent-mobile.svg';
import { ALMOST_DONE_DISCLAIMER, experimentalWhyMaticArray, whyMaticArray } from './../quotesViewUtils';
import Anchor from 'src/kit/Anchor/Anchor';
import useResponsive from 'src/hooks/useResponsive';
import PagesFooter from './../PagesFooter/PagesFooter';
import { AlmostDoneProps } from './AlmostDone.types';

const MOCK_PROPS = {
  currentPage: 4,
  pagesCount: 4
};

const AlmostDone: React.FC<AlmostDoneProps> = ({ config, proposalBuilderExperiment }) => {
  const { isMobile } = useResponsive();

  return (
    <div css={wrapperCSS}>
      <div css={contentWrapperCSS}>
        <div css={headingCSS}>You&apos;re almost done</div>

        <div css={contactBlockCSS}>
          <div css={agentInfoWrapperCSS}>
            <div css={agentInfoCSS}>
              To finish purchasing your policy, give us a call at{' '}
              <Anchor css={anchorsCSS} anchorType="underline-active" href={`tel:${config.agency_phone_number}`}>
                {config.agency_phone_number}
              </Anchor>{' '}
              or email{' '}
              <Anchor css={anchorsCSS} anchorType="underline-active" href={`mailto:${config.agency_email}`}>
                {config.agency_email}
              </Anchor>
            </div>
          </div>
          <img css={agentImgCSS} src={isMobile ? CallAgentMB : CallAgent} alt="AgentLady" />
        </div>

        <div css={whyWrapperCSS}>
          <div css={whyMaticCSS}>Why Matic?</div>
          <div css={itemsWrapperCSS}>
            {(proposalBuilderExperiment ? experimentalWhyMaticArray : whyMaticArray).map((item, i) => (
              <div css={itemWrapperCSS} key={i}>
                <img src={CheckMark} alt="Check Mark" />
                <div css={itemCopyWrapperCSS}>
                  <b>{item.label}</b>
                  <div css={itemDescriptionCSS}>{item.description}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <PagesFooter
        disclaimer={proposalBuilderExperiment ? '' : ALMOST_DONE_DISCLAIMER}
        currentPage={MOCK_PROPS.currentPage}
        pagesCount={MOCK_PROPS.pagesCount}
        variant={proposalBuilderExperiment ? 'white-styled' : 'default'}
      />
    </div>
  );
};

export default AlmostDone;
