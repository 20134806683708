import { css } from '@emotion/react';
import { colors as c, mq } from 'src/theme/variables';
import { text, text14, text20, text24 } from 'src/theme/typography';
import wavyBackgroundBlue from 'src/assets/images/wavy-bg-blue.png';

export const heroSectionCSS = css`
  background-image: none;

  ${mq[0]} {
    background: url(${wavyBackgroundBlue}) 20% 100% no-repeat;
    background-size: cover;
  }
`;

export const offerDetailsCSS = css`
  padding: 12px 24px 0 24px;

  ${mq[0]} {
    padding: 20px;
  }

  ${mq[1]} {
    padding: 32px;
  }
`;

export const detailsBlockCSS = css`
  ${mq[0]} {
    max-width: 80%;
    padding-bottom: 12px;
  }
`;

export const savingsCardCSS = css`
  min-width: 50%;
  border-radius: 8px;
  position: relative;

  ${mq[0]} {
    border-radius: 8px;
    border-right-width: 1px;
    border-left-width: 1px;
    margin-left: 0;
    margin-right: 0;
  }

  ${mq[1]} {
    width: 50%;
  }
`;

export const mobileCardTitleCSS = css`
  ${text20};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${c.white};
  background-color: ${c.cheriseLight};
  height: 40px;
  position: relative;
  top: -1px;
  line-height: 32px;
  font-weight: 700;
`;

export const cardTitleCSS = css`
  ${text20};
  margin-top: 0;

  ${mq[0]} {
    ${text24};
    margin-bottom: 8px;
  }
`;

export const cardTextCSS = css`
  ${text};
  margin-bottom: 12px;

  ${mq[0]} {
    margin-bottom: unset;
  }
`;

export const mobileBenefitsWrapperCSS = css`
  padding: 32px 0;
`;

export const benefitsTitleCSS = css`
  ${text24};

  ${mq[0]} {
    ${text};
  }
`;

export const benefitsWrapperCSS = css`
  ${mq[0]} {
    margin-top: 40px;
  }
`;

export const carrierCustomCSS = css`
  background-color: ${c.white};

  ${mq[0]} {
    background-color: ${c.white};
  }
`;

export const BBBIconCSS = css`
  margin-top: 34px;
  ${text14};
`;
