import React, { useEffect } from 'react';

import { useLocation, Outlet } from 'react-router-dom';

const ScrollToTop: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  //New component presented in react-router-dom v6.4+
  return <Outlet />;
};

export default ScrollToTop;
