/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import AgencyInfoMask from 'src/assets/svg/agency-info-photo-mask.svg?react';
import {
  agencyInfoWrapper,
  imgWrapper,
  agencyInfo,
  img,
  textWrapper,
  heading,
  headingSM,
  headingLG,
  dot,
  bigger,
  bold,
  item,
  imgWithDotWrapper,
  imgMask as imgMaskCSS
} from './AgencyInfo.style';

const HEADING_TEXT = 'The independent agency difference.';

const AgencyInfo: React.FC = () => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.to('#officeImg', {
      y: -80,
      scrollTrigger: {
        trigger: '#officeImg',
        start: 'top bottom',
        end: 'bottom top',
        scrub: 1
      }
    });
  }, []);

  return (
    <section css={agencyInfoWrapper}>
      <h2 css={[heading, headingSM]}>{HEADING_TEXT}</h2>
      <div css={agencyInfo}>
        <div css={imgWithDotWrapper}>
          <div css={imgWrapper}>
            <img
              id="officeImg"
              css={img}
              src="https://maticinsurance-cdn.sirv.com/customer-experience/office-img.jpeg"
              alt="Agency"
              loading="lazy"
            />
            <AgencyInfoMask css={imgMaskCSS} />
            <div css={dot} />
          </div>
        </div>

        <div css={textWrapper}>
          <h2 css={[heading, headingLG]}>{HEADING_TEXT}</h2>

          <div css={item}>
            <p css={bold}>Unbiased Support</p>
            <p css={bigger}>
              Our Matic advisors are here to get the best policy for you and are not paid by premiums sold. We get paid
              when you are happy.
            </p>
          </div>

          <div css={item}>
            <p css={bold}>Advocates for Life</p>
            <p css={bigger}>
              Matic will never stop working for you, which is why we’ll continually shop the best policy for you,
              year-round with our Rate Review™ monitoring service.
            </p>
          </div>

          <div css={item}>
            <p css={bold}>Double Support</p>
            <p css={bigger}>
              Let our Matic advisors help you navigate any challenges with your carrier. Leave the hard work to Matic.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AgencyInfo;
