import { css } from '@emotion/react';
import { text20 } from 'src/theme/typography';

export const inspectionMessageCSS = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 31px;
`;

export const inspectionTextCSS = css`
  flex-basis: 85%;
  line-height: 28px;
`;

export const inspectionHeadingCSS = css`
  ${text20};
  font-weight: 700;
  padding-top: 28px;
  margin-bottom: 16px;
`;
