import { IQuote, HomeCoverageKey, IQuoteCoverage } from 'src/interfaces/quotesView/IQuote';

type CoverageInfoHash = {
  [key in HomeCoverageKey]: string;
};

type HomeCoverage = {
  title: string;
  value: string;
};

type SideCopies = {
  title: string;
  description: string;
};

const CoverageHomeTitles: CoverageInfoHash = {
  [HomeCoverageKey.Dwelling]: 'Dwelling',
  [HomeCoverageKey.OtherStructures]: 'Other structures',
  [HomeCoverageKey.PersonalProperty]: 'Personal Property',
  [HomeCoverageKey.LossOfUse]: 'Loss of use',
  [HomeCoverageKey.PersonalLiability]: 'Personal Liabilities',
  [HomeCoverageKey.MedicalPayments]: 'Medical Payments',
  [HomeCoverageKey.WindHail]: 'Wind and hail',
  [HomeCoverageKey.Hurricane]: 'Hurricane'
};

export const coverageByKey = (quote: IQuote, coverageKey: HomeCoverageKey): IQuoteCoverage | undefined =>
  quote.coverages.find(({ key }) => key === coverageKey);

export const getHomeQuoteCoverages = (quote: IQuote): HomeCoverage[] => {
  const coverageByKeyValue = (coverageKey: HomeCoverageKey) =>
    coverageByKey(quote, coverageKey)?.limit_description || 'Not included';

  return [
    {
      title: CoverageHomeTitles.dwelling,
      value: coverageByKeyValue(HomeCoverageKey.Dwelling)
    },
    {
      title: CoverageHomeTitles.other_structures,
      value: coverageByKeyValue(HomeCoverageKey.OtherStructures)
    },
    {
      title: CoverageHomeTitles.personal_property,
      value: coverageByKeyValue(HomeCoverageKey.PersonalProperty)
    },
    {
      title: CoverageHomeTitles.loss_of_use,
      value: coverageByKeyValue(HomeCoverageKey.LossOfUse)
    },
    {
      title: CoverageHomeTitles.personal_liability,
      value: coverageByKeyValue(HomeCoverageKey.PersonalLiability)
    },
    {
      title: CoverageHomeTitles.medical_payments,
      value: coverageByKeyValue(HomeCoverageKey.MedicalPayments)
    },
    {
      title: CoverageHomeTitles.wind_hail,
      value: coverageByKeyValue(HomeCoverageKey.WindHail)
    },
    {
      title: CoverageHomeTitles.hurricane,
      value: coverageByKeyValue(HomeCoverageKey.Hurricane)
    }
  ];
};

const EXPERIMENTAL_DEDUCTIBLE_DESCRIPTION =
  'You can save approximately 38% on home insurance by increasing your deductible from $1,000 to $2,500.';

const EXPERIMENTAL_COVERAGE_DESCRIPTION =
  "To ensure you're fully protected, we suggest updating your dwelling coverage. " +
  'This adjustment will provide you with the necessary coverage to rebuild your home from scratch.';
export const getSideCopies = ({
  carrierName,
  aRated,
  proposalBuilderExperiment
}: {
  carrierName: string;
  aRated: boolean;
  proposalBuilderExperiment: boolean;
}): SideCopies[] => [
  {
    title: 'Your carrier',
    description:
      `${carrierName} is an ${
        aRated && 'A-rated'
      } insurance carrier. That means they’ve earned high marks from a credit rating agency ` +
      '— and they’ll be able to pay your claims if you ever need to file one.'
  },
  {
    title: 'Your coverage',
    description: proposalBuilderExperiment
      ? EXPERIMENTAL_COVERAGE_DESCRIPTION
      : 'Knowing that you’re fully protected is crucial for your peace of mind. That’s why Matic ' +
        'leveraged data to ensure that your home is covered for its full replacement cost. If your ' +
        'house is damaged by a covered event, you’ll have coverage that can help rebuild your home.'
  },
  {
    title: 'Your deductible',
    description: proposalBuilderExperiment
      ? EXPERIMENTAL_DEDUCTIBLE_DESCRIPTION
      : 'This is the amount of money you’ll pay when you file a claim — before insurance kicks in. ' +
        'We found the lowest available deductible to help you keep your out-of-pocket costs down.'
  }
];
