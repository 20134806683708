import House from 'src/assets/svg/quotes-house-with-auto.svg';
import Document from 'src/assets/svg/quotes-coverage-document.svg';
import Safe from 'src/assets/svg/quotes-safe-with-money.svg';

import SIRV from 'src/constants/sirv';

export const TALK_TO_ADVISOR_COPIES = [
  {
    img: House,
    imgAlt: 'House with car',
    heading: 'Save up to 20% by bundling',
    description:
      'Speak with an Advisor to discuss different bundling options based on your ' +
      'home, auto and driver details, then compare.'
  },
  {
    img: Document,
    imgAlt: 'Document',
    heading: 'Maximize your Coverage',
    description:
      'Flood, umbrella, wind & hail, other structures, personal property... Best protect what matters most to you. '
  },
  {
    img: Safe,
    imgAlt: 'Safe with money',
    heading: 'Unlock Additional Savings',
    description:
      'Discuss how to customize your policy, learn about lesser known savings and ' +
      'gain access to our full carrier network.'
  }
];

export const TALK_TO_ADVISOR_FLORIDA_COPIES = [
  {
    img: SIRV.FLORIDA_SIGN,
    imgAlt: 'House with car',
    heading: 'Maximize your coverage',
    description:
      'Flood, hurricane, auto, other structures, personal property... Best protect what matters most to you..'
  },
  {
    img: SIRV.NOTES,
    imgAlt: 'Document',
    heading: 'Customize your Coverage',
    description:
      'Work with a Florida Advisor to customize your home insurance policy, ' +
      'getting you the right policy at the right price.'
  },
  {
    img: SIRV.BANK,
    imgAlt: 'Safe with money',
    heading: 'Unlock Additional Savings',
    description:
      'Have a valid wind mitigation inspection? What about a security system? ' +
      'Discuss measures you can take for lower rates.'
  }
];

export const CARRIERS_ARRAY_COPIES = [
  { src: SIRV.CARRIERS.SAFECO.url, alt: SIRV.CARRIERS.SAFECO.alt },
  { src: SIRV.CARRIERS.PROGRESSIVE.url, alt: SIRV.CARRIERS.PROGRESSIVE.alt },
  { src: SIRV.CARRIERS.TRAVELERS.url, alt: SIRV.CARRIERS.TRAVELERS.alt }
];
