import DashedRow, { DashedRowProps } from 'src/kit/DashedRow/DashedRow';
import { rowCustomDashedColorCSS } from './HistoricalPremium.style';

const StyledDashedRow: React.FC<DashedRowProps & { isHighlighted?: boolean }> = ({
  label,
  content,
  isHighlighted = false
}) => {
  return <DashedRow label={label} content={content} customCSS={rowCustomDashedColorCSS(isHighlighted)} />;
};

export default StyledDashedRow;
