/** @jsxImportSource @emotion/react */
import Button from 'src/kit/Button/Button';
import { getStartedContainerCSS } from './GetStarted.style';
import { ButtonSize } from 'src/kit/Button/types';

interface GetStartedProps {
  onTalkToAdvisorClicked: (location: string) => void;
}

const GetStarted: React.FC<GetStartedProps> = ({ onTalkToAdvisorClicked }) => {
  return (
    <section css={getStartedContainerCSS}>
      <div>
        <h2>Ready to get started?</h2>
        <p>Talk with a licensed Matic advisor. Schedule a call or let us call you.</p>
      </div>
      <Button size={ButtonSize.Large} onClick={() => onTalkToAdvisorClicked('main-talk-to-advisor-section')}>
        Talk to an Advisor
      </Button>
    </section>
  );
};

export default GetStarted;
