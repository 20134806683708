/** @jsxImportSource @emotion/react */
import React from 'react';

import { SkeletonLoaderProps } from './SkeletonLoader.types';
import { loaderCSS } from './SkeletonLoader.style';

const SkeletonLoader: React.FC<SkeletonLoaderProps> = ({ customCSS, centered, height, width, minWidth, maxWidth }) => {
  const getWidth = (min = 10, max = 100, w?: number) => {
    if (!w) {
      const difference = max - min;
      let rand = Math.random();
      rand = Math.floor(rand * difference);
      rand = rand + min;
      return rand;
    }

    return w;
  };

  return (
    <div css={customCSS}>
      <div data-testid="skeleton-loader" css={loaderCSS(height, getWidth(minWidth, maxWidth, width), centered)} />
    </div>
  );
};

export default SkeletonLoader;
