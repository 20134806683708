/** @jsxImportSource @emotion/react */
import React from 'react';

import {
  wrapperCSS,
  glassCSS,
  anchorCSS,
  textWrapperCSS,
  familyImgCSS,
  headingCSS,
  yellowTriangleCSS,
  checkmarkCSS,
  descriptionCSS,
  contactLinkWrapperCSS,
  orangeTriangleCSS,
  triangleRelativeWrapperCSS,
  containerCSS,
  mobilePaddingCSS,
  heartCSS
} from './Hero.style';
import FamilyImageMobile from 'src/assets/svg/life-flow-family-mobile.png';
import FamilyImage from 'src/assets/svg/life-flow-family-desktop.png';
import YellowTriangle from 'src/assets/svg/yellow-rectangle.svg';
import OrangeTriangle from 'src/assets/svg/orange-rectangle.svg';
import Glass from 'src/assets/svg/magnifying-glass.svg?react';
import Checkmark from 'src/assets/svg/checkmark-chevron.svg';
import Container from 'src/kit/Container/Container';
import Heart from 'src/assets/svg/handwritten-heart.svg?react';
import useResponsive from 'src/hooks/useResponsive';
import { FS_SENSITIVE_DATA_CLASS } from 'src/constants/fullStory';

interface HeroProps {
  userName: string;
  onTalkToAdvisorClicked: (location: string) => void;
}

const Hero: React.FC<HeroProps> = ({ userName, onTalkToAdvisorClicked }) => {
  const { isMobile } = useResponsive();

  return (
    <Container customCSS={containerCSS}>
      <div css={wrapperCSS} data-testid="life-layout">
        <div css={textWrapperCSS}>
          <div css={headingCSS}>
            <span className={FS_SENSITIVE_DATA_CLASS.MASK}>{userName}</span>, compare life insurance quotes
          </div>
          <div css={descriptionCSS}>And help protect your family if something happens to you</div>
        </div>
        <div css={contactLinkWrapperCSS}>
          Compare now and{' '}
          <div onClick={() => onTalkToAdvisorClicked('life-header')} role="presentation" css={anchorCSS}>
            talk to a licensed advisor
          </div>{' '}
          to get started.
        </div>

        <div css={mobilePaddingCSS} />

        <img css={yellowTriangleCSS} src={YellowTriangle} alt="Yellow Triangle" />
        <Glass css={glassCSS} />
        <img css={checkmarkCSS} src={Checkmark} alt="Checkmark" />
        <div css={triangleRelativeWrapperCSS}>
          <img css={orangeTriangleCSS} src={OrangeTriangle} alt="Orange Triangle" />
        </div>

        <img css={familyImgCSS} src={isMobile ? FamilyImageMobile : FamilyImage} alt="Happy Family" />

        <Heart css={heartCSS} />
      </div>
    </Container>
  );
};

export default Hero;
