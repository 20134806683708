import { css } from '@emotion/react';
import { mq } from 'src/theme/variables';
import { headingL, text20 } from 'src/theme/typography';

export const headingCSS = css`
  text-align: center;
  margin: 0;
  ${headingL};
`;

export const descriptionCSS = css`
  padding-top: 16px;
  padding-bottom: 12px;
  text-align: center;
  ${mq[0]} {
    ${text20};
    margin: 0 auto;
    padding-top: 12px;
    width: 80%;
  }
`;

export const cardsWrapperCSS = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${mq[0]} {
    flex-direction: row;
  }
`;

export const cardCSS = css`
  max-width: 100%;
  ${mq[0]} {
    max-width: 32%;
  }
`;
