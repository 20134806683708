/** @jsxImportSource @emotion/react */
import React, { Fragment, useRef, useState } from 'react';

import { SerializedStyles } from '@emotion/react';
import { useParams } from 'react-router-dom';

import { Answers } from 'src/interfaces/IAnswer';
import { Form as FormInterface } from 'src/interfaces/form.types';
import Question from 'src/questionsForm/components/Question/Question';
import Tooltip from 'src/shared/components/Tooltip/Tooltip';
import useResponsive from 'src/hooks/useResponsive';
import stringTemplater from 'src/utils/stringTemplater';
import {
  dimmed,
  form,
  heading as headingCSS,
  modalCloseBtnCSS,
  scrollPoint,
  subHeading,
  toolTip,
  toolTipCloseBtn,
  withMargin,
  questionsWrapperCSS
} from './QuestionForm.style';
import { visuallyHidden } from 'src/theme/helpers';
import { indicator } from 'src/theme/shared-styles';
import Dialog from 'src/kit/Dialog/Dialog';
import Button from 'src/kit/Button/Button';
import { getQuestionComponentByKey } from './QuestionForm.utils';
import { QuestionComponentKey, FormComponentKey } from 'src/constants/schemaKeys';
import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';
import { DefaultLocation, Flows } from 'src/interfaces/IPage';
import { TooltipRefProps } from 'react-tooltip';
import { UploadFilesComponentProps } from 'src/components/UploadFilesComponent/UploadFilesComponent.types';
import { QuestionSwitchProps, QuestionTypes } from 'src/interfaces/IQuestion';
import { getVisibleFormPart } from 'src/questionsForm/utils/questionsForm.utils';

interface FormProps extends FormInterface {
  customCSS?: SerializedStyles;
  answers: Answers;
  isLineHidden?: boolean;
  isFieldArray?: boolean;
  formPrefix?: string;
  questionKeyName?: string;
  triggerSchemaUpdate?(): void;
  uploadFilesWrapperParams?: UploadFilesComponentProps;
}

const QuestionForm: React.FC<FormProps> = ({
  customCSS,
  fields,
  heading,
  subheading,
  component_key,
  key_name: formKeyName,
  formPrefix,
  answers,
  isDimmed = false,
  isHidden = false,
  isLineHidden = false,
  hint,
  isFieldArray = false,
  triggerSchemaUpdate,
  uploadFilesWrapperParams
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const tooltipRef = useRef<TooltipRefProps & HTMLButtonElement>(null);
  const responsive = useResponsive();
  const normalizedHeading = stringTemplater(heading, { answers });
  const QuestionComponent = getQuestionComponentByKey(component_key as QuestionComponentKey);
  const { flow, gid } = useParams() as DefaultLocation;
  const formKey = isFieldArray ? `${formPrefix}.${formKeyName}` : formKeyName;
  const isPolicyHolderQuestionViewed = useRef(false);
  const isPolicyHolderComponentViewed = useRef(false);

  //TODO: Callback to track question showed event, refactor as util
  if (isDimmed === false) {
    switch (component_key) {
      case QuestionComponentKey.PolicyHolderQuestion:
        !isPolicyHolderComponentViewed.current && analytics.track(SEGMENT.PH_INFO_SUBMITTED, gid, flow);
        isPolicyHolderComponentViewed.current = true;
        break;

      default:
        break;
    }

    switch (formKeyName) {
      case FormComponentKey.CurrentPolicyHolderQuestion:
        !isPolicyHolderQuestionViewed.current && analytics.track(SEGMENT.PH_QUESTION_SHOWN, gid, flow);
        isPolicyHolderQuestionViewed.current = true;
        break;
      default:
        break;
    }
  }

  const visibleFields = getVisibleFormPart<QuestionSwitchProps>(fields, answers);

  return !isHidden ? (
    <fieldset data-testid="form" css={[form(isLineHidden), customCSS]}>
      <legend css={visuallyHidden}>{normalizedHeading}</legend>
      <div data-testid="form-scroll-point" css={scrollPoint} id={formKey} />
      <div data-testid="form-inner">
        {
          // need to consider creating another question form
        }
        {flow !== Flows.Life && <div css={indicator} />}

        <div css={isDimmed && dimmed}>
          <div css={!subheading && withMargin}>
            <div>
              <Fragment>
                {flow !== Flows.Life && (
                  <span role="heading" aria-level={2} aria-hidden="true" css={headingCSS}>
                    {normalizedHeading}
                  </span>
                )}
                <Fragment>
                  {responsive.isDesktop && hint && (
                    <button
                      css={toolTip}
                      data-testid="tooltip"
                      data-tooltip-content=""
                      aria-label={hint}
                      ref={tooltipRef}
                      data-tooltip-id={`tooltip-${formKey}`}
                    >
                      ?
                    </button>
                  )}
                  {responsive.isDesktop ||
                    (hint && (
                      <Fragment>
                        <button type="button" css={toolTip} data-testid="tooltip" onClick={() => setIsModalOpen(true)}>
                          ?
                        </button>

                        <Dialog
                          isOpen={isModalOpen}
                          onRequestClose={() => setIsModalOpen(false)}
                          customCSS={{ closeButton: modalCloseBtnCSS }}
                        >
                          <div>{hint}</div>
                          <Button
                            onClick={() => setIsModalOpen(false)}
                            customCSS={toolTipCloseBtn}
                            data-testid="modal-close-btn"
                          >
                            Close
                          </Button>
                        </Dialog>
                      </Fragment>
                    ))}
                </Fragment>
              </Fragment>
            </div>
            {responsive.isDesktop && hint && <Tooltip id={`tooltip-${formKey}`}>{hint}</Tooltip>}
          </div>
          {subheading && <h3 css={subHeading} dangerouslySetInnerHTML={{ __html: subheading }} />}

          {QuestionComponent && (
            <QuestionComponent
              shouldRenderEditableHomeDetails
              triggerSchemaUpdate={triggerSchemaUpdate}
              uploadFilesWrapperParams={uploadFilesWrapperParams}
            />
          )}
          <div css={questionsWrapperCSS(flow === Flows.Life)}>
            {visibleFields.map(({ key_name, ...rest }, i) => (
              <Question
                key={i}
                {...rest}
                heading={normalizedHeading}
                key_name={isFieldArray ? `${formPrefix}.${key_name}` : key_name}
                isDisabled={isDimmed}
                {...(QuestionTypes.UploadFileInputFlorida === rest?.type && { uploadFilesWrapperParams })}
              />
            ))}
          </div>
        </div>
      </div>
    </fieldset>
  ) : null;
};

export default React.memo(QuestionForm);
