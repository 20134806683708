import React from 'react';
import { SerializedStyles } from '@emotion/react';

export enum ButtonSize {
  Normal = 'NORMAL',
  Large = 'LARGE'
}

export type ButtonVariant = 'default' | 'secondary' | 'secondary_inverted' | 'inverted' | 'text' | 'text_inverted';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  size?: ButtonSize;
  type?: 'submit' | 'reset' | 'button';
  variant?: ButtonVariant;
  fullWidth?: boolean;
  isLoading?: boolean;
  customCSS?: SerializedStyles;
}
