import Page from 'src/components/Page/Page';
import PageHeader from 'src/components/PageHeader/PageHeader';
import SEGMENT from 'src/constants/segment';
import React from 'react';
import Interstitial from 'src/components/Interstitial/Interstitial';
import Footer from 'src/components/Footer/Footer';
import { useLocation, useParams } from 'react-router-dom';
import { customStyleFooterCSS } from './InterstitialLayout.style';
import { DefaultLocation, Flows } from 'src/interfaces/IPage';
import useQuestions from 'src/api/questions/useQuestions';
import useConfig from 'src/api/config/useConfig';

const InterstitialLayout: React.FC = () => {
  const { search } = useLocation();
  const { flow, gid } = useParams() as DefaultLocation;
  const { data: questions } = useQuestions(gid);
  const { data: config } = useConfig(gid);

  const urlParams = new URLSearchParams(search);
  const shouldRenderDTQInterstitial = urlParams.get('design') === 'v2' && flow === Flows.AllFunnel;

  return (
    <Page fullHeight>
      <PageHeader
        page={SEGMENT.PAGES_KEY.INTERSTITIAL}
        phone={
          questions?.answers?.person_is_policyholder === 'yes' ||
          !!questions?.answers?.person_is_policyholder_identified
            ? config?.partner?.cst_phone
            : undefined
        }
      />
      <main>
        <Interstitial shouldRenderDTQInterstitial={shouldRenderDTQInterstitial} />
      </main>
      <Footer
        page={SEGMENT.PAGES_KEY.INTERSTITIAL}
        withConfetti={shouldRenderDTQInterstitial}
        customCSS={customStyleFooterCSS(shouldRenderDTQInterstitial)}
      />
    </Page>
  );
};

export default InterstitialLayout;
