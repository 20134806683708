/** @jsxImportSource @emotion/react */
import React from 'react';

import condominiumImg from 'src/assets/svg/condominium.svg';
import singleFamilyDetachedImg from 'src/assets/svg/single_family_detached.svg';
import { buttonCSS, imageCSS, radioButtonCSS, labelCSS, inputCSS, buttonDotCSS } from './RadioButton.style';
import { RadioButtonProps } from './RadioButton.types';

const getImageFromValue = (name: string): string | undefined => {
  switch (name) {
    case 'condominium':
      return condominiumImg;
    case 'single_family_detached':
      return singleFamilyDetachedImg;
  }
};

const RadioButton: React.FC<RadioButtonProps> = ({
  index,
  componentRef,
  onChange,
  name,
  value,
  label,
  isDisabled,
  checked,
  onBlur,
  customCSS,
  withImage = false,
  isLifeFlowVariant = false,
  shouldHighlightError = false
}) => (
  <label
    css={[radioButtonCSS(checked, isLifeFlowVariant, shouldHighlightError), customCSS]}
    className="radio-button-label"
    htmlFor={`${name}[${index}]`}
  >
    <input
      type="radio"
      css={inputCSS(shouldHighlightError, isLifeFlowVariant)}
      disabled={isDisabled}
      id={`${name}[${index}]`}
      name={name}
      value={value}
      ref={componentRef}
      onChange={e => {
        onChange(e);
        onBlur(e);
      }}
      onBlur={onBlur}
      checked={checked}
    />
    <div className="radio-button" css={buttonCSS}>
      <div className="radio-button-dot" css={buttonDotCSS(shouldHighlightError)} />
    </div>
    <div css={labelCSS(isLifeFlowVariant)}>{label}</div>
    {withImage && (
      <div css={imageCSS}>
        <img src={getImageFromValue(value)} aria-hidden alt="" />
      </div>
    )}
  </label>
);

export default RadioButton;
