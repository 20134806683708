/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';

import { QuoteCardKeyValuesProps } from './QuoteCardKeyValues.types';
import {
  containerCSS,
  labelRowCSS,
  metadataWrapperCSS,
  premiumRowCSS,
  valueRowCSS,
  bndlMsqPremiumValueCSS
} from './QuoteCardKeyValues.style';
import Format from 'src/utils/format';
import { getPremiumLabelByType } from 'src/utils/quotesPage';
import useResponsive from 'src/hooks/useResponsive';
import { AUTOMATION_TEST_IDS } from 'src/constants/automationTestIds';
import useFeatureToggle from 'src/hooks/useFeatureToggle';
import { FEATURE_TOGGLES_IDS_ENUM as FT } from 'src/interfaces/experiment.types';
import { getPercentRangeValue } from 'src/utils/percentageCalculation';

const QuoteCardKeyValues: React.FC<QuoteCardKeyValuesProps> = ({
  customCSS,
  premium,
  deductible,
  highlightPremium,
  multiPolicyPremium,
  isBndToggleOn
}) => {
  const { isMobile } = useResponsive();

  const { isTestVaritionA: directSalesToDigitalProfileTestVariationA } = useFeatureToggle(
    FT.DIRECT_SALES_TO_DIGITAL_PROFILE_STEP1
  );

  const premiumValue = useMemo(() => {
    if (isBndToggleOn) {
      return Format.money(multiPolicyPremium || premium.value, 0, '$');
    }

    return directSalesToDigitalProfileTestVariationA
      ? getPercentRangeValue(premium.value, 15, 15)
      : Format.money(premium.value, 0, '$');
  }, [isBndToggleOn, multiPolicyPremium, premium.value, directSalesToDigitalProfileTestVariationA]);

  const bndlMsgQuote = isBndToggleOn && !!multiPolicyPremium;

  return (
    <div css={[containerCSS, customCSS]}>
      {!isMobile && (
        <div css={metadataWrapperCSS}>
          {<div css={valueRowCSS}>{Format.money(deductible, 9, '$')}</div>}
          <div css={labelRowCSS()}>Deductible</div>
        </div>
      )}
      <div css={metadataWrapperCSS}>
        {bndlMsgQuote && <div css={bndlMsqPremiumValueCSS}>{Format.money(premium.value, 0, '$')}</div>}
        <div
          css={[valueRowCSS, premiumRowCSS(highlightPremium, directSalesToDigitalProfileTestVariationA)]}
          data-testid={AUTOMATION_TEST_IDS.QUOTE.PREMIUM}
        >
          {premiumValue}
        </div>
        <div css={labelRowCSS(highlightPremium)}>
          {getPremiumLabelByType(premium.type, false, directSalesToDigitalProfileTestVariationA)}
        </div>
      </div>
    </div>
  );
};

export default QuoteCardKeyValues;
