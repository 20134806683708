/** @jsxImportSource @emotion/react */
import React from 'react';

import {
  wrapperCSS,
  ratingCSS,
  ratingValueCSS,
  starsCSS,
  starCSS,
  linkCSS,
  disclaimerCSS,
  reviewsWrapperCSS,
  descriptionCSS
} from './GoogleReviewRating.style';
import starIcon from 'src/assets/svg/star-icon.svg';
import Anchor from 'src/kit/Anchor/Anchor';
import { visuallyHidden } from 'src/theme/helpers';
import { GoogleReviewRatingProps, GoogleReviewsLayoutVariant } from './GoogleReviewRating.types';

export const REVIEWS_URL =
  'https://www.google.com/maps/place/Matic+Insurance+Services/@39.9499093,-83.0026541,17z/data=' +
  '!3m1!4b1!4m5!3m4!1s0x88388f8fe6839505:0xe9257f5b02b8dc5d!8m2!3d39.9499052!4d-83.0004654';

const RATING = '4.9';
const REVIEWS_NUMBER = '800+';

const GoogleReviewRating: React.FC<GoogleReviewRatingProps> = ({
  customCSS,
  disclaimer,
  layoutVariant = GoogleReviewsLayoutVariant.Vertical,
  onGoogleReviewsClick
}) => {
  return (
    <div css={[wrapperCSS(layoutVariant), customCSS]}>
      <div css={ratingCSS}>
        <span css={ratingValueCSS(layoutVariant)}>{RATING}</span>
        <b>/5</b>
      </div>
      <div css={reviewsWrapperCSS(layoutVariant)}>
        <div css={starsCSS}>
          {[...Array(5)].map((e, i) => (
            <img src={starIcon} key={i} alt="Star" css={starCSS(layoutVariant)} loading="lazy" />
          ))}
        </div>
        {layoutVariant === GoogleReviewsLayoutVariant.Horizontal ? (
          <div css={descriptionCSS}> {REVIEWS_NUMBER} Google verified reviews</div>
        ) : (
          <Anchor
            css={linkCSS}
            href={REVIEWS_URL}
            onClick={() => onGoogleReviewsClick()}
            target="_blank"
            rel="noopener noreferrer"
          >
            See All Google Reviews ({REVIEWS_NUMBER})<span css={visuallyHidden}>(opens a new window)</span>
          </Anchor>
        )}
      </div>
      {disclaimer && <div css={disclaimerCSS}>{disclaimer}</div>}
    </div>
  );
};

export default GoogleReviewRating;
