import { SerializedStyles } from '@emotion/react';
export interface BBBIconProps {
  colorVariation: BBBIconVariation;
  shouldHideText?: boolean;
  customCSS?: SerializedStyles;
  isSimplifiedVersion?: boolean;
  onClick?: () => void;
}

export enum BBBIconVariation {
  White = 'WHITE',
  Blue = 'BLUE',
  Grey = 'GREY'
}
