/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';

import { css } from '@emotion/react';
import DisclosureCheckBox from 'src/questionsForm/components/DisclosureCheckBox/DisclosureCheckBox';
import Button from 'src/kit/Button/Button';

import { ButtonSize } from 'src/kit/Button/types';
import EditPhoneButton from 'src/questionsForm/components/EditPhoneButton/EditPhoneButton';
import { ConsentButtonProps } from './ConsentButton.types';

const ConsentButton: React.FC<ConsentButtonProps> = ({
  shouldRenderDisclosureCheckbox,
  shouldRenderPhoneButton,
  onSubmit,
  isLoading,
  phoneValue,
  label
}) => {
  const [isDisclosureAccepted, setIsDisclosureAccepted] = useState(false);
  const [hasDisclosureError, setHasDisclosureError] = useState(false);

  useEffect(() => {
    if (isDisclosureAccepted) {
      setHasDisclosureError(false);
    }
  }, [isDisclosureAccepted]);

  const handleSubmit = (phone: string | null) => {
    if (shouldRenderDisclosureCheckbox && !isDisclosureAccepted) {
      setHasDisclosureError(true);
    } else {
      setHasDisclosureError(false);
      onSubmit(phone);
    }
  };

  return (
    <div>
      {shouldRenderDisclosureCheckbox && (
        <DisclosureCheckBox
          isAccepted={isDisclosureAccepted}
          hasError={hasDisclosureError}
          onClick={setIsDisclosureAccepted}
        />
      )}
      {shouldRenderPhoneButton ? (
        <EditPhoneButton phoneValue={phoneValue} isLoading={isLoading} onSubmit={phone => handleSubmit(phone)} />
      ) : (
        <Button
          data-testid="questions-form-action-button"
          css={css`
            width: 100%;
          `}
          size={ButtonSize.Large}
          isLoading={isLoading}
          onClick={() => handleSubmit(null)}
        >
          {label}
        </Button>
      )}
    </div>
  );
};

export default ConsentButton;
