import React from 'react';
import { WrapperProps } from './types';

const Wrapper: React.FC<WrapperProps> = ({ tag, children, ...props }) => {
  switch (tag) {
    case 'a':
      return <a {...props}>{children}</a>;
    case 'button':
      return <button {...props}>{children}</button>;
    case 'header':
      return <header {...props}>{children}</header>;
    case 'span':
      return <span {...props}>{children}</span>;
    case 'label':
      return <label {...props}>{children}</label>;
    default:
      return <div {...props}>{children}</div>;
  }
};

export default Wrapper;
