import { css } from '@emotion/react';

import { colors as c, mq } from 'src/theme/variables';
import { text, text12 } from 'src/theme/typography';

export const termsAndConditionsTitleCSS = css`
  margin-bottom: 40px;
  text-align: center;
`;

export const termsAndConditionsSubtitleCSS = css`
  font-weight: bold;
  color: ${c.black};
`;

export const termsAndConditionsTextCSS = css`
  ${text12};

  ${mq[1]} {
    ${text};
  }
`;
