/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';

import { DefaultLocation, Entity, EntityList } from 'src/interfaces/IPage';
import stringTemplater from 'src/utils/stringTemplater';
import CheckboxMark from 'src/assets/svg/check-mark.svg?react';
import ConfirmationModal from 'src/shared/components/ConfirmationModal/ConfirmationModal';
import { useParams } from 'react-router-dom';

import { checkbox, entityListItem, action, text, textInner } from './EntityListItem.style';
import { FS_SENSITIVE_DATA_CLASS } from 'src/constants/fullStory';

interface Props {
  list: EntityList;
  entity: Entity;
  canRemove?: boolean;
  onRemove(): void;
}

const EntityListItem: React.FC<Props> = ({ list, entity, canRemove = false, onRemove }) => {
  const { flow } = useParams() as DefaultLocation;
  const entityLabel = stringTemplater(list!.template, entity);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const itemLabel = list.item_label ? list.item_label.toLowerCase() : '';
  const confirmationModalTitle = `Are you sure you want to remove this ${itemLabel}?`;
  const confirmationModalText = `Information you’ve entered about this ${itemLabel}
   will be deleted and they will not be included in the ${flow} policy quote.`;

  const onRemoveItem = () => {
    setConfirmationModalOpen(true);
  };

  const onConfirmRemoveItem = () => {
    onRemove();
  };

  const onClose = () => {
    setConfirmationModalOpen(false);
  };

  return (
    <div css={entityListItem} data-testid="entity-list-item">
      <CheckboxMark css={checkbox} />
      <div css={text}>
        <span className={list.tracking_forbidden ? FS_SENSITIVE_DATA_CLASS.MASK : ''} css={textInner}>
          {entityLabel}
        </span>
      </div>
      {canRemove && (
        <button css={action} data-testid="remove-list-item-button" onClick={onRemoveItem}>
          Remove {list.item_label}
        </button>
      )}
      <ConfirmationModal
        title={confirmationModalTitle}
        subTitle={confirmationModalText}
        isModalOpen={confirmationModalOpen}
        onClose={onClose}
        onConfirm={onConfirmRemoveItem}
      />
    </div>
  );
};

export default EntityListItem;
