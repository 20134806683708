/** @jsxImportSource @emotion/react */
import React, { Fragment } from 'react';

import Container from 'src/kit/Container/Container';
import Logo from 'src/kit/Logo/Logo';
import Wrapper from 'src/kit/Wrapper/Wrapper';
import Divider from 'src/kit/Divider/Divider';
import { headerCSS, headerInnerCSS, logoContainerCSS, dividerCSS, partnerLogoCSS } from './Header.style';
import HeaderContacts from './HeaderContacts/HeaderContacts';
import useResponsive from 'src/hooks/useResponsive';
import { HeaderProps } from './types';
import DemoFlowIndicator from 'src/components/DemoFlowIndicator/DemoFlowIndicator';
import LiveHelpComponent from 'src/components/LiveHelpComponents/LiveHelpComponents';

const Header: React.FC<HeaderProps> = ({
  wrapperTag = 'header',
  partnerLogo,
  partnerName,
  showContacts,
  phoneNumber,
  reverseLogo,
  fullscreen,
  children,
  onPhoneClick,
  customCSS,
  isDemo = false,
  userName,
  isLiveHeaderVariation = false,
  shouldHideMaticLogo = false
}) => {
  const { isMobile } = useResponsive();

  const getMaticLogoWidth = (isLiveHeaderVariation?: boolean): number | undefined => {
    if (partnerLogo) {
      return isMobile ? 73 : 117;
    }
    return isMobile ? 88 : isLiveHeaderVariation ? 100 : 145;
  };

  const getPartnerLogoHeight = (): number | undefined => {
    return isMobile ? 30 : 50;
  };

  const getContactToRender =
    isLiveHeaderVariation && phoneNumber ? (
      <LiveHelpComponent phoneNumber={phoneNumber} userName={userName} />
    ) : (
      <HeaderContacts phoneNumber={phoneNumber} onPhoneClick={onPhoneClick} />
    );

  return (
    <Wrapper tag={wrapperTag} css={[customCSS, headerCSS(isDemo, isLiveHeaderVariation)]} data-testid="header">
      {isDemo && <DemoFlowIndicator />}
      <Container customCSS={headerInnerCSS} fullscreen={fullscreen}>
        <div css={logoContainerCSS(!!reverseLogo, isLiveHeaderVariation)}>
          {partnerLogo && (
            <Fragment>
              <Logo
                customCSS={partnerLogoCSS(shouldHideMaticLogo)}
                partnerLogo={partnerLogo}
                partnerName={partnerName}
                shouldRenderExtended={shouldHideMaticLogo}
                height={getPartnerLogoHeight()}
              />
              {!shouldHideMaticLogo && <Divider customCSS={dividerCSS} height={isMobile ? 28 : 48} />}
            </Fragment>
          )}
          {!shouldHideMaticLogo && <Logo width={getMaticLogoWidth(isLiveHeaderVariation)} />}
        </div>

        {children ? children : showContacts && getContactToRender}
      </Container>
    </Wrapper>
  );
};

export default Header;
