/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';

import { SerializedStyles } from '@emotion/react';
import { useParams } from 'react-router-dom';

import { Answers } from 'src/interfaces/IAnswer';
import { Form as FormInterface } from 'src/interfaces/form.types';
import Question from 'src/questionsForm/components/Question/Question';
import stringTemplater from 'src/utils/stringTemplater';
import { dimmed, form, scrollPoint, questionsWrapperCSS, buttonCSS } from './QuestionForm.style';
import { visuallyHidden } from 'src/theme/helpers';
import { DefaultLocation, Flows } from 'src/interfaces/IPage';

import { UploadFilesComponentProps } from 'src/components/UploadFilesComponent/UploadFilesComponent.types';
import { QuestionSwitchProps, QuestionTypes, Option } from 'src/interfaces/IQuestion';
import { getVisibleFormPart } from 'src/questionsForm/utils/questionsForm.utils';
import Button from 'src/kit/Button/Button';
import { getAge } from 'src/utils/date';
import { getTermLengths } from 'src/utils/lifeFlow.utils';
import { useLifeQuotesStatesActions } from 'src/contexts/life-flow-context';

interface LifeFormProps extends FormInterface {
  customCSS?: SerializedStyles;
  answers: Answers;
  isLineHidden?: boolean;
  isFieldArray?: boolean;
  formPrefix?: string;
  questionKeyName?: string;
  triggerSchemaUpdate?(): void;
  uploadFilesWrapperParams?: UploadFilesComponentProps;
  onActionClick: () => void;
  isButtonLoading: boolean;
  isButtonDisabled: boolean;
  buttonLabel: string;
  onFiledChanged: () => void;
}

const updateObjectInArray = <T extends Record<string, any>>(array: T[], key: string, newData: Partial<T>): T[] => {
  const index = array.findIndex(obj => obj.key_name === key);

  if (index !== -1) {
    return [...array.slice(0, index), { ...array[index], ...newData }, ...array.slice(index + 1)];
  }
  return array;
};

const LifeQuestionForm: React.FC<LifeFormProps> = ({
  customCSS,
  fields,
  heading,
  key_name: formKeyName,
  formPrefix,
  answers,
  isDimmed = false,
  isHidden = false,
  isLineHidden = false,
  isFieldArray = false,
  uploadFilesWrapperParams,
  isButtonLoading,
  isButtonDisabled,
  onActionClick,
  buttonLabel
}) => {
  const { setCurrentOptionsRange } = useLifeQuotesStatesActions();
  const [answerOptions, setAnswerOptions] = useState<Option[]>([]);
  const normalizedHeading = stringTemplater(heading, { answers });
  const { flow } = useParams() as DefaultLocation;
  const formKey = isFieldArray ? `${formPrefix}.${formKeyName}` : formKeyName;
  const termSpecific = !(!!answers?.person_date_of_birth && !!answers?.person_tobacco_use);

  useEffect(() => {
    if (!isNaN(getAge(answers?.person_date_of_birth as string) as number)) {
      const options = getTermLengths(
        +getAge(answers?.person_date_of_birth as string),
        answers?.person_tobacco_use as string
      ) as Option[];

      setCurrentOptionsRange(
        options.length === 1
          ? [+options[0].value]
          : options.length > 1
            ? [+options[0].value, +options[options.length - 1].value]
            : []
      );

      setAnswerOptions(options);
    }
  }, [answers?.person_date_of_birth, answers?.person_tobacco_use, setAnswerOptions, setCurrentOptionsRange]);

  const updatedFields = updateObjectInArray(fields, 'person_term_length', {
    hint: termSpecific ? fields[fields.findIndex(f => f.key_name === 'person_term_length')].hint : '',
    answer_options: answerOptions
  });

  const visibleFields = getVisibleFormPart<QuestionSwitchProps>(updatedFields, answers);

  return !isHidden ? (
    <fieldset data-testid="form" css={[form(isLineHidden, true), customCSS]}>
      <legend css={visuallyHidden}>{normalizedHeading}</legend>
      <div data-testid="form-scroll-point" css={scrollPoint} id={formKey} />
      <div data-testid="form-inner">
        <div css={isDimmed && dimmed}>
          <div css={questionsWrapperCSS(flow === Flows.Life)}>
            {visibleFields.map(({ key_name, ...rest }, i) => (
              <Question
                key={i}
                {...rest}
                heading={normalizedHeading}
                key_name={isFieldArray ? `${formPrefix}.${key_name}` : key_name}
                isDisabled={key_name === 'person_term_length' ? termSpecific : isDimmed}
                {...(QuestionTypes.UploadFileInputFlorida === rest?.type && { uploadFilesWrapperParams })}
              />
            ))}
            <Button
              type="button"
              data-testid="questions-form-action-button"
              css={buttonCSS(isButtonDisabled, isButtonLoading)}
              isLoading={isButtonLoading}
              onClick={onActionClick}
              disabled={isButtonDisabled}
            >
              {buttonLabel}
            </Button>
          </div>
        </div>
      </div>
    </fieldset>
  ) : null;
};

export default React.memo(LifeQuestionForm);
