import { css } from '@emotion/react';
import { borderRadius, boxShadow, colors as c, mq } from 'src/theme/variables';
import { text24 } from 'src/theme/typography';
import React from 'react';

export const dialogBodyCSS = css`
  position: relative;
  border-radius: ${borderRadius}px;
  box-shadow: ${boxShadow.container};
  padding: 0;
  ${mq[0]} {
    padding: 0;
  }
`;

export const dialogCloseCSS = css`
  position: absolute;
  right: 0;
  top: 0;
  padding: 24px;
  border: none;
  background-color: transparent;
  border-radius: 0;
  width: auto;
  height: auto;
  margin: 0;
  z-index: 2;
  color: red;

  &:hover {
    background-color: transparent;
  }

  ${mq[0]} {
    margin: 0;
    width: auto;
    height: auto;
  }
`;

export const dialogTitleCSS = css`
  ${text24};
  padding: 16px 16px 4px;
`;

export const dialogContentInlineCSS = (isMobile: boolean): React.CSSProperties => ({
  width: '400px',
  ...(isMobile && { marginBottom: '0px' })
});

export const dialogOverlayInlineCSS = (isMobile: boolean): React.CSSProperties => ({
  ...(isMobile && { padding: '0px' })
});

export const dialogContentCSS = css`
  padding: 16px;
`;

export const borderTop = css`
  border-top: 1px solid ${c.mercury};
`;

export const dialogActionsCSS = css`
  text-align: center;
  padding: 16px;
  ${mq[0]} {
    text-align: right;
  }
`;

export const dialogActionsItemCSS = css`
  margin: 0 8px;
`;
