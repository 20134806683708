import { ScrollPathItem } from './useFormScroll.types';
import { Form } from 'src/interfaces/form.types';
import { Answers } from 'src/interfaces/IAnswer';
import { getVisibleFormPart, questionHasRequiredFields } from 'src/questionsForm/utils/questionsForm.utils';
import { QuestionSwitchProps } from 'src/interfaces/IQuestion';

export const getScrollPath = (path: ScrollPathItem[], questions: Form[], answers: Answers): ScrollPathItem[] => {
  return questions.map(question => {
    const existingPathItem = path.find(i => i.key_name === question.key_name);
    return existingPathItem
      ? existingPathItem
      : {
          key_name: question.key_name,
          fields: getVisibleFormPart<QuestionSwitchProps>(question.fields, answers),
          canTriggerScroll: questionHasRequiredFields(question),
          scrolled: false
        };
  });
};

const getNextNotOptionalScrollIndex = (path: ScrollPathItem[], index: number): number => {
  return path.findIndex((item, i) => i > index && item.canTriggerScroll);
};

export const getCurrentScrollIndex = (lastScrolledIndex: number, path: ScrollPathItem[]): number => {
  const index = lastScrolledIndex === -1 ? 0 : lastScrolledIndex + 1;
  if (!path[index]) {
    return -1;
  }
  return path[index].canTriggerScroll ? index : getNextNotOptionalScrollIndex(path, index);
};

export const isScrollPathChanged = (path: ScrollPathItem[], questions: Form[] = []): boolean => {
  for (let i = 0; i < questions.length; i++) {
    if (questions[i].key_name !== path[i]?.key_name) {
      return true;
    }
  }
  return false;
};
