import { css } from '@emotion/react';
import { borderRadius, colors, sizes, typography } from 'src/theme/variables';

const PADDING_LEFT = 16;

export const entityAddNew = css`
  margin-top: 8px;
`;

export const entityAddButton = css`
  background-color: ${colors.white};
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  font-family: ${typography.fontFamily};
  height: ${sizes.controlHeight}px;
  outline: none;
  padding-left: ${PADDING_LEFT}px;
  text-align: left;
  width: 100%;
  border: 1px solid ${colors.black};
  border-radius: ${borderRadius}px;
  position: relative;
  z-index: 1;

  &:hover {
    border: 2px solid ${colors.azure};
    padding-left: ${PADDING_LEFT - 1}px;
  }

  &:focus {
    + .input-focus-helper {
      border-color: ${colors.azure};
    }
  }
`;

export const addButtonText = css`
  margin-left: 8px;
`;

export const entityAddButtonWrapper = css`
  position: relative;
`;
