import { css, SerializedStyles } from '@emotion/react';
import { indicator, indicatorLine } from 'src/theme/shared-styles';
import { mq } from 'src/theme/variables';
import { text20, text32, text40 } from 'src/theme/typography';

export const greeting = (hasIndicator?: boolean, isSticky?: boolean): SerializedStyles => css`
  position: relative;
  ${hasIndicator &&
  css`
    &::before {
      ${indicatorLine};
      bottom: -55px;
      content: '';
      top: 26px;
      ${mq[0]} {
        bottom: -78px;
        top: 37px;
      }
    }
  `}
  ${isSticky &&
  css`
    position: sticky;
    top: var(--header-height);
    background-color: #fff;
    z-index: 2;
    &:after,
    &:before {
      content: '';
      position: absolute;
      background-color: #fff;
      right: 0;
      left: 0;
      height: 12px;
    }
    &:before {
      top: -10px;
    }
    &:after {
      bottom: -10px;
    }
  `}
`;

export const greetingHeading = css`
  display: flex;
  align-items: center;
  margin: 0;
  font-weight: 700;
  ${text32};
  ${mq[0]} {
    ${text40};
  }
`;

export const paragraph = css`
  margin-top: 4px;
  ${mq[0]} {
    ${text20};
    margin-top: 8px;
  }
`;

export const greetingIndicator = css`
  ${indicator};
  ${mq[0]} {
    top: 17px;
  }
`;

export const vehicleIcon = css`
  margin-right: 8px;
`;
