/** @jsxImportSource @emotion/react */
import React from 'react';

import { buttonCSS, groupCSS } from './QuoteCoverages.style';
import QuoteCoverageGroup from './QuoteCoverageGroup/QuoteCoverageGroup';
import { QuoteCoveragesProps } from './QuoteCoverages.types';
import Button from 'src/kit/Button/Button';
import Expander from 'src/components/Expander/Expander';

const QuoteCoverages: React.FC<QuoteCoveragesProps> = ({ customCSS, groups, expandable, shouldRenderDescription }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  if (!groups?.length) {
    return null;
  }

  if (expandable && groups?.length > 1) {
    return (
      <div css={[customCSS]}>
        <QuoteCoverageGroup key={groups[0].key} customCSS={groupCSS(true)} group={groups[0]} />

        <Expander isExpanded={isOpen}>
          {groups
            .filter((g, i) => i !== 0)
            .map(group => isOpen && <QuoteCoverageGroup key={group.key} customCSS={groupCSS()} group={group} />)}
        </Expander>

        {!isOpen && (
          <Button css={buttonCSS} variant="text" onClick={() => setIsOpen(true)}>
            See Full Coverage Breakdown
          </Button>
        )}
      </div>
    );
  }

  return (
    <div css={customCSS}>
      {groups.map((group, i) => (
        <QuoteCoverageGroup
          shouldRenderDescription={shouldRenderDescription}
          key={group.key}
          group={group}
          customCSS={groupCSS(i === 0)}
        />
      ))}
    </div>
  );
};

export default QuoteCoverages;
