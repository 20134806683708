/** @jsxImportSource @emotion/react */
import React, { Fragment, useState } from 'react';

import { LandlordButtonProps } from './LandlordButton.types';
import { actions } from './LandlordButton.style';
import Button from 'src/kit/Button/Button';
import Dialog from 'src/kit/Dialog/Dialog';

const LandlordButton: React.FC<LandlordButtonProps> = ({
  customCSS,
  label = 'What is landlord insurance?',
  invertedButton = false
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Fragment>
      <Button
        css={customCSS}
        variant={invertedButton ? 'text_inverted' : 'text'}
        onClick={() => setIsOpen(true)}
        data-testid="landlord-open-button"
      >
        {label}
      </Button>
      <Dialog isOpen={isOpen} title="What is landlord insurance?" size="small" onRequestClose={() => setIsOpen(false)}>
        <div>
          A Landlord Insurance policy is for a someone who rents or plans to rent out their house. Property and
          liability coverage included in a Landlord Insurance policy can help protect you, the landlord, and your rental
          property. It does not typically cover losses for your tenant’s personal belongings.
        </div>
        <div css={actions}>
          <Button onClick={() => setIsOpen(false)} data-testid="landlord-close-button">
            Close
          </Button>
        </div>
      </Dialog>
    </Fragment>
  );
};

export default LandlordButton;
