import { css, SerializedStyles } from '@emotion/react';
import { colors, mq } from 'src/theme/variables';
import { text, text14 } from 'src/theme/typography';

export const headerCSS = css`
  display: flex;
  justify-content: space-between;
  ${text14};
  font-weight: bold;

  ${mq[0]} {
    ${text};
  }
`;

export const descriptionCSS = (renderDescription?: boolean): SerializedStyles => css`
  display: none;
  font-size: 14px;
  line-height: 18px;
  color: ${colors.dove};
  margin-top: 8px;
  ${renderDescription &&
  css`
    display: block;
  `}

  ${mq[0]} {
    display: block;
  }
`;

export const nameCSS = (isIconPresent?: boolean): SerializedStyles => css`
  color: ${colors.warmGray100};

  ${isIconPresent &&
  css`
    display: flex;
    align-items: center;
    gap: 12px;
    color: ${colors.black};
  `}

  ${mq[0]} {
    color: ${colors.black};
  }
`;

export const valueCSS = (isIconPresent?: boolean): SerializedStyles => css`
  flex-shrink: 0;
  margin-left: 8px;

  ${isIconPresent &&
  css`
    color: ${colors.darkGreen};
  `}
`;
