import { css, SerializedStyles } from '@emotion/react';
import { colors } from 'src/theme/variables';
import { text, text14 } from 'src/theme/typography';

export const option = (disabled: boolean): SerializedStyles => css`
  align-items: center;
  display: flex;
  opacity: ${disabled ? 0.5 : 1};
`;

export const icon = css`
  width: 30px;
  margin-right: 16px;
`;

export const selectLabel = (isSelected: boolean): SerializedStyles => css`
  ${text};
  font-weight: bold;
  color: ${isSelected ? colors.white : colors.black};
`;

export const selectDescription = (isSelected: boolean): SerializedStyles => css`
  ${text14};
  color: ${isSelected ? colors.white : colors.warmGray100};
`;
