import { css } from '@emotion/react';
import { mq, colors as c } from 'src/theme/variables';
import { text20 } from 'src/theme/typography';

export const cardCSS = css`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 16px 0;
  max-width: 100%;
  min-width: 200px;
  padding: 20px;
  box-sizing: border-box;
`;

export const headingCSS = css`
  ${text20};
  font-weight: bold;
  padding: 12px 0 16px;
  text-align: center;
`;

export const descriptionCSS = css`
  max-width: 100%;
  padding-bottom: 24px;
  text-align: center;
`;

export const actionButtonCSS = css`
  width: 100%;
`;

export const callActionCSS = css`
  ${actionButtonCSS};
  background-color: ${c.azure};
  color: ${c.white};
  font-weight: 700;
  border-radius: 4px;
  cursor: pointer;
  padding: 10px 4px;
  transition:
    color 0.3s,
    background-color 0.3s,
    border-color 0.3s;
  min-width: 100%;
  &:hover {
    color: ${c.white};
    background-color: ${c.cornflower};
  }
`;

export const carriersLogoCSS = css`
  display: flex;
  align-items: baseline;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 12px;

  img {
    width: 50px;

    ${mq[0]} {
      width: 100px;
    }

    ${mq[1]} {
      width: 120px;
    }
  }
`;
