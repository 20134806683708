import { css } from '@emotion/react';

export const startOverMessageCSS = css`
  margin: 8px 0 32px;
  font-weight: 500;
  text-align: center;
`;

export const foreverLockedIconCSS = css`
  margin-bottom: 16px;
`;
