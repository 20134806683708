/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, Fragment } from 'react';

import { useFormContext } from 'react-hook-form';

import QuestionForm from 'src/questionsForm/components/QuestionForm/QuestionForm';
import Greeting from 'src/questionsForm/components/Greeting/Greeting';
import Consent from 'src/components/Consent/Consent';
import validateRules from 'src/questionsForm/utils/ruleValidator.utils';
import { scrollToNode } from 'src/utils/scroll';
import Format from 'src/utils/format';
import useFormScroll from 'src/questionsForm/hooks/useFormScroll/useFormScroll';
import { formActions } from 'src/theme/shared-styles';
import { consentFormPage, consentPageGreeting } from './ConsentFormPage.style';
import useResponsive from 'src/hooks/useResponsive';
import { QUESTION_KEYS } from 'src/constants/hardcoded';
import ConsentButton from 'src/questionsForm/components/ConsentButton/ConsentButton';
import { QuestionsFormPageProps } from 'src/questionsForm/types/questionsFormPage.types';
import { getQuestionForms } from 'src/questionsForm/utils/questionsForm.utils';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

const ConsentFormPage: React.FC<QuestionsFormPageProps> = ({
  greeting,
  action,
  questions,
  onPageSubmit,
  key_name,
  triggerSchemaUpdate,
  uploadFilesWrapperParams
}) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>();
  const { isDesktop, isMobile, isTablet } = useResponsive();
  const {
    setValue,
    watch,
    formState: { touchedFields }
  } = useFormContext();
  const client = useQueryClient();
  const { gid } = useParams();

  const answers = watch();

  if (answers.phone) {
    answers.phone = Format.phone(answers.phone as string);
  }

  const visibleForms = getQuestionForms(
    {
      ...answers,
      isMobile,
      isDesktop,
      isTablet
    },
    questions
  );

  const touchedFieldsArray = Object.keys(touchedFields);
  useFormScroll(visibleForms, answers, touchedFieldsArray);

  const onSubmit = async (updatedPhone: string | null) => {
    if (updatedPhone) {
      setValue(QUESTION_KEYS.PHONE, updatedPhone);
    }

    setIsSubmitting(true);
    await onPageSubmit();
    setIsSubmitting(false);
  };

  useEffect(() => {
    setTimeout(() => scrollToNode(`section-${key_name}`), 500);
  }, [key_name]);

  const shouldRenderDisclosureCheckbox = action.with_disclosures_checkbox && !isMobile;
  const shouldRenderConsent =
    action && validateRules(answers, action.visibility_conditions) && !shouldRenderDisclosureCheckbox;

  useEffect(() => {
    if (shouldRenderConsent) {
      client.refetchQueries(['disclosures', gid]);
    }
  }, [client, gid, shouldRenderConsent]);

  return (
    <Fragment>
      <div css={consentFormPage} id={`section-${key_name}`} data-testid={`section-${key_name}`}>
        {greeting && Object.keys(greeting).length > 0 && (
          <div css={consentPageGreeting}>
            <Greeting keyName={key_name} {...greeting} />
          </div>
        )}
        <div>
          {visibleForms.map(({ isDimmed, ...f }, index) => {
            const isLineHidden = visibleForms.length === index + 1;
            return (
              <QuestionForm
                key={f.key_name}
                answers={answers}
                isLineHidden={isLineHidden}
                isDimmed={isDimmed}
                triggerSchemaUpdate={triggerSchemaUpdate}
                {...f}
                uploadFilesWrapperParams={uploadFilesWrapperParams}
              />
            );
          })}
        </div>
        {action && validateRules(answers, action.visibility_conditions) && (
          <div css={formActions}>
            <ConsentButton
              label={action.label}
              phoneValue={answers.phone}
              isLoading={isSubmitting}
              onSubmit={onSubmit}
              shouldRenderDisclosureCheckbox={!!action.with_disclosures_checkbox && !isMobile}
              shouldRenderPhoneButton={!!action.with_phone_input}
            />
          </div>
        )}
      </div>
      {shouldRenderConsent && <Consent />}
    </Fragment>
  );
};

export default ConsentFormPage;
