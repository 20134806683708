import axios from 'axios';

import { smartyStreetsWebKey, smartyStreetsAutocompleteApi } from 'src/constants/services';
import { SuggestionResponse } from 'src/interfaces/IStreets';

interface IStreetService {
  suggest(search: string): Promise<SuggestionResponse>;
  suggestSecondary(search: string, selected: string): Promise<SuggestionResponse>;
}

const streetService: IStreetService = {
  suggest: search =>
    axios.get(smartyStreetsAutocompleteApi, {
      method: 'GET',
      params: {
        key: smartyStreetsWebKey,
        include_only_states: 'allstates',
        search
      }
    }),
  suggestSecondary: (search, selected) =>
    axios.get(smartyStreetsAutocompleteApi, {
      method: 'GET',
      params: {
        key: smartyStreetsWebKey,
        include_only_states: 'allstates',
        search,
        selected
      }
    })
};

export default streetService;
