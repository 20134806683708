import { LifePolicies } from './ComparisonTable.types';

export const lifeComparisonTableContent = [
  {
    name: 'Tax-free benefit',
    polices_include: [LifePolicies.TERM_LIFE, LifePolicies.ACCIDENTAL_DEATH, LifePolicies.WHOLE_LIFE]
  },
  {
    name: 'Fixed pricing',
    polices_include: [LifePolicies.TERM_LIFE, LifePolicies.ACCIDENTAL_DEATH, LifePolicies.WHOLE_LIFE]
  },
  {
    name: 'Policy covers of all types of passing',
    polices_include: [LifePolicies.TERM_LIFE, LifePolicies.WHOLE_LIFE]
  },
  {
    name: 'Wide range of coverage options (including $1M+)',
    polices_include: [LifePolicies.TERM_LIFE]
  },
  {
    name: 'Ideal for smaller coverage needs ($350K or less)',
    polices_include: [LifePolicies.ACCIDENTAL_DEATH, LifePolicies.WHOLE_LIFE]
  },
  {
    name: 'Ideal for smaller budgets',
    polices_include: [LifePolicies.ACCIDENTAL_DEATH]
  },
  {
    name: 'Popular choice for older adults',
    polices_include: [LifePolicies.WHOLE_LIFE]
  },
  {
    name: 'Popular choice for parents with kids at home',
    polices_include: [LifePolicies.TERM_LIFE, LifePolicies.ACCIDENTAL_DEATH]
  },
  {
    name: 'Permanent coverage you can keep for life',
    polices_include: [LifePolicies.WHOLE_LIFE]
  }
];
