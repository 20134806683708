/** @jsxImportSource @emotion/react */
import React, { Fragment } from 'react';

import { headingWrapper, iconWrapper, icon, stepName, step as stepCSS } from './Steps.style';

import mouseIcon from 'src/assets/svg/mouse.svg';
import compareIcon from 'src/assets/svg/compare.svg';
import relaxIcon from 'src/assets/svg/relax.svg';

const STEPS = [
  {
    name: 'Click',
    icon: mouseIcon,
    iconDesktopWidth: '147px',
    description:
      'Matic does all the legwork upfront using your current policy and property info. No need to worry ' +
      'about answering a ton of questions or uploading documents. Click “Get quotes now” and watch those quotes ' +
      'come pouring in.'
  },
  {
    name: 'Compare',
    icon: compareIcon,
    iconDesktopWidth: '100px',
    description:
      'In seconds, Matic compares 40+ A-Rated carriers and delivers the best quotes catered ' +
      'specifically to you. It’s a quick and convenient way to shop for home insurance — the whole ' +
      'process takes just a few minutes.'
  },
  {
    name: 'Relax',
    icon: relaxIcon,
    iconDesktopWidth: '125px',
    description:
      'Unlike many comparison sites, Matic is a full-service licensed agency. Matic helps manage ' +
      'the paperwork and details of your new policy, and even shops your policy again before renewal so you’ll ' +
      'always have the best rate. With Matic, you’ll never have to think about insurance again.'
  }
];

const Steps: React.FC = () => (
  <Fragment>
    {STEPS.map((step, i) => (
      <div css={stepCSS} key={i}>
        <div css={headingWrapper}>
          <div css={iconWrapper(step.iconDesktopWidth)}>
            <img css={icon} src={step.icon} alt={`${step.name} Icon`} loading="lazy" />
          </div>
          <p css={stepName}>{step.name}</p>
        </div>
        <p>{step.description}</p>
      </div>
    ))}
  </Fragment>
);

export default Steps;
