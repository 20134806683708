import { BundleQuote } from 'src/interfaces/IQuotes';

export const getUniqueHomeQuotes = (quotes: BundleQuote[]): BundleQuote[] => {
  const uniqueIds: string[] = [];

  const unique = quotes.filter(quote => {
    const isDuplicate = uniqueIds.includes(quote.quotes.home.gid);

    if (!isDuplicate) {
      uniqueIds.push(quote.quotes.home.gid);

      return true;
    }

    return false;
  });

  return unique;
};
