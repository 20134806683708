/** @jsxImportSource @emotion/react */

import { dotCSS, faqContainerCSS, triangleCSS } from './FAQ.style';
import LifeQuestionIcon from 'src/assets/svg/life-question.svg';
import FAQAccordion from './FAQAccordion/FAQAccordion';
import useResponsive from 'src/hooks/useResponsive';
import LifeDotIcon from 'src/assets/svg/life-dot.svg';
import LifeTriangleIcon from 'src/assets/svg/life-triangle.svg';
import { AccordionItem } from 'src/shared/components/Accordion/Accordion.types';
import SEGMENT from 'src/constants/segment';
import analytics from 'src/utils/analytics';
import { lifeFAQAccordionItems } from './FAQ.content';
import { DefaultLocation } from 'src/interfaces/IPage';

const FAQ: React.FC<DefaultLocation> = ({ gid, flow }) => {
  const { isMobile } = useResponsive();

  const onAccordionClick = ({ title }: AccordionItem, ordinal: number) => {
    analytics.track(SEGMENT.FAQ_CLICKED, gid, flow, {
      ordinal,
      question_text: title,
      page: SEGMENT.PAGES.LIFE
    });
  };

  return (
    <section css={faqContainerCSS}>
      <img src={LifeQuestionIcon} alt="Question Mark" />
      <h2>{isMobile ? 'Frequently asked questions' : 'Other frequently asked questions about life insurance'}</h2>
      <FAQAccordion onChange={onAccordionClick} items={lifeFAQAccordionItems} />
      <img src={LifeDotIcon} alt="Dot" css={dotCSS} />
      <img src={LifeTriangleIcon} alt="Triangle" css={triangleCSS} />
    </section>
  );
};

export default FAQ;
