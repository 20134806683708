import { colors } from 'src/theme/variables';

export const QUOTE_TAGS = {
  BEST_VALUE: { color: colors.green, label: 'Great value' },
  RECOMMENDED: { color: colors.darkGreen, label: 'Recommended' },
  BUNDLE_DISCOUNT: { color: colors.violet, label: 'Bundle Discount' },
  BUNDLE_OPTION: { color: colors.darkGreen, label: '+20% additional Savings*' },
  BUNDLE_MESSAGE: { color: colors.violet, label: 'Bundle Discount' }
};

export const getMultiPolicyDiscountTag = (discount: number): { color: string; label: string } => {
  return {
    color: colors.darkGreen,
    label: `${discount}% additional Savings*`
  };
};

export const getInSavingsTag = (savings: number): { color: string; label: string } => {
  return {
    color: colors.darkGreen,
    label: `$${savings} in Savings`
  };
};

export const getBndlMsqTag = (discount: number): { color: string; label: string } => {
  return {
    color: colors.violet,
    label: `${discount}% Bundle Discount`
  };
};
