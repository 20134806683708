/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import {
  buttonCSS,
  componentWrapperCSS,
  zipInputWrapperCSS,
  errorCSS,
  inputWrapperCSS,
  labelCSS,
  inputCSS,
  inputElWrapperCSS
} from './ZipFormButton.style';
import Button from 'src/kit/Button/Button';
import ValidationError from 'src/shared/components/ValidationError/ValidationError';
import { ZipFormButtonProps } from './ZipFormButton.types';
import Input from 'src/shared/components/Input/Input';
import { isZipValid } from './ZipFormButton.utils';
import { ZIP_LENGTH } from './ZipFormButton.constants';

const ZipFormButton: React.FC<ZipFormButtonProps> = ({ customCSS, label, isLoading, onSubmit, hasError }) => {
  const [zipValue, setZipValue] = useState<string>('');
  const [hasInputError, setHasInputError] = useState(hasError);

  const onZipChange = (newValue: string) => {
    if (newValue.length === ZIP_LENGTH + 1) {
      return;
    }

    setZipValue(newValue);
    setHasInputError(false);
  };

  const handleSubmit = () => {
    !isZipValid(zipValue) && setHasInputError(true);
    onSubmit(zipValue);
  };

  return (
    <div css={[componentWrapperCSS, customCSS]}>
      <div css={zipInputWrapperCSS(hasInputError)}>
        <div css={inputWrapperCSS}>
          <label htmlFor="zip-button-input" css={labelCSS(hasInputError)}>
            Your ZIP
          </label>
          <Input
            autoComplete="off"
            id="zip-button-input"
            customCSS={inputCSS(hasInputError)}
            customWrapperCSS={inputElWrapperCSS}
            name="zip"
            onChange={target => onZipChange(target.currentTarget.value)}
            value={zipValue}
            type={'number'}
            aria-label={'zip-button-input'}
          />
        </div>
        <Button
          customCSS={buttonCSS}
          isLoading={isLoading}
          onClick={handleSubmit}
          data-testid="zip-input-action-button"
        >
          {label}
        </Button>
      </div>
      <ValidationError customCSS={errorCSS} visible={hasInputError} heading="ZIP code">
        {hasError ? 'We could not match your ZIP' : 'Please, enter correct ZIP'}
      </ValidationError>
    </div>
  );
};

export default ZipFormButton;
