/** @jsxImportSource @emotion/react */
import React, { Fragment } from 'react';

import { QuotesHeroContentProps } from './QuotesHeroContent.types';
import {
  buttonCSS,
  descriptionCSS,
  headingCSS,
  imageBackgroundCSS,
  imageContainerCSS,
  imageCSS,
  buttonIconWrapperCSS,
  buttonWrapperCSS
} from './QuotesHeroContent.style';
import {
  contentHeroCSS,
  descriptionTextHeroCSS,
  headingHeroCSS,
  infoCenteringCSS
} from 'src/components/Quotes/QuotesPageHero/QuotesPageHero.style';
import AgentBackground from 'src/assets/svg/quotes-hero-agent-bcg.svg?react';
import Button from 'src/kit/Button/Button';
import useResponsive from 'src/hooks/useResponsive';
import SirvImage from 'src/kit/SirvImage/SirvImage';
import SIRV from 'src/constants/sirv';
import noop from 'src/utils/noop';
import Anchor from 'src/kit/Anchor/Anchor';
import HandsetIcon from 'src/assets/svg/handset-blue.svg';
import { AUTOMATION_TEST_IDS } from 'src/constants/automationTestIds';

const QuotesHeroContent: React.FC<QuotesHeroContentProps> = ({
  customCSS,
  heading,
  description,
  actionLabel = 'Schedule a free consultation',
  onActionClick = noop,
  contactNumber,
  onPhoneNumberClick,
  isPolicyHolderVariation,
  isAccordVariation = false
}) => {
  const { isMobile } = useResponsive();

  return (
    <div css={[contentHeroCSS, customCSS]}>
      <div css={infoCenteringCSS}>
        <div css={[headingHeroCSS, headingCSS]} data-testid={AUTOMATION_TEST_IDS.QUOTES_PAGE.HEADING}>
          {heading}
        </div>
        <div css={imageContainerCSS}>
          <AgentBackground css={imageBackgroundCSS} />
          <SirvImage
            customCSS={imageCSS}
            url={SIRV.QUOTE_HERO_AGENT_LADY}
            isLazyLoad={false}
            width={isMobile ? 255 : 558}
            alt="Advisor"
          />
        </div>
        <div css={[descriptionTextHeroCSS, descriptionCSS]}>{description}</div>

        {!isAccordVariation && (
          <div css={buttonIconWrapperCSS}>
            <div css={buttonWrapperCSS}>
              <Button css={buttonCSS} fullWidth={isMobile} variant="inverted" onClick={onActionClick}>
                {isPolicyHolderVariation && contactNumber ? (
                  <Fragment>
                    <Anchor href={`tel:${contactNumber}`} onClick={onPhoneNumberClick}>
                      <img src={HandsetIcon} width={20} alt="Handset" />
                      <span> {contactNumber}</span>
                    </Anchor>
                  </Fragment>
                ) : (
                  actionLabel
                )}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default QuotesHeroContent;
