/** @jsxImportSource @emotion/react */

import {
  Accordion as AccordionComponent,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion';

import LifeChevron from 'src/assets/svg/life-chevron.svg?react';
import {
  accordionContainerCSS,
  accordionHeadingCSS,
  accordionItemCSS,
  arrowCSS,
  descriptionCSS
} from './FAQAccordion.style';
import { AccordionProps } from 'src/shared/components/Accordion/Accordion.types';

const FAQAccordion: React.FC<AccordionProps> = ({ items, onChange }) => {
  const onAccordionChange = ([ordinal]: string[]) =>
    items[+ordinal - 1] && onChange && onChange(items[+ordinal - 1], +ordinal);

  return (
    <AccordionComponent allowZeroExpanded css={accordionContainerCSS} onChange={onAccordionChange}>
      {items.map(({ title, description }, key: number) => (
        <AccordionItem key={key + 1} uuid={key + 1} css={accordionItemCSS}>
          <AccordionItemHeading>
            <AccordionItemButton css={accordionHeadingCSS}>
              <span>{title}</span>
              <LifeChevron css={arrowCSS} />
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel css={descriptionCSS}>{description}</AccordionItemPanel>
        </AccordionItem>
      ))}
    </AccordionComponent>
  );
};

export default FAQAccordion;
