import { css, keyframes, SerializedStyles } from '@emotion/react';
import { colors } from 'src/theme/variables';

const showContainer = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const bounce = keyframes`
  0%, 30% {
    transform: translate(0, 0);
  }
  40% {
    transform: translate(0, -12px);
  }
  60% {
    transform: translate(0, 12px);
  }
  70%, 100% {
    transform: translate(0, 0);
  }
`;

export const containerCSS = css`
  display: flex;
  padding: 20px 0;
  justify-content: center;
  animation: ${showContainer} 0.3s linear;
`;

export const dotCSS = (delay = 0): SerializedStyles => css`
  content: '';
  width: 10px;
  height: 10px;
  background-color: ${colors.cheriseLight};
  margin-right: 2px;
  margin-left: 2px;
  border-radius: 50%;
  animation: ${bounce} 1.5s ease infinite;
  animation-delay: var(--delay);
  --delay: ${delay}s;
`;
