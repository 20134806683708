/** @jsxImportSource @emotion/react */
import React, { useCallback, useState } from 'react';

import { SerializedStyles } from '@emotion/react';
import { container } from './Expander.style';

interface Props {
  customCSS?: SerializedStyles | SerializedStyles[];
  isExpanded?: boolean;
  initialHeight?: number | string;
  accordionMode?: boolean;
  children?: React.ReactNode;
}

const Expander: React.FC<Props> = ({
  customCSS,
  isExpanded = false,
  initialHeight = 0,
  children,
  accordionMode = false
}) => {
  const [height, setHeight] = useState(initialHeight);
  const measureContent = useCallback((node: HTMLElement | null) => {
    if (node !== null) {
      setHeight(`${node.getBoundingClientRect().height}px`);
      setTimeout(() => {
        setHeight('auto');
      }, 300);
    }
  }, []);

  return (
    <div
      data-testid="expander"
      aria-live="polite"
      css={container(isExpanded ? height : initialHeight, isExpanded, accordionMode)}
    >
      <div css={customCSS} ref={measureContent}>
        {children}
      </div>
    </div>
  );
};

export default Expander;
