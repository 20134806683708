import { QuotesPageHeroType } from './QuotesPageHero.types';

export const getQuotesPageHeroHeading = (type: QuotesPageHeroType, firstName = ''): string => {
  switch (type) {
    case QuotesPageHeroType.PolicyHolder:
      return `${firstName}, have questions?  Let’s talk.`;
    case QuotesPageHeroType.NoQuotes:
      return `${firstName}, we need more information.`;
    case QuotesPageHeroType.Landlord:
      return `${firstName}, your landlord insurance quotes are ready!`;
    case QuotesPageHeroType.Accord:
      return `${firstName}, your home insurance quotes are ready!`;
    default:
      return `${firstName}, ready to switch policies? Let’s talk.`;
  }
};
