/** @jsxImportSource @emotion/react */
import React from 'react';

import { useParams } from 'react-router-dom';
import CoverageSelect from './CoverageSelect/CoverageSelect';
import CoverageShields from 'src/assets/svg/coverages-sprite.svg';
import { CoverageTypeOptions } from 'src/constants/coverageTypes';
import { CoverageType, CoverageTypeOption } from 'src/interfaces/ICoverageTypes';
import Format from 'src/utils/format';
import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';
import { DefaultLocation } from 'src/interfaces/IPage';
import useSession from 'src/api/session/useSession';
import CoverageOptionsTitle from './CoverageOptionsTitle/CoverageOptionsTitle';
import useResponsive from 'src/hooks/useResponsive';
import {
  description,
  icon,
  price,
  title,
  option as optionCSS,
  container,
  select,
  optionTitle,
  options
} from './CoverageOptions.style';
import { CoverageOptionsProps } from './CoverageOptions.types';
import { getPremiumLabelByType } from 'src/utils/quotesPage';

const CoverageOptions: React.FC<CoverageOptionsProps> = ({
  customCSS,
  selected = CoverageType.Basic,
  onChange,
  assets,
  quotes
}) => {
  const { flow, gid } = useParams() as DefaultLocation;
  const { data } = useSession(flow, gid);
  const { isMobile } = useResponsive();
  const hasQuotes = (coverageType: CoverageType) => quotes[coverageType].length !== 0;
  const onCoverageChange = (value: CoverageType) => {
    analytics.track(SEGMENT.COVERAGE_OPTIONS_SELECTED, gid, data?.flow, { option: value });
    onChange(value);
  };
  const onLearnMore = () => {
    analytics.track(SEGMENT.COVERAGE_OPTIONS_REVIEWED, gid, data?.flow);
  };

  const getOptionPrice = (option: CoverageTypeOption): string => {
    if (!hasQuotes(option.value)) {
      return 'No quotes found';
    }
    const value = Format.money(quotes[option.value][0].premium.value, 0, '$');
    const type = getPremiumLabelByType(quotes[option.value][0].premium.type, true);

    return `Starting at ${value}${type}`;
  };

  const renderOption = (option: CoverageTypeOption) => (
    <button
      css={optionCSS(selected === option.value, !hasQuotes(option.value))}
      key={option.value}
      disabled={!hasQuotes(option.value)}
      onClick={() => onCoverageChange(option.value)}
    >
      <img
        css={icon}
        src={`${CoverageShields}#${option.value}${selected === option.value ? 'red' : ''}`}
        alt={`${option.label} Coverage`}
      />
      <div css={title} data-testid="coverage-option-title" aria-hidden="true">
        {option.label}
      </div>
      <div css={price} data-testid="coverage-option-price">
        {getOptionPrice(option)}
      </div>
      <div css={description}>{option.description}</div>
    </button>
  );

  return (
    <div css={[customCSS, container]}>
      <CoverageOptionsTitle customCSS={optionTitle} assets={assets} onActionClick={onLearnMore} />

      {!isMobile && (
        <div css={options} data-testid="coverage-type-options">
          {CoverageTypeOptions.map(renderOption)}
        </div>
      )}

      {isMobile && <CoverageSelect customCSS={select} value={selected} onChange={onCoverageChange} quotes={quotes} />}
    </div>
  );
};

export default CoverageOptions;
