import React from 'react';

const items = [
  {
    key: '1',
    title: 'Who is Matic?',
    description: (
      <span>
        Matic is a digital insurance agency that helps customers like you save money on their insurance. When you
        request a quote, Matic finds the best price from our network of over 40 A-rated carriers in seconds.
      </span>
    )
  },
  {
    key: '2',
    title: 'What does Matic charge?',
    description: (
      <span>
        Nothing. Matic gets paid a commission directly from the insurance carrier when you switch policies. You’ll only
        switch if you’re getting value so we do our best.
      </span>
    )
  },
  {
    key: '3',
    title: 'Can I bundle my home and auto insurance?',
    description: <span>Yes. With Matic you can bundle your home and auto insurance and save up to 20%.</span>
  },
  {
    key: '4',
    title: 'How does Matic get compensated?',
    description: (
      <span>
        We get paid a commission directly from the insurance carriers when you switch policies. You’ll only switch if
        you’re getting value so we do our best.
      </span>
    )
  }
];

export default {
  items
};
