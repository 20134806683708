export enum HomeCoverageKey {
  Dwelling = 'dwelling',
  OtherStructures = 'other_structures',
  PersonalProperty = 'personal_property',
  LossOfUse = 'loss_of_use',
  PersonalLiability = 'personal_liability',
  MedicalPayments = 'medical_payments',
  Hurricane = 'hurricane',
  WindHail = 'wind_hail'
}

enum AutoCoverageKey {
  BiPerPerson = 'bodily_injury_per_person',
  BiPerAccident = 'bodily_injury_per_accident',
  PropertyDamage = 'property_damage',
  UninsuredMpd = 'uninsured_motorist_property_damage',
  UninsuredMbiPerPerson = 'uninsured_motorist_bodily_injury_per_person',
  UninsuredMbiPerAccident = 'uninsured_motorist_bodily_injury_per_accident',
  UnderinsuredMbiPerPerson = 'underinsured_motorist_bodily_injury_per_person',
  UnderinsuredMbiPerAccident = 'underinsured_motorist_bodily_injury_per_accident',
  Comprehensive = 'comprehensive',
  Collision = 'collision',
  Towing = 'towing',
  RenalPerDay = 'rental_per_day',
  RentalMaxAmount = 'rental_max_amount',
  PersonalInjuryProtection = 'personal_injury_protection'
}

enum PAFCoverageKey {
  Jewelry = 'jewelry'
}

enum LimitType {
  MoneyLimit = 'money_limit',
  MonthlyLimit = 'monthly',
  OtherLimit = 'other'
}

enum DeductibleType {
  Flat = 'flat',
  Percentage = 'percentage'
}

// TODO: this interface actually can be used not only for quotes but for policies as well.
// It can be potentially moved to it's own location and used as a part of Policy interface.
export interface IQuoteCoverage {
  asset_gid: string | null;
  key: HomeCoverageKey | AutoCoverageKey | PAFCoverageKey;

  limit_type: LimitType | null;
  limit_value: string | null;
  limit_description: string | null;

  deductible_type: DeductibleType | null;
  deductible_value: string;
  deductible_description: string | null;
}

export interface IQuote {
  gid: string;
  premium: string;
  policy_type: string;
  kind: string;
  coverages_tier: string | null;
  coverages: IQuoteCoverage[];
  multi_policy_discount_premium: number | null;
  multi_policy_discount_percentage: string | null;

  effective_date: string;
  expiration_date: string;

  online_bind_url: string | null;

  assets_gid: string[] | null;
  coinsureds_gids: string[] | null;

  carrier_key: string;
  carrier_name: string;
  carrier_logo_url: string;
  carrier_marketing_description: string | null;
  carrier_gid: string;
}

export interface IQuotesGroup {
  quotes: IQuote[];
  total_premium: string;
  multi_policy_discount: boolean;
}
