import { AddressAnswer } from 'src/interfaces/IQuestion';
import { PropertyTypeKeys } from 'src/constants/schemaKeys';

export const getReadableAddress = (address?: AddressAnswer): string => {
  if (!address) {
    return '';
  }
  return [address?.line1, address?.city, address?.state, address?.zip].filter(v => !!v).join(', ');
};

export const getReadablePropertyType = (propertyType?: PropertyTypeKeys): string => {
  switch (propertyType) {
    case PropertyTypeKeys.home:
      return 'House';
    case PropertyTypeKeys.condo:
      return 'Condo';
    default:
      return '';
  }
};

export const getReadablePropertyStories = (stories?: string, renderWithoutNumber?: boolean): string => {
  if (!renderWithoutNumber && (!stories || Number.isNaN(+stories))) {
    return '';
  }
  const num = Number(stories);

  if (renderWithoutNumber) {
    return ` Stor${num > 1 ? 'ies' : 'y'}`;
  }

  return `${num} Stor${num > 1 ? 'ies' : 'y'}`;
};
