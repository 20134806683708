import { css } from '@emotion/react';
import { colors, questionsForm, indicators, mq } from './variables';

export const indicatorLine = css`
  background-color: ${colors.dove};
  bottom: ${indicators.sizeMobile / 2 - 18}px;
  left: -18.5px;
  position: absolute;
  top: 0;
  width: 2px;
  ${mq[0]} {
    bottom: ${indicators.sizeDesktop / 2 - 25}px;
    left: -29.5px;
  }
`;

export const indicatorNegativeLine = css`
  ${indicatorLine};
  background-color: #fff;
`;

export const indicator = css`
  background-color: ${colors.white};
  border: 2px solid ${colors.dove};
  border-radius: 50%;
  content: '';
  height: ${indicators.sizeMobile}px;
  left: -23.5px;
  position: absolute;
  top: ${18 - indicators.sizeMobile / 2}px;
  width: ${indicators.sizeMobile}px;
  z-index: 1;
  ${mq[0]} {
    height: ${indicators.sizeDesktop}px;
    left: -38px;
    top: 9px;
    width: ${indicators.sizeDesktop}px;
  }
  ${mq[1]} {
    top: 14px;
  }
`;

export const formHolder = css`
  appearance: none;
  border: none;
  margin: 0;
  padding: 0 0 ${questionsForm.formHolderBottomSpaceMobile}px;
  position: relative;

  ${mq[0]} {
    padding-bottom: ${questionsForm.formHolderBottomSpaceDesktop}px;
  }
`;

export const formActions = css`
  position: relative;
  margin-bottom: 24px;
  margin-top: -32px;
  text-align: center;
  z-index: 1;
  ${mq[0]} {
    margin-bottom: 60px;
    margin-top: -72px;
  }
`;

export const faqHorizontalScrollFixContainer = css`
  overflow-x: hidden;
  ${mq[1]} {
    overflow: auto;
  }
`;

export const formInputFocusOutline = css`
  content: '';
  position: absolute;
  top: -4px;
  right: -4px;
  bottom: -4px;
  left: -4px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  border-radius: 7px;
  border: 2px solid transparent;
  transition: border-color 0.2s ease;
`;
