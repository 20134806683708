/** @jsxImportSource @emotion/react */
import React, { Fragment, useEffect, useState } from 'react';

import { QuotesProps, SelectedQuoteState } from 'src/components/Quotes/Quotes.types';
import { useParams } from 'react-router-dom';
import { DefaultLocation, Entity, Flows } from 'src/interfaces/IPage';
import useQuotes from 'src/api/quotes/useQuotes';
import { BundleQuote, BundleQuotesResponse, BundleStatus, BundleType } from 'src/interfaces/IQuotes';

import Container from 'src/kit/Container/Container';
import { quoteCard, quotesBanner, quotesContainer } from 'src/components/Quotes/Quotes.style';

import Quantify from 'src/utils/quantify';
import { getPremiumLabelByType } from 'src/utils/quotesPage';
import QuoteBanner from 'src/components/Quotes/QuotesBanner/QuotesBanner';
import useResponsive from 'src/hooks/useResponsive';
import { QUOTE_TAGS } from 'src/constants/quoteTag';
import { Tag } from 'src/components/Quotes/QuoteCard/QuoteCard.types';
import BundleQuoteCard from 'src/components/Quotes/QuoteCard/BundleQuoteCard/BundleQuoteCard';
import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';
import QuoteDetailsDialog from 'src/components/Quotes/QuoteDetailsDialog/QuoteDetailsDialog';
import BundleQuoteCardDetails from 'src/components/Quotes/QuoteCard/components/BundleQuoteCardDetails/BundleQuoteCardDetails';
import BundleQuoteTypeDetails from 'src/components/Quotes/QuoteCard/components/BundleQuoteTypeDetails/BundleQuoteTypeDetails';
import Format from 'src/utils/format';
import { detailsHeading, detailsHeadingItem } from 'src/components/Quotes/AutoBundleQuotes/AutoBundleQuotes.style';
import BundleQuotesHeader from './../BundleQuotes/BundleQuotesHeader';
import { useCustomNavigate } from 'src/hooks/useCustomNavigate';
import { tabWrapperCSS, switchButtonCSS } from './HomeBundleQuotes.style';
import HomeIcon from 'src/assets/svg/coverage-home.svg?react';
import AutoIcon from 'src/assets/svg/coverage-auto.svg?react';
import { HomeBundleTab } from './HomeBundle.types';
import useQuestions from 'src/api/questions/useQuestions';
import BundleActionCard from 'src/components/Quotes/QuoteCard/BundleActionCard/BundleActionCard';
import Icon from 'src/assets/svg/bundle-home.svg';
import { getUniqueHomeQuotes } from './HomeBundleQuotes.utils';
import userEventDataService, { UserEvents } from 'src/api/userEventsData/userEventsData';

const HomeBundleQuotes: React.FC<QuotesProps> = ({ selectQuote }) => {
  const { isMobile } = useResponsive();
  const navigate = useCustomNavigate();
  const { flow, gid } = useParams() as DefaultLocation;
  const { data: quotes } = useQuotes<BundleQuotesResponse>(flow, gid);
  const quotesData = quotes as BundleQuotesResponse;
  const { data: questions } = useQuestions(gid);
  const { auto_vehicles, drivers } = questions?.answers || {};
  const [currentTab, setCurrentTab] = useState<HomeBundleTab>(HomeBundleTab.HomeBundle);
  const [isMobileDetailsOpen, setIsMobileDetailsOpen] = useState(false);
  const [selectedQuote, setSelectedQuote] = useState<SelectedQuoteState<BundleQuote> | null>(null);

  const first_name = questions?.answers.person_first_name;
  const address = questions?.answers.property_address;

  const hasNoQuotes = quotesData.bundle_status === BundleStatus.Failed;
  const homeOnly = quotesData.bundle_status === BundleStatus.HomeOnly;

  const addressValue = (address as Record<string, string>)?.line1;
  const vehiclesNumber = Quantify.anything((auto_vehicles as Entity[])?.length, 'Vehicle', 'Vehicles');
  const driversNumber = Quantify.anything((drivers as Entity[])?.length, 'Driver', 'Drivers');
  const filteredHomeQuotes = getUniqueHomeQuotes(quotesData?.quotes);

  useEffect(() => {
    analytics.page(SEGMENT.PAGES.QUOTES, {
      flow_type: flow,
      session_gid: gid
    });
  }, [flow, gid]);

  useEffect(() => {
    const quotesDataToRecap = quotesData.quotes.map(quote => ({
      premium: quote.premium.value,
      auto: {
        carrier_key: quote.quotes.auto?.carrier.key,
        gid: quote.quotes.auto?.gid
      },
      home: {
        carrier_key: quote.quotes.home.carrier.key,
        gid: quote.quotes.home.gid
      }
    }));

    if (quotesData) {
      analytics.track(SEGMENT.QUOTES_LIST_VIEWED, gid, flow, {
        number_of_quotes: quotesData.quotes.length,
        bundle: quotesData.bundle_status === BundleStatus.Success || quotesData.bundle_status === BundleStatus.Failed,
        quotes: quotesDataToRecap
      });
    }
  }, [flow, gid, quotesData]);

  const onHomeOnlyMessageActionClick = () => {
    analytics.track(SEGMENT.PHONE_CONVERSATION_INITIATED, gid, flow, {
      location: SEGMENT.LOCATIONS.BUNDLE_HOME_ONLY_MESSAGE,
      page: SEGMENT.PAGES_KEY.QUOTES
    });
    navigate(`/${flow}/${gid}/confirmation`);
  };

  const getQuoteTags = (quote: BundleQuote): Tag[] => {
    const tags = [];
    if (quote.best_value) {
      tags.push(QUOTE_TAGS.BEST_VALUE);
    }
    if (quote.bundle_discount && currentTab === HomeBundleTab.HomeBundle) {
      tags.push(QUOTE_TAGS.BUNDLE_DISCOUNT);
    }
    return tags;
  };

  const renderQuoteDetailsHeading = (quote: BundleQuote) => {
    if (homeOnly) {
      return null;
    }

    return (
      <div css={detailsHeading}>
        <BundleQuoteTypeDetails
          customCSS={detailsHeadingItem}
          type="home"
          logo={{
            url: quote.quotes.home.carrier.logo_url,
            label: quote.quotes.home.carrier.name
          }}
          premium={{
            value: Format.money(quote.quotes.home.premium.value, 0, '$'),
            label: getPremiumLabelByType(quote.quotes.home.premium.type)
          }}
        />
        {currentTab !== HomeBundleTab.Home && (
          <BundleQuoteTypeDetails
            customCSS={detailsHeadingItem}
            type="auto"
            logo={{
              url: quote.quotes.auto?.carrier.logo_url,
              label: quote.quotes.auto?.carrier.name
            }}
            premium={{
              value: Format.money(quote.quotes.auto?.premium.value, 0, '$'),
              label: getPremiumLabelByType(quote.quotes.auto?.premium.type)
            }}
          />
        )}
      </div>
    );
  };

  const trackQuoteReviewed = async (quote: BundleQuote, ordinal: number) => {
    analytics.track(SEGMENT.QUOTE_REVIEWED, gid, Flows.Home, {
      carrier_key: quote.quotes.home.carrier.key,
      ordinal
    });

    if (!homeOnly) {
      analytics.track(SEGMENT.QUOTE_REVIEWED, gid, Flows.Auto, {
        carrier_key: quote.quotes.auto?.carrier.key,
        ordinal
      });
    }

    try {
      await userEventDataService.callUserEventReport(UserEvents.QuoteDetailsClicked, gid);
    } catch {}
  };

  const onShowDetailsClicked = (quote: BundleQuote, ordinal: number) => () => {
    trackQuoteReviewed(quote, ordinal);
  };

  const getQuotedData = (quote: BundleQuote) => {
    const quoteSelectData = {
      gid: quote.quotes.home.gid,
      carrier: quote.quotes.home.carrier,
      premium_value: quote.quotes.home.premium.value,
      flow: Flows.Home,
      onlineBindUrl: quote.quotes.home.online_bind_url,
      digital_profile_url: quote.quotes.home.digital_profile_url
    };
    const bundledQuoteSelectData =
      homeOnly || currentTab === HomeBundleTab.Home
        ? null
        : {
            gid: quote.quotes.auto?.gid,
            carrier: quote.quotes.auto?.carrier,
            premium_value: quote.quotes.auto?.premium.value,
            flow: Flows.Home
          };

    return { quoteSelectData, bundledQuoteSelectData };
  };

  const onQuoteSelect = (quote: BundleQuote, ordinal: number) => () => {
    if (isMobile) {
      trackQuoteReviewed(quote, ordinal);
      setSelectedQuote({ quote, ordinal });
      setIsMobileDetailsOpen(o => !o);
    } else {
      const { quoteSelectData, bundledQuoteSelectData } = getQuotedData(quote);
      selectQuote(ordinal, quoteSelectData, bundledQuoteSelectData);
    }
  };

  const onQuoteSelectFromDetailsPage = () => {
    if (selectedQuote) {
      const { quote, ordinal } = selectedQuote;
      const { quoteSelectData, bundledQuoteSelectData } = getQuotedData(quote);
      selectQuote(ordinal, quoteSelectData, bundledQuoteSelectData);
    }
  };

  const getMobileDetailsPopup = () => {
    if (selectedQuote) {
      const { quote: q } = selectedQuote;
      const logo = { url: q.quotes.home.carrier.logo_url, label: q.quotes.home.carrier.name };
      const price = {
        value: Format.money(q.quotes.home.premium.value, 0, '$'),
        label: getPremiumLabelByType(q.quotes.home.premium.type)
      };

      return (
        <QuoteDetailsDialog
          logo={logo}
          price={price}
          customHeading={renderQuoteDetailsHeading(q)}
          contentComponent={
            <BundleQuoteCardDetails
              quote={q}
              bundleType={BundleType.HomeBundle}
              isSingleType={homeOnly || currentTab === HomeBundleTab.Home}
            />
          }
          onClose={() => {
            setIsMobileDetailsOpen(false);
            setTimeout(() => setSelectedQuote(null), 100);
          }}
          onActionClick={onQuoteSelectFromDetailsPage}
          isOpen={isMobileDetailsOpen}
        />
      );
    }
  };

  const getQuoteToRender = (quote: BundleQuote, i: number, isSingleType: boolean) => {
    return (
      <BundleQuoteCard
        customCSS={quoteCard(true)}
        key={isSingleType ? quote.quotes?.home?.gid : `${quote.quotes?.home?.gid}${quote.quotes?.auto?.gid}`}
        quote={quote}
        tags={getQuoteTags(quote)}
        isSingleType={isSingleType ? true : homeOnly}
        onShowDetailsClicked={onShowDetailsClicked(quote, i + 1)}
        onQuoteSelect={onQuoteSelect(quote, i + 1)}
        singleTypeCarrierName={quote.quotes?.home?.carrier.name}
        singleTypeLogo={quote.quotes?.home?.carrier.logo_url}
        singleTypePremium={quote.quotes?.home?.premium}
        bundleType={BundleType.HomeBundle}
      />
    );
  };

  return (
    <Container customCSS={quotesContainer} isAriaLivePolite>
      <BundleQuotesHeader
        userName={first_name as string}
        addressValue={addressValue}
        vehiclesNumberLabel={vehiclesNumber}
        driversNumberLabel={driversNumber}
        bundle_status={quotesData.bundle_status}
        isEditingAllowed={!!quotesData?.allow_editing_details}
      />

      {!homeOnly && !hasNoQuotes && (
        <div css={tabWrapperCSS}>
          <button
            css={switchButtonCSS(currentTab === HomeBundleTab.HomeBundle)}
            onClick={() => setCurrentTab(HomeBundleTab.HomeBundle)}
          >
            <HomeIcon /> + <AutoIcon /> Bundle Quotes
          </button>
          <button
            css={switchButtonCSS(currentTab === HomeBundleTab.Home)}
            onClick={() => setCurrentTab(HomeBundleTab.Home)}
          >
            <HomeIcon /> Home Quotes
          </button>
        </div>
      )}
      {homeOnly && (
        <BundleActionCard img={Icon} customCSS={quoteCard(true)} onActionClick={onHomeOnlyMessageActionClick} />
      )}
      {!hasNoQuotes && (
        <Fragment>
          {currentTab === HomeBundleTab.HomeBundle
            ? quotesData?.quotes?.map((quote: BundleQuote, i: number) => getQuoteToRender(quote, i, false))
            : filteredHomeQuotes.map((quote: BundleQuote, i: number) => getQuoteToRender(quote, i, true))}
        </Fragment>
      )}

      <QuoteBanner customCSS={quotesBanner} hasNoQuotes={hasNoQuotes} currentQuoteLength={quotesData?.quotes.length} />

      {getMobileDetailsPopup()}
    </Container>
  );
};

export default HomeBundleQuotes;
