import {
  HomeDetailsFields,
  HomeDetailsResponse
} from 'src/components/HomeDetails/HomeDetailsWrapper/HomeDetailsWrapper.types';
import { API_URLS } from 'src/constants/api';
import { getApiUrl } from 'src/utils/api.helpers';
import api from 'src/api/api';

const homeDetailsService = {
  getHomeDetails(gid: string): Promise<HomeDetailsResponse | null> {
    return api.get(getApiUrl(API_URLS.HOME_DETAILS, gid));
  },
  updateHomeDetails(gid: string, data: HomeDetailsFields): Promise<HomeDetailsResponse> {
    return api.put(getApiUrl(API_URLS.HOME_DETAILS, gid), { body: data });
  }
};

export default homeDetailsService;
