import { Flows } from 'src/interfaces/IPage';
import { UseBaseMutationResult, useMutation, useQueryClient } from 'react-query';
import { Session } from 'src/interfaces/session.types';
import { Answers } from 'src/interfaces/IAnswer';
import { ResponseError } from 'src/interfaces/IResponse';
import { QUERY_CACHE_KEYS } from 'src/constants/queryCacheKeys';
import api from 'src/api/api';
import { API_URLS } from 'src/constants/api';
import { getApiUrl } from 'src/utils/api.helpers';

const useMutateAnswers = (
  flow: Flows,
  gid: string,
  refetchQueries: string[][] = []
): UseBaseMutationResult<Partial<Session>, ResponseError, Answers> => {
  const client = useQueryClient();

  return useMutation(
    (data: Answers): Promise<Session> =>
      api.put(getApiUrl(API_URLS.ANSWERS_UPDATED, flow, gid), {
        body: data
      }),
    {
      onSuccess: async () => {
        await Promise.all(
          [QUERY_CACHE_KEYS.QUOTES, QUERY_CACHE_KEYS.QUESTIONS, ...refetchQueries].map(key =>
            client.refetchQueries(key)
          )
        );
      }
    }
  );
};

export default useMutateAnswers;
