/** @jsxImportSource @emotion/react */
import React from 'react';

import { useFormContext } from 'react-hook-form';

import { HookFormFieldEntity, Greeting as GreetingInterface } from 'src/interfaces/IPage';
import { BaseForm } from 'src/interfaces/form.types';
import QuestionForm from 'src/questionsForm/components/QuestionForm/QuestionForm';
import Greeting from 'src/questionsForm/components/Greeting/Greeting';
import useFormScroll from 'src/questionsForm/hooks/useFormScroll/useFormScroll';
import {
  entityDetailsForm,
  entityGreeting,
  scrollPoint,
  questions as questionsCSS,
  form
} from './EntityDetailsForm.style';
import { getQuestionForms } from 'src/questionsForm/utils/questionsForm.utils';
import { UploadFilesComponentProps } from 'src/components/UploadFilesComponent/UploadFilesComponent.types';

interface Props {
  entity: HookFormFieldEntity;
  greeting?: GreetingInterface;
  questions: BaseForm[];
  isLast: boolean;
  key_name: string;
  index: number;
  onLoginClick?(): void;
  triggerSchemaUpdate?(): void;
  uploadFilesWrapperParams?: UploadFilesComponentProps;
}

const emptyObject = {};

const EntityDetailsForm: React.FC<Props> = ({
  entity,
  questions,
  greeting,
  isLast,
  key_name: formKeyName,
  index,
  triggerSchemaUpdate,
  uploadFilesWrapperParams
}) => {
  const {
    watch,
    formState: { touchedFields }
  } = useFormContext();
  const answers = watch(`${formKeyName}.${index}`);
  const visibleForms = getQuestionForms(answers || emptyObject, questions);
  const touchedFieldsArray = Object.keys(touchedFields?.[formKeyName]?.[index] || {});
  const formPrefix = `${formKeyName}[${index}]`;

  useFormScroll(visibleForms, answers, touchedFieldsArray, formPrefix);
  return (
    <div css={entityDetailsForm} data-testid="entity-details-form">
      <div data-testid="entity-details-scroll-point" css={scrollPoint} id={`${formKeyName}.${index}`} />
      {/** Container for sticky greeting */}
      <div>
        {greeting && (
          <Greeting customCSS={entityGreeting(index === 0)} keyName={formKeyName} textOptions={entity} {...greeting} />
        )}
        <div css={questionsCSS}>
          {visibleForms.map(({ key_name, isDimmed, ...f }, index) => {
            const isLastVisibleForm = visibleForms.length === index + 1;
            const isLineHidden = isLastVisibleForm && isLast;

            return (
              <QuestionForm
                {...f}
                customCSS={form(isLastVisibleForm)}
                key={`${key_name}.${index}`}
                key_name={key_name}
                answers={entity}
                questionKeyName={formKeyName}
                isLineHidden={isLineHidden}
                formPrefix={formPrefix}
                isDimmed={isDimmed}
                isFieldArray
                triggerSchemaUpdate={triggerSchemaUpdate}
                uploadFilesWrapperParams={uploadFilesWrapperParams}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default EntityDetailsForm;
