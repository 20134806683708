import { css } from '@emotion/react';
import { colors, mq } from 'src/theme/variables';
import { activeElementReset } from 'src/theme/helpers';
import { indicatorLine, indicatorNegativeLine } from 'src/theme/shared-styles';

export const skip = css`
  ${activeElementReset};
  margin-top: 12px;
  text-align: center;
  color: ${colors.azure};
`;

export const defaultFormPage = (isLifeVariation?: boolean) => css`
  position: relative;
  scroll-margin-top: var(--header-height);

  ${isLifeVariation &&
  css`
    fieldset {
      padding: 0;
    }
  `}

  ${!isLifeVariation &&
  css`
    &::before {
      ${indicatorLine};
      content: '';
    }
  `}
`;

export const defaultPageGreeting = css`
  padding-bottom: 28px;
  position: relative;
  ${mq[0]} {
    padding-bottom: 40px;
  }
  ${mq[1]} {
    padding-bottom: 56px;
  }
  &::before {
    ${indicatorNegativeLine};
    content: '';
  }
  &:empty {
    &::before {
      display: none;
    }
  }
`;
