import { AccordionItem } from 'src/shared/components/Accordion/Accordion.types';

export const getAccordionItems = (): AccordionItem[] => [
  {
    key: '1',
    title: 'Who is Matic?',
    description: (
      <span>
        Matic is a digital insurance agency that helps customers like you save money on their insurance. When you
        request a quote, Matic searches our network of over 40 A-rated carriers in seconds.
      </span>
    )
  },
  {
    key: '2',
    title: 'How Matic partners with Kin?',
    description: (
      <span>
        If Matic is unable to match you with a product or service that meets your needs, our carrier partner Kin
        Insurance may be able to help. By clicking “Yes, I’m interested” you will go to Kin’s website where you can get
        quotes and choose to buy a policy directly from Kin.
      </span>
    )
  },
  {
    key: '3',
    title: 'How does Matic get compensated?',
    description: (
      <span>
        We get paid a commission directly from the insurance carriers when you switch policies. You’ll only switch if
        you’re getting value so we do our best.
      </span>
    )
  }
];

export const firstRowKinOptions = [
  '4.6 out of 5 average customer satisfaction rating',
  'Fast, easy online quotes',
  'Top-rated service when you need it'
];

export const secondRowKinOptions = [
  'Affordable premiums and deductibles',
  'Member-owned insurance company that’s customer-focused by design',
  'Customizable coverage in minutes'
];
