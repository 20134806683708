/** @jsxImportSource @emotion/react */
import { Fragment, ReactNode } from 'react';
import {
  lockContainerCSS,
  backgroundContentCSS,
  blurredCSS,
  wrapperCSS,
  headingCSS,
  loadingCSS
} from './LifeQuotesLock.style';
import LockIcon from 'src/assets/svg/lock-icon.svg?react';
import useResponsive from 'src/hooks/useResponsive';
import LoadingDots from 'src/shared/components/LoadingDots/LoadingDots';

const LifeQuotesLock: React.FC<{ children: ReactNode; isLoading: boolean }> = ({ children, isLoading }) => {
  const { isMobile } = useResponsive();
  return (
    <div css={lockContainerCSS} data-testid="quotes-lock">
      <div css={blurredCSS}>
        <div css={wrapperCSS(isLoading)}>
          <LockIcon width={isLoading || isMobile ? 64 : 120} height={isLoading || isMobile ? 64 : 120} />
          {isLoading && (
            <Fragment>
              <div css={headingCSS} data-testid="quotes-loading">
                Unlocking your quotes
              </div>
              <div>We&apos;re reviewing your details so we can unlock the best quotes for you. </div>
              <LoadingDots customCSS={loadingCSS} />
            </Fragment>
          )}
        </div>
      </div>
      <div css={backgroundContentCSS}>{children}</div>
    </div>
  );
};

export default LifeQuotesLock;
