import { utcToZonedTime } from 'date-fns-tz';
import { getDay, isWithinInterval } from 'date-fns';

const yyyyMMDDPattern = /(\d{4})-(\d{2})-(\d{2})/;
const mmDDYYYYPattern = /(\d{2})-(\d{2})-(\d{4})/;

const yearInMs = 3.15576e10;
export const getAge = (date: string): number | string => {
  const result = Math.floor((Date.now() - +new Date(date)) / yearInMs);
  if (isNaN(result)) return date;

  return result;
};

const getDateTZ = (): Date => {
  const date = new Date();
  return utcToZonedTime(date, 'America/New_York');
};

type workingHoursType = [number, number] | [number];

type isWorkingHoursType = {
  startHrs?: workingHoursType;
  monToFriEndHrs?: workingHoursType;
  satEndHrs?: workingHoursType;
};

const getWorkingHours = ([hours = 0, min = 0]: workingHoursType): Date => {
  return new Date(getDateTZ().setHours(hours, min, 0, 0));
};

export const isWorkingHours = (hours?: isWorkingHoursType): boolean => {
  const { startHrs = [9, 0], monToFriEndHrs = [20, 0], satEndHrs = [13, 0] } = hours || {};

  const currentTime = getDateTZ();
  const day = getDay(currentTime);

  const isWorkingHoursMonToFri = isWithinInterval(currentTime, {
    start: getWorkingHours(startHrs),
    end: getWorkingHours(monToFriEndHrs)
  });

  const isWorkingHoursSat = isWithinInterval(currentTime, {
    start: getWorkingHours(startHrs),
    end: getWorkingHours(satEndHrs)
  });

  return (isWorkingHoursMonToFri && ![0, 6].includes(day)) || (isWorkingHoursSat && day === 6);
};

export const parseDateValue = (value: string): string => {
  // YYYY-MM-DD to MM-DD-YYYY
  if (!value.match(yyyyMMDDPattern)) return value;

  return value.replace(yyyyMMDDPattern, '$2-$3-$1');
};

export const normalizeDateValue = (value: string): string => {
  // MM-DD-YYYY to YYYY-MM-DD
  if (!value.match(mmDDYYYYPattern)) return value;

  return value.replace(mmDDYYYYPattern, '$3-$1-$2');
};
