/** @jsxImportSource @emotion/react */
import React from 'react';

import { TalkToAdvisorCardProps } from './TalkToAdvisorCard.types';
import {
  actionButtonCSS,
  cardCSS,
  headingCSS,
  descriptionCSS,
  carriersLogoCSS,
  callActionCSS
} from './TalkToAdvisorCard.style';
import { Card } from 'src/shared/components/Card/Card';
import Button from 'src/kit/Button/Button';
import noop from 'src/utils/noop';
import SirvImage from 'src/kit/SirvImage/SirvImage';
import Format from 'src/utils/format';
import Anchor from 'src/kit/Anchor/Anchor';

const TalkToAdvisorCard: React.FC<TalkToAdvisorCardProps> = ({
  customCSS,
  img,
  imgAlt,
  heading,
  description,
  onTalkToAdvisorClick = noop,
  carriersArray,
  submitText = 'Talk to an Advisor',
  contactPhone = ''
}) => {
  return (
    <Card customCSS={[cardCSS, customCSS]}>
      <img src={img} alt={imgAlt} />
      <div css={headingCSS}>{heading}</div>
      <div css={descriptionCSS}>{description}</div>
      {carriersArray?.length && (
        <div css={carriersLogoCSS}>
          {carriersArray.map((carrier, index) => (
            <SirvImage url={carrier.src} key={index} width={120} alt={carrier.alt} />
          ))}
        </div>
      )}
      {contactPhone ? (
        <Anchor customCSS={callActionCSS} href={`tel:${Format.phone(contactPhone)}`} onClick={onTalkToAdvisorClick}>
          {submitText}
        </Anchor>
      ) : (
        <Button data-testid="talk-to-advisor-button" customCSS={actionButtonCSS} onClick={onTalkToAdvisorClick}>
          {submitText}
        </Button>
      )}
    </Card>
  );
};

export default TalkToAdvisorCard;
