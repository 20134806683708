import { useParams } from 'react-router-dom';
import { DefaultLocation } from 'src/interfaces/IPage';
import browserUpdate from 'browser-update';
import SEGMENT from 'src/constants/segment';
import analytics from 'src/utils/analytics';
import { useEffect, useState } from 'react';

const useBrowserUpdate = (): number => {
  const { gid, flow } = useParams() as Partial<DefaultLocation>;
  const [browserUpdateHeight, setBrowserUpdateHeight] = useState(0);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entry => {
      setBrowserUpdateHeight(entry[0].contentRect.height || 0);
    });

    const getBrowserNotificationBanner = () => document.getElementById('buorg');

    browserUpdate({
      container: document.getElementsByTagName('header')[0],
      required: { o: 0, c: -20, f: -20, e: -20, s: -5 },
      onshow: () => {
        analytics.track(SEGMENT.BROWSER_UPDATE.SHOWN, gid, flow);
        resizeObserver.observe(getBrowserNotificationBanner()!);
      },
      onclick: () => analytics.track(SEGMENT.BROWSER_UPDATE.UPDATE_BUTTON_CLICKED, gid, flow),
      onclose: () => analytics.track(SEGMENT.BROWSER_UPDATE.IGNORE_BUTTON_CLICKED, gid, flow),
      text: {
        msgmore: 'For the best experience, we recommend that you update.'
      }
    });

    return () => {
      const browserUpdateBanner = getBrowserNotificationBanner();
      browserUpdateBanner && resizeObserver.unobserve(browserUpdateBanner);
    };
  }, [flow, gid]);

  return browserUpdateHeight;
};

export default useBrowserUpdate;
