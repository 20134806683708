import { AddressAnswer, AnswerValue } from 'src/interfaces/IQuestion';
import { normalizePhoneNumber } from './phone';

interface KinUrlParams {
  address?: AddressAnswer | null;
  personGid?: AnswerValue | null;
  firstName?: AnswerValue | null;
  lastName?: AnswerValue | null;
  email?: AnswerValue | null;
  personPhone?: AnswerValue | null;
}
export const getKinUrl = (kinObject: KinUrlParams) => {
  const { state, zip, line1, line2, city } = kinObject.address as AddressAnswer;
  const person_gid = kinObject.personGid ? kinObject.personGid : '';
  const firstName = kinObject.firstName ? kinObject.firstName : '';
  const lastName = kinObject.lastName ? kinObject.lastName : '';
  const email = kinObject.email ? kinObject.email : '';
  const phone = kinObject.personPhone ? kinObject.personPhone : '';
  const addressStreet = !!line2 ? `${line1} ${line2}` : line1;
  const envParam = import.meta.env.PROD ? '' : '_tlk=2JM6C7M9&';
  return (
    `https://www.jh3iusl3d.com/LR9KH/2CTPL/?${envParam}` +
    `address_street=${addressStreet}&address_zip=${zip}&address_state=${state}&address_city=${city}` +
    `&first_name=${firstName}&last_name=${lastName}` +
    `&raw_email=${email}&raw_phone=${normalizePhoneNumber(phone as string)}&sub_1=${person_gid}`
  );
};
