import { css } from '@emotion/react';

import { mq, colors as c, confettiHeight } from 'src/theme/variables';
import { text12, text14 } from 'src/theme/typography';
import FooterConfetti from 'src/assets/svg/footer-confetti.svg';

export const disclosuresContentCSS = css`
  padding-bottom: 40px;
  padding-top: 8px;

  ${mq[0]} {
    padding-bottom: 40px;
    padding-top: 20px;
  }

  ${mq[1]} {
    padding-bottom: 88px;
    padding-top: 56px;
  }
`;

export const disclosuresFooterCSS = css`
  color: ${c.warmGray};
  ${text12};
  margin-top: auto;
  text-align: center;

  ${mq[1]} {
    ${text14};
  }
`;

export const disclosuresContainerCSS = css`
  max-width: 800px;
`;

export const disclosuresFooterTextCSS = css`
  margin-bottom: 60px;

  & span {
    border-bottom: 2px solid ${c.dove};
  }
`;

export const disclosuresConfettiCSS = css`
  height: ${confettiHeight[0]}px;
  background-image: url(${FooterConfetti});
  background-position: center;
  background-repeat: repeat-x;
  background-size: cover;

  ${mq[0]} {
    height: ${confettiHeight[1]}px;
  }

  ${mq[1]} {
    height: ${confettiHeight[2]}px;
    background-size: auto, contain;
  }
`;
