import { css } from '@emotion/react';
import { mq } from 'src/theme/variables';

export const carousel = css`
  .slick-slide > div {
    margin-bottom: 32px;
    ${mq[0]} {
      margin-bottom: 44px;
    }
    &:last-child {
      margin-bottom: 0;
      display: flex;
      align-items: center;
    }
  }
`;

export const slide = css`
  text-align: center;
`;
