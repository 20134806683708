import { css, SerializedStyles } from '@emotion/react';
import { colors, mq, sizes } from 'src/theme/variables';
import { text, text14, text20, text24, text32, text40 } from 'src/theme/typography';
import { QuoteCardBasePremiumSize } from './QuoteCardBase.types';
import { card } from 'src/shared/components/Card/Card.style';

export const container = css`
  ${card};
  position: relative;
  padding: 20px ${sizes.paddingBodyHorizontalMobile}px;
  margin-left: -${sizes.paddingBodyHorizontalMobile}px;
  margin-right: -${sizes.paddingBodyHorizontalMobile}px;
  ${mq[0]} {
    margin-left: 0;
    margin-right: 0;
    padding: 32px;
  }
`;

export const summary = (direction: 'row' | 'column'): SerializedStyles => css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: ${direction};
`;

export const summaryLeft = (fullWidth: boolean): SerializedStyles => css`
  flex-grow: 1;
  width: ${fullWidth ? '100%' : 'auto'};
`;

export const summaryRight = (fullWidth: boolean): SerializedStyles => css`
  display: flex;
  flex-shrink: 1;
  width: ${fullWidth ? '100%' : 'auto'};
  align-items: center;
  justify-content: center;
`;

export const metadataWrapper = css`
  display: none;
  ${mq[0]} {
    display: flex;
    align-items: center;
    margin-left: 8px;
  }
`;

export const premium = css`
  margin-right: 24px;
  text-align: center;
  ${mq[1]} {
    margin-right: 60px;
  }
`;

export const premiumValue = (size: QuoteCardBasePremiumSize = 'default'): SerializedStyles => css`
  ${text32};
  font-weight: bold;
  ${mq[0]} {
    ${size === 'large' &&
    css`
      ${text40};
    `}
    ${size === 'default' &&
    css`
      ${text24};
    `}
    ${size === 'small' &&
    css`
      ${text20};
    `}
  }
  ${mq[1]} {
    ${size === 'small' &&
    css`
      ${text24};
    `}
    ${size === 'default' &&
    css`
      ${text32};
    `}
  }
`;

export const premiumValueSuffix = (size: QuoteCardBasePremiumSize = 'default'): SerializedStyles => css`
  ${text20};
  font-weight: bold;

  ${mq[1]} {
    ${size === 'large' &&
    css`
      ${text24};
    `}
  }
`;

export const premiumLabel = css`
  ${text14};
  color: ${colors.warmGray};

  ${mq[1]} {
    ${text};
  }
`;

export const metaItem = css`
  margin-right: 16px;
  text-align: center;

  ${mq[1]} {
    margin-right: 60px;
  }
`;

export const metaValue = css`
  ${text};
  font-weight: bold;

  ${mq[1]} {
    ${text24};
  }
`;

export const metaLabel = css`
  ${text14};
  color: ${colors.warmGray};

  ${mq[1]} {
    ${text};
  }
`;

export const actions = (fullWidth: boolean): SerializedStyles => css`
  display: flex;
  flex-direction: column;
  width: ${fullWidth ? '100%' : 'auto'};
`;

export const actionButton = css`
  line-height: 23px;
  white-space: nowrap;
  font-variant-numeric: lining-nums;
  ${mq[0]} {
    line-height: 20px;
  }

  a {
    border-bottom: none;
  }

  a:hover,
  a:visited {
    border-bottom: none;
    color: ${colors.white};
  }
`;

export const showButton = css`
  ${text};
  font-weight: bold;
  margin-top: 16px;
  margin-bottom: 8px;
  ${mq[0]} {
    margin-top: 8px;
    margin-bottom: 0;
  }
`;

export const details = css`
  padding-top: 24px;
`;

export const detailsAction = css`
  text-align: center;
  margin-top: 24px;
`;

export const detailsActionButton = css`
  ${text};
  font-weight: bold;
`;
