import { css } from '@emotion/react';
import { text, text32, text12, text20 } from 'src/theme/typography';
import { mq, colors } from 'src/theme/variables';

export const wrapperCSS = css`
  background-color: #e0edff;
  margin: 0 auto;
  box-shadow: 10px 10px 5px #aaaaaa;
  position: relative;
  break-after: page;
  height: 100vh;

  ${mq[0]} {
    width: 794px;
    height: 1123px;
  }

  @media print {
    box-shadow: none;
    width: 794px;
    height: 1123px;
  }
`;

export const headerWrapperCSS = css`
  padding: 32px 24px;
  display: flex;
  justify-content: space-between;

  img {
    @media print {
      width: 180px;
    }

    ${mq[0]} {
      width: 180px;
    }
  }
`;

export const hiCSS = css`
  font-size: 56px;
  line-height: 90px;
  font-weight: 900;

  ${mq[0]} {
    font-size: 80px;
  }

  @media print {
    font-size: 80px;
  }
`;

export const descriptionCSS = css`
  ${text20};
  font-weight: bolder;

  ${mq[0]} {
    ${text32};
  }

  @media print {
    ${text32};
  }
`;

export const textWrapperCSS = css`
  padding: 0 32px;
  margin: 50px 0;

  ${mq[0]} {
    margin: 100px 0 50px;
  }

  @media print {
    margin: 100px 0 50px;
  }
`;

export const mainImgCSS = css`
  width: 95%;
`;

export const copyCSS = css`
  padding: 0 32px;
  position: absolute;
  bottom: 20px;

  a {
    color: #156eea;
    text-decoration: none;
  }
`;

export const policyDetailsWrapperCSS = css`
  color: ${colors.dove};
  ${text12};

  div {
    padding: 2px 0;
  }

  ${mq[0]} {
    ${text};
  }

  @media print {
    ${text};
  }
`;
