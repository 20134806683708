/** @jsxImportSource @emotion/react */
import React from 'react';

import { BundleActionCardProps } from './BundleActionCard.types';
import { icon, text } from './BundleActionCard.style';
import QuoteCardBase from 'src/components/Quotes/QuoteCard/components/QuoteCardBase/QuoteCardBase';
import { summary } from 'src/components/Quotes/QuoteCard/ManualQuoteCard/ManualQuoteCard.style';
import noop from 'src/utils/noop';

const BundleActionCard: React.FC<BundleActionCardProps> = ({ customCSS, onActionClick = noop, img }) => {
  return (
    <QuoteCardBase
      customCSS={customCSS}
      testId="auto-bundle-action-card"
      actionLabel="Call a Matic Advisor"
      showDetailsButton={false}
      onActionClick={onActionClick}
    >
      <div css={summary}>
        <img css={icon} src={img} alt="Car under shelter" />
        <div css={text}>Call a Matic insurance Advisor to save a bundle with a bundled policy.</div>
      </div>
    </QuoteCardBase>
  );
};

export default BundleActionCard;
