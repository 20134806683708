import { css, SerializedStyles } from '@emotion/react';
import { colors } from 'src/theme/variables';

export const container = (color?: string): SerializedStyles => css`
  display: inline-block;
  background-color: ${color ? color : colors.dove};
  color: ${colors.white};
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  padding: 4px 12px;
  border-radius: 54px;
`;
