/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';

import debounce from 'debounce-promise';
import { useParams } from 'react-router-dom';

import { streetService } from 'src/api/street';
import AddressOption, { getOptionString } from './AddressOption';
import ValidationError from 'src/shared/components/ValidationError/ValidationError';
import { FormFieldProps } from 'src/interfaces/IQuestion';
import { DefaultLocation, Flows } from 'src/interfaces/IPage';
import { AddressSuggestion, SuggestionResponse } from 'src/interfaces/IStreets';
import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';
import Input from 'src/shared/components/Input/Input';
import { address as addressCSS, addressMenu, error, loading, menuList } from './Address.style';
import { FS_SENSITIVE_DATA_CLASS } from 'src/constants/fullStory';
import { getDefaultValueString, suggestionToAnswer } from './Address.utils';

interface Options {
  data: AddressSuggestion[];
  isLoading: boolean;
}

const Address: React.FC<FormFieldProps> = ({
  key_name,
  onValidEntry = () => null,
  value,
  placeholder,
  isDisabled,
  inputId,
  ariaLabel = 'Address',
  componentRef,
  heading,
  tracking_forbidden = false,
  onBlur: controlledBlur
}) => {
  const addressEl = useRef<HTMLInputElement>(null);
  const inputEl = useRef<HTMLInputElement>(null);
  const { flow, gid } = useParams() as DefaultLocation;

  const [isFocused, setFocused] = useState(false);
  const [selected, setSelected] = useState<string | null>(null);
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const [isInvalid, setInvalid] = useState(false);
  const [options, setOptions] = useState<Options>({ data: [], isLoading: false });

  useEffect(() => {
    if (componentRef && inputEl.current) {
      componentRef(inputEl.current);
    }
  }, [componentRef, inputEl]);

  const dataRef = useRef({ opts: options.data, defaultValue: '', focusedIndex });
  dataRef.current.opts = options.data;
  dataRef.current.defaultValue = useMemo(() => getDefaultValueString(value), [value]);
  dataRef.current.focusedIndex = focusedIndex;

  const isOpen = isFocused && (!!options.data.length || options.isLoading);
  const errorMsg = !options.data.length && flow !== Flows.Home && 'We couldn’t find this address.';

  const onInput = useMemo(
    () =>
      debounce((inputValue: string) => {
        if (inputValue) {
          document.activeElement === inputEl.current && setFocused(true);
          setOptions(options => ({ ...options, isLoading: true }));

          streetService
            .suggest(inputValue)
            .then((response: SuggestionResponse) => {
              setFocusedIndex(-1);
              setOptions({ data: response.data.suggestions || [], isLoading: false });
            })
            .catch(() => {
              setFocusedIndex(-1);
              setOptions({ data: [], isLoading: false });
            });
        }
      }, 1000),
    []
  );

  const onSelect = useCallback(
    (option: AddressSuggestion, optionString: string) => {
      if (+option.entries > 1) {
        setOptions(options => ({ ...options, isLoading: true }));
        streetService
          .suggestSecondary(inputEl.current?.value as string, optionString)
          .then((response: SuggestionResponse) =>
            setOptions({ data: response.data.suggestions || [], isLoading: false })
          )
          .catch(() => setOptions({ data: [], isLoading: false }));

        if (inputEl.current) {
          inputEl.current.value = option.street_line;
          inputEl.current.focus();
        }
      } else {
        if (inputEl.current) inputEl.current.value = optionString;
        onValidEntry(suggestionToAnswer(option));
        setFocused(false);
        setInvalid(false);
        setSelected(getDefaultValueString(suggestionToAnswer(option)));
      }
    },
    [onValidEntry]
  );
  const keyBinding = useCallback(
    (e: KeyboardEvent) => {
      switch (e.code) {
        case 'ArrowDown':
          setFocusedIndex(i => (dataRef.current.opts.length === ++i ? -1 : i));
          break;
        case 'ArrowUp':
          setFocusedIndex(i => (i === -1 ? dataRef.current.opts.length - 1 : --i));
          break;
        case 'Enter':
          const option = dataRef.current.opts[dataRef.current.focusedIndex];
          !!option && onSelect(option, getOptionString(option));
          break;
        case 'Escape':
          inputEl.current && inputEl.current.blur();
          setFocused(false);
          break;
        default:
          return;
      }
    },
    [onSelect]
  );

  const onBlur = useCallback(
    (e: any) => {
      if (!addressEl.current?.contains(e.target)) {
        const inputValue = inputEl.current?.value.trim();
        document.removeEventListener('keydown', keyBinding);
        setFocused(false);
        setInvalid(!inputValue || inputValue !== dataRef.current.defaultValue);
        setFocusedIndex(-1);
      }
    },
    [keyBinding]
  );
  const onFocus = () => {
    setFocused(true);
    document.addEventListener('click', onBlur);
    document.addEventListener('keydown', keyBinding);
  };

  const onInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.target.value && options.data.length === 0 && !dataRef.current.defaultValue) {
      analytics.track(SEGMENT.AUTOCOMPLETE_FAILED, gid, flow, {
        question_key: inputId,
        value: e.target.value
      });
    }
    controlledBlur?.();
  };

  useEffect(() => {
    if (!isFocused) {
      document.removeEventListener('click', onBlur);
      document.removeEventListener('keydown', keyBinding);
    }
  }, [isFocused, onBlur, keyBinding]);

  return (
    <div css={addressCSS} ref={addressEl} data-testid="address">
      <Input
        className={tracking_forbidden ? FS_SENSITIVE_DATA_CLASS.MASK : ''}
        autoComplete="off"
        hasError={!isOpen && isInvalid}
        name={key_name}
        id={inputId}
        aria-label={ariaLabel}
        onInput={e => {
          onValidEntry((e.target as HTMLInputElement).value);
          onInput((e.target as HTMLInputElement).value);
        }}
        type="search"
        onFocus={onFocus}
        placeholder={placeholder}
        ref={inputEl}
        defaultValue={dataRef.current.defaultValue}
        data-testid="address-input"
        disabled={isDisabled}
        onBlur={onInputBlur}
      />
      <div css={addressMenu(isOpen)} data-testid="address-menu">
        <div css={menuList} role="listbox" aria-label="Address menu list">
          {options.data.map((option, i) => (
            <AddressOption
              key={JSON.stringify(option)}
              inputValue={inputEl.current?.value}
              option={option}
              index={i}
              isFocused={focusedIndex === i}
              onSelect={onSelect}
              onHover={i => setFocusedIndex(i)}
              isSelected={selected === getDefaultValueString(suggestionToAnswer(option))}
            />
          ))}
          {options.isLoading && <div css={loading}>Loading...</div>}
        </div>
      </div>
      {errorMsg && (
        <div css={error(isInvalid)} data-testid="address-error">
          <ValidationError heading={heading} visible={isInvalid}>
            {errorMsg}
          </ValidationError>
        </div>
      )}
    </div>
  );
};

export default Address;
