/** @jsxImportSource @emotion/react */
import React, { useRef } from 'react';

import ReactSelect from 'react-select';

import { selectCSS } from './Select.style';
import { OnChangeValue } from 'react-select/dist/declarations/src/types';
import ReactSelectType from 'react-select/dist/declarations/src/Select';
import noop from 'src/utils/noop';
import { SelectOption, SelectProps } from './Select.types';
import { MenuList, Option, DropdownIndicator, Control } from './SelectParts.utils';
import { DEFAULT_SELECT_OPTIONS } from './Select.constants';
import { useParams } from 'react-router-dom';
import { DefaultLocation, Flows } from 'src/interfaces/IPage';

const Select: React.FC<SelectProps> = ({
  customCSS,
  inputId,
  ariaLabel,
  options,
  onValidEntry = noop,
  placeholder,
  components = {},
  menuShouldBlockScroll = false,
  componentRef,
  hasError,
  onBlur,
  value,
  styles,
  isSearchable = false,
  isDisabled = false,
  openMenuOnFocus = false
}) => {
  const { flow } = useParams() as DefaultLocation;
  const isLifeFlowVariant = flow === Flows.Life;
  const shouldHighlightError = !!hasError;

  if (!options) {
    throw new Error('Select Component: options must be provided.');
  }

  const selectRef = useRef<ReactSelectType<unknown>>(null);
  if (componentRef && selectRef.current) {
    componentRef(selectRef.current.inputRef);
  }

  const selectValue = options!.find(option => option.value === value) || null;
  const onChange = (option: OnChangeValue<unknown, false>) => {
    onValidEntry((option as OnChangeValue<SelectOption, false>)?.value || null);
  };

  return (
    <ReactSelect
      css={[
        selectCSS(hasError, isLifeFlowVariant, inputId === 'person_health_status', shouldHighlightError),
        customCSS
      ]}
      {...DEFAULT_SELECT_OPTIONS}
      inputId={inputId}
      aria-label={ariaLabel}
      options={options}
      placeholder={placeholder}
      isSearchable={isSearchable}
      isDisabled={isDisabled}
      value={selectValue}
      onChange={onChange}
      onBlur={onBlur}
      menuShouldBlockScroll={menuShouldBlockScroll}
      styles={styles}
      openMenuOnFocus={openMenuOnFocus}
      components={{
        MenuList,
        Option,
        DropdownIndicator,
        Control,
        ...components
      }}
      ref={selectRef}
    />
  );
};

export default Select;
