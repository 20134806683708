import { css, SerializedStyles } from '@emotion/react';

const expandedStyles = css`
  opacity: 1;
  transition:
    height 0.3s ease-in-out,
    opacity 0.3s ease-in-out 0.3s;
`;

const accordionModeStyles = css`
  transition:
    height 0.3s ease-in-out 0.3s,
    opacity 0.3s ease-in-out;
`;

const accordionModeExpanded = css`
  transition:
    height 0.3s ease-in-out 0.3s,
    opacity 0.3s ease-in-out 0.6s;
`;

export const container = (
  height: string | number,
  isExpanded: boolean,
  accordionMode: boolean
): SerializedStyles => css`
  height: ${height};
  opacity: 0;
  transition:
    height 0.3s ease-in-out 0.3s,
    opacity 0.3s ease-in-out;
  will-change: height, opacity;
  visibility: ${isExpanded ? 'visible' : 'hidden'};
  ${isExpanded && expandedStyles}
  ${accordionMode && accordionModeStyles}
  ${isExpanded && accordionMode && accordionModeExpanded}
`;
