/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import useConfig from 'src/api/config/useConfig';
import FullPageLoader from 'src/shared/components/FullPageLoader/FullPageLoader';
import Container from 'src/kit/Container/Container';
import {
  componentsBackgroundCSS,
  componentsPaddingCSS,
  faqCSS,
  googleReviewsCSS,
  heroCSS,
  profileHeadingCss,
  userProfileCSS
} from './LookupLanding.style';
import HowItWorksSteps from 'src/components/HowItWorksSteps/HowItWorksSteps';
import { getAllFunnelLookupSteps } from 'src/components/HowItWorksSteps/HowItWorksSteps.utils';
import useResponsive from 'src/hooks/useResponsive';
import SERVICERS, { getServicerNameToDisplay } from 'src/constants/servicers';
import CarriersList from 'src/components/CarriersList/CarriersList';
import {
  getAccordionItems,
  getCarriersListHeader,
  getCarriersListSubheader
} from 'src/components/Savings/Savings.utils';
import GoogleReviews from 'src/components/Landing/GoogleReviews/GoogleReviews';
import WhyTrustMatic from 'src/components/WhyTrustMatic/WhyTrustMatic';
import FAQ from 'src/components/FAQ/FAQ';
import SEGMENT from 'src/constants/segment';
import analytics from 'src/utils/analytics';
import LookupZipForm from 'src/components/LookupZipForm/LookupZipForm';
import { lookupService } from 'src/api/lookup';
import queryString from 'query-string';
import { useCustomNavigate } from 'src/hooks/useCustomNavigate';
import { useLocation, useParams } from 'react-router-dom';
import { LookupErrorKeys, LookupFields } from './LookupLanding.types';
import { LookupResponse, LookupStatusKeys } from 'src/interfaces/ILookup';
import { DefaultLocation, Flows } from 'src/interfaces/IPage';
import LookupDialog from './LookupDialog/LookupDialog';
import { ZIP_ERROR_KEY } from './LookupLanding.consts';
import { concatLookupData } from './LookupLanding.utils';

import { Disclosure } from 'src/interfaces/disclosures.types';
import useDisclosures from 'src/api/disclosures/useDisclosures';
import { getAge } from 'src/utils/date';

const LookupLanding: React.FC = () => {
  const navigate = useCustomNavigate();
  const { search } = useLocation();
  const { flow } = useParams() as DefaultLocation;

  const params = queryString.parse(search?.replace('?', ''));
  const loanNumber = params?.key as string;
  const testMode = params?.test_mode === 'true';
  const utmMedium = (params?.utm_medium as string) || '';

  const { isMobile } = useResponsive();

  const { data: config, isLoading: isConfigLoading } = useConfig();
  const { data: disclosuresData, isLoading: isDisclosuresLoading } = useDisclosures();

  const [isLoading, setIsLoading] = useState(false);
  const [requestError, setRequestError] = useState<LookupErrorKeys>(LookupErrorKeys.NULL);
  const [showDialog, setShowDialog] = useState(false);

  const isPhhNewRezServicer = config?.servicer_key === SERVICERS.PHH_NRZ;
  const isNewRezServicer = config?.servicer_key === SERVICERS.NEW_REZ_NRZ;
  const servicerName = getServicerNameToDisplay(config?.servicer_key, config?.servicer_name);

  useEffect(() => {
    const checkLoanNumber = async () => {
      try {
        const data = await lookupService.checkLoanNumberStatus(loanNumber);
        if (data.status === LookupStatusKeys.CUSTOMER_NOT_FOUND) {
          setRequestError(LookupErrorKeys.INVALID_LOAN);
          setShowDialog(true);
          analytics.track(SEGMENT.LOAN_NUMBER_LOCKED_POPUP, undefined, flow);
        }
      } catch {
      } finally {
        setIsLoading(false);
      }
    };

    checkLoanNumber();
  }, [flow, loanNumber]);

  useEffect(() => {
    if (config?.servicer_key) {
      if (utmMedium === LookupFields.UTM_MEDIUM_DIRECT_MAIL_FIELD) {
        analytics.page(SEGMENT.PAGES.LOOKUP_FROM_DIRECT_MAIL, {
          flow_type: flow,
          partner_key: config.servicer_key
        });
      } else {
        analytics.page(SEGMENT.PAGES.LOOKUP, {
          flow_type: flow,
          partner_key: config.servicer_key
        });
      }
    }
  }, [config, flow, utmMedium]);

  const onSubmit = async (zipCode: string) => {
    setIsLoading(true);
    try {
      const customerData = await lookupService.findCustomer(loanNumber, zipCode, testMode);
      const isError = customerData.status !== LookupStatusKeys.SUCCESS;
      const lookupData = concatLookupData(loanNumber, zipCode);

      if (isError) {
        onZipError(customerData, lookupData);
      } else {
        onZipSuccess(customerData, lookupData);
      }
    } catch {
    } finally {
      setIsLoading(false);
    }
  };

  const onZipError = (data: LookupResponse, lookupData: string) => {
    const error = ZIP_ERROR_KEY[data.status];
    switch (error) {
      case LookupErrorKeys.SESSION_LOCKED:
        analytics.track(SEGMENT.LOAN_NUMBER_LOCKED_POPUP, undefined, flow);
        break;
      case LookupErrorKeys.INVALID_ZIP:
        analytics.track(SEGMENT.CUSTOMER_IDENTIFIED, undefined, flow, {
          resolution: SEGMENT.SUBMIT_RESOLUTION.NOT_FOUND,
          enteredData: lookupData,
          identifier: LookupFields.ZIP_CODE_FIELD,
          customer_lookup_by_zip: true
        });
        break;
    }

    setRequestError(error);
    setShowDialog(true);
  };

  const onZipSuccess = (data: LookupResponse, lookupData: string) => {
    const session_gid = data.session_gid as string;
    analytics.track(SEGMENT.CUSTOMER_IDENTIFIED, session_gid, flow, {
      resolution: SEGMENT.SUBMIT_RESOLUTION.SUCCESS,
      enteredData: lookupData,
      identifier: LookupFields.ZIP_CODE_FIELD,
      customer_lookup_by_zip: true
    });

    analytics.identify(data.person_gid, {
      email: analytics.generateTrackEmail(data.person_gid as string),
      age: data.date_of_birth ? getAge(data.date_of_birth as string) : undefined
    });

    analytics.track(SEGMENT.FLOW_STARTED, session_gid, flow);

    disclosuresData?.disclosures?.forEach((disclosure: Disclosure) => {
      analytics.track(SEGMENT.DISCLOSURE_ACCEPTED, session_gid, flow, {
        disclosure_gid: disclosure.gid,
        page: SEGMENT.PAGES_KEY.LOOKUP
      });
    });

    navigate({ pathname: `/${Flows.Lookup}/${data.session_gid}/quotes`, search });
  };

  const onCloseModal = (isRedirect: boolean) => {
    if (isRedirect) {
      onRedirectToDSHome();
    }
    setShowDialog(false);
  };

  const onGoogleReviewsClick = () =>
    analytics.track(SEGMENT.GOOGLE_REVIEWS_CLICKED, undefined, undefined, {
      location: SEGMENT.PAGES_KEY.LOOKUP
    });

  const onRedirectToDSHome = () => {
    analytics.track(SEGMENT.REDIRECT_FROM_LOOKUP_TO_LANDING, undefined, flow);
    navigate('/home', { replace: true });
  };

  if (isConfigLoading || !config) {
    return <FullPageLoader />;
  }

  return (
    <div>
      <div css={heroCSS}>
        <LookupZipForm
          isLoading={isLoading || isDisclosuresLoading}
          hasError={requestError === LookupErrorKeys.INVALID_ZIP}
          onFormSubmit={onSubmit}
          onManuallyButtonClick={onRedirectToDSHome}
        />
      </div>
      <Container customCSS={componentsPaddingCSS}>
        <h2 css={profileHeadingCss}>It’s easy as 1, 2, 3</h2>
        <div css={userProfileCSS}>
          <HowItWorksSteps
            heading={isMobile ? 'It’s easy as 1, 2, 3' : ''}
            steps={getAllFunnelLookupSteps(isNewRezServicer)}
          />
        </div>
      </Container>

      <div css={[componentsBackgroundCSS, componentsPaddingCSS]}>
        <Container>
          <CarriersList
            header={getCarriersListHeader(config.servicer_key, servicerName)}
            subheader={getCarriersListSubheader(isPhhNewRezServicer, servicerName)}
          />
        </Container>
      </div>

      <Container customCSS={componentsPaddingCSS}>
        <GoogleReviews
          customCSS={{ wrapper: googleReviewsCSS }}
          heading="Why homeowners trust Matic"
          onGoogleReviewsClick={onGoogleReviewsClick}
        />
      </Container>

      <div css={[componentsBackgroundCSS, componentsPaddingCSS]}>
        <Container>
          <WhyTrustMatic savings={null} isNewRez={isNewRezServicer} />
        </Container>
      </div>

      <Container customCSS={componentsPaddingCSS}>
        <FAQ customCSS={faqCSS} page={SEGMENT.PAGES.LOOKUP} accordionItems={getAccordionItems(servicerName)} />
      </Container>
      <LookupDialog isOpen={showDialog} error={requestError} closeModal={onCloseModal} />
    </div>
  );
};

export default LookupLanding;
