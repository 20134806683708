import { css } from '@emotion/react';
import { actionColors, colors } from 'src/theme/variables';

export const containerCSS = css`
  width: 40px;
  height: 40px;
  border: 2px solid ${colors.darkBlue};
  border-radius: 50%;
  box-sizing: border-box;
  background-color: ${colors.white};
  display: flex;
  align-items: center;
  flex-shrink: 0;
  padding: 0;
  transition:
    border-color 0.2s ease,
    background-color 0.2s ease,
    color 0.2s ease;
  cursor: pointer;

  :hover,
  :focus {
    background-color: ${actionColors.hover};
    border-color: ${colors.darkBlue};
    svg {
      path {
        fill: ${colors.white};
      }
    }
  }
`;

export const containerSingleColor = css`
  border: 4px solid ${colors.black};
  background-color: ${colors.black};

  :hover,
  :focus {
    background-color: ${actionColors.primary};
    border-color: ${actionColors.primary};
  }
`;

export const image = css`
  width: 100%;
  svg {
    display: block;
    height: auto;
    margin: auto;
    max-height: 20px;
    max-width: 20px;
    path {
      fill: ${actionColors.primary};
    }
  }
`;

export const imageSingleColor = css`
  height: 16px;
  svg {
    path {
      fill: ${colors.grayFive};
    }
  }
`;
