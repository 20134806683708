import axios from 'axios';

enum HTTPMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  HEAD = 'HEAD',
  DELETE = 'DELETE'
}

interface ApiCallParams {
  headers?: any;
  body?: any;
  params?: any;
}

type ApiCall = (url: string, params?: ApiCallParams, withRedirect?: boolean) => Promise<any>;

interface API {
  get: ApiCall;
  post: ApiCall;
  put: ApiCall;
  patch: ApiCall;
  head: ApiCall;
  delete: ApiCall;
}

const call = (
  method: HTTPMethod,
  url: string,
  { headers = {}, body = {}, params = {} }: ApiCallParams = {},
  withRedirect: boolean
) =>
  axios({
    method,
    url,
    headers: {
      ...headers,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(body),
    params
  })
    .then(res => res.data)
    .catch(error => {
      if (!error.response) {
        window.location.reload();
      } else {
        if (error.response.status >= 400 && withRedirect) {
          const problemUrl = window.location.pathname.replace(/\/.*/, 'problem');
          window.location.replace(problemUrl);
        }
        throw error;
      }
    });

const api: API = {
  get(url, params = {}, withRedirect = true) {
    return call(HTTPMethod.GET, url, params, withRedirect);
  },
  post(url, params = {}, withRedirect = true) {
    return call(HTTPMethod.POST, url, params, withRedirect);
  },
  put(url, params = {}, withRedirect = true) {
    return call(HTTPMethod.PUT, url, params, withRedirect);
  },
  patch(url, params = {}, withRedirect = true) {
    return call(HTTPMethod.PATCH, url, params, withRedirect);
  },
  head(url, params = {}, withRedirect = true) {
    return call(HTTPMethod.HEAD, url, params, withRedirect);
  },
  delete(url, params = {}, withRedirect = true) {
    return call(HTTPMethod.DELETE, url, params, withRedirect);
  }
};

export default api;
