/** @jsxImportSource @emotion/react */
import { FC, useState } from 'react';
import Button from 'src/kit/Button/Button';
import HomeIcon from 'src/assets/svg/bndl-house.svg';
import {
  buttonCSS,
  containerCSS,
  headingCSS,
  homeCardCSS,
  homeIconCSS,
  quotesContainerCSS,
  subheadingCSS,
  textContentCSS
} from './BundleNotInterested.style';
import { BundleNotInterestedProps } from './BundleNotInterested.types';
import Container from 'src/kit/Container/Container';
import HomeCard from 'src/components/Quotes/QuoteCard/HomeCard/HomeCard';
import { HomeQuote } from 'src/interfaces/IQuotes';
import analytics from 'src/utils/analytics';
import { useParams } from 'react-router-dom';
import { DefaultLocation } from 'src/interfaces/IPage';
import SEGMENT from 'src/constants/segment';
import QuoteDetailsDialog from 'src/components/Quotes/QuoteDetailsDialog/QuoteDetailsDialog';
import QuoteCoverages from 'src/components/Quotes/QuoteCoverages/QuoteCoverages';
import { getHomeQuotesCoveragesGroups, getPremiumLabelByType } from 'src/utils/quotesPage';
import Format from 'src/utils/format';
import useResponsive from 'src/hooks/useResponsive';

const BundleNotInterested: FC<BundleNotInterestedProps> = ({ onQuoteSelect, homeQuotes }) => {
  const { gid, flow } = useParams() as DefaultLocation;

  const [isOpenHomeQuotes, setIsOpenHomeQuotes] = useState(false);
  const [selectedQuote, setSelectedQuote] = useState<HomeQuote | null>(null);

  const { isMobile } = useResponsive();

  const onShowHomeQuotes = () => {
    analytics.track(SEGMENT.BUNDLE_ONLY.SEE_HOME_QUOTES_CLICKED, gid, flow);
    setIsOpenHomeQuotes(true);
  };

  const handleSelectQuote = (quote: HomeQuote) => {
    analytics.track(SEGMENT.BUNDLE_ONLY.HOME_QUOTE_SELECTED, gid, flow);
    onQuoteSelect(quote);
  };

  const onShowDetailsClicked = (quote: HomeQuote, index: number) => {
    analytics.track(SEGMENT.QUOTE_REVIEWED, gid, flow, {
      carrier_key: quote.carrier.key,
      ordinal: index
    });
    setSelectedQuote(quote);
  };

  return (
    <>
      {isOpenHomeQuotes ? (
        <Container>
          <div css={quotesContainerCSS}>
            {homeQuotes.map((quote, i) => (
              <HomeCard
                customCSS={homeCardCSS}
                key={quote.gid}
                index={i}
                quote={quote}
                onShowDetailsClicked={() => onShowDetailsClicked(quote, i)}
                onQuoteSelect={() => handleSelectQuote(quote)}
              />
            ))}
          </div>
          {!!selectedQuote && isMobile && (
            <QuoteDetailsDialog
              isOpen={!!selectedQuote}
              shouldHideMblChoose
              onClose={() => {
                setSelectedQuote(null);
              }}
              onActionClick={() => setSelectedQuote(null)}
              logo={{
                url: selectedQuote.carrier.logo_url,
                label: selectedQuote.carrier.name
              }}
              price={{
                value: Format.money(selectedQuote.premium.value, 0, '$'),
                label: getPremiumLabelByType(selectedQuote.premium.type)
              }}
              contentComponent={
                <QuoteCoverages groups={getHomeQuotesCoveragesGroups(selectedQuote.coverages)} expandable />
              }
            />
          )}
        </Container>
      ) : (
        <div css={containerCSS}>
          <div css={textContentCSS}>
            <img css={homeIconCSS} src={HomeIcon} width={80} alt="Home" />
            <div>
              <div css={headingCSS}>Not interested in a bundle?</div>
              <p css={subheadingCSS}>This offer is bundle only, but we found other home-only quotes.</p>
            </div>
          </div>
          <Button css={buttonCSS} variant="secondary" onClick={onShowHomeQuotes}>
            See Home-Only Quotes
          </Button>
        </div>
      )}
    </>
  );
};

export default BundleNotInterested;
