import { css, SerializedStyles } from '@emotion/react';
import { colors, mq, sizes } from 'src/theme/variables';
import { text20, text32, text40, text48 } from 'src/theme/typography';

export const containerCSS = (isCallMeNowVariation: boolean): SerializedStyles => css`
  background-color: ${colors.azure};
  color: ${colors.white};
  overflow: hidden;

  ${mq[1]} {
    border-radius: 16px;
  }

  ${isCallMeNowVariation &&
  css`
    background-color: ${colors.azure62};
  `}
`;

export const containerInnerCSS = css`
  position: relative;
  max-width: 1086px;
  padding: 0 ${sizes.paddingBodyHorizontalMobileDense}px;
  ${mq[0]} {
    padding: 0 ${sizes.paddingBodyHorizontalTablet}px;
  }
  ${mq[1]} {
    margin: 0 auto;
  }
`;

export const arrowCSS = css`
  position: absolute;
  bottom: -37px;
  left: calc(45% - 100px);

  ${mq[0]} {
    left: ${sizes.paddingBodyHorizontalTablet + 8}px;
    bottom: -19px;
  }
  ${mq[1]} {
    left: ${sizes.paddingBodyHorizontalTablet + 16}px;
    bottom: -55px;
  }
`;

export const contentHeroCSS = css`
  padding-top: 70px;
  padding-bottom: 56px;
  box-sizing: border-box;
  position: relative;
  text-align: center;

  ${mq[0]} {
    display: flex;
    align-items: center;
    min-height: 400px;
    padding-top: 34px;
    padding-bottom: 100px;
    text-align: left;
  }

  ${mq[1]} {
    margin: 0 auto;
    padding-top: 40px;
    padding-bottom: 60px;
  }
`;

export const headingHeroCSS = css`
  ${text32};
  font-weight: 700;
  margin-bottom: 12px;
  ${mq[0]} {
    ${text40};
    margin-bottom: 20px;
  }
  ${mq[1]} {
    ${text48};
    line-height: 52px;
    font-weight: 900;
  }
`;

export const descriptionTextHeroCSS = css`
  ${mq[1]} {
    ${text20};
    font-weight: 500;
  }
`;

export const landlordButtonCSS = css`
  color: ${colors.white};
  border-bottom: 2px solid ${colors.white};
`;

export const infoCenteringCSS = css`
  margin: auto 0;
`;
