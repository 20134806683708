import { css, SerializedStyles } from '@emotion/react';
import { text, text20, text14 } from 'src/theme/typography';
import { colors, mq } from 'src/theme/variables';

export const rowWrapperCSS = css`
  display: flex;
  border-radius: 8px;
  border: 1px solid #b3b3b3;
  flex-direction: column;
  padding: 16px;
  width: 90%;
  margin-bottom: 24px;
  box-shadow: 2px 4px 0px 0px rgba(0, 0, 0, 0.12);
  align-items: center;

  ${mq[0]} {
    padding: 24px;
    flex-direction: row;
    align-items: flex-start;
  }

  svg {
    width: 120px;
    height: 140px;
    margin-right: 24px;
  }
`;

export const columnWrapperCSS = css`
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    width: 100%;
    margin: 12px 0;

    a {
      border-bottom: 0;
      font-weight: 700;
    }

    img {
      margin-right: 6px;
    }
  }
`;

export const contentWrapperCSS = css`
  ${text14};
  text-align: center;
  width: 100%;

  ${mq[0]} {
    width: 70%;
    text-align: left;
  }

  h4 {
    font-weight: normal;
    ${text};
    margin: 0 0 12px;

    ${mq[0]} {
      ${text20};
    }
  }
`;

export const anchorCSS = (isWhite?: boolean): SerializedStyles => css`
  ${text};
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 10px 20px;
  border-radius: 4px;
  font-weight; 500;
  border: 2px solid ${colors.azure};
  background-color: ${colors.white};
  border-color: ${colors.azure};
  color: ${colors.azure};
  margin-top: 12px;
  font-weight: 700;
 


  &:focus {
    color: ${colors.white} !important;
  }

  &:visited {
    color: ${colors.azure};
  }

  img {
    margin-right: 6px;
  }

  a {
    text-decoration: none;
    font-weight: 700;

   
  }

  ${mq[0]} {
    color: ${colors.white};
    background-color: ${colors.azure};
    margin-top: 20px;

    ${
      isWhite &&
      css`
        background-color: ${colors.white};
        text-decoration: none;
        color: ${colors.azure};
        margin-top: 0;

        &:visited,
        &:focus {
          color: ${colors.azure} !important;
          border-bottom-color: ${colors.azure};
        }
      `
    }
  }

 

`;
