import { css, SerializedStyles } from '@emotion/react';
import { colors, mq } from 'src/theme/variables';
import { text, text20 } from 'src/theme/typography';

export const tabWrapperCSS = css`
  display: flex;
  text-align: center;
  margin: -20px -20px 32px;

  ${mq[0]} {
    width: 100%;
    width: 650px;
    margin: -50px auto 32px;
  }
`;

export const switchButtonCSS = (isActive: boolean): SerializedStyles => css`
  ${text};
  width: 50%;
  padding: 12px;
  font-weight: 700;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.white};
  border: 1px solid ${colors.nobel};

  svg {
    transform: scale(0.6);
  }

  path {
    width: 18px;
    height: auto;
  }

  ${mq[0]} {
    ${text20};
    padding: 16px;

    svg {
      margin: 0 6px;
      transform: scale(0.8);
    }
  }

  ${isActive &&
  css`
    color: ${colors.azure};
    border-bottom: 3px solid ${colors.azure};

    path,
    circle {
      stroke: ${colors.azure};
    }
  `}
`;
