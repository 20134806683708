/** @jsxImportSource @emotion/react */

import React, { Fragment, useState } from 'react';
import Dialog from 'src/kit/Dialog/Dialog';
import CloseIcon from 'src/assets/svg/close.svg?react';
import {
  ClaimsRadioButtons,
  ExtendedClaimsRadioButtons
} from 'src/components/EditInfoSideModal/components/RadioButtons';
import { propertyDataStore, PropertyType, propertyQuestionCheckboxes } from './EditInfoScheme';
import { useParams } from 'react-router-dom';
import { CheckBoxes } from 'src/components/EditInfoSideModal/components/CheckBoxes';

import useMutatePropertyAnswers from 'src/api/allFunnel/useMutatePropertyAnswers';
import { DefaultLocation } from 'src/interfaces/IPage';

import {
  contentCSS,
  containerCSS,
  overlayCSS,
  headerCSS,
  closeCSS,
  buttonCSS,
  questionHeadingCSS
} from './EditInfoSideModal.style';
import Button from 'src/kit/Button/Button';
import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';
import { EditInfoSideModalProps, ValidationErrorsType } from './EditInfoSideModal.types';

const EditInfoSideModal: React.FC<EditInfoSideModalProps> = ({
  isOpen,
  onModalClosed,
  onPersonalizedQuoteInfoUpdate
}) => {
  const [dataStore, setDataStore] = useState(propertyDataStore);
  const { gid, flow } = useParams() as DefaultLocation;
  const { mutateAsync: updateDataProperty } = useMutatePropertyAnswers(gid);
  const [validationErrors, setValidationErrors] = useState<ValidationErrorsType>({
    isClaimMissed: false,
    isDogInfoMissed: false
  });

  const onPropertyUpdate = (property: string, value: string) => {
    setDataStore({ ...dataStore, [property]: value });
    if (property === PropertyType.OpenClaims) {
      setValidationErrors({ ...validationErrors, isClaimMissed: false });
    }

    if (property === PropertyType.HasDog) {
      setValidationErrors({ ...validationErrors, isDogInfoMissed: false });
    }
  };

  const onSubmit = async () => {
    const isClaimMissed = dataStore.property_has_open_claims === null;
    const isDogInfoMissed = dataStore.person_has_dog === null;
    analytics.track(SEGMENT.PERSONALIZED_QUOTE_SHOW_QUOTES_CLICKED, gid, flow);

    setValidationErrors({
      isClaimMissed,
      isDogInfoMissed
    });

    if (!isClaimMissed && !isDogInfoMissed) {
      await updateDataProperty(dataStore);
      onPersonalizedQuoteInfoUpdate();
    }
  };

  return (
    <Dialog
      isOpen={isOpen}
      size="small"
      shouldCloseOnOverlayClick
      shouldHideCloseIcon
      transitionDuration={600}
      onRequestClose={onModalClosed}
      customCSS={{
        body: containerCSS,
        dialogInline: { content: contentCSS(), overlay: overlayCSS }
      }}
    >
      <div css={headerCSS}>
        <CloseIcon css={closeCSS} onClick={onModalClosed} />
      </div>

      <ClaimsRadioButtons onPropertyUpdate={onPropertyUpdate} isValidationError={validationErrors.isClaimMissed} />

      <Fragment>
        <div css={questionHeadingCSS}>Do any of these apply to your home?</div>
        {propertyQuestionCheckboxes.map((item, i) => (
          <CheckBoxes onPropertyUpdate={onPropertyUpdate} key={i} item={item} />
        ))}
      </Fragment>

      <ExtendedClaimsRadioButtons
        onPropertyUpdate={onPropertyUpdate}
        isValidationError={validationErrors.isDogInfoMissed}
      />

      <Button css={buttonCSS} onClick={onSubmit} fullWidth>
        Show Quotes
      </Button>
    </Dialog>
  );
};

export default EditInfoSideModal;
