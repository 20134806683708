/** @jsxImportSource @emotion/react */
import React, { Fragment } from 'react';

import QuoteCardBase from 'src/components/Quotes/QuoteCard/components/QuoteCardBase/QuoteCardBase';
import useResponsive from 'src/hooks/useResponsive';
import BundleQuoteCardDetails from 'src/components/Quotes/QuoteCard/components/BundleQuoteCardDetails/BundleQuoteCardDetails';
import { suffixCSS } from 'src/components/Quotes/QuoteCard/QuoteCard.style';
import { getPremiumLabelByType } from 'src/utils/quotesPage';
import Format from 'src/utils/format';
import { cardContent, tags as tagsCustom, typeDetailsContainer } from './BundleQuoteCard.style';
import BundleQuoteTypeDetails from 'src/components/Quotes/QuoteCard/components/BundleQuoteTypeDetails/BundleQuoteTypeDetails';
import { divider } from 'src/components/Quotes/QuoteCard/components/BundleQuoteTypeDetails/BundleQuoteTypeDetails.style';
import QuoteTags from 'src/components/Quotes/QuoteTags/QuoteTags';
import { BundleQuoteCardProps } from './BundleQuoteCard.types';
import QuoteLogo from 'src/components/Quotes/QuoteCard/components/QuoteLogo/QuoteLogo';
import { QuoteCardBasePremiumSize } from 'src/components/Quotes/QuoteCard/components/QuoteCardBase/QuoteCardBase.types';
import { AUTOMATION_TEST_IDS as TI } from 'src/constants/automationTestIds';

const BundleQuoteCard: React.FC<BundleQuoteCardProps> = ({
  customCSS,
  tags,
  quote,
  isSingleType,
  onQuoteSelect,
  onShowDetailsClicked,
  singleTypeCarrierName,
  singleTypeLogo,
  singleTypePremium,
  bundleType
}) => {
  const { isMobile } = useResponsive();

  const getPremium = () => {
    if (isMobile) {
      return;
    }
    if (isSingleType) {
      return {
        value: Format.money(singleTypePremium?.value, 0, '$'),
        label: getPremiumLabelByType(singleTypePremium?.type)
      };
    }
    return {
      value: `${Format.money(quote.premium.value, 0, '$')}`,
      valueSuffix: `/${getPremiumLabelByType(quote.premium.type, true)}`,
      label: 'Estimated Premium',
      valueSize: 'large' as QuoteCardBasePremiumSize
    };
  };

  const getActionLabel = () => {
    if (isMobile) {
      const premium = isSingleType ? singleTypePremium : quote.premium;
      return (
        <Fragment>
          {Format.money(premium.value, 0, '$')}
          <span css={suffixCSS}>/{getPremiumLabelByType(premium.type, true)}</span>
        </Fragment>
      );
    }

    return 'Choose Quote';
  };

  const getCardDirection = (): 'column' | 'row' => {
    if (isSingleType) {
      return 'row';
    }
    return isMobile ? 'column' : 'row';
  };

  const renderTypeDetails = () => {
    return (
      <div css={typeDetailsContainer}>
        <BundleQuoteTypeDetails
          type="home"
          logo={{
            url: quote.quotes.home.carrier.logo_url,
            label: quote.quotes.home.carrier.name
          }}
          premium={{
            value: Format.money(quote.quotes.home.premium.value, 0, '$'),
            label: getPremiumLabelByType(quote.quotes.home.premium.type)
          }}
          testId={TI.AUTO_BUNDLE_QUOTE_ANNUAL_PREMIUM_VALUE}
        />
        <div css={divider} />
        <BundleQuoteTypeDetails
          type="auto"
          logo={{
            url: quote.quotes.auto.carrier.logo_url,
            label: quote.quotes.auto.carrier.name
          }}
          premium={{
            value: Format.money(quote.quotes.auto.premium.value, 0, '$'),
            label: getPremiumLabelByType(quote.quotes.auto.premium.type)
          }}
          testId={TI.AUTO_BUNDLE_QUOTE_MONTHS_PREMIUM_VALUE}
        />
      </div>
    );
  };

  return (
    <QuoteCardBase
      customCSS={customCSS}
      premium={getPremium()}
      actionLabel={getActionLabel()}
      detailsComponent={<BundleQuoteCardDetails isSingleType={!!isSingleType} bundleType={bundleType} quote={quote} />}
      showDetailsButton={!isMobile}
      cardDirection={getCardDirection()}
      fullWidthActions={getCardDirection() === 'column'}
      onActionClick={onQuoteSelect}
      onShowDetails={onShowDetailsClicked}
      buyOnlineQuote={!!quote.quotes.home?.online_bind_url && !!isSingleType}
    >
      <div css={cardContent(!!isSingleType)}>
        {isSingleType ? <QuoteLogo logoUrl={singleTypeLogo} logoLabel={singleTypeCarrierName} /> : renderTypeDetails()}
        <QuoteTags customCSS={tagsCustom(!!isSingleType)} tags={tags} />
      </div>
    </QuoteCardBase>
  );
};

export default BundleQuoteCard;
