/** @jsxImportSource @emotion/react */
import React from 'react';

import { useParams } from 'react-router-dom';

import { DefaultLocation } from 'src/interfaces/IPage';
import Benefits from 'src/shared/components/Benefits/Benefits';
import stringTemplater from 'src/utils/stringTemplater';

import { greeting, greetingHeading, paragraph as paragraphCSS, greetingIndicator, vehicleIcon } from './Greeting.style';
import { GreetingProps } from './Greeting.type';
import getValueByKey from 'src/utils/getValueByKey';
import { getVehicleIconUrl } from './vehicleIcon.util';
import { AUTO_FORM_PAGE_KEYS } from 'src/constants/hardcoded';
import { FS_SENSITIVE_DATA_CLASS } from 'src/constants/fullStory';
import { extendGreetingProps } from './Greetings.util';

const Greeting: React.FC<GreetingProps> = props => {
  const { customCSS, textOptions = {}, keyName, heading_icon_path, fixed_on_scroll, tracking_forbidden } = props;
  const { flow } = useParams() as DefaultLocation;

  const { heading, subheading, paragraph, softLanding, benefits } = extendGreetingProps(props, flow);

  const normalizedHeading = heading ? stringTemplater(heading, textOptions) : '';
  const normalizedSubheading = subheading ? stringTemplater(subheading, textOptions) : '';
  const normalizedParagraph = paragraph ? stringTemplater(paragraph, textOptions) : '';
  const sensitiveDataClass = tracking_forbidden ? FS_SENSITIVE_DATA_CLASS.MASK : '';

  const getHeadingIconValue = (): string | null => {
    if (!heading_icon_path) {
      return null;
    }
    switch (keyName) {
      case AUTO_FORM_PAGE_KEYS.VEHICLES:
        return getValueByKey(heading_icon_path, textOptions, 3);
      default:
        return null;
    }
  };

  const headingIconValue = getHeadingIconValue();

  return (
    <div css={[greeting(softLanding, fixed_on_scroll), customCSS]}>
      {softLanding && <div css={greetingIndicator} />}
      {normalizedHeading && (
        <h2 css={greetingHeading}>
          {headingIconValue && (
            <img
              css={vehicleIcon}
              width="40"
              height="40"
              src={getVehicleIconUrl(headingIconValue)}
              alt={`${headingIconValue} logo`}
            />
          )}
          <span className={sensitiveDataClass}>{normalizedHeading}</span>
        </h2>
      )}
      {normalizedSubheading && (
        <h3 className={sensitiveDataClass} css={greetingHeading}>
          {normalizedSubheading}
        </h3>
      )}
      {normalizedParagraph && (
        <p className={sensitiveDataClass} css={paragraphCSS}>
          {normalizedParagraph}
        </p>
      )}
      {softLanding && benefits?.length && <Benefits benefits={benefits} />}
    </div>
  );
};

export default Greeting;
