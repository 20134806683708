import { css, SerializedStyles } from '@emotion/react';
import { colors } from 'src/theme/variables';
import { text12 } from 'src/theme/typography';

export const container = (fullWidth: boolean): SerializedStyles => css`
  display: ${fullWidth ? 'flex' : 'inline-block'};
`;

export const label = css`
  color: ${colors.dove};
`;

export const middleMan = css`
  content: '';
  flex-grow: 1;
  border-bottom: 1px dashed ${colors.nobel};
  margin-left: 8px;
`;

export const value = css`
  font-weight: 700;
  margin-left: 8px;
`;

export const valueSuffix = css`
  font-size: ${text12};
  font-weight: 500;
`;
