/** @jsxImportSource @emotion/react */
import React from 'react';

import { containerCSS, logoCSS, wrapperCSS } from './ErrorFallback.style';
import GenericErrorContent from 'src/components/GenericError/GenericErrorContent/GenericErrorContent';
import Logo from 'src/kit/Logo/Logo';
import Container from 'src/kit/Container/Container';

const ErrorFallback: React.FC = () => {
  return (
    <main css={wrapperCSS}>
      <Container customCSS={containerCSS}>
        <Logo customCSS={logoCSS} width={116} />
        <GenericErrorContent />
      </Container>
    </main>
  );
};

export default ErrorFallback;
