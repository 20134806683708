import { css, SerializedStyles } from '@emotion/react';

import { colors, typography, mq, actionColors, borderRadius } from 'src/theme/variables';
import { ButtonVariant } from './types';

const buttonColors = (bgc: string, bc: string, c: string): SerializedStyles => css`
  background-color: ${bgc};
  border-color: ${bc};
  color: ${c};
`;

const largeButtonStyles = css`
  ${mq[0]} {
    font-size: ${typography.fontSizeLarge};
    line-height: ${typography.lineHeightLarge};
    padding: 14px 16px;
  }
`;

const textButtonStyles = css`
  padding: 0;
  border: none;
  border-radius: 0;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
`;

const getButtonColors = (variant: ButtonVariant): SerializedStyles => {
  switch (variant) {
    case 'text':
    case 'text_inverted':
      return buttonColors('transparent', 'transparent', colors.azure);
    case 'secondary':
      return buttonColors(colors.white, colors.azure, colors.azure);
    case 'secondary_inverted':
      return buttonColors(colors.azure, colors.white, colors.white);
    case 'inverted':
      return buttonColors(colors.white, colors.white, colors.azure);
    default:
      return buttonColors(colors.azure, colors.azure, colors.white);
  }
};

const getDisabledColors = (variant: ButtonVariant): SerializedStyles => {
  switch (variant) {
    case 'text':
    case 'text_inverted':
      return buttonColors('transparent', 'transparent', colors.dove);
    case 'secondary':
      return buttonColors(colors.white, colors.silver, colors.dove);
    default:
      return buttonColors(colors.mercury, colors.mercury, colors.dove);
  }
};

const getActiveFocusColors = (variant: ButtonVariant): SerializedStyles => {
  switch (variant) {
    case 'text':
      return buttonColors('transparent', 'transparent', actionColors.active);
    case 'text_inverted':
      return buttonColors('transparent', 'transparent', colors.cornflower);
    default:
      return buttonColors(colors.denim, colors.denim, colors.white);
  }
};

const getHoverColors = (variant: ButtonVariant): SerializedStyles => {
  switch (variant) {
    case 'text':
      return buttonColors('transparent', 'transparent', actionColors.hover);
    case 'text_inverted':
      return buttonColors('transparent', 'transparent', colors.lightBlue);
    default:
      return buttonColors(colors.cornflower, colors.cornflower, colors.white);
  }
};

const getButtonVariant = (variant: ButtonVariant): SerializedStyles => {
  switch (variant) {
    case 'text':
    case 'text_inverted':
      return textButtonStyles;
    default:
      return css``;
  }
};

export const button = (
  isLarge: boolean,
  variant: ButtonVariant = 'default',
  fullWidth = false
): SerializedStyles => css`
  ${getButtonColors(variant)};
  font-family: ${typography.fontFamily};
  font-size: ${typography.fontSize};
  line-height: ${typography.lineHeight};
  font-weight: 700;
  border-radius: ${borderRadius}px;
  border-style: solid;
  border-width: 2px;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  max-width: 100%;
  transition:
    color 0.3s,
    background-color 0.3s,
    border-color 0.3s;
  padding: 10px 12px;
  position: relative;
  ${fullWidth &&
  css`
    width: 100%;
  `}
  ${getButtonVariant(variant)};

  &:hover {
    ${getHoverColors(variant)}
  }

  &:active,
  &:focus {
    ${getActiveFocusColors(variant)}
  }

  &:disabled,
  &:disabled:hover {
    cursor: not-allowed;
    ${getDisabledColors(variant)}
  }
  ${isLarge && largeButtonStyles}
`;

export const loaderWrapper = css`
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;
