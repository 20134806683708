import { css } from '@emotion/react';
import './assets/normalize.css';

import { colors, header, mq, typography as t } from './theme/variables';

export const globalCSS = css`
  body {
    font-family: ${t.fontFamily}, sans-serif;
    font-size: ${t.fontSize};
    line-height: ${t.lineHeight};
    font-variant-numeric: lining-nums;
    font-weight: 500;

    @page {
      margin-left: 0in !important;
      margin-right: 0in !important;
      margin-top: 0in !important;
      margin-bottom: 0in !important;
      padding: 0px !important;
    }
  }

  :root {
    --demo-flow-space: 0px;
    --browser-update-height: 0px;
    --header-height: calc(${header.headerHeightMobile}px + var(--demo-flow-space) + var(--browser-update-height));

    ${mq[0]} {
      --header-height: calc(${header.headerHeightDesktop}px + var(--demo-flow-space) + var(--browser-update-height));
    }
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td {
    margin: 0;
    padding: 0;
    border: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  p {
    margin: 0;
  }

  input[type='search'],
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-decoration {
    appearance: none;
  }

  ::placeholder {
    color: ${colors.dove};
    font-style: italic;
  }

  .__react_component_tooltip.place-left::after {
    border-left: 8px solid ${colors.white} !important;
  }

  .__react_component_tooltip.place-left::before {
    border-left: 8px solid ${colors.silver} !important;
  }

  .__react_component_tooltip.place-right::after {
    border-right: 8px solid ${colors.white} !important;
  }

  .__react_component_tooltip.place-right::before {
    border-right: 8px solid ${colors.silver} !important;
  }

  .__react_component_tooltip.place-top::after {
    border-top: 8px solid ${colors.white} !important;
  }

  .__react_component_tooltip.place-top::before {
    border-top: 8px solid ${colors.silver} !important;
  }

  .__react_component_tooltip.place-bottom::after {
    border-bottom: 8px solid ${colors.white} !important;
  }

  .__react_component_tooltip.place-bottom::before {
    border-bottom: 8px solid ${colors.silver} !important;
    border-left: 8px solid transparent !important;
    border-right: 8px solid transparent !important;
    margin-left: -8px !important;
    top: -8px !important;
  }

  .__react_component_tooltip.place-top::before {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    bottom: -8px;
    left: 50%;
    margin-left: -8px;
  }

  .ReactModal__Content {
    opacity: 0;
    transform: translateX(100px);
    transition: all 500ms ease-in-out;
  }

  .ReactModal__Content--after-open {
    opacity: 1;
    transform: translateX(0px);
  }

  .ReactModal__Content--before-close {
    opacity: 0;
    transform: translateX(100px);
  }

  @media screen and (min-width: 750px) {
    .calendly-popup {
      max-height: 684px !important;
    }
  }
`;
