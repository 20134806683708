/** @jsxImportSource @emotion/react */
import {
  Accordion as AccordionComponent,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion';

import LifeChevron from 'src/assets/svg/life-chevron.svg?react';
import {
  accordionHeading,
  accordionHeadingCSS,
  arrowCSS,
  descriptionCSS,
  lifeComparisonContainerCSS
} from './Comparison.style';
import LifeComparisonTable from './ComparisonTable/ComparisonTable';
import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';
import { DefaultLocation } from 'src/interfaces/IPage';

const Comparison: React.FC<DefaultLocation> = ({ gid, flow }) => {
  const onAccordionClick = () => {
    analytics.track(SEGMENT.SIDE_BY_SIDE_COMPARISON_CLICKED, gid, flow);
  };

  return (
    <section css={lifeComparisonContainerCSS}>
      <AccordionComponent allowZeroExpanded onChange={onAccordionClick}>
        <AccordionItem css={accordionHeading}>
          <AccordionItemHeading>
            <AccordionItemButton css={accordionHeadingCSS}>
              <span>See a side-by-side comparison</span>
              <LifeChevron css={arrowCSS} height="12px" />
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel css={descriptionCSS}>
            <LifeComparisonTable />
          </AccordionItemPanel>
        </AccordionItem>
      </AccordionComponent>
    </section>
  );
};

export default Comparison;
