/** @jsxImportSource @emotion/react */
import React from 'react';

import { CoverageComparisonSelectProps } from './CoverageComparisonSelect.types';
import { container, icon, option, label as labelCSS } from './CoverageComparisonSelect.style';
import { CoverageTypeOptions } from 'src/constants/coverageTypes';
import CoverageShields from 'src/assets/svg/coverages-sprite.svg';
import noop from 'src/utils/noop';

const CoverageComparisonSelect: React.FC<CoverageComparisonSelectProps> = ({
  customCSS,
  type,
  onSelectType = noop
}) => {
  return (
    <div css={[container, customCSS]} data-testid="coverage-comparison-select">
      {CoverageTypeOptions.map(({ value, label }) => (
        <button css={option(value === type)} key={value} onClick={() => onSelectType(value)} type="button">
          <img src={`${CoverageShields}#${value}${value === type ? 'red' : ''}`} css={icon} alt={`${label} Coverage`} />
          <span css={labelCSS(value === type)}>{label}</span>
        </button>
      ))}
    </div>
  );
};

export default CoverageComparisonSelect;
