/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

import GoogleReviewCard from 'src/components/Landing/GoogleReviewCard/GoogleReviewCard';
import GoogleReviewRating from 'src/components/Landing/GoogleReviewRating/GoogleReviewRating';
import { carousel, content, desktopView, header, rating, card, disclaimer } from './GoogleReview.style';
import useResponsive from 'src/hooks/useResponsive';
import GoogleReviewCarousel from 'src/components/Landing/GoogleReviewCarousel/GoogleReviewCarousel';
import { GoogleReviewsProps } from './GoogleReviews.types';

const GOOGLE_REVIEWS = [
  {
    text:
      'Matic took good care of me and answered all my questions. They were able to help me save a lot of money on ' +
      'my mortgage by finding me a lower rate on my home insurance.',
    author: 'Adilson V.'
  },
  {
    text: 'They made it easy to change insurance companies and saved me almost $600 a year auto/home combined.',
    author: 'James D.'
  },
  {
    text:
      'Over the course of a short phone call, they Matic rep both increased my coverage and reduced my premiums ' +
      'by nearly 50%.',
    author: 'Donna R.'
  }
];

const DISCLAIMER = '* Testimonials are actual Matic customer experiences. Your results may vary.';

const GoogleReviews: React.FC<GoogleReviewsProps> = ({
  onGoogleReviewsClick,
  heading = 'Real People, Really Happy Customers',
  withControls = false,
  customCSS = {}
}) => {
  const { isMobile, isTablet, isDesktop } = useResponsive();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.from('.google-review-card', {
      y: 40,
      opacity: 0,
      stagger: 0.1,
      scrollTrigger: {
        trigger: '#googleReviewCardWrapper',
        start: '150px bottom',
        end: 'bottom top'
      }
    });
  }, []);

  return (
    <div css={customCSS.wrapper}>
      <h2 css={[header, customCSS.heading]}>{heading}</h2>
      <div css={content}>
        <GoogleReviewRating
          customCSS={rating}
          disclaimer={isMobile || isTablet ? DISCLAIMER : ''}
          onGoogleReviewsClick={onGoogleReviewsClick}
        />
        {isMobile ? (
          <GoogleReviewCarousel customCSS={carousel} withControls={withControls} reviews={GOOGLE_REVIEWS} />
        ) : (
          <div>
            <div css={desktopView} id="googleReviewCardWrapper">
              {GOOGLE_REVIEWS.map(item => (
                <div className="google-review-card" css={card} key={item.author}>
                  <GoogleReviewCard text={item.text} author={item.author} />
                </div>
              ))}
            </div>
            {isDesktop && <div css={disclaimer}>{DISCLAIMER}</div>}
          </div>
        )}
      </div>
    </div>
  );
};

export default GoogleReviews;
