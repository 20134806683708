const _getValueByKey = (key: string, obj: { [key: string]: any }, depth = 1, currentDepth = 0): any => {
  if (currentDepth === depth || !key || !obj || typeof obj !== 'object') {
    return;
  }
  if (obj.hasOwnProperty(key)) {
    return obj[key];
  }

  const nestedObjKeys = Object.keys(obj).filter(
    value => typeof obj[value] === 'object' && obj[value] !== null && Object.keys(obj[value]).length > 0
  );
  let value;
  for (let i = 0; i < nestedObjKeys.length; i++) {
    const v = _getValueByKey(key, obj[nestedObjKeys[i]], depth, currentDepth + 1);
    if (v !== undefined) {
      value = v;
      break;
    }
  }
  return value;
};

const getValueByKey = (key: string, obj: { [key: string]: any }, depth = 1): any => {
  return _getValueByKey(key, obj, depth);
};

export default getValueByKey;
