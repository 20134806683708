import { QueryObserverResult, useQuery } from 'react-query';
import queryString from 'query-string';
import api from 'src/api/api';
import { ILoan } from 'src/interfaces/quotesView/ILoan';

const useLoans = (personGid: string | null, assetGid: string | undefined): QueryObserverResult<ILoan[]> => {
  return useQuery(
    ['quotes_view_loans', personGid, assetGid],
    () => api.get(`/api/quote_pdf/people/${personGid}/loans?${queryString.stringify({ assets_gid: assetGid })}`),
    {
      enabled: !!personGid
    }
  );
};

export default useLoans;
