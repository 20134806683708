/** @jsxImportSource @emotion/react */
import { containerInnerCSS } from 'src/components/ScheduleCall/ScheduleCall.style';
import SirvImage from 'src/kit/SirvImage/SirvImage';
import SIRV from 'src/constants/sirv';
import Anchor from 'src/kit/Anchor/Anchor';
import PhoneIcon from 'src/assets/svg/phone.svg?react';
import MailIcon from 'src/assets/svg/mail.svg?react';
import React from 'react';
import { ScheduleCallTeamProps } from './ScheduleCallTeam.types';
import {
  assistanceContactsCSS,
  assistanceContentCSS,
  assistanceCSS,
  assistanceDescriptionCSS,
  assistanceHeadingCSS,
  assistanceTextCSS,
  contactIconCSS,
  contactsHeadingCSS,
  imageCSS,
  linkCSS,
  phoneIconCSS
} from './ScheduleCallTeam.style';

const ScheduleCallTeam: React.FC<ScheduleCallTeamProps> = ({ agentEmail, agentPhone, onPhoneNumberClick }) => (
  <div css={containerInnerCSS}>
    <div css={assistanceCSS}>
      <SirvImage css={imageCSS} alt="Matic team" url={SIRV.TEAM_IMG} width={96} height={96} />
      <div css={assistanceContentCSS}>
        <div css={assistanceTextCSS}>
          <div css={assistanceHeadingCSS}>The Matic Team</div>
          <p css={assistanceDescriptionCSS}>
            We look forward to connecting with you. In the meantime, if you need immediate assistance please contact us.
          </p>
        </div>

        <div css={assistanceContactsCSS}>
          <div css={contactsHeadingCSS}>Have questions?</div>
          <Anchor css={linkCSS} href={`tel:${agentPhone}`} onClick={onPhoneNumberClick}>
            <PhoneIcon css={[contactIconCSS, phoneIconCSS]} />
            <span>{agentPhone}</span>
          </Anchor>
          <Anchor css={linkCSS} href={`mailto:${agentEmail}`}>
            <MailIcon css={contactIconCSS} />
            <span>{agentEmail}</span>
          </Anchor>
        </div>
      </div>
    </div>
  </div>
);

export default ScheduleCallTeam;
