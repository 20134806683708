/** @jsxImportSource @emotion/react */
import { FC, useState } from 'react';
import PhoneIcon from 'src/assets/svg/phone.svg';
import ViewEditField from 'src/components/ViewEditField/ViewEditField';
import { PHONE_MASK } from 'src/utils/phone';
import { inputCSS } from 'src/questionsForm/components/EditPhoneButton/EditPhoneButton.style';
import InputMask from 'react-input-mask';
import {
  containerCSS,
  labelCSS,
  mobileIconCSS,
  phoneInputCSS,
  viewEditFieldsInputCSS
} from './ProfilePhoneNumber.style';
import { ProfilePhoneNumberProps } from './ProfilePhoneNumber.types';
import { FS_SENSITIVE_DATA_CLASS } from 'src/constants/fullStory';

const ProfilePhoneNumber: FC<ProfilePhoneNumberProps> = ({
  phone,
  hasPhoneError,
  onPhoneUpdate,
  updatedPhone,
  isEdit = false,
  withIcon = false
}) => {
  const [isEditMode, setIsEditMode] = useState(isEdit);
  return (
    <div css={containerCSS}>
      {withIcon && <img css={mobileIconCSS} src={PhoneIcon} alt="Phone" />}
      <ViewEditField
        errorMessage="Please, enter valid phone number"
        label="Your Phone Number"
        value={phone}
        customCss={labelCSS}
        hasError={hasPhoneError}
        showEditable={isEditMode}
        onEditClicked={() => setIsEditMode(true)}
      >
        <InputMask
          className={FS_SENSITIVE_DATA_CLASS.MASK}
          css={[inputCSS(false), viewEditFieldsInputCSS, !phone && phoneInputCSS]}
          autoComplete="off"
          type="tel"
          name="phone"
          placeholder={phone}
          onChange={onPhoneUpdate}
          value={updatedPhone}
          mask={PHONE_MASK}
        />
      </ViewEditField>
    </div>
  );
};

export default ProfilePhoneNumber;
