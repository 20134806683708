import React from 'react';
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { ChartDataDto } from 'src/interfaces/session.types';
import HistoricalBarCustomLabel from './HistoricalBarCustomLabel';
import HistoricalBarCustomAxisTick from './HistoricalBarCustomAxisTick';
import { HistoricalPremiumBarChartValues } from 'src/components/HistoricalPremium/HistoricalPremium.types';

const HistoricalBarChart: React.FC<{ chartData?: ChartDataDto }> = ({ chartData }) => {
  if (!chartData) return null;

  const fake = 100;

  const values = chartData.reduce((acc: HistoricalPremiumBarChartValues, item, index) => {
    const value = +item[1];
    const tick = item[0];
    const prev = acc[index - 1];

    const updatedFake = index === 0 ? fake : prev.fake + prev.value;

    acc.push({ value, fake: updatedFake, tick });
    return acc;
  }, []);

  return (
    <ResponsiveContainer width="100%" height={350}>
      <BarChart
        width={730}
        height={250}
        data={values}
        margin={{ top: 70, left: 0, right: 0, bottom: 0 }}
        {...{
          overflow: 'visible'
        }}
      >
        <XAxis axisLine={false} tickLine={false} dataKey="tick" tick={<HistoricalBarCustomAxisTick />} />
        <YAxis hide />
        <Bar dataKey="fake" stackId="a" fill="#D0E2FB" />
        <Bar dataKey="value" stackId="a" fill="#156EEA" label={<HistoricalBarCustomLabel values={values} />} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default HistoricalBarChart;
