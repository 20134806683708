/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from 'react';

import { BundleQuoteCardDetailsProps, BundleQuoteDetailsTab, CurrentTabState } from './BundleQuoteCardDetails.types';
import { container, tabButton, tabIndicator, tabs, tabsInner, buttonWrapperCSS } from './BundleQuoteCardDetails.style';
import { getAutoQuotesCoveragesGroups, getHomeQuotesCoveragesGroups } from 'src/utils/quotesPage';
import QuoteCoverages from 'src/components/Quotes/QuoteCoverages/QuoteCoverages';
import { BundleType } from 'src/interfaces/IQuotes';

const initIndicatorPosition = {
  left: 0,
  width: 0
};

const BundleQuoteCardDetails: React.FC<BundleQuoteCardDetailsProps> = ({
  customCSS,
  quote,
  isSingleType,
  bundleType
}) => {
  const [currentTab, setCurrentTab] = useState<BundleQuoteDetailsTab>(
    bundleType === BundleType.HomeBundle ? BundleQuoteDetailsTab.Home : BundleQuoteDetailsTab.Auto
  );
  const [indicatorPosition, setIndicatorPosition] = useState<CurrentTabState>(initIndicatorPosition);
  const autoButtonRef = useRef<HTMLButtonElement>(null);
  const homeButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (autoButtonRef?.current) {
      const width = autoButtonRef.current.offsetWidth || 0;
      const left = autoButtonRef.current.offsetLeft || 0;
      setIndicatorPosition({ width, left });
    }
  }, [autoButtonRef]);

  useEffect(() => {
    const el = currentTab === BundleQuoteDetailsTab.Auto ? autoButtonRef : homeButtonRef;
    const width = el?.current?.offsetWidth || 0;
    const left = el?.current?.offsetLeft || 0;
    setIndicatorPosition({ width, left });
  }, [currentTab]);

  const setTab = (tab: BundleQuoteDetailsTab) => () => {
    setCurrentTab(tab);
  };

  if (bundleType === BundleType.AutoBundle && isSingleType) {
    return <QuoteCoverages groups={getAutoQuotesCoveragesGroups(quote.quotes.auto.assets)} />;
  }

  if (bundleType === BundleType.HomeBundle && isSingleType) {
    return <QuoteCoverages groups={getHomeQuotesCoveragesGroups(quote.quotes.home.coverages)} />;
  }

  return (
    <div css={[container, customCSS]}>
      <div css={tabs} role="tablist" aria-label="Policy types">
        <div css={tabsInner}>
          <div css={buttonWrapperCSS(bundleType === BundleType.HomeBundle)}>
            <button
              css={tabButton(currentTab === BundleQuoteDetailsTab.Auto)}
              onClick={setTab(BundleQuoteDetailsTab.Auto)}
              ref={autoButtonRef}
              aria-selected={currentTab === BundleQuoteDetailsTab.Auto}
              type="button"
              role="tab"
              id={`quote-details-auto-tab-${quote.quotes.auto.gid}`}
              aria-controls={`quote-details-auto-tabpanel-${quote.quotes.auto.gid}`}
            >
              Auto Policy
            </button>
            <button
              css={tabButton(currentTab === BundleQuoteDetailsTab.Home)}
              onClick={setTab(BundleQuoteDetailsTab.Home)}
              ref={homeButtonRef}
              aria-selected={currentTab === BundleQuoteDetailsTab.Home}
              type="button"
              role="tab"
              id={`quote-details-home-tab-${quote.quotes.home.gid}`}
              aria-controls={`quote-details-home-tabpanel-${quote.quotes.home.gid}`}
            >
              Home Policy
            </button>
          </div>
          <span css={tabIndicator(indicatorPosition.left, indicatorPosition.width)} />
        </div>
      </div>
      <div
        hidden={currentTab !== BundleQuoteDetailsTab.Auto}
        role="tabpanel"
        id={`quote-details-auto-tabpanel-${quote.quotes.auto.gid}`}
        aria-labelledby={`quote-details-auto-tab-${quote.quotes.auto.gid}`}
      >
        <QuoteCoverages groups={getAutoQuotesCoveragesGroups(quote.quotes.auto.assets)} />
      </div>
      <div
        hidden={currentTab !== BundleQuoteDetailsTab.Home}
        role="tabpanel"
        id={`quote-details-home-tabpanel-${quote.quotes.home.gid}`}
        aria-labelledby={`quote-details-home-tab-${quote.quotes.home.gid}`}
      >
        <QuoteCoverages groups={getHomeQuotesCoveragesGroups(quote.quotes.home.coverages)} />
      </div>
    </div>
  );
};

export default BundleQuoteCardDetails;
