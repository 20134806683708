import { css } from '@emotion/react';

import { colors, mq } from 'src/theme/variables';

import { text20, text24, text, headingXL } from 'src/theme/typography';

export const imgWithDotWrapper = css`
  margin: 24px 0;
  position: relative;
  ${mq[1]} {
    flex-basis: 43%;
  }
`;

export const imgWrapper = css`
  position: relative;
  overflow: hidden;
  max-width: 322px;
  ${mq[0]} {
    max-width: 460px;
  }
  ${mq[1]} {
    max-width: 546px;
  }
`;

export const img = css`
  display: block;
  position: absolute;
  width: calc(100% + 84px);
`;

export const imgMask = css`
  width: calc(100% + 2px);
  position: relative;
  top: 0;
  left: -1px;
  display: block;
`;

export const agencyInfo = css`
  ${mq[1]} {
    display: flex;
    justify-content: space-between;
  }
`;

export const textWrapper = css`
  ${mq[1]} {
    max-width: 450px;
  }
`;

export const heading = css`
  ${headingXL};
  margin: 0;
  ${mq[1]} {
    margin: 24px 0 40px;
  }
`;

export const headingSM = css`
  ${mq[1]} {
    display: none;
  }
`;

export const headingLG = css`
  display: none;
  ${mq[1]} {
    display: block;
  }
`;

export const agencyInfoWrapper = css`
  padding: 64px 0;
  ${mq[0]} {
    padding: 80px 0;
  }
  ${mq[1]} {
    padding: 112px 0;
  }
`;

export const dot = css`
  background-color: ${colors.azure};
  width: 31px;
  height: 31px;
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  ${mq[0]} {
    width: 56px;
    height: 56px;
  }
  ${mq[1]} {
    width: 64px;
    height: 64px;
    bottom: 5px;
    right: 10px;
  }
`;

export const bold = css`
  ${text20};
  font-weight: 700;
  ${mq[0]} {
    ${text24};
  }
`;

export const bigger = css`
  ${text};
  ${mq[0]} {
    ${text20};
    line-height: 28px;
  }
`;

export const item = css`
  margin-top: 24px;
`;
