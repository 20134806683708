/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';

import PageHeader from 'src/components/PageHeader/PageHeader';
import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';
import Page from 'src/components/Page/Page';
import { useParams } from 'react-router-dom';
import { DefaultLocation } from 'src/interfaces/IPage';
import Container from 'src/kit/Container/Container';
import DisclosuresPage from 'src/components/DisclosuresPage/DisclosuresPage';
import {
  disclosuresConfettiCSS,
  disclosuresContainerCSS,
  disclosuresContentCSS,
  disclosuresFooterCSS,
  disclosuresFooterTextCSS
} from './DisclosuresLayout.style';
import useTrackFullStorySession from 'src/hooks/useTrackFullStorySession';
import useTrackAdBlocker from 'src/hooks/useTrackAdBlocker';

const PAGE_TITLE = 'Matic Insurance | Get homeowners insurance';

const DisclosuresLayout: React.FC = () => {
  const { gid, flow } = useParams() as DefaultLocation;
  useTrackFullStorySession(gid);
  useTrackAdBlocker(gid);

  useEffect(() => {
    document.title = PAGE_TITLE;
    analytics.page(SEGMENT.PAGES.DISCLOSURES, {
      flow_type: flow,
      session_gid: gid
    });
  }, [flow, gid]);

  return (
    <Page fullHeight>
      <PageHeader page={SEGMENT.PAGES_KEY.DISCLOSURES} />
      <main css={disclosuresContentCSS}>
        <Container customCSS={disclosuresContainerCSS}>
          <DisclosuresPage />
        </Container>
      </main>
      <footer css={disclosuresFooterCSS}>
        <Container customCSS={disclosuresFooterTextCSS}>
          <span>Matic Insurance Services Inc,</span> CA License No. <span>019244</span>
        </Container>
        <div data-testid="footer-confetti" css={disclosuresConfettiCSS} />
      </footer>
    </Page>
  );
};

export default DisclosuresLayout;
