/** @jsxImportSource @emotion/react */
import React from 'react';

import { UserProfileDetailsIconListProps } from './UserProfileDetailsIconList.types';
import { heading, list as listCSS, item, icon as iconCSS, label as labelCSS } from './UserProfileDetailsIconList.style';
import { getDetailsDataByKey } from './UserProfileDetailsIconList.utils';

const UserProfileDetailsIconList: React.FC<UserProfileDetailsIconListProps> = ({ customCSS, header, list }) => {
  if (!list?.length) {
    return null;
  }
  return (
    <div css={customCSS}>
      <div css={heading}>{header}</div>
      <ul css={listCSS}>
        {list
          .map(key => getDetailsDataByKey(key))
          .map(({ key, label, icon, iconAlt }) => (
            <li key={key} css={item}>
              <img css={iconCSS} width={20} height={20} src={icon} alt={iconAlt} />
              <span css={labelCSS}>{label}</span>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default UserProfileDetailsIconList;
