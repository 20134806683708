import { useEffect, useState, useRef, useCallback } from 'react';

const useIdleManualTimer = (
  intervalTime: number,
  onIdleCallback: (should: boolean) => void
): [() => void, () => void] => {
  const [shouldStart, setShouldStart] = useState(false);
  const [shouldReset, setShouldReset] = useState(false);
  const [triggerStartAfterResume, shouldTriggerStartAfterResume] = useState(false);
  const timer: any = useRef(null);

  useEffect(() => {
    if (shouldStart || triggerStartAfterResume) {
      shouldTriggerStartAfterResume(false);
      timer.current = setTimeout(() => onIdleCallback(true), intervalTime);
      return () => clearTimeout(timer.current);
    }
  }, [shouldStart, intervalTime, onIdleCallback, triggerStartAfterResume]);

  useEffect(() => {
    if (shouldReset) {
      clearTimeout(timer.current);
    }
  }, [shouldReset]);

  const start = useCallback(() => {
    setShouldStart(true);
  }, []);

  const reset = useCallback(() => {
    setShouldReset(true);
    shouldTriggerStartAfterResume(true);
  }, []);

  return [start, reset];
};

export default useIdleManualTimer;
