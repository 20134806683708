/** @jsxImportSource @emotion/react */
import React from 'react';

import { LandingFlowCardsProps } from './LandingFlowCards.types';
import { cardCSS, containerCSS, flowLoadingWrapperCSS } from './LandingFlowCards.style';
import Loader from 'src/kit/Loader/Loader';
import { Flows } from 'src/interfaces/IPage';
import { FLOW_CARDS } from './LandingFlowCards.constants';
import FlowActionCard from 'src/components/Landing/FlowActionCard/FlowActionCard';
import noop from 'src/utils/noop';
import useResponsive from 'src/hooks/useResponsive';
import { getGetQuotesLandingTestId } from 'src/constants/automationTestIds';
import { getFlowURL } from './LandingFlowCards.utils';

const LandingFlowCards: React.FC<LandingFlowCardsProps> = ({
  customCSS,
  isLoading = false,
  flows = [],
  params = {},
  animationCardSelector,
  onFlowSelect = noop
}) => {
  const { isDesktop } = useResponsive();
  if (isLoading) {
    return (
      <div css={flowLoadingWrapperCSS}>
        <Loader />
      </div>
    );
  }

  if (!flows?.length) {
    return null;
  }

  return (
    <div css={[containerCSS(flows.length), customCSS]}>
      {flows
        .sort((a: Flows, b: Flows) => FLOW_CARDS[a].position - FLOW_CARDS[b].position)
        .map((flow: Flows, index) => (
          <div key={flow} css={cardCSS(!isDesktop && index === 2)} className={animationCardSelector}>
            <FlowActionCard
              horizontal={!isDesktop && index === 2}
              imgSrc={FLOW_CARDS[flow].img}
              imgAlt={`${FLOW_CARDS[flow].imgAlt}`}
              text={FLOW_CARDS[flow].name}
              onClick={() => onFlowSelect(flow, getFlowURL(params, FLOW_CARDS[flow]))}
              actionButtonTestId={getGetQuotesLandingTestId(flow)}
            />
          </div>
        ))}
    </div>
  );
};

export default LandingFlowCards;
