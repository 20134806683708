import { css } from '@emotion/react';
import { headingL } from 'src/theme/typography';
import { mq } from 'src/theme/variables';

export const heading = css`
  ${headingL};
  margin-bottom: 20px;
  ${mq[0]} {
    text-align: center;
  }
`;
