export interface SavingsProps {}

export enum SavingsContactLocations {
  Partnership = 'partnership_section',
  FAQ = 'faq_section',
  OfferForm = 'offer_form',
  StickyHeader = 'sticky_header',
  MobileHeader = 'mobile_header',
  LiveHelpButton = 'live_help_button'
}
