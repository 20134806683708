import { css } from '@emotion/react';
import { text24, text32 } from 'src/theme/typography';
import { mq } from 'src/theme/variables';

export const containerCSS = css`
  text-align: center;
`;

export const headingCSS = css`
  ${text24};
  font-weight: 700;
  margin-bottom: 28px;
  ${mq[0]} {
    ${text32};
  }
`;

export const buttonCSS = css`
  margin-top: 20px;
  ${mq[0]} {
    margin-top: 28px;
    padding-left: 86px;
    padding-right: 86px;
  }
`;
