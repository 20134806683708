import api from 'src/api/api';
import { API_URLS } from 'src/constants/api';
import { getApiUrl } from 'src/utils/api.helpers';

export const enum UserEvents {
  ScheduleCall = 'schedule_call_click',
  QuoteDetailsClicked = 'quote_details_click'
}

const userEventDataService = {
  callUserEventReport(userEvent: string, gid: string): Promise<any> {
    return api.post(
      getApiUrl(API_URLS.USER_DATA_EVENTS, gid),

      {
        body: {
          event: userEvent
        }
      }
    );
  }
};

export default userEventDataService;
