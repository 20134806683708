import React from 'react';

import { FormQuestionProps } from 'src/interfaces/IQuestion';
import SliderInput from 'src/questionsForm/components/Question/SliderInputs/SliderInput';

import { Controller, RegisterOptions } from 'react-hook-form';

const SliderQuestion: React.FC<FormQuestionProps> = props => (
  <Controller
    name={props.key_name}
    rules={props.validation?.static as RegisterOptions}
    render={({ field: { onChange, value, onBlur, ref } }) => {
      const onSelectChange = (v: string) => {
        onChange(v);
        onBlur();
      };

      return <SliderInput {...props} onValidEntry={onSelectChange} value={value} componentRef={ref} />;
    }}
  />
);

export default SliderQuestion;
