import { css, SerializedStyles } from '@emotion/react';
import { mq } from 'src/theme/variables';

export const containerCSS = (flowsLength: number): SerializedStyles => css`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 12px auto -${flowsLength === 3 ? 280 : 150}px;

  ${mq[0]} {
    max-width: 668px;
    margin-bottom: -${flowsLength === 3 ? 300 : 150}px;
  }
  ${mq[1]} {
    flex-wrap: nowrap;
    max-width: 990px;
    margin-bottom: -150px;
  }
`;

export const flowLoadingWrapperCSS = css`
  margin: 20px auto 0;
`;

export const cardCSS = (horizontal: boolean): SerializedStyles => css`
  max-width: ${horizontal ? '100%' : 'calc(50% - 24px)'};
  flex-basis: ${horizontal ? 424 : 200}px;
  display: flex;
  margin: ${horizontal ? 24 : 0}px 12px 0;
  opacity: 0;
  transform: translateY(40px);
  ${mq[0]} {
    max-width: ${horizontal ? '100%' : '310px'};
    flex-basis: ${horizontal ? '100%' : '310px'};
  }
  ${mq[1]} {
    max-width: ${horizontal ? '100%' : '320px'};
    flex-basis: ${horizontal ? '100%' : '320px'};
  }
`;
