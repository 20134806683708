import React from 'react';

import { FormQuestionProps } from 'src/interfaces/IQuestion';

import { QuestionWrapper } from 'src/questionsForm/components/QuestionWrapper/QuestionWrapper';
import QuantityInput from './QuantityInput';

const QuantityQuestion: React.FC<FormQuestionProps> = props => (
  <QuestionWrapper {...props} inputComponent={QuantityInput} />
);

export default QuantityQuestion;
