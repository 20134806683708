import { css } from '@emotion/react';

import { mq } from 'src/theme/variables';
import { text24, text32 } from 'src/theme/typography';

export const containerCSS = css`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  ${mq[0]} {
    flex-direction: row;
  }
`;

export const leftColCSS = css`
  flex-basis: 50%;
  margin-bottom: 32px;
  ${mq[0]} {
    width: 50%;
    padding-right: 20px;
    margin-bottom: 0;
  }
  ${mq[1]} {
    padding-right: 40px;
    margin-bottom: 0;
  }
`;

export const rightColCSS = css`
  width: 100%;
  ${mq[0]} {
    width: 50%;
    padding-left: 20px;
  }
  ${mq[1]} {
    width: 500px;
    padding-left: 0;
  }
`;

export const headerCSS = css`
  ${text24};
  margin: 0 0 12px;

  ${mq[0]} {
    ${text32}
  }
`;

export const subheaderCSS = css`
  line-height: 1.4;
`;
