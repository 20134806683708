import React from 'react';

import Checkbox from 'src/shared/components/Checkbox/Checkbox';
import { Controller } from 'react-hook-form';
import { CheckboxInputProps } from './CheckboxInput.types';

const CheckboxInput: React.FC<CheckboxInputProps> = ({
  key_name,
  label = '',
  label_description,
  icon,
  isDisabled,
  customCSS
}) => {
  return (
    <Controller
      key={key_name}
      name={key_name}
      render={({ field }) => (
        <Checkbox
          customCSS={{ label: customCSS }}
          key={field.name}
          {...field}
          label={label}
          labelDescription={label_description}
          icon={icon}
          disabled={isDisabled}
          checked={field.value || false}
          value={field.name}
          onChange={event => field.onChange(event.target.checked)}
        />
      )}
    />
  );
};

export default CheckboxInput;
