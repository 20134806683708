import { FloridaIneligibilityReasons } from 'src/interfaces/session.types';

export enum LocationStateKeys {
  UserName = 'userName',
  ContactPhone = 'contactPhone',
  IneligibleType = 'ineligibleType'
}

export interface LocationState {
  [LocationStateKeys.UserName]: string;
  [LocationStateKeys.ContactPhone]: string;
  [LocationStateKeys.IneligibleType]: FloridaIneligibilityReasons;
}
