export enum LookupErrorKeys {
  SESSION_LOCKED = 'session_locked',
  INVALID_ZIP = 'invalid_zip',
  INVALID_LOAN = 'invalid_loan',
  NULL = 'null'
}

export enum LookupFields {
  UTM_MEDIUM_DIRECT_MAIL_FIELD = 'direct_mail',
  ZIP_CODE_FIELD = 'zip',
  LOAN_NUMBER_FIELD = 'key',
  NONE = 'none'
}
