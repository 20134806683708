import { SerializedStyles, css } from '@emotion/react';
import { text24, text32 } from 'src/theme/typography';
import { colors as c, mq } from 'src/theme/variables';

export const sectionWrapperCSS = (isDarkerBg: boolean): SerializedStyles => css`
  background-color: ${isDarkerBg ? c.grayFive : c.white};
  padding-bottom: 32px;
  padding-top: 32px;

  ${mq[0]} {
    padding-bottom: 40px;
    padding-top: 40px;
  }
  ${mq[1]} {
    padding-bottom: 60px;
    padding-top: 60px;
  }
`;

export const googleReviewsCSS = css`
  overflow-x: hidden;

  ${mq[1]} {
    padding: 0;
  }
`;

export const carrierContentCSS = css`
  h2 {
    line-height: 30px;
    ${mq[0]} {
      line-height: 44px;
    }
  }
  p {
    line-height: 24px;
    ${mq[0]} {
      line-height: 20px;
      margin-bottom: 6px;
    }
  }
`;

export const googleReviewHeadingCSS = css`
  ${text24};
  line-height: 30px;

  ${mq[0]} {
    ${text32};
  }
`;
