/** @jsxImportSource @emotion/react */
import React from 'react';
import {
  borderTop,
  dialogActionsCSS,
  dialogBodyCSS,
  dialogCloseCSS,
  dialogContentCSS,
  dialogContentInlineCSS,
  dialogOverlayInlineCSS,
  dialogTitleCSS
} from './FileUploadDialog.style';
import Dialog from 'src/kit/Dialog/Dialog';
import useResponsive from 'src/hooks/useResponsive';
import { FileUploadDialogProps } from './FileUploadDialog.types';

const FileUploadDialog: React.FC<FileUploadDialogProps> = ({ isOpen, onClose, title, description, children }) => {
  const { isMobile } = useResponsive();

  return (
    <Dialog
      isOpen={isOpen}
      onRequestClose={onClose}
      title={title}
      shouldCloseOnOverlayClick
      customCSS={{
        body: dialogBodyCSS,
        closeButton: dialogCloseCSS,
        title: dialogTitleCSS,
        dialogInline: {
          content: dialogContentInlineCSS(isMobile),
          overlay: dialogOverlayInlineCSS(isMobile)
        }
      }}
    >
      <div css={[dialogContentCSS, borderTop]}>{description}</div>
      <div css={[dialogActionsCSS, borderTop]}>{children}</div>
    </Dialog>
  );
};

export default FileUploadDialog;
