import { css, SerializedStyles } from '@emotion/react';

import { colors as c, mq } from 'src/theme/variables';
import { text, text20 } from 'src/theme/typography';

export const disclosuresFormCSS = (isCallMeNow?: boolean): SerializedStyles => css`
  width: 100%;

  ${isCallMeNow &&
  css`
    background-color: ${c.azure};
    color: ${c.white};
    ${text};
    ${mq[0]} {
      width: unset;
      margin: 0 24px;
    }
  `}
`;

export const submitButtonCSS = (isCallMeNow?: boolean, isBigPhoneVariation?: boolean): SerializedStyles => css`
  display: block;
  width: 90%;
  margin: auto;
  font-variant-numeric: lining-nums;
  padding: 16px;

  ${mq[0]} {
    width: 100%;
  }

  ${text20};

  ${isBigPhoneVariation &&
  css`
    span {
      display: flex;
      justify-content: center;

      img {
        margin-right: 8px;
      }
    }
  `}

  ${!isCallMeNow &&
  css`
    &:hover {
      background-color: ${c.azure};
      border-color: ${c.azure};
    }
  `}

  ${mq[0]} {
    margin-bottom: 0;
  }

  img {
    margin-right: 12px;
  }
`;

export const mobilePhoneCSS = css`
  padding-bottom: 20px;
`;

export const phoneIconCSS = css`
  height: 20px;
  margin-right: 12px;
  color: ${c.azure};

  ${mq[0]} {
    display: none;
  }

  ${mq[1]} {
    display: block;
  }
`;

export const iconButtonWrapperCSS = css`
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: center;
  font-variant-numeric: lining-nums;
`;

export const buttonsWrapperCSS = css`
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;

  button {
    ${mq[0]} {
      ${text};
    }

    ${mq[1]} {
      ${text20};
    }
  }

  ${mq[0]} {
    flex-direction: row;

    button {
      height: 68px;
      max-width: 48%;
    }
  }
`;
