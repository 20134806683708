/** @jsxImportSource @emotion/react */

import DividerIcon from 'src/assets/svg/curvy-divider.svg';
import { diffContainerCSS, diffHeadingCSS, diffPointCSS, diffWrapperCSS, dividerCSS } from './DifferenceSection.style';

const DifferenceSection = () => {
  return (
    <section css={diffContainerCSS}>
      <h2 css={diffHeadingCSS}>
        What&apos;s the difference between term life insurance, accidental death, and whole life?
      </h2>
      <img css={dividerCSS} src={DividerIcon} alt="Divider" />

      <div css={diffWrapperCSS}>
        <div css={diffPointCSS}>
          <h3>Term Life Insurance</h3>
          <p>
            Getting a term life policy secures a tax-free payment for your loved ones in case you pass away while the
            policy is active. Terms typically range between 10 and 30 years.
          </p>
        </div>
        <div css={diffPointCSS}>
          <h3>Accidental Death Insurance</h3>
          <p>
            This type of life insurance provides your family with a payout in case you pass away in a covered accident.
          </p>
        </div>
        <div css={diffPointCSS}>
          <h3>Whole Life Insurance</h3>
          <p>
            This is one type of permanent insurance that provides coverage for as long as you live. These premiums tend
            to cost more than term insurance because the policy doesn&apos;t have an end date.
          </p>
        </div>
      </div>
    </section>
  );
};

export default DifferenceSection;
