import { css } from '@emotion/react';
import { mq } from 'src/theme/variables';

export const wrapperCSS = css`
  background-color: #e5e5e5;
  display: flex;
  flex-direction: column;
  gap: 30px;

  ${mq[0]} {
    padding: 30px 0;
    height: unset;
  }

  @media print {
    padding: 0;
    height: unset;
    gap: 0;
  }
`;
