/** @jsxImportSource @emotion/react */
import LifeGirlInPhone from 'src/assets/svg/life-girl-in-phone.svg';
import { advisorContainerCSS } from './Advisor.style';
import Button from 'src/kit/Button/Button';

interface AdvisorProps {
  onTalkToAdvisorClicked: (location: string) => void;
}

const Advisor: React.FC<AdvisorProps> = ({ onTalkToAdvisorClicked }) => {
  return (
    <section css={advisorContainerCSS}>
      <div>
        <h2>Still have questions? Talk to an advisor to get started.</h2>
        <p>
          Matic’s team of life insurance advisors is available to help you compare options and feel confident about your
          coverage.
        </p>
        <Button fullWidth onClick={() => onTalkToAdvisorClicked('bottom-advisor-section')}>
          Talk to an Advisor
        </Button>
      </div>
      <img src={LifeGirlInPhone} alt="The girl looks at the phone" />
    </section>
  );
};

export default Advisor;
