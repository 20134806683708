import { QueryObserverResult, useQuery, useQueryClient } from 'react-query';
import { QuotesResponse, QuotesResponseStatus } from 'src/interfaces/IQuotes';
import { Flows } from 'src/interfaces/IPage';
import { QUERY_CACHE_KEYS } from 'src/constants/queryCacheKeys';
import api from 'src/api/api';
import { API_URLS } from 'src/constants/api';
import { getApiUrl } from 'src/utils/api.helpers';

function useQuotes<QuotesType extends QuotesResponse>(
  flow: Flows,
  gid: string,
  refetchInterval: number | false = false,
  setRefetchInterval: (interval: number | false) => void = () => null,
  refetchOnMount = false,
  shouldSkip?: boolean
): QueryObserverResult<QuotesType> {
  const client = useQueryClient();

  return useQuery([QUERY_CACHE_KEYS.QUOTES, flow, gid], () => api.get(getApiUrl(API_URLS.QUOTES, flow, gid)), {
    refetchInterval,
    refetchIntervalInBackground: true,
    refetchOnMount,
    enabled: !shouldSkip,
    onSuccess: async data => {
      if (data?.status !== QuotesResponseStatus.InProgress) {
        setRefetchInterval(false);
        await client.refetchQueries([QUERY_CACHE_KEYS.CONFIG]);
      }
    }
  });
}

export default useQuotes;
