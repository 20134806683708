/** @jsxImportSource @emotion/react */
import React from 'react';

import { SerializedStyles } from '@emotion/react';

import { containerCSS, headerCSS, leftColCSS, rightColCSS, subheaderCSS } from './CarriersList.style';
import CarriersCarousel from 'src/components/CarriersCarousel/CarriersCarousel';

interface Props {
  customCSS?: {
    container?: SerializedStyles;
    textContent?: SerializedStyles;
  };
  header?: string | React.ReactElement;
  subheader?: string | React.ReactElement;
}

const HEADER = 'Matic Only Works With The Best';
const SUB_HEADER =
  'Matic will shop you against 40+ A-Rated carriers so you’ll never have to worry if you’ve got the best policy.';

const CarriersList: React.FC<Props> = ({ customCSS = {}, header = HEADER, subheader = SUB_HEADER }) => {
  return (
    <div css={[containerCSS, customCSS.container]}>
      <div css={[leftColCSS, customCSS.textContent]}>
        <h2 css={headerCSS}>{header}</h2>
        <p css={subheaderCSS}>{subheader}</p>
      </div>
      <div css={rightColCSS}>
        <CarriersCarousel />
      </div>
    </div>
  );
};

export default CarriersList;
