import { css } from '@emotion/react';
import { text, text20 } from 'src/theme/typography';
import { colors, mq } from 'src/theme/variables';

export const accordionContainerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  font-weight: 500;
`;

export const accordionItemCSS = css`
  border: 1px solid ${colors.mercury};
  padding: 24px 32px;
  transition: 0.25s ease-in-out;
`;

export const accordionHeadingCSS = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  gap: 15px;

  ${text};

  ${mq[0]} {
    ${text20}
  }
`;

export const arrowCSS = css`
  transition: 0.25s ease-in-out;

  [aria-expanded='true'] & {
    transform: rotate(-180deg);
  }
`;

export const descriptionCSS = css`
  ${text};
  max-height: 500px;
  transition: 0.25s ease-in-out;
  padding-top: 40px;

  &[hidden] {
    padding-top: 0;
    display: block;
    max-height: 0;
    overflow: hidden;
  }

  ${mq[0]} {
    ${text20}
  }
`;
