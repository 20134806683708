/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';

import Button from 'src/kit/Button/Button';
import { ButtonSize } from 'src/kit/Button/types';
import { Entity, HookFormFieldEntity, EntityList as EntityListInterface } from 'src/interfaces/IPage';
import EntityListItem from './EntityListItem';
import EntityAddNew from 'src/questionsForm/components/EntityAddNew/EntityAddNew';
import { isAddingDriverVehicleDisabled, isPrimaryDriver } from 'src/utils/schemaStateValidation';
import { indicator } from 'src/theme/shared-styles';
import { entityList as entityListCSS, entityListButton, entityListHeading } from './EntityList.style';

interface Props {
  list: EntityListInterface;
  entities: HookFormFieldEntity[];
  onAfterSubmit(): void;
  isCurrent: boolean;
  key_name: string;
  detailsVisible: boolean;
  append: (value: Entity, shouldFocus: { shouldFocus: boolean }) => void;
  remove: (index?: number | number[]) => void;
}

const EntityList: React.FC<Props> = ({
  entities,
  list,
  onAfterSubmit,
  isCurrent,
  key_name,
  detailsVisible,
  append,
  remove
}) => {
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const showButton = isCurrent && !isSubmitted;

  const canRemove = (entity: HookFormFieldEntity): boolean => {
    return (
      !isPrimaryDriver(key_name, entity.driver_relationship_to_policyholder as string) &&
      (entities.length > 1 || showButton)
    );
  };

  const onAddNew = (entity: Entity) => {
    append(entity, { shouldFocus: false });
  };

  const onRemove = (index: number) => () => {
    remove(index);
  };

  const onSubmit = async () => {
    setIsSubmitted(true);
    onAfterSubmit();
  };

  return (
    <div css={entityListCSS(detailsVisible)}>
      <div css={indicator} />
      <p css={entityListHeading}>{list?.heading}</p>

      {entities.map((e, i) => (
        <EntityListItem entity={e} list={list} key={e.id} canRemove={canRemove(e)} onRemove={onRemove(i)} />
      ))}

      {!isAddingDriverVehicleDisabled(key_name, entities) && <EntityAddNew list={list} onSubmit={onAddNew} />}

      {showButton && (
        <Button
          data-testid="questions-form-action-button"
          onClick={onSubmit}
          type="button"
          disabled={entities.length < 1}
          customCSS={entityListButton}
          size={ButtonSize.Large}
        >
          Confirm & Continue
        </Button>
      )}
    </div>
  );
};

export default EntityList;
