import { css } from '@emotion/react';

export const quotesContainerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
`;

export const featuredQuoteWrapperCSS = css`
  display: flex;
  flex-direction: column;
`;
