/** @jsxImportSource @emotion/react */
import React, { ChangeEvent, useState } from 'react';
import {
  cardModalCSS,
  cardModalDetailsCSS,
  cardPhoneNumberCSS,
  cardPolicyDetailsCSS,
  cardPolicyWrapperCSS,
  customerAliasCSS,
  mobileDisclosuresCSS,
  policyAddressCSS,
  policyExpirationDateCSS,
  securityDisclosureFormCSS,
  privacyMattersCustomCSS,
  offerDetailsCSS,
  phoneInputCSS,
  titleCSS,
  buttonWrapperCSS,
  modalBodyCSS,
  contentCSS,
  bundleMsgWrapperCSS,
  propertyCSS,
  BBBIconCSS
} from './SavingsOffer.style';
import { savingsOfferMobileIcon, viewEditFieldsInputCSS } from 'src/components/ViewEditField/ViewEditFields.style';
import ViewEditField from 'src/components/ViewEditField/ViewEditField';
import InputMask from 'react-input-mask';
import { inputCSS } from 'src/questionsForm/components/EditPhoneButton/EditPhoneButton.style';
import DisclosureForm from 'src/components/DisclosureForm/DisclosureForm';
import { isPhoneValid, PHONE_MASK } from 'src/utils/phone';
import phoneIcon from 'src/assets/svg/phone.svg';
import Dialog from 'src/kit/Dialog/Dialog';
import { useParams } from 'react-router-dom';
import Consent from 'src/components/Consent/Consent';
import { SavingsOfferProps } from './SavingsOffer.types';
import { getReadableAddress } from 'src/utils/homeDetails';
import { customConsentCSS } from 'src/components/AllFunnelOfferForm/AllFunnelOfferForm.style';
import BBBIcon from 'src/components/BBBIcon/BBBIcon';
import { BBBIconVariation } from 'src/components/BBBIcon/BBBIcon.types';
import CustomTCPAConsent from 'src/components/CustomTCPAConsent/CustomTCPAConsent';
import SEGMENT from 'src/constants/segment';
import analytics from 'src/utils/analytics';
import { DefaultLocation } from 'src/interfaces/IPage';
import Button from 'src/kit/Button/Button';
import CloseIcon from 'src/assets/svg/close.svg?react';
import { getPossessiveForm } from 'src/components/SavingsHero/SavingHero.utils';
import useResponsive from 'src/hooks/useResponsive';
import { FS_SENSITIVE_DATA_CLASS } from 'src/constants/fullStory';

const SavingsOffer: React.FC<SavingsOfferProps> = ({
  person,
  bestQuote,
  address,
  currentPolicy,
  propertyAddress = '',
  isSubmitLoading,
  onSubmit,
  isImplicitDisclosuresAccepted,
  isBundleMsgActive = false
}) => {
  const { gid, flow } = useParams() as DefaultLocation;
  const { isMobile } = useResponsive();
  const [updatedPhone, setUpdatedPhone] = useState<string>('');
  const [isEditMode, setIsEditMode] = useState(false);
  const [hasPhoneError, setHasPhoneError] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isPhoneModalOpen, setIsPhoneModalOpen] = useState(false);

  const onPhoneUpdate = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setHasPhoneError(false);
    setUpdatedPhone(value);
  };

  const handleSubmitButtonClick = (fromModal = false) => {
    if (validatePhone()) {
      const phone = updatedPhone.trim().length === 0 ? person.phone : updatedPhone;
      onSubmit(phone, fromModal);
    } else {
      analytics.track(SEGMENT.SAVINGS_INVALID_PHONE_SUBMIT_ATTEMPTED, gid, flow);
    }
  };

  const validatePhone = () => {
    const isValid = updatedPhone ? isPhoneValid(updatedPhone) : !!person.phone;
    setHasPhoneError(!isValid);
    return isValid;
  };

  const getCTAButtonText = () => {
    const OLBText = 'Confirm My Savings';
    const defaultSavingsText = 'Continue';

    return bestQuote?.online_bind_url ? OLBText : defaultSavingsText;
  };

  const getPhoneNumberInput = () => (
    <ViewEditField
      errorMessage="Please, enter valid phone number"
      label="Your Phone Number"
      value={person.phone}
      hasError={hasPhoneError}
      showEditable={isEditMode}
      onEditClicked={() => setIsEditMode(true)}
    >
      <div>
        <InputMask
          className={FS_SENSITIVE_DATA_CLASS.MASK}
          css={[inputCSS(false), viewEditFieldsInputCSS, !person.phone && phoneInputCSS]}
          autoComplete="off"
          type="tel"
          name="phone"
          placeholder={person.phone}
          onChange={onPhoneUpdate}
          value={updatedPhone}
          mask={PHONE_MASK}
        />
      </div>
    </ViewEditField>
  );

  const handleModalClose = () => setIsModalOpen(false);

  const closePhoneInputModal = () => {
    analytics.track(SEGMENT.PHONE_INPUT_MODAL.CLOSED, gid, flow);

    setIsPhoneModalOpen(false);
  };

  const openPhoneInputModal = () => {
    analytics.track(SEGMENT.PHONE_INPUT_MODAL.OPENED, gid, flow);

    setIsPhoneModalOpen(true);
  };

  const title = (
    <div css={titleCSS}>
      <h2>Let`s get started.</h2>
      <CloseIcon onClick={closePhoneInputModal} />
    </div>
  );

  return (
    <div css={cardModalCSS}>
      <div css={cardModalDetailsCSS}>
        <DisclosureForm
          CTAText={getCTAButtonText()}
          onFormSubmit={() => (!!person.phone ? handleSubmitButtonClick(false) : openPhoneInputModal())}
          phoneInput={getPhoneNumberInput()}
          isLoading={isSubmitLoading}
          customCSS={{
            formWrapperCSS: securityDisclosureFormCSS
          }}
        />
        {!isImplicitDisclosuresAccepted && !!person.phone && (
          <CustomTCPAConsent
            buttonLabel={getCTAButtonText()}
            customCSS={privacyMattersCustomCSS()}
            location={SEGMENT.LOCATIONS.SF_PRIVACY_MATTERS}
          />
        )}
      </div>

      <div css={mobileDisclosuresCSS}>
        <Dialog isOpen={isModalOpen} onRequestClose={handleModalClose}>
          <Consent customCSS={customConsentCSS} />
          <DisclosureForm
            CTAText={getCTAButtonText()}
            onFormSubmit={() => handleSubmitButtonClick(true)}
            phoneInput={getPhoneNumberInput()}
            shouldRenderPhoneOnMobile
            isLoading={isSubmitLoading}
          />
        </Dialog>
      </div>

      <div css={offerDetailsCSS}>
        <div css={cardPolicyWrapperCSS}>
          {isBundleMsgActive ? (
            <div css={bundleMsgWrapperCSS(!!person.phone)}>
              <div>
                <p
                  css={customerAliasCSS}
                  className={FS_SENSITIVE_DATA_CLASS.MASK}
                >{`${person.first_name} ${getPossessiveForm(person.last_name)} Profile`}</p>
                <p css={policyAddressCSS} className={FS_SENSITIVE_DATA_CLASS.MASK}>
                  <span role="img" aria-label="House">
                    🏡{' '}
                  </span>
                  {(address && getReadableAddress(address)) ?? propertyAddress}
                </p>
                {currentPolicy.expiration_date && (
                  <b css={policyExpirationDateCSS(isBundleMsgActive)} className={FS_SENSITIVE_DATA_CLASS.MASK}>
                    {currentPolicy.carrier_name} (renews on {currentPolicy.expiration_date})
                  </b>
                )}
                {currentPolicy?.premium && (
                  <p css={propertyCSS} className={FS_SENSITIVE_DATA_CLASS.MASK}>
                    Premium <span /> ${currentPolicy?.premium}
                  </p>
                )}
                {currentPolicy?.deductible && (
                  <p css={propertyCSS} className={FS_SENSITIVE_DATA_CLASS.MASK}>
                    Deductible <span /> ${currentPolicy?.deductible}
                  </p>
                )}
              </div>
            </div>
          ) : (
            <div css={cardPolicyDetailsCSS(!!person.phone)}>
              <div>
                <p
                  css={customerAliasCSS}
                  className={FS_SENSITIVE_DATA_CLASS.MASK}
                >{`${person.first_name} ${person.last_name}`}</p>
                <p css={policyAddressCSS} className={FS_SENSITIVE_DATA_CLASS.MASK}>
                  <span role="img" aria-label="House">
                    🏡{' '}
                  </span>
                  {(address && getReadableAddress(address)) ?? propertyAddress}
                </p>
                {currentPolicy.expiration_date && (
                  <p css={policyExpirationDateCSS()} className={FS_SENSITIVE_DATA_CLASS.MASK}>
                    {currentPolicy.carrier_name} (renews on {currentPolicy.expiration_date})
                  </p>
                )}
              </div>
            </div>
          )}
          {person.phone && (
            <div css={cardPhoneNumberCSS} data-testid="phone-block">
              <img css={savingsOfferMobileIcon} src={phoneIcon} alt="phone-icon" />
              {getPhoneNumberInput()}
            </div>
          )}
        </div>
      </div>

      {!isBundleMsgActive && <BBBIcon colorVariation={BBBIconVariation.Blue} customCSS={BBBIconCSS} />}

      <Dialog
        isOpen={isPhoneModalOpen}
        title={title}
        size="small"
        customCSS={{ body: modalBodyCSS, dialogInline: { content: contentCSS(isMobile) } }}
        shouldCloseOnOverlayClick
      >
        {getPhoneNumberInput()}
        {!isImplicitDisclosuresAccepted && (
          <CustomTCPAConsent
            buttonLabel={getCTAButtonText()}
            customCSS={privacyMattersCustomCSS(!!person.phone)}
            location={SEGMENT.LOCATIONS.SF_PRIVACY_MATTERS}
          />
        )}

        <div css={buttonWrapperCSS}>
          <Button variant="secondary" onClick={closePhoneInputModal}>
            Cancel
          </Button>
          <Button onClick={() => handleSubmitButtonClick(false)}>Continue</Button>
        </div>
      </Dialog>
    </div>
  );
};

export default SavingsOffer;
