/** @jsxImportSource @emotion/react */
import React, { useCallback, useState, useRef } from 'react';

import { useParams } from 'react-router-dom';

import Async from 'react-select/async';
import debounce from 'debounce-promise';

import { FormFieldProps, VehicleOption } from 'src/interfaces/IQuestion';
import api from 'src/api/api';
import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';
import { DefaultLocation } from 'src/interfaces/IPage';
import { Option, MenuList, DropdownIndicator, Control } from 'src/shared/components/Select/SelectParts.utils';
import { selectCSS } from 'src/shared/components/Select/Select.style';

const loadOptions =
  (url: string) =>
  (inputValue: string): Promise<VehicleOption[]> =>
    api.get(`${url}?term=${inputValue}`);

const VehicleSelect: React.FC<FormFieldProps> = props => {
  const [selected, setSelected] = useState<VehicleOption | null>(null);
  const selectRef = useRef(null);
  const { flow, gid } = useParams() as DefaultLocation;
  const onChange = (v: VehicleOption | null) => {
    setSelected(v);
    !!props.onValidEntry && props.onValidEntry(v);
  };
  const hasPropsValue = useCallback(() => Object.keys(props.value || ({} as VehicleOption)).length > 0, [props.value]);
  const value = selected || hasPropsValue() ? (props.value as VehicleOption) : null;

  const getLabelString = (option: VehicleOption) => `${option.year} ${option.make} ${option.model}`;
  const getOptionValue = (option: VehicleOption) => option.gid || '';

  if (!props.url) {
    throw new Error('`url` must be provided in props of a VehicleSelect.');
  }

  const onBlur = (inputValue: string) => {
    const options = (selectRef.current as any)?.props.options || [];
    if (inputValue && options.length === 0) {
      analytics.track(SEGMENT.AUTOCOMPLETE_FAILED, gid, flow, {
        question_key: props.inputId,
        value: inputValue
      });
    }
    props.onBlur?.();
  };

  return (
    <Async
      css={selectCSS(props.hasError)}
      ref={selectRef}
      cacheOptions
      inputId={props.inputId}
      isDisabled={props.isDisabled}
      data-testid="async-select"
      classNamePrefix="select"
      placeholder={props.placeholder}
      components={{
        MenuList,
        Option,
        DropdownIndicator,
        Control
      }}
      getOptionLabel={getLabelString}
      getOptionValue={getOptionValue}
      isClearable={false}
      loadOptions={debounce(loadOptions(props.url), 300)}
      value={value}
      onChange={(v: unknown) => onChange(v as VehicleOption)}
      onBlur={e => onBlur((e.target as HTMLInputElement).value)}
    />
  );
};

export default VehicleSelect;
