import { css } from '@emotion/react';
import { text, text20, text24, text40 } from 'src/theme/typography';
import { colors, mq } from 'src/theme/variables';

export const getStartedContainerCSS = css`
  margin-bottom: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.babyBlue};
  padding: 20px;
  border-radius: 8px;
  gap: 20px;
  text-align: center;

  h2 {
    margin: 0 0 12px;
    ${text24};
  }

  p {
    ${text};
  }

  button {
    padding: 12px;
    width: 100%;
  }

  ${mq[0]} {
    padding: 40px 56px;
    flex-direction: row;
    text-align: unset;

    h2 {
      ${text40};
      margin-bottom: 8px;
    }

    p {
      ${text20};
    }

    button {
      padding: 16px;
      width: 293px;
    }
  }
`;
