import React from 'react';

import PageHeader from 'src/components/PageHeader/PageHeader';
import Footer from 'src/components/Footer/Footer';
import UnsupportedCarrierLanding from 'src/components/Landing/UnsupportedCarrierLanding/UnsupportedCarrierLanding';
import CustomizedCarrierLanding from 'src/components/Landing/CustomizedCarrierLanding/CustomizedCarrierLanding';
import Page from 'src/components/Page/Page';
import Landing from 'src/components/Landing/Landing';
import SEGMENT from 'src/constants/segment';
import useLanding from 'src/api/landing/useLanding';
import { useLocation } from 'react-router-dom';

const LandingLayout: React.FC = () => {
  const { data } = useLanding();
  const { pathname } = useLocation();

  const getLandingPageVariation = () => {
    switch (true) {
      case pathname.includes('v1'):
        return <CustomizedCarrierLanding />;
      case pathname.includes('v2'):
        return <UnsupportedCarrierLanding />;
      default:
        return <Landing />;
    }
  };

  return (
    <Page>
      <PageHeader phone={data?.agent_phone} page={SEGMENT.PAGES_KEY.LANDING} />
      <main>{getLandingPageVariation()}</main>
      <Footer phone={data?.agent_phone} page={SEGMENT.PAGES_KEY.LANDING} withConfetti />
    </Page>
  );
};

export default LandingLayout;
