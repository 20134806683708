import { LocationState, LocationStateKeys } from './ConfirmationLayout.types';

export const isObjectInstanceOfLocationState = (object: unknown): object is LocationState => {
  return (
    typeof object === 'object' &&
    object !== null &&
    (Object.prototype.hasOwnProperty.call(object, LocationStateKeys.OlB_QUOTES_ARRAY) ||
      Object.prototype.hasOwnProperty.call(object, LocationStateKeys.CALL_ME_NOW_VARIATION) ||
      Object.prototype.hasOwnProperty.call(object, LocationStateKeys.CURRENT_QUOTE) ||
      Object.prototype.hasOwnProperty.call(object, LocationStateKeys.POLICY_HOLDER) ||
      Object.prototype.hasOwnProperty.call(object, LocationStateKeys.SELECTED_CARRIER))
  );
};
