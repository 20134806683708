/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import {
  singleSliderWrapperCSS,
  sliderOverridesCSS,
  optionsWrapperCSS,
  wrapperCSS
} from 'src/questionsForm/components/Question/SliderInputs/SliderInput.style';
import noop from 'src/utils/noop';
import { SliderQuestionProps, SliderAnswer } from 'src/interfaces/IQuestion';
import { getDefaultCarrierSliderValues } from 'src/questionsForm/components/Question/SliderInputs/SliderInput.utils';

import Slider from '@mui/material/Slider';

const CarrierSliderInput: React.FC<SliderQuestionProps> = ({ customCSS, onValidEntry = noop, value }) => {
  const FICOSliderConfiguration = getDefaultCarrierSliderValues();
  const [sliderClicksCount, setSliderClicksCount] = useState(0);
  const [sliderValue, setSliderValue] = useState(value ? +value : 5);

  const onSliderValueChange = (v: number) => {
    setSliderValue(v);
    onValidEntry!({
      sliderValue: v.toString(),
      clicksCount: sliderClicksCount + 1
    } as SliderAnswer);
  };

  return (
    <div css={wrapperCSS}>
      <div css={singleSliderWrapperCSS}>
        <Slider
          onChange={(_, v) => onSliderValueChange(v as number)}
          onChangeCommitted={() => setSliderClicksCount(sliderClicksCount + 1)}
          css={[sliderOverridesCSS, customCSS]}
          aria-label="Always visible"
          value={sliderValue}
          step={FICOSliderConfiguration.step}
          marks={FICOSliderConfiguration.marks}
          max={FICOSliderConfiguration.maxValue}
          min={FICOSliderConfiguration.minValue}
        />
        <div css={optionsWrapperCSS}>
          <span>hate them</span>
          <span>love them</span>
        </div>
      </div>
    </div>
  );
};

export default CarrierSliderInput;
