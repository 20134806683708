import { css } from '@emotion/react';
import { colors } from 'src/theme/variables';

export const disclaimersWrapperCSS = css`
  margin-bottom: 15px;
  white-space: pre-wrap;
`;

export const termsAndPolicyLinkCSS = css`
  border-width: 0.5px;
  border-color: ${colors.black};
  color: ${colors.black};
  :visited {
    border-color: ${colors.black};
    color: ${colors.black};
  }
`;
