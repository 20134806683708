/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';

import { Card } from 'src/shared/components/Card/Card';
import {
  buttonWrapperCSS,
  dividerCSS,
  featuredOfferCSS,
  priceCSS,
  priceDescriptionCSS,
  quoteCardCSS,
  quoteContentCSS,
  quoteInfoCSS,
  quotePieceCSS,
  textButtonCustomCSS,
  productNameCSS,
  policyTypeCSS,
  dividerTextCSS,
  quoteDividerCSS,
  wrapperCSS,
  detailsWrapperCSS,
  detailsLabelCSS,
  carrierDetailsWrapperCSS,
  carrierDetailCSS
} from './QuoteCard.style';
import Button from 'src/kit/Button/Button';
import QuoteLogo from 'src/components/Quotes/QuoteCard/components/QuoteLogo/QuoteLogo';
import { QuoteLogoSize } from 'src/components/Quotes/QuoteCard/components/QuoteLogo/QuoteLogo.types';
import { Divider } from '@mui/material';
import WhiteStarIcon from 'src/assets/svg/white-star-icon.svg?react';
import useResponsive from 'src/hooks/useResponsive';
import { LifeQuote } from 'src/interfaces/IQuotes';
import { LifeQuoteSelectData } from 'src/components/Quotes/Quotes.types';
import { getLifeQuoteSelectedData } from 'src/utils/quoteSelectData';
import DividerIcon from 'src/assets/svg/curvy-divider.svg';
import Expander from 'src/components/Expander/Expander';
import { Fragment } from 'react';
import RatingIcon from 'src/assets/svg/a-m-rating.svg?react';
import FoundedIcon from 'src/assets/svg/founding.svg?react';
import LocationIcon from 'src/assets/svg/location.svg?react';
import { AUTOMATION_TEST_IDS } from 'src/constants/automationTestIds';
interface LifeQuoteProps {
  isFeatured?: boolean;
  isManual?: boolean;
  quote: LifeQuote;
  index?: number;
  onQuoteSelect(ordinal: number, quoteData: LifeQuoteSelectData): void;
  onQuoteDetailsReview(ordinal: number, quoteData: LifeQuoteSelectData): void;
}

const MonthlyPremium = ({ premium }: { premium: number }) => (
  <div data-testid={AUTOMATION_TEST_IDS.QUOTE.PREMIUM}>
    <div css={priceCSS}>
      ${premium}
      <span>/mo</span>
    </div>
    <p css={priceDescriptionCSS}>Monthly Premium</p>
  </div>
);

const QuoteCard: React.FC<LifeQuoteProps> = ({
  isFeatured = false,
  quote,
  onQuoteSelect,
  index,
  isManual = false,
  onQuoteDetailsReview
}) => {
  const { isDesktop } = useResponsive();
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const buttonLabel = isManual ? 'Talk to an Advisor' : 'Choose Quote';

  const isCarriersDetailsAvailable: boolean =
    !!quote?.carrier?.foundation || !!quote?.carrier?.location || !!quote?.carrier?.rating;

  const onQuoteSelectHandle = () => {
    onQuoteSelect(index!, getLifeQuoteSelectedData(quote));
  };

  const onShowDetailsClick = () => {
    setIsDetailsOpen(s => !s);
    !isDetailsOpen && onQuoteDetailsReview(index!, getLifeQuoteSelectedData(quote));
  };

  return (
    <div css={wrapperCSS(isFeatured)} data-testid={AUTOMATION_TEST_IDS.QUOTE.CARD}>
      <Card customCSS={quoteCardCSS(isFeatured)}>
        {isFeatured && (
          <div css={featuredOfferCSS}>
            <WhiteStarIcon />
            Featured Offer
          </div>
        )}

        <div css={quoteContentCSS}>
          <div css={quotePieceCSS}>
            <QuoteLogo
              size={!isDesktop ? QuoteLogoSize.DEFAULT : QuoteLogoSize.AVERAGE}
              logoUrl={quote.carrier?.logo_url}
              logoLabel={quote.carrier?.name}
            />

            {isDesktop && <Divider orientation="vertical" flexItem css={dividerCSS} />}
            {!isDesktop && !!quote.premium?.value && <MonthlyPremium premium={quote.premium?.value} />}
            <div css={quoteInfoCSS}>
              <div css={productNameCSS}>{quote?.policy_name}</div>
              <div css={policyTypeCSS}>{quote?.policy_type}</div>
              {quote?.value_propositions?.map((benefit, index) => <p key={`${benefit}-${index}`}>{benefit}</p>)}
            </div>
          </div>

          <div css={quotePieceCSS}>
            {isDesktop && !!quote.premium?.value && <MonthlyPremium premium={quote.premium?.value} />}

            <div css={buttonWrapperCSS}>
              <Button onClick={onQuoteSelectHandle} data-testid={AUTOMATION_TEST_IDS.QUOTE.SELECT_BUTTON}>
                {buttonLabel}
              </Button>
              {isCarriersDetailsAvailable && (
                <Button variant="text" customCSS={textButtonCustomCSS} onClick={onShowDetailsClick}>
                  {isDetailsOpen ? 'Show Less' : 'Show Details'}
                </Button>
              )}
            </div>
          </div>
        </div>
        {isDetailsOpen && (
          <Expander customCSS={detailsWrapperCSS} isExpanded>
            <div css={detailsLabelCSS} data-testid="life-quote-details">
              About the Company
            </div>
            <div css={carrierDetailsWrapperCSS}>
              {quote?.carrier?.foundation && (
                <div css={carrierDetailCSS}>
                  <FoundedIcon />
                  {quote?.carrier?.foundation}
                </div>
              )}
              {quote?.carrier?.location && (
                <div css={carrierDetailCSS}>
                  <LocationIcon />
                  {quote?.carrier?.location}
                </div>
              )}
              {quote?.carrier?.rating && (
                <div css={carrierDetailCSS}>
                  <RatingIcon />
                  {quote?.carrier?.rating}
                </div>
              )}
            </div>
          </Expander>
        )}
      </Card>
      {isFeatured && (
        <Fragment>
          <img css={quoteDividerCSS} src={DividerIcon} alt="Divider" />
          <p css={dividerTextCSS}>More term life insurance options to consider</p>
        </Fragment>
      )}
    </div>
  );
};

export default QuoteCard;
