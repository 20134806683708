/** @jsxImportSource @emotion/react */
import React, { Fragment } from 'react';

import {
  desktopContainerCSS,
  mobileContainerCSS,
  hoursCSS,
  callActionCSS,
  paragraphCSS,
  callActionMobileCSS,
  phoneImageCSS,
  phoneNumberCSS
} from './HeaderContacts.style';
import Format from 'src/utils/format';
import phoneIcon from 'src/assets/svg/phone.svg';
import { HeaderContactsProps } from 'src/kit/Header/types';
import Anchor from 'src/kit/Anchor/Anchor';
import { AUTOMATION_TEST_IDS } from 'src/constants/automationTestIds';

const noop = () => null;

const HeaderContacts: React.FC<HeaderContactsProps> = ({ phoneNumber, customCSS, onPhoneClick = noop }) => {
  if (!phoneNumber) {
    return null;
  }

  return (
    <Fragment>
      <div css={[customCSS, desktopContainerCSS]}>
        <div css={hoursCSS}>
          <p css={paragraphCSS}>Mon - Fri: 9AM - 8PM ET</p>
          <p css={paragraphCSS}>Sat: 9AM - 1PM ET</p>
        </div>

        <div css={callActionCSS} data-testid={AUTOMATION_TEST_IDS.CALL_MATIC_ADVISOR}>
          <p css={paragraphCSS}>Call a Matic Advisor </p>
          <p css={paragraphCSS}>
            at{' '}
            <Anchor
              anchorType="underline-active"
              href={`tel:${phoneNumber}`}
              onClick={onPhoneClick}
              customCSS={phoneNumberCSS}
            >
              {Format.phone(phoneNumber)}
            </Anchor>
          </p>
        </div>
      </div>
      <div css={[customCSS, mobileContainerCSS]}>
        <a css={callActionMobileCSS} href={`tel:${phoneNumber}`} onClick={onPhoneClick}>
          <img css={phoneImageCSS} src={phoneIcon} alt="Phone" /> <span>Call Us</span>
        </a>
      </div>
    </Fragment>
  );
};

export default HeaderContacts;
