/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';

import {
  actionsCSS,
  cardInnerContentCSS,
  cardInnerCSS,
  containerCSS,
  detailsCSS,
  hideDetailsButtonCSS,
  keyValuesCSS,
  logoCSS,
  tagsWrapperCSS,
  valuesAndActionsCSS
} from './HomeCard.style';
import Expander from 'src/components/Expander/Expander';
import QuoteCardActions from 'src/components/Quotes/QuoteCard/components/QuoteCardActions/QuoteCardActions';
import QuoteLogo from 'src/components/Quotes/QuoteCard/components/QuoteLogo/QuoteLogo';
import { QuoteLogoSize } from 'src/components/Quotes/QuoteCard/components/QuoteLogo/QuoteLogo.types';
import QuoteTags from 'src/components/Quotes/QuoteTags/QuoteTags';
import useResponsive from 'src/hooks/useResponsive';
import QuoteCardKeyValues from 'src/components/Quotes/QuoteCard/components/QuoteCardKeyValues/QuoteCardKeyValues';
import { getHomeQuotesCoveragesGroups } from 'src/utils/quotesPage';
import QuoteCoverages from 'src/components/Quotes/QuoteCoverages/QuoteCoverages';
import OnlineBingLabel from 'src/components/Quotes/OnlineBingLabel/OnlineBingLabel';
import Button from 'src/kit/Button/Button';
import noop from 'src/utils/noop';
import { HomeQuoteCardProps } from './HomeCard.types';

const HomeCard: React.FC<HomeQuoteCardProps> = ({
  customCSS,
  index,
  quote,
  tags,
  highlightPremium = false,
  onCardViewed = noop,
  onQuoteSelect,
  onShowDetailsClicked,
  useMultiPolicyDiscount = false
}) => {
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);

  const { isMobile } = useResponsive();

  useEffect(() => {
    onCardViewed(quote, index as number);
  }, [onCardViewed, quote, index]);

  const onShowDetailsClick = () => {
    if (!isMobile) {
      setIsDetailsOpen(s => !s);
    }
    !isDetailsOpen && onShowDetailsClicked();
  };

  const olbAutomationTestProp = quote.online_bind_url ? { 'data-olbquote': '' } : {};
  return (
    <div css={[containerCSS, customCSS]} data-testid="quote-card" {...olbAutomationTestProp}>
      {!!quote.online_bind_url && <OnlineBingLabel>Buy Directly Online!</OnlineBingLabel>}
      <div css={cardInnerCSS}>
        <QuoteLogo
          customCSS={logoCSS}
          size={isMobile ? QuoteLogoSize.BIG : QuoteLogoSize.DEFAULT}
          logoUrl={quote.carrier.logo_url}
          logoLabel={quote.carrier.name}
        />
        <div css={cardInnerContentCSS}>
          <QuoteTags customCSS={tagsWrapperCSS} tags={tags} />
          <div css={valuesAndActionsCSS}>
            <QuoteCardKeyValues
              customCSS={keyValuesCSS}
              premium={quote.premium}
              deductible={quote.deductible}
              highlightPremium={highlightPremium}
              useMultiPolicyDiscount={useMultiPolicyDiscount}
              multiPolicyPremium={quote.multi_policy_discount_premium}
            />
            <QuoteCardActions
              customCSS={actionsCSS}
              fullWidth={isMobile}
              isDetailsOpen={isDetailsOpen}
              onShowDetailsClick={onShowDetailsClick}
              onChooseClick={onQuoteSelect}
              actionLabel="Choose Quote"
            />
          </div>
        </div>
      </div>
      {isDetailsOpen && (
        <Expander isExpanded>
          <QuoteCoverages customCSS={detailsCSS} groups={getHomeQuotesCoveragesGroups(quote.coverages)} />
          {
            <Button
              customCSS={hideDetailsButtonCSS}
              variant="text"
              aria-expanded="true"
              onClick={() => setIsDetailsOpen(false)}
            >
              Show Less
            </Button>
          }
        </Expander>
      )}
    </div>
  );
};

export default HomeCard;
