export const QUERY_CACHE_KEYS = {
  CONFIG: 'config',
  SESSION: 'session',
  QUOTES: 'quotes',
  QUESTIONS: 'questions',
  SAVINGS_QUOTES: 'savings_quotes',
  DISCLOSURES: 'disclosures',
  LANDING: 'landing',
  ALL_FUNNEL: 'all_funnel',
  PREFERENCES: 'preferences',
  HOME_DETAILS: 'HOME_DETAILS',
  INTERSTITIAL_ANSWERS: 'interstitial_answers',
  SOURCE_DIMENSION: 'source_dimension',
  QUOTES_VIEW_CONFIG: 'quotes_view_config'
};
