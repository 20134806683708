/** @jsxImportSource @emotion/react */
import React from 'react';

import { loader, dot, loaderWrapper } from './Loader.style';
import { LoaderDotProps, LoaderProps } from './types';

const LoaderDot: React.FunctionComponent<LoaderDotProps> = ({ inverted }) => <div css={dot(inverted)} />;

const Loader: React.FunctionComponent<LoaderProps> = ({ inverted = false, fullPage = false, customCSS }) => (
  <div css={[loaderWrapper(fullPage), customCSS]} data-testid="loader">
    <div css={loader}>
      <LoaderDot inverted={inverted} />
      <LoaderDot inverted={inverted} />
      <LoaderDot inverted={inverted} />
    </div>
  </div>
);

export default Loader;
