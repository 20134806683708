import { css, SerializedStyles } from '@emotion/react';
import { colors, header, mq } from 'src/theme/variables';

export const headerCSS = (isDemo: boolean, isBigPhoneExp?: boolean): SerializedStyles => css`
  display: flex;
  align-items: center;
  background-color: ${colors.white};
  width: 100%;
  padding-top: ${isDemo ? header.demoFlowPadding : 8}px;
  padding-bottom: 8px;
  min-height: var(--header-height);
  box-sizing: border-box;

  ${mq[0]} {
    padding-top: ${isDemo && isBigPhoneExp ? header.demoFlowPadding : 12}px;
    padding-bottom: 12px;
  }
`;

export const headerInnerCSS = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: auto;
`;

export const logoContainerCSS = (reverseLogo: boolean, isLiveHeaderVariation: boolean): SerializedStyles => css`
  display: flex;
  align-items: center;
  flex-direction: ${reverseLogo ? 'row-reverse' : 'row'};

  ${isLiveHeaderVariation &&
  css`
    max-width: 70%;

    ${mq[0]} {
      max-width: unset;
    }
  `}
`;

export const dividerCSS = css`
  margin: 0 12px;
  ${mq[0]} {
    margin: 0 20px;
  }
`;

export const partnerLogoCSS = (shouldRenderFullWidth: boolean): SerializedStyles => css`
  max-width: ${shouldRenderFullWidth ? 230 : 205}px;
`;
