import { css, SerializedStyles } from '@emotion/react';
import { text14, text32 } from 'src/theme/typography';
import { colors as c, mq } from 'src/theme/variables';

export const interstitialContainerCSS = (isCustomHeight = false): SerializedStyles => css`
  margin-top: 17px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 17px;
  margin-bottom: 160px;

  ${isCustomHeight &&
  css`
    min-height: 40vh;
  `}

  & > h2 {
    font-weight: 700;
    ${text32};
    margin: 0;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
  }

  ${mq[0]} {
    & > h2 {
      font-size: 32px;
      line-height: 40px;
    }
  }
`;

export const interstitialSubHeadingCSS = css`
  font-weight: 500;
  margin-top: 16px;
  text-align: center;

  & span {
    font-weight: 700;
  }
`;

export const screensContainerCSS = css`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  max-width: 615px;
  width: 100%;
  height: 350px;
  margin-top: 32px;
`;

export const interstitialCardWrapperCSS = (isCurrentScreen: boolean): SerializedStyles => css`
  border: 1px solid ${c.mercury};
  box-shadow: 2px 6px 0 rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  transition: 0.65s ease-in-out;
  transform: translateX(${isCurrentScreen ? 0 : -100}vw);
  opacity: ${isCurrentScreen ? 1 : 0.1};
  padding: 40px 11px;
  z-index: 1;

  & h2 {
    font-size: 20px;
    line-height: 28px;
  }

  ${mq[0]} {
    padding: 49px 80px;
  }
`;

export const iconWrapperCSS = (isFinalScreen = false): SerializedStyles => css`
  background-color: ${c.azure62 + '1a'}; // add opacity 0.1
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--icon-bg-size);
  height: var(--icon-bg-size);
  box-sizing: border-box;
  flex-shrink: 0;

  --icon-bg-size: 72px;

  ${isFinalScreen &&
  css`
    --icon-bg-size: 104px;
    border: 2px solid rgba(64, 141, 247, 0.1);
    background-color: ${c.white};
    margin-bottom: 24px;
  `}
`;

const additionalRangeCSS = css`
  flex-direction: column;
  margin: 24px 0 0;
  gap: 0;
`;

export const controlWrapperCSS = (isRangeSelectComponent: boolean): SerializedStyles => css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 24px;
  width: 100%;
  gap: 24px;

  & > div:first-of-type {
    width: 100%;
  }

  ${mq[0]} {
    flex-direction: row;
    gap: 16px;
    margin-top: 56px;
    ${isRangeSelectComponent && additionalRangeCSS};
  }
`;

export const loadingBlockCSS = (isShortSpacing = false): SerializedStyles => css`
  ${text14};
  line-height: 20px;
  margin-top: ${isShortSpacing ? 11 : 40}px;

  & span {
    font-weight: 700;
  }
`;

export const headingWrapperCSS = (isFinalScreen = false): SerializedStyles => css`
  & p {
    margin-top: ${isFinalScreen ? 16 : 8}px;
    display: ${isFinalScreen ? 'block' : 'none'};
  }

  ${mq[0]} {
    & p {
      display: block;
    }
  }
`;

export const carrierSearchCSS = css`
  margin-bottom: 12px;
`;

export const additionalGlobalStyle = css`
  body {
    overflow-x: hidden;
  }
`;
