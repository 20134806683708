import { css } from '@emotion/react';
import { colors as c, header } from 'src/theme/variables';

export const demoFlowIndicatorCSS = css`
  width: 100%;
  text-align: center;
  background: linear-gradient(123.38deg, ${c.cheriseLight} 11.91%, ${c.peachpink} 100.01%);
  font-size: 12px;
  line-height: 16px;
  box-sizing: border-box;
  padding: 3px 0;
  position: fixed;
  color: white;
  font-weight: 700;
  z-index: 99999;
  top: var(--browser-update-height);
`;

export const demoFlowUpdatedGlobalStylesCSS = css`
  :root {
    --demo-flow-space: ${header.demoFlowPadding}px;
  }
`;
