import { css } from '@emotion/react';
import { text20 } from 'src/theme/typography';
import { mq, colors as c } from 'src/theme/variables';

export const containerCSS = css`
  border-radius: 8px;
  border: 1px solid ${c.nobel};
  background: ${c.white};
  box-shadow: 2px 4px 0px 0px rgba(0, 0, 0, 0.12);
  padding: 24px 32px;
  text-align: center;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin: 24px;

  ${mq[0]} {
    margin: auto;
    flex-direction: row;
    max-width: 65%;
    text-align: left;
    margin-bottom: 70px;
  }
`;

export const headingCSS = css`
  font-weight: bold;
  margin-bottom: 8px;
  ${text20};
`;

export const homeIconCSS = css`
  margin-right: 15px;
`;

export const buttonCSS = css`
  width: 100%;
  margin-top: 24px;

  ${mq[0]} {
    margin-top: 0;
    width: auto;
  }
`;

export const subheadingCSS = css`
  ${text20};
`;

export const homeOnlyHeadingCSS = css`
  font-weight: bold;
  margin-bottom: 8px;
`;

export const quotesContainerCSS = css`
  margin: auto;
  margin-bottom: 24px;
  ${mq[1]} {
    max-width: 1000px;
  }
`;

export const homeCardCSS = css`
  margin-bottom: 16px;
  padding: 24px;
`;

export const textContentCSS = css`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mq[0]} {
    flex-direction: row;
  }
`;
