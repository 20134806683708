/** @jsxImportSource @emotion/react */
import React from 'react';

import { BundleQuoteTypeDetailsProps } from './BundleQuoteTypeDetails.types';
import { container, icon, images, premiumContainer, premiumLabel, premiumValue } from './BundleQuoteTypeDetails.style';
import coverageHome from 'src/assets/svg/coverage-home.svg';
import coverageAuto from 'src/assets/svg/coverage-auto.svg';
import QuoteLogo from 'src/components/Quotes/QuoteCard/components/QuoteLogo/QuoteLogo';
import { QuoteLogoSize } from 'src/components/Quotes/QuoteCard/components/QuoteLogo/QuoteLogo.types';

const BundleQuoteTypeDetails: React.FC<BundleQuoteTypeDetailsProps> = ({ customCSS, type, premium, logo, testId }) => {
  return (
    <div css={[container, customCSS]}>
      <div css={images}>
        <img css={icon} src={type === 'home' ? coverageHome : coverageAuto} alt={type === 'home' ? 'Shield' : 'Auto'} />
        <QuoteLogo size={QuoteLogoSize.SMALL} logoUrl={logo.url} logoLabel={logo.label} />
      </div>
      <div css={premiumContainer}>
        <div css={premiumValue} data-testid={testId}>
          {premium.value}
        </div>
        <div css={premiumLabel}>{premium.label}</div>
      </div>
    </div>
  );
};

export default BundleQuoteTypeDetails;
