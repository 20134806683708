import { css, SerializedStyles } from '@emotion/react';
import { colors } from 'src/theme/variables';

export const moneyWrapperCSS = css`
  position: relative;
`;

export const moneySignCSS = (hasError = false): SerializedStyles => css`
  left: 20px;
  position: absolute;
  top: 21px;
  color: ${colors.black};
  z-index: 2;

  ${hasError &&
  css`
    color: ${colors.red};
  `};
`;
