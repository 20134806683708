import { useRef, useEffect } from 'react';

import { scrollToNode } from 'src/utils/scroll';
import { ScrollPathItem, UseFormScroll } from './useFormScroll.types';
import { arrayContainsArray } from 'src/utils/arrayHelpers';
import { getCurrentScrollIndex, getScrollPath, isScrollPathChanged } from './useFormScroll.utils';
import { hasAnswers } from 'src/questionsForm/utils/questionsForm.utils';

const useFormScroll: UseFormScroll = (questions, answers, touchedFields, formPrefix = '') => {
  const path = useRef<ScrollPathItem[]>(getScrollPath([], questions, answers));

  useEffect(() => {
    if (isScrollPathChanged(path.current, questions)) {
      path.current = getScrollPath(path.current, questions, answers);
    }
    const lastScrolledItemIndex = path.current.map(item => item.scrolled).lastIndexOf(true);
    const currentIndex = getCurrentScrollIndex(lastScrolledItemIndex, path.current);

    const currentScrollItem = path.current[currentIndex];
    const nextScrollItem = path.current[currentIndex + 1];

    if (!currentScrollItem || !nextScrollItem || !answers) return;

    const allFieldsInQuestionTouched = arrayContainsArray(
      touchedFields || [],
      currentScrollItem.fields.map(f => f.key_name) || []
    );
    const currentQuestionFieldsHasAnswers = hasAnswers(currentScrollItem.fields || [], answers);
    const nextQuestionFieldsAllTouched = arrayContainsArray(
      touchedFields || [],
      nextScrollItem.fields.map(f => f.key_name) || []
    );

    if (
      allFieldsInQuestionTouched &&
      currentQuestionFieldsHasAnswers &&
      !nextQuestionFieldsAllTouched &&
      currentScrollItem.canTriggerScroll
    ) {
      // Weird scroll bug with react-input-mask
      setTimeout(
        () => scrollToNode(formPrefix ? `${formPrefix}.${nextScrollItem.key_name}` : nextScrollItem.key_name),
        0
      );

      path.current = path.current.map((item, index) => (index <= currentIndex ? { ...item, scrolled: true } : item));
    }
  }, [touchedFields, questions, answers, formPrefix]);
};

export default useFormScroll;
