import { css, SerializedStyles } from '@emotion/react';
import { colors as c, mq } from 'src/theme/variables';

export const LoaderCardCSS = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px;

  div {
    height: 50px;
    width: 100px;
    background-color: ${c.nobel};
    margin: 0 20px;
  }
`;

export const wrapperCSS = css`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  text-align: center;
`;

export const cardWrapperCSS = css`
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin-top: 12px;
  height: 322px;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;

  ${mq[1]} {
    flex-direction: row;
    height: 140px;

    & > div:first-of-type {
      margin-right: 10%;
    }
  }
`;

export const miniColumnCSS = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const loaderBlockCSS = (height: number, width: number, roundBorders?: boolean): SerializedStyles => css`
  height: ${height}px;
  width: ${width}px;
  margin: 6px 14px;
  border-radius: 4px;

  ${roundBorders &&
  css`
    border-radius: 10px;
  `}
`;

export const loaderGradientCSS = css`
  background-color: ${c.nobel};
  background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    )
    ${c.nobel};
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 2s loading ease-in-out infinite;

  @keyframes loading {
    to {
      background-position-x: -20%;
    }
  }
`;

export const headingCSS = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${mq[1]} {
    flex-direction: row;
  }

  div {
    margin-right: 12px;
  }
`;
