/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';

import gsap from 'gsap';
import { useParams } from 'react-router-dom';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import {
  heading as headingCSS,
  faq as faqCSS,
  headingWrapper,
  scene,
  sceneInner,
  ballOne,
  ballTwo,
  img,
  accordionWrapper,
  sceneWrapper,
  curl2 as curl2CSS,
  arrowCurved as arrowCurvedCSS,
  idea as ideaCSS,
  twoLines as twoLinesCSS
} from './FAQ.style';
import FAQConstants from './FAQ.constants';
import FAQUtils from './FAQ.utils';
import SEGMENT from 'src/constants/segment';
import analytics from 'src/utils/analytics';

import curl2 from 'src/assets/svg/curl2.svg';
import arrowCurved from 'src/assets/svg/arrow-curved.svg';
import idea from 'src/assets/svg/heart-thought.svg';
import twoLines from 'src/assets/svg/two-lines.svg';
import Accordion from 'src/shared/components/Accordion/Accordion';
import noop from 'src/utils/noop';
import { FAQProps } from './FAQ.types';

import { DefaultLocation } from 'src/interfaces/IPage';
import { AccordionItem } from 'src/shared/components/Accordion/Accordion.types';

const FAQ: React.FC<FAQProps> = ({
  page,
  customCSS,
  onFaqClick = noop,
  heading = 'Quick questions we can answer',
  accordionItems = []
}) => {
  const { flow, gid } = useParams() as DefaultLocation;

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.to('#ballOne', FAQUtils.getAnimationConfig('#ballOne', -40));
    gsap.to('#ballTwo', FAQUtils.getAnimationConfig('#ballTwo', -40));
    gsap.to('#curl2', FAQUtils.getAnimationConfig('#curl2', -90));
    gsap.to('#idea', FAQUtils.getAnimationConfig('#idea', -70));
    gsap.to('#twoLines', FAQUtils.getAnimationConfig('#twoLines', -90));
  }, []);

  const onAccordionClick = ({ title }: AccordionItem, ordinal: number) => {
    analytics.track(SEGMENT.FAQ_CLICKED, gid, flow, {
      ordinal,
      question_text: title,
      page
    });

    onFaqClick({ title, ordinal });
  };

  return (
    <section css={[faqCSS, customCSS]}>
      <div css={headingWrapper}>
        <h2 css={headingCSS}>{heading}</h2>
      </div>

      <div css={sceneWrapper}>
        <div css={scene}>
          <div css={sceneInner}>
            <div css={ballOne} id="ballOne" />
            <div css={ballTwo} id="ballTwo" />
            <img
              css={img}
              src="https://maticinsurance.sirv.com/customer-experience/faq.png"
              alt="Portrait of a person"
              loading="lazy"
            />
          </div>
          <img css={curl2CSS} src={curl2} alt="Curl" id="curl2" loading="lazy" />
          <img css={arrowCurvedCSS} src={arrowCurved} alt="Curved Arrow" id="curvedArrow" loading="lazy" />
          <img css={ideaCSS} src={idea} alt="Idea" id="idea" loading="lazy" />
          <img css={twoLinesCSS} src={twoLines} alt="Two Lines" id="twoLines" loading="lazy" />
        </div>
      </div>

      <div css={accordionWrapper}>
        <Accordion
          items={accordionItems?.length > 0 ? accordionItems : FAQConstants.items}
          onChange={onAccordionClick}
        />
      </div>
    </section>
  );
};

export default FAQ;
