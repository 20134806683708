/** @jsxImportSource @emotion/react */
import React, { Fragment, useState } from 'react';

import { headingCSS } from './FileUploadList.style';
import { FileUploadListProps } from './FileUploadList.types';
import Button from 'src/kit/Button/Button';
import FileUploadListItem from './FileUploadListItem/FileUploadListItem';
import FileUploadDialog from './FileUploadDialog/FileUploadDialog';
import { dialogActionsItemCSS } from './FileUploadDialog/FileUploadDialog.style';
import { UploadFile } from './FileUploadListItem/FileUploadListItem.types';

const FileUploadList: React.FC<FileUploadListProps> = ({ files = [], onRemoveFile, onRetryUpload }) => {
  const [dialogOpenFile, setDialogOpenFile] = useState<UploadFile | null>(null);

  const onHandleDelete = (file: UploadFile) => {
    onRemoveFile(file);
    setDialogOpenFile(null);
  };

  const renderConfirmRemoveDialog = () => (
    <FileUploadDialog
      isOpen={!!dialogOpenFile}
      title="Remove file?"
      description="This file will be permanently removed and not uploaded to Matic."
      onClose={() => setDialogOpenFile(null)}
    >
      <Fragment>
        <Button css={dialogActionsItemCSS} type="button" variant="secondary" onClick={() => setDialogOpenFile(null)}>
          Cancel
        </Button>
        <Button css={dialogActionsItemCSS} type="button" onClick={() => onHandleDelete(dialogOpenFile as UploadFile)}>
          Remove
        </Button>
      </Fragment>
    </FileUploadDialog>
  );

  return (
    <div>
      {!!files.length && <p css={headingCSS}>Uploaded Files:</p>}
      <div>
        {files.map((file, i) => (
          <FileUploadListItem
            key={`${file.file_name}_${i}`}
            onRetryUpload={() => onRetryUpload(file)}
            onRemoveFile={() => setDialogOpenFile(file)}
            file={file}
          />
        ))}
      </div>
      {renderConfirmRemoveDialog()}
    </div>
  );
};

export default FileUploadList;
