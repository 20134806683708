/** @jsxImportSource @emotion/react */
import React from 'react';
import { calcAbsDiffPercentage } from 'src/utils/percentageCalculation';
import bellIcon from 'src/assets/svg/bell-icon.svg';
import { containerCSS, headerCSS, nameCSS, titleDescriptionCSS, titleHighlightCSS } from './SavingsHead.style';
import { ICurrentPolicy, SavingsQuote } from 'src/interfaces/IQuotes';
import { Person } from 'src/interfaces/ISavings';
import { getDayString } from 'src/utils/stringHelpers';
import { FS_SENSITIVE_DATA_CLASS } from 'src/constants/fullStory';

interface Props {
  person: Person;
  currentPolicy: ICurrentPolicy;
  activeQuote: SavingsQuote;
  showExpirationDate?: boolean;
  isPreScoreVariation?: boolean;
  predictedPremiumChange?: number | null;
}

const SavingsHead: React.FC<Props> = ({
  person,
  currentPolicy,
  activeQuote,
  showExpirationDate = true,
  isPreScoreVariation,
  predictedPremiumChange
}) => {
  const expiredDays = activeQuote.days_till_quote_expired <= 0 ? 1 : activeQuote.days_till_quote_expired;
  const savingsInPercentage = calcAbsDiffPercentage(currentPolicy.premium!, activeQuote.premium.value);

  return (
    <div css={containerCSS}>
      <div className={FS_SENSITIVE_DATA_CLASS.MASK} css={nameCSS}>
        {person.first_name},
      </div>
      <div css={headerCSS(isPreScoreVariation)}>
        {isPreScoreVariation ? (
          'Avoid higher premiums at renewal'
        ) : (
          <span>
            Matic Found a Homeowners Insurance Quote That Can Save You{' '}
            {savingsInPercentage < 1 ? 'Money' : <span css={titleHighlightCSS}>up to {savingsInPercentage}%</span>}
          </span>
        )}
      </div>

      {isPreScoreVariation && predictedPremiumChange && (
        <div css={titleDescriptionCSS(isPreScoreVariation)}>
          Your premium is likely to increase ${predictedPremiumChange} at your next renewal. Let Matic help you save by
          shopping 40+ A-rated carriers.
        </div>
      )}
      {showExpirationDate && !isPreScoreVariation && (
        <div css={titleDescriptionCSS()}>
          <img src={bellIcon} width="24" alt="Bell" />
          <span>This quote expires in less than {getDayString(expiredDays)}.</span>
        </div>
      )}
    </div>
  );
};

export default SavingsHead;
