import { css, SerializedStyles } from '@emotion/react';
import { colors as c, mq, typography } from 'src/theme/variables';
import { FormQuestionDirections } from 'src/interfaces/IQuestion';

export const contentCSS = (): React.CSSProperties => {
  return {
    left: 0,
    top: 0,
    position: 'absolute',
    maxWidth: 550,
    overflowX: 'hidden',
    overflowY: 'scroll',
    minHeight: '100%'
  };
};

export const overlayCSS: React.CSSProperties = {
  padding: 0
};

export const containerCSS = css`
  .MuiFormControlLabel-label {
    font-family: ${typography.fontFamily};
  }

  box-sizing: border-box;
  border-radius: 0;
`;

export const wrapperCSS = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const coverageCSS = css`
  background: ${c.grayFive};
  margin: 0 -40px -40px;
  padding: 20px 50px;
  flex-shrink: 1;

  ${mq[0]} {
    height: 100%;
    padding: 50px;
  }
`;

export const closeCSS = css`
  display: flex;
  align-items: center;
  cursor: pointer;

  path {
    stroke: ${c.black};
  }
`;

export const headerCSS = css`
  display: flex;
  justify-content: end;
  margin-top: 10px;
`;

export const questionHeadingCSS = css`
  font-weight: 700;
  color: black;
  font-size: 24px;
  margin-bottom: 12px;
  margin-top: 40px;
`;

const horizontal = css`
  flex-wrap: wrap;

  .radio-button-label {
    flex: 1 1 auto;
    width: calc(50% - 120px);

    + .radio-button-label {
      margin-top: 0;
      margin-left: 8px;
      ${mq[0]} {
        margin-left: 20px;
      }
    }
  }
`;

const vertical = css`
  flex-direction: column;
`;

export const questions = (direction: FormQuestionDirections = FormQuestionDirections.vertical): SerializedStyles => css`
  display: flex;
  ${direction === FormQuestionDirections.horizontal ? horizontal : vertical};
`;

export const checkBoxWrapperCSS = css`
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  border-radius: 6px;
  margin: 8px 0;
  padding: 20px;
`;

export const radioCSS = (width: string): SerializedStyles => css`
  border: 1px solid black;
  border-radius: 4px;
  padding: 10px 20px 10px 0;
  margin: 0;
  width: ${width};
`;

export const radioRowCSS = css`
  width: 100%;
  justify-content: space-between;
`;

export const mainCheckBoxCSS = (bottomSpace: boolean): SerializedStyles => css`
  ${bottomSpace &&
  css`
    margin-bottom: 24px;
  `}
`;

export const borderCSS = (bottomSpace: boolean): SerializedStyles => css`
  ${bottomSpace &&
  css`
    border: 2px solid #156eea;
  `}
`;

export const checkBoxCSS = css`
  display: flex;
`;

export const buttonCSS = css`
  min-width: 100%;
  width: 100%;
  margin-top: 20px;
`;

export const errorCSS = css`
  font-size: 14px;
  color: ${c.red};
`;

export const emptyBorder = css`
  border: none;
`;

export const radioWrapperCSS = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 12px;
  border-radius: 4px;
  border: 1px solid black;

  label {
    margin-left: 0;
    border: none;
  }
`;
