import { css } from '@emotion/react';
import { mq } from 'src/theme/variables';
import { text, text14, text20 } from 'src/theme/typography';

export const container = css`
  display: flex;
  align-items: baseline;
  ${mq[0]} {
    flex-direction: column;
    align-items: flex-end;
    flex-basis: 160px;
    flex-shrink: 0;
  }
  ${mq[1]} {
    flex-basis: auto;
  }
`;

export const label = css`
  ${text20};
  ${mq[0]} {
    ${text};
    text-align: right;
  }
`;

export const button = css`
  ${text};
  font-weight: bold;
  margin-left: 12px;
  ${mq[0]} {
    ${text14};
    margin-left: 0;
    text-align: right;
  }
`;

export const dialog = css`
  padding: 0 0 40px;
  ${mq[0]} {
    padding: 40px;
  }
`;

export const actions = css`
  text-align: right;
  margin-top: 24px;
`;
