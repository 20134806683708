/** @jsxImportSource @emotion/react */
import React, { useRef, Fragment } from 'react';

import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';

import { FormFieldProps } from 'src/interfaces/IQuestion';

import AddIcon from 'src/assets/svg/add.svg';
import SubtractIcon from 'src/assets/svg/subtract.svg';
import Input from 'src/shared/components/Input/Input';
import { button, quantityWrapper, quantityInput, quantityInputWrapper } from './QuantityInput.style';

const QuantityInput: React.FC<FormFieldProps> = ({ key_name, validation, isDisabled, ariaLabel }) => {
  const { getValues, setValue } = useFormContext();

  const changeValue = (delta: number) => {
    if (isDisabled) return;
    const currentValue = Number.parseInt(getValues(key_name) || '0', 10);
    if (!currentValue && delta < 0) return;

    const newValue = Math.max(currentValue + delta, 0);
    setValue(key_name, newValue);
  };

  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div css={quantityWrapper}>
      <Controller
        name={key_name}
        rules={validation?.static as RegisterOptions}
        defaultValue={getValues(key_name) || 0}
        render={({ field }) => (
          <Fragment>
            <button
              css={button}
              type="button"
              aria-label="Decrease"
              onClick={() => {
                changeValue(-1);
              }}
            >
              <img src={SubtractIcon} alt="Decrease" />
            </button>
            <div css={quantityInputWrapper}>
              <Input
                {...field}
                customCSS={quantityInput}
                ref={inputRef}
                type="number"
                name={key_name}
                id={key_name}
                aria-label={ariaLabel}
                disabled={isDisabled}
                onChange={e => {
                  field.onChange(Math.max(parseInt(e.target.value || '0', 10), 0));
                }}
                onFocus={() => {
                  if (!field.value && inputRef.current) inputRef.current.value = '';
                }}
                onBlur={() => {
                  (inputRef.current || { value: null }).value = field.value;
                }}
                data-testid="quantity-input"
              />
            </div>
            <button
              css={button}
              type="button"
              aria-label="Increase"
              onClick={() => {
                changeValue(1);
              }}
            >
              <img src={AddIcon} alt="Increase" />
            </button>
          </Fragment>
        )}
      />
    </div>
  );
};

export default QuantityInput;
