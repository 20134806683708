import { SerializedStyles, css } from '@emotion/react';
import { colors as c, mq } from 'src/theme/variables';
import { text, text14, text20, text24, text40, text48, text12, text32 } from 'src/theme/typography';
import wavyBackgroundBlue from 'src/assets/images/wavy-bg-blue.png';

import DetailsBg from 'src/assets/svg/detailsBg.svg';
import { tooltipArrowCSS, tooltipCSS } from 'src/shared/components/Tooltip/Tooltip.style';
import { TooltipComponentsPropsOverrides } from '@mui/material';
import MobileDetailsBg from 'src/assets/svg/bndl-mobile-bg.svg';

export const heroSectionCSS = css`
  background-image: none;

  ${mq[0]} {
    background: url(${wavyBackgroundBlue}) 20% 100% no-repeat;
    background-size: cover;
  }
`;

export const offerDetailsCSS = css`
  padding: 20px;

  ${mq[1]} {
    min-height: unset;
    padding: 24px;
  }
`;

export const detailsBlockCSS = css`
  ${mq[0]} {
    padding-bottom: 0;
  }

  p {
    ${text12};
    margin-top: 4px;

    ${mq[0]} {
      ${text14};
      margin: unset;
    }
  }
`;

export const savingsCardCSS = css`
  min-width: 52%;
  border-radius: 8px;
  position: relative;
  border: 1px solid #e6e6e6;

  ${mq[0]} {
    border-radius: 8px;
    border-right-width: 1px;
    border-left-width: 1px;
    margin-left: 0;
    margin-right: 0;
  }

  ${mq[1]} {
    width: 50%;
  }
`;

export const cardTitleCSS = css`
  ${text};
  margin-top: 0;
  margin-bottom: 8px;

  ${mq[0]} {
    ${text24};
  }

  ${mq[1]} {
    margin-bottom: 2px;
  }
`;

export const mobileBenefitsWrapperCSS = css`
  padding: 32px 0;
`;

export const benefitsTitleCSS = css`
  ${text};

  ${mq[0]} {
    ${text};
  }
`;

export const benefitsWrapperCSS = css`
  ${mq[0]} {
    margin-top: 40px;
  }
`;

export const carrierCustomCSS = css`
  background-color: ${c.white};

  ${mq[0]} {
    background-color: ${c.white};
  }
`;

export const BBBIconCSS = css`
  margin-top: 20px;
  ${text14};

  ${mq[0]} {
    margin-top: 32px;
  }
`;

export const bundleWrapperCSS = css`
  display: flex;
  height: 259px;
  border-bottom: 1px solid ${c.mercury};

  ${mq[0]} {
    height: 160px;
    border-bottom: none;
  }

  ${mq[1]} {
    height: 210px;
  }
`;

export const bundleMobileWrapperCSS = (
  isMPD?: boolean,
  isToggleOn?: boolean,
  isSingleQuoteVariation?: boolean
): SerializedStyles => css`
  display: flex;
  flex-direction: column;
  height: 300px;
  border-bottom: 1px solid ${c.mercury};
  background-color: ${c.powderBlue};
  position: relative;
  overflow: hidden;
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;

  ${mq[0]} {
    height: 280px;
  }

  ${mq[1]} {
    height: 300px;
  }

  ${isSingleQuoteVariation &&
  css`
    border-bottom: none;

    ${mq[0]} {
      height: 280px;
    }
  `};

  ${!isToggleOn &&
  css`
    height: 230px;

    ${mq[0]} {
      height: 220px;
    }
  `};

  ${isSingleQuoteVariation &&
  !isMPD &&
  css`
    height: 230px;

    ${mq[0]} {
      height: 220px;
    }
  `};
`;

export const bndlImgCSS = css`
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
`;

export const detailsWrapperCSS = (isSingleQuoteVariation?: boolean, isMPD?: boolean): SerializedStyles => css`
  height: 100%;
  width: 50%;
  background-image: url(${DetailsBg});
  background-size: cover;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 999;
  position: relative;

  img {
    margin-top: 32px;
  }

  ${isMPD &&
  css`
    ${mq[0]} {
      height: 90%;
    }

    ${mq[1]} {
      height: 100%;
    }
  `}

  ${isSingleQuoteVariation &&
  css`
    width: 100%;

    ${mq[1]} {
      width: 50%;
    }
  `}
`;

export const mobileDetailsWrapperCSS = (isSingleQuoteVariation?: boolean): SerializedStyles => css`
  display: flex;
  flex-direction: column;
  background-image: url(${MobileDetailsBg});
  background-size: cover;
  background-position: center;
  padding: 20px;

  ${isSingleQuoteVariation &&
  css`
    padding: 50px 0;
  `}

  height: 90%;
  z-index: 9;
`;

export const mobileInnerWrapperCSS = (
  isMPD?: boolean,
  isToggleOn?: boolean,
  isSingleQuoteVariation?: boolean
): SerializedStyles => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;

  ${isSingleQuoteVariation &&
  isMPD &&
  isToggleOn &&
  css`
    padding-top: 95px;
  `}

  ${mq[0]} {
    padding-top: 61px;
  }

  ${mq[1]} {
    padding-top: 105px;
  }
`;

export const basedCSS = css`
  margin-top: 8px;
  ${text14};

  ${mq[0]} {
    margin-top: 10px;
    ${text};
  }
`;

export const bundleMsgWrapperCSS = css`
  margin-top: 20px;
  line-height: 18px;
  display: flex;
  text-align: left;
  ${text14};

  ${mq[0]} {
    margin-top: 39px;
  }

  img {
    margin-right: 20px;
  }

  b {
    ${text14};

    ${mq[0]} {
      ${text};
    }
  }
`;

export const headerCSS = css`
  ${text24};
  font-weight: 700;

  b {
    color: ${c.darkGreen};
    font-weight: 700;
  }

  ${mq[0]} {
    ${text40};
  }
`;

export const containerCSS = css`
  text-align: center;

  ${mq[0]} {
    text-align: left;
  }
`;

export const nameCSS = css`
  ${text};
  line-height: 24px;
  margin-bottom: 4px;

  ${mq[0]} {
    ${text20};
    margin-bottom: 8px;
  }
`;

export const savingsTagCSS = css`
  border-radius: 40px;
  background: #cdf1cd;
  padding: 4px 12px;
  font-size: 12px;
  margin-top: 12px;
  text-align: center;
`;

export const savingsValueCSS = (isMPD?: boolean): SerializedStyles => css`
  ${text32};
  font-weight: 700;
  ${!isMPD &&
  css`
    margin-top: 11px;
  `}

  ${mq[0]} {
    ${text48};
    line-height: 1;
    font-weight: 900;
  }
`;

export const savingsValueWrapper2CSS = css`
  ${text};
  text-align: center;
  display: flex;
  margin-top: 14px;
  align-items: center;

  ${mq[0]} {
    display: block;
    margin-top: 0;
  }
`;

export const savingsValue2CSS = css`
  ${text32};
  margin-left: 12px;
  color: #2f8802;
  font-weight: 700;

  ${mq[0]} {
    ${text24};
  }

  ${mq[1]} {
    ${text40};
  }

  span {
    ${text24};
  }
`;

export const savingsValueSuffixCSS = css`
  ${text};
  font-weight: 700;

  ${mq[0]} {
    ${text20};
  }
`;

export const infoIconCSS = css`
  cursor: pointer;
  margin-left: 8px;
  margin-top: 4px;
`;

export const bndlCheckboxWrapperCSS = css`
  display: flex;
  width: 100%;
  align-items: center;

  .MuiButtonBase-root {
    margin-left: -8px;
    position: relative;
    z-index: 0;

    :after {
      z-index: -1;
      position: absolute;
      content: '';
      height: 18px;
      width: 18px;
      background-color: ${c.white};
      border-radius: 3px;
    }
  }

  ${mq[0]} {
    margin-top: -8px;

    .MuiButtonBase-root {
      margin-top: 0;
    }
  }

  span {
    ${text14};

    ${mq[0]} {
      ${text};
    }
  }
`;

export const tooltipCustomStyles = (isMobile: boolean): TooltipComponentsPropsOverrides => ({
  tooltip: {
    sx: {
      ...tooltipCSS,
      maxWidth: `${isMobile ? 245 : 356}px`,
      fontSize: '14px',
      lineHeight: '20px',
      color: c.black,
      border: `1px solid ${c.silver}`,
      padding: '20px',
      boxShadow: '2px 4px 0px 0px rgba(0, 0, 0, 0.12)'
    }
  },
  arrow: {
    sx: {
      ...tooltipArrowCSS,
      '&::before': {
        border: `1px solid ${c.silver}`,
        backgroundColor: '#fff',
        boxSizing: 'border-box'
      }
    }
  }
});

export const savingsDescriptionCSS = (isMPD?: boolean): SerializedStyles => css`
  color: ${c.dove};
  ${text12};

  ${mq[0]} {
    ${text14};
    margin-top: 4px;
  }
  ${isMPD &&
  css`
    text-decoration: line-through;
  `}
`;

export const hiddenCSS = css`
  opacity: 0;
  transition: opacity 0.8s ease;
`;

export const imgBgCSS = (alignment: string, bundleActive?: boolean): SerializedStyles => css`
  position: absolute;
  ${alignment === 'right' &&
  css`
    right: 0;
    border-top-right-radius: 7px;
  `}

  ${alignment === 'left' &&
  css`
    left: 0;
    border-top-left-radius: 7px;
  `}

  ${bundleActive &&
  css`
    opacity: 1;
  `}
`;

export const mobileCarBndlCSS = (isActive: boolean, isMP: boolean): SerializedStyles => css`
  position: absolute;
  right: -128px;
  transition: right 0.55s ease;

  ${isMP &&
  css`
    top: 52px;
  `}

  ${!isMP &&
  !isActive &&
  css`
    top: 32px;
  `}

  ${!isMP &&
  isActive &&
  css`
    top: 30px;
  `}

  ${isActive &&
  css`
    right: 22px;
    transition: right 0.25s ease;

    @media screen and (min-width: 845px) {
      right: 7%;
    }

    @media screen and (min-width: 850px) {
      top: 37px;
    }
  `};
`;

export const mobileHomeBndlCSS = (isActive: boolean, isMP: boolean): SerializedStyles => css`
  position: absolute;
  top: 39px;
  left: 38%;
  transition: left 0.25s ease;

  ${!isActive &&
  css`
    top: 13px;
  `}

  ${!isMP &&
  isActive &&
  css`
    top: 18px;
  `}

  @media screen and (min-width: 900px) {
    top: 11px;
  }

  ${isActive &&
  css`
    left: 12%;
    transition: left 0.25s ease;

    @media screen and (min-width: 970px) {
      top: 20px;
    }
  `};
`;

export const hero2WrapperCSS = css`
  justify-content: space-between;
  display: flex;
  align-items: center;
  flex-direction: column;

  ${mq[0]} {
    flex-direction: row;
  }
`;

export const premiumsWrapperCSS = css`
  margin-top: 20px;

  ${mq[0]} {
    margin-top: 24px;
  }

  div {
    margin: 4px 0;

    > span:first-of-type {
      color: black;
      ${text}
    }

    > span:last-of-type {
      font-weight: 500;
    }
  }

  div:first-of-type {
    span {
      ${mq[0]} {
        ${text20}
      }
    }
  }

  div:last-of-type {
    color: black;
    margin: 4px 0;

    span {
      ${text14}

      ${mq[0]} {
        ${text}
      }
    }
  }
`;

export const hero2CSS = () => css`
  height: 220px;
  padding: 20px;
  background-color: #e1f1ff;
  position: relative;
  border-radius: 8px;

  ${mq[0]} {
    padding: 23px;
  }

  ${mq[1]} {
    height: 120px;
    padding: 32px;
  }
`;

export const img2CSS = (isDisabled: boolean, isToggleOn: boolean): SerializedStyles => css`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 224px;

  ${mq[1]} {
    right: 7%;
    margin: auto unset;
    left: unset;
    width: 140px;
  }

  @media screen and (min-width: 1120px) {
    width: 170px;
  }

  @media screen and (min-width: 1230px) {
    width: unset;
  }

  ${isDisabled &&
  css`
    z-index: 2;
    opacity: 1;
    transition: opacity 0.25s ease;

    ${isToggleOn &&
    css`
      opacity: 0;
      transition: opacity 0.25s ease;
    `}
  `}
`;

export const headingCSS = css`
  ${text};
  color: #333333;
  text-align: center;
  font-weight: 700;

  ${mq[0]} {
    text-align: center;
    ${text}
  }

  ${mq[1]} {
    width: 66%;
    text-align: left;
    margin-bottom: 10px;
    font-weight: 700;
    ${text20}
  }

  ${mq[2]} {
    width: 60%;
  }
`;

export const subWrapperCSS = css`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;

  ${mq[1]} {
    max-width: unset;
    justify-content: center;
  }

  p {
    ${text12};
    margin-top: -4px;

    ${mq[0]} {
      ${text14};
      margin: unset;
    }
  }
`;

export const headerWrapperCSS = (isToggleOn: boolean, isMultiPolicyOn: boolean): SerializedStyles => css`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 83%;

  ${mq[1]} {
    flex-wrap: wrap;
    margin-top: 50px;
    justify-content: space-around;
    width: 83%;
  }

  ${isToggleOn &&
  isMultiPolicyOn &&
  css`
    ${mq[1]} {
      margin-top: 60px;
    }
  `}

  ${isMultiPolicyOn &&
  css`
    ${mq[1]} {
      margin-top: 15px;

      @media screen and (min-width: 1200px) {
        margin-top: 20px;
      }

      @media screen and (min-width: 1230px) {
        margin-top: 35px;
      }
    }
  `}

  img,
  div {
    margin-top: 0;
    margin-bottom: 4px;
  }
`;

export const MPTagCSS = css`
  color: white;
  border-radius: 40px;
  background: ${c.violet};
  padding: 4px 12px;
  font-weight: 700;
  ${text12};
  text-align: center;
  margin-top: 12px;
`;

export const MPWrapperCSS = (isMultiPolicyOn: boolean): SerializedStyles => css`
  text-align: center;
  ${isMultiPolicyOn &&
  css`
    color: ${c.violet};
  `}
`;
