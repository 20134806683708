/** @jsxImportSource @emotion/react */

import { Card } from 'src/shared/components/Card/Card';
import HistoricalPremiumChart from './HistoricalPremiumChart/HistoricalPremiumChart';
import {
  cardCustomCSS,
  cardCarrierCSS,
  cardNameCSS,
  cardTitleCSS,
  chartWrapperCSS,
  historicalWrapperCSS,
  cardAddressCSS,
  cardInfoSectionCSS,
  historicalPremiumTitleCSS,
  historicalPremiumDescCSS,
  textWrapperCSS,
  historicalPremiumContainerCSS,
  chartTitleCSS
} from './HistoricalPremium.style';
import StyledDashedRow from './StyledDashedRow';
import { HistoricalPremiumDto } from 'src/interfaces/session.types';
import Format from 'src/utils/format';
import { AnswerValue } from 'src/interfaces/IQuestion';
import HistoricalPremiumTooltip from './HistoricalPremiumTooltip';
import { useEffect } from 'react';
import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';
import { useParams } from 'react-router-dom';
import { DefaultLocation } from 'src/interfaces/IPage';
import { STATES } from 'src/constants/states';
import HistoricalBarChart from './HistoricalBarChart/HistoricalBarChart';
import { FS_SENSITIVE_DATA_CLASS } from 'src/constants/fullStory';

const HistoricalPremium: React.FC<
  Partial<HistoricalPremiumDto> & {
    currentPolicyAmount?: number;
    address: string;
    personName: string;
    currentCarrier?: string;
    yearBuild?: AnswerValue | null;
    squareFeet?: AnswerValue | null;
    stories?: AnswerValue | null;
    state?: string;
  }
> = ({
  chart_data,
  predicted_user_premium_amount,
  premium_change_amount,
  premium_change_percent,
  premium_type,
  currentPolicyAmount,
  address,
  personName,
  currentCarrier,
  yearBuild,
  squareFeet,
  stories,
  state
}) => {
  const { gid, flow } = useParams() as DefaultLocation;

  useEffect(() => {
    premium_type &&
      analytics.track(SEGMENT.HISTORICAL_PREMIUM_DATA_TYPE_USED, gid, flow, {
        premium_type
      });
  }, [flow, gid, premium_type]);

  const stateName = (state && STATES[state]) || '';

  return (
    <div css={historicalPremiumContainerCSS}>
      <section css={textWrapperCSS}>
        <h2 css={historicalPremiumTitleCSS}>Your rates are going up</h2>
        <p css={historicalPremiumDescCSS}>
          Based on rate trends in {stateName}, where customers are seeing a{' '}
          <span>{premium_change_percent}% increase*</span>
          on average in their rates. Your premium is projected to increase by <span>${premium_change_amount}*</span> at
          renewal, now is the time to explore your options.
        </p>
      </section>

      <section css={historicalWrapperCSS}>
        <div>
          <Card customCSS={cardCustomCSS}>
            <h2 css={cardTitleCSS}>Your Insurance Profile</h2>
            <p css={cardNameCSS} className={FS_SENSITIVE_DATA_CLASS.MASK}>
              {personName}
            </p>

            <section css={cardInfoSectionCSS}>
              <p css={cardCarrierCSS}>{currentCarrier}</p>
              {currentPolicyAmount && (
                <StyledDashedRow label="2023 Annual Premium" content={Format.money(currentPolicyAmount || 0, 0, '$')} />
              )}
              {predicted_user_premium_amount && (
                <StyledDashedRow
                  label="2024 Est. Annual Premium"
                  content={`${Format.money(predicted_user_premium_amount || 0, 0, '$')}`}
                  isHighlighted
                />
              )}
            </section>

            <section css={cardInfoSectionCSS}>
              <p className={FS_SENSITIVE_DATA_CLASS.MASK} css={cardAddressCSS}>
                🏡 {address}
              </p>
              <StyledDashedRow label="Year Built" content={yearBuild as number} />
              <StyledDashedRow label="Square Feet" content={squareFeet as number} />
              <StyledDashedRow label="Stories" content={stories as string} />
            </section>
          </Card>

          <HistoricalPremiumTooltip />
        </div>

        <div css={chartWrapperCSS}>
          <h3 css={chartTitleCSS}>
            {premium_type === 'state_data' ? `Avg. Premium increase in ${stateName}` : 'Your Premium'}
          </h3>
          {['state_data', 'carrier_data'].includes(premium_type as string) ? (
            <HistoricalBarChart chartData={chart_data} />
          ) : (
            <HistoricalPremiumChart chartData={chart_data} premiumType={premium_type} />
          )}
        </div>
      </section>
    </div>
  );
};

export default HistoricalPremium;
