import { css, SerializedStyles } from '@emotion/react';
import { borderRadius, colors, mq } from 'src/theme/variables';
import { text } from 'src/theme/typography';

export const zipInputWrapperCSS = (hasPhoneError: boolean): SerializedStyles => css`
  background-color: ${colors.white};
  border: ${hasPhoneError ? 2 : 1}px solid ${hasPhoneError ? colors.red : colors.nobel};
  display: flex;
  border-radius: ${borderRadius}px;
  padding: 4px;
  margin: auto;
  width: 100%;
  box-sizing: border-box;
  position: relative;
`;

export const inputCSS = (hasError: boolean): SerializedStyles => css`
  ${text};
  border: none;
  color: ${hasError ? colors.red : 'inherit'};
  position: relative;
  width: 80%;
  font-variant-numeric: lining-nums;
  z-index: 1;
  height: 20px;
  padding: 0;
  font-weight: bold;
`;

export const inputWrapperCSS = css`
  display: flex;
  flex-direction: column;
  text-align: left;
  flex-shrink: 0;
  flex-basis: calc(50% - 12px);
  margin: 6px;

  ${mq[0]} {
    flex-basis: 144px;
  }
`;

export const labelCSS = (hasError: boolean): SerializedStyles => css`
  ${text};
  font-weight: 400;
  color: ${hasError ? colors.red : colors.dove};
`;

export const buttonCSS = css`
  flex-grow: 1;
  min-width: 140px;
`;

export const componentWrapperCSS = css`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const errorCSS = css`
  text-align: center;
  margin-top: 4px;
  padding: 1px;
`;

export const inputElWrapperCSS = css`
  position: static;
`;
