import { css, SerializedStyles } from '@emotion/react';
import { borderRadius, colors, sizes } from 'src/theme/variables';

export const addressMenu = (isOpen: boolean): SerializedStyles => css`
  background-color: ${colors.white};
  border: 1px solid ${colors.silver};
  border-radius: ${borderRadius}px;
  box-shadow: 2px 4px 0px 0px rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  display: none;
  position: absolute;
  top: ${sizes.controlHeight + 6}px;
  width: 100%;
  z-index: 2;
  padding: 4px 0;

  ${isOpen &&
  css`
    display: block;
  `};
`;

export const menuList = css`
  max-height: 400px;
  overflow: auto;
`;

export const address = css`
  position: relative;
`;

export const loading = css`
  color: ${colors.dove};
  padding: 8px 20px;
  text-align: center;
`;

export const error = (isVisible: boolean): SerializedStyles => css`
  height: 0;
  margin-top: 0;
  transition:
    height 0.3s ease-in-out,
    margin-top 0.3s ease-in-out;
  will-change: height, margin-top;

  ${isVisible &&
  css`
    height: 20px;
    margin-top: 8px;
  `};
`;
