/** @jsxImportSource @emotion/react */
import React from 'react';

import { visuallyHidden } from 'src/theme/helpers';
import { validationErrorCSS } from './ValidationError.style';
import { ValidationErrorProps } from './ValidationError.types';

const ValidationError: React.FC<ValidationErrorProps> = ({ customCSS, visible = false, heading, children }) =>
  visible ? (
    <div css={[validationErrorCSS, customCSS]} role="alert" aria-live="polite">
      {heading && <span css={visuallyHidden}>{heading} error: </span>}
      {children}
    </div>
  ) : null;

export default ValidationError;
