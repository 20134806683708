import React, { Fragment, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { NavigationItemDetailsComponentProps } from 'src/shared/components/Navigation/NavigationItem/NavigationItem.types';
import { useWatch } from 'react-hook-form';
import { MaritalStatusKeys } from 'src/constants/schemaKeys';
import NavigationItemDetails from 'src/shared/components/Navigation/NavigationItemDetails/NavigationItemDetails';
import { scrollToNode } from 'src/utils/scroll';
import PersonIcon from 'src/assets/svg/person.svg';
import { getAge } from 'src/utils/date';
import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';
import { DefaultLocation } from 'src/interfaces/IPage';

const UserDetails: React.FC<NavigationItemDetailsComponentProps> = ({ onDataReady }) => {
  const { gid, flow } = useParams() as DefaultLocation;
  const arrayToWatch = ['person_first_name', 'person_last_name', 'person_date_of_birth', 'person_marital_status'];

  const data = useWatch({ name: arrayToWatch });
  const firstName = data[0] as string;
  const lastName = data[1] as string;
  const dateOfBirth = data[2] as string;
  const maritalStatus = data[3] as keyof typeof MaritalStatusKeys | null;

  useEffect(() => {
    if (data.some(v => !!v)) {
      onDataReady();
    }
  }, [data, onDataReady]);

  const getDetails = (): string[] => {
    const res: string[] = [];

    const age = getAge(dateOfBirth || '');
    typeof age === 'number' && res.push(String(age));
    maritalStatus && res.push(MaritalStatusKeys[maritalStatus]);

    return res;
  };

  if (data.every(v => !v)) {
    return null;
  }

  const onEditDetailsClicked = () => {
    scrollToNode('section-about_you');

    analytics.track(SEGMENT.QUESTIONS_SIDE_BARD_EDIT_CLICKED, gid, flow, {
      location: 'person_details'
    });
  };

  return (
    <Fragment>
      <NavigationItemDetails
        testId="user-details-scroll-button"
        hasSensitiveData
        iconSrc={PersonIcon}
        header={`${firstName} ${lastName}`}
        details={getDetails()}
        onDetailClick={onEditDetailsClicked}
      />
    </Fragment>
  );
};

export default UserDetails;
