import { css } from '@emotion/react';

import { colors, mq, sizes } from 'src/theme/variables';
import { text12, text24, text32 } from 'src/theme/typography';

export const content = css`
  margin-top: 20px;
  ${mq[1]} {
    flex-direction: row;
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
`;

export const rating = css`
  flex-shrink: 0;
  margin-bottom: 24px;
  ${mq[0]} {
    margin-bottom: 20px;
  }
  ${mq[0]} {
    margin-bottom: 0;
  }
`;

export const disclaimerBase = css`
  color: ${colors.warmGray};
  line-height: 16px;
`;

export const disclaimer = css`
  ${text12};
  ${disclaimerBase};
  margin-top: 12px;
  margin-left: 24px;
`;

export const carousel = css`
  margin: 0 -${sizes.paddingBodyHorizontalMobile}px;
`;

export const card = css`
  width: 33.33%;
  margin-right: 20px;
  &:last-child {
    margin-right: 0;
  }
`;

export const desktopView = css`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  ${mq[1]} {
    margin-left: 24px;
  }
`;

export const header = css`
  ${text24};
  margin: 0 0 12px;
  text-align: center;

  ${mq[0]} {
    ${text32};
    text-align: left;
  }
`;
