import { HomeQuote } from 'src/interfaces/IQuotes';

export enum LocationStateKeys {
  OlB_QUOTES_ARRAY = 'olbQuotesArray',
  CURRENT_QUOTE = 'currentQuote',
  CALL_ME_NOW_VARIATION = 'isCallMeNowVariation',
  POLICY_HOLDER = 'isPolicyHolderVariation',
  SELECTED_CARRIER = 'carrierName'
}

export interface LocationState {
  olbQuotesArray: HomeQuote[];
  currentQuote: HomeQuote;
  isCallMeNowVariation: boolean;
  isPolicyHolderVariation?: boolean;
  carrierName?: string;
  isSimpleCalendly: boolean;
}
