/** @jsxImportSource @emotion/react */
import React, { useCallback, useState, useRef } from 'react';

import { components } from 'react-select';
import Async from 'react-select/async';
import Highlighter from 'react-highlight-words';
import debounce from 'debounce-promise';
import { useParams } from 'react-router-dom';

import { FormFieldProps, Option as IOption } from 'src/interfaces/IQuestion';
import { DefaultLocation } from 'src/interfaces/IPage';
import api from 'src/api/api';
import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';
import { Control, MenuList, Option } from 'src/shared/components/Select/SelectParts.utils';
import useResponsive from 'src/hooks/useResponsive';
import Chevron from 'src/assets/svg/dropdown-chevron.svg?react';
import { selectCSS } from 'src/shared/components/Select/Select.style';
import Select, { FormatOptionLabelMeta } from 'react-select/dist/declarations/src/Select';

const DropdownIndicator: React.FC<any> = (props: any) => (
  <components.DropdownIndicator
    {...props}
    innerProps={{
      ...props.innerProps,
      onTouchEnd: (e: any) => {
        // MDS-433
        props.innerProps.onTouchEnd(e);
        (document.activeElement as Element & { blur(): void })?.blur();
      }
    }}
  >
    <Chevron />
  </components.DropdownIndicator>
);

const loadOptions = (url: string) => (inputValue: string) => api.get(`${url}?term=${inputValue}`);

const formatOptionLabel = ({ label }: IOption, { inputValue }: FormatOptionLabelMeta<IOption>) => (
  <Highlighter searchWords={[inputValue || '']} textToHighlight={label || ''} highlightTag="strong" />
);

const AsyncSelect: React.FC<FormFieldProps> = props => {
  const [selected, setSelected] = useState<IOption | null>(null);
  const responsive = useResponsive();
  const customPlaceholder = responsive.isDesktop ? 'Click to search for carrier' : 'Tap to search for carrier';
  const selectRef = useRef<Select<IOption>>(null);
  const wrapperRef = useRef(null);
  if (props.componentRef && selectRef.current) {
    props.componentRef(selectRef.current.inputRef);
  }
  const { flow, gid } = useParams() as DefaultLocation;
  const onChange = (v: IOption | null) => {
    setSelected(v);
    !!props.onValidEntry && props.onValidEntry(v);
  };
  const hasPropsValue = useCallback(() => props.value && Object.keys(props.value as IOption).length > 0, [props.value]);
  const value = selected || hasPropsValue() ? props.value : null;

  if (!props.url) {
    throw new Error('`url` must be provided in props of an AsyncSelect.');
  }

  const onBlur = (inputValue: string) => {
    const options = (selectRef.current as any)?.props.options || [];
    // Default option "It's not on the list" is always present
    if (inputValue && options.length === 1) {
      analytics.track(SEGMENT.AUTOCOMPLETE_FAILED, gid, flow, {
        question_key: props.inputId,
        value: inputValue
      });
    }
    props.onBlur?.();
  };

  return (
    <div ref={wrapperRef}>
      <Async
        cacheOptions
        ref={selectRef}
        defaultOptions={props.default_options}
        inputId={props.inputId}
        isDisabled={props.isDisabled}
        data-testid="async-select"
        css={selectCSS(props.hasError)}
        classNamePrefix="select"
        placeholder={props.placeholder || customPlaceholder}
        getOptionLabel={(o: IOption) => o.label}
        getOptionValue={(o: IOption) => o.value}
        isClearable={false}
        formatOptionLabel={formatOptionLabel}
        loadOptions={debounce(loadOptions(props.url), 300)}
        value={value as IOption}
        components={{
          MenuList,
          Option,
          DropdownIndicator,
          Control
        }}
        onChange={(v: IOption | null) => onChange(v)}
        onBlur={e => onBlur((e.target as HTMLInputElement).value)}
        onFocus={() => props.onFocus?.()}
      />
    </div>
  );
};

export default AsyncSelect;
