import { Entity } from 'src/interfaces/IPage';
import { UploadFilesComponentProps } from 'src/components/UploadFilesComponent/UploadFilesComponent.types';

enum VehicleKeys {
  Year = 'year',
  Make = 'make',
  Model = 'model'
}

export enum UsageKeys {
  work = 'work',
  pleasure = 'pleasure',
  business = 'business'
}

export enum PropertyTypeKeys {
  home = 'single_family_detached',
  condo = 'condominium',
  duplex = 'duplex',
  townhouse = 'townhouse',
  pud = 'pud',
  rowhome = 'rowhome',
  multifamily = 'multifamily'
}

export enum MaritalStatusKeys {
  married = 'Married',
  divorced = 'Divorced',
  domestic_partnership = 'Domestic partnership',
  single = 'Single',
  separated = 'Separated',
  widowed = 'Widowed'
}

export enum EducationKeys {
  less_than_high_school_diploma = 'Less Than High School Diploma',
  high_school = 'High School',
  some_college_no_degree = 'Some College No Degree',
  associate_degree = 'Associate Degree',
  bachelors_degree = 'Bachelor’s Degree',
  masters_degree = 'Master’s Degree',
  post_graduate = 'Post Graduate',
  doctor_medicine = 'Doctor Medicine',
  law_degree = 'Law Degree',
  vocational_technical = 'Vocational Technical'
}

export interface DriverEntity extends Entity {
  first_name: string;
  last_name: string;
  date_of_birth: string | null;
  gender: string;
  marital_status: keyof typeof MaritalStatusKeys | null;
  education: keyof typeof EducationKeys | null;
  accidents_or_violations_presence: 'yes' | 'no';
  accidents_accdamocaf: number;
  accidents_accnaf: number;
  claims_othernonacc: number;
  violations_dwi: number;
  violations_other: number;
  violations_reckless: number;
  violations_speed25: number;
  violations_speed19: number;
}

export interface VehicleEntity extends Entity {
  annual_mileage: string | null;
  usage: keyof typeof UsageKeys;
  vehicle: Record<VehicleKeys, string>;
}

export enum FormComponentKey {
  CurrentPolicyHolderQuestion = 'questions_person_is_policyholder'
}

export enum QuestionComponentKey {
  FloridaHomeInspectionsMessage = 'florida_home_inspections_message',
  BundleOffer = 'home_bundle_component',
  PolicyHolderQuestion = 'policyholder_questions'
}

export enum QuestionTypeKey {
  CarrierSlider = 'current_carrier_rating'
}

export interface QuestionComponentProps {
  shouldRenderEditableHomeDetails?: boolean;
  triggerSchemaUpdate?(): void;
  uploadFilesWrapperParams?: UploadFilesComponentProps;
}
