/** @jsxImportSource @emotion/react */
import React from 'react';

import CoverageShields from 'src/assets/svg/coverages-sprite.svg';
import Format from 'src/utils/format';
import { icon, option, selectDescription, selectLabel } from './CoverageSelectOption.style';
import { CoverageSelectOptionProps } from './CoverageSelectOption.types';

const CoverageSelectOption: React.FC<CoverageSelectOptionProps> = ({
  isDisabled,
  value,
  label,
  selected = false,
  singleValue = false,
  price
}) => (
  <div css={option(isDisabled)}>
    <img
      css={icon}
      src={`${CoverageShields}#${value}${selected || singleValue ? 'red' : ''}`}
      alt={`${label} Coverage`}
    />
    <div>
      <div css={selectLabel(selected)}>{label}</div>
      <div css={selectDescription(selected)}>
        {price ? `Starting at ${Format.money(price, 2, '$')}mo` : 'No quotes found'}
      </div>
    </div>
  </div>
);

export default CoverageSelectOption;
