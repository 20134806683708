import { SerializedStyles, css } from '@emotion/react';
import { text, text14 } from 'src/theme/typography';
import { colors, mq } from 'src/theme/variables';

export const customButtonCardCSS = css`
  display: flex;
  justify-content: center;
  padding: 24px 0;
`;

export const toggleButtonCSS = (isButtonActive = false): SerializedStyles => css`
  width: 180px;
  color: ${colors.black};
  ${text14};
  border-color: ${colors.nobel};
  padding: 12px 20px;
  text-transform: none;

  ${mq[0]} {
    width: 220px;
    ${text};
  }

  ${isButtonActive &&
  css`
    font-weight: 700 !important;
    color: #005cdc !important;
    background-color: ${colors.blueFife} !important;
    border: 2px solid ${colors.azure62} !important;
  `}
`;

export const toggleGroupAdditionalCSS = css`
  width: 100%;
  justify-content: center;
  margin-bottom: 24px;
`;

export const benefitsWrapperAdditionalCSS = css`
  margin-top: 48px;
`;

export const additionalHeroCSS = css`
  ${mq[0]} {
    padding: 10px 0 0;
  }
`;

export const singleQuotesContainerCSS = css`
  @media screen and (max-width: 750px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    > div {
      width: 100%;
    }
  }
`;
