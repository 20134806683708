/** @jsxImportSource @emotion/react */
import React from 'react';

import { UserProfileDetailsItemProps } from './UserProfileDetailsItem.types';
import {
  container,
  label as labelCSS,
  valueSuffix as valueSuffixCSS,
  middleMan,
  value as valueCSS
} from './UserProfileDetailsItem.style';

const UserProfileDetailsItem: React.FC<UserProfileDetailsItemProps> = ({
  customCSS,
  label,
  value,
  valueSuffix,
  fullWidth = false,
  className
}) => {
  return (
    <div css={[container(fullWidth), customCSS]} className={className}>
      <span css={labelCSS}>{label}</span>
      {fullWidth && <span css={middleMan} />}
      <span css={valueCSS}>
        {value}
        {valueSuffix && <span css={valueSuffixCSS}>{valueSuffix}</span>}
      </span>
    </div>
  );
};

export default UserProfileDetailsItem;
