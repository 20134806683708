import { css } from '@emotion/react';
import { mq, colors } from 'src/theme/variables';
import { text48, text32 } from 'src/theme/typography';

export const wrapperCSS = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const headingCSS = css`
  ${text32};
  font-weight: 900;
  padding: 24px;
  text-align: center;

  ${mq[0]} {
    ${text48};
    padding: 0;
  }
`;

export const profileWrapperCSS = css`
  ${mq[0]} {
    width: 650px;
  }

  margin-top: 35px;
  margin-bottom: 80px;

  hr {
    margin: 12px 0;
    border: 1px solid ${colors.mercury};
  }
`;

export const itemsWrapperCSS = css`
  max-width: 300px;

  ${mq[0]} {
    max-width: 740px;
  }
`;
