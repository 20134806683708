import { css, SerializedStyles } from '@emotion/react';
import { colors, mq } from 'src/theme/variables';

export const footerCSS = (variant: string): SerializedStyles => css`
  background-color: ${variant === 'default' ? '#f2f2f2' : ' #ffffff'};
  position: absolute;
  width: 100%;
  font-size: 11px;
  color: #333333;

  ${mq[0]} {
    bottom: 0px;
  }

  @media print {
    bottom: 0px;
  }
`;

export const logoFooterCSS = (variant: string): SerializedStyles => css`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  align-items: center;
  font-size: 12px;
  color: ${colors.dove};

  ${mq[0]} {
    margin-top: ${variant === 'default' ? '70px' : '0px'};
  }

  @media print {
    margin-top: ${variant === 'default' ? '70px' : '0px'};
  }
`;

export const contentWrapperCSS = css`
  padding: 24px;
`;

export const smallLogoCSS = css`
  height: 40px;
`;
