/** @jsxImportSource @emotion/react */
import React from 'react';

import { QuoteBenefitsProps } from './QuoteBenefits.types';
import Icon from 'src/assets/svg/quote-benefit-checkmark.svg?react';
import { benefitCSS, iconCSS, titleCSS } from './QuoteBenefits.style';

const QuoteBenefits: React.FC<QuoteBenefitsProps> = ({ customCSS = {}, title = 'Benefits', benefits }) => {
  return (
    <div css={customCSS.container}>
      <div css={[titleCSS, customCSS.title]}>{title}</div>
      {benefits.map((benefitText, i) => {
        if (benefitText) {
          return (
            <div key={i} css={benefitCSS}>
              <Icon css={[iconCSS, customCSS.icon]} />
              <div>{benefitText}</div>
            </div>
          );
        }
      })}
    </div>
  );
};

export default QuoteBenefits;
