import { css } from '@emotion/react';

import { colors as c, mq } from 'src/theme/variables';
import { text40, text32 } from 'src/theme/typography';

export const wrapperCSS = css`
  margin: 0 auto;
  max-width: 1000px;
`;

export const currentPolicyWrapperCSS = css`
  align-items: center;
  border: 1px solid ${c.nobel};
  border-radius: 8px;
  box-shadow: 2px 4px 0 rgb(0 0 0 / 12%);
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  margin: 40px 32px;
  max-width: 804px;
  padding: 32px 20px;
  flex-direction: column;
  min-height: 200px;

  ${mq[0]} {
    min-height: unset;
    margin: 40px auto auto;
    justify-content: space-around;
    flex-direction: row;
  }
`;

export const currentPolicyItemCSS = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const headingContainerCSS = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 24px;
  padding-left: 32px;
  padding-right: 32px;
`;

export const olbQuotesWrapperCSS = css`
  margin: auto;
  padding-bottom: 20px;
  padding-left: 32px;
  padding-right: 32px;

  ${mq[0]} {
    width: 680px;
  }

  ${mq[1]} {
    width: 800px;
  }
`;

export const olbQuotesHeadingCSS = css`
  margin-bottom: 16px;
`;

export const olbQuotesHeadingDescriptionCSS = css`
  ${text32}
`;

export const headingCSS = css`
  ${text32}

  ${mq[0]} {
    ${text40}
  }
`;
export const headingDescriptionCSS = css`
  line-height: 32px;
`;

export const carrierImgCSS = css`
  max-width: 115px;
`;

export const detailsHeaderCSS = css`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  text-align: center;
`;

export const detailsButtonCSS = css`
  margin-top: 12px;
`;

export const annualPremiumCSS = css`
  ${text32};
  font-weight: bold;
`;
