import { useCallback, useEffect, useState } from 'react';
import {
  CALENDLY_EVENT_SCHEDULED,
  CALENDLY_EVENT_TYPE_VIEWED,
  CALENDLY_INIT_WIDGET_INLINE,
  CALENDLY_INIT_WIDGET_POPUP,
  CALENDLY_STYLES_LINK_URL,
  CALENDLY_TIME_SELECTED,
  CALENDLY_WIDGET_SCRIPT_URL
} from 'src/utils/calendly';
import { Flows } from 'src/interfaces/IPage';
import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';
import { AnswerValue } from 'src/interfaces/IQuestion';

interface InitProps {
  gid: string;
  flow: Flows;
  url?: string;
  isPopup?: boolean;
  phone: AnswerValue | null;
  name: AnswerValue | null;
  email: AnswerValue | null;
}

interface CalendlyData {
  step: number;
  calendlyInit: () => void;
}

const filterCalendlyEvents = (eventHandler: (event: string) => void) => (event: MessageEvent) => {
  !(event.data.event || '').indexOf('calendly') && eventHandler(event.data.event);
};

const useInitCalendly = ({
  gid,
  flow,
  url,
  isPopup = false,
  phone = '',
  email = '',
  name = ''
}: InitProps): CalendlyData => {
  const [step, setStep] = useState(0);

  const initCalendlyWidget = useCallback(() => {
    const validPhone = `${phone}`?.includes('*') || phone === null ? '' : phone;
    const parentElement = document.getElementById('calendly-widget') || null;

    const calendlyAction = isPopup ? CALENDLY_INIT_WIDGET_POPUP : CALENDLY_INIT_WIDGET_INLINE;

    (window as any).Calendly[calendlyAction]({
      ...(parentElement && { parentElement }),
      url,
      prefill: {
        name,
        email,
        customAnswers: {
          a1: `1${validPhone}`,
          a2: `1${validPhone}`
        }
      },
      utm: {
        utmMedium: 'serweb',
        ...(gid && { utmTerm: gid })
      }
    });
  }, [email, name, gid, isPopup, phone, url]);

  const onCalendlyEvent = useCallback(
    (eventName: string) => {
      switch (eventName) {
        case CALENDLY_EVENT_TYPE_VIEWED:
          setStep(1);
          break;
        case CALENDLY_TIME_SELECTED:
          analytics.track(SEGMENT.SCHEDULE_CALL_TIME_SELECTED, gid, flow);
          setStep(2);
          break;
        case CALENDLY_EVENT_SCHEDULED:
          setStep(3);
          analytics.track(SEGMENT.CONVERSATION_SCHEDULED, gid, flow);
          window.scrollTo(0, 0);
          break;
        default:
          break;
      }
    },
    [flow, gid]
  );

  const addCalendlyLinkTag = () => {
    const link = document.createElement('link');
    link.href = CALENDLY_STYLES_LINK_URL;
    link.rel = 'stylesheet';
    document.head.appendChild(link);
  };

  const removeCalendlyLinkTag = () => {
    const linkTag = document.querySelector('link');
    if (linkTag && linkTag.href === CALENDLY_STYLES_LINK_URL) {
      document.head.removeChild(linkTag);
    }
  };

  const addCalendlyScriptTag = useCallback(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = CALENDLY_WIDGET_SCRIPT_URL;
    script.onload = initCalendlyWidget;
    document.head.appendChild(script);
  }, [initCalendlyWidget]);

  const calendlyInit = useCallback(() => {
    !(window as any).Calendly && isPopup && addCalendlyLinkTag();
    !(window as any).Calendly && addCalendlyScriptTag();
    window.addEventListener('message', filterCalendlyEvents(onCalendlyEvent));
    (window as any).Calendly && initCalendlyWidget();

    setTimeout(() => {
      document
        .querySelector('iframe')
        ?.setAttribute('aria-label', 'Calendly widget to select date and time for a chat.');
    }, 500);
  }, [addCalendlyScriptTag, initCalendlyWidget, isPopup, onCalendlyEvent]);

  useEffect(() => {
    if (!isPopup) {
      removeCalendlyLinkTag();
    }
  }, [isPopup]);

  return { calendlyInit, step };
};

export default useInitCalendly;
