/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { useParams } from 'react-router-dom';
import format from 'src/utils/format';

import {
  img,
  imgWrapper,
  agencyContact,
  curl as curlCSS,
  envelope as envelopeCSS,
  callUs,
  phoneNumber,
  letAgents,
  scheduleCallButtonCSS
} from './AgencyContact.style';
import curl from 'src/assets/svg/agent-curl.svg';
import envelope from 'src/assets/svg/agent-envelope.svg';
import Anchor from 'src/kit/Anchor/Anchor';
import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';
import { DefaultLocation } from 'src/interfaces/IPage';

import useLanding from 'src/api/landing/useLanding';
import Button from 'src/kit/Button/Button';
import ScheduleCallPopupWidget from 'src/components/ScheduleCall/ScheduleCallPopupWidget/ScheduleCallPopupWidget';

const getAnimationConfig = (trigger: string, y: number) => ({
  y,
  scrollTrigger: {
    trigger,
    start: 'top bottom',
    end: 'bottom top',
    scrub: 1
  }
});

const AgencyContact: React.FC = () => {
  const { flow, gid } = useParams() as DefaultLocation;
  const { data: landingData } = useLanding();

  const [isShowCalendly, setIsShowCalendly] = useState(false);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.to('#curl', getAnimationConfig('#curl', -70));
    gsap.to('#envelope', getAnimationConfig('#envelope', -140));
  }, []);

  const onPhoneNumberClick = () => {
    analytics.track(SEGMENT.PHONE_CONVERSATION_INITIATED, gid, flow, {
      location: SEGMENT.LOCATIONS.AGENT_CARD,
      page: SEGMENT.PAGES_KEY.LANDING
    });
  };

  const onShowCalendly = () => {
    setIsShowCalendly(false);
    setTimeout(() => {
      setIsShowCalendly(true);
    }, 0);
  };

  return (
    <div css={agencyContact}>
      <div css={imgWrapper}>
        <img
          css={img}
          src="https://maticinsurance.sirv.com/customer-experience/agency.png"
          alt="Advisor willing to help"
          loading="lazy"
        />
        <img src={curl} id="curl" css={curlCSS} alt="Curl" loading="lazy" />
        <img src={envelope} id="envelope" css={envelopeCSS} alt="Envelope" loading="lazy" />
      </div>

      <p css={letAgents}>Or, let our advisors do all the work!</p>

      {landingData?.agent_phone && (
        <p css={callUs}>
          Call us at{' '}
          <Anchor customCSS={phoneNumber} href={`tel:${landingData.agent_phone}`} onClick={onPhoneNumberClick}>
            {format.phone(landingData.agent_phone)}
          </Anchor>
        </p>
      )}

      <div>
        <Button css={scheduleCallButtonCSS} onClick={onShowCalendly}>
          Or, schedule a Call
        </Button>
      </div>
      {isShowCalendly && <ScheduleCallPopupWidget />}
    </div>
  );
};

export default AgencyContact;
