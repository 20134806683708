/** @jsxImportSource @emotion/react */
import React from 'react';
import {
  mainWrapperCSS,
  mainHeadingCSS,
  cardWrapperCSS,
  cardHeaderCSS,
  contentWrapperCSS,
  partnerWrapperCSS,
  optionsWrapperCSS,
  optionWrapperCSS,
  buttonCSS,
  iconCSS,
  FAQCSS,
  imgCSS,
  carriersCSS,
  carriersWrapperCSS
} from './FloridaKinLanding.style';
import Container from 'src/kit/Container/Container';
import Icon from 'src/assets/svg/quote-benefit-checkmark.svg?react';
import Button from 'src/kit/Button/Button';
import FAQKin from 'src/components/FAQ/FAQKin';
import SEGMENT from 'src/constants/segment';
import analytics from 'src/utils/analytics';
import { useParams } from 'react-router-dom';
import { DefaultLocation } from 'src/interfaces/IPage';
import QuotesCarriers from 'src/components/Quotes/QuotesCarriers/QuotesCarriers';
import { getAccordionItems, firstRowKinOptions, secondRowKinOptions } from './FloridaKinLanding.utils';
import { useQuestions } from 'src/api/questions';
import { getKinUrl } from 'src/utils/kin';

import { AddressAnswer } from 'src/interfaces/IQuestion';

const renderOptionsArray = (options: string[]) => {
  return options.map((option, index) => {
    return (
      <div key={index} css={optionWrapperCSS}>
        <Icon css={iconCSS} /> <div>{option}</div>
      </div>
    );
  });
};

const FloridaKinLanding: React.FC<{ userName: string }> = ({ userName = '' }) => {
  const { flow, gid } = useParams() as DefaultLocation;
  const { data } = useQuestions(gid);

  const answers = data?.answers;
  const propertyAddress = answers?.property_address as AddressAnswer;

  const onButtonClick = () => {
    analytics.track(SEGMENT.KIN_INSURANCE_INTERESTED_CLICKED, gid, flow);

    window.open(
      getKinUrl({
        address: propertyAddress,
        personGid: answers?.person_gid,
        lastName: answers?.person_last_name,
        email: answers?.person_email,
        firstName: answers?.person_first_name,
        personPhone: answers?.person_phone
      }),
      '_self'
    );
  };

  return (
    <Container>
      <div css={mainWrapperCSS}>
        <div>
          {userName && `${userName},`}
          <p css={mainHeadingCSS}>Check out our partner Kin Insurance</p>
        </div>

        <div css={cardWrapperCSS}>
          <div css={cardHeaderCSS}>Get a quote from Kin</div>
          <div css={contentWrapperCSS}>
            <div css={partnerWrapperCSS}>
              <img src="https://maticinsurance.sirv.com/carriers/regular/kin.svg" alt="Kin Insurance" css={imgCSS} />
              <div>
                <h4>Why Kin might be a good fit for you</h4>
                While other insurers struggle to handle a fast-changing world, Kin is built for the future and prepared
                to meet its challenges head on while helping customers do the same.
              </div>
            </div>
            <div>
              <h3>Benefits</h3>
              <div css={optionsWrapperCSS}>
                <div>{renderOptionsArray(firstRowKinOptions)}</div>
                <div>{renderOptionsArray(secondRowKinOptions)}</div>
              </div>
            </div>
            <Button customCSS={buttonCSS} type="submit" variant="default" onClick={onButtonClick}>
              Yes, I’m Interested
            </Button>
          </div>
        </div>
        <FAQKin
          page={SEGMENT.PAGES.NO_OPTIONS}
          accordionItems={getAccordionItems()}
          customCSS={FAQCSS}
          heading="Quick Questions we can Answer"
        />
        <div css={carriersWrapperCSS}>
          <QuotesCarriers customCSS={carriersCSS} />
        </div>
      </div>
    </Container>
  );
};

export default FloridaKinLanding;
