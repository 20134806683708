import React from 'react';
import { FormQuestionProps } from 'src/interfaces/IQuestion';

import { QuestionWrapper } from 'src/questionsForm/components/QuestionWrapper/QuestionWrapper';
import QuestionInput from 'src/questionsForm/components/Question/QuestionInput/QuestionInput';

const NumberInputQuestion: React.FC<FormQuestionProps> = props => (
  <QuestionWrapper {...props} inputComponent={QuestionInput} type="number" />
);

export default NumberInputQuestion;
