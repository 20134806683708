/** @jsxImportSource @emotion/react */
import React, { Fragment } from 'react';

import { useParams } from 'react-router-dom';

import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';
import { DefaultLocation } from 'src/interfaces/IPage';
import quotesService from 'src/api/quotes/quotes.service';
import QuoteCardBase from 'src/components/Quotes/QuoteCard/components/QuoteCardBase/QuoteCardBase';
import QuoteLogo from 'src/components/Quotes/QuoteCard/components/QuoteLogo/QuoteLogo';
import Anchor from 'src/kit/Anchor/Anchor';
import { summary, phoneNumber, description, logo, container } from './ManualQuoteCard.style';
import useResponsive from 'src/hooks/useResponsive';
import { ManualQuoteCardProps } from './ManualQuoteCard.types';
import { QuoteLogoSize } from 'src/components/Quotes/QuoteCard/components/QuoteLogo/QuoteLogo.types';
import { useCustomNavigate } from 'src/hooks/useCustomNavigate';
import userEventDataService, { UserEvents } from 'src/api/userEventsData/userEventsData';

const ManualQuoteCard: React.FC<ManualQuoteCardProps> = ({
  customCSS,
  ordinal,
  contactPhone,
  quote,
  fullWidth,
  showLessVariant = false,
  shouldRenderImmediateCallVariation = false,
  openScheduleCallModal
}) => {
  const { flow, gid } = useParams() as DefaultLocation;
  const navigate = useCustomNavigate();
  const { isMobile } = useResponsive();

  const onChoose = async () => {
    try {
      await quotesService.selectQuote(flow, gid, [quote.gid]);
      analytics.track(SEGMENT.QUOTE_SELECTED, gid, flow, {
        carrier_key: quote.carrier.key,
        ordinal: ordinal,
        bundle_status: null,
        quote_gid: quote.gid,
        bundle_only: false
      });
    } catch {}

    if (quote.digital_profile_url) {
      window.open(quote.digital_profile_url, '_self');
      return;
    }

    if (openScheduleCallModal) {
      openScheduleCallModal();
    } else {
      navigate(`/${flow}/${gid}/confirmation`);
    }
  };

  const onShowDetailsClick = async () => {
    analytics.track(SEGMENT.QUOTE_REVIEWED, gid, flow, {
      carrier_key: quote.carrier.key,
      ordinal: ordinal
    });

    try {
      await userEventDataService.callUserEventReport(UserEvents.QuoteDetailsClicked, gid);
    } catch {}
  };

  const onPhoneNumberClick = () => {
    analytics.track(SEGMENT.PHONE_CONVERSATION_INITIATED, gid, flow, {
      location: SEGMENT.LOCATIONS.MANUAL_QUOTE_CARD,
      page: SEGMENT.PAGES_KEY.QUOTES
    });
  };

  const quoteCardText = shouldRenderImmediateCallVariation
    ? 'We want to get the details right to help you save the most. Call a Matic Advisor at '
    : 'Call a Matic Advisor at ';

  const actionLabel = shouldRenderImmediateCallVariation
    ? 'Schedule a call'
    : quote.digital_profile_url
      ? 'Continue'
      : 'Talk to Matic';

  return (
    <QuoteCardBase
      customCSS={[customCSS, container(fullWidth)]}
      actionLabel={actionLabel}
      detailsComponent={<Fragment>{quote.carrier.description}</Fragment>}
      showDetailsButton={!isMobile}
      cardDirection={isMobile ? 'column' : 'row'}
      fullWidthActions={fullWidth}
      showLessVariant={showLessVariant}
      onActionClick={onChoose}
      onShowDetails={onShowDetailsClick}
      shouldHideDetailsButton={!quote.carrier.description}
    >
      <div css={summary}>
        <QuoteLogo
          customCSS={logo}
          logoUrl={quote.carrier.logo_url}
          logoLabel={quote.carrier.name}
          size={isMobile ? QuoteLogoSize.BIG : QuoteLogoSize.DEFAULT}
        />
        <div css={description}>
          {' '}
          {quote.digital_profile_url ? (
            ''
          ) : (
            <Fragment>
              {quoteCardText}

              <Anchor
                customCSS={phoneNumber}
                anchorType="underline-active"
                href={`tel:${contactPhone}`}
                onClick={onPhoneNumberClick}
              >
                {contactPhone}
              </Anchor>
            </Fragment>
          )}
        </div>
      </div>
    </QuoteCardBase>
  );
};

export default ManualQuoteCard;
