import React from 'react';
import { useParams } from 'react-router-dom';
import { DefaultLocation } from 'src/interfaces/IPage';
import { NavigationSectionDetails } from './Navigation.types';
import { NavigationItemProps } from 'src/shared/components/Navigation/NavigationItem/NavigationItem.types';
import NavigationItem from 'src/shared/components/Navigation/NavigationItem/NavigationItem';
import { scrollToNode } from 'src/utils/scroll';
import { NavigationDetails } from './Navigation.utils';
import useQuestions from 'src/api/questions/useQuestions';

const Navigation: React.FC = () => {
  const { gid } = useParams() as DefaultLocation;
  const { data: questions } = useQuestions(gid);

  if (!questions) return null;

  const {
    schema: { pages },
    step: currentStep
  } = questions;
  const steps = pages.map(p => p.key_name);
  const currentStepIndex = steps.indexOf(currentStep);

  const sections: NavigationItemProps[] = pages.map((p, index) => {
    const isCompleted = index < currentStepIndex;
    const isUnavailable = index > currentStepIndex;
    const isExpandable =
      isCompleted &&
      !isUnavailable &&
      Object.values(NavigationSectionDetails).includes(p.key_name as NavigationSectionDetails);

    return {
      name: p.heading || '',
      key_name: p.key_name,
      isCurrent: p.key_name === currentStep,
      isCompleted,
      isUnavailable,
      asyncDetailsComponent: isExpandable ? NavigationDetails[p.key_name as NavigationSectionDetails] : null
    };
  });

  return (
    <>
      {sections
        .filter(s => !!s.name)
        .map(props => (
          <NavigationItem
            {...props}
            key={props.key_name}
            onItemClick={() => scrollToNode(`section-${props.key_name}`)}
          />
        ))}
    </>
  );
};

export default Navigation;
