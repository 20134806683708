import { css, SerializedStyles } from '@emotion/react';
import { mq, navigationWidthDesktop, sizes, colors } from 'src/theme/variables';

export const questionsHeaderCSS = (isCSTVariation?: boolean): SerializedStyles => css`
  position: fixed;
  width: 100%;
  z-index: 3;

  ${isCSTVariation &&
  css`
    left: 0;
    top: 0;
  `}
`;

export const contentCSS = css`
  display: flex;
  min-height: 100vh;
  padding-left: ${sizes.paddingBodyHorizontalTablet}px;
  padding-right: ${sizes.paddingBodyHorizontalMobile}px;
  padding-top: var(--header-height);

  ${mq[0]} {
    min-height: calc(100vh - var(--header-height));
    padding-left: ${sizes.paddingBodyHorizontalTablet}px;
    padding-right: ${sizes.paddingBodyHorizontalTablet}px;
  }
`;

export const leftColumnCSS = css`
  position: fixed;
  box-sizing: border-box;
  display: none;
  padding-right: 20px;
  padding-top: 60px;
  width: ${navigationWidthDesktop}px;

  ${mq[0]} {
    display: block;
  }
`;

export const navigationCSS = css`
  padding-left: 85px;
`;

export const formCSS = (shouldRenderSkipButton?: boolean): SerializedStyles => css`
  padding-top: ${shouldRenderSkipButton ? 46 : 24}px;
  width: 600px;

  ${mq[0]} {
    padding-left: ${navigationWidthDesktop + 32}px;
    padding-top: 66px;
    width: 600px;
  }
`;

export const skipButtonCSS = css`
  border-color: ${colors.white};

  ${mq[0]} {
    margin-left: -20px;
    border-color: ${colors.azure};
    margin-top: 6px;
  }
`;

export const buttonWrapperCSS = css`
  background-color: ${colors.white};
  height: 45px;
  position: fixed;
  top: 60px;
  z-index: 9;
  width: 88vw;
  display: flex;
  justify-content: flex-end;

  ${mq[0]} {
    display: none;
  }
`;
