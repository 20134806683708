import { Answers } from 'src/interfaces/IAnswer';
import { QuotesPageHeroType } from 'src/components/Quotes/QuotesPageHero/QuotesPageHero.types';

export enum HomeQuotesPageVariations {
  Savings = 'savings_quote',
  Landlord = 'landlord_quote',
  NoQuotes = 'no_quotes',
  BundleToggle = 'bundle_toggle_discount',
  OnlyManual = 'manual_quotes',
  QuotesList = 'quotes_list',
  Undefined = 'undefined'
}

export const getCurrentQuotePageVariation = (
  savingsQuotesPage: boolean,
  noQuotes: boolean,
  isBndlTgglPageVariation: boolean,
  isLandlordPage: boolean,
  isManualQuotePage: boolean,
  isSimpleQuoteListPage: boolean
): HomeQuotesPageVariations => {
  switch (true) {
    case isBndlTgglPageVariation:
      return HomeQuotesPageVariations.BundleToggle;
    case savingsQuotesPage:
      return HomeQuotesPageVariations.Savings;
    case isLandlordPage:
      return HomeQuotesPageVariations.Landlord;
    case noQuotes:
      return HomeQuotesPageVariations.NoQuotes;
    case isSimpleQuoteListPage:
      return HomeQuotesPageVariations.QuotesList;
    case isManualQuotePage:
      return HomeQuotesPageVariations.OnlyManual;
    default:
      return HomeQuotesPageVariations.Undefined;
  }
};

export const getUnderwritingQuestionsAppliedKeys = (
  keyObject: Record<string, string>,
  answers: Answers = {}
): string[] => {
  return Object.keys(keyObject).reduce((acc, key) => {
    const keyValue = keyObject[key];
    return answers[keyValue] ? [...acc, keyValue] : acc;
  }, [] as string[]);
};

export const getQuotesHeroType = (data: {
  noQuotes: boolean;
  savingsQuotesPage: boolean;
  landlordQuotesPage: boolean;
  accordQuotesPage: boolean;
  negativeSavingsTestVariation?: boolean;
  isPolicyHolderVariation?: boolean;
}): QuotesPageHeroType => {
  switch (true) {
    case data.noQuotes:
      return QuotesPageHeroType.NoQuotes;
    case data.isPolicyHolderVariation:
      return QuotesPageHeroType.PolicyHolder;
    case data.savingsQuotesPage:
      return QuotesPageHeroType.Savings;
    case data.landlordQuotesPage:
      return QuotesPageHeroType.Landlord;
    case data.accordQuotesPage:
      return QuotesPageHeroType.Accord;
    default:
      return QuotesPageHeroType.Default;
  }
};
