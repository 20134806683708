import { css, SerializedStyles } from '@emotion/react';
import { text, text14 } from 'src/theme/typography';
import { mq } from 'src/theme/variables';

export const title = (isExpandable: boolean): SerializedStyles => css`
  font-family: inherit;
  font-weight: inherit;
  ${text14};
  padding: 0;
  background-color: transparent;
  border: 0;
  color: inherit;
  text-align: left;
  cursor: ${isExpandable ? 'pointer' : 'default'};
  ${mq[0]} {
    ${text};
  }
`;

export const toggle = (closed: boolean): SerializedStyles => css`
  height: 16px;
  transform: rotate(${closed ? '180' : '0'}deg);
  transition: transform 0.2s ease;
  vertical-align: middle;
  margin-left: 8px;
`;

export const description = css`
  ${text14};
  font-weight: normal;
  text-align: left;
  padding-top: 8px;
`;
