import { css, SerializedStyles } from '@emotion/react';
import { actionColors, colors } from 'src/theme/variables';
import { text } from 'src/theme/typography';

export const container = css`
  display: flex;
`;

const selectedCSS = css`
  position: relative;
  background-color: ${colors.white};
  border-left: solid 1px ${colors.silver};
  border-right: solid 1px ${colors.silver};
  padding-left: 0;
  padding-right: 0;
  &:after {
    box-shadow:
      inset 0 0 4px rgba(0, 0, 0, 0.3),
      inset 0 -4px 0 ${actionColors.primary};
  }
  &:first-of-type {
    border-left: none;
  }
  &:last-child {
    border-right: none;
  }
`;

export const option = (selected: boolean): SerializedStyles => css`
  align-items: center;
  border: 0;
  cursor: pointer;
  display: flex;
  flex: 1 1 33%;
  flex-direction: column;
  overflow: hidden;
  padding: 16px 1px 8px;
  position: relative;
  :after {
    bottom: 0;
    box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
    content: '';
    left: -6px;
    position: absolute;
    right: -6px;
    top: 0;
    z-index: 3;
  }

  ${selected && selectedCSS}
`;

export const icon = css`
  height: 32px;
`;

export const label = (selected: boolean): SerializedStyles => css`
  ${text};
  line-height: 24px;
  font-weight: ${selected ? 'bold' : 'normal'};
`;
