/** @jsxImportSource @emotion/react */
import React, { Fragment } from 'react';
import { rowWrapperCSS, columnWrapperCSS, contentWrapperCSS, anchorCSS } from './PolicyHolderQuestion.style';
import StopwatchGirl from 'src/assets/svg/computer-mouse.svg?react';
import DocumentIcon from 'src/assets/svg/quotes-coverage-document.svg?react';
import Button from 'src/kit/Button/Button';
import Anchor from 'src/kit/Anchor/Anchor';
import Format from 'src/utils/format';
import noop from 'src/utils/noop';
import useConfig from 'src/api/config/useConfig';
import { useParams } from 'react-router-dom';
import { DefaultLocation } from 'src/interfaces/IPage';
import useResponsive from 'src/hooks/useResponsive';
import HandsetIcon from 'src/assets/svg/handset.svg';
import HandsetBlueIcon from 'src/assets/svg/handset-blue.svg';
import { useCustomNavigate } from 'src/hooks/useCustomNavigate';
import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';

export interface PolicyHolderQuestionProps {
  triggerSchemaUpdate?(step?: string): void;
}

const PolicyHolderQuestion: React.FC<PolicyHolderQuestionProps> = ({ triggerSchemaUpdate = noop }) => {
  const { gid, flow } = useParams() as DefaultLocation;
  const navigate = useCustomNavigate();
  const { data: config } = useConfig(gid);
  const { isMobile } = useResponsive();

  const contactPhone = config?.partner?.cst_phone;

  const onScheduleCall = () => {
    analytics.track(SEGMENT.PH_COMPONENT_SCHEDULE_A_CALL, gid, flow);

    return navigate(`/${flow}/${gid}/confirmation`, {
      state: {
        isPolicyHolderVariation: true
      }
    });
  };

  const onSeeNewQuotesClick = () => {
    analytics.track(SEGMENT.PH_COMPONENT_SEE_QUOTES, gid, flow);
    triggerSchemaUpdate('get_quotes');
  };

  const onPhoneClick = () => {
    analytics.track(SEGMENT.PH_COMPONENT_PHONE_LINK, gid, flow);
  };

  const onContactUsClick = () => {
    analytics.track(SEGMENT.PH_COMPONENT_CONTACT_US, gid, flow);
  };

  return (
    <div css={columnWrapperCSS}>
      <div css={rowWrapperCSS}>
        <DocumentIcon />{' '}
        <div css={contentWrapperCSS}>
          <h4>Check homeowners insurance quotes</h4>
          <Button onClick={onSeeNewQuotesClick}>See Quotes</Button>
        </div>
      </div>
      <div css={rowWrapperCSS}>
        <StopwatchGirl />{' '}
        <div css={contentWrapperCSS}>
          <h4>Support for my current policy </h4>
          <div>
            If you have questions about your policy, a Licensed Customer Service Specialist can help you. For immediate
            assistance, we can be reached Monday-Friday from 9am-6pm ET or customerservice@matic.com.
          </div>

          {isMobile ? (
            <Fragment>
              <Button onClick={onScheduleCall}>Contact Us</Button>
              <br />
              {contactPhone && (
                <Anchor css={anchorCSS()} onClick={onPhoneClick} href={`tel:${Format.phone(contactPhone)}`}>
                  <img src={HandsetBlueIcon} width={20} alt="Handest" /> {contactPhone}
                </Anchor>
              )}

              <br />
            </Fragment>
          ) : (
            <Fragment>
              {contactPhone && (
                <Anchor css={anchorCSS()} onClick={onPhoneClick} href={`tel:${Format.phone(contactPhone)}`}>
                  <img src={HandsetIcon} width={20} alt="Handest" /> {contactPhone}
                </Anchor>
              )}
              <br />

              <Anchor
                onClick={onContactUsClick}
                css={anchorCSS(true)}
                href={'https://matic.com/consumer-contact-form/'}
              >
                Contact Us
              </Anchor>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default PolicyHolderQuestion;
