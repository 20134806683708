import { isFuture, isSameDay, isValid, parseISO } from 'date-fns';

import { AnswerValue, DynamicValidationTypes } from 'src/interfaces/IQuestion';
import { getAge } from './date';

type validateFn = (value: AnswerValue, params?: Record<string, number | string>) => string | boolean;

export const MESSAGES: Record<DynamicValidationTypes, string> = {
  [DynamicValidationTypes.YearNowOrOlder]: 'Year built must be current year or older',
  [DynamicValidationTypes.DateOfBirth]: 'Your age must be between 16 and 95',
  [DynamicValidationTypes.DateOfBirthLife]: 'Your age must be between 18 and ',
  [DynamicValidationTypes.DateNowOrFuture]: 'This should be today’s date or in the future.'
};

const dynamicValidations: Record<DynamicValidationTypes, validateFn> = {
  [DynamicValidationTypes.YearNowOrOlder]: value =>
    +value <= new Date().getFullYear() || MESSAGES[DynamicValidationTypes.YearNowOrOlder],
  [DynamicValidationTypes.DateOfBirth]: value => {
    const age = getAge(value as string);
    const isValidAge = Number.isInteger(age as number) && +age >= 16 && +age <= 95;

    return isValidAge || MESSAGES[DynamicValidationTypes.DateOfBirth];
  },
  [DynamicValidationTypes.DateOfBirthLife]: (value, params) => {
    const age = getAge(value as string);
    const isValidAge = Number.isInteger(age as number) && +age >= 18 && +age <= +params!.maxAge;

    return isValidAge || MESSAGES[DynamicValidationTypes.DateOfBirthLife] + params!.maxAge;
  },
  [DynamicValidationTypes.DateNowOrFuture]: value => {
    const v = (value as string) || '';
    const dates: [Date, Date] = [new Date(v), new Date()];

    return (
      (isValid(parseISO(v)) && (isSameDay(...dates) || isFuture(dates[0]))) ||
      v.length === 0 ||
      MESSAGES[DynamicValidationTypes.DateNowOrFuture]
    );
  }
};

export default dynamicValidations;
