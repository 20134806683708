import { css, SerializedStyles } from '@emotion/react';
import { borderRadius, colors } from 'src/theme/variables';

const logoGap = 8;
const logoMinHeight = 70;

export const logos = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -${logoGap / 2}px 8px;
`;

export const logo = (hideBorder?: boolean): SerializedStyles => css`
  background-color: ${colors.white};
  border: ${hideBorder ? 0 : 1}px solid ${colors.silver};
  border-radius: ${borderRadius}px;
  box-sizing: border-box;
  display: inline-flex;
  flex: 1 0 144px;
  justify-content: center;
  align-items: center;
  margin: ${logoGap / 2}px;
  min-height: ${logoMinHeight}px;
  padding: 18px;
`;

export const moreCarriersButton = css`
  display: block;
  margin: 8px auto 0;
`;
