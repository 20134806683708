/** @jsxImportSource @emotion/react */
import React from 'react';
import { useParams } from 'react-router-dom';
import { DefaultLocation } from 'src/interfaces/IPage';
import GenericErrorContent from './GenericErrorContent/GenericErrorContent';
import Container from 'src/kit/Container/Container';
import { wrapperCSS } from './GenericError.style';
import { useConfig } from 'src/api/config';

const GenericError: React.FC = () => {
  const { gid } = useParams() as Partial<DefaultLocation>;
  const { data: config } = useConfig(gid);

  return (
    <section css={wrapperCSS}>
      <Container>
        <GenericErrorContent contactPhone={config?.partner?.agent_phone} />
      </Container>
    </section>
  );
};

export default GenericError;
