import { css } from '@emotion/react';

export const container = css`
  display: flex;
  align-items: center;
  // to remove extra margin at the bottom of child inline-block element
  font-size: 0;
`;

export const image = css`
  align-self: center;
  & img {
    max-height: 100%;
  }
`;
