/** @jsxImportSource @emotion/react */
import React from 'react';

import { QuotesOtherCarriersProps } from './QuotesOtherCarriers.types';
import { buttonCSS, containerCSS, headingCSS } from './QuotesOtherCarriers.style';
import Button from 'src/kit/Button/Button';
import useResponsive from 'src/hooks/useResponsive';
import noop from 'src/utils/noop';
import CarriersGrid from 'src/components/CarriersGrid/CarriersGrid';
import { ButtonSize } from 'src/kit/Button/types';

const QuotesOtherCarriers: React.FC<QuotesOtherCarriersProps> = ({ customCSS, onActionClick = noop }) => {
  const { isMobile } = useResponsive();

  return (
    <div css={[containerCSS, customCSS]}>
      <div css={headingCSS}>Other carriers we can match you with</div>
      <CarriersGrid expanded withBorder={false} />
      <Button
        customCSS={buttonCSS}
        variant="secondary"
        size={isMobile ? ButtonSize.Normal : ButtonSize.Large}
        fullWidth={isMobile}
        onClick={onActionClick}
      >
        Discuss all of the options
      </Button>
    </div>
  );
};

export default QuotesOtherCarriers;
