/** @jsxImportSource @emotion/react */
import React, { useState, Fragment, Ref } from 'react';

import { useFormContext, Controller } from 'react-hook-form';

import RadioButton from './RadioButton';
import { RadioFormQuestionProps, Option, FormQuestionDirections, FormQuestionVariants } from 'src/interfaces/IQuestion';
import { otherBtn, questions } from './RadioButtons.style';
import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';
import { useParams } from 'react-router-dom';
import { DefaultLocation, Flows } from 'src/interfaces/IPage';

const RadioButtons: React.FC<RadioFormQuestionProps> = ({
  answer_options,
  key_name,
  isDisabled,
  max_visible_options,
  direction = FormQuestionDirections.vertical,
  variant = FormQuestionVariants.withoutImages,
  customOptionCSS,
  hasError
}) => {
  const { getValues } = useFormContext();
  const answer = getValues(key_name);
  const { gid, flow } = useParams() as DefaultLocation;

  const [areOtherShown, setAreOtherShown] = useState(false);
  const options = answer_options!;
  const maxVisibleOptions = max_visible_options || options.length;
  const primary = options.slice(0, maxVisibleOptions);
  const secondary = options.slice(maxVisibleOptions, options.length);
  const hasSecondarySelected = secondary.some(o => o.value === answer);
  const policyHolderQuestionKey = 'person_is_policyholder';
  const isLifeFlowVariant = flow === Flows.Life;
  const shouldHighlightError = !!hasError;

  const mapOptions = (
    options: Option[],
    onChange: (...event: any[]) => void,
    onBlur: (...event: any[]) => void,
    value: string,
    indexOffset = 0,
    ref?: Ref<HTMLInputElement>
  ) => {
    return options.map((o, i) => (
      <RadioButton
        shouldHighlightError={shouldHighlightError}
        index={indexOffset + i}
        key={o.value}
        isDisabled={isDisabled}
        name={key_name}
        isLifeFlowVariant={isLifeFlowVariant}
        componentRef={i === 0 && ref ? ref : undefined}
        onChange={event => {
          if (key_name === policyHolderQuestionKey) {
            analytics.track(SEGMENT.PH_QUESTION_ANSWERED, gid, flow, {
              answer: event.target.value
            });
          }
          onChange(event);
        }}
        value={o.value}
        label={o.label}
        checked={value === o.value}
        onBlur={onBlur}
        withImage={variant === FormQuestionVariants.withImages}
        customCSS={customOptionCSS}
      />
    ));
  };
  return (
    <Controller
      name={key_name}
      rules={{ required: 'This field is required' }}
      render={({ field: { onChange, value, onBlur, ref } }) => (
        <div css={questions(direction, isLifeFlowVariant)}>
          {mapOptions(primary, onChange, onBlur, value, 0, ref)}
          {secondary.length > 0 && (
            <Fragment>
              {!areOtherShown && !hasSecondarySelected ? (
                <button type="button" onClick={() => setAreOtherShown(true)} css={otherBtn}>
                  Other
                </button>
              ) : (
                mapOptions(secondary, onChange, onBlur, value, primary.length)
              )}
            </Fragment>
          )}
        </div>
      )}
    />
  );
};

export default RadioButtons;
