import { Option } from 'src/interfaces/IQuestion';

interface PolicyData {
  tobacco: string;
  ageRange: string;
  termLengths: number[];
}

interface LifeOption extends Omit<Option, 'value'> {
  value: number | string;
}

const data: PolicyData[] = [
  {
    tobacco: 'no',
    ageRange: '18-50',
    termLengths: [10, 15, 20, 25, 30]
  },
  {
    tobacco: 'no',
    ageRange: '51-55',
    termLengths: [10, 15, 20, 25]
  },
  {
    tobacco: 'no',
    ageRange: '56-60',
    termLengths: [10, 15, 20]
  },
  {
    tobacco: 'no',
    ageRange: '61-65',
    termLengths: [10, 15]
  },
  {
    tobacco: 'no',
    ageRange: '66-74',
    termLengths: [10]
  },
  {
    tobacco: 'yes',
    ageRange: '18-45',
    termLengths: [10, 15, 20, 25, 30]
  },
  {
    tobacco: 'yes',
    ageRange: '46-50',
    termLengths: [10, 15, 20, 25]
  },
  {
    tobacco: 'yes',
    ageRange: '51-55',
    termLengths: [10, 15, 20]
  },
  {
    tobacco: 'yes',
    ageRange: '56-60',
    termLengths: [10, 15]
  },
  {
    tobacco: 'yes',
    ageRange: '61-70',
    termLengths: [10]
  }
];

export const getTermLengths = (age: number | null, tobacco: string | null): LifeOption[] => {
  if (age === null || tobacco === null) return [];

  const policy = data.find(policy => {
    const [min, max] = policy.ageRange.split('-').map(Number);
    return policy.tobacco === tobacco && age >= min && age <= max;
  });

  if (policy) {
    return policy.termLengths.map(length => ({
      description: `${length} years`,
      label: `${length} years`,
      value: length
    }));
  } else {
    return [];
  }
};

export const getLifeFormErrorMessage = (errors: string[], isTobaccoUSed: string) => {
  switch (true) {
    case errors.includes('person_date_of_birth'):
      return `We’re sorry! The only online quotes we have available are for those aged 
              18-${isTobaccoUSed === 'yes' ? '70' : '74'}. 
              Please call us at {{phone}} to explore other options with our licensed advisors.`;
    default:
      return `It looks like you’ve left one or more field(s) unanswered.  
              Please answer all questions to unlock your quotes.`;
  }
};

export const getWarningMessage = (optionsRange: number[]) => {
  const stringEnd =
    optionsRange.length === 0
      ? ''
      : optionsRange.length === 1
        ? `${optionsRange[0]}  yrs.`
        : `between ${optionsRange[0]} - ${optionsRange[1]}  yrs.`;

  return `Based on your Date of Birth and/or Tobacco use, please select a term length ${stringEnd}`;
};

export const inRange = (num: number, range: number[]) => {
  return range.length === 1 ? num === range[0] : num >= range[0] && num <= range[1];
};
