/** @jsxImportSource @emotion/react */
import React, { FormEvent, Fragment } from 'react';
import Button from 'src/kit/Button/Button';
import { disclosuresFormCSS, mobilePhoneCSS, submitButtonCSS } from './DisclosureForm.style';
import useResponsive from 'src/hooks/useResponsive';
import noop from 'src/utils/noop';
import { DisclosureFormProps } from './DisclosureForm.types';

const DisclosureForm: React.FC<DisclosureFormProps> = ({
  CTAText,
  phoneInput,
  shouldRenderPhoneOnMobile = false,
  disabled = false,
  children,
  onFormSubmit = noop,
  isLoading,
  customCSS = {}
}) => {
  const { isMobile } = useResponsive();

  const onSubmit = (evt: FormEvent) => {
    evt.preventDefault();

    onFormSubmit();
  };

  const getButtonsToRender = () => {
    return (
      <Fragment>
        <Button
          variant={'default'}
          customCSS={{ ...submitButtonCSS(), ...customCSS.buttonCSS }}
          isLoading={isLoading}
          disabled={disabled}
          type="submit"
          onClick={onSubmit}
          data-testid="disclosure-form-button"
        >
          {CTAText}
        </Button>
      </Fragment>
    );
  };

  return (
    <form css={[disclosuresFormCSS(), customCSS.formWrapperCSS]} data-testid="disclosure-form">
      {children}

      {shouldRenderPhoneOnMobile && isMobile && (
        <div css={mobilePhoneCSS} data-testid="phone-on-mobile">
          {phoneInput}
        </div>
      )}

      {getButtonsToRender()}
    </form>
  );
};

export default DisclosureForm;
