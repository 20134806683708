import React from 'react';

import { FormQuestionProps } from 'src/interfaces/IQuestion';

import { QuestionWrapper } from 'src/questionsForm/components/QuestionWrapper/QuestionWrapper';
import MaskInput from './MaskInput/MaskInput';

const MaskedInputQuestion: React.FC<FormQuestionProps> = props => (
  <QuestionWrapper {...props} inputComponent={MaskInput} type="tel" />
);

export default MaskedInputQuestion;
