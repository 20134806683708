import { LifeQuoteSelectData } from 'src/components/Quotes/Quotes.types';
import { Flows } from 'src/interfaces/IPage';
import { LifeQuote } from 'src/interfaces/IQuotes';

export const getLifeQuoteSelectedData = (quote: LifeQuote): LifeQuoteSelectData => {
  return {
    gid: quote.gid,
    carrier: quote.carrier,
    premium_value: quote.premium?.value ?? null,
    flow: Flows.Life,
    policy_name: quote?.policy_name,
    policy_type: quote?.policy_type
  };
};
