import { SerializedStyles, css } from '@emotion/react';
import { text12, text20, text32 } from 'src/theme/typography';
import { colors, mq, typography } from 'src/theme/variables';
import { tooltipArrowCSS, tooltipCSS } from 'src/shared/components/Tooltip/Tooltip.style';
import { TooltipComponentsPropsOverrides } from '@mui/material';

export const historicalPremiumContainerCSS = css`
  padding: var(--historical-padding);
  --historical-padding: 16px;

  ${mq[0]} {
    --historical-padding: 32px;
  }
`;

export const historicalWrapperCSS = css`
  display: flex;
  flex-direction: column-reverse;
  gap: 54px;

  ${mq[0]} {
    display: flex;
    flex-direction: row;
  }

  ${mq[1]} {
    gap: 127px;
  }
`;

export const cardCustomCSS = css`
  flex: 0 0 340px;
  padding: 20px;
  box-sizing: border-box;
`;

export const chartWrapperCSS = css`
  flex: 1 1 auto;
  height: 300px;
  position: relative;

  canvas {
    width: 100%;
  }
`;

export const cardTitleCSS = css`
  ${text20};
  font-weight: 700;
  margin: 0;
`;

export const cardNameCSS = css`
  font-weight: 700;
  margin-top: 4px;
`;

export const cardCarrierCSS = css`
  font-weight: 400;
  margin-top: 10px;
`;

export const cardAddressCSS = css`
  font-weight: 700;
`;

export const cardInfoSectionCSS = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 32px;
`;

export const rowCustomDashedColorCSS = (isHighlighted: boolean): SerializedStyles => css`
  border-color: ${colors.nobel};

  span:last-of-type {
    font-weight: 700;
    color: ${isHighlighted ? colors.red : colors.black};
  }
`;

export const textWrapperCSS = css`
  text-align: start;
  max-width: 706px;
  margin: 0 auto;
  margin-bottom: 21px;

  ${mq[0]} {
    text-align: center;
  }
`;

export const historicalPremiumTitleCSS = css`
  margin-bottom: 8px;
  margin-top: 0;
  ${text20};

  ${mq[0]} {
    ${text32};
    margin-bottom: 4px;
  }
`;

export const historicalPremiumDescCSS = css`
  font-weight: 500;

  span {
    color: ${colors.red};
  }
`;

export const historicalPremiumTooltipCSS = css`
  ${text12};
  margin-top: 12px;
  color: ${colors.azure};
  text-decoration: underline !important;
  font-family: ${typography.fontFamily}, sans-serif;
  text-transform: none;
`;

export const chartTitleCSS = css`
  position: absolute;
  top: 0;
  margin: 0;

  ${mq[0]} {
    ${text20};
  }
`;

export const chartLabelCSS = (left: number): SerializedStyles => css`
  position: absolute;
  left: ${left}px;
  bottom: 10px;
  ${text12};
  font-weight: '700';
`;

export const tooltipCustomStyles = (isMobile: boolean): TooltipComponentsPropsOverrides => ({
  tooltip: {
    sx: {
      ...tooltipCSS,
      maxWidth: `${isMobile ? 245 : 356}px`,
      fontSize: '13px',
      lineHeight: '16px',
      color: colors.black,
      border: `1px solid ${colors.silver}`,
      padding: '20px',
      boxShadow: '2px 4px 0px 0px rgba(0, 0, 0, 0.12)'
    }
  },
  arrow: {
    sx: {
      ...tooltipArrowCSS,
      '&::before': {
        border: `1px solid ${colors.silver}`,
        backgroundColor: '#fff',
        boxSizing: 'border-box'
      }
    }
  }
});
