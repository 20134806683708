import { QueryObserverResult, useQuery } from 'react-query';
import { SavingsResponse } from 'src/interfaces/ISavings';
import { QUERY_CACHE_KEYS } from 'src/constants/queryCacheKeys';
import api from 'src/api/api';
import { API_URLS } from 'src/constants/api';
import { getApiUrl } from 'src/utils/api.helpers';

const useSavingsQuotes = (gid: string): QueryObserverResult<SavingsResponse> => {
  return useQuery([QUERY_CACHE_KEYS.SAVINGS_QUOTES, gid], () => api.get(getApiUrl(API_URLS.SAVINGS, gid)));
};

export default useSavingsQuotes;
