import { css, SerializedStyles } from '@emotion/react';
import { borderRadius, colors, sizes, typography, mq } from 'src/theme/variables';
import CalendarIcon from 'src/assets/svg/calendar-grey.svg';

export const inputWrapper = css`
  position: relative;
`;

export const prefilledValueCSS = (isPrefilled: boolean, isLifeFlowVariant?: boolean) => css`
  ${!isLifeFlowVariant &&
  isPrefilled &&
  css`
    border: 2px solid ${colors.azure};
    transition: border-color 0.2s ease;

    &:focus {
      border: 1px solid ${colors.black};
    }
  `}
`;

export const inputCSS = (
  isInvalid?: boolean,
  isDisabled?: boolean,
  hasPrefix = false,
  isLifeFlowVariant?: boolean,
  isDate?: boolean
): SerializedStyles => css`
  background-color: ${colors.white};
  border: 1px solid ${colors.black};
  border-radius: ${borderRadius}px;
  box-sizing: border-box;
  color: ${colors.black};
  font-family: ${typography.fontFamily};
  font-size: ${typography.fontSize};
  height: ${sizes.controlHeight}px;
  outline: none;
  padding-left: ${sizes.inputPaddingHorizontal}px;
  padding-right: ${sizes.inputPaddingHorizontal}px;
  width: 100%;
  transition:
    border-color 0.2s ease,
    color 0.2s ease;
  position: relative;
  z-index: 1;
  font-variant: lining-nums;
  appearance: none;

  &:hover {
    border-color: ${colors.azure62};
  }

  ${isLifeFlowVariant &&
  css`
    height: 44px;
    padding-left: 12px;
    margin-left: 12px;

    ${mq[0]} {
      width: 140px;
    }

    ${isDate &&
    css`
      margin-left: 0px;
      background: white url(${CalendarIcon}) right no-repeat;
      background-position-x: 97%;

      ${mq[0]} {
        background-position-x: 92%;
      }
    `}
  `}

  ${isInvalid &&
  css`
    &,
    &:hover {
      padding-left: ${isLifeFlowVariant ? '12px' : sizes.inputPaddingHorizontal - 1}px;
      padding-right: ${sizes.inputPaddingHorizontal - 1}px;
      color: ${colors.red};
      border-color: ${colors.red};
      border-width: 2px;
    }

    &:focus {
      border-color: ${colors.black};
      border-width: 1px;
      transition: border-color 0.2s ease;
    }
  `};

  ${isDisabled &&
  css`
    background-color: ${colors.mercury};
    cursor: not-allowed;
  `}

  &:focus + .input-focus-helper {
    transition: border-color 0.2s ease;
    border-color: ${isInvalid ? colors.red : colors.azure};
  }

  ${hasPrefix &&
  css`
    &,
    &:hover,
    &:focus {
      padding-left: 30px;
      padding-right: 20px;
    }
  `};
`;

export const focusHelperCSS = css`
  position: absolute;
  inset: -4px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  border-radius: 7px;
  border: 2px solid transparent;
  transition: border-color 0.2s ease;
`;
