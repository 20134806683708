import { css } from '@emotion/react';

export const container = css`
  display: inline-block;
`;

export const image = css`
  display: block;
  max-width: 100%;
`;
