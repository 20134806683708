/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';

import { useFieldArray } from 'react-hook-form';

import EntityList from 'src/questionsForm/components/EntityList/EntityList';
import Greeting from 'src/questionsForm/components/Greeting/Greeting';
import { HookFormFieldEntity } from 'src/interfaces/IPage';
import EntityDetails from 'src/questionsForm/components/EntityDetails/EntityDetails';
import { scrollToNode } from 'src/utils/scroll';
import { entityFormPage } from './EntityFormPage.style';
import { QuestionsFormPageProps } from 'src/questionsForm/types/questionsFormPage.types';

const EntityFormPage: React.FC<QuestionsFormPageProps> = ({
  greeting,
  list,
  form,
  isCurrent,
  show_consent,
  onPageSubmit,
  key_name,
  triggerSchemaUpdate,
  uploadFilesWrapperParams,
  isFormSubmitting
}) => {
  const [areDetailsVisible, setAreDetailsVisible] = useState(false);

  useEffect(() => {
    isCurrent && setTimeout(() => scrollToNode(`section-${key_name}`), 100);
  }, [key_name, isCurrent]);

  const { fields, append, remove } = useFieldArray({
    name: key_name
  });

  const onAfterSubmit = () => {
    setAreDetailsVisible(true);
    setTimeout(() => {
      scrollToNode(`${key_name}.0`);
    }, 100);
  };

  const detailsVisible = areDetailsVisible || !isCurrent;

  return (
    <div css={entityFormPage} id={`section-${key_name}`}>
      {greeting && Object.keys(greeting).length > 0 && <Greeting keyName={key_name} {...greeting} />}

      <EntityList
        list={list!}
        entities={fields as HookFormFieldEntity[]}
        onAfterSubmit={onAfterSubmit}
        isCurrent={isCurrent}
        key_name={key_name}
        detailsVisible={detailsVisible}
        append={append}
        remove={remove}
      />

      {detailsVisible && (
        <EntityDetails
          entities={fields as HookFormFieldEntity[]}
          form={form!}
          entityKey={key_name}
          isCurrentStep={isCurrent}
          show_consent={show_consent}
          onPageSubmit={onPageSubmit}
          triggerSchemaUpdate={triggerSchemaUpdate}
          uploadFilesWrapperParams={uploadFilesWrapperParams}
          isFormSubmitting={isFormSubmitting}
        />
      )}
    </div>
  );
};

export default EntityFormPage;
