import { css, SerializedStyles } from '@emotion/react';

import { mq } from 'src/theme/variables';

import wave from 'src/assets/svg/wave-shape.svg';
import SIRV from 'src/constants/sirv';

export const hero = (flowsLength: number): SerializedStyles => css`
  background: url(${SIRV.LANDING_HERO_IMAGE}) no-repeat center top;
  background-size: cover;
  display: flex;
  min-height: 300px;
  margin-bottom: ${flowsLength === 3 ? 320 : 180}px;
  position: relative;
  ${mq[0]} {
    min-height: 475px;
    margin-bottom: ${flowsLength === 3 ? 360 : 170}px;
  }
  ${mq[1]} {
    background-attachment: fixed;
    background-size: 142%;
    margin-bottom: 200px;
  }
  ${mq[2]} {
    background-size: cover;
  }
`;

export const heroWaveLine = css`
  background: url(${wave}) no-repeat 50% bottom;
  background-size: 101% auto;
  bottom: -1px;
  height: 50px;
  overflow: hidden;
  position: absolute;
  width: 100%;
  ${mq[1]} {
    height: 160px;
  }
`;
