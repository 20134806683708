import { Step } from './HowItWorksSteps.types';

export const getAllFunnelLookupSteps = (isNewRez: boolean): Step[] => [
  {
    heading: 'Don’t Lift a Finger',
    img: 'https://maticinsurance.sirv.com/illustrations/click.png',
    imgAlt: 'Person measuring a wall with tape measure',
    subHeading: '(except to click)',
    description:
      'Matic does all the legwork upfront using your current policy and property info. No need to worry about ' +
      'answering a ton of questions or uploading documents. Matic’s got you covered.'
  },
  {
    heading: 'Hold on to Your Hat',
    img: 'https://maticinsurance.sirv.com/illustrations/compare.png',
    imgAlt: 'Balance scales',
    description: isNewRez
      ? 'In seconds, Matic compares your policy against top-rated carriers and presents you with quotes. ' +
        'It’s a fast and convenient way to shop for home insurance.'
      : 'In seconds, Matic compares your policy against top-rated carriers and delivers the best quotes available. ' +
        'It’s the fastest and most convenient way to shop for home insurance.'
  },
  {
    heading: 'Sit Back and Relax',
    img: 'https://maticinsurance.sirv.com/illustrations/relax.png',
    imgAlt: 'Person sitting on desk with thumbs up',
    description: isNewRez
      ? ' Unlike other comparison sites, Matic is a full-service licensed agency. Matic helps manage the paperwork ' +
        'and details of your new policy, and even shops your policy again before renewal so you can set it and ' +
        'forget it. With Matic, you don’t have to think about insurance ever again.'
      : 'Unlike other comparison sites, Matic is a full-service licensed agency. Matic helps manage the paperwork ' +
        'and details of your new policy, and even shops your policy again before renewal so you’ll always have the ' +
        'best rate. With Matic, you’ll never have to think about insurance again.'
  }
];

export const getCallMeNowSteps = (): Step[] => [
  {
    heading: 'You’ll receive a call from a Matic advisor',
    img: 'https://maticinsurance.sirv.com/illustrations/girl-with-circle.svg',
    imgAlt: 'Smiling advisor',
    description:
      'A licensed Matic advisor will call you shortly to discuss your new policy details and answer ' +
      'any questions you may have. Keep an eye out for a call from Matic Insurance.'
  },
  {
    heading: 'Be prepared to answer a few questions',
    img: 'https://maticinsurance.sirv.com/illustrations/plain-house.svg',
    imgAlt: 'House image',
    description: (
      <span>
        Details you will need to provide include: <br />
        <ul>
          <li>Roof type, material, and age</li>
          <li>Foundation details </li>
          <li>Garage size </li>
          <li> Updates to your plumbing and electrical system</li>
        </ul>
        <p>If you don’t have this information, our advisors can help guide you to find them.</p>
      </span>
    )
  },
  {
    heading: 'Ask about auto!',
    img: 'https://maticinsurance.sirv.com/illustrations/auto-license.svg',
    imgAlt: 'Car license',
    description:
      'Matic does more than just homeowner’s insurance. Ask about bundling your home policy with ' +
      'an auto policy to save up to 20%.'
  }
];
