import defaultAuto from 'src/assets/svg/default-vehicle-icon.svg';

/**
 * Add new vehicle:
 * * add svg icon file to sirv (file name should be lower case without spaces, should match auto name form server.
 *   ex: alfa_romeo)
 * * add key from file name to allowedIconKeys array
 * */
const allowedIconKeys = [
  'acura',
  'alfa_romeo',
  'audi',
  'bmw',
  'buick',
  'cadillac',
  'chevrolet',
  'chrysler',
  'daewoo',
  'dodge',
  'ferrari',
  'fiat',
  'ford',
  'genesis',
  'gmc',
  'honda',
  'hyundai',
  'infiniti',
  'jaguar',
  'jeep',
  'kia',
  'land_rover',
  'lexus',
  'lincoln',
  'mazda',
  'mercedes-benz',
  'mini',
  'mitsubishi',
  'nissan',
  'oldsmobile',
  'pontiac',
  'porsche',
  'ram',
  'saab',
  'smart',
  'subaru',
  'suzuki',
  'tesla',
  'toyota',
  'volkswagen',
  'volvo'
];
const vehicleIconBaeUrl = 'https://maticinsurance.sirv.com/ds_vehicle_icons/{vehicle_key}.svg';

export const getVehicleIconUrl = (headingIconValue: string): string => {
  const key = headingIconValue.trim().toLowerCase().replace(' ', '_');
  return allowedIconKeys.indexOf(key) > -1 ? vehicleIconBaeUrl.replace('{vehicle_key}', key) : defaultAuto;
};
