/** @jsxImportSource @emotion/react */
import React, { Fragment } from 'react';
import { LookupErrorKeys } from 'src/components/LookupLanding/LookupLanding.types';
import Dialog from 'src/kit/Dialog/Dialog';
import Button from 'src/kit/Button/Button';
import {
  calendlyContainerCSS,
  calendlyWidgetCSS,
  dialogContentCSS,
  headingCSS,
  submitButtonCSS
} from './LookupDialog.style';
import { LookupDialogProps } from './LookupDialog.types';
import ScheduleCall from 'src/components/ScheduleCall/ScheduleCall';
import { AUTOMATION_TEST_IDS } from 'src/constants/automationTestIds';

const LookupDialog: React.FC<LookupDialogProps> = ({ error, closeModal, isOpen }) => {
  const heading =
    LookupErrorKeys.SESSION_LOCKED === error
      ? "Matic Couldn't Confirm Your Information by ZIP Code"
      : 'Sorry, We could not Match Your information';
  const buttonText = LookupErrorKeys.INVALID_ZIP === error ? 'try the online form again' : 'enter your info manually';

  const isRedirectToLanding = [LookupErrorKeys.SESSION_LOCKED, LookupErrorKeys.INVALID_LOAN].indexOf(error) > -1;
  const allowClose = LookupErrorKeys.INVALID_ZIP === error;

  const dialogHeading = () => (
    <Fragment>
      <p css={headingCSS}>{heading}</p>
      <p>Please, select the time when our agency will call you</p>
      <p>or you can</p>
      <Button
        variant={'text'}
        css={submitButtonCSS}
        onClick={() => closeModal(isRedirectToLanding)}
        data-testid={AUTOMATION_TEST_IDS.LOOKUP_DIALOG_SUBMIT_BUTTON}
      >
        {buttonText}
      </Button>
    </Fragment>
  );

  return (
    <Dialog fullscreen showHeader isOpen={isOpen} {...(allowClose && { onRequestClose: () => closeModal(false) })}>
      <div css={dialogContentCSS}>
        <ScheduleCall
          shouldHideFinalHeading
          shouldHideTeam
          customHeading={dialogHeading()}
          customCSS={{ container: calendlyContainerCSS, widget: calendlyWidgetCSS }}
        />
      </div>
    </Dialog>
  );
};

export default LookupDialog;
