/** @jsxImportSource @emotion/react */
import React from 'react';
import { contentWrapperCSS } from './../AlmostDone/AlmostDone.style';
import {
  coverageContentWrapperCSS,
  copyWrapperCSS,
  quoteWrapperCSS,
  copyItemWrapperCSS,
  copyItemTitleCSS,
  copyItemDescriptionCSS,
  otherQuotesWrapperCSS,
  detailsItemCSS,
  coverageCSS,
  carrierLogoCSS,
  deductibleWrapperCSS,
  premiumCSS,
  adjustQuoteCSS,
  otherItemsWrapperCSS,
  otherItemWrapperCSS,
  otherQuotesPremiumCSS,
  quoteCardCSS,
  premiumWrapperCSS,
  additionalSavingsCSS
} from './HomeQuoteSummary.style';
import { headerCopyCSS, headingCSS, wrapperCSS } from './../HomeCoverages/HomeCoverage.style';

import PagesFooter from './../PagesFooter/PagesFooter';
import UserProfileDetailsItem from 'src/components/Quotes/StandardHouseQuotes/QuotesUserProfile/UserProfileDetailsItem/UserProfileDetailsItem';
import { coverageByKey, getHomeQuoteCoverages, getSideCopies } from './HomeQuoteSummary.utils';
import Anchor from 'src/kit/Anchor/Anchor';
import Format from 'src/utils/format';
import { HomeCoverageKey } from 'src/interfaces/quotesView/IQuote';
import { HomeQuoteSummaryProps } from './HomeQuoteSummary.types';

const MOCK_PROPS = {
  currentPage: 2,
  pagesCount: 4,
  aRated: true
};

const HomeQuoteSummary: React.FC<HomeQuoteSummaryProps> = ({
  config,
  bestQuote,
  alternativeQuotes,
  proposalBuilderExperiment
}) => (
  <div css={wrapperCSS}>
    <div css={contentWrapperCSS}>
      <div css={headingCSS}>Your home insurance quote</div>
      <div css={headerCopyCSS}>
        Matic compared quotes from {config.a_rated_carriers_count}+ A-rated insurance carriers to match you with the
        right policy for the best price. Take a look at the policy details.
      </div>
    </div>

    <div css={coverageContentWrapperCSS}>
      <div css={quoteWrapperCSS}>
        <div css={quoteCardCSS}>
          <img src={bestQuote.carrier_logo_url} height="64" alt="Carrier Logo" css={carrierLogoCSS} />
          <div css={coverageCSS}>Coverages</div>

          <div>
            {getHomeQuoteCoverages(bestQuote).map((item, i) => (
              <div key={i}>
                <UserProfileDetailsItem
                  fullWidth
                  customCSS={detailsItemCSS}
                  label={item.title}
                  value={item.value || 'Not included'}
                />
              </div>
            ))}
            <hr />
            <div css={[coverageCSS, deductibleWrapperCSS]}>
              <div>Deductible</div>
              <div>{coverageByKey(bestQuote, HomeCoverageKey.Dwelling)?.deductible_description || 'Not included'}</div>
            </div>
          </div>
        </div>
        <div css={premiumWrapperCSS}>
          <span css={premiumCSS}>
            {Format.money(bestQuote.premium || '', 2, '$')}
            {!proposalBuilderExperiment && <sup>*</sup>}
          </span>
          /yr
        </div>
      </div>

      <div css={copyWrapperCSS}>
        <div>
          {getSideCopies({
            carrierName: bestQuote.carrier_name,
            aRated: MOCK_PROPS.aRated,
            proposalBuilderExperiment
          }).map((item, index) => (
            <div key={index} css={copyItemWrapperCSS}>
              <div css={copyItemTitleCSS}>{item.title}</div>
              <div css={copyItemDescriptionCSS}>{item.description}</div>
            </div>
          ))}

          <div css={adjustQuoteCSS}>
            {!proposalBuilderExperiment && <div css={coverageCSS}>Adjust your quote</div>}
            {proposalBuilderExperiment && (
              <div css={additionalSavingsCSS}>
                Additionally, bundling home and auto insurance can lead to a 16% savings.
              </div>
            )}
            {!proposalBuilderExperiment && (
              <div>
                Need to adjust your coverage or have a question? Call us at{' '}
                <Anchor href={`tel:${config.agency_phone_number}`}>{config.agency_phone_number}</Anchor>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>

    {!!alternativeQuotes.length && (
      <div css={otherQuotesWrapperCSS}>
        <div css={coverageCSS}> Other quotes for you</div>

        <div css={otherItemsWrapperCSS}>
          {alternativeQuotes.map(alternativeQuote => (
            <div key={alternativeQuote.gid} css={otherItemWrapperCSS}>
              <img src={alternativeQuote.carrier_logo_url} alt={alternativeQuote.carrier_name} />
              <div>
                <span css={otherQuotesPremiumCSS}>{Format.money(alternativeQuote.premium || '', 2, '$')}</span>*
              </div>
            </div>
          ))}
        </div>
      </div>
    )}

    <PagesFooter
      footerCopy={
        proposalBuilderExperiment
          ? ''
          : '*Offers are estimates only. Your actual rate, payment, and coverage may be different'
      }
      currentPage={MOCK_PROPS.currentPage}
      pagesCount={MOCK_PROPS.pagesCount}
      variant="white-styled"
    />
  </div>
);

export default HomeQuoteSummary;
