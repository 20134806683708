/** @jsxImportSource @emotion/react */
import * as React from 'react';
import { useParams } from 'react-router-dom';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';

import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';
import { DefaultLocation, Flows } from 'src/interfaces/IPage';

import { toggleButtonCSS } from './AFBundleToggleComponent.style';
import { AFBundleToggleComponentProps } from './AFBundleToggleComponent.types';

const AFBundleToggleComponent: React.FC<AFBundleToggleComponentProps> = ({ setAFToggleOn }) => {
  const { gid } = useParams() as DefaultLocation;
  const [isToggleOn, setIsToggleOn] = React.useState<boolean>(true);

  const handleToggleClicked = (_event: React.MouseEvent<HTMLElement>, switchMode: boolean) => {
    analytics.track(SEGMENT.AF_QUOTES_BUNDLE_CLICKED, gid, Flows.AllFunnel, {
      isBundleOn: switchMode
    });

    setIsToggleOn(switchMode);
    setAFToggleOn(switchMode);
  };

  return (
    <ToggleButtonGroup value={isToggleOn} exclusive aria-label="Bundle toggle group">
      <ToggleButton css={toggleButtonCSS(isToggleOn)} value={true} onClick={handleToggleClicked}>
        Bundle discount on
      </ToggleButton>
      <ToggleButton css={toggleButtonCSS(!isToggleOn)} value={false} onClick={handleToggleClicked}>
        Bundle off
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default AFBundleToggleComponent;
