/** @jsxImportSource @emotion/react */
import React from 'react';

import { container, image } from './SirvImage.style';
import { ImgUrlParams, SirvImageProps } from './types';

const getImgUrlWithParams = ({ height = 0, width, url, multiplier = 1 }: ImgUrlParams) => {
  const imgUrl = new URL(url);
  width = (Number(imgUrl.searchParams.get('w')) || width) * multiplier;
  height = (Number(imgUrl.searchParams.get('h')) || height) * multiplier;

  imgUrl.searchParams.set('w', width.toString());
  imgUrl.searchParams.set('h', height.toString());

  return imgUrl.toString();
};

const SirvImage: React.FC<SirvImageProps> = props => {
  const { className, alt, customCSS, isLazyLoad = true } = props;
  const imgSrcX1 = getImgUrlWithParams(props);
  const imgSrcX2 = getImgUrlWithParams({ ...props, multiplier: 2 });

  return (
    <div className={className} css={[container, customCSS]}>
      <img
        css={image}
        src={imgSrcX1}
        srcSet={`${imgSrcX1} 1x, ${imgSrcX2} 2x`}
        alt={alt}
        {...(isLazyLoad && { loading: 'lazy' })}
      />
    </div>
  );
};

export default SirvImage;
