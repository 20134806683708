/** @jsxImportSource @emotion/react */

import { Button, ClickAwayListener, Tooltip } from '@mui/material';
import { historicalPremiumTooltipCSS, tooltipCustomStyles } from './HistoricalPremium.style';
import useResponsive from 'src/hooks/useResponsive';
import React, { useState } from 'react';

const TOOLTIP_TEXT = `*This estimate is based on your current premium and the average percent increase of premiums 
in your state renewed this year and next year with the same carrier and similar premium to yours. 
Rates and discounts vary, are determined by many factors, and are subject to change.`;
const TOOLTIP_VISIBLE_TEXT = '*Here’s how we calculate these estimates';

interface Props {
  label?: string;
}

const HistoricalPremiumTooltip: React.FC<Props> = ({ label = TOOLTIP_VISIBLE_TEXT }) => {
  const { isMobile } = useResponsive();
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return isMobile ? (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <Tooltip
          PopperProps={{
            disablePortal: true
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={TOOLTIP_TEXT}
          arrow
          componentsProps={tooltipCustomStyles(true)}
          placement="top"
        >
          <Button variant="text" onClick={handleTooltipOpen} css={historicalPremiumTooltipCSS}>
            {label}
          </Button>
        </Tooltip>
      </div>
    </ClickAwayListener>
  ) : (
    <Tooltip placement="top" title={TOOLTIP_TEXT} arrow componentsProps={tooltipCustomStyles(false)}>
      <div css={historicalPremiumTooltipCSS}>{label}</div>
    </Tooltip>
  );
};

export default HistoricalPremiumTooltip;
