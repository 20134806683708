import { css } from '@emotion/react';
import { text, text20 } from 'src/theme/typography';
import { mq, colors as c } from 'src/theme/variables';

export const containerCSS = css`
  display: flex;
  align-items: center;
  ${text};

  ${mq[0]} {
    padding-top: 0;
  }
`;

export const phoneInputCSS = css`
  width: 100%;
  height: 60px;
  background-color: white;
`;

export const mobileIconCSS = css`
  height: 24px;
  width: 24px;
  margin-right: 12px;
  ${mq[0]} {
    margin-right: 0;
  }
`;

export const viewEditFieldsInputCSS = css`
  ${text20};
  background-color: ${c.concrete};
  border: 1px solid ${c.warmGray};
  border-radius: 4px;
  box-sizing: border-box;
  color: ${c.black};
  height: 42px;
  outline: none;
  padding-left: 8px;
  padding-right: 8px;
  transition:
    border-color 0.2s ease,
    color 0.2s ease;

  &:focus {
    border-color: ${c.warmGray};
    background-color: ${c.white};
  }
`;

export const labelCSS = css`
  width: 100%;

  ${mq[0]} {
    width: 100%;
  }
`;
