import { create } from 'zustand';

type Answers = {
  term: number | null;
  coverage_amount: number | null;
};

interface State {
  isButtonDisabled: boolean;
  isQuotesScreenLocked: boolean;
  isBasedBlockVisible: boolean;
  shouldStartQuoting: boolean;
  currentAnswersState: Answers;
  shouldShowWarning: boolean;
  currentOptionsRange: number[];
}

interface Action {
  actions: {
    setIsQuotesScreenLocked: (value: boolean) => void;
    setIsButtonDisabled: (value: boolean) => void;
    setIsBasedBlockVisible: (value: boolean) => void;
    setShouldStartQuoting: (value: boolean) => void;
    setCurrentAnswersState: (values: Answers) => void;
    setShowWarning: (value: boolean) => void;
    setCurrentOptionsRange: (value: number[]) => void;
  };
}

export const useLifeStates = create<State & Action>((set, get) => ({
  isQuotesScreenLocked: true,
  isButtonDisabled: false,
  isBasedBlockVisible: false,
  shouldStartQuoting: false,
  currentAnswersState: {
    term: null,
    coverage_amount: null
  },
  shouldShowWarning: false,
  currentOptionsRange: [],
  actions: {
    setIsQuotesScreenLocked: value => set(() => ({ isQuotesScreenLocked: value })),
    setIsButtonDisabled: value => set(() => ({ isButtonDisabled: value })),
    setIsBasedBlockVisible: value => set(() => ({ isBasedBlockVisible: value })),
    setShouldStartQuoting: value => set(() => ({ shouldStartQuoting: value })),
    setCurrentAnswersState: value => set(() => ({ currentAnswersState: value })),
    setShowWarning: value =>
      set(() => {
        get().actions.setIsBasedBlockVisible(false);
        return { shouldShowWarning: value };
      }),
    setCurrentOptionsRange: value => set(() => ({ currentOptionsRange: value }))
  }
}));

export const useLifeQuotesStateData = (): State =>
  useLifeStates(state => ({
    isQuotesScreenLocked: state.isQuotesScreenLocked,
    isButtonDisabled: state.isButtonDisabled,
    isBasedBlockVisible: state.isBasedBlockVisible,
    shouldStartQuoting: state.shouldStartQuoting,
    currentAnswersState: state.currentAnswersState,
    shouldShowWarning: state.shouldShowWarning,
    currentOptionsRange: state.currentOptionsRange
  }));
export const useLifeQuotesStatesActions = (): Action['actions'] => useLifeStates(state => state.actions);
