/** @jsxImportSource @emotion/react */
import React from 'react';

import { Controller } from 'react-hook-form';

import { FieldProps } from 'src/interfaces/IQuestion';
import Input from 'src/shared/components/Input/Input';
import { useFormContext } from 'react-hook-form';
import validateDriversLicense from 'src/utils/driversLicenseValidation';

interface InputProps extends FieldProps {
  hasError?: boolean;
  onChange?: (...event: any[]) => void;
}

const DriverLicenseInput: React.FC<InputProps> = ({ hasError = false, key_name, isDisabled, inputId, ...props }) => {
  const { getValues, clearErrors, setError } = useFormContext();

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const regex = new RegExp(/^\*{4}.{4}$/);
    const conditionToSkipValidate = regex.test(e.target.value);

    if (key_name.includes('driver_license_number')) {
      const getDriverSequence = key_name.split('[').pop()?.split(']')[0];

      if (!conditionToSkipValidate) {
        const driverLicenseState: string | undefined = getDriverSequence
          ? getValues().drivers[+getDriverSequence].driver_license_state
          : undefined;

        if (driverLicenseState && e.target.value) {
          const m = validateDriversLicense(driverLicenseState, e.target.value);
          if (!m.isValid) {
            setError(key_name, {
              type: 'required',
              message: m.validationError
            });
          } else {
            clearErrors();
          }
        } else {
          clearErrors();
        }
      }
    }
  };

  return (
    <Controller
      name={key_name}
      render={({ field }) => (
        <div>
          <Input
            {...props}
            {...field}
            onChange={e => {
              props.onChange && props.onChange(e);
              field.onChange(e);
            }}
            type="text"
            hasError={hasError}
            id={inputId}
            maxLength={18}
            value={field.value || ''}
            name={key_name}
            disabled={isDisabled}
            onBlur={onBlur}
          />
        </div>
      )}
    />
  );
};

export default DriverLicenseInput;
