import { css, SerializedStyles } from '@emotion/react';

import { colors } from 'src/theme/variables';

export const addressOption = (isFocused?: boolean, isSelected?: boolean): SerializedStyles => css`
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  position: relative;
  text-align: left;
  width: 100%;
  padding: 8px 16px;
  font-variant: lining-nums;

  ${isFocused &&
  css`
    background-color: ${colors.mercury};
  `}

  &:hover {
    background-color: ${colors.mercury};
  }

  ${isSelected &&
  css`
    &,
    &:hover {
      background-color: ${colors.azure};
      color: ${colors.white};
    }
  `}
`;

export const entries = css`
  color: ${colors.azure};
`;

export const caret = css`
  height: 12px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 12px;
`;
