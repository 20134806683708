import { EXPERIMENT_VARIANTS_ENUM, FEATURE_TOGGLES_IDS_ENUM as FT } from 'src/interfaces/experiment.types';
import { EXPERIMENT_REPORT_VARIANTS } from 'src/constants/abTesting';
import queryString from 'query-string';
import {
  FTInitializeType,
  FTUtilsReturnedType,
  FTUtilsType
} from 'src/hooks/useInitFeatureToggles/useInitFeatureToggles.types';
import { FeatureToggle } from 'src/interfaces/config.types';
import { Flows } from 'src/interfaces/IPage';
import SEGMENT from 'src/constants/segment';
import analytics from 'src/utils/analytics';

export const getFTFromURL = (name: FT, search = ''): FeatureToggle | null => {
  const variant = queryString.parse(search.replace('?', ''))[name] as EXPERIMENT_VARIANTS_ENUM;

  return variant ? { variant, name, enabled: true } : null;
};

export const getFTConfig: FTUtilsType<FTUtilsReturnedType> = (toggles, search, gid, flow, name) => {
  const toggle = getFTFromURL(name, search) || toggles.find(t => t.name === name);
  const { enabled = false, variant = null } = toggle || {};
  const reportVariation = EXPERIMENT_REPORT_VARIANTS[variant as EXPERIMENT_VARIANTS_ENUM];

  return {
    isEnabled: enabled,
    variation: variant,
    reportVariation,
    isControlVariation: variant === EXPERIMENT_VARIANTS_ENUM.CONTROL,
    name,
    track(callback?: any) {
      if (enabled) {
        analytics.track(SEGMENT.EXPERIMENT_VIEWED, gid, flow, {
          experiment_id: name,
          variation_id: reportVariation,
          gid
        });
        callback?.();
      }
    }
  };
};

const initializeFTConfig =
  (toggles: FeatureToggle[] | [], search: string, gid: string, flow: Flows): FTInitializeType =>
  name =>
    getFTConfig(toggles, search, gid, flow, name);

export default initializeFTConfig;
