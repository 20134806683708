/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';

import {
  actionButton,
  actions,
  container,
  details,
  detailsAction,
  detailsActionButton,
  metadataWrapper,
  metaItem,
  metaLabel,
  metaValue,
  premium as premiumCSS,
  premiumLabel,
  premiumValue,
  premiumValueSuffix,
  showButton,
  summary,
  summaryLeft,
  summaryRight
} from './QuoteCardBase.style';
import Expander from 'src/components/Expander/Expander';
import Button from 'src/kit/Button/Button';
import noop from 'src/utils/noop';
import { QuoteCardBaseProps } from './QuoteCardBase.types';
import OnlineBingLabel from 'src/components/Quotes/OnlineBingLabel/OnlineBingLabel';
import useResponsive from 'src/hooks/useResponsive';
import { AUTOMATION_TEST_IDS } from 'src/constants/automationTestIds';

export const LABELS = {
  ACTION_BUTTON: 'Choose Quote',
  SHOW_DETAILS: 'Show Details',
  HIDE_DETAILS: 'Hide Details',
  SHOW_LESS: 'Show Less'
};

const QuoteCardBase: React.FC<QuoteCardBaseProps> = ({
  customCSS,
  testId = AUTOMATION_TEST_IDS.QUOTE.CARD,
  children,
  actionLabel = LABELS.ACTION_BUTTON,
  premium,
  eligibility,
  metadata,
  detailsComponent,
  showDetailsButton = true,
  cardDirection = 'row',
  fullWidthActions = false,
  buyOnlineQuote = false,
  showLessVariant = false,
  onActionClick = noop,
  onShowDetails = noop,
  shouldHideDetailsButton = false,
  shouldHideSelectButton = false
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { isMobile } = useResponsive();

  const toggleDetails = () => {
    if (!isOpen || isMobile) {
      onShowDetails();
    }
    setIsOpen(s => !s);
  };

  const getDetailsButtonLabel = (): string => {
    if (isOpen && !isMobile) {
      return showLessVariant ? LABELS.SHOW_LESS : LABELS.HIDE_DETAILS;
    }
    return LABELS.SHOW_DETAILS;
  };

  const shouldHideChooseButtonDesktop = shouldHideSelectButton && !isMobile;
  const olbAutomationTestProp = buyOnlineQuote ? { 'data-olbquote': '' } : {};

  return (
    <div css={[container, customCSS]} data-testid={testId} data-eligibility={eligibility} {...olbAutomationTestProp}>
      <div css={summary(cardDirection)}>
        {buyOnlineQuote && <OnlineBingLabel>Buy Directly Online!</OnlineBingLabel>}
        <div css={summaryLeft(cardDirection === 'column')}>{children}</div>
        <div css={summaryRight(cardDirection === 'column')}>
          {!!metadata && (
            <div css={metadataWrapper}>
              {metadata.map(item => (
                <div key={item.label} css={metaItem}>
                  <div css={metaValue}>{item.value}</div>
                  <div css={metaLabel}>{item.label}</div>
                </div>
              ))}
            </div>
          )}
          {premium && (
            <div css={premiumCSS}>
              <div css={premiumValue(premium.valueSize)} data-testid={AUTOMATION_TEST_IDS.QUOTE.PREMIUM}>
                {premium.value}
                {!!premium.valueSuffix && (
                  <span css={premiumValueSuffix(premium.valueSize)}>{premium.valueSuffix}</span>
                )}
              </div>
              <div css={premiumLabel}>{premium.label}</div>
            </div>
          )}

          <div css={actions(fullWidthActions)}>
            {!shouldHideChooseButtonDesktop && (
              <Button
                customCSS={actionButton}
                data-testid={AUTOMATION_TEST_IDS.QUOTE.SELECT_BUTTON}
                onClick={onActionClick}
              >
                {actionLabel}
              </Button>
            )}
            {showDetailsButton && !shouldHideDetailsButton && (
              <Button
                customCSS={showButton}
                variant="text"
                aria-expanded={isOpen ? 'true' : 'false'}
                data-testid="quote-details-button"
                onClick={toggleDetails}
              >
                {getDetailsButtonLabel()}
              </Button>
            )}
          </div>
        </div>
      </div>
      {isOpen && !isMobile && (
        <Expander isExpanded>
          <div css={details}>{detailsComponent}</div>
          {showDetailsButton && (
            <div css={detailsAction}>
              <Button customCSS={detailsActionButton} variant="text" aria-expanded="true" onClick={toggleDetails}>
                {showLessVariant ? LABELS.SHOW_LESS : LABELS.HIDE_DETAILS}
              </Button>
            </div>
          )}
        </Expander>
      )}
    </div>
  );
};

export default QuoteCardBase;
