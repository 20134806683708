import { css, SerializedStyles } from '@emotion/react';
import { mq, colors } from 'src/theme/variables';
import { text24, text } from 'src/theme/typography';

export const wrapperCSS = css`
  ${mq[0]} {
    padding: 24px;
    z-index: 9999;
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    border: 1px solid var(--revamp-neutral-gray-30, ${colors.nobel});
    box-shadow: 2px 6px 0px 0px rgba(0, 0, 0, 0.12);
  }
`;

export const headerWrapperCSS = css`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const headerTextCSS = css`
  ${text24};
  font-weight: 700;
  margin-bottom: 8px;
`;

export const buttonCSS = css`
  display: flex !important;
  width: 230px;
  margin-bottom: 24px;
  justify-content: center;
  align-items: center;
  font-variant-numeric: lining-nums;

  span {
    display: flex;
    align-items: center;
  }

  img {
    margin-right: 4px;
  }
`;

export const iconCSS = css`
  cursor: pointer;
`;

export const popoverCSS = css`
  margin-top: 12px;
`;

export const liveHelpButtonCSS = css`
  padding: 8px 18px;
  border-width: 3px;
  border-radius: 5px;
  font-size: 21px;

  &:focus {
    background-color: ${colors.white};
    border-color: ${colors.azure};
    color: ${colors.azure};
  }

  span {
    display: flex;
    align-items: center;

    svg {
      margin-right: 6px;
    }
  }

  &:focus {
    background-color: ${colors.white};
    border-color: ${colors.azure};
    color: ${colors.azure};
  }
`;

export const anchorCSS = (isWhiteColored?: boolean): SerializedStyles => css`
  ${text};
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${isWhiteColored &&
  css`
    color: ${colors.white};
  `}

  &:hover {
    color: ${colors.white};
  }

  &:focus {
    color: ${colors.white} !important;
  }
`;

export const contactLinkCSS = (isFullWidth?: boolean): SerializedStyles => css`
  color: ${colors.white};
  ${text};
  text-decoration: none;
  border-bottom: none;
  background-color: ${colors.azure};
  border-radius: 4px;
  padding: 12px 20px;
  width: 140px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px 0;

  ${mq[1]} {
    margin: 0;
  }

  ${isFullWidth &&
  css`
    width: 90%;
  `}

  img {
    margin-right: 10px;
  }

  &:hover {
    color: ${colors.white};
  }

  &:focus {
    color: ${colors.white};
  }
`;

export const anchorHeaderCSS = css`
  display: flex;
  align-items: center;
  font-size: 22px;
  color: ${colors.black};

  &:focus,
  &:hover {
    color: ${colors.black};
  }
`;

export const iconHoverCSS = css`
  width: 28px;
  height: 20px;
`;
