import SERVICERS from 'src/constants/servicers';
import React, { Fragment } from 'react';
import { SavingsTabsType, SavingTabsEnum } from './SavingsTabs/SavingsTabs.types';
import { SavingsQuote } from 'src/interfaces/IQuotes';

const getFirstBenefit = (servicerKey: string): string => {
  switch (true) {
    case servicerKey === SERVICERS.NEW_REZ_NRZ:
      return 'Matic shops your policy at no extra cost';
    case [SERVICERS.FREEDOM, SERVICERS.PHH, SERVICERS.ROUNDPOINT].includes(servicerKey):
      return 'Matic is easy to use at no cost to you at any point.';
    default:
      return 'Matic is 100% free to use (no hidden fees)';
  }
};

export const getSavingsBenefitsList = (
  servicerName: string | React.ReactElement,
  servicerKey: string,
  preScoreVariation?: boolean,
  bundleVariation?: boolean
): (string | React.ReactElement)[] => {
  const benefitsArray = [
    getFirstBenefit(servicerKey),
    // eslint-disable-next-line react/jsx-key
    <Fragment>We work with {servicerName} to make switching seamless</Fragment>,
    !preScoreVariation
      ? servicerKey === SERVICERS.NEW_REZ_NRZ
        ? 'We watch your rate to make it easy to save in the future'
        : 'We continually shop the best policy for you year-round.'
      : '',
    'Our advisors can bundle home and auto for up to 20%  savings'
  ];

  if ([SERVICERS.MR_COOPER_NRZ, SERVICERS.MR_COOPER].includes(servicerKey)) {
    benefitsArray.splice(0, 1);
  }

  if (bundleVariation) {
    return [
      <Fragment key={0}>We work with {servicerName} to make switching seamless.</Fragment>,
      'Matic is easy to use.',
      'We work with 40+ A-rated carriers so you only have to shop once.'
    ];
  }

  return benefitsArray;
};

const getSavingsTabText = (activeTab: SavingTabsEnum, tab: SavingTabsEnum): string => {
  switch (tab) {
    case SavingTabsEnum.MostSavings:
      return activeTab === SavingTabsEnum.MostSavings ? 'I want the most savings' : 'see the highest savings quote';
    case SavingTabsEnum.Bundle:
      return 'Bundle and save more';
    default:
      return '';
  }
};

export const getSavingsTabs = (
  activeTab: SavingTabsEnum,
  quotes: SavingsQuote[],
  experimentVersion: SavingTabsEnum
): SavingsTabsType[] => {
  const savingsTabs = [SavingTabsEnum.MostSavings, experimentVersion];
  return savingsTabs.map((tab, index) => ({
    isActive: tab === activeTab,
    tab: tab,
    text: getSavingsTabText(activeTab, tab),
    quoteGid: quotes[index]?.gid
  }));
};

export const getPossessiveForm = (surname: string): string => {
  const possessiveSuffix = surname.endsWith('s') ? "'" : "'s";
  const possessiveForm = `${surname}${possessiveSuffix}`;

  return possessiveForm;
};
