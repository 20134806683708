/** @jsxImportSource @emotion/react */
import React from 'react';

import MaskedInput from 'react-input-mask';
import { Controller, RegisterOptions } from 'react-hook-form';

import { FormFieldProps, Validation } from 'src/interfaces/IQuestion';
import { focusHelperCSS, inputCSS, inputWrapper } from 'src/shared/components/Input/Input.style';
import { FS_SENSITIVE_DATA_CLASS } from 'src/constants/fullStory';

export interface MaskInputProps extends FormFieldProps {
  hasError?: boolean;
  mask?: string;
  validation?: Validation;
}

const MaskInput: React.FC<MaskInputProps> = ({
  hasError = false,
  key_name,
  isDisabled,
  inputId,
  type = 'search',
  placeholder,
  mask,
  validation,
  tracking_forbidden = false
}) => {
  if (validation?.static?.pattern?.value) {
    validation.static.pattern.value = new RegExp(validation.static.pattern.value);
  }

  return (
    <Controller
      name={key_name}
      key={key_name}
      render={({ field: { onChange, value, onBlur, name, ref } }) => (
        <div css={inputWrapper}>
          <MaskedInput
            className={tracking_forbidden ? FS_SENSITIVE_DATA_CLASS.MASK : ''}
            css={inputCSS(hasError, isDisabled)}
            id={inputId}
            mask={mask as string}
            name={name}
            type={type}
            onChange={onChange}
            value={value || ''}
            disabled={isDisabled}
            onBlur={onBlur}
            inputRef={(r: HTMLInputElement) => {
              ref(r);
            }}
            placeholder={placeholder}
            autoComplete="off"
          />
          <div css={focusHelperCSS} className="input-focus-helper" />
        </div>
      )}
      rules={validation?.static as RegisterOptions}
      defaultValue=""
    />
  );
};

export default MaskInput;
