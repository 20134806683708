/** @jsxImportSource @emotion/react */
import React, { Fragment } from 'react';

import { QuotesPageHeroProps, QuotesPageHeroType } from './QuotesPageHero.types';
import { arrowCSS, containerCSS, containerInnerCSS, landlordButtonCSS } from './QuotesPageHero.style';
import QuotesHeroContentSavings from './QuotesHeroContentSavings/QuotesHeroContentSavings';
import useResponsive from 'src/hooks/useResponsive';
import QuotesHeroArrow from 'src/assets/svg/quotes-hero-arrow.svg';
import QuotesHeroArrowTablet from 'src/assets/svg/quotes-hero-arrow-tablet.svg';
import QuotesHeroArrowDesktop from 'src/assets/svg/quotes-hero-arrow-desktop.svg';
import QuotesHeroContent from './QuotesHeroContent/QuotesHeroContent';
import noop from 'src/utils/noop';
import { getQuotesPageHeroHeading } from './QuotesPageHero.utils';
import Anchor from 'src/kit/Anchor/Anchor';
import LandlordButton from 'src/components/Quotes/QuotesHeader/LandlordButton/LandlordButton';
import QuotesHeroBundleContentSavings from './QuotesHeroBundleContentSavings/QuotesHeroBundleContentSavings';
import { Flows } from 'src/interfaces/IPage';
import { useParams } from 'react-router-dom';

const QuotesPageHero: React.FC<QuotesPageHeroProps> = ({
  customCSS,
  type,
  data = {},
  setAFToggleOn,
  onActionClick = noop,
  onCallToAgentClick = noop,
  isBndlTgglPageVariation
}) => {
  const { isDesktop, isTablet } = useResponsive();
  const { flow } = useParams();
  const onHeroScheduleClicked = () => {
    onActionClick();
  };
  const getArrow = (): React.ReactElement => {
    const alt = 'Arrow';
    const css = arrowCSS;

    switch (true) {
      case isDesktop:
        return <img css={css} width="448" src={QuotesHeroArrowDesktop} alt={alt} />;
      case isTablet:
        return <img css={css} width="290" src={QuotesHeroArrowTablet} alt={alt} />;
      default:
        return <img css={css} width="200" src={QuotesHeroArrow} alt={alt} />;
    }
  };

  const getDescription = (): string | React.ReactElement => {
    switch (type) {
      case QuotesPageHeroType.PolicyHolder:
        return (
          <Fragment>
            Our Licensed Customer Service Specialists are ready to assist you with all of your insurance needs.
          </Fragment>
        );
      case QuotesPageHeroType.NoQuotes:
        return (
          <Fragment>
            Your home is special, which is great! However, that means we need a specialized Advisor to get quotes that
            work for your home. Please call us at{' '}
            <Anchor anchorType="single-color" inverted href={`tel:${data.agentPhone}`} onClick={onCallToAgentClick}>
              {data.agentPhone}
            </Anchor>{' '}
            or schedule a time below.
          </Fragment>
        );
      case QuotesPageHeroType.Landlord:
        return (
          <Fragment>
            Matic compared carriers and found the best{' '}
            <LandlordButton customCSS={landlordButtonCSS} label="landlord insurance" invertedButton /> options. For
            questions, or to customize coverages, call a Matic Advisor at{' '}
            <Anchor anchorType="single-color" inverted href={`tel:${data.agentPhone}`} onClick={onCallToAgentClick}>
              {data.agentPhone}
            </Anchor>{' '}
          </Fragment>
        );

      case QuotesPageHeroType.Accord:
        return (
          <Fragment>
            Matic compared carriers and found the best options.
            <div>
              For questions, or to customize coverages, call a Matic Advisor at{' '}
              <Anchor anchorType="single-color" inverted href={`tel:${data.agentPhone}`} onClick={onCallToAgentClick}>
                {data.agentPhone}
              </Anchor>{' '}
            </div>
          </Fragment>
        );
      default:
        return (
          <Fragment>
            Our advisors are ready to assist you with all of your insurance needs. It’s fast and at no cost to you so
            that you can find the exact right fit for you.
          </Fragment>
        );
    }
  };

  return (
    <Fragment>
      <div css={[containerCSS(isBndlTgglPageVariation), customCSS]}>
        <div css={containerInnerCSS}>
          {isBndlTgglPageVariation ? (
            <QuotesHeroBundleContentSavings setAFToggleOn={setAFToggleOn} firstName={data.firstName} />
          ) : type === QuotesPageHeroType.Savings ? (
            <QuotesHeroContentSavings firstName={data.firstName} savingValue={data.savingValue || 0} />
          ) : (
            <QuotesHeroContent
              isAccordVariation={flow === Flows.Accord}
              heading={getQuotesPageHeroHeading(type, data.firstName)}
              description={getDescription()}
              onActionClick={onHeroScheduleClicked}
              contactNumber={data.agentPhone}
              isPolicyHolderVariation={type === QuotesPageHeroType.PolicyHolder}
              onPhoneNumberClick={onCallToAgentClick}
            />
          )}
        </div>
      </div>

      <div css={containerInnerCSS}>{getArrow()}</div>
    </Fragment>
  );
};

export default QuotesPageHero;
