import { SerializedStyles, css } from '@emotion/react';
import { text20, text24 } from 'src/theme/typography';
import { colors, mq } from 'src/theme/variables';

export const savingsRowCSS = css`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;

  ${mq[0]} {
    flex-direction: row;
  }

  img {
    height: fit-content;
  }
`;

export const savingsAmountCSS = css`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const savingsDashedRowCustomCSS = (isStarred = false): SerializedStyles => css`
  padding-top: 16px;
  border-color: ${colors.nobel};

  > span {
    ${text20};
    line-height: 20px;
  }

  > span:first-of-type {
    color: ${colors.black};
    font-weight: 500;
    position: relative;
  }

  ${isStarred &&
  css`
    padding-top: 24px;

    > span:first-of-type:after {
      position: absolute;
      content: '*';
      right: -10px;
      color: green;
    }
  `}

  > span:last-of-type {
    font-weight: 700;
  }
`;

export const savingsYearCSS = css`
  ${text20};

  ${mq[0]} {
    ${text24};
  }
`;

export const buttonWrapperCSS = css`
  margin-top: 32px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
`;

export const coverageButtonCustomCSS = css`
  width: fit-content;
  ${text20};
  font-weight: 700;
`;

export const cardVariationBWrapperCSS = (IsBndlCard = false): SerializedStyles => css`
  box-shadow: 2px 4px 0px 0px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  border: 1px solid ${colors.nobel};
  background: ${IsBndlCard ? '#e1f1ff' : 'white'};
  margin-bottom: 24px;

  ${IsBndlCard &&
  css`
    border-radius: 4px;
  `}
`;

export const bndlWrapperCSS = css`
  width: 50%;
  border-radius: 4px;
`;
