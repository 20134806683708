import { DefaultSlider, SliderMark } from './SliderInput.types';

const generateNumberArray = (elementsNumber: number) => {
  return Array.from(Array(elementsNumber).keys()).map(
    (number: number): SliderMark => ({
      label: '',
      value: number
    })
  );
};

export const getDefaultCarrierSliderValues = (): DefaultSlider => {
  const marks = generateNumberArray(10);
  const defaultValues = {
    maxValue: 10,
    minValue: 1,
    step: 1
  };

  return {
    ...defaultValues,
    marks
  };
};
