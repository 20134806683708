const PIP_LABEL = 'Personal Injury Protection';
const PIP_DESCRIPTION = `Personal injury protection covers healthcare expenses from a car accident for both injured 
  policyholders and passengers, even if some don’t have health insurance.`;

export enum CoverageAutoKeys {
  BodilyInjury = 'bodily_injury',
  PropertyDamage = 'property_damage',
  UninsuredMotoristBodily = 'uninsured_motorist_bodily',
  UninsuredMotoristProperty = 'uninsured_motorist_property',
  PersonalInjuryProtection = 'personal_injury_protection',
  PIP = 'pip',
  Rental = 'rental',
  Collision = 'collision',
  RoadsideAssistance = 'roadside_assistance',
  Comprehensive = 'comprehensive',
  RentalReimbursement = 'rental_reimbursement',
  Towing = 'towing',
  UMBI = 'umbi',
  UMPD = 'umpd',
  MedicalPayments = 'medical_payments',
  LimitedPropertyDamageLiability = 'limited_property_damage_liability',
  PIPMedical = 'pip_medical',
  PIPWorkLoss = 'pip_work_loss',
  FullGlass = 'full_glass',
  PIPDeductible = 'pip_deductible',
  PIPStacking = 'pip_stacking',
  TortOption = 'tort_option',
  AdditionalPIP = 'additional_pip',
  OptionalBasicEconomicLoss = 'optional_basic_economic_loss',
  AccidentalDeath = 'accidental_death',
  CombinedFirstPartyBenefits = 'combined_first_party_benefits',
  ExtraordinaryMedical = 'extraordinary_medical',
  Funeral = 'funeral',
  IncomeLoss = 'income_loss',
  MedicalExpenses = 'medical_expenses',
  UninsuredUnderinsuredStacking = 'uninsured_underinsured_stacking',
  PIPAccidentalDeathBenefits = 'pip_accidental_death_benefits',
  PIPLostWages = 'pip_lost_wages',
  PIPMedpayHospitalFuneralExpenses = 'pip_medpay_hospital_funeral_expenses',
  WorkLoss = 'work_loss',
  NoFaultCoverageOption = 'no_fault_coverage_option',
  RentalMaxAmount = 'rental_max_amount',
  RentalPerDay = 'rental_per_day',
  UninsuredMotoristBodilyInjury = 'uninsured_motorist_bodily_injury',
  UnderinsuredMotoristBodilyInjury = 'underinsured_motorist_bodily_injury',
  UninsuredMotoristPropertyDamage = 'uninsured_motorist_property_damage'
}

type CoverageInfoHash = {
  [key in CoverageAutoKeys]: string;
};

export const CoverageAutoTitles: CoverageInfoHash = {
  [CoverageAutoKeys.BodilyInjury]: 'Bodily Injury Liability',
  [CoverageAutoKeys.PropertyDamage]: 'Property Damage Liability',
  [CoverageAutoKeys.UninsuredMotoristBodily]: 'Uninsured Motorist Bodily',
  [CoverageAutoKeys.UninsuredMotoristProperty]: 'Uninsured Motorist Property',
  [CoverageAutoKeys.PersonalInjuryProtection]: PIP_LABEL,
  [CoverageAutoKeys.Collision]: 'Collision (Deductible)',
  [CoverageAutoKeys.RoadsideAssistance]: 'Roadside Assistance',
  [CoverageAutoKeys.Comprehensive]: 'Comprehensive (Deductible)',
  [CoverageAutoKeys.RentalReimbursement]: 'Rental Reimbursement',
  [CoverageAutoKeys.PIP]: PIP_LABEL,
  [CoverageAutoKeys.Rental]: 'Rental',
  [CoverageAutoKeys.Towing]: 'Towing',
  [CoverageAutoKeys.UMBI]: 'Uninsured/Underinsured Motorists Bodily Injury',
  [CoverageAutoKeys.UMPD]: 'Uninsured/Underinsured Motorists Property Damage',
  [CoverageAutoKeys.MedicalPayments]: 'Medical Payments',
  [CoverageAutoKeys.LimitedPropertyDamageLiability]: 'Limited Property Damage Liability',
  [CoverageAutoKeys.PIPMedical]: 'Personal Injury Protection Medical',
  [CoverageAutoKeys.PIPWorkLoss]: 'Personal Injury Protection Work Loss',
  [CoverageAutoKeys.FullGlass]: 'Full Glass',
  [CoverageAutoKeys.PIPDeductible]: 'Personal Injury Protection Deductible',
  [CoverageAutoKeys.PIPStacking]: 'Personal Injury Protection Stacking',
  [CoverageAutoKeys.TortOption]: 'Tort option',
  [CoverageAutoKeys.AdditionalPIP]: 'Additional Personal Injury Protection',
  [CoverageAutoKeys.OptionalBasicEconomicLoss]: 'Optional Basic Economic Loss',
  [CoverageAutoKeys.AccidentalDeath]: 'Accidental Death',
  [CoverageAutoKeys.CombinedFirstPartyBenefits]: 'Combined First Party Benefits',
  [CoverageAutoKeys.ExtraordinaryMedical]: 'Extraordinary Medical',
  [CoverageAutoKeys.Funeral]: 'Funeral',
  [CoverageAutoKeys.IncomeLoss]: 'Income loss',
  [CoverageAutoKeys.MedicalExpenses]: 'Medical Expenses',
  [CoverageAutoKeys.UninsuredUnderinsuredStacking]: 'Uninsured/Underinsured Stacking',
  [CoverageAutoKeys.PIPAccidentalDeathBenefits]: 'PIP Accidental Death Benefits',
  [CoverageAutoKeys.PIPLostWages]: 'PIP Lost Wages',
  [CoverageAutoKeys.PIPMedpayHospitalFuneralExpenses]: 'PIP MedPay, Hospital, Funeral Expenses',
  [CoverageAutoKeys.WorkLoss]: 'Work Loss',
  [CoverageAutoKeys.NoFaultCoverageOption]: 'No-Fault Coverage Option',
  [CoverageAutoKeys.RentalMaxAmount]: 'Maximum amount of rental car reimbursement',
  [CoverageAutoKeys.RentalPerDay]: 'Rental car reimbursement per day',
  [CoverageAutoKeys.UninsuredMotoristBodilyInjury]: 'Uninsured Motorist Bodily',
  [CoverageAutoKeys.UnderinsuredMotoristBodilyInjury]: 'Underinsured Motorist Bodily Injury',
  [CoverageAutoKeys.UninsuredMotoristPropertyDamage]: 'Uninsured Motorist Property'
};

export const CoverageAutoDescriptions: CoverageInfoHash = {
  [CoverageAutoKeys.BodilyInjury]: `Bodily injury liability is coverage that helps you pay for another person’s injuries
   in a car accident for which you are found to be at fault.`,
  [CoverageAutoKeys.PropertyDamage]: `Property Damage liability typically helps cover the cost of repairs if you are at 
  fault for a car accident that damages another vehicle or property such as a fence or building front.`,
  [CoverageAutoKeys.UninsuredMotoristBodily]: `If you’re in an accident with an uninsured or underinsured driver, your 
  UMBI coverage helps pay for your medical treatments and any pain and suffering.`,
  [CoverageAutoKeys.UninsuredMotoristProperty]: `Helps protect you on the road if someone hits you who doesn’t have 
  insurance or is underinsured.`,
  [CoverageAutoKeys.PersonalInjuryProtection]: PIP_DESCRIPTION,
  [CoverageAutoKeys.Collision]: `The amount of your collision deductible equals the amount of money you agree to pay 
  out-of-pocket for repairs to your car when you file a collision claim.`,
  [CoverageAutoKeys.RoadsideAssistance]: 'Roadside assistance coverage helps drivers when their vehicle breaks down.',
  [CoverageAutoKeys.Comprehensive]: `Comprehensive is a separate coverage from collision. It helps cover different 
  types of losses that are usually not the result of driving the vehicle, such as theft, hail or fallen trees.`,
  [CoverageAutoKeys.RentalReimbursement]: `Rental Reimbursement is an optional coverage that helps pay your rental car 
  costs while your car is being repaired as a result of a covered claim.`,
  [CoverageAutoKeys.PIP]: PIP_DESCRIPTION,
  [CoverageAutoKeys.Rental]: `Rental Reimbursement is a coverage that helps pay your rental car costs while your car 
  is being repaired as a result of a covered claim.`,
  [CoverageAutoKeys.Towing]: `Towing and Labor coverage protects you against some of the costs and problems that come 
  with roadside breakdowns.`,
  [CoverageAutoKeys.UMBI]: `Uninsured Motorists Bodily Injury pays for medical costs from an accident caused by an 
  uninsured or underinsured driver.`,
  [CoverageAutoKeys.UMPD]: `Uninsured Motorists Property Damage pays for property damaged in an accident caused by an 
  uninsured or underinsured driver.`,
  [CoverageAutoKeys.MedicalPayments]: `Medical Payments helps cover you or your passengers' medical expenses after a 
  car accident, no matter who was at fault.`,
  [CoverageAutoKeys.LimitedPropertyDamageLiability]: `Limited Property Damage allows you to recover additional money 
  from the at-fault driver’s insurance company for repair costs after a car accident.`,
  [CoverageAutoKeys.PIPMedical]: `Personal injury protection covers healthcare expenses from a car accident for both 
  injured policyholders and passengers, even if some don’t have health insurance.`,
  [CoverageAutoKeys.PIPWorkLoss]: `Work Loss coverage provides a weekly benefit if a person is unable to work as a 
  result of an automobile accident.`,
  [CoverageAutoKeys.FullGlass]: `Full Glass coverage means you may not have to pay a deductible if your windshield is 
  damaged and needs repair.`,
  [CoverageAutoKeys.PIPDeductible]: `Your Personal Injury Protection deductible is the amount of money you agree to 
  pay out-of-pocket for medical expenses after an accident.`,
  [CoverageAutoKeys.PIPStacking]: `Personal Injury Protection stacking enables you to combine coverage limits from 
  multiple vehicles into a single limit.`,
  [CoverageAutoKeys.TortOption]: `Tort Option is an optional endorsement that retains your right to sue a negligent 
  party after an accident.`,
  [CoverageAutoKeys.AdditionalPIP]: `Additional Personal Injury Protection is additional coverage that supplements 
  your state's required PIP coverage.`,
  [CoverageAutoKeys.OptionalBasicEconomicLoss]: `Optional Basic Economic loss is supplemental coverage for economic 
  loss above the minimum provided by your policy.`,
  [CoverageAutoKeys.AccidentalDeath]: `Accidental death coverage helps cover funeral and burial expenses in the event 
  of death from a covered accident.`,
  [CoverageAutoKeys.CombinedFirstPartyBenefits]: `Combined First Party Benefits combine all first party benefit limits 
  into a single limit.`,
  [CoverageAutoKeys.ExtraordinaryMedical]: `Extraordinary Medical is an optional coverage that increases your coverage 
  for medical expenses.`,
  [CoverageAutoKeys.Funeral]: `Funeral coverage helps cover the costs of a funeral after a death caused by a covered 
  accident.`,
  [CoverageAutoKeys.IncomeLoss]: `Income Loss coverage provides a regular benefit if a person is unable to work as a 
  result of an automobile accident.`,
  [CoverageAutoKeys.MedicalExpenses]: `Medical Expenses helps cover you or your passengers' medical expenses after a 
  car accident, no matter who was at fault.`,
  [CoverageAutoKeys.UninsuredUnderinsuredStacking]: `Uninsured/Underinsured stacking enables you to combine coverage 
  limits from multiple vehicles into a single limit.`,
  [CoverageAutoKeys.PIPAccidentalDeathBenefits]: `Accidental death coverage helps cover funeral and burial expenses in 
  the event of death from an accident.`,
  [CoverageAutoKeys.PIPLostWages]: `PIP Lost Wages provides a benefit if a person is unable to work as the result of 
  an automobile accident.`,
  [CoverageAutoKeys.PIPMedpayHospitalFuneralExpenses]: `PIP MedPay, Hospital, Funeral Expenses covers medical and 
  funeral expenses resulting from an automobile accident.`,
  [CoverageAutoKeys.WorkLoss]: `Work Loss coverage provides a weekly benefit if a person is unable to work as a result 
  of an automobile accident.`,
  [CoverageAutoKeys.NoFaultCoverageOption]: `No-Fault Coverage option enables you to opt-in or opt-out of your state's 
  no-fault coverage`,
  [CoverageAutoKeys.RentalMaxAmount]: `This optional coverage can help you pay for a rental car when your car is in the
  shop because of a covered claim. It does not cover damages to a rental vehicle`,
  [CoverageAutoKeys.RentalPerDay]: `This optional coverage can help you pay for a rental car when your car is in the
  shop because of a covered claim. It does not cover damages to a rental vehicle`,
  [CoverageAutoKeys.UninsuredMotoristBodilyInjury]: `This type of coverage can help pay your medical bills when you're
  in an accident with a driver who doesn't have any insurance`,
  [CoverageAutoKeys.UnderinsuredMotoristBodilyInjury]: `This type of coverage can help pay your medical bills when
  you're in an accident with a driver who doesn't enough insurance`,
  [CoverageAutoKeys.UninsuredMotoristPropertyDamage]: `This can help pay for damages to your car or other property when
  you're in an accident with a driver who doesn't have any or enough insurance. A deductible may apply`
};
