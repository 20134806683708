import { SerializedStyles, css } from '@emotion/react';
import { text, text14 } from 'src/theme/typography';
import { mq, colors as c } from 'src/theme/variables';

export const containerCSS = css`
  padding: 24px;
  min-width: 52%;
  border-radius: 8px;
  position: relative;
  border: 1px solid ${c.mercury};

  ${mq[0]} {
    border-radius: 8px;
    border-right-width: 1px;
    border-left-width: 1px;
    margin-left: 0;
    margin-right: 0;
    margin-top: 56px;
  }

  ${mq[1]} {
    width: 466px;
  }
`;

export const bundleMsgWrapperCSS = css`
  ${text14};
  color: ${c.black};
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;

  ${mq[0]} {
    align-items: center;
    flex-direction: row;
  }
`;

export const customerAliasCSS = css`
  ${text};
  margin-bottom: 8px;
  font-weight: bold;
`;

export const propertyCSS = css`
  color: ${c.mineshaft};
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  width: 130px;

  span {
    margin-right: 26px;
  }
`;

export const policyExpirationDateCSS = css`
  color: ${c.warmGray};
`;

export const policyAddressCSS = css`
  margin-bottom: 8px;
`;

export const profileInfoCSS = (isPhonePrefilled: boolean): SerializedStyles => css`
  ${mq[0]} {
    max-width: ${isPhonePrefilled ? 50 : 100}%;
  }
`;
