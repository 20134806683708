import { css } from '@emotion/react';
import { mq, colors as c } from 'src/theme/variables';
import { text, text20, text24, text32, text48 } from 'src/theme/typography';

export const mainWrapperCSS = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 56px;
`;

export const mainHeadingCSS = css`
  ${text32};
  font-weight: 900;
  margin-bottom: 12px;

  ${mq[0]} {
    ${text48};
    margin-bottom: 32px;
  }
`;

export const cardWrapperCSS = css`
  border: 1px solid ${c.silver};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  ${mq[1]} {
    width: 1030px;
    margin-bottom: 90px;
  }
`;

export const cardHeaderCSS = css`
  padding: 10px 23px;
  ${text};
  font-weight: bold;
  color: ${c.white};
  background-color: ${c.cheriseLight};
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;

  ${mq[1]} {
    ${text20};
  }
`;

export const contentWrapperCSS = css`
  padding: 20px;
  display: flex;
  flex-direction: column;

  ${mq[1]} {
    padding: 34px 54px;
  }

  h3 {
    ${text24};
    margin-top: 0;
  }
`;

export const partnerWrapperCSS = css`
  display: flex;
  margin-bottom: 32px;
  flex-direction: column;
  ${text};

  h4 {
    ${text};
    margin-bottom: 4px;
  }

  ${mq[1]} {
    flex-direction: row;
    ${text20};
    margin-bottom: 48px;
  }
`;

export const imgCSS = css`
  width: 40%;
  margin: auto;

  ${mq[0]} {
    width: 111px;
  }

  ${mq[1]} {
    margin-right: 100px;
  }
`;

export const optionsWrapperCSS = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  ${mq[0]} {
    flex-direction: row;

    > div {
      width: 47%;
    }
  }
`;

export const optionWrapperCSS = css`
  display: flex;
  align-items: center;
  ${text};
  margin-bottom: 24px;
`;

export const buttonCSS = css`
  width: 580px;
  margin: 6px auto 0;
  padding: 16px;
`;

export const iconCSS = css`
  margin-right: 16px;
  min-width: 30px;
  min-height: 30px;
  max-width: 30px;
  max-height: 30px;
`;

export const FAQCSS = css`
  margin-bottom: 30px;

  ${mq[0]} {
    margin-bottom: 80px;
  }

  ${mq[1]} {
    max-width: 1030px;
    margin-bottom: 130px;
  }
`;

export const carriersWrapperCSS = css`
  max-width: 100%;
  margin-bottom: 40px;

  ${mq[0]} {
    margin-bottom: 100px;
  }
`;

export const carriersCSS = css`
  div {
    ${text20};
  }
`;
