import { css, SerializedStyles } from '@emotion/react';
import { borderRadius, boxShadow, colors, mq, sizes } from 'src/theme/variables';

const calendlyLgBp = 1015;
const calendlyMdBp = 665;
export const calendlyMdMax = calendlyLgBp - 1;
export const calendlySmMax = calendlyMdBp - 1;
const calendlyMd = `only screen and (max-width: ${calendlyMdMax}px)`;
export const calendlySm = `only screen and (max-width: ${calendlySmMax}px)`;

const widgetHeight = {
  sm: [860, 860, 860, 430],
  md: [900, 900, 880, 510],
  lg: [690]
};

export const container = css`
  text-align: center;
  padding: 16px 12px 32px ${mq[0]} {
    padding: 56px ${sizes.paddingBodyHorizontalTablet}px 56px;
  }

  ${mq[1]} {
    padding: 56px 0 72px;
  }
`;

export const containerInnerCSS = css`
  max-width: 800px;
  margin: 0 auto;

  @media ${calendlyMd} {
    max-width: 680px;
  }
`;

export const widgetCSS = (step: number): SerializedStyles => css`
  height: ${widgetHeight.lg[0]}px;
  min-width: 320px;
  margin-bottom: 8px;
  margin-top: 8px;

  @media ${calendlyMd} {
    height: ${widgetHeight.md[step]}px;
  }

  @media ${calendlySm} {
    background-color: ${colors.white};
    border: 1px solid ${colors.silver};
    border-radius: ${borderRadius}px;
    height: ${widgetHeight.sm[step]}px;
    box-shadow: ${boxShadow.container};
    margin-bottom: 12px;
    iframe {
      border-radius: ${borderRadius}px;
    }
  }
`;
