import { css, SerializedStyles } from '@emotion/react';
import { activeElementReset } from 'src/theme/helpers';
import { text14 } from 'src/theme/typography';

export const containerCSS = css`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
`;

export const headingCSS = (hasDetails?: boolean): SerializedStyles => css`
  ${activeElementReset};
  align-items: center;
  display: flex;
  font-size: ${hasDetails ? 16 : 14}px;
  font-weight: bold;
  text-align: left;
`;

export const iconCSS = css`
  margin-right: 8px;
  max-height: 18px;
`;

export const editCSS = css`
  height: 12px;
  margin-left: 6px;
  width: 12px;
`;

export const subheadingCSS = css`
  ${text14};
  font-weight: normal;
  margin-left: 26px;
`;
