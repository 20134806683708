import { text32 } from './../../theme/typography';
import { css, SerializedStyles } from '@emotion/react';
import { mq } from 'src/theme/variables';
import { text48 } from 'src/theme/typography';

export const formWrapperCSS = (shouldRenderUpdatedHero = false): SerializedStyles => css`
  max-width: 600px;
  padding-top: 88px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${shouldRenderUpdatedHero &&
  css`
    flex-direction: column-reverse;
    padding: 0;

    ${mq[0]} {
      align-items: start;
    }
  `}

  ${mq[0]} {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const headingCSS = css`
  ${text32};
  font-weight: 700;
  margin: 0;
  padding: 8px;

  ${mq[0]} {
    ${text48};
    padding: 0;
  }
`;

export const buttonWrapperCSS = css`
  width: 100%;
  max-width: 408px;
  margin-top: 24px;
`;

export const customConsentCSS = css`
  ${mq[0]} {
    margin-top: 0;
    padding-bottom: 0;
  }
`;
