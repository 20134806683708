import { css, SerializedStyles } from '@emotion/react';
import { text20, text48 } from 'src/theme/typography';
import { mq } from 'src/theme/variables';

export const lockContainerCSS = css`
  width: 100%;
  position: relative;
`;

export const backgroundContentCSS = css``;

export const blurredCSS = css`
  position: absolute;
  backdrop-filter: blur(4px);
  width: calc(100% + 20px);
  height: calc(100% + 38px);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: baseline;
  bottom: -24px;
  left: -10px;
  align-items: center;

  &::after {
    content: ' ';
    position: absolute;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    background: rgba(255, 255, 255, 0.85);
    bottom: -3px;
    left: -1px;
  }

  > div {
    z-index: 2;
  }
`;

export const wrapperCSS = (isLoading: boolean): SerializedStyles => css`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${text20};

  margin-top: ${isLoading ? 40 : 0}px;

  ${mq[0]} {
    margin-top: ${isLoading ? 40 : 0}px;
  }

  ${mq[0]} {
    max-width: 54%;
  }
`;

export const headingCSS = css`
  margin-bottom: 12px;
  font-weight: 900;
  ${text20};

  ${mq[0]} {
    ${text48};
  }
`;

export const loadingCSS = css`
  ${mq[0]} {
    margin-top: 32px;
  }
`;
