import { css } from '@emotion/react';

import { mq, colors } from 'src/theme/variables';
import { headingM, headingXL, text32 } from 'src/theme/typography';

export const landingCSS = css`
  overflow-x: hidden;
  margin-bottom: 40px;
`;

export const heroHeadingCSS = css`
  ${text32};
  font-weight: 700;
  text-align: center;
  margin-top: 32px;
  margin-bottom: 8px;

  ${mq[0]} {
    font-weight: 900;
    font-size: 48px;
    line-height: 52px;
    margin-top: 56px;
  }

  ${mq[1]} {
    margin-top: 84px;
  }
`;

export const headingCSS = css`
  ${headingXL};
  margin: 0 0 16px;
`;

export const subHeadingCSS = css`
  ${headingM};
  text-align: center;
  margin-bottom: 40px;
  ${mq[0]} {
    margin-bottom: 64px;
  }
  ${mq[1]} {
    margin-bottom: 80px;
  }
`;

export const cardsContainerCSS = css`
  margin-left: -12px;
  margin-right: -12px;
`;

export const stepsCSS = css`
  opacity: 0;
`;

export const stepsAndContactWrapperCSS = css`
  padding-bottom: 32px;
  ${mq[0]} {
    padding-bottom: 40px;
  }
  ${mq[1]} {
    padding-bottom: 64px;
    display: flex;
    justify-content: space-between;
  }
`;

export const stepsWrapperCSS = css`
  ${mq[0]} {
    flex-basis: 50%;
  }
`;

export const contactWrapperCSS = css`
  margin-top: 100px;

  ${mq[1]} {
    margin-top: 0;
  }
`;

export const separatorCSS = css`
  position: relative;
  margin: 52px 0;
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: ${colors.black};
  }
  ${mq[1]} {
    &:before {
      margin: 0;
      height: 100%;
      width: 1px;
    }
  }
`;

export const separatorOrCSS = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${colors.white};
  width: 56px;
  height: 56px;
  color: ${colors.black};
  &:before {
    font-weight: 700;
    content: 'or';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    width: 40px;
    height: 40px;
    text-align: center;
    border: 2px solid ${colors.cherise};
    border-radius: 50%;
    box-sizing: border-box;
    padding-top: 8px;
    left: 0;
    right: 0;
    margin: auto;
  }
  ${mq[1]} {
    width: 112px;
    height: 112px;
    left: 0;
    &:before {
      ${text32};
      width: 72px;
      height: 72px;
      padding-top: 10px;
    }
  }
`;

export const carrierListWrapperCSS = css`
  background-color: ${colors.grayFive};
  padding-bottom: 32px;
  padding-top: 32px;
  ${mq[0]} {
    padding-bottom: 40px;
    padding-top: 40px;
  }
  ${mq[1]} {
    padding-bottom: 56px;
    padding-top: 56px;
  }
`;

export const googleReviewsSectionCSS = css`
  padding-bottom: 64px;
  ${mq[1]} {
    padding-bottom: 112px;
  }
`;
