import { css } from '@emotion/react';
import { colors, mq } from 'src/theme/variables';

export const groupTitleCSS = css`
  width: 100%;
  display: flex;
  justify-content: space-between;

  svg {
    cursor: pointer;

    path {
      stroke: ${colors.black};
    }
  }
`;

export const customDialogCSS = css`
  height: 100vh;
  width: 100vw;
  left: 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: calc(20px + var(--demo-flow-space) + var(--browser-update-height)) 16px 40px;

  ${mq[0]} {
    padding: 8px 32px 32px;
    width: unset;
    height: unset;
  }
`;

export const popupButtonWrapperCSS = css`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
`;
