/** @jsxImportSource @emotion/react */

import React from 'react';
import {
  additionalGlobalStyle,
  carrierSearchCSS,
  interstitialContainerCSS,
  interstitialSubHeadingCSS,
  loadingBlockCSS
} from './InterstitialAF.style';
import LoadingDots from 'src/shared/components/LoadingDots/LoadingDots';
import SIRV from 'src/constants/sirv';
import SEGMENT from 'src/constants/segment';
import { DefaultLocation } from 'src/interfaces/IPage';
import { useParams } from 'react-router-dom';
import analytics from 'src/utils/analytics';
import { Global } from '@emotion/react';

const InterstitialAF: React.FC = () => {
  const { gid, flow } = useParams() as DefaultLocation;
  const flowStartedReported = React.useRef(false);

  React.useEffect(() => {
    if (!flowStartedReported.current) {
      analytics.track(SEGMENT.FLOW_STARTED, gid, flow);
      flowStartedReported.current = true;
    }
  }, [flow, gid]);

  return (
    <div css={interstitialContainerCSS(true)}>
      <Global styles={additionalGlobalStyle} />
      <img src={SIRV.SEARCH_CARRIERS} alt={'Carrier search gif'} css={carrierSearchCSS} />

      <h2>Searching all of our Top-Rated Carriers</h2>
      <>
        <p css={interstitialSubHeadingCSS} data-testid={'time-takes-selection'}>
          Matic is shopping <span>40+ A-rated</span> carriers to match you with a competitive quote based on coverage
          and pricing.
          <br />
          This typically takes <span>30 seconds.</span>
        </p>

        <div css={loadingBlockCSS(true)}>
          <LoadingDots />
        </div>
      </>
    </div>
  );
};

export default InterstitialAF;
