import { css } from '@emotion/react';
import { mq, colors } from 'src/theme/variables';
import { text20 } from 'src/theme/typography';

export const headingCSS = css`
  ${mq[0]} {
    max-width: 359px;
    margin-bottom: 12px;
  }
  ${mq[1]} {
    max-width: 627px;
  }
`;

export const descriptionCSS = css`
  ${mq[0]} {
    max-width: 322px;
  }
  ${mq[1]} {
    max-width: 535px;
  }
`;

export const imageContainerCSS = css`
  display: inline-block;
  position: relative;
  margin-top: 16px;
  margin-bottom: 15px;
  font-size: 0;
  ${mq[0]} {
    position: absolute;
    margin: 0;
    bottom: 0;
    right: -162px;
  }
  ${mq[1]} {
    right: -72px;
  }
`;

export const imageCSS = css`
  position: relative;
`;

export const imageBackgroundCSS = css`
  position: absolute;
  left: -12px;
  right: -2px;
  bottom: 0;
  ${mq[0]} {
    #quotes-hero-agent-figure-1,
    #quotes-hero-agent-figure-2 {
      display: none;
    }
  }
  ${mq[1]} {
    #quotes-hero-agent-figure-1,
    #quotes-hero-agent-figure-2 {
      display: block;
    }
  }
`;

export const buttonWrapperCSS = css`
  margin-top: 28px;
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${mq[0]} {
    ${text20};
    flex-direction: row;
    margin-top: 20px;
    margin-bottom: 0;
  }

  ${mq[1]} {
    margin-top: 28px;
    margin-bottom: 8px;
  }
`;

export const buttonCSS = css`
  font-variant-numeric: lining-nums;
  display: flex;
  justify-content: center;

  a {
    border-bottom: none;
    display: flex;
  }

  a:hover {
    border-bottom: none;
    color: ${colors.azure};
  }

  ${mq[0]} {
    padding-left: 20px;
    padding-right: 20px;
  }

  ${mq[1]} {
    padding-left: 36px;
    padding-right: 36px;
  }
`;

export const buttonIconWrapperCSS = css`
  display: flex;
  flex-direction: column-reverse;

  ${mq[0]} {
    flex-direction: column;
    align-items: flex-start;
  }
`;
