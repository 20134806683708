import { css } from '@emotion/react';

export const buttons = css`
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
`;

export const confirmButton = css`
  margin-left: 8px;
`;
