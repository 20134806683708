import { css } from '@emotion/react';
import { mq } from 'src/theme/variables';
import { text32 } from 'src/theme/typography';

export const wrapperCSS = css`
  margin: auto;
  box-shadow: 10px 10px 5px #aaaaaa;
  position: relative;
  background-color: white;
  margin-bottom: 180px;

  ${mq[0]} {
    width: 794px;
    height: 1123px;
    margin-bottom: 0;
  }

  @media print {
    box-shadow: none;
    width: 794px;
    height: 1123px;
    margin-bottom: 0;
  }
`;

export const contentWrapperCSS = css`
  margin: 32px 24px;
`;

export const whyWrapperCSS = css`
  text-align: center;
`;

export const headingCSS = css`
  ${text32};
  font-weight: bold;

  ${mq[0]} {
    font-size: 44px;
  }

  @media print {
    font-size: 44px;
  }
`;

export const whyMaticCSS = css`
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 32px;
`;

export const itemsWrapperCSS = css`
  display: flex;
  flex-direction: column;

  ${mq[0]} {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
  }

  @media print {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
  }
`;

export const itemWrapperCSS = css`
  display: flex;
  align-items: baseline;

  margin: 25px 0;
  text-align: left;

  ${mq[0]} {
    width: 250px;
  }

  @media print {
    width: 250px;
  }
`;

export const itemCopyWrapperCSS = css`
  display: flex;
  flex-direction: column;
  margin-left: 14px;
`;

export const anchorsCSS = css`
  color: #156eea;
`;

export const itemDescriptionCSS = css`
  margin-top: 10px;
  color: #333333;
  font-size: 16px;
`;

export const contactBlockCSS = css`
  margin: 32px 0;
  padding: 12px;
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  background-color: #dceff0;


  ${mq[0]} {
    margin: 100px 0;
    height: 134px
    background-color: none;
    justify-content: flex-end;
  }

  @media print {
    margin: 100px 0;
    height: 134px
    background-color: none;
    justify-content: flex-end;
  }
}
`;

export const agentInfoWrapperCSS = css`
  height: 80px;
  width: 100%;
  background-color: #dceff0;
`;

export const agentImgCSS = css`
  width: 100px;

  ${mq[0]} {
    width: 740px;
    bottom: -22px;
    position: absolute;
  }

  @media print {
    width: 740px;
    bottom: -22px;
    position: absolute;
  }
`;

export const agentInfoCSS = css`
  font-size: 14px;
  color: #333333;
  padding: 16px;

  ${mq[0]} {
    width: 400px;
    margin-left: 35%;
    font-size: 18px;
  }

  @media print {
    width: 400px;
    margin-left: 35%;
    font-size: 18px;
  }
`;
