/** @jsxImportSource @emotion/react */
import React from 'react';

import { carousel, slide } from './CarriersCarousel.style';
import SIRV from 'src/constants/sirv';
import SirvImage from 'src/kit/SirvImage/SirvImage';
import Carousel from 'src/kit/Carousel/Carousel';
import { breakpoints } from 'src/theme/variables';
import { Carriers } from 'src/interfaces/ICarriers';
import { CarrierCarouselProps } from './CarrierCarousel.types';
import useResponsive from 'src/hooks/useResponsive';

const carriers = SIRV.CARRIERS as Carriers;

const CarriersCarousel: React.FC<CarrierCarouselProps> = ({
  dots = true,
  autoplay = false,
  rows = { sm: 3, md: 2 },
  slidesPerRow = { sm: 2, md: 3 },
  maxCarriers
}) => {
  const { isDesktop } = useResponsive();

  const options = {
    arrows: false,
    infinite: true,
    autoplay,
    dots,
    rows: rows?.md,
    slidesPerRow: slidesPerRow?.md,
    responsive: [
      {
        breakpoint: breakpoints[1],
        settings: {
          rows: rows?.sm,
          slidesPerRow: slidesPerRow?.sm
        }
      }
    ]
  };

  return (
    <Carousel customCSS={carousel} {...options}>
      {Object.keys(carriers)
        .filter((_, i) => {
          if (maxCarriers) {
            return isDesktop ? i < maxCarriers.md : i < maxCarriers.sm;
          }
          return true;
        })
        .map((carrier: string, i) => (
          <div css={slide} key={i}>
            <SirvImage url={carriers[carrier].url} width={130} alt={carriers[carrier].alt} />
          </div>
        ))}
    </Carousel>
  );
};

export default CarriersCarousel;
