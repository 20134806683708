/** @jsxImportSource @emotion/react */
import React from 'react';

import { Tooltip as ReactTooltip } from 'react-tooltip';
import { tooltip, tooltipArrowHelpCSS } from './Tooltip.style';

interface Props {
  id: string;
  effect?: 'solid' | 'float' | undefined;
  children?: React.ReactNode;
}

const Tooltip: React.FC<Props> = ({ id, children, effect = 'solid' }) => {
  return (
    <div css={tooltipArrowHelpCSS}>
      <ReactTooltip id={id} float={effect === 'float'} css={tooltip}>
        {children}
      </ReactTooltip>
    </div>
  );
};

export default Tooltip;
