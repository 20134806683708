/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';

import { QuotesProps, SelectedQuoteState } from 'src/components/Quotes/Quotes.types';
import { useParams } from 'react-router-dom';
import { DefaultLocation, Entity, Flows } from 'src/interfaces/IPage';
import useQuotes from 'src/api/quotes/useQuotes';
import { BundleQuote, BundleQuotesResponse, BundleStatus, BundleType } from 'src/interfaces/IQuotes';
import Container from 'src/kit/Container/Container';
import { quoteCard, quotesBanner, quotesContainer } from 'src/components/Quotes/Quotes.style';
import { getPremiumLabelByType } from 'src/utils/quotesPage';
import Quantify from 'src/utils/quantify';
import QuoteBanner from 'src/components/Quotes/QuotesBanner/QuotesBanner';
import useResponsive from 'src/hooks/useResponsive';
import { QUOTE_TAGS } from 'src/constants/quoteTag';
import { Tag } from 'src/components/Quotes/QuoteCard/QuoteCard.types';
import BundleQuoteCard from 'src/components/Quotes/QuoteCard/BundleQuoteCard/BundleQuoteCard';
import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';
import QuoteDetailsDialog from 'src/components/Quotes/QuoteDetailsDialog/QuoteDetailsDialog';
import BundleQuoteCardDetails from 'src/components/Quotes/QuoteCard/components/BundleQuoteCardDetails/BundleQuoteCardDetails';
import BundleQuoteTypeDetails from 'src/components/Quotes/QuoteCard/components/BundleQuoteTypeDetails/BundleQuoteTypeDetails';
import Format from 'src/utils/format';
import { detailsHeading, detailsHeadingItem } from './AutoBundleQuotes.style';
import BundleActionCard from 'src/components/Quotes/QuoteCard/BundleActionCard/BundleActionCard';
import { useCustomNavigate } from 'src/hooks/useCustomNavigate';
import BundleQuotesHeader from './../BundleQuotes/BundleQuotesHeader';
import useQuestions from 'src/api/questions/useQuestions';
import Icon from 'src/assets/svg/auto-bundle-color-icon.svg';
import userEventDataService, { UserEvents } from 'src/api/userEventsData/userEventsData';

const AutoBundleQuotes: React.FC<QuotesProps> = ({ selectQuote }) => {
  const navigate = useCustomNavigate();
  const { isMobile } = useResponsive();
  const { flow, gid } = useParams() as DefaultLocation;
  const { data: quotes } = useQuotes<BundleQuotesResponse>(flow, gid);
  const quotesData = quotes as BundleQuotesResponse;
  const { data: questions } = useQuestions(gid);

  const answers = questions?.answers || {};
  const { vehicles, drivers } = answers;
  const first_name = answers.person_first_name;
  const address = answers.vehicle_address;

  const [selectedQuote, setSelectedQuote] = useState<SelectedQuoteState<BundleQuote> | null>(null);
  const [isMobileDetailsOpen, setIsMobileDetailsOpen] = useState(false);

  const hasNoQuotes = quotesData.bundle_status === BundleStatus.Failed;
  const autoOnly = quotesData.bundle_status === BundleStatus.AutoOnly;

  const addressValue = (address as Record<string, string>)?.line1;
  const vehiclesNumber = Quantify.anything((vehicles as Entity[]).length, 'Vehicle', 'Vehicles');
  const driversNumber = Quantify.anything((drivers as Entity[]).length, 'Driver', 'Drivers');

  useEffect(() => {
    analytics.page(SEGMENT.PAGES.QUOTES, {
      flow_type: flow,
      session_gid: gid
    });
  }, [flow, gid]);

  useEffect(() => {
    const quotesDataToRecap = quotesData.quotes.map(quote => ({
      premium: quote.premium.value,
      auto: {
        carrier_key: quote.quotes.auto?.carrier.key,
        gid: quote.quotes.auto?.gid
      },
      home: {
        carrier_key: quote.quotes.home?.carrier?.key,
        gid: quote.quotes.home?.gid
      }
    }));

    if (quotesData) {
      analytics.track(SEGMENT.QUOTES_LIST_VIEWED, gid, flow, {
        number_of_quotes: quotesData.quotes.length,
        bundle: quotesData.bundle_status === BundleStatus.Success || quotesData.bundle_status === BundleStatus.Failed,
        quotes: quotesDataToRecap
      });
    }
  }, [flow, gid, quotesData]);

  const getQuoteTags = (quote: BundleQuote): Tag[] => {
    const tags = [];
    if (quote.best_value) {
      tags.push(QUOTE_TAGS.BEST_VALUE);
    }
    if (quote.bundle_discount) {
      tags.push(QUOTE_TAGS.BUNDLE_DISCOUNT);
    }
    return tags;
  };

  const renderQuoteDetailsHeading = (quote: BundleQuote) => {
    if (autoOnly) {
      return null;
    }
    return (
      <div css={detailsHeading}>
        <BundleQuoteTypeDetails
          customCSS={detailsHeadingItem}
          type="home"
          logo={{
            url: quote.quotes.home.carrier.logo_url,
            label: quote.quotes.home.carrier.name
          }}
          premium={{
            value: Format.money(quote.quotes.home.premium.value, 0, '$'),
            label: getPremiumLabelByType(quote.quotes.home.premium.type)
          }}
        />
        <BundleQuoteTypeDetails
          customCSS={detailsHeadingItem}
          type="auto"
          logo={{
            url: quote.quotes.auto.carrier.logo_url,
            label: quote.quotes.auto.carrier.name
          }}
          premium={{
            value: Format.money(quote.quotes.auto.premium.value, 0, '$'),
            label: getPremiumLabelByType(quote.quotes.auto.premium.type)
          }}
        />
      </div>
    );
  };

  const trackQuoteReviewed = async (quote: BundleQuote, ordinal: number) => {
    analytics.track(SEGMENT.QUOTE_REVIEWED, gid, Flows.Auto, {
      carrier_key: quote.quotes.auto.carrier.key,
      ordinal
    });
    if (!autoOnly) {
      analytics.track(SEGMENT.QUOTE_REVIEWED, gid, Flows.Home, {
        carrier_key: quote.quotes.home.carrier.key,
        ordinal
      });
    }

    try {
      await userEventDataService.callUserEventReport(UserEvents.QuoteDetailsClicked, gid);
    } catch {}
  };

  const onShowDetailsClicked = (quote: BundleQuote, ordinal: number) => () => {
    trackQuoteReviewed(quote, ordinal);
  };

  const onQuoteSelect = (quote: BundleQuote, ordinal: number) => () => {
    if (isMobile) {
      trackQuoteReviewed(quote, ordinal);
      setSelectedQuote({ quote, ordinal });
      setIsMobileDetailsOpen(o => !o);
    } else {
      const quoteSelectData = {
        gid: quote.quotes.auto.gid,
        carrier: quote.quotes.auto.carrier,
        flow: Flows.Auto,
        premium_value: quote.quotes.auto.premium.value,
        digital_profile_url: quote.quotes.home?.digital_profile_url
      };
      const bundledQuoteSelectData = autoOnly
        ? null
        : {
            gid: quote.quotes.home.gid,
            carrier: quote.quotes.home.carrier,
            flow: Flows.Home,
            premium_value: quote.quotes.home.premium.value
          };
      selectQuote(ordinal, quoteSelectData, bundledQuoteSelectData);
    }
  };

  const onQuoteSelectFromDetailsPage = () => {
    if (selectedQuote) {
      const { quote, ordinal } = selectedQuote;
      const quoteSelectData = {
        gid: quote.quotes.auto.gid,
        carrier: quote.quotes.auto.carrier,
        flow: Flows.Auto,
        premium_value: quote.quotes.auto.premium.value,
        digital_profile_url: quote.quotes.home?.digital_profile_url
      };
      const bundledQuoteSelectData = autoOnly
        ? null
        : {
            gid: quote.quotes.home.gid,
            carrier: quote.quotes.home.carrier,
            flow: Flows.Home,
            premium_value: quote.quotes.home.premium.value
          };
      selectQuote(ordinal, quoteSelectData, bundledQuoteSelectData);
    }
  };

  const onAutoOnlyMessageActionClick = () => {
    analytics.track(SEGMENT.PHONE_CONVERSATION_INITIATED, gid, flow, {
      location: SEGMENT.LOCATIONS.BUNDLE_AUTO_ONLY_MESSAGE,
      page: SEGMENT.PAGES_KEY.QUOTES
    });
    navigate(`/${flow}/${gid}/confirmation`);
  };

  const getMobileDetailsPopup = () => {
    if (selectedQuote) {
      const { quote: q } = selectedQuote;
      const logo = { url: q.quotes.auto.carrier.logo_url, label: q.quotes.auto.carrier.name };
      const price = {
        value: Format.money(q.quotes.auto.premium.value, 0, '$'),
        label: getPremiumLabelByType(q.quotes.auto.premium.type)
      };

      return (
        <QuoteDetailsDialog
          logo={logo}
          price={price}
          customHeading={renderQuoteDetailsHeading(q)}
          contentComponent={
            <BundleQuoteCardDetails quote={q} bundleType={BundleType.AutoBundle} isSingleType={autoOnly} />
          }
          onClose={() => {
            setIsMobileDetailsOpen(false);
            setTimeout(() => setSelectedQuote(null), 100);
          }}
          onActionClick={onQuoteSelectFromDetailsPage}
          isOpen={isMobileDetailsOpen}
        />
      );
    }
  };

  return (
    <Container customCSS={quotesContainer} isAriaLivePolite>
      <BundleQuotesHeader
        userName={first_name as string}
        addressValue={addressValue}
        vehiclesNumberLabel={vehiclesNumber}
        driversNumberLabel={driversNumber}
        bundle_status={quotesData.bundle_status}
        isEditingAllowed={!!quotesData?.allow_editing_details}
      />

      {autoOnly && !hasNoQuotes && (
        <BundleActionCard img={Icon} customCSS={quoteCard(true)} onActionClick={onAutoOnlyMessageActionClick} />
      )}

      {!hasNoQuotes &&
        quotesData?.quotes.map((quote: BundleQuote, i: number) => (
          <BundleQuoteCard
            singleTypePremium={quote.quotes?.auto?.premium}
            singleTypeCarrierName={quote.quotes?.auto?.carrier.name}
            singleTypeLogo={quote.quotes?.auto?.carrier.logo_url}
            customCSS={quoteCard(true)}
            key={`${quote.quotes?.auto?.gid}${quote.quotes?.home?.gid}`}
            quote={quote}
            tags={getQuoteTags(quote)}
            isSingleType={autoOnly}
            bundleType={BundleType.AutoBundle}
            onShowDetailsClicked={onShowDetailsClicked(quote, i + 1)}
            onQuoteSelect={onQuoteSelect(quote, i + 1)}
          />
        ))}

      <QuoteBanner customCSS={quotesBanner} hasNoQuotes={hasNoQuotes} currentQuoteLength={quotesData?.quotes.length} />

      {getMobileDetailsPopup()}
    </Container>
  );
};

export default AutoBundleQuotes;
