/** @jsxImportSource @emotion/react */
import React, { useState, Fragment } from 'react';
import Button from 'src/kit/Button/Button';
import {
  submitButtonCSS,
  buttonsWrapperCSS,
  iconButtonWrapperCSS
} from 'src/components/DisclosureForm/DisclosureForm.style';
import { useParams } from 'react-router-dom';
import callMeNowService from 'src/api/callMeNow/callMeNow.service';
import { useCustomNavigate } from 'src/hooks/useCustomNavigate';
import { DefaultLocation } from 'src/interfaces/IPage';
import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';
import Anchor from 'src/kit/Anchor/Anchor';
import {
  wrapperCSS,
  titleCSS,
  descriptionCSS,
  orangeTriangleCSS,
  yellowTriangleCSS,
  anchorWhiteCSS
} from './CallMeNowCardComponent.style';
import { anchorCSS } from 'src/components/LiveHelpComponents/LiveHelpComponents.style';
import HandsetIcon from 'src/assets/svg/handset.svg';
import { CallMeNowCardComponentProps } from './CallMeNowCardComponent.types';
import userEventDataService, { UserEvents } from 'src/api/userEventsData/userEventsData';
import CalendarWhiteIcon from 'src/assets/svg/calendar-white.svg';
import HandsetBlueIcon from 'src/assets/svg/handset-blue.svg';

const CallMeNowCardComponent: React.FC<CallMeNowCardComponentProps> = ({
  contactNumber,
  customCSS,
  isBigPhoneVariation = false,
  isPolicyHolderVariation = false
}) => {
  const { flow, gid } = useParams() as DefaultLocation;
  const navigate = useCustomNavigate();
  const confirmationPagePath = `/${flow}/${gid}/confirmation`;
  const [isRedirecting, setIsRedirecting] = useState(false);

  const onPhoneNumberClick = () => {
    analytics.track(SEGMENT.PHONE_CONVERSATION_INITIATED, gid, flow, {
      location: SEGMENT.LOCATIONS.CALL_ME_NOW_CARD,
      page: SEGMENT.PAGES_KEY.QUOTES
    });
  };

  const onCallMeNowClick = async () => {
    setIsRedirecting(true);
    analytics.track(SEGMENT.CALL_ME_NOW_SUBMITTED, gid, flow, {
      location: SEGMENT.LOCATIONS.CALL_ME_NOW_CARD
    });

    try {
      await callMeNowService.scheduleCall(gid);
      navigate(confirmationPagePath, { state: { isCallMeNowVariation: true } });
    } catch {}
  };

  const onScheduleCallClick = async () => {
    setIsRedirecting(true);
    analytics.track(SEGMENT.CONVERSATION_SCHEDULING_INITIATED, gid, flow, {
      page: SEGMENT.PAGES_KEY.QUOTES,
      location: SEGMENT.LOCATIONS.CALL_ME_NOW_CARD
    });

    try {
      await userEventDataService.callUserEventReport(UserEvents.ScheduleCall, gid);
    } catch {}

    navigate(confirmationPagePath, {
      state: { isPolicyHolderVariation: !!isPolicyHolderVariation }
    });
  };

  const title = isBigPhoneVariation
    ? 'Don’t see a quote that works for you?'
    : isPolicyHolderVariation
      ? 'Do you have any questions about these quotes? Talk to a Licensed Customer Service Specialist.'
      : 'Need help choosing a quote?';

  const description = isBigPhoneVariation ? (
    'No worries. A licensed Matic advisor can put together a custom policy to meet your needs. ' +
    'Call us now, or schedule a time that works best.'
  ) : isPolicyHolderVariation ? (
    ''
  ) : (
    <Fragment>
      {' '}
      Speak with a Matic advisor at{' '}
      <Anchor href={`tel:${contactNumber}`} onClick={onPhoneNumberClick}>
        {contactNumber}
      </Anchor>{' '}
      or we can call you.
    </Fragment>
  );

  const getFirstButtonClick = () => {
    isPolicyHolderVariation ? onScheduleCallClick() : onCallMeNowClick();
  };

  const firstButtonMsg = isPolicyHolderVariation ? ' Schedule a Call' : 'Call Me Now';
  const secondButtonMsg = isPolicyHolderVariation ? contactNumber : ' Schedule a Call';

  return (
    <div css={[customCSS, wrapperCSS]}>
      <div css={orangeTriangleCSS} />
      <div css={yellowTriangleCSS} />
      <div css={titleCSS}>{title}</div>
      <div css={descriptionCSS}>{description}</div>
      <div css={buttonsWrapperCSS}>
        {isBigPhoneVariation ? (
          <Button customCSS={submitButtonCSS(true, isBigPhoneVariation)}>
            <img src={HandsetIcon} width={20} alt="Handset" />
            <Anchor customCSS={anchorCSS(true)} href={`tel:${contactNumber}`} onClick={onPhoneNumberClick}>
              {contactNumber}
            </Anchor>
          </Button>
        ) : (
          <Button
            customCSS={submitButtonCSS(true)}
            type="submit"
            variant="secondary_inverted"
            onClick={getFirstButtonClick}
            isLoading={isRedirecting}
          >
            <div css={iconButtonWrapperCSS}>
              {isPolicyHolderVariation && <img src={CalendarWhiteIcon} width={20} alt="Calendar" />}
              {firstButtonMsg}
            </div>
          </Button>
        )}

        {isPolicyHolderVariation ? (
          <Anchor
            css={[submitButtonCSS(true), anchorWhiteCSS]}
            href={`tel:${contactNumber}`}
            onClick={onPhoneNumberClick}
          >
            {<img src={HandsetBlueIcon} width={20} alt="Handset" />}
            {secondButtonMsg}
          </Anchor>
        ) : (
          <Button
            customCSS={submitButtonCSS(true)}
            type="submit"
            variant="secondary"
            onClick={onScheduleCallClick}
            isLoading={isRedirecting}
          >
            {secondButtonMsg}
          </Button>
        )}
      </div>
    </div>
  );
};

export default CallMeNowCardComponent;
