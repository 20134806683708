export interface DisclosuresResponse {
  disclosures_accepted: boolean;
  disclosures: Disclosure[];
}

export enum DisclosureKind {
  CX_TCPA = 'cx_tcpa',
  Credit_Report = 'credit_report',
  Matic_Policies = 'matic_policies',
  Investment_Property = 'investment_property'
}

export interface Disclosure {
  gid: string;
  content: string;
  accepted_disclosures?: string[];
  kind: DisclosureKind;
}
