import { colors } from 'src/theme/variables';

const CustomizedDot: React.FC<any> = (props: any) => {
  const { cx, cy } = props;

  return (
    <circle
      r="6"
      fill={colors.cornflower}
      stroke={colors.azure}
      strokeWidth="4"
      cx={cx - 1.5}
      width="16"
      height="16"
      cy={cy}
    />
  );
};

export default CustomizedDot;
