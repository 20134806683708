/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';

import { Controller, RegisterOptions } from 'react-hook-form';

import { FieldProps } from 'src/interfaces/IQuestion';
import dynamicValidations from 'src/utils/dynamicValidations';
import Input from 'src/shared/components/Input/Input';
import { moneySignCSS, moneyWrapperCSS } from './MoneyInput.style';

interface InputProps extends FieldProps {
  hasError?: boolean;
  onChange?: (...event: any[]) => void;
}

const MoneyInput: React.FC<InputProps> = ({
  hasError = false,
  key_name,
  isDisabled,
  inputId,
  type = 'number',
  validation,
  ...props
}) => {
  const newMin = (v: null | undefined | string) => {
    let res;
    if (validation?.static?.min && v) {
      res = v >= validation?.static?.min?.value;
    } else {
      res = true;
    }

    return res || validation?.static?.min?.message;
  };

  const rules = {
    ...validation?.static,
    ...(validation?.static?.min && { validate: newMin }),
    ...(validation?.dynamic && { [validation.dynamic]: dynamicValidations[validation.dynamic] })
  };

  if (rules.min) {
    delete rules.min;
  }

  if (rules?.pattern) {
    rules.pattern.value = new RegExp(rules.pattern.value);
  }

  const [isFocused, setIsFocused] = useState(false);

  return (
    <Controller
      rules={rules as RegisterOptions}
      name={key_name}
      render={({ field }) => (
        <div css={moneyWrapperCSS}>
          {(isFocused || !!field.value) && <span css={moneySignCSS(hasError)}>$</span>}
          <Input
            {...props}
            {...field}
            placeholder={!isFocused ? props.placeholder : ''}
            onChange={e => {
              props.onChange && props.onChange(e);
              field.onChange(e);
            }}
            hasError={hasError}
            hasPrefix={isFocused || !!field.value}
            id={inputId}
            value={field.value || ''}
            name={key_name}
            type={type}
            disabled={isDisabled}
            onFocus={() => {
              setIsFocused(true);
            }}
            onBlur={() => setIsFocused(false)}
          />
        </div>
      )}
    />
  );
};

export default MoneyInput;
