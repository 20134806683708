import { css, SerializedStyles } from '@emotion/react';
import { mq } from 'src/theme/variables';
import { text24, text32 } from 'src/theme/typography';

const iconWrapperDimensionsDesktop = '125px';
const iconPadding = '175px';
const iconWrapperDimensionsMobile = '76px';

export const headingWrapper = css`
  align-items: center;
  display: flex;
  margin-bottom: 12px;
`;

export const iconWrapper = (iconWidthDesktop: string = iconWrapperDimensionsDesktop): SerializedStyles => css`
  align-items: center;
  display: flex;
  height: ${iconWrapperDimensionsMobile};
  justify-content: center;
  margin-right: 12px;
  width: ${iconWrapperDimensionsMobile};
  ${mq[0]} {
    height: ${iconWrapperDimensionsDesktop};
    left: 0;
    top: 0;
    position: absolute;
    width: ${iconWidthDesktop};
  }
`;

export const icon = (): SerializedStyles => css`
  max-width: 100%;
  max-height: 100%;
`;

export const stepName = css`
  font-weight: 700;
  ${text24};
  ${mq[0]} {
    ${text32};
  }
`;

export const step = css`
  position: relative;
  margin-top: 24px;
  ${mq[0]} {
    margin-top: 40px;
    min-height: ${iconWrapperDimensionsDesktop};
    padding-left: ${iconPadding};
  }
`;
