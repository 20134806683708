import React from 'react';

import { components as selectComponents } from 'react-select';
import Select from 'src/shared/components/Select/Select';
import { CoverageTypeOptions } from 'src/constants/coverageTypes';
import { CoverageType } from 'src/interfaces/ICoverageTypes';
import CoverageSelectOption from './CoverageSelectOption/CoverageSelectOption';
import { CoverageSelectProps } from './CoverageSelect.types';
const { Option, SingleValue } = selectComponents;

const CustomSelectOption: React.FC<any> = props => (
  <Option {...props}>
    <CoverageSelectOption {...props.data} selected={props.isSelected} price={props.price} />
  </Option>
);

const CustomSelectedValue: React.FC<any> = props => (
  <SingleValue {...props}>
    <CoverageSelectOption {...props.data} singleValue price={props.price} />
  </SingleValue>
);

const CoverageSelect: React.FC<CoverageSelectProps> = ({ customCSS, quotes, value, onChange }) => {
  const hasQuotes = (coverageType: CoverageType) => quotes[coverageType].length !== 0;
  const getPrice = (coverageType: CoverageType) => quotes[coverageType][0]?.premium.value;

  return (
    <Select
      customCSS={customCSS}
      styles={{
        valueContainer: provided => ({
          ...provided,
          height: 64
        })
      }}
      inputId="coverage-type"
      options={CoverageTypeOptions.map(option => ({ ...option, isDisabled: !hasQuotes(option.value) }))}
      value={value}
      onValidEntry={onChange}
      ariaLabel="Coverage type"
      components={{
        Option: function Option(props: any) {
          return <CustomSelectOption {...props} price={getPrice(props.data.value)} />;
        },
        SingleValue: function SingleValue(props: any) {
          return <CustomSelectedValue {...props} price={getPrice(props.data.value)} />;
        }
      }}
    />
  );
};

export default CoverageSelect;
