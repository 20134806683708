/** @jsxImportSource @emotion/react */
import React, { Fragment } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Card } from 'src/shared/components/Card/Card';
import useResponsive from 'src/hooks/useResponsive';
import {
  cardWrapperCSS,
  loaderGradientCSS,
  loaderBlockCSS,
  wrapperCSS,
  miniColumnCSS,
  headingCSS
} from './MockQuotesLoader.style';

const MockQuotesLoader: React.FC = () => {
  const { isDesktop } = useResponsive();
  return (
    <div css={wrapperCSS}>
      <div css={headingCSS}>
        <div>Preparing your quotes. This might take a few more seconds </div>
        <CircularProgress size={20} />
      </div>

      {isDesktop ? (
        <Fragment>
          <Card customCSS={cardWrapperCSS}>
            <div css={[loaderBlockCSS(50, 140), loaderGradientCSS]} />

            <div css={[loaderBlockCSS(24, 150), loaderGradientCSS]} />

            <div css={miniColumnCSS}>
              <div css={[loaderBlockCSS(30, 85), loaderGradientCSS]} />
              <div css={[loaderBlockCSS(12, 100), loaderGradientCSS]} />
            </div>

            <div css={miniColumnCSS}>
              <div css={[loaderBlockCSS(30, 85), loaderGradientCSS]} />
              <div css={[loaderBlockCSS(12, 100), loaderGradientCSS]} />
            </div>

            <div css={miniColumnCSS}>
              <div css={[loaderBlockCSS(44, 124), loaderGradientCSS]} />
              <div css={[loaderBlockCSS(11, 100), loaderGradientCSS]} />
            </div>
          </Card>
          <Card customCSS={cardWrapperCSS}>
            <div css={[loaderBlockCSS(50, 140), loaderGradientCSS]} />

            <div css={[loaderBlockCSS(24, 150), loaderGradientCSS]} />

            <div css={miniColumnCSS}>
              <div css={[loaderBlockCSS(30, 85), loaderGradientCSS]} />
              <div css={[loaderBlockCSS(12, 100), loaderGradientCSS]} />
            </div>

            <div css={miniColumnCSS}>
              <div css={[loaderBlockCSS(30, 85), loaderGradientCSS]} />
              <div css={[loaderBlockCSS(12, 100), loaderGradientCSS]} />
            </div>

            <div css={miniColumnCSS}>
              <div css={[loaderBlockCSS(44, 124), loaderGradientCSS]} />
              <div css={[loaderBlockCSS(11, 100), loaderGradientCSS]} />
            </div>
          </Card>
        </Fragment>
      ) : (
        <Fragment>
          <Card customCSS={cardWrapperCSS}>
            <div css={[loaderBlockCSS(40, 120), loaderGradientCSS]} />
            <div css={[loaderBlockCSS(20, 95, true), loaderGradientCSS]} />
            <div css={[loaderBlockCSS(40, 120), loaderGradientCSS]} />
            <div css={[loaderBlockCSS(20, 95), loaderGradientCSS]} />
            <div css={[loaderBlockCSS(50, 290), loaderGradientCSS]} />
            <div css={[loaderBlockCSS(24, 115), loaderGradientCSS]} />
          </Card>
          <Card customCSS={cardWrapperCSS}>
            <div css={[loaderBlockCSS(40, 120), loaderGradientCSS]} />
            <div css={[loaderBlockCSS(20, 95, true), loaderGradientCSS]} />
            <div css={[loaderBlockCSS(40, 120), loaderGradientCSS]} />
            <div css={[loaderBlockCSS(20, 95), loaderGradientCSS]} />
            <div css={[loaderBlockCSS(50, 290), loaderGradientCSS]} />
            <div css={[loaderBlockCSS(24, 115), loaderGradientCSS]} />
          </Card>
        </Fragment>
      )}
    </div>
  );
};

export default MockQuotesLoader;
