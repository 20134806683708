import { css, SerializedStyles } from '@emotion/react';

import { mq, colors as c } from 'src/theme/variables';
import { text, text20, text24, text40, text48 } from 'src/theme/typography';

export const containerCSS = css`
  text-align: center;

  ${mq[0]} {
    text-align: left;
  }
`;

export const nameCSS = css`
  ${text};
  line-height: 24px;
  margin-bottom: 4px;
  ${mq[0]} {
    ${text20};
    margin-bottom: 8px;
  }
`;

export const headerCSS = (preScoreVariation?: boolean): SerializedStyles => css`
  ${text24};
  font-weight: 700;

  ${mq[0]} {
    ${text40};

    ${preScoreVariation &&
    css`
      ${text48};
      max-width: 90%;
    `}
  }
`;

export const titleHighlightCSS = css`
  color: ${c.christi};
`;

export const titleDescriptionCSS = (preScoreVariation?: boolean): SerializedStyles => css`
  color: ${c.warmGray};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;

  ${preScoreVariation &&
  css`
    ${text};
    color: black;

    ${mq[0]} {
      ${text};
    }
  `}

  ${mq[0]} {
    justify-content: flex-start;
  }

  img {
    margin-right: 4px;
  }
`;
