/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';

import { Entity, EntityList } from 'src/interfaces/IPage';
import EntityForm from 'src/questionsForm/components/EntityForm/EntityForm';

import ADD from 'src/assets/svg/add.svg';
import { addButtonText, entityAddButton, entityAddNew, entityAddButtonWrapper } from './EntityAddNew.style';
import { focusHelperCSS } from 'src/shared/components/Input/Input.style';

interface Props {
  list: EntityList;
  onSubmit(entity: Entity): void;
}

const EntityAddNew: React.FC<Props> = ({ list, onSubmit }) => {
  const [isAddNew, setIsAddNew] = useState<boolean>(false);

  return (
    <div css={entityAddNew}>
      {isAddNew ? (
        <EntityForm onHide={() => setIsAddNew(false)} list={list} onSubmit={onSubmit} />
      ) : (
        <div css={entityAddButtonWrapper}>
          <button
            data-testid="add-new-entity-button"
            type="button"
            css={entityAddButton}
            onClick={() => setIsAddNew(true)}
          >
            <img src={ADD} alt="Add" />
            <span css={addButtonText}>{list.add_action}</span>
          </button>
          <div css={focusHelperCSS} className="input-focus-helper" />
        </div>
      )}
    </div>
  );
};

export default EntityAddNew;
