/** @jsxImportSource @emotion/react */
import React from 'react';

import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';

import { useParams } from 'react-router-dom';
import { DefaultLocation } from 'src/interfaces/IPage';

import { AllFunnelOfferFormProps } from './AllFunnelOfferForm.types';
import { buttonWrapperCSS, formWrapperCSS, headingCSS } from './AllFunnelOfferForm.style';
import EditPhoneButton from 'src/questionsForm/components/EditPhoneButton/EditPhoneButton';
import { useMutatePersonPhone } from 'src/api/allFunnel';
import { QUERY_CACHE_KEYS } from 'src/constants/queryCacheKeys';
import CustomTCPAConsent from 'src/components/CustomTCPAConsent/CustomTCPAConsent';

const AllFunnelOfferForm: React.FC<AllFunnelOfferFormProps> = ({
  shouldRenderUpdatedHero = false,
  phoneValue,
  onSubmit,
  onUserPhoneUpdated,
  isPageSubmitting,
  customCSS = {}
}) => {
  const { gid, flow } = useParams() as DefaultLocation;
  const { mutateAsync: mutatePersonPhone } = useMutatePersonPhone(gid, [[QUERY_CACHE_KEYS.ALL_FUNNEL, gid]]);

  const handlePhoneUpdateOnSubmit = async (phone: string | null) => {
    if (phone && phone !== phoneValue) {
      onUserPhoneUpdated(true);
      await mutatePersonPhone(phone);
    }
    onSubmit(phone);
  };

  const handleSubmit = (phone: string | null, isModalView: boolean) => {
    if (isModalView) {
      analytics.track(SEGMENT.TERMS_OF_SERVICE_ACCEPTED_FROM_MODAL, gid, flow);
    }

    handlePhoneUpdateOnSubmit(phone);
  };

  return (
    <div css={[formWrapperCSS(shouldRenderUpdatedHero), customCSS.formWrapper]}>
      {!shouldRenderUpdatedHero && (
        <h1 css={[headingCSS, customCSS.heading]}>Do You Have the Best Rate on Home Insurance?</h1>
      )}
      <EditPhoneButton
        customCSS={buttonWrapperCSS}
        label="Check My Rates"
        phoneValue={phoneValue}
        isLoading={isPageSubmitting}
        onSubmit={phone => handleSubmit(phone, false)}
        isAFVariation
      />

      <CustomTCPAConsent location={SEGMENT.LOCATIONS.AF_PRIVACY_MATTERS} />
    </div>
  );
};

export default AllFunnelOfferForm;
