import { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import Dialog from 'src/kit/Dialog/Dialog';
import { customModalStyleCSS } from './RecaptchaModal.style';

interface RecaptchaModalProps {
  onVisibilityChange(token: string | null): void;
  isOpen: boolean;
  siteKey: string;
}

const RecaptchaModal: React.FC<RecaptchaModalProps> = ({ isOpen = true, onVisibilityChange, siteKey }) => {
  const recaptchaRef = useRef(null);

  return (
    <Dialog
      customCSS={{
        dialogInline: { content: customModalStyleCSS() }
      }}
      isOpen={isOpen}
    >
      <ReCAPTCHA ref={recaptchaRef} size="normal" sitekey={siteKey} type="image" onChange={onVisibilityChange} />
    </Dialog>
  );
};

export default RecaptchaModal;
