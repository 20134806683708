enum CoverageHomeKeys {
  Dwelling = 'dwelling',
  OtherStructures = 'other_structures',
  PersonalProperty = 'personal_property',
  MedicalPayments = 'medical_payments',
  PersonalLiability = 'personal_liability',
  LossOfUse = 'loss_of_use'
}

type CoverageInfoHash = {
  [key in CoverageHomeKeys]: string;
};

export const CoverageHomeTitles: CoverageInfoHash = {
  [CoverageHomeKeys.Dwelling]: 'Dwelling',
  [CoverageHomeKeys.OtherStructures]: 'Other structures',
  [CoverageHomeKeys.PersonalProperty]: 'Personal Property',
  [CoverageHomeKeys.MedicalPayments]: 'Medical Payment',
  [CoverageHomeKeys.PersonalLiability]: 'Personal Liability',
  [CoverageHomeKeys.LossOfUse]: 'Loss of use'
};

export const CoverageDescriptions: CoverageInfoHash = {
  [CoverageHomeKeys.Dwelling]: `If your house is damaged by a covered event, 
  which could include a fire,
     fallen tree, lightning, or another hazard, 
     this coverage can help you pay some or all of the cost to repair or rebuild your home.`,
  [CoverageHomeKeys.OtherStructures]: `If damages occur to a fence, shed, or other structure that
   isn't attached to your house, 
    this coverage can help pay for some or all of the cost to repair or rebuild it.`,
  [CoverageHomeKeys.PersonalProperty]: `If your furniture, clothing, or other belongings
   are stolen or damaged during a covered incident,
     this coverage can help you pay some or all of the cost to repair or replace them.`,
  [CoverageHomeKeys.MedicalPayments]: `If another person gets a minor injury on your property, 
    this coverage can help pay for some
     or all of the associated medical expenses regardless of who is at fault.`,
  [CoverageHomeKeys.PersonalLiability]: `If someone sues you because they injure 
  themselves or because their property gets damaged at your home, 
    this coverage can help you pay for some or all of the associated expenses like legal fees,
     medical bills,
     and replacement items.`,
  [CoverageHomeKeys.LossOfUse]: `If you're unable to live in your home after a claim,
     this coverage can help you pay for some or all of the cost to stay in a hotel or
      rental while your home is repaired or rebuilt.`
};
