import { css } from '@emotion/react';
import { colors, mq, typography } from 'src/theme/variables';
import { text12 } from 'src/theme/typography';

export const consentCSS = css`
  color: ${colors.warmGray};
  font-family: ${typography.fontFamily};

  ${mq[0]} {
    margin-top: -40px;
    padding-bottom: 40px;
  }
`;

export const paragraphCSS = css`
  ${text12};
  margin-bottom: 20px;
  white-space: pre-line;

  a {
    color: ${colors.azure};
  }
`;
