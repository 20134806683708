import { css } from '@emotion/react';
import { text, text20, text32, text48 } from 'src/theme/typography';
import { mq } from 'src/theme/variables';

export const container = css`
  ${text};
  text-align: center;
  max-width: 920px;
  padding-top: 20px;
  padding-bottom: 40px;
  ${mq[0]} {
    ${text20};
    padding-top: 60px;
    padding-bottom: 56px;
  }
  ${mq[1]} {
    padding-top: 68px;
    padding-bottom: 100px;
  }
`;

export const heading = css`
  display: inline-block;
  font-weight: 900;
  ${text32};
  ${mq[0]} {
    ${text48};
  }
`;

export const subheading = css`
  margin-top: 8px;
  ${mq[0]} {
    margin-top: 20px;
  }
  ${mq[1]} {
    margin-top: 0;
  }
`;

export const image = css`
  margin: 8px; 0;
  ${mq[0]} {
    margin-top: -56px;
    margin-bottom: -32px;
  }
`;

export const description = css`
  p {
    margin-top: 8px;
    :first-of-type {
      margin-top: 0;
    }
    ${mq[0]} {
      margin-top: 4px;
    }
  }
`;
