export const lifeFAQAccordionItems = [
  {
    title: 'What is term life insurance?',
    description: (
      <span>
        Term life insurance pays your loved ones money if you pass away. It covers a set amount of time that you choose
        — usually between 10 and 30 years – and provides a sum of money that you decide when you buy the policy.
      </span>
    )
  },
  {
    title: 'Why would a family consider term life insurance?',
    description: (
      <span>
        Think of it as a safety net for your loved ones after you’re gone. It protects your income and helps your family
        cover expenses like a mortgage, bills, and children’s education.
      </span>
    )
  },
  {
    title: 'Is term life insurance expensive?',
    description: (
      <span>
        Term life insurance is generally considered a more affordable option and it can be customized to fit your
        budget. More than half of Americans overestimate the cost of life insurance** – so it’s important to do your
        research and get a quote based on your age, gender, and health history.
      </span>
    )
  },
  {
    title: 'Is a medical exam required?',
    description: (
      <span>
        Our goal is to find the lowest rate for the level of life insurance coverage you’re seeking. Depending on your
        age and health history, a medical exam may be required to get the most accurate pricing. Some applicants will be
        offered a life insurance policy with no medical exam. Either way, you will be guided through the process to
        ensure your application is completed as quickly as possible.{' '}
      </span>
    )
  }
];
