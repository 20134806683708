/** @jsxImportSource @emotion/react */
import React from 'react';

import { container } from './Tag.style';
import { TagProps } from './Tag.types';

const Tag: React.FC<TagProps> = ({ customCSS, color, children }) => {
  return <div css={[container(color), customCSS]}>{children}</div>;
};

export default Tag;
