/** @jsxImportSource @emotion/react */
import React from 'react';

import Button from 'src/kit/Button/Button';
import Dialog from 'src/kit/Dialog/Dialog';
import { buttons, confirmButton } from './ConfirmationModal.style';

interface Props {
  confirmButtonLabel?: string;
  onConfirm?: () => void;
  title?: string;
  subTitle?: string;
  isModalOpen?: boolean;
  onClose?: () => void;
}

const ConfirmationModal: React.FC<Props> = ({
  confirmButtonLabel = 'Confirm',
  isModalOpen = false,
  onConfirm,
  ...props
}) => (
  <Dialog isOpen={isModalOpen} size="small" {...props}>
    <div css={buttons}>
      <Button data-testid="cancel-button" type="button" variant="secondary" onClick={props.onClose}>
        Cancel
      </Button>
      <Button css={confirmButton} type="button" onClick={onConfirm}>
        {confirmButtonLabel}
      </Button>
    </div>
  </Dialog>
);

export default ConfirmationModal;
