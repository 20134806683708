import { css } from '@emotion/react';
import { mq } from 'src/theme/variables';
import { text as textCSS, text20 } from 'src/theme/typography';

export const icon = css`
  display: none;
  margin-right: 24px;
  ${mq[0]} {
    display: block;
  }
`;

export const text = css`
  margin-right: 24px;
  ${textCSS};
  ${mq[0]} {
    ${text20};
    font-weight: 700;
  }
`;
