import React from 'react';
import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';

import ROUTES from './constants/routes';
import GenericErrorLayout from './layouts/GenericErrorLayout/GenericErrorLayout';

import TermsAndConditionsLayout from './layouts/TermsAndConditionsLayout/TermsAndConditionsLayout';
import QuestionsLayout from './layouts/QuestionsLayout/QuestionsLayout';
import QuotesLayout from './layouts/QuotesLayout/QuotesLayout';
import ConfirmationLayout from './layouts/ConfirmationLayout/ConfirmationLayout';
import LandingLayout from './layouts/LandingLayout/LandingLayout';

import FloridaNoOptions from './layouts/FloridaNoOptions/FloridaNoOptions';
import Session from './components/Session/Session';
import InterstitialLayout from './layouts/InterstitialLayout/InterstitialLayout';
import SessionTimeoutLayout from './layouts/SessionTimeoutLayout/SessionTimeoutLayout';
import QuotesViewLayout from './layouts/QuotesViewLayout/QuotesViewLayout';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';

export const CustomRouterProvider = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<ScrollToTop />} errorElement={<GenericErrorLayout />}>
      <Route path={ROUTES.TERMS_AND_CONDITIONS} element={<TermsAndConditionsLayout />} />
      <Route path={ROUTES.QUESTIONS} element={<QuestionsLayout />} />
      <Route path={ROUTES.INTERSTITIAL} element={<InterstitialLayout />} />
      <Route path={ROUTES.QUOTES} element={<QuotesLayout />} />
      <Route path={ROUTES.CONFIRMATION} element={<ConfirmationLayout />} />
      <Route path={ROUTES.LANDING_V1} element={<LandingLayout />} />
      <Route path={ROUTES.LANDING_V2} element={<LandingLayout />} />
      <Route path={ROUTES.LANDING} element={<LandingLayout />} />
      <Route path={ROUTES.FLOW_PROBLEM} element={<GenericErrorLayout />} />
      <Route path={ROUTES.PROBLEM} element={<GenericErrorLayout />} />
      <Route path={ROUTES.NO_OPTIONS} element={<FloridaNoOptions />} />
      <Route path={ROUTES.FLOW} element={<Session />} />
      <Route path={ROUTES.ONLY_FLOW_KEY} element={<Session />} />
      <Route path={ROUTES.SESSION_TIMEOUT} element={<SessionTimeoutLayout />} />
      <Route path={ROUTES.QUOTES_VIEW} element={<QuotesViewLayout />} />
      <Route path={ROUTES.INVALID_ROUTE} element={<GenericErrorLayout />} />
    </Route>
  )
);
