import { css } from '@emotion/react';
import { colors as c, labelWidth, mq } from 'src/theme/variables';
import { text20 } from 'src/theme/typography';

export const viewEditFieldsEditCSS = css`
  background-color: transparent;
  margin-left: 0;
  ${mq[0]} {
    margin-left: 12px;
  }
`;

export const viewEditFieldsViewCSS = css`
  text-align: left;
`;

export const viewEditFieldsInputCSS = css`
  ${text20};
  background-color: ${c.concrete};
  border: 1px solid ${c.warmGray};
  border-radius: 4px;
  box-sizing: border-box;
  color: ${c.black};
  height: 42px;
  outline: none;
  padding-left: 8px;
  padding-right: 8px;
  transition:
    border-color 0.2s ease,
    color 0.2s ease;

  &:focus {
    border-color: ${c.warmGray};
    background-color: ${c.white};
  }
`;

export const savingsOfferMobileIcon = css`
  height: 24px;
  width: 24px;
`;

export const editButtonCSS = css`
  cursor: pointer;
  border: none;
  color: ${c.steelBlue};
  padding: 8px 10px;
  text-decoration: none;
  background-color: transparent;
`;

export const labelBlockCSS = css`
  ${mq[0]} {
    width: ${labelWidth}px;
  }
`;

export const labelContentCSS = css`
  font-weight: bold;
`;

export const buttonLabelCSS = css`
  font-weight: bold;
`;
