import { css, SerializedStyles } from '@emotion/react';
import { colors, indicators } from 'src/theme/variables';
import { text20 } from 'src/theme/typography';
import { activeElementReset } from 'src/theme/helpers';

import checkboxMark from 'src/assets/svg/check-mark.svg';

export const navigation = (isCompleted: boolean): SerializedStyles => css`
  padding-bottom: 24px;
  position: relative;
  &::before {
    background-color: ${isCompleted ? colors.mineshaft : colors.nobel};
    bottom: ${indicators.sizeDesktop / 2 - 16}px;
    content: '';
    left: -18.5px;
    position: absolute;
    top: 10px;
    width: 2px;
  }
  &:last-of-type::before {
    content: none;
  }
`;

export const navigationName = (isAvailable: boolean): SerializedStyles => css`
  ${activeElementReset};
  ${text20};
  font-weight: 700;
  color: ${isAvailable ? colors.black : colors.nobel};
  ${!isAvailable && 'pointer-events: none;'};
`;

export const chevron = (isExpanded: boolean): SerializedStyles => css`
  height: 12px;
  margin-left: 10px;
  transform: ${isExpanded ? 'none' : 'rotate(-180deg)'};
  transition: transform 300ms ease;
  width: 12px;
`;

export const navigationIndicator = (isCompleted: boolean, isCurrent: boolean): SerializedStyles => css`
  background-color: ${colors.white};
  border: 2px solid ${colors.nobel};
  border-radius: 50%;
  content: '';
  height: ${indicators.sizeDesktop}px;
  left: ${-indicators.sizeDesktop - 11}px;
  position: absolute;
  top: ${13 - indicators.sizeDesktop / 2}px;
  width: ${indicators.sizeDesktop}px;
  z-index: 1;

  ${isCompleted &&
  css`
    background: ${colors.green} url(${checkboxMark}) no-repeat center center;
    background-size: 8px;
    border-color: ${colors.green};
  `};

  ${isCurrent &&
  css`
    background-color: ${colors.mineshaft};
    border-color: ${colors.mineshaft};
  `};
`;

export const loadingLineCSS = css`
  padding: 6px 0 4px;
`;
