import { css, SerializedStyles } from '@emotion/react';
import { text, text14, text24, text32 } from 'src/theme/typography';
import { colors, mq } from 'src/theme/variables';

export const sessionTimeoutWrapperCSS = (url: string): SerializedStyles => css`
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-image: url(${url});
  background-repeat: no-repeat;
  background-size: cover;

  h2 {
    text-align: center;
    margin: 0;
    ${text24};
    line-height: 32px;
  }

  p {
    text-align: center;
    font-weight: 500;
    ${text14};
    line-height: 20px;
    margin-top: 4px;
  }

  ${mq[0]} {
    align-items: center;

    h2 {
      ${text32};
    }

    p {
      margin-top: 8px;
      ${text};
    }
  }
`;

export const cardCSS = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 610px;
  padding: 24px 16px 16px;
  box-sizing: border-box;
  border-color: ${colors.nobel};
  box-shadow: 2px 4px 0px rgba(0, 0, 0, 0.12);
  border-radius: 0;

  ${mq[0]} {
    padding: 40px;
    border-radius: 8px;
  }
`;
