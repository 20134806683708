import { css, SerializedStyles } from '@emotion/react';

import { borderRadius, colors, mq, sizes, onlyMobile } from 'src/theme/variables';
import { FormQuestionDirections } from 'src/interfaces/IQuestion';

const horizontal = (isLifeVariant?: boolean): SerializedStyles => css`
  flex-wrap: wrap;

  .radio-button-label {
    flex: 1 1 auto;
    width: calc(50% - 80px);

    ${isLifeVariant &&
    css`
      ${onlyMobile} {
        max-width: 25%;
      }
    `}

    + .radio-button-label {
      margin-top: 0;
      margin-left: 8px;

      ${mq[0]} {
        margin-left: 20px;

        ${isLifeVariant &&
        css`
          margin-left: 0;
        `}
      }
    }
  }
`;

const vertical = css`
  flex-direction: column;
`;

export const questions = (
  direction: FormQuestionDirections = FormQuestionDirections.vertical,
  isLifeVariant?: boolean
): SerializedStyles => css`
  display: flex;
  ${direction === FormQuestionDirections.horizontal ? horizontal(isLifeVariant) : vertical};
`;

export const otherBtn = css`
  background-color: ${colors.white};
  border: 1px solid ${colors.black};
  border-radius: ${borderRadius}px;
  cursor: pointer;
  height: ${sizes.controlHeight}px;
  margin-top: 8px;
  max-width: 300px;
  padding: 0 20px;
  text-align: left;
  width: 100%;
`;
