import React, { useEffect } from 'react';

import { useFormContext } from 'react-hook-form';

import { FormFieldProps, FormQuestionProps, QuestionSwitchProps, QuestionTypes } from 'src/interfaces/IQuestion';
import RadioQuestion from './RadioQuestion/RadioQuestion';
import TextInputQuestion from './TextInputQuestion/TextInputQuestion';
import NumberInputQuestion from './NumberInputQuestion/NumberInputQuestion';
import MaskedInputQuestion from './MaskedInputQuestion/MaskedInputQuestion';
import DateInputQuestion from './DateInputQuestion/DateInputQuestion';
import AsyncSelectQuestion from './AsyncSelectQuestion/AsyncSelectQuestion';
import SelectQuestion from './SelectQuestion/SelectQuestion';
import AddressQuestion from './AddressQuestion/AddressQuestion';
import TypicalMileageQuestion from './TypicalMileageQuestion/TypicalMileageQuestion';
import QuantityQuestion from './QuantityQuestion/QuantityQuestion';
import SliderQuestion from './SliderQuestion/SliderQuestion';
import MoneyInputQuestion from './MoneyInputQuestion/MoneyInputQuestion';
import CheckboxQuestion from './CheckboxQuestion/CheckboxQuestion';
import UploadFileInputFlorida from 'src/components/UploadFileInputFlorida/UploadFileInputFlorida';

const getQuestionByType = (type: QuestionTypes | string = QuestionTypes.TextInput): React.FC<FormQuestionProps> => {
  switch (type) {
    case QuestionTypes.Radio:
      return RadioQuestion;
    case QuestionTypes.Date:
      return DateInputQuestion;
    case QuestionTypes.Select:
      return SelectQuestion;
    case QuestionTypes.AsyncSelect:
      return AsyncSelectQuestion;
    case QuestionTypes.Address:
      return AddressQuestion;
    case QuestionTypes.TypicalMileage:
      return TypicalMileageQuestion;
    case QuestionTypes.NumberInput:
      return NumberInputQuestion;
    case QuestionTypes.MaskedInput:
      return MaskedInputQuestion;
    case QuestionTypes.MoneyInput:
      return MoneyInputQuestion;
    case QuestionTypes.Quantity:
      return QuantityQuestion;
    case QuestionTypes.Checkbox:
      return CheckboxQuestion;
    case QuestionTypes.Slider:
      return SliderQuestion;
    case QuestionTypes.UploadFileInputFlorida:
      return UploadFileInputFlorida;
    case QuestionTypes.TextInput:
    default:
      return TextInputQuestion;
  }
};

const Question: React.FC<QuestionSwitchProps & FormFieldProps> = ({ type, ...props }) => {
  const QuestionComponent = getQuestionByType(type);
  const { clearErrors } = useFormContext();

  /* Since we use `shouldUnregister: false`, there is a possibility
    to trigger validation and then unmount that field.
    Without cleanup formState will be invalid. */
  useEffect(() => {
    return () => {
      clearErrors(props.key_name);
    };
  }, [clearErrors, props.key_name]);

  return <QuestionComponent {...props} />;
};

export default Question;
