/** @jsxImportSource @emotion/react */
import React, { useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { get, useFormState } from 'react-hook-form';

import ValidationError from 'src/shared/components/ValidationError/ValidationError';
import { controlCSS, textQuestion, labelWrapperCSS } from './QuestionWrapper.style';
import SEGMENT from 'src/constants/segment';
import analytics from 'src/utils/analytics';

import { DefaultLocation, Flows } from 'src/interfaces/IPage';
import { QuestionWrapperProps } from './QuestionWrapper.types';
import QuestionLabel from './QuestionLabel/QuestionLabel';

const INVALID_FIELDS_TO_REPORT = [
  { key: 'email', segmentEvent: SEGMENT.EMAIL_CHANGE_ATTEMPTED },
  { key: 'phone', segmentEvent: SEGMENT.PHONE_CHANGE_ATTEMPTED }
];

export const QuestionWrapper: React.FC<QuestionWrapperProps> = ({
  label,
  hint,
  inputComponent: InputComponent,
  heading,
  labelTag,
  hideLabel = false,
  ...textProps
}) => {
  const { errors } = useFormState();
  const keyName = textProps.key_name;

  const { flow, gid } = useParams() as DefaultLocation;
  const error = get(errors, textProps.key_name);
  const reportedInvalidFiled = useRef(false);
  const isLifeFlowVariant = flow === Flows.Life;

  // TODO: Remove all logic connected to tracking events and params out of TextQuestion components
  useEffect(() => {
    if (error) {
      INVALID_FIELDS_TO_REPORT.forEach(input => {
        if (input.key === keyName && !reportedInvalidFiled.current) {
          analytics.track(input.segmentEvent, gid, flow, {
            resolution: SEGMENT.CHANGE_ATTEMPTED_RESOLUTION.ERROR
          });
          reportedInvalidFiled.current = true;
        }
      });
    }
  }, [error, gid, flow, keyName, reportedInvalidFiled]);

  return (
    <div css={textQuestion(isLifeFlowVariant)} data-testid={`question-input-${textProps.key_name}`}>
      <div css={labelWrapperCSS(isLifeFlowVariant)}>
        {!hideLabel && label && (
          <QuestionLabel
            wrapperElement={labelTag}
            keyName={textProps.key_name}
            tooltipText={hint}
            hasError={!!error?.message && !isLifeFlowVariant}
          >
            {label}
          </QuestionLabel>
        )}
      </div>
      <div css={controlCSS(isLifeFlowVariant)}>
        <InputComponent
          {...textProps}
          hasError={!!error?.message}
          inputId={textProps.key_name}
          label={label as string}
        />
      </div>
      <ValidationError heading={heading} visible={!!error?.message && !isLifeFlowVariant}>
        {error?.message}
      </ValidationError>
    </div>
  );
};

export default QuestionWrapper;
