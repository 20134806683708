/** @jsxImportSource @emotion/react */
import React from 'react';

import { container } from './Container.style';
import { ContainerProps } from './types';

const Container: React.FC<ContainerProps> = ({
  isAriaLivePolite,
  customCSS,
  fullscreen = false,
  dense = false,
  children,
  id
}) => {
  return (
    <div {...(isAriaLivePolite && { 'aria-live': 'polite' })} css={[container(fullscreen, dense), customCSS]} id={id}>
      {children}
    </div>
  );
};

export default Container;
