/** @jsxImportSource @emotion/react */

import { useEffect, useRef, useState } from 'react';
import { customTooltipCSS } from './HistoricalPremiumChart.style';
import { AreaPointItem } from './addon';
import { PremiumTypeDto } from 'src/interfaces/session.types';

export type AnchorTypeT = 'bottom' | 'left' | 'right';
export type CustomTooltipTypes = 'first' | 'last' | 'default' | 'firstHighest' | 'lastHighest' | 'defaultHighest';
export type CustomTooltipCSSProps = {
  left: number;
  top: number;
  width: number;
  anchorPosition: number;
  anchorType: AnchorTypeT;
};

const CustomChartTooltip: React.FC<{
  data: AreaPointItem;
  type: CustomTooltipTypes;
  premiumType?: PremiumTypeDto;
  isEstimatedValue: boolean;
}> = ({ data, type, premiumType, isEstimatedValue }) => {
  const [componentWidth, setComponentWidth] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    ref.current && setComponentWidth((ref.current as HTMLElement).clientWidth);
  }, []);

  const getTooltipParamsByType = (): CustomTooltipCSSProps => {
    let left = 0;
    let top = data.y - 47.5;
    let anchorPosition = 6;
    let anchorType: AnchorTypeT = 'bottom';
    let width = 0;

    switch (type) {
      case 'first':
        left = data.x - 13;
        break;
      case 'last':
        const percentOffsetLast = data.x - componentWidth + 10;
        const dollarOffsetLast = data.x - componentWidth - 10;
        left = premiumType === 'user_data' ? dollarOffsetLast : percentOffsetLast;
        anchorPosition = componentWidth - 20;
        width = premiumType === 'user_data' ? 90 : 60;
        break;

      case 'lastHighest':
        const percentOffsetLastHighest = data.x - componentWidth + 10;
        const dollarOffsetLastHighest = data.x - componentWidth - 20;
        anchorPosition = componentWidth - 20;
        width = 60;

        left = premiumType === 'user_data' ? dollarOffsetLastHighest : percentOffsetLastHighest;
        top = data.y - 18;
        anchorType = 'right';
        break;

      case 'defaultHighest':
        left = data.x - componentWidth - 20;
        top = data.y - 18;
        anchorType = 'right';
        break;
      case 'firstHighest':
        top = data.y - 18;
        left = data.x + componentWidth - 25;
        anchorType = 'left';
        break;
      default:
        left = data.x - componentWidth / 2;
        anchorPosition = componentWidth / 2 - 7;
        break;
    }

    return { left, top, anchorPosition, anchorType, width };
  };

  const tooltipValue = ['state_data', 'carrier_data'].includes(premiumType as string)
    ? `${data.payload.value}%`
    : `$${data.payload.value}`;

  return (
    <div css={customTooltipCSS(getTooltipParamsByType())} ref={ref}>
      {tooltipValue} {''}
      {isEstimatedValue && '(est.)'}
    </div>
  );
};

export default CustomChartTooltip;
