/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';

import ValidationError from 'src/shared/components/ValidationError/ValidationError';
import { SerializedStyles } from '@emotion/react';
import {
  editButtonCSS,
  viewEditFieldsEditCSS,
  viewEditFieldsViewCSS,
  buttonLabelCSS,
  labelBlockCSS,
  labelContentCSS
} from './ViewEditFields.style';

interface ViewEditFieldProps {
  hasError: boolean;
  label: string;
  value: string;
  errorMessage: string;
  showEditable?: boolean;
  onEditClicked?(): void;
  customCss?: SerializedStyles;
  children?: React.ReactNode;
}

const ViewEditField: React.FunctionComponent<ViewEditFieldProps> = ({
  showEditable,
  value,
  customCss,
  label,
  hasError,
  errorMessage,
  onEditClicked,
  children
}) => {
  const [isEditMode, setIsEditMode] = useState(showEditable);

  useEffect(() => {
    setIsEditMode(showEditable);
  }, [showEditable]);

  return isEditMode || !value ? (
    <label css={[labelBlockCSS, customCss]}>
      <span css={labelContentCSS}>{label}</span>
      {children}
      <ValidationError visible={hasError}>{errorMessage}</ValidationError>
    </label>
  ) : (
    <div css={[viewEditFieldsEditCSS, customCss]}>
      <div css={buttonLabelCSS}>{label}</div>
      <span css={viewEditFieldsViewCSS}>{value}</span>
      <button
        css={[editButtonCSS]}
        onClick={() => {
          onEditClicked && onEditClicked();
          setIsEditMode(true);
        }}
      >
        Edit
      </button>
    </div>
  );
};

export default ViewEditField;
