/** @jsxImportSource @emotion/react */
import React from 'react';
import { FileUploadListItemProps } from './FileUploadListItem.types';
import FileIcon from 'src/assets/svg/file.svg';
import FailedIcon from 'src/assets/svg/circle-close.svg?react';
import CheckIcon from 'src/assets/svg/circle-check.svg?react';
import { FileStatusUpload } from 'src/shared/components/FileUploadsList/FileUploadList.types';
import RefreshIcon from 'src/assets/svg/refresh.svg?react';
import DeleteIcon from 'src/assets/svg/close.svg?react';
import {
  actionsCSS,
  deleteCSS,
  descriptionCSS,
  failedCSS,
  fileContainerCSS,
  informationCSS,
  loaderCSS,
  nameCSS,
  refreshIconCSS,
  sizeCSS,
  statusIconCSS
} from './FileUploadListItem.style';
import format from 'src/utils/format';

const FileUploadListItem: React.FC<FileUploadListItemProps> = ({ file, onRetryUpload, onRemoveFile }) => {
  const isUploadFailed = file.status === FileStatusUpload.FAILED;
  const isUploading = file.status === FileStatusUpload.LOADING;

  return (
    <div key={file.file_name} css={fileContainerCSS}>
      <img src={FileIcon} alt="File Icon" />
      <div css={informationCSS}>
        <div css={descriptionCSS}>
          <div css={nameCSS}>
            {file.file_name}
            {isUploadFailed ? (
              <span css={failedCSS}>
                <FailedIcon css={statusIconCSS} /> Failed to upload
              </span>
            ) : (
              (!isUploading && <CheckIcon css={statusIconCSS} />) || null
            )}
          </div>
          {!isUploadFailed && <div css={sizeCSS}>{format.size(file.byte_size)}</div>}
        </div>
        {file.status === FileStatusUpload.LOADING && <div css={loaderCSS} />}
      </div>
      <div css={actionsCSS}>
        {isUploadFailed && <RefreshIcon css={refreshIconCSS} onClick={onRetryUpload} />}
        <DeleteIcon css={deleteCSS} onClick={onRemoveFile} />
      </div>
    </div>
  );
};

export default FileUploadListItem;
