/** @jsxImportSource @emotion/react */
import './reactSlick.css';
import React, { useRef, useState } from 'react';
import Slider from 'react-slick';

import { CarouselProps } from './Carousel.types';
import { container } from './Carousel.style';
import SIRV from 'src/constants/sirv';

const Carousel: React.FC<CarouselProps> = ({ customCSS, withAutoplayControl = false, children, ...options }) => {
  const [autoplay, setAutoplay] = useState<boolean>(withAutoplayControl);
  const carousel = useRef<{ slickPlay: () => void; slickPause: () => void }>(null);

  const onControlClick = () => {
    if (autoplay) {
      carousel.current?.slickPause();
    } else {
      carousel.current?.slickPlay();
    }
    setAutoplay(s => !s);
  };

  const appendDots = (dots: React.ReactNode) => {
    return (
      <ul className="slick-dots">
        {dots}
        {withAutoplayControl && (
          <li className="carousel-play-control">
            <button onClick={onControlClick}>
              {autoplay ? <img src={SIRV.PAUSE_ICON} alt="Pause" /> : <img src={SIRV.PLAY_ICON} alt="Play" />}
            </button>
          </li>
        )}
      </ul>
    );
  };

  return (
    <Slider
      css={[container, customCSS]}
      ref={carousel as any}
      {...options}
      dots={options.dots || withAutoplayControl}
      autoplay={options.autoplay || withAutoplayControl}
      appendDots={appendDots}
    >
      {children}
    </Slider>
  );
};

export default Carousel;
