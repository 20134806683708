import React, { useEffect } from 'react';

import PageHeader from 'src/components/PageHeader/PageHeader';
import Footer from 'src/components/Footer/Footer';
import Page from 'src/components/Page/Page';
import { useParams } from 'react-router-dom';

import SEGMENT from 'src/constants/segment';
import useQuestions from 'src/api/questions/useQuestions';
import useTrackFullStorySession from 'src/hooks/useTrackFullStorySession';
import LifeQuotesLanding from 'src/components/LifeQuotes/LifeQuotes';

const LifeLayout: React.FC = () => {
  const { gid } = useParams();
  const { data: questions } = useQuestions(gid);
  useTrackFullStorySession(gid);

  useEffect(() => {
    document.title = 'Matic Insurance Instant Quotes Result';
  }, []);

  return (
    <Page>
      <PageHeader
        page={SEGMENT.PAGES_KEY.LIFE}
        isLiveHeaderVariation
        userName={questions?.answers?.person_first_name as string | ''}
      />
      <LifeQuotesLanding />
      <Footer page={SEGMENT.PAGES_KEY.LIFE} />
    </Page>
  );
};

export default LifeLayout;
