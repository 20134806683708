/** @jsxImportSource @emotion/react */
import React from 'react';

import { css } from '@emotion/react';

import Button from 'src/kit/Button/Button';
import { ButtonSize } from 'src/kit/Button/types';
import { HookFormFieldEntity, EntityForm } from 'src/interfaces/IPage';
import EntityDetailsForm from './EntityDetailsForm';
import { formActions } from 'src/theme/shared-styles';
import { UploadFilesComponentProps } from 'src/components/UploadFilesComponent/UploadFilesComponent.types';
import Consent from 'src/components/Consent/Consent';

interface Props {
  entities: HookFormFieldEntity[];
  form: EntityForm;
  entityKey: string;
  show_consent?: boolean;
  isCurrentStep: boolean;
  onPageSubmit(): Promise<any>;
  triggerSchemaUpdate?(): void;
  uploadFilesWrapperParams?: UploadFilesComponentProps;
  isFormSubmitting: boolean;
}

const EntityDetails: React.FC<Props> = ({
  entities,
  form,
  entityKey,
  show_consent,
  isCurrentStep,
  onPageSubmit,
  triggerSchemaUpdate,
  uploadFilesWrapperParams,
  isFormSubmitting
}) => {
  const lastId = entities[entities.length - 1]?.id;

  return (
    <div>
      {entities.map((e, i) => (
        <EntityDetailsForm
          key={e.id}
          key_name={entityKey}
          index={i}
          entity={e}
          questions={form.questions!}
          greeting={form.greeting}
          triggerSchemaUpdate={triggerSchemaUpdate}
          isLast={isCurrentStep && lastId === e.id}
          uploadFilesWrapperParams={uploadFilesWrapperParams}
        />
      ))}
      {isCurrentStep && (
        <div css={formActions}>
          <Button
            data-testid="questions-form-action-button"
            css={css`
              width: 100%;
            `}
            type="button"
            isLoading={isFormSubmitting}
            size={ButtonSize.Large}
            onClick={onPageSubmit}
          >
            {!!show_consent ? 'Get Your Quotes' : 'Confirm & Continue'}
          </Button>
        </div>
      )}
      {isCurrentStep && !!show_consent && <Consent />}
    </div>
  );
};

export default EntityDetails;
