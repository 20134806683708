import { UseBaseMutationResult, useMutation } from 'react-query';
import { ResponseError } from 'src/interfaces/IResponse';
import api from 'src/api/api';
import { API_URLS } from 'src/constants/api';
import { getApiUrl } from 'src/utils/api.helpers';
import { PropertyData } from 'src/components/EditInfoSideModal/EditInfoScheme';

const useMutatePropertyAnswers = (gid: string): UseBaseMutationResult<PropertyData, ResponseError, PropertyData> => {
  return useMutation((data: PropertyData) => api.put(getApiUrl(API_URLS.PROPERTY_INFO_UPDATE, gid), { body: data }));
};

export default useMutatePropertyAnswers;
