import { css } from '@emotion/react';
import { colors, sizes } from 'src/theme/variables';
import { text, text32 } from 'src/theme/typography';

export const containerCSS = css`
  position: relative;
`;

export const closeButtonCSS = css`
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;

  path {
    stroke: ${colors.azure};
  }
`;

export const cardCSS = css`
  padding: ${sizes.paddingBodyHorizontalMobile}px;
  margin-top: 12px;
`;

export const cardHeadingCSS = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 28px;
`;

export const priceCSS = css`
  text-align: right;
`;

export const premiumCSS = css`
  ${text32};
  font-weight: bold;
`;

export const premiumPeriodCSS = css`
  ${text};
  color: ${colors.warmGray};
`;

export const actionButtonCSS = css`
  margin-top: 20px;
  width: 100%;
`;
