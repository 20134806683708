import { css } from '@emotion/react';
import { mq } from 'src/theme/variables';

export const links = css`
  margin-bottom: 32px;
`;

export const paragraph = css`
  margin-top: 0;
  margin-bottom: 16px;
`;

export const socialLinks = css`
  display: flex;
  justify-content: center;
  a:not(:last-child) {
    margin-right: 20px;
  }
  ${mq[0]} {
    justify-content: unset;
  }
`;
