import { css } from '@emotion/react';
import { text20, text32 } from 'src/theme/typography';
import { mq } from 'src/theme/variables';

export const faqContainerCSS = css`
  margin: 0 auto 40px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  max-width: 960px;
  position: relative;

  h2 {
    margin: 0 0 12px;
    ${text20};
  }

  ${mq[0]} {
    gap: 40px;
    margin-bottom: 80px;
  }

  ${mq[1]} {
    h2 {
      ${text32};
      line-height: 40px;
    }
  }
`;

export const triangleCSS = css`
  position: absolute;
  left: -83px;
  top: 359px;
  display: none;

  ${mq[2]} {
    display: block;
  }
`;

export const dotCSS = css`
  position: absolute;
  right: -99px;
  top: 479px;
  display: none;

  ${mq[2]} {
    display: block;
  }
`;
