/** @jsxImportSource @emotion/react */
import { forwardRef, InputHTMLAttributes } from 'react';

import { SerializedStyles } from '@emotion/react';

import { inputCSS, inputWrapper, focusHelperCSS, prefilledValueCSS } from './Input.style';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  hasError?: boolean;
  hasPrefix?: boolean;
  customCSS?: SerializedStyles;
  customWrapperCSS?: SerializedStyles;
  className?: string;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ hasError = false, hasPrefix = false, customCSS, customWrapperCSS, ...props }, ref) => {
    return (
      <div css={[inputWrapper, customWrapperCSS]}>
        <input
          type="text"
          css={[
            inputCSS(hasError, props.disabled, hasPrefix),
            customCSS,
            prefilledValueCSS(!!props.defaultValue || !!props.value)
          ]}
          {...props}
          ref={ref}
        />
        <div css={focusHelperCSS} className="input-focus-helper" />
      </div>
    );
  }
);

Input.displayName = 'Input';

export default Input;
