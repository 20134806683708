/** @jsxImportSource @emotion/react */
import React from 'react';

import { container } from './QuoteLogo.style';
import { QuoteLogoProps, QuoteLogoSize } from './QuoteLogo.types';

const QuoteLogo: React.FC<QuoteLogoProps> = ({
  customCSS,
  size = QuoteLogoSize.DEFAULT,
  logoUrl,
  logoLabel = 'Quote logo'
}) => {
  return (
    <div css={[container(size), customCSS]}>
      <img src={logoUrl} alt={logoLabel} data-testid="quote-logo" />
    </div>
  );
};

export default QuoteLogo;
