import React from 'react';

import { Controller, RegisterOptions } from 'react-hook-form';

import Select from 'src/shared/components/Select/Select';
import { QuestionWrapper } from 'src/questionsForm/components/QuestionWrapper/QuestionWrapper';
import { FormQuestionProps } from 'src/interfaces/IQuestion';
import { useFormContext } from 'react-hook-form';

const SelectQuestion: React.FC<FormQuestionProps> = props => {
  const { setValue, clearErrors } = useFormContext();
  return (
    <Controller
      name={props.key_name}
      rules={props.validation?.static as RegisterOptions}
      render={({ field: { onChange, value, onBlur, ref } }) => {
        const onSelectChange = (v: string) => {
          //TODO: Temporary fix before fix all our types and remove unknown values
          const valueToSubmit = () => {
            if (v === null) {
              return 0;
            }
            return v;
          };

          if (props.key_name.includes('driver_license_state')) {
            setValue(props.key_name.replace('_state', '_number'), '');
            clearErrors(props.key_name.replace('_state', '_number'));
          }

          onChange(valueToSubmit());
          onBlur();
        };

        /** TODO: Consider types refactoring. Add inputProps as a separate prop */
        return (
          <QuestionWrapper
            {...props}
            options={props.answer_options}
            inputComponent={Select}
            onValidEntry={onSelectChange}
            value={value}
            componentRef={ref}
            onBlur={onBlur}
          />
        );
      }}
    />
  );
};

export default SelectQuestion;
