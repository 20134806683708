/** @jsxImportSource @emotion/react */
import React, { Fragment } from 'react';
import {
  iconCSS,
  imageWrapperCSS,
  stepDescriptionCSS,
  stepHeadingCSS,
  stepIndexCSS,
  stepInfoCSS,
  stepItemCSS,
  stepsWrapperCSS,
  stepWrapperCSS,
  stepSubHeadingCSS,
  headingCSS,
  mobileImgCSS
} from './HowItWorksSteps.style';
import useResponsive from 'src/hooks/useResponsive';
import { HowItWorksStepsProps, Step } from './HowItWorksSteps.types';

const HowItWorksSteps: React.FC<HowItWorksStepsProps> = ({ steps, heading, isCallMeNowVariation }) => {
  const { isMobile } = useResponsive();

  return (
    <div>
      {heading && <h2 css={headingCSS(isCallMeNowVariation)}>{heading}</h2>}
      <div css={stepsWrapperCSS(isCallMeNowVariation)}>
        {steps.map((item: Step, i) => (
          <Fragment key={i}>
            {isMobile && isCallMeNowVariation && (
              <div css={mobileImgCSS}>
                <img css={iconCSS} src={item.img} alt={item.imgAlt} loading="lazy" />
              </div>
            )}

            <div css={stepItemCSS(isCallMeNowVariation)}>
              {isMobile && (
                <div css={stepWrapperCSS(i === steps.length - 1, isCallMeNowVariation)}>
                  <div css={stepIndexCSS}>{i + 1}</div>
                </div>
              )}
              {!isMobile && (
                <div css={imageWrapperCSS}>
                  <img css={iconCSS} src={item.img} alt={item.imgAlt} loading="lazy" />
                </div>
              )}
              <div css={stepInfoCSS}>
                <div css={stepHeadingCSS(isCallMeNowVariation)}>
                  {!isMobile && <span css={stepIndexCSS}>{i + 1}</span>}
                  <div>
                    {item.heading} {item.subHeading && <div css={stepSubHeadingCSS}>{item.subHeading}</div>}
                  </div>
                </div>
                <p css={stepDescriptionCSS}>{item.description}</p>
              </div>
            </div>
          </Fragment>
        ))}
      </div>
    </div>
  );
};
export default HowItWorksSteps;
