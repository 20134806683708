/** @jsxImportSource @emotion/react */
import React from 'react';

import { TalkToAdvisorSectionProps } from './TalkToAdvisorSection.types';
import { cardCSS, cardsWrapperCSS, descriptionCSS, headingCSS } from './TalkToAdvisorSection.style';
import { TALK_TO_ADVISOR_COPIES, TALK_TO_ADVISOR_FLORIDA_COPIES } from './TalkToAdvisorSection.constants';
import TalkToAdvisorCard from './TalkToAdvisorCard/TalkToAdvisorCard';
import noop from 'src/utils/noop';

const TalkToAdvisorSection: React.FC<TalkToAdvisorSectionProps> = ({
  customCSS,
  isFloridaOriented = false,
  onTalkToAdvisorClick = noop
}) => {
  const blocksToRender = isFloridaOriented ? TALK_TO_ADVISOR_FLORIDA_COPIES : TALK_TO_ADVISOR_COPIES;
  return (
    <div css={customCSS}>
      <h2 css={headingCSS}>
        {isFloridaOriented ? 'The Florida Specialist Difference' : 'Schedule a 10 Minute Consultation'}
      </h2>
      <div css={descriptionCSS}>
        {isFloridaOriented
          ? 'We understand the importance of covering your home with the right insurance in Florida. ' +
            'Our specialist team is trained to help you through it.'
          : '  Not seeing a quote that works for you? Let us know what matters most and we’ll do the shopping. ' +
            'Our promise is to find you the right policy for your needs. No forms, no pressure, no unwanted calls.'}
      </div>

      <div css={cardsWrapperCSS}>
        {blocksToRender.map((item, index) => (
          <TalkToAdvisorCard
            customCSS={cardCSS}
            key={index}
            img={item.img}
            imgAlt={item.imgAlt}
            heading={item.heading}
            description={item.description}
            onTalkToAdvisorClick={() => onTalkToAdvisorClick(index + 1, item.heading)}
          />
        ))}
      </div>
    </div>
  );
};

export default TalkToAdvisorSection;
