/** @jsxImportSource @emotion/react */
import React from 'react';

import { useLocation, useParams } from 'react-router-dom';

import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';
import useConfig from 'src/api/config/useConfig';
import { DefaultLocation } from 'src/interfaces/IPage';

import Header from 'src/kit/Header/Header';
import { SerializedStyles } from '@emotion/react';
import { Flows } from 'src/interfaces/IPage';
import searchParams from 'src/constants/searchParams';

interface Props {
  customCSS?: SerializedStyles;
  page: string;
  phone?: string;
  isLiveHeaderVariation?: boolean;
  userName?: string;
}

const PageHeader: React.FunctionComponent<Props> = ({ page, customCSS, phone, isLiveHeaderVariation, userName }) => {
  const { flow, gid } = useParams() as DefaultLocation;
  const { data: config } = useConfig(gid);
  const { search } = useLocation();

  const agentPhone = phone || config?.partner?.agent_phone;
  const partnerLogo = config?.partner?.logo;
  const partnerName = config?.partner?.name;

  const shouldHideMaticLogo =
    !!config?.partner?.key && ['new_american_funding', 'new_american_insurance'].includes(config?.partner?.key);

  const onPhoneClick = () =>
    analytics.track(SEGMENT.PHONE_CONVERSATION_INITIATED, gid, flow, {
      location: SEGMENT.LOCATIONS.HEADER,
      page
    });

  const urlParams = new URLSearchParams(search);
  const isDemo = flow === Flows.Lookup ? urlParams.get(searchParams.testMode) : config?.test_mode;

  return (
    <Header
      phoneNumber={agentPhone}
      partnerLogo={partnerLogo}
      partnerName={partnerName}
      userName={userName}
      onPhoneClick={onPhoneClick}
      showContacts
      fullscreen={page === 'questions'}
      isDemo={!!isDemo}
      customCSS={customCSS}
      shouldHideMaticLogo={shouldHideMaticLogo}
      isLiveHeaderVariation={isLiveHeaderVariation}
    ></Header>
  );
};

export default PageHeader;
