const isMaticUrl = (url: string): boolean => {
  if (!url) {
    return false;
  }

  const hostname = extractHostname(url.toLowerCase());

  const hostParts = hostname.split('.');

  return (
    hostParts.length > 1 &&
    (hostParts[hostParts.length - 1] === 'com' || hostParts[hostParts.length - 1] === 'link') &&
    hostParts[hostParts.length - 2] === 'matic'
  );
};

const extractHostname = (url: string): string => {
  let hostname;
  if (url.indexOf('//') > -1) {
    hostname = url.split('/')[2];
  } else {
    hostname = url.split('/')[0];
  }

  hostname = hostname.split(':')[0];
  hostname = hostname.split('?')[0];

  return hostname;
};

export default isMaticUrl;
