/** @jsxImportSource @emotion/react */
import React from 'react';

import GoogleReviewCard from 'src/components/Landing/GoogleReviewCard/GoogleReviewCard';
import Carousel from 'src/kit/Carousel/Carousel';
import { reviewCard } from './GoogleReviewCarousel.style';
import { GoogleReview, GoogleReviewCarouselProps } from './GoogleReviewCarousel.types';

const GoogleReviewCarousel: React.FC<GoogleReviewCarouselProps> = ({ reviews, customCSS, withControls = false }) => {
  const options = {
    dots: false,
    autoplay: false,
    arrows: false,
    centerMode: true,
    infinite: true,
    centerPadding: '20px',
    slidesToShow: 1
  };
  return (
    <Carousel css={customCSS} withAutoplayControl={withControls} {...options}>
      {reviews.map((review: GoogleReview) => (
        <GoogleReviewCard customCSS={reviewCard} key={review.author} text={review.text} author={review.author} />
      ))}
    </Carousel>
  );
};

export default GoogleReviewCarousel;
