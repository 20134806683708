import { css, SerializedStyles } from '@emotion/react';
import { actionColors, borderRadius, boxShadow, colors, mq } from 'src/theme/variables';
import { text, text14, text20 } from 'src/theme/typography';

export const container = css`
  display: flex;
  align-items: center;
  flex-direction: column;
  ${mq[0]} {
    flex-direction: row;
    justify-content: center;
  }
`;

export const optionTitle = css`
  align-self: flex-start;
  ${mq[0]} {
    margin-right: 16px;
    align-self: auto;
  }
  ${mq[1]} {
    margin-right: 24px;
  }
`;

export const options = css`
  display: flex;
`;

export const option = (active: boolean, disabled: boolean): SerializedStyles => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 8px;
  padding: 20px;
  width: 33%;
  max-width: 220px;
  position: relative;
  border: 1px solid ${active ? actionColors.primary : colors.silver};
  border-radius: ${borderRadius}px;
  background-color: ${colors.white};
  box-shadow: ${boxShadow.container};
  cursor: ${disabled ? 'not-allowed' : 'pointer'};
  &:after {
    ${active &&
    css`
      content: '';
      position: absolute;
      top: -1px;
      right: -1px;
      bottom: -1px;
      left: -1px;
      border: 2px solid ${actionColors.primary};
      border-radius: ${borderRadius}px;
    `}
  }
  &:first-of-type {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  &:focus,
  &:hover {
    ${!disabled &&
    css`
      border-color: ${actionColors.primary};
      box-shadow: ${boxShadow.containerHovered};
    `}
  }
  ${mq[1]} {
    margin: 0 10px;
  }
`;

export const icon = css`
  width: 35px;
  margin-bottom: 12px;
`;

export const title = css`
  ${text};
  font-weight: bold;
  margin-bottom: 4px;
  color: ${colors.black};
  ${mq[1]} {
    ${text20};
  }
`;

export const price = css`
  ${text14};
  color: ${colors.warmGray100};
  margin-bottom: 12px;
  ${mq[1]} {
    ${text};
  }
`;

export const description = css`
  font-size: 12px;
  line-height: 16px;
  color: ${colors.warmGray};
  ${mq[1]} {
    ${text14};
  }
`;

export const select = css`
  width: 100%;
  margin-top: 8px;
  .select__control {
    height: 70px;
  }
`;
