/** @jsxImportSource @emotion/react */
import React from 'react';

import { QuotesCarriersProps } from './QuotesCarriers.types';
import { heading } from './QuotesCarriers.style';
import CarriersCarousel from 'src/components/CarriersCarousel/CarriersCarousel';
import useResponsive from 'src/hooks/useResponsive';
import CarriersGrid from 'src/components/CarriersGrid/CarriersGrid';

const QuotesCarriers: React.FC<QuotesCarriersProps> = ({ customCSS }) => {
  const { isMobile } = useResponsive();
  return (
    <div css={customCSS}>
      <div css={heading}>Our top rated carrier network</div>
      {isMobile ? (
        <CarriersGrid />
      ) : (
        <CarriersCarousel
          dots={false}
          autoplay
          rows={{ sm: 1, md: 1 }}
          slidesPerRow={{ sm: 4, md: 5 }}
          maxCarriers={{ sm: 16, md: 15 }}
        />
      )}
    </div>
  );
};

export default QuotesCarriers;
