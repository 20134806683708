import api from 'src/api/api';
import { API_URLS } from 'src/constants/api';
import { Flows } from 'src/interfaces/IPage';
import { getApiUrl } from 'src/utils/api.helpers';

const quotesService = {
  selectQuote(flow: Flows, gid: string, quotes_gid: string[]): Promise<unknown> {
    return api.post(getApiUrl(API_URLS.SELECT_QUOTE, flow, gid), {
      body: { quotes_gid }
    });
  }
};

export default quotesService;
