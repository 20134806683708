import { css } from '@emotion/react';

import { colors as c, borderRadius, boxShadow } from 'src/theme/variables';

export const card = css`
  background-color: ${c.white};
  border: 1px solid ${c.silver};
  border-radius: ${borderRadius}px;
  box-shadow: ${boxShadow.container};
`;
