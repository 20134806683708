import React, { Fragment, useEffect } from 'react';

import { NavigationItemDetailsComponentProps } from 'src/shared/components/Navigation/NavigationItem/NavigationItem.types';
import NavigationItemDetails from 'src/shared/components/Navigation/NavigationItemDetails/NavigationItemDetails';
import { scrollToNode } from 'src/utils/scroll';
import { useWatch } from 'react-hook-form';
import { UsageKeys, VehicleEntity } from 'src/constants/schemaKeys';
import VehicleIcon from 'src/assets/svg/vehicle.svg';

const VehicleDetails: React.FC<NavigationItemDetailsComponentProps> = ({ keyName, onDataReady }) => {
  const vehicles = useWatch({ name: keyName });

  useEffect(() => {
    if (vehicles?.length) {
      onDataReady();
    }
  }, [vehicles, onDataReady]);

  if (!vehicles?.length) {
    return null;
  }

  return (
    <Fragment>
      {vehicles.map((vehicle: VehicleEntity, index: number) => (
        <NavigationItemDetails
          key={index}
          testId="vehicle-scroll-button"
          iconSrc={VehicleIcon}
          header={`${vehicle.vehicle.year} ${vehicle.vehicle.make} ${vehicle.vehicle.model}`}
          details={
            vehicle['vehicle_annual_mileage']
              ? `${vehicle['vehicle_annual_mileage']} mi/year for ${UsageKeys[vehicle.vehicle_usage as UsageKeys]}`
              : null
          }
          onDetailClick={() => scrollToNode(`vehicles.${index}`)}
        />
      ))}
    </Fragment>
  );
};

export default VehicleDetails;
