import React, { Fragment } from 'react';
import { Step } from 'src/components/HowItWorksSteps/HowItWorksSteps.types';
import compareIcon from 'src/assets/svg/how-savings-works-compare.svg';
import savingsIcon from 'src/assets/svg/how-savings-works-safe.svg';
import relaxIcon from 'src/assets/svg/how-it-works-cup.svg';
import SERVICERS from 'src/constants/servicers';
import { AccordionItem } from 'src/shared/components/Accordion/Accordion.types';

export const getSavingsBenefitsSteps = (partner: string | React.ReactElement): Step[] => [
  {
    img: compareIcon,
    imgAlt: 'compare-policies-icon',
    heading: 'Matic Compares Policies ',
    description: (
      <Fragment>
        We work closely with {partner} to get you the most accurate quote (based on your current coverage).
      </Fragment>
    )
  },

  {
    img: savingsIcon,
    imgAlt: 'compare-icon',
    heading: 'You Review your Savings',
    description:
      'You can set a time to chat with a Matic Advisor. Not bound to one carrier, ' +
      'they can help you get the best price from our network of over 40 A-rated carriers.'
  },
  {
    img: relaxIcon,
    imgAlt: 'relax-icon',
    heading: 'We Take Care of the Rest',
    description: (
      <Fragment>
        We&rsquo;ll work with {partner} to make sure your policy is all set. That&rsquo;s less paperwork for you.
      </Fragment>
    )
  }
];

export const getCarriersListHeader = (
  partnerKey: string,
  partnerName: string | React.ReactElement
): string | React.ReactElement => {
  return [SERVICERS.MR_COOPER, SERVICERS.MR_COOPER_NRZ].includes(partnerKey) ? (
    <Fragment>
      Mr. Cooper<sup>®</sup> and Matic Team Up to Find You Better Rates on Home Insurance
    </Fragment>
  ) : (
    <Fragment>{partnerName} and Matic Team Up to Find You Better Rates on Home Insurance</Fragment>
  );
};

export const getCarriersListSubheader = (
  isPHHNewRez: boolean,
  partnerName: string | React.ReactElement
): string | React.ReactElement => {
  return isPHHNewRez ? (
    'Newrez works with Matic to help customers like you shop for home insurance. Our free comparison ' +
      ' tool quickly runs your current policy against 40+ A-rated carriers. Seconds later, you have ' +
      'your top quotes, with a clear breakdown of coverages. It’s a beautiful thing.'
  ) : (
    <Fragment>
      {partnerName} works with Matic to make it easy to shop and compare home insurance. In just one click, Matic shops
      your current policy against their network of A-rated insurance carriers to find the best rates available. No
      questions to answer. No forms to complete.
    </Fragment>
  );
};

export const getAccordionItems = (servicerName: string | React.ReactElement): AccordionItem[] => [
  {
    key: '1',
    title: 'Where is this offer coming from?',
    description: (
      <span>
        Matic has reviewed your information and is presenting you with this offer with the help of 40+ A-Rated insurance
        carriers.
      </span>
    )
  },
  {
    key: '2',
    title: 'How does Matic get compensated?',
    description: (
      <span>
        We get paid a commission directly from the insurance carriers when you switch policies. You’ll only switch if
        you’re getting value so we do our best.
      </span>
    )
  },
  {
    key: '3',
    title: 'Who is Matic?',
    description: (
      <span>
        Matic is a tech company that specializes in home insurance. We were selected by {servicerName} to help borrowers
        like yourself lower your monthly expenses.
      </span>
    )
  },
  {
    key: '4',
    title: 'What does Matic charge?',
    description: (
      <span>
        Nothing. Matic gets paid a commission directly from the insurance carrier when you switch policies. You’ll only
        switch if you’re getting value so we do our best.
      </span>
    )
  },
  {
    key: '5',
    title: 'Can I bundle a car?',
    description: <span>Yes, you can bundle your auto and home insurance and save up to 20%.</span>
  }
];
