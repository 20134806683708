/* istanbul ignore file */
import 'react-app-polyfill/stable';
import 'array-flat-polyfill';
import smoothScroll from 'smoothscroll-polyfill';

import App from './App';
import { setAppElementForDialog } from './kit/Dialog/Dialog';
import { createRoot } from 'react-dom/client';

smoothScroll.polyfill();
setAppElementForDialog('#root');

const root = createRoot(document.getElementById('root')!);

root.render(<App />);
