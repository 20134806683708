import { QueryObserverResult, useQuery } from 'react-query';
import queryString from 'query-string';
import api from 'src/api/api';
import { IAgencyLicense } from 'src/interfaces/IAgencyLicense';

const useAgencyLicenses = (state?: string | null): QueryObserverResult<IAgencyLicense[]> => {
  return useQuery(['quotes_view_agency_licenses', state], () =>
    api.get(`/api/quote_pdf/agency_licenses?${queryString.stringify({ state: state })}`)
  );
};

export default useAgencyLicenses;
