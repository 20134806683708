/** @jsxImportSource @emotion/react */
import { FC } from 'react';
import { ProfileProps } from './Profile.types';
import { Card } from 'src/shared/components/Card/Card';
import {
  bundleMsgWrapperCSS,
  containerCSS,
  customerAliasCSS,
  policyAddressCSS,
  policyExpirationDateCSS,
  profileInfoCSS,
  propertyCSS
} from './Profile.style';
import { getPossessiveForm } from 'src/components/SavingsHero/SavingHero.utils';
import { getReadableAddress } from 'src/utils/homeDetails';
import { FS_SENSITIVE_DATA_CLASS } from 'src/constants/fullStory';

const Profile: FC<ProfileProps> = ({ phone, currentPolicy, firstName, lastName, address, children }) => {
  return (
    <Card customCSS={containerCSS}>
      <div css={bundleMsgWrapperCSS}>
        <div css={profileInfoCSS(!!phone)}>
          <p css={customerAliasCSS}>{`${firstName} ${getPossessiveForm(lastName)} Profile`}</p>
          <p css={policyAddressCSS} className={FS_SENSITIVE_DATA_CLASS.MASK}>
            <span role="img" aria-label="House">
              🏡{' '}
            </span>
            {(address && getReadableAddress(address)) ?? ''}
          </p>
          {currentPolicy.expiration_date && (
            <b css={policyExpirationDateCSS} className={FS_SENSITIVE_DATA_CLASS.MASK}>
              {currentPolicy.carrier_name} (renews on {currentPolicy.expiration_date})
            </b>
          )}
          {currentPolicy.premium && (
            <p css={propertyCSS} className={FS_SENSITIVE_DATA_CLASS.MASK}>
              Premium <span /> ${currentPolicy.premium}
            </p>
          )}
          {currentPolicy.deductible && (
            <p css={propertyCSS} className={FS_SENSITIVE_DATA_CLASS.MASK}>
              Deductible <span /> ${currentPolicy.deductible}
            </p>
          )}
        </div>
        <div>{children}</div>
      </div>
    </Card>
  );
};

export default Profile;
