/** @jsxImportSource @emotion/react */
import React from 'react';

import { container, image } from './Logo.style';
import SirvImage from 'src/kit/SirvImage/SirvImage';
import { URLS } from 'src/constants/urls';
import { LogoProps } from './types';

const Logo: React.FC<LogoProps> = ({
  partnerLogo,
  partnerName = 'Partner',
  width = 200,
  height,
  customCSS,
  shouldRenderExtended = false
}) => {
  const props = {
    url: URLS.MATIC_LOGO,
    width,
    height,
    alt: 'Matic logo'
  };

  if (partnerLogo) {
    props.url = partnerLogo;
    props.width = shouldRenderExtended ? 230 : width;
    props.height = shouldRenderExtended ? 70 : height;
    props.alt = partnerName;
  }

  return (
    <div css={[container, customCSS]}>
      <SirvImage customCSS={image} {...props} />
    </div>
  );
};

export default Logo;
