import React from 'react';

import { RouterProvider } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import * as Sentry from '@sentry/react';

import ErrorFallback from './components/ErrorFallback/ErrorFallback';
import { QUERY_CLIENT_CONFIG } from './constants/queryClientConfig';
import { CustomRouterProvider } from './CustomRouterProvider';
import { Global } from '@emotion/react';
import { globalCSS } from './App.style';

const queryClient = new QueryClient(QUERY_CLIENT_CONFIG);

if (import.meta.env.NODE_ENV === 'development') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  // const { worker } = require('./mocks/browser');
  // worker.start();
}

const App: React.FC = () => (
  <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
    <QueryClientProvider client={queryClient}>
      <Global styles={globalCSS} />
      <RouterProvider router={CustomRouterProvider} />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </Sentry.ErrorBoundary>
);

export default App;
