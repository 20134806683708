import { css } from '@emotion/react';
import { borderRadius, colors as c } from 'src/theme/variables';
import { text14 } from 'src/theme/typography';

export const actionsCSS = css`
  display: flex;
  align-items: center;
  svg {
    cursor: pointer;
  }
`;

export const deleteCSS = css`
  display: flex;
  align-items: center;
  cursor: pointer;
  path {
    stroke: ${c.black};
  }
`;

export const fileContainerCSS = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px; 0;
`;

export const informationCSS = css`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 12px;
`;

export const descriptionCSS = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex: 1;
`;

export const failedCSS = css`
  display: flex;
  align-items: center;
  color: ${c.red};
`;

export const loaderCSS = css`
  position: relative;
  background: ${c.mercury};
  width: 100%;
  height: 3px;
  border-radius: ${borderRadius * 2.5}px;

  @keyframes load {
    from {
      width: 0;
    }

    to {
      width: 100%;
    }
  }

  &:before {
    content: '';
    position: absolute;
    height: 3px;
    width: 0;
    background-color: ${c.azure};
    border-radius: ${borderRadius * 2.5}px;
    animation-duration: 3s;
    animation-name: load;
    animation-iteration-count: infinite;
  }
`;

export const nameCSS = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const sizeCSS = css`
  ${text14};
  color: ${c.dove};
  flex-shrink: 0;
`;

export const statusIconCSS = css`
  padding: 0 8px;
  flex-shrink: 0;
`;

export const refreshIconCSS = css`
  margin-right: 12px;
`;
