import { UploadFile } from './FileUploadListItem/FileUploadListItem.types';

export enum FileStatusUpload {
  SUCCESS = 'success',
  FAILED = 'failed',
  LOADING = 'loading'
}

export interface FileUploadListProps {
  files: UploadFile[];
  onRetryUpload(file: UploadFile): void;
  onRemoveFile(file: UploadFile): void;
}
