/** @jsxImportSource @emotion/react */
import React from 'react';
import {
  hiCSS,
  copyCSS,
  wrapperCSS,
  mainImgCSS,
  descriptionCSS,
  textWrapperCSS,
  headerWrapperCSS,
  policyDetailsWrapperCSS
} from './WelcomePage.style';
import MainImg from 'src/assets/svg/happy-couple.svg';
import MaticLogo from 'src/assets/svg/matic-logo.svg';
import { WelcomePageProps } from './WelcomePage.types';

const WelcomePage: React.FC<WelcomePageProps> = ({ agencyLicense, person, address, lender, bestQuote }) => {
  return (
    <div css={wrapperCSS}>
      <div css={headerWrapperCSS}>
        <img src={MaticLogo} alt="Matic logo" />
        <div css={policyDetailsWrapperCSS}>
          {lender && <div>Lender: {lender.name}</div>}
          {address && <div>Address: {address.full}</div>}
          <div>
            Policy period: {new Date(bestQuote.effective_date).toLocaleDateString('en-US')} to{' '}
            {new Date(bestQuote.expiration_date).toLocaleDateString('en-US')}
          </div>
        </div>
      </div>
      <div css={textWrapperCSS}>
        <div css={hiCSS}>Hi {person.first_name}!</div>
        <div css={descriptionCSS}>Here’s your home insurance quote.</div>
      </div>
      <img src={MainImg} css={mainImgCSS} alt="Happy Couple" />
      <div css={copyCSS}>
        Quote provided by <a href="https://matic.com/terms-of-use/">Matic Insurance Services Inc.</a>{' '}
        {agencyLicense?.license_number || ''}
      </div>
    </div>
  );
};

export default WelcomePage;
