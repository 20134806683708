import { css, SerializedStyles } from '@emotion/react';

import { colors } from 'src/theme/variables';

const dotSize = 8;
const animationSpeed = 0.6;

const fullPageLoaderCSS = css`
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const loaderWrapper = (fullPage: boolean): SerializedStyles => (fullPage ? fullPageLoaderCSS : css``);

export const loader = css`
  display: flex;
  justify-content: center;
`;

export const dot = (isInverted: boolean): SerializedStyles => css`
  animation: fade ${animationSpeed}s infinite alternate;
  background-color: ${isInverted ? colors.white : colors.black};
  border-radius: 50%;
  height: ${dotSize}px;
  margin-right: ${dotSize}px;
  width: ${dotSize}px;

  &:nth-of-type(2) {
    animation-delay: ${animationSpeed / 3}s;
  }

  &:nth-of-type(3) {
    animation-delay: ${animationSpeed / 1.5}s;
    margin-right: 0;
  }

  @keyframes fade {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
`;
