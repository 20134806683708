import { QuoteLogoSize } from './QuoteLogo.types';

const styleLimits = {
  [QuoteLogoSize.SMALL]: {
    container: {
      desktop: {
        minWidth: 100
      }
    },
    image: {
      mobile: {
        maxHeight: 40,
        maxWidth: 120
      },
      desktop: {
        maxWidth: 100,
        maxHeight: 50
      }
    }
  },
  [QuoteLogoSize.DEFAULT]: {
    container: {
      desktop: {
        minWidth: 160
      }
    },
    image: {
      mobile: {
        maxHeight: 50,
        maxWidth: 120
      },
      desktop: {
        maxWidth: 160,
        maxHeight: 70
      }
    }
  },
  [QuoteLogoSize.BIG]: {
    container: {
      desktop: {
        minWidth: 160
      }
    },
    image: {
      mobile: {
        maxHeight: 50,
        maxWidth: 180
      },
      desktop: {
        maxWidth: 200,
        maxHeight: 100
      }
    }
  },
  [QuoteLogoSize.AVERAGE]: {
    container: {
      desktop: {
        minWidth: 120
      }
    },
    image: {
      mobile: {
        maxHeight: 35,
        maxWidth: 80
      },
      desktop: {
        maxHeight: 50,
        maxWidth: 120
      }
    }
  }
};

export default {
  styleLimits
};
