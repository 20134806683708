/** @jsxImportSource @emotion/react */
import React, { Fragment, useEffect } from 'react';

import UploadFilesInput from 'src/shared/components/UploadFilesInput/UploadFilesInput';
import { FileStatusUpload } from 'src/shared/components/FileUploadsList/FileUploadList.types';
import FileUploadList from 'src/shared/components/FileUploadsList/FileUploadList';
import { UploadFilesComponentProps } from './UploadFilesComponent.types';
import { dialogActionsItemCSS } from 'src/shared/components/FileUploadsList/FileUploadDialog/FileUploadDialog.style';
import FileUploadDialog from 'src/shared/components/FileUploadsList/FileUploadDialog/FileUploadDialog';
import Button from 'src/kit/Button/Button';
import noop from 'src/utils/noop';
import { useParams } from 'react-router-dom';
import { DefaultLocation } from 'src/interfaces/IPage';
import { validateRules } from './UploadFilesComponent.utils';
import convert from 'src/shared/utils/convert';
import { UploadFile } from 'src/shared/components/FileUploadsList/FileUploadListItem/FileUploadListItem.types';
import { documentUploadService, useDocumentUpload } from 'src/api/documentUpload';

const UploadFilesComponent: React.FC<UploadFilesComponentProps> = ({
  showUploadPopupWarning = false,
  setShowUploadPopupWarning = noop,
  onSubmit = noop,
  canSubmit = { current: true }
}) => {
  const { gid } = useParams() as DefaultLocation;
  const [files, setFiles] = useDocumentUpload(gid);

  useEffect(() => {
    const isUploading = files.some(f => f.status === FileStatusUpload.LOADING);
    canSubmit.current = !isUploading;
  }, [files, canSubmit]);

  const setFileUploaded = async (newFiles: File[]) => {
    const transFiles: UploadFile[] = await Promise.all(
      newFiles.map(async file => ({
        file_name: file.name,
        byte_size: file.size,
        base64_content: (await convert.toBase64(file)) as string,
        status: FileStatusUpload.LOADING
      }))
    );

    setFiles(prevFiles => [...prevFiles, ...transFiles]);

    transFiles.forEach(async file => {
      const i = files.indexOf(file);

      try {
        const res = await documentUploadService.uploadDocument(gid, {
          file_name: file.file_name,
          base64_content: file.base64_content as string
        });
        file.attachment_id = res.attachment_id;
        file.status = FileStatusUpload.SUCCESS;
      } catch (e) {
        file.status = FileStatusUpload.FAILED;
      }

      setFiles(prevState => {
        prevState[i] = file;
        return [...prevState];
      });
    });
  };

  const onRetry = async (file: UploadFile) => {
    const i = files.findIndex(f => f.base64_content === file.base64_content);
    const fileToRetry = { ...files[i] };

    if (i >= 0) {
      fileToRetry.status = FileStatusUpload.LOADING;

      setFiles(prevState => {
        prevState[i] = fileToRetry;
        return [...prevState];
      });

      try {
        fileToRetry.base64_content &&
          (await documentUploadService.uploadDocument(gid, {
            file_name: fileToRetry.file_name,
            base64_content: fileToRetry.base64_content
          }));
        fileToRetry.status = FileStatusUpload.SUCCESS;
      } catch (e) {
        fileToRetry.status = FileStatusUpload.FAILED;
      }

      setFiles(prevState => {
        prevState[i] = fileToRetry;
        return [...prevState];
      });
    }
  };

  const onRemoveFile = async (file: UploadFile) => {
    if (file.attachment_id) {
      const res = await documentUploadService.deleteUploadedDocument(gid, file.attachment_id);
      res && setFiles(prevState => prevState.filter(f => f.attachment_id !== file.attachment_id));
    } else {
      setFiles(prevState => prevState.filter(f => f.base64_content !== file.base64_content));
    }
  };

  const closeWarningPopup = () => setShowUploadPopupWarning(false);

  const getFileUploadWarning = () => (
    <FileUploadDialog
      isOpen={showUploadPopupWarning}
      title="Continue without uploading?"
      description="Your documents are still uploading. If you continue to quote your documents will be discarded."
      onClose={closeWarningPopup}
    >
      <Fragment>
        <Button css={dialogActionsItemCSS} type="button" variant="secondary" onClick={closeWarningPopup}>
          Cancel
        </Button>
        <Button css={dialogActionsItemCSS} type="button" onClick={onSubmit}>
          Continue to Quotes
        </Button>
      </Fragment>
    </FileUploadDialog>
  );

  return (
    <Fragment>
      <UploadFilesInput setFileUploaded={setFileUploaded} validation={validateRules} currentFilesCount={files.length} />
      <FileUploadList files={files} onRetryUpload={onRetry} onRemoveFile={onRemoveFile} />
      {getFileUploadWarning()}
    </Fragment>
  );
};

export default UploadFilesComponent;
