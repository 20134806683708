/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';

import { useParams } from 'react-router-dom';

import InputMask from 'react-input-mask';
import Button from 'src/kit/Button/Button';
import {
  inputCSS,
  buttonCSS,
  labelCSS,
  errorCSS,
  inputWrapperCSS,
  componentWrapperCSS,
  editPhoneWrapperCSS,
  focusHelper
} from './EditPhoneButton.style';
import { EditPhoneButtonProps } from './EditPhoneButton.types';
import ValidationError from 'src/shared/components/ValidationError/ValidationError';
import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';

import { DefaultLocation } from 'src/interfaces/IPage';
import { FS_SENSITIVE_DATA_CLASS } from 'src/constants/fullStory';
import noop from 'src/utils/noop';
import { isPhoneValid } from 'src/utils/phone';
import { ButtonSize } from 'src/kit/Button/types';

const PHONE_MASK = '(999) 999-9999';

const EditPhoneButton: React.FC<EditPhoneButtonProps> = ({
  phoneValue,
  isLoading,
  customCSS,
  label = 'Get Quotes',
  onSubmit = noop,
  isAFVariation = false
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const { flow, gid } = useParams() as DefaultLocation;
  const [updatedPhone, setUpdatedPhone] = useState('');
  const [hasPhoneError, setHasPhoneError] = useState(false);

  const value = phoneValue && !updatedPhone && !isFocused ? phoneValue : updatedPhone;

  const isValid = () => (updatedPhone ? isPhoneValid(updatedPhone) : !!phoneValue);

  const onPhoneChange = (newValue: string) => {
    setUpdatedPhone(newValue);
    setHasPhoneError(false);
  };

  const onSubmitClick = () => {
    if (updatedPhone) {
      analytics.track(SEGMENT.PHONE_CHANGE_ATTEMPTED, gid, flow, {
        resolution: isValid() ? SEGMENT.CHANGE_ATTEMPTED_RESOLUTION.SUCCESS : SEGMENT.CHANGE_ATTEMPTED_RESOLUTION.ERROR
      });
    }

    if (isValid()) {
      onSubmit(updatedPhone ? updatedPhone : phoneValue);
    } else {
      setHasPhoneError(true);
    }
  };

  return (
    <div css={[componentWrapperCSS, customCSS]}>
      <div css={editPhoneWrapperCSS(hasPhoneError, isAFVariation)}>
        <div css={inputWrapperCSS}>
          <label htmlFor="phone-button-input" css={labelCSS(hasPhoneError)}>
            Phone number
          </label>
          <InputMask
            id="phone-button-input"
            className={FS_SENSITIVE_DATA_CLASS.MASK}
            css={inputCSS(hasPhoneError)}
            autoComplete="off"
            type="tel"
            name="phone"
            onChange={target => onPhoneChange(target.currentTarget.value)}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            value={value || ''}
            mask={updatedPhone ? PHONE_MASK : ''}
          />
          <div css={focusHelper(isFocused, hasPhoneError)} />
        </div>
        <Button
          customCSS={buttonCSS(isAFVariation)}
          isLoading={isLoading}
          onClick={onSubmitClick}
          data-testid="phone-edit-action-button"
          size={isAFVariation ? ButtonSize.Large : ButtonSize.Normal}
        >
          {label}
        </Button>
      </div>
      <ValidationError customCSS={errorCSS} visible={hasPhoneError} heading="Phone number">
        Please, enter a valid phone number
      </ValidationError>
    </div>
  );
};

export default EditPhoneButton;
