import { SerializedStyles } from '@emotion/react';

export enum QuotesPageHeroType {
  Default = 'DEFAULT',
  Savings = 'SAVINGS',
  NoQuotes = 'NO_QUOTES',
  Landlord = 'LANDLORD',
  PolicyHolder = 'POLICY_HOLDER',
  Accord = 'ACCORD'
}

export interface QuotesPageHeroProps {
  customCSS?: SerializedStyles;
  type: QuotesPageHeroType;
  data?: {
    savingValue?: number;
    agentPhone?: string;
    firstName?: string;
    carrierName?: string;
    carrierLogo?: string;
    firstQuotePremium?: number;
  };
  shouldRenderBBBIcon?: boolean;
  onCallToAgentClick?: () => void;
  onActionClick?: () => void;
  setAFToggleOn: (isToggleOn: boolean) => void;
  isBndlTgglPageVariation: boolean;
}
