import { css, SerializedStyles } from '@emotion/react';
import { colors as c, mq } from 'src/theme/variables';
import { text24, text20, text } from 'src/theme/typography';

export const wrapperCSS = css`
  font-weight: 400;
`;

export const sectionWrapperCSS = (isDarkerBg: boolean): SerializedStyles => css`
  background-color: ${isDarkerBg ? c.grayFive : c.white};
  padding-bottom: 32px;
  padding-top: 32px;

  ${mq[0]} {
    padding-bottom: 40px;
    padding-top: 40px;
  }
  ${mq[1]} {
    padding-bottom: 60px;
    padding-top: 60px;
  }
`;

export const stickyPhoneLineCSS = (isDemoMode?: boolean): SerializedStyles => css`
  background: ${c.azure};
  padding: 11px;
  color: ${c.white};
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  ${text20};
  position: sticky;
  top: ${isDemoMode ? 22 : 0}px;
  z-index: 999;

  ${mq[0]} {
    ${text};
  }

  img {
    margin-right: 8px;
    width: 24px;
  }

  a {
    padding-top: 0;
    padding-bottom: 0;
    width: unset;

    ${mq[0]} {
      ${text24};
      margin: 0px;
    }
  }
`;

export const contactButtonCSS = css`
  ${text24};
`;

export const anchorCSS = css`
  color: ${c.white};
  text-decoration: none;
  border-bottom: none;
  margin-left: 10px;
  ${text20};

  &:hover {
    color: ${c.white};
  }

  ${mq[0]} {
    margin-left: 0;
    ${text24};
  }
`;

export const headerTextCopyCSS = css`
  margin-right: 8px;
`;
