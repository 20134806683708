/** @jsxImportSource @emotion/react */
import React from 'react';

import { QuoteCoverageGroupProps } from './QuoteCoverageGroup.types';
import { container, coverage, header, headerImage } from './QuoteCoverageGroup.style';
import QuoteCoverage from 'src/components/Quotes/QuoteCoverages/QuoteCoverage/QuoteCoverage';
import coverageShield from 'src/assets/svg/coverage-shield.svg';
import coverageAuto from 'src/assets/svg/coverage-auto.svg';

const QuoteCoverageGroup: React.FC<QuoteCoverageGroupProps> = ({ customCSS, group, shouldRenderDescription }) => {
  const { title, type, coverages } = group;
  return (
    <div css={[container, customCSS]}>
      <div css={header}>
        {type && (
          <img
            css={headerImage}
            src={type === 'home' ? coverageShield : coverageAuto}
            alt={type === 'home' ? 'Shield' : 'Auto'}
          />
        )}
        <span>{title}</span>
      </div>

      {coverages.map(c => (
        <QuoteCoverage
          key={c.title}
          customCSS={coverage}
          title={c.title}
          type={c.type}
          value={c.value}
          description={c.description}
          shouldRenderDescription={shouldRenderDescription}
        />
      ))}
    </div>
  );
};

export default QuoteCoverageGroup;
