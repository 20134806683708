/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import Container from 'src/kit/Container/Container';
import { DefaultLocation, Flows } from 'src/interfaces/IPage';
import SirvImage from 'src/kit/SirvImage/SirvImage';
import useResponsive from 'src/hooks/useResponsive';
import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';
import useQuotes from 'src/api/quotes/useQuotes';
import { PossibleQuotesResponse } from 'src/components/Quotes/Quotes.types';
import { QuotesResponseStatus } from 'src/interfaces/IQuotes';
import useSession from 'src/api/session/useSession';
import { POLLING_INTERVAL } from './Interstitial.consts';
import { useCustomNavigate } from 'src/hooks/useCustomNavigate';
import { container, description, heading, image, subheading } from './Interstitial.style';

import InterstitialAF from 'src/components/InterstitialAF/InterstitialAF';
import { InterstitialProps } from './Intersitital.types';
import { useQueryClient } from 'react-query';
import { QUERY_CACHE_KEYS } from 'src/constants/queryCacheKeys';

const Interstitial: React.FC<InterstitialProps> = ({ shouldRenderDTQInterstitial }) => {
  const { gid, flow } = useParams() as DefaultLocation;
  const { isMobile } = useResponsive();
  const navigate = useCustomNavigate();

  const { isLoading: isSessionLoading } = useSession(flow, gid);
  const [refetchInterval, setRefetchInterval] = useState<false | number>(POLLING_INTERVAL);

  const { data: quotes, isFetchedAfterMount } = useQuotes<PossibleQuotesResponse>(
    flow,
    gid,
    refetchInterval,
    setRefetchInterval,
    true
  );
  const isLoading = !isFetchedAfterMount || isSessionLoading || quotes?.status !== QuotesResponseStatus.Finished;

  const queryClient = useQueryClient();

  useEffect(() => {
    const navigateCallback = async () => {
      await queryClient.invalidateQueries({ queryKey: [QUERY_CACHE_KEYS.SESSION, flow, gid] });
      navigate({ pathname: `/${flow}/${gid}/quotes` }, { replace: true });
    };
    if (!isLoading) {
      navigateCallback();
    }
  }, [flow, gid, isLoading, navigate, queryClient]);

  useEffect(() => {
    analytics.page(SEGMENT.PAGES.INTERSTITIAL, {
      flow_type: flow,
      session_gid: gid
    });
  }, [gid, flow]);

  const getImageSize = (): number => {
    if (isMobile) {
      return 250;
    }
    return 407;
  };

  if (shouldRenderDTQInterstitial) {
    return <InterstitialAF />;
  }

  const homePolicyFlows = [Flows.Home, Flows.AllFunnel, Flows.Accord, Flows.Florida, Flows.Lookup].includes(flow);
  const currentFlow = homePolicyFlows ? 'homeowners' : 'auto';
  const oppositeFlow = homePolicyFlows ? 'auto' : 'home';

  return (
    <Container customCSS={container}>
      <div css={heading}>Sit Tight, Matic Is Comparing Quotes From Multiple Carriers</div>

      <p css={subheading}>This process can take up to 60 seconds.</p>

      <SirvImage
        customCSS={image}
        alt="Scale"
        url="https://maticinsurance-cdn.sirv.com/animations/scale.gif"
        isLazyLoad={false}
        width={getImageSize()}
      />

      <div css={description}>
        <p>
          <b>Did You Know...</b>
        </p>
        <p>
          {flow === Flows.AutoBundle
            ? 'Matic can help you cancel your current insurance policy,' +
              ' helping you to simplify your whole world of insurance.'
            : `Matic does more than just ${currentFlow} insurance.` +
              ` We can bundle ${oppositeFlow} insurance for even more savings.`}
        </p>
      </div>
    </Container>
  );
};

export default Interstitial;
