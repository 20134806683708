import { css } from '@emotion/react';

import { buttonStyle } from 'src/kit/Button/Button';
import { colors, mq } from 'src/theme/variables';
import { text24 } from 'src/theme/typography';

export const agencyContact = css`
  max-width: 450px;
  text-align: center;
  margin: 0 auto;
`;

export const imgWrapper = css`
  width: calc(100% + 12px);
  margin-left: -12px;
  position: relative;
  ${mq[1]} {
    width: calc(100% + 17px);
    margin-left: -17px;
  }
`;

export const img = css`
  max-width: 100%;
`;

export const curl = css`
  position: absolute;
  left: 14%;
  top: 34%;
  max-width: 30%;
`;

export const envelope = css`
  position: absolute;
  top: 37%;
  left: 66%;
  max-width: 25%;
`;

export const callUs = css`
  ${text24};
  font-weight: 700;
`;

export const phoneNumber = css`
  color: ${colors.black};
  text-decoration: none;
  border-bottom: none;
`;

export const scheduleCallButtonCSS = css`
  ${buttonStyle(true)}
  display: block;
  text-decoration: none;
  margin-top: 16px;
  width: 100%;
`;

export const letAgents = css`
  margin-top: 12px;
`;
