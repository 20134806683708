import { IAddress } from 'src/interfaces/quotesView/IAddress';
import { IPerson } from 'src/interfaces/quotesView/IPerson';
import { ILender } from 'src/interfaces/quotesView/ILender';
import useAssets from 'src/api/quotesView/useAssets';
import useConfig from 'src/api/quotesView/useConfig';
import useLoans from 'src/api/quotesView/useLoans';
import usePerson from 'src/api/quotesView/usePerson';
import useQuotes from 'src/api/quotesView/useQuotes';
import useQuote from 'src/api/quotesView/useQuote';
import useQuotesViewParams, { QuotesViewFlowType } from './useQuotesViewParams';
import { IQuotesViewConfig } from 'src/interfaces/quotesView/IQuotesViewConfig';
import { IQuote } from 'src/interfaces/quotesView/IQuote';
import useAgencyLicenses from 'src/api/quotesView/useAgencyLicenses';
import { IAgencyLicense } from 'src/interfaces/IAgencyLicense';
import useRecommendedQuotes from './useRecommendedQuotes';

type useQuotesViewData = {
  config: IQuotesViewConfig | undefined;
  flow: QuotesViewFlowType | null;
  person: IPerson | undefined;
  address: IAddress | undefined;
  lender: ILender | null | undefined;
  bestQuote: IQuote | undefined;
  alternativeQuotes: IQuote[] | undefined;
  agencyLicense: IAgencyLicense | undefined;
  isDataReady: boolean;
  proposalBuilderExperiment: boolean;
};

const useQuotesViewData = (): useQuotesViewData => {
  const { flow, personGid, assetsGids, quotesRequestGid, engagementGid, proposedQuoteGid } = useQuotesViewParams();

  const { data: config } = useConfig();
  const { data: person } = usePerson(personGid);
  const { data: assets } = useAssets(personGid, assetsGids);
  const { data: loans, isFetching: isFetchingLoans } = useLoans(personGid, assetsGids?.[0]);

  const { data: quotesGroups } = useQuotes(personGid, {
    quotes_request_gid: quotesRequestGid,
    engagement_gid: engagementGid,
    assets_gids: assetsGids,
    // TODO: policy_types might need to be a url param in a future.
    policy_types: flow === QuotesViewFlowType.Home ? ['home', 'condo', 'fire'] : ['auto']
  });

  const { data: proposedQuote, isFetching: isFetchingProposedQuote } = useQuote(personGid, proposedQuoteGid);

  const address = (assets?.homes?.[0] || assets?.vehicles?.[0])?.address;
  const recentLoan = loans?.[0];
  const lender = recentLoan?.lender || recentLoan?.servicer;

  const { data: agencyLicenses, isFetching: isFetchingAgencyLicenses } = useAgencyLicenses(address?.state);
  const agencyLicense = agencyLicenses?.[0];

  const recommendedQuotes = useRecommendedQuotes(quotesGroups);

  const isDataReady =
    config &&
    person &&
    assets &&
    !isFetchingLoans &&
    !isFetchingAgencyLicenses &&
    !isFetchingProposedQuote &&
    (proposedQuote || recommendedQuotes);

  const commonData = {
    config,
    flow,
    person,
    address,
    lender,
    agencyLicense,
    isDataReady: !!isDataReady
  };

  if (proposedQuoteGid) {
    return {
      ...commonData,
      bestQuote: proposedQuote,
      alternativeQuotes: [],
      proposalBuilderExperiment: true
    };
  }

  return {
    ...commonData,
    bestQuote: recommendedQuotes?.bestQuote,
    alternativeQuotes: recommendedQuotes?.alternativeQuotes,
    proposalBuilderExperiment: false
  };
};

export default useQuotesViewData;
