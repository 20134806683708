import { css, SerializedStyles } from '@emotion/react';
import { mq } from 'src/theme/variables';
import { text, text20, text32 } from 'src/theme/typography';
import { calendlySm } from 'src/components/ScheduleCall/ScheduleCall.style';

export const headingContainerCSS = css`
  text-align: center;

  @media ${calendlySm} {
    margin-bottom: 12px;
  }
`;

export const headingCSS = (isLife?: boolean): SerializedStyles => css`
  font-weight: 900;
  margin-bottom: 4px;
  padding: 12px;
  ${text32};

  ${mq[0]} {
    padding: 0;
    font-size: ${isLife ? '48px' : '40px'};
    line-height: 68px;
  }
`;

export const subheadingCSS = css`
  ${text};
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
  }

  ${mq[0]} {
    ${text20};
  }
`;
