import { css } from '@emotion/react';
import { text20 } from 'src/theme/typography';
import { mq } from 'src/theme/variables';
import { calendlyMdMax, calendlySmMax } from 'src/components/ScheduleCall/ScheduleCall.style';

export const headingCSS = css`
  ${text20};
  margin: 0;
  font-weight: 700;
`;

export const calendlyContainerCSS = css`
  ${mq[0]} {
    padding: 0;
  }
`;

export const dialogContentCSS = css`
  text-align: center;
`;

export const submitButtonCSS = css`
  font-weight: 700;
`;

export const calendlyWidgetCSS = css`
  @media only screen and (max-width: ${calendlyMdMax}px) and (min-width: ${calendlySmMax}px) {
    height: 495px;
  }
`;
