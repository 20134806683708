import api from 'src/api/api';
import { API_URLS } from 'src/constants/api';
import { RecaptchaResponse } from 'src/interfaces/recaptcha.types';
import { getApiUrl } from 'src/utils/api.helpers';

const recaptchaService = {
  checkV3Token(gid: string, body: { recaptcha_token: string; action: string }): Promise<RecaptchaResponse> {
    return api.put(getApiUrl(API_URLS.VERIFY_RECAPTCHA, gid), { body });
  }
};

export default recaptchaService;
