/** @jsxImportSource @emotion/react */
import { forwardRef } from 'react';

import { CheckboxProps } from './Checkbox.types';

import {
  checkboxCSS,
  checkIconCSS,
  containerCSS,
  imageCSS,
  labelContainerCSS,
  subtitleCSS,
  titleCSS
} from './Checkbox.style';
import CheckboxIcon from './CheckboxIcon/CheckboxIcon';

//@todo refactor this component to remove "data-testid", as label consists of svg name
const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ customCSS, label, labelDescription, icon, alt, className, hasError = false, ...props }, ref) => {
    const renderLabel = () => {
      if (labelDescription) {
        return (
          <span css={labelContainerCSS}>
            <span css={titleCSS}>{label}</span>
            <span css={subtitleCSS}>{labelDescription}</span>
          </span>
        );
      }
      return <span css={labelContainerCSS}>{label}</span>;
    };

    const altProp = typeof label === 'string' ? label : alt;

    return (
      <label css={customCSS?.label}>
        <input type="checkbox" css={checkboxCSS} {...props} ref={ref} data-testid="checkbox-input" />
        <div css={[containerCSS(props.disabled), customCSS?.container]} className={className}>
          <CheckboxIcon customCSS={checkIconCSS(hasError)} checked={!!props.checked} disabled={props.disabled} />
          {renderLabel()}
          {!!icon && <img css={imageCSS(!!props.checked)} src={icon} width={40} height={40} alt={altProp} />}
        </div>
      </label>
    );
  }
);

Checkbox.displayName = 'Checkbox';
export default Checkbox;
