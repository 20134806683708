/** @jsxImportSource @emotion/react */
import { SerializedStyles } from '@emotion/react';

import React, { ReactNode } from 'react';
import { dashedRowCSS } from './DashedRow.style';

export interface DashedRowProps {
  label: ReactNode;
  content: ReactNode;
  customCSS?: SerializedStyles;
  className?: string;
}

const DashedRow: React.FC<DashedRowProps> = ({ label, content, customCSS, className }) => {
  return (
    <div css={[dashedRowCSS, customCSS]}>
      <span>{label}</span> <span className={className}>{content}</span>
    </div>
  );
};

export default DashedRow;
