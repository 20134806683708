/** @jsxImportSource @emotion/react */

import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Button from 'src/kit/Button/Button';
import { ButtonSize } from 'src/kit/Button/types';
import { buttonsContainerCSS } from './UnlockCardContent.style';
import { getCardText } from 'src/components/SessionTimeout/SessionTimeout.utils';
import SessionTimeoutForm from 'src/components/SessionTimeout/SessionTimeoutForm/SessionTimeoutForm';
import { SESSION_TIMEOUT_FORM_ID } from 'src/components/SessionTimeout/SessionTimeoutForm/SessionTimeoutForm.utils';
import { UnlockCardProps } from './UnlockCardContent.types';

const UnlockCardContent: React.FC<UnlockCardProps> = ({ startOver, setForeverLocked }) => {
  const [showConfirmLayout, setShowConfirmLayout] = useState(false);
  const methods = useForm({
    shouldUnregister: false,
    shouldFocusError: false
  });

  const { heading, description } = getCardText(showConfirmLayout);

  const getButtons = () => {
    return showConfirmLayout ? (
      <>
        <Button variant="secondary" fullWidth size={ButtonSize.Large} onClick={() => setShowConfirmLayout(false)}>
          Cancel
        </Button>
        <Button fullWidth size={ButtonSize.Large} onClick={startOver}>
          Start Over
        </Button>
      </>
    ) : (
      <>
        <Button variant="secondary" fullWidth size={ButtonSize.Large} onClick={() => setShowConfirmLayout(true)}>
          Start Over
        </Button>
        <Button
          fullWidth
          size={ButtonSize.Large}
          type="submit"
          form={SESSION_TIMEOUT_FORM_ID}
          disabled={methods.formState.isSubmitting}
        >
          Resume
        </Button>
      </>
    );
  };

  return (
    <>
      <h2>{heading}</h2>
      <p>{description}</p>

      <FormProvider {...methods}>
        {!showConfirmLayout && <SessionTimeoutForm setForeverLocked={setForeverLocked} />}
      </FormProvider>

      <div css={buttonsContainerCSS}>{getButtons()}</div>
    </>
  );
};

export default UnlockCardContent;
