import { css } from '@emotion/react';
import { borderWidth, colors as c } from 'src/theme/variables';

export const dashedRowCSS = css`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: ${borderWidth / 4}px dashed ${c.dove};
  align-items: flex-end;

  & span {
    position: relative;
    z-index: 0;
  }

  & span:first-of-type {
    font-weight: 400;
    color: ${c.mineshaft};
  }

  & span:before {
    position: absolute;
    z-index: -1;
    top: 2px;
    width: calc(100% + 6px);
    height: 100%;
    content: '';
    background-color: ${c.white};
  }

  & span:last-of-type:before {
    right: 0;
  }
`;
