import { css, SerializedStyles } from '@emotion/react';
import { mq, colors } from 'src/theme/variables';
import { text14, text } from 'src/theme/typography';

export const toggleButtonCSS = (isButtonActive = false): SerializedStyles => css`
  width: 180px;
  color: ${colors.white};
  ${text14};
  background-color: ${colors.cornflowerBlue};
  border-color: ${colors.white};
  padding-top: 8px;
  padding-bottom: 8px;
  text-transform: none;
  height: 40px;

  ${mq[0]} {
    width: 220px;
    ${text};
  }

  ${isButtonActive &&
  css`
    font-weight: 900 !important;
    color: ${colors.white} !important;
    background-color: ${colors.denimDark} !important;
    border: 2px solid ${colors.prussian};
  `}
`;
