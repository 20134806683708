import { css, SerializedStyles } from '@emotion/react';
import { mq } from 'src/theme/variables';

export const cardContent = (autoOnly: boolean): SerializedStyles => css`
  display: flex;
  flex-direction: ${autoOnly ? 'row' : 'column'};
  padding-bottom: ${autoOnly ? 0 : '16px'};
  ${mq[0]} {
    flex-direction: row;
    padding-bottom: 0;
  }
`;

export const typeDetailsContainer = css`
  flex-shrink: 0;
`;

export const tags = (autoOnly: boolean): SerializedStyles => css`
  justify-content: center;
  padding-top: ${autoOnly ? 0 : '16px'};
  ${mq[0]} {
    margin-left: 24px;
    padding: 0;
    justify-content: left;
  }
`;
