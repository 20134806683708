import { css } from '@emotion/react';
import { colors, confettiHeight, mq } from 'src/theme/variables';
import FooterConfetti from 'src/assets/svg/footer-confetti.svg';
import { text12, text14 } from 'src/theme/typography';

export const footer = css`
  background-color: ${colors.grayFive};
  padding: 40px 0;
  position: relative;
  ${mq[0]} {
    padding: 56px 0;
  }
`;

export const confettiCSS = css`
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  top: -${confettiHeight[0] / 2}px;
  height: ${confettiHeight[0]}px;
  background-image: url(${FooterConfetti});
  background-position: center;
  background-repeat: repeat-x;
  background-size: cover;
  ${mq[0]} {
    top: -${confettiHeight[1] / 2}px;
    height: ${confettiHeight[1]}px;
  }
  ${mq[1]} {
    top: -${confettiHeight[2] / 2}px;
    height: ${confettiHeight[2]}px;
    background-size: auto, contain;
  }
`;

export const disclosures = css`
  ${text12};
  line-height: 16px;
  font-weight: 400;
  margin-bottom: 28px;
  ${mq[0]} {
    ${text14};
  }
`;

export const logo = css`
  display: block;
  width: 212px;
  margin-bottom: 24px;
  margin-left: auto;
  margin-right: auto;
  ${mq[0]} {
    margin-left: 0;
    margin-right: 0;
  }
`;

export const subtitle = css`
  font-size: 20px;
  line-height: 1.4;
  font-weight: bold;
  margin-bottom: 24px;
`;

export const copyright = css`
  font-size: 16px;
  line-height: 1.25;
  margin-top: 20px;
  ${mq[0]} {
    margin-top: 0;
  }
`;

export const footerRow = css`
  display: flex;
  flex-direction: column-reverse;
  ${mq[0]} {
    justify-content: space-between;
    flex-direction: row;
  }
`;
