import React from 'react';

export interface QuestionLabelProps {
  wrapperElement?: QuestionLabelWrapper;
  keyName: string;
  tooltipText?: string;
  hasError?: boolean;
  children: React.ReactNode;
}

export enum QuestionLabelWrapper {
  Div = 'DIV',
  Label = 'LABEL'
}
