/** @jsxImportSource @emotion/react */
import React, { Fragment } from 'react';

import { wrapperCSS } from './QuotesViewBuilder.style';
import WelcomePage from './WelcomePage/WelcomePage';
import AlmostDone from './AlmostDone/AlmostDone';
import HomeCoverages from './HomeCoverages/HomeCoverages';
import HomeQuoteSummary from './HomeQuoteSummary/HomeQuoteSummary';

import useQuotesViewData from './hooks/useQuotesViewData';
import { QuotesViewFlowType } from './hooks/useQuotesViewParams';
import FullPageLoader from 'src/shared/components/FullPageLoader/FullPageLoader';

const QuotesViewBuilder: React.FC = () => {
  const {
    isDataReady,
    flow,
    config,
    person,
    address,
    lender,
    agencyLicense,
    bestQuote,
    alternativeQuotes,
    proposalBuilderExperiment
  } = useQuotesViewData();

  if (!isDataReady || !config || !person || !bestQuote || !alternativeQuotes) {
    return <FullPageLoader />;
  }

  // TODO: Replace and add Auto and other flow type
  const qetQuotesViewPages = (): JSX.Element => {
    switch (flow) {
      case QuotesViewFlowType.Home:
      default:
        return (
          <Fragment>
            <HomeQuoteSummary
              config={config}
              bestQuote={bestQuote}
              alternativeQuotes={alternativeQuotes}
              proposalBuilderExperiment={proposalBuilderExperiment}
            />
            <HomeCoverages proposalBuilderExperiment={proposalBuilderExperiment} />
          </Fragment>
        );
    }
  };

  return (
    <div css={wrapperCSS}>
      <WelcomePage
        person={person}
        address={address}
        lender={lender}
        agencyLicense={agencyLicense}
        bestQuote={bestQuote}
      />
      {qetQuotesViewPages()}
      <AlmostDone config={config} proposalBuilderExperiment={proposalBuilderExperiment} />
    </div>
  );
};

export default QuotesViewBuilder;
