/** @jsxImportSource @emotion/react */
import React from 'react';

import { container } from './Divider.style';
import { DividerProps } from './types';

const Divider: React.FC<DividerProps> = ({ height, customCSS }) => {
  return <div css={[container(height), customCSS]} data-testid="divider" />;
};

export default Divider;
