import { css } from '@emotion/react';
import { text, text20 } from 'src/theme/typography';
import { mq } from 'src/theme/variables';

export const lifeComparisonContainerCSS = css`
  margin: 0 auto 40px;
  max-width: 960px;

  ${mq[0]} {
    margin-bottom: 120px;
  }
`;

export const accordionHeadingCSS = css`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  cursor: pointer;
  ${text};

  ${mq[0]} {
    ${text20}
  }
`;

export const arrowCSS = css`
  transition: 0.25s ease-in-out;

  [aria-expanded='true'] & {
    transform: rotate(-180deg);
  }
`;

export const accordionHeading = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 40px;
`;

export const descriptionCSS = css`
  ${text20};
  max-height: 800px;
  transition: max-height 0.25s ease-in-out;
  overflow: hidden;

  &[hidden] {
    display: block;
    max-height: 0;
  }
`;
