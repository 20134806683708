/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';

import PageHeader from 'src/components/PageHeader/PageHeader';
import SEGMENT from 'src/constants/segment';
import Footer from 'src/components/Footer/Footer';
import Page from 'src/components/Page/Page';
import Savings from 'src/components/Savings/Savings';
import { useParams } from 'react-router-dom';
import { DefaultLocation } from 'src/interfaces/IPage';
import { visuallyHidden } from 'src/theme/helpers';
import useSavingsQuotes from 'src/api/savingsQuotes/useSavingsQuotes';
import useTrackFullStorySession from 'src/hooks/useTrackFullStorySession';
import useTrackAdBlocker from 'src/hooks/useTrackAdBlocker';

const PAGE_TITLE = 'Matic Insurance Instant Quotes Result';

const SavingsLayout: React.FC = () => {
  const { gid } = useParams() as DefaultLocation;
  const { data: offer } = useSavingsQuotes(gid);

  const personName = offer?.person.first_name;

  useTrackFullStorySession(gid);
  useTrackAdBlocker(gid);

  useEffect(() => {
    document.title = PAGE_TITLE;
  }, [gid]);

  return (
    <Page>
      {/*TODO sync for refactor SEGMENT.PAGES_KEY.LANDING because it`s not landing page*/}
      <PageHeader page={SEGMENT.PAGES_KEY.LANDING} userName={personName} />

      <main>
        <h1 css={visuallyHidden}>{PAGE_TITLE}</h1>
        <Savings />
      </main>
      <Footer withConfetti page={SEGMENT.PAGES_KEY.SAVINGS} />
    </Page>
  );
};

export default SavingsLayout;
