/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';

import Page from 'src/components/Page/Page';
import { visuallyHidden } from 'src/theme/helpers';
import SessionTimeout from 'src/components/SessionTimeout/SessionTimeout';
import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';
import { useParams } from 'react-router-dom';
import { DefaultLocation } from 'src/interfaces/IPage';

const PAGE_TITLE = 'Matic Insurance';

const SessionTimeoutLayout: React.FC = () => {
  const { gid, flow } = useParams() as DefaultLocation;

  useEffect(() => {
    document.title = PAGE_TITLE;
    analytics.page(SEGMENT.PAGES.SESSION_TIMEOUT, { flow_type: flow, session_gid: gid });
  }, [flow, gid]);

  return (
    <Page>
      <main>
        <h1 css={visuallyHidden}>{PAGE_TITLE}</h1>
        <SessionTimeout />
      </main>
    </Page>
  );
};

export default SessionTimeoutLayout;
