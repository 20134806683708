import * as React from 'react';
import { QuestionComponentKey, QuestionComponentProps } from 'src/constants/schemaKeys';
import UploadFileInputFlorida from 'src/components/UploadFileInputFlorida/UploadFileInputFlorida';
import HomeBundleOffer from 'src/components/HomeBundleOffer/HomeBundleOffer';
import PolicyHolderQuestion from 'src/components/PolicyHolderQuestion/PolicyHolderQuestion';

export const getQuestionComponentByKey = (key?: QuestionComponentKey): React.FC<QuestionComponentProps> | null => {
  switch (key) {
    case QuestionComponentKey.PolicyHolderQuestion:
      return PolicyHolderQuestion;
    case QuestionComponentKey.FloridaHomeInspectionsMessage:
      return UploadFileInputFlorida as React.FC<QuestionComponentProps>;
    case QuestionComponentKey.BundleOffer:
      return HomeBundleOffer;
    default:
      return null;
  }
};
