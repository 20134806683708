import { css } from '@emotion/react';
import { text, text20 } from 'src/theme/typography';
import { colors, mq, typography } from 'src/theme/variables';

export const cellCSS = css`
  border-bottom: none;
  font-family: ${typography.fontFamily};
  ${text};
  font-weight: 700;
  padding: 6px 8px;

  ${mq[0]} {
    ${text20};
    padding: 6px 12px;
  }
`;

export const nameCellCSS = css`
  ${cellCSS};
  width: 214px;
  font-weight: 500;
`;

export const headingCellCSS = css`
  ${cellCSS};
  padding: 14px 5px;

  ${mq[0]} {
    padding: 20px 12px;
  }
`;

export const tableCSS = css`
  box-shadow: none;
  border-spacing: 0;
`;

export const rowCSS = (isOdd: boolean) => css`
  background-color: ${isOdd ? colors.grayFive : 'transparent'};
`;

export const headCSS = css`
  background-color: ${colors.grayFive};
`;

export const hideContentCSS = css`
  width: 1px;
  height: 1px;
  margin: -1px;
  position: relative;
  overflow: hidden;
  display: block;
`;
