import React from 'react';

import { FormQuestionProps } from 'src/interfaces/IQuestion';
import { QuestionWrapper } from 'src/questionsForm/components/QuestionWrapper/QuestionWrapper';
import DateInput from './DateInput';

const DateInputQuestion: React.FC<FormQuestionProps> = props => (
  <QuestionWrapper {...props} inputComponent={DateInput} type="tel" />
);

export default DateInputQuestion;
