/** @jsxImportSource @emotion/react */
import React from 'react';
import { buttonCSS, buttonWrapperCSS, formWrapperCSS, headingCSS, privacyMattersCSS } from './LookupZipForm.style';
import { LookupZipFormProps } from './LookupZipForm.types';
import ZipFormButton from 'src/components/ZipFormButton/ZipFormButton';
import { DefaultLocation } from 'src/interfaces/IPage';
import SEGMENT from 'src/constants/segment';
import analytics from 'src/utils/analytics';
import Button from 'src/kit/Button/Button';
import { useParams } from 'react-router-dom';
import CustomTCPAConsent from 'src/components/CustomTCPAConsent/CustomTCPAConsent';

import { isZipValid } from 'src/components/ZipFormButton/ZipFormButton.utils';
import { LookupFields } from 'src/components/LookupLanding/LookupLanding.types';

const LookupZipForm: React.FC<LookupZipFormProps> = ({ onFormSubmit, isLoading, hasError, onManuallyButtonClick }) => {
  const { flow } = useParams() as DefaultLocation;

  const handleSubmit = (zipValue: string, isModalView: boolean) => {
    if (!isZipValid(zipValue)) {
      analytics.track(SEGMENT.CUSTOMER_IDENTIFIED, undefined, flow, {
        identifier: LookupFields.NONE,
        customer_lookup_by_zip: true
      });
    }

    if (!isZipValid(zipValue)) {
      return;
    }

    if (isModalView) {
      analytics.track(SEGMENT.TERMS_OF_SERVICE_ACCEPTED_FROM_MODAL, undefined, flow);
      onFormSubmit(zipValue);
    } else {
      onFormSubmit(zipValue);
    }
  };

  const renderEditZipButton = (isModalView: boolean) => (
    <ZipFormButton
      label="Check My Rates"
      isLoading={isLoading}
      customCSS={buttonWrapperCSS}
      hasError={hasError}
      onSubmit={zipValue => handleSubmit(zipValue, isModalView)}
    />
  );

  const renderButtonWithRedirect = (
    <Button variant={'text'} css={buttonCSS} onClick={onManuallyButtonClick}>
      Enter my info manually
    </Button>
  );

  return (
    <div css={formWrapperCSS}>
      <h1 css={headingCSS}>Do You Have the Best Rate on Home Insurance?</h1>
      {renderEditZipButton(false)}
      {renderButtonWithRedirect}

      <CustomTCPAConsent css={privacyMattersCSS} location={SEGMENT.LOCATIONS.AF_PRIVACY_MATTERS} />
    </div>
  );
};

export default LookupZipForm;
