/** @jsxImportSource @emotion/react */
import React from 'react';

import { OnlineBingLabelProps } from './OnlineBingLabel.types';
import { container } from './OnlineBingLabel.style';

const OnlineBingLabel: React.FC<OnlineBingLabelProps> = ({ customCSS, children }) => {
  return <div css={[container, customCSS]}>{children}</div>;
};

export default OnlineBingLabel;
