import { css } from '@emotion/react';
import { mq, onlyMobile } from 'src/theme/variables';

export const labelWrapperCSS = (isLifeFlowVariant?: boolean) => css`
  ${isLifeFlowVariant &&
  css`
    display: flex;
    align-items: center;
    margin-right: 8px;
  `};
`;

export const textQuestion = (isLifeFlowVariant?: boolean) => css`
  display: block;

  + div {
    margin-top: 12px;
  }

  ${isLifeFlowVariant &&
  css`
    display: flex;
    align-items: start;
    width: 100%;
    flex-direction: column;

    ${mq[0]} {
      width: unset;
      flex-direction: row;
      max-width: 315px;
      align-items: center;
    }

    + div {
      ${onlyMobile &&
      css`
        margin-top: 20px;
      `}

      ${mq[0]} {
        margin-top: 0px;
      }
    }
  `}
`;

export const controlCSS = (isLifeFlowVariant?: boolean) => css`
  margin: 4px 0;

  ${isLifeFlowVariant &&
  css`
    ${onlyMobile} {
      width: 100%;
    }
  `};
`;

export const iconCSS = css`
  display: flex;
  margin: 0 8px 0 4px;
  cursor: pointer;
  width: 16px;
`;
