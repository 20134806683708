import { css, SerializedStyles } from '@emotion/react';

import { sizes, mq } from 'src/theme/variables';

export const container = (fullscreen: boolean, dense: boolean): SerializedStyles => css`
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  padding-left: ${dense ? sizes.paddingBodyHorizontalMobileDense : sizes.paddingBodyHorizontalMobile}px;
  padding-right: ${dense ? sizes.paddingBodyHorizontalMobileDense : sizes.paddingBodyHorizontalMobile}px;
  max-width: ${fullscreen ? '100%' : `${sizes.containerMaxWidth + sizes.paddingBodyHorizontalTablet * 2}px`};

  ${mq[0]} {
    padding-left: ${sizes.paddingBodyHorizontalTablet}px;
    padding-right: ${sizes.paddingBodyHorizontalTablet}px;
  }
`;
