import { css } from '@emotion/react';
import { borderRadius, colors, mq } from 'src/theme/variables';

export const frameCSS = css`
  position: relative;
  overflow: hidden;
  border: 1px solid ${colors.nobel};
  border-radius: ${borderRadius}px;
  margin-bottom: 16px;
`;

export const mapCSS = css`
  height: 160px;
  ${mq[0]} {
    height: 230px;
  }
`;
