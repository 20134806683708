import { css, SerializedStyles } from '@emotion/react';
import { mq, colors as c } from 'src/theme/variables';
import { text, text12, text24, text14 } from 'src/theme/typography';

export const cardModalCSS = css`
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${mq[0]} {
    height: unset;
    padding: 24px;
    top: 150px;
  }

  ${mq[0]} {
    align-items: center;
  }
`;

export const cardModalDetailsCSS = css`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;

  ${mq[0]} {
    margin-bottom: 24px;
  }
`;

export const cardPhoneNumberCSS = css`
  align-items: center;
  display: flex;
  padding-top: 12px;

  ${mq[0]} {
    padding-top: 0;
  }
`;

export const cardPolicyWrapperCSS = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 12px;
  box-sizing: border-box;

  ${mq[0]} {
    padding: 20px;
  }

  ${mq[1]} {
    flex-direction: row;
  }
`;

export const cardPolicyDetailsCSS = (isUserPhonePrefilled = false): SerializedStyles => css`
  ${mq[1]} {
    max-width: ${isUserPhonePrefilled ? 60 : 100}%;
  }
`;

export const bundleMsgWrapperCSS = (isUserPhonePrefilled = false): SerializedStyles => css`
  ${text14};
  color: ${c.black};

  ${mq[1]} {
    max-width: ${isUserPhonePrefilled ? 60 : 100}%;
  }
`;

export const cardHrMobile = css`
  display: block;
  width: 100%;
  margin: 0;

  ${mq[0]} {
    display: none;
  }
`;

export const customerAliasCSS = css`
  ${text};
  margin-bottom: 8px;
  font-weight: bold;
`;

export const policyExpirationDateCSS = (isBundleMsgActive?: boolean): SerializedStyles => css`
  color: ${c.warmGray};

  ${isBundleMsgActive &&
  css`
    color: ${c.mineshaft};
  `};
`;

export const policyAddressCSS = css`
  margin-bottom: 8px;
`;

export const heroWrapperCSS = (alignment?: string): SerializedStyles => css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 0 0;
  align-items: center;

  ${alignment &&
  css`
    align-items: ${alignment};
  `}

  ${mq[0]} {
    flex-direction: row;
    padding: 55px 0;
  }
`;

export const heroInfoCSS = (shouldHideDesktopPadding?: boolean): SerializedStyles => css`
  padding-bottom: 32px;

  ${mq[0]} {
    padding-top: 40px;
    margin-right: 20px;
    max-width: 50%;

    ${shouldHideDesktopPadding &&
    css`
      padding-top: 0;
    `}
  }

  ${mq[1]} {
    width: 45%;
  }
`;

export const mobileDisclosuresCSS = css`
  display: block;

  ${mq[0]} {
    display: none;
  }
`;

export const inscriptionCSS = css`
  margin-left: auto;
  ${text12};
  font-style: italic;
`;

export const securityDisclosureFormCSS = css`
  box-sizing: border-box;
`;

export const privacyMattersCustomCSS = (isUserPhonePrefilled = false): SerializedStyles => css`
  background-color: ${isUserPhonePrefilled ? c.grayFive : c.blueFife};
  padding: 16px 29px 16px 24px;
  margin-top: ${isUserPhonePrefilled ? 0 : '8px'};
  max-width: 100%;

  ${mq[0]} {
    max-width: 100%;
    border: ${isUserPhonePrefilled ? `1px solid ${c.mercury}` : 'none'};
    border-top: none;
    margin-left: 0;
  }
`;

export const offerDetailsCSS = css`
  border: 1px solid ${c.mercury};

  margin: 20px;

  ${mq[0]} {
    width: 100%;
    margin: unset;
  }
`;

export const phoneInputCSS = css`
  width: 100%;
  height: 60px;
  background-color: white;
`;

export const titleCSS = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
  position: relative;

  :after {
    content: '';
    width: calc(100% + var(--side-paddings) * 2);
    height: 1px;
    position: absolute;
    bottom: 12px;
    left: calc(var(--side-paddings) * -1);
    box-shadow: inset 0px -1px 0px #f0f0f0;
  }

  h2 {
    margin: 0;
    ${text24};
  }

  svg {
    cursor: pointer;
    path {
      stroke: ${c.black};
    }
  }
`;

export const buttonWrapperCSS = css`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 24px;
  position: relative;

  :after {
    content: '';
    width: calc(100% + var(--side-paddings) * 2);
    height: 1px;
    position: absolute;
    top: -12px;
    left: calc(var(--side-paddings) * -1);
    box-shadow: inset 0px 1px 0px #f0f0f0;
  }
`;

export const modalBodyCSS = css`
  padding: 12px var(--side-paddings) !important;
  --side-paddings: 16px;

  ${mq[0]} {
    margin: auto;
    max-width: 466px;
    --side-paddings: 24px;
  }
`;

export const contentCSS = (isMobile?: boolean): React.CSSProperties => {
  return {
    marginTop: isMobile ? '34px' : 'auto'
  };
};

export const propertyCSS = css`
  color: ${c.mineshaft};
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  width: 130px;

  span {
    margin-right: 26px;
  }
`;

export const BBBIconCSS = css`
  margin: 0 20px 20px;
  width: unset;

  ${mq[0]} {
    margin: unset;
    margin-top: 20px;
    width: 100%;
  }
`;
