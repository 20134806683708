import { QueryObserverResult, useQuery } from 'react-query';
import queryString from 'query-string';
import api from 'src/api/api';
import { IQuotesGroup } from 'src/interfaces/quotesView/IQuote';

interface QuotesParams {
  engagement_gid: string | null;
  quotes_request_gid: string | null;
  assets_gids: string[] | null;
  policy_types: string[] | null;
}

const useQuotes = (personGid: string | null, quotesParams: QuotesParams): QueryObserverResult<IQuotesGroup[]> => {
  return useQuery(
    ['quotes_view_quotes', personGid, ...Object.values(quotesParams)],
    () =>
      api.get(
        `/api/quote_pdf/people/${personGid}/quotes?${queryString.stringify(quotesParams, { arrayFormat: 'bracket' })}`
      ),
    {
      enabled:
        !!personGid &&
        !!quotesParams.engagement_gid &&
        !!quotesParams.quotes_request_gid &&
        !!quotesParams.assets_gids &&
        !!quotesParams.policy_types
    }
  );
};

export default useQuotes;
