import { QueryObserverResult, useQuery } from 'react-query';
import api from 'src/api/api';
import { IQuotesViewConfig } from 'src/interfaces/quotesView/IQuotesViewConfig';
import { QUERY_CACHE_KEYS } from 'src/constants/queryCacheKeys';
import { API_URLS } from 'src/constants/api';

const useQuotesViewConfig = (): QueryObserverResult<IQuotesViewConfig> =>
  useQuery([QUERY_CACHE_KEYS.QUOTES_VIEW_CONFIG], () => api.get(API_URLS.GET_QUOTES_VIEW_CONFIG));

export default useQuotesViewConfig;
