/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';

import { visuallyHidden } from 'src/theme/helpers';
import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';
import { useParams } from 'react-router-dom';
import { DefaultLocation } from 'src/interfaces/IPage';
import QuotesBuilder from 'src/components/QuotesViewBuilder/QuotesViewBuilder';

const PAGE_TITLE = 'Quotes View';

const QuotesViewLayout: React.FC = () => {
  const { gid, flow } = useParams() as DefaultLocation;

  useEffect(() => {
    document.title = PAGE_TITLE;
    analytics.page(SEGMENT.PAGES.QUOTES_VIEW, { flow_type: flow, session_gid: gid });
  }, [flow, gid]);

  return (
    <main>
      <h1 css={visuallyHidden}>{PAGE_TITLE}</h1>
      <QuotesBuilder />
    </main>
  );
};

export default QuotesViewLayout;
