/** @jsxImportSource @emotion/react */
import React, { Fragment } from 'react';
import { additionalBrowserUpdateSpaceCSS, fullHeightPageCSS } from './Page.style';
import { PageProps } from './Page.types';
import { Global } from '@emotion/react';
import useBrowserUpdate from 'src/hooks/useBrowserUpdate';
import useInitIntegrations from 'src/hooks/useInitIntegrations';

const Page: React.FC<PageProps> = ({ children, fullHeight = false }) => {
  useInitIntegrations();
  const browserUpdateHeight = useBrowserUpdate();

  return (
    <Fragment>
      <Global styles={additionalBrowserUpdateSpaceCSS(browserUpdateHeight)} />
      {fullHeight ? (
        <div css={fullHeightPageCSS} data-testid="full-height-wrapper">
          {children}
        </div>
      ) : (
        children
      )}
    </Fragment>
  );
};

export default Page;
