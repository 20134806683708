/** @jsxImportSource @emotion/react */

import homeWithoutConfetti from 'src/assets/svg/home-without-confetti.svg';
import homeBundle from 'src/assets/svg/home-bundle.svg';
import CheckIcon from 'src/assets/svg/checkmark-filled.svg?react';
import Button from 'src/kit/Button/Button';
import {
  cardsWrapperCSS,
  cardWrapperCSS,
  checkIconCSS,
  customButtonCSS,
  homeBundleOfferContainerCSS,
  labelCSS,
  roundedBackgroundCSS
} from './HomeBundleOffer.style';
import { ButtonSize } from 'src/kit/Button/types';
import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { DefaultLocation } from 'src/interfaces/IPage';
import { useCustomNavigate } from 'src/hooks/useCustomNavigate';
import Dialog from 'src/kit/Dialog/Dialog';
import { useFormContext } from 'react-hook-form';
import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';
import { useMutateSession } from 'src/api/session';
import { AUTOMATION_TEST_IDS } from 'src/constants/automationTestIds';

const HomeBundleOffer: React.FC = () => {
  const { flow, gid } = useParams() as DefaultLocation;
  const { search } = useLocation();
  const navigate = useCustomNavigate();
  const { mutateAsync: mutateSession, isLoading } = useMutateSession(flow, gid);
  const { getValues } = useFormContext();

  useEffect(() => {
    analytics.track(SEGMENT.HOME_BUNDLE_OFFER_VIEWED, gid, flow);
  }, [flow, gid]);

  const getHomeQuotes = (): void => {
    analytics.track(SEGMENT.HOME_QUOTES_BUTTON_CLICKED, gid, flow);
    mutateSession({
      step: 'home_bundle',
      answers: { person_is_interested_in_auto_insurance: 'no', ...getValues() },
      quotes_request: true
    }).then(() => {
      navigate({ pathname: `/${flow}/${gid}/interstitial`, search });
    });
  };

  const getBundleQuotes = (): void => {
    analytics.track(SEGMENT.BUNDLE_QUOTES_BUTTON_CLICKED, gid, flow);
    mutateSession({
      step: 'auto_start',
      answers: { person_is_interested_in_auto_insurance: 'yes', ...getValues() }
    });
  };

  return (
    <Dialog isOpen fullscreen>
      <div css={homeBundleOfferContainerCSS}>
        <h1>
          Want to Bundle Auto and Save Up to <span>20%?</span>
        </h1>
        <p>Tell us a little bit about your cars and drivers to see home & auto bundle quotes.</p>
        <div css={cardsWrapperCSS}>
          <div css={cardWrapperCSS}>
            <img src={homeWithoutConfetti} alt="Home icon without confetti" />
            <span>Home Quotes</span>
            <Button
              variant="secondary"
              isLoading={isLoading}
              size={ButtonSize.Large}
              onClick={getHomeQuotes}
              customCSS={customButtonCSS}
              data-testid={AUTOMATION_TEST_IDS.HOME_BUNDLE_OFFER_HOME_QUOTES_BUTTON}
            >
              Get Quotes
            </Button>
            <CheckIcon css={checkIconCSS} />
          </div>
          <div css={[cardWrapperCSS, roundedBackgroundCSS]}>
            <img src={homeBundle} alt="Home and car icons with bow" />
            <span>Bundle Quotes</span>
            <Button
              size={ButtonSize.Large}
              isLoading={isLoading}
              onClick={getBundleQuotes}
              customCSS={customButtonCSS}
              data-testid={AUTOMATION_TEST_IDS.HOME_BUNDLE_OFFER_BUNDLE_QUOTES_BUTTON}
            >
              Get Quotes
            </Button>
            <div css={labelCSS}>Up to 20% Off</div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default HomeBundleOffer;
