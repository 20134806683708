/** @jsxImportSource @emotion/react */
import { FC, useState } from 'react';
import { Card } from 'src/shared/components/Card/Card';
import {
  autoIconCSS,
  bundleCarIconCSS,
  bundleHomeIconCSS,
  cardCSS,
  cardImageContainerCSS,
  carrierLogoCSS,
  coverageCSS,
  coveragesButtonCSS,
  coveragesCSS,
  elipseCSS,
  estimationCSS,
  headerImagesContainerCSS,
  houseIconCSS,
  incompleteAutoBlockCSS,
  incompleteAutoHeadingCSS,
  perYearCSS,
  premiumBlockCSS,
  premiumValueCSS,
  privacyMattersCSS,
  savingsBlockCSS,
  savingsValueCSS,
  submitButtonCSS
} from './BundleOnlyCard.style';
import { BundleOnlyCardProps } from './BundleOnlyCard.types';
import DashedRow from 'src/kit/DashedRow/DashedRow';
import Button from 'src/kit/Button/Button';
import CustomTCPAConsent from 'src/components/CustomTCPAConsent/CustomTCPAConsent';
import SEGMENT from 'src/constants/segment';
import BundleCoveragesModal from 'src/components/BundleOnlyHero/BundleCoveragesModal/BundleCoveragesModal';
import Format from 'src/utils/format';
import HouseIcon from 'src/assets/svg/house.svg';
import AutoWithLockIcon from 'src/assets/svg/auto-with-lock.svg';
import UnlockIcon from 'src/assets/svg/unlock.svg';
import BundleCar from 'src/assets/svg/car-only-bundle.svg';
import BundleHome from 'src/assets/svg/bndl-house.svg';
import analytics from 'src/utils/analytics';
import { getPercentRangeValue } from 'src/utils/percentageCalculation';
import { useParams } from 'react-router-dom';
import { DefaultLocation } from 'src/interfaces/IPage';
import DisclosureForm from 'src/components/DisclosureForm/DisclosureForm';
import { FS_SENSITIVE_DATA_CLASS } from 'src/constants/fullStory';

const BundleOnlyCard: FC<BundleOnlyCardProps> = ({
  quote,
  currentPolicy,
  isLoading,
  isDisclosuresAccepted,
  hasPhone,
  onSubmit
}) => {
  const { gid, flow } = useParams() as DefaultLocation;

  const [isOpen, setIsOpen] = useState(false);
  const carrierLogo = quote.carrier.logo_url;
  const carrierName = quote.carrier.name;

  const handleOpenCoverages = () => {
    setIsOpen(true);
    analytics.track(SEGMENT.BUNDLE_ONLY.COVERAGES_OPENED, gid, flow);
  };

  return (
    <Card customCSS={cardCSS}>
      <div css={cardImageContainerCSS}>
        <div css={headerImagesContainerCSS}>
          <img src={BundleHome} css={bundleHomeIconCSS} alt="" />
          <img css={carrierLogoCSS} src={carrierLogo} alt={carrierName} />
          <img src={BundleCar} css={bundleCarIconCSS} alt="" />
        </div>
        <div css={elipseCSS} />
      </div>
      <div>
        <div css={premiumBlockCSS}>
          <img css={houseIconCSS} src={HouseIcon} alt="" />
          <span css={premiumValueCSS}>
            {Format.money(quote.premium.value || 0, 0, '$')}
            <span css={perYearCSS}>/yr</span>
          </span>
        </div>
        <div css={estimationCSS}>Est. Home Premium</div>
      </div>
      <div css={savingsBlockCSS}>
        <span css={savingsValueCSS}>{getPercentRangeValue(quote.saving_value, 15, 30)}</span> {''}
        of savings compared to your current premium of{' '}
        <strong className={FS_SENSITIVE_DATA_CLASS.MASK}>
          {Format.money(currentPolicy.premium || 0, 0, '$')}
        </strong>{' '}
        from {currentPolicy.carrier_name} (renews on {currentPolicy.expiration_date}).
      </div>
      <div css={coveragesCSS}>
        {quote.coverages.dwelling && (
          <DashedRow
            className={FS_SENSITIVE_DATA_CLASS.MASK}
            customCSS={coverageCSS}
            label="Dwelling Coverage"
            content={<strong>{Format.money(quote.coverages.dwelling || 0, 0, '$')}</strong>}
          />
        )}
        <DashedRow
          className={FS_SENSITIVE_DATA_CLASS.MASK}
          customCSS={coverageCSS}
          label="Deductible"
          content={<strong>{Format.money(quote.deductible || 0, 0, '$')}</strong>}
        />
      </div>
      {!!quote.coverages && (
        <Button css={coveragesButtonCSS} variant="text" onClick={handleOpenCoverages}>
          View All Home Coverages
        </Button>
      )}

      <div css={incompleteAutoBlockCSS}>
        <img src={AutoWithLockIcon} css={autoIconCSS} alt={''} />
        <div>
          <div css={incompleteAutoHeadingCSS}>
            <strong>Auto quote is incomplete</strong>
          </div>
          <div>We’ll need a little more info to unlock your auto premium.</div>
        </div>
      </div>
      <DisclosureForm
        CTAText={
          <>
            <img src={UnlockIcon} alt={''} />
            Unlock full bundle quote
          </>
        }
        customCSS={{ buttonCSS: submitButtonCSS }}
        onFormSubmit={onSubmit}
        isLoading={isLoading}
      />
      {!isDisclosuresAccepted && hasPhone && (
        <CustomTCPAConsent
          shouldHideIcon
          customCSS={privacyMattersCSS}
          buttonLabel="Unlock full bundle quote"
          location={SEGMENT.LOCATIONS.SF_PRIVACY_MATTERS}
        />
      )}
      <BundleCoveragesModal
        isOpen={isOpen}
        isLoading={isLoading}
        coverages={quote.coverages}
        onClose={() => setIsOpen(false)}
      />
    </Card>
  );
};

export default BundleOnlyCard;
