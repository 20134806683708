/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';

import FloridaIcon from 'src/assets/svg/home-with-confetti.svg';
import Loader from 'src/kit/Loader/Loader';
import { useParams } from 'react-router-dom';
import { DefaultLocation } from 'src/interfaces/IPage';

import { wrapperCSS, headingCSS, profileWrapperCSS, itemsWrapperCSS } from './CallMeNowConfirmation.style';
import useQuestions from 'src/api/questions/useQuestions';
import { Answers } from 'src/interfaces/IAnswer';
import HowItWorksSteps from 'src/components/HowItWorksSteps/HowItWorksSteps';
import { getCallMeNowSteps } from 'src/components/HowItWorksSteps/HowItWorksSteps.utils';
import QuotesUserProfile from 'src/components/Quotes/StandardHouseQuotes/QuotesUserProfile/QuotesUserProfile';
import { AFAnswers } from 'src/components/AllFunnelOfferForm/AllFunnelOfferForm.types';

const CallMeNowConfirmation: React.FC = () => {
  const { gid } = useParams() as DefaultLocation;
  const { data: questions, isLoading } = useQuestions(gid);

  const answers = useMemo(() => questions?.answers || ({} as Answers), [questions]);
  const {
    stories,
    person_first_name,
    person_last_name,
    property_address,
    current_policy,
    property_year_built,
    property_square_feet
  } = answers as AFAnswers;

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div css={wrapperCSS}>
      <img src={FloridaIcon} height={100} alt="House icon with clouds and phone" />
      <div css={headingCSS}>{person_first_name}, you’re all set!</div>

      <div>
        <QuotesUserProfile
          shouldRenderCallMeNow
          shouldRenderNA
          customCSS={profileWrapperCSS}
          firstName={person_first_name}
          lastName={person_last_name}
          carrierName={current_policy?.carrier_name}
          currentPolicy={current_policy}
          homeDetails={{
            yearBuilt: property_year_built,
            squareFeet: property_square_feet,
            stories: stories
          }}
          address={property_address}
        />
      </div>
      <div css={itemsWrapperCSS}>
        <HowItWorksSteps heading="What you can expect next" steps={getCallMeNowSteps()} isCallMeNowVariation />
      </div>
    </div>
  );
};

export default CallMeNowConfirmation;
