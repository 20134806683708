import { SavingSelectedQuote } from 'src/interfaces/ISavings';
import api from 'src/api/api';
import { API_URLS } from 'src/constants/api';
import { getApiUrl } from 'src/utils/api.helpers';

const savingsService = {
  selectQuote(gid: string, data: SavingSelectedQuote): Promise<any> {
    return api.post(getApiUrl(API_URLS.SELECT_SAVINGS_QUOTE, gid), {
      body: { ...data }
    });
  }
};

export default savingsService;
