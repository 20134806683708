/** @jsxImportSource @emotion/react */

import {
  carrierLogoCSS,
  carrierSavingCSS,
  carrierSectionCSS
} from 'src/components/SavingsHero/SavingsCarrier/SavingsCarrier.style';
import { carrierCustomCSS, savingsCardCSS } from 'src/components/SavingsHero/SavingsHero.style';
import { Card } from 'src/shared/components/Card/Card';
import {
  buttonWrapperCSS,
  coverageButtonCustomCSS,
  savingsAmountCSS,
  savingsDashedRowCustomCSS,
  savingsRowCSS,
  savingsYearCSS
} from './AfSingleQuoteCard.style';
import DashedRow from 'src/kit/DashedRow/DashedRow';
import Button from 'src/kit/Button/Button';
import { ButtonSize } from 'src/kit/Button/types';
import CoveragePopup from 'src/components/Quotes/AfSingleQuote/CoveragePopup/CoveragePopup';
import { useState } from 'react';
import {
  cardPolicyDetailsCSS,
  customerAliasCSS,
  policyAddressCSS,
  policyExpirationDateCSS
} from 'src/components/SavingsHero/SavingsOffer/SavingsOffer.style';
import { getReadableAddress } from 'src/utils/homeDetails';
import { AddressAnswer } from 'src/interfaces/IQuestion';
import { AfSingleQuoteCardProps } from './AfSingleQuoteCard.types';
import { AUTOMATION_TEST_IDS } from 'src/constants/automationTestIds';
import { FS_SENSITIVE_DATA_CLASS } from 'src/constants/fullStory';

const IDS = AUTOMATION_TEST_IDS.SINGLE_QUOTE;

const AfSingleQuoteCard: React.FC<AfSingleQuoteCardProps> = ({
  bestQuote,
  currentPolicy,
  selectQuote,
  answers,
  saving,
  premiumToRender,
  trackQuoteReviewed
}) => {
  const [isCoveragePopupOpen, setIsCoveragePopupOpen] = useState(false);

  const showDetails = () => {
    trackQuoteReviewed();
    setIsCoveragePopupOpen(true);
  };

  return (
    <Card customCSS={savingsCardCSS}>
      <div css={[carrierSectionCSS(), carrierCustomCSS]} data-testid={IDS.CARD}>
        <div css={savingsRowCSS}>
          <img css={carrierLogoCSS} src={bestQuote.carrier.logo_url} alt={bestQuote.carrier.name} />
          <div css={savingsAmountCSS}>
            <span>Save</span>
            <div css={carrierSavingCSS}>
              {saving}
              <span css={savingsYearCSS}>/yr</span>
            </div>
          </div>
        </div>
        <DashedRow
          label={<span data-testid={IDS.NEW_PREMIUM}>New Premium</span>}
          content={<span data-testid={IDS.NEW_PREMIUM_VALUE}>${premiumToRender}</span>}
          customCSS={savingsDashedRowCustomCSS(true)}
        />
        <DashedRow
          label={<span data-testid={IDS.CURRENT_PREMIUM}>Your Current Premium</span>}
          content={<span data-testid={IDS.CURRENT_PREMIUM_VALUE}>${currentPolicy?.premium}</span>}
          customCSS={savingsDashedRowCustomCSS()}
        />
        <div css={buttonWrapperCSS}>
          <Button size={ButtonSize.Large} fullWidth onClick={selectQuote} data-testid={IDS.CHOOSE_QUOTE_BUTTON_ON_CARD}>
            Choose Quote
          </Button>
          <Button
            variant="text"
            customCSS={coverageButtonCustomCSS}
            onClick={showDetails}
            data-testid={IDS.COVERAGE_DETAILS_BUTTON}
          >
            Coverage Details
          </Button>
        </div>

        <div css={cardPolicyDetailsCSS()}>
          <div>
            <p
              css={customerAliasCSS}
              data-testid={IDS.LEAD_NAME}
              className={FS_SENSITIVE_DATA_CLASS.MASK}
            >{`${answers?.person_first_name} ${answers?.person_last_name}`}</p>
            <p css={policyAddressCSS} data-testid={IDS.LEAD_ADDRESS} className={FS_SENSITIVE_DATA_CLASS.MASK}>
              <span role="img" aria-label="House">
                🏡{' '}
              </span>
              {answers?.property_address && getReadableAddress(answers?.property_address as AddressAnswer)}
            </p>
            {currentPolicy?.expiration_date && (
              <p css={policyExpirationDateCSS()}>
                {currentPolicy?.carrier_name} (Renews on {currentPolicy?.expiration_date})
              </p>
            )}
          </div>
        </div>
      </div>

      <CoveragePopup
        isPopupOpen={isCoveragePopupOpen}
        selectQuote={selectQuote}
        onClose={() => setIsCoveragePopupOpen(false)}
        bestQuote={bestQuote}
      />
    </Card>
  );
};

export default AfSingleQuoteCard;
