/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import useConfig from 'src/api/config/useConfig';
import { useCustomNavigate } from 'src/hooks/useCustomNavigate';
import { useLocation, useParams } from 'react-router-dom';
import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';
import { DefaultLocation } from 'src/interfaces/IPage';
import FullPageLoader from 'src/shared/components/FullPageLoader/FullPageLoader';
import Container from 'src/kit/Container/Container';
import { wrapperCSS, mainContentWrapperCSS } from './LifeQuotes.style';
import Hero from 'src/components/Life/Hero/Hero';
import DifferenceSection from 'src/components/Life/DifferenceSection/DifferenceSection';
import GetStarted from 'src/components/Life/GetStarted/GetStarted';
import Advisor from 'src/components/Life/Advisor/Advisor';
import FAQ from 'src/components/Life/FAQ/FAQ';
import Comparison from 'src/components/Life/Comparison/Comparison';
import UserProfile, { UserProfileProps } from 'src/components/Life/UserProfile/UserProfile';
import QuotesWrapper from 'src/components/Life/QuotesWrapper/QuotesWrapper';
import useQuestions from 'src/api/questions/useQuestions';
import { getAge } from 'src/utils/date';
import { getStateFullName } from './LifeQuotes.utils';
import useResponsive from 'src/hooks/useResponsive';
import QuestionsWrapper from 'src/components/Life/QuestionsWrapper/QuestionsWrapper';

const LifeQuotes: React.FC = () => {
  const { gid, flow } = useParams() as DefaultLocation;
  const navigate = useCustomNavigate();
  const { search } = useLocation();
  const { isMobile } = useResponsive();
  const { data: config, isLoading: isConfigLoading } = useConfig(gid);
  const { data: questions, isLoading: isAnswersLoading } = useQuestions(gid);
  const answers = questions?.answers;

  useEffect(() => {
    if (flow && config?.servicer_key) {
      analytics.track(SEGMENT.FLOW_STARTED, gid, flow, {
        partner_key: config?.servicer_key
      });

      analytics.page(SEGMENT.PAGES.LIFE, {
        flow_type: flow,
        session_gid: gid,
        partner_key: config?.servicer_key
      });

      analytics.identify(answers?.person_gid, {
        email: analytics.generateTrackEmail(answers?.person_gid as string),
        state: config?.state ?? undefined,
        age: answers?.person_date_of_birth ? getAge(answers?.person_date_of_birth as string) : undefined
      });
    }
  }, [config, flow, gid, answers]);

  const onTalkToAdvisorClicked = (location: string) => {
    analytics.track(SEGMENT.PHONE_CONVERSATION_INITIATED, gid, flow, {
      location,
      page: SEGMENT.PAGES_KEY.LIFE
    });

    navigate({ pathname: `/${flow}/${gid}/confirmation`, search });
  };

  if (isConfigLoading || isAnswersLoading) {
    return <FullPageLoader />;
  }

  const getProfileProps: UserProfileProps = {
    firstName: answers?.person_first_name as string,
    lastName: answers?.person_last_name as string,
    age: answers?.person_date_of_birth ? getAge(answers?.person_date_of_birth as string) : undefined,
    loan_balance: (answers?.loan_balance as number) ?? undefined,
    loan_duration: (answers?.loan_duration as number) ?? undefined,
    state: getStateFullName(answers?.person_address_state as string)
  };

  return (
    <div>
      <Hero onTalkToAdvisorClicked={onTalkToAdvisorClicked} userName={answers?.person_first_name as string} />
      <Container customCSS={wrapperCSS}>
        {isMobile && <UserProfile {...getProfileProps} />}
        <QuestionsWrapper agentPhone={config?.partner?.agent_phone || ''} />
        <div css={mainContentWrapperCSS}>
          {!isMobile && <UserProfile {...getProfileProps} />}
          <QuotesWrapper gid={gid} flow={flow} />
        </div>
        <DifferenceSection />
        <Comparison gid={gid} flow={flow} />
        <GetStarted onTalkToAdvisorClicked={onTalkToAdvisorClicked} />
        <FAQ gid={gid} flow={flow} />
        <Advisor onTalkToAdvisorClicked={onTalkToAdvisorClicked} />
      </Container>
    </div>
  );
};

export default LifeQuotes;
