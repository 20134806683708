const INTERNAL = '/api/internal';
const INTERNAL_SESSION = `${INTERNAL}/sessions`;
const SESSION_FLOW = `${INTERNAL_SESSION}/:flow`;
const SESSION_FLOW_GID = `${SESSION_FLOW}/:gid`;
const SESSION_GID = `${INTERNAL_SESSION}/:gid`;
const ATTACHMENTS_BASE = `${INTERNAL_SESSION}/:gid/attachments`;
const ALL_FUNNEL_BASE = `${INTERNAL_SESSION}/all_funnel/:gid`;
const SAVINGS_BASE = `${INTERNAL_SESSION}/savings/:gid`;
const QUOTES_BASE = `${SESSION_FLOW_GID}/quotes`;
const INTERSTITIAL_BASE = `${SESSION_GID}/interstitial`;
const ANSWERS_UPDATED = `${SESSION_FLOW_GID}/answers`;

export const API_URLS = {
  CONFIG: `${INTERNAL}/config`,
  LANDING: `${INTERNAL}/landing`,
  DISCLOSURES: `${INTERNAL}/disclosures`,
  LOOKUP: `${INTERNAL}/lookup`,
  SESSION_FLOW,
  AF: ALL_FUNNEL_BASE,
  AF_CREATE_LEAD: `${ALL_FUNNEL_BASE}/create_lead`,
  AF_MUTATE_PERSON: `${ALL_FUNNEL_BASE}/person`,
  SESSION_FLOW_GID,
  SESSION: `${SESSION_FLOW_GID}:search`,
  UNLOCK_SESSION: `${SESSION_GID}/unlock?:query`,
  LOCK_STATUS: `${SESSION_GID}/lock_status`,
  START_OVER: `${SESSION_GID}/start_over`,
  MUTATE_SESSION_FLOW: `${SESSION_FLOW_GID}`,
  RESET_QUESTION_STEP: `${SESSION_GID}/reset_step`,
  SOURCE_DIMENSIONS: `${SESSION_GID}/source_dimensions`,
  UPDATE_HOME_DETAILS: `${SESSION_FLOW_GID}/update_home_details`,
  START_QUOTING: `${INTERNAL_SESSION}/:flow/start_quoting`,
  QUOTES: QUOTES_BASE,
  SELECT_QUOTE: `${QUOTES_BASE}/select_quotes`,
  SAVINGS: SAVINGS_BASE,
  SELECT_SAVINGS_QUOTE: `${SAVINGS_BASE}/select_quote`,
  ATTACHMENTS: ATTACHMENTS_BASE,
  ATTACHMENTS_UPLOAD: `${ATTACHMENTS_BASE}/upload`,
  ATTACHMENTS_DELETE: `${ATTACHMENTS_BASE}/delete`,
  HOME_DETAILS: `${ALL_FUNNEL_BASE}/home_details`,
  INTERSTITIAL_ANSWERS_UPDATE: `${INTERSTITIAL_BASE}/answers`,
  PROPERTY_INFO_UPDATE: `${ALL_FUNNEL_BASE}/answers`,
  GET_INTERSTITIAL_ANSWERS: `${INTERSTITIAL_BASE}/questions`,
  GET_QUESTIONS: `${INTERNAL}/questions?gid=:gid`,
  GET_QUOTES_VIEW_CONFIG: '/api/quote_pdf/config',
  SCHEDULE_CALL: `${SESSION_GID}/schedule_call`,
  USER_DATA_EVENTS: `${SESSION_GID}/send_event`,
  VERIFY_RECAPTCHA: `${SESSION_GID}/verify_recaptcha`,
  FULL_STORY: `${SESSION_GID}/full_story`,
  AD_BLOCKER: `${SESSION_GID}/ad_blocker`,
  ANSWERS: `${INTERNAL}/answers`,
  ANSWERS_UPDATED: ANSWERS_UPDATED
};
