import React, { useEffect, useState } from 'react';
import FullPageLoader from 'src/shared/components/FullPageLoader/FullPageLoader';
import { Navigate, useLocation, useParams } from 'react-router-dom';

import { DefaultLocation, Flows } from 'src/interfaces/IPage';
import { QuotesResponseStatus } from 'src/interfaces/IQuotes';

import ROUTES from 'src/constants/routes';
import sessionService from 'src/api/session/session.service';

interface State {
  loading: boolean;
  status: string | null;
  gid: string | null;
}

const BaseSession: React.FC = () => {
  const { flow, gid } = useParams() as DefaultLocation;
  const { search } = useLocation();
  const [state, setState] = useState<State>({
    loading: true,
    status: null,
    gid: null
  });
  const RedirectRoute = state.status === QuotesResponseStatus.Finished ? ROUTES.QUOTES : ROUTES.QUESTIONS;

  const getOrCreateSession = async (sessionFlow: Flows, sessionGid?: string) => {
    const { gid: resolvedGid, status } = sessionGid
      ? await sessionService.getSession(sessionFlow, sessionGid)
      : await sessionService.createSession(sessionFlow);

    setState({
      loading: false,
      status: status,
      gid: resolvedGid
    });
  };

  useEffect(() => {
    getOrCreateSession(flow, gid);
  }, [gid, flow]);

  return state.loading ? (
    <FullPageLoader />
  ) : (
    <Navigate
      replace
      to={{
        pathname: RedirectRoute.replace(':flow', flow).replace(':gid', state.gid as string),
        search
      }}
    />
  );
};

export default BaseSession;
