/** @jsxImportSource @emotion/react */

import { QuoteCoverageProps } from './QuoteCoverage.types';
import { nameCSS, descriptionCSS, headerCSS, valueCSS } from './QuoteCoverage.style';

import dwellingCoverage from 'src/assets/svg/dwelling-coverage.svg';
import otherStructuresCoverage from 'src/assets/svg/other-structure-coverage.svg';
import personalPropertyCoverage from 'src/assets/svg/personal-property-coverage.svg';
import lossOfUseCoverage from 'src/assets/svg/loss-of-use-coverage.svg';
import personalLiabilityCoverage from 'src/assets/svg/personal-liability-coverage.svg';
import medicalPaymentsCoverage from 'src/assets/svg/medical-payment-coverage.svg';
import { CoverageHomeKeys } from 'src/components/ComparisonModal/ComparisonModal.types';

const QuoteCoverage: React.FC<QuoteCoverageProps> = ({
  customCSS,
  title,
  value,
  description,
  shouldRenderDescription,
  type
}) => {
  const getIconByType = () => {
    switch (type) {
      case CoverageHomeKeys.Dwelling:
        return dwellingCoverage;
      case CoverageHomeKeys.OtherStructures:
        return otherStructuresCoverage;
      case CoverageHomeKeys.PersonalProperty:
        return personalPropertyCoverage;
      case CoverageHomeKeys.LossOfUse:
        return lossOfUseCoverage;
      case CoverageHomeKeys.PersonalLiability:
        return personalLiabilityCoverage;
      case CoverageHomeKeys.MedicalPayments:
        return medicalPaymentsCoverage;
      default:
        return '';
    }
  };

  const icon = getIconByType();

  return (
    <div css={customCSS}>
      <div css={headerCSS}>
        <div css={nameCSS(!!icon)}>
          {icon ? (
            <>
              <img src={icon} alt={title} /> <span>{title}</span>
            </>
          ) : (
            title
          )}
        </div>
        {value && <div css={valueCSS(!!icon)}>{value}</div>}
      </div>
      {description && <div css={descriptionCSS(shouldRenderDescription)}>{description}</div>}
    </div>
  );
};

export default QuoteCoverage;
