/** @jsxImportSource @emotion/react */
import React, { Fragment } from 'react';

import Expander from 'src/components/Expander/Expander';
import { CoverageAutoTitles, CoverageAutoDescriptions } from 'src/constants/coveragesAuto';
import { description, title, toggle } from './CoverageInfo.style';
import noop from 'src/utils/noop';
import arrowAccordion from 'src/assets/svg/arrow-accordion.svg';
import { CoverageInfoProps } from './CoverageInfo.types';

const CoverageInfo: React.FC<CoverageInfoProps> = ({
  customCSS = {},
  coverageKey,
  isExpandable = false,
  expandKey = '',
  isOpen = false,
  onExpand = noop
}) => {
  const expanderStyles = [description];
  if (customCSS.expander) {
    expanderStyles.push(customCSS.expander);
  }

  if (!isExpandable) {
    return (
      <div css={[title(isExpandable), customCSS.button]} data-testid="coverage-info-title">
        {CoverageAutoTitles[coverageKey]}
      </div>
    );
  }

  return (
    <Fragment>
      <button
        type="button"
        css={[title(isExpandable), customCSS.button]}
        onClick={() => onExpand(isOpen ? null : expandKey)}
        data-testid="coverage-info-title"
      >
        {CoverageAutoTitles[coverageKey]}{' '}
        <img data-testid="coverage-info-toggle" css={toggle(!isOpen)} src={arrowAccordion} alt="Arrow" />
      </button>
      <Expander customCSS={expanderStyles} isExpanded={isOpen} accordionMode>
        {CoverageAutoDescriptions[coverageKey]}
      </Expander>
    </Fragment>
  );
};

export default CoverageInfo;
