/** @jsxImportSource @emotion/react */
import React from 'react';

import { css } from '@emotion/react';

import { button, loaderWrapper } from './Button.style';
import { ButtonProps, ButtonSize } from './types';
import Loader from 'src/kit/Loader/Loader';
import noop from 'src/utils/noop';

const Button: React.FunctionComponent<ButtonProps> = ({
  size = ButtonSize.Normal,
  children,
  variant = 'default',
  isLoading = false,
  customCSS,
  disabled,
  fullWidth,
  type = 'button',
  onClick,
  ...props
}) => (
  <button
    {...props}
    type={type}
    disabled={disabled}
    css={[button(size === ButtonSize.Large, variant, fullWidth), customCSS]}
    onClick={isLoading ? noop : onClick}
  >
    <span
      css={css`
        visibility: ${isLoading ? 'hidden' : 'visible'};
      `}
    >
      {children}
    </span>
    {isLoading && (
      <span css={loaderWrapper}>
        <Loader inverted={variant !== 'secondary'} />
      </span>
    )}
  </button>
);

export { button as buttonStyle } from './Button.style';
export default Button;
