import { SerializedStyles } from '@emotion/react';
import { BundleQuote, BundleType } from 'src/interfaces/IQuotes';

export enum BundleQuoteDetailsTab {
  Auto = 'auto',
  Home = 'home'
}

export interface CurrentTabState {
  left: number;
  width: number;
}

export interface BundleQuoteCardDetailsProps {
  customCSS?: SerializedStyles;
  quote: BundleQuote;
  isSingleType: boolean;
  bundleType: BundleType;
}
