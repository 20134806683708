import api from 'src/api/api';
import { API_URLS } from 'src/constants/api';
import { getApiUrl } from 'src/utils/api.helpers';

const fullStoryService = {
  setFullStorySessionUrl(gid: string, url: string): Promise<any> {
    return api.post(getApiUrl(API_URLS.FULL_STORY, gid), { body: { url } });
  }
};

export default fullStoryService;
