import { css, SerializedStyles } from '@emotion/react';
import { borderRadius, colors } from 'src/theme/variables';
import { text14 } from 'src/theme/typography';
import { checkboxIconHovered } from './CheckboxIcon/CheckboxIcon.style';
import { formInputFocusOutline } from 'src/theme/shared-styles';

const CHECK_ICON_WIDTH = 20;

const containerHoverCSS = css`
  border-color: ${colors.cornflower};
  .checkbox-input-mark {
    ${checkboxIconHovered};
  }
`;

export const containerCSS = (disabled?: boolean): SerializedStyles => css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: ${disabled ? 'auto' : 'pointer'};
  user-select: none;
  border: 1px solid ${colors.black};
  padding: 20px 16px 20px ${16 + CHECK_ICON_WIDTH}px;
  border-radius: ${borderRadius}px;
  &:before {
    ${formInputFocusOutline};
  }
  &:hover {
    ${!disabled && containerHoverCSS};
  }
`;

export const checkboxCSS = css`
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
  &:focus + div:before {
    border-color: ${colors.azure};
  }
`;

export const checkIconCSS = (hasError = false): SerializedStyles => css`
  position: absolute;
  left: 16px;
  top: calc(50% - ${CHECK_ICON_WIDTH / 2}px);
  border-color: ${hasError ? colors.red : 'inherit'};
`;

export const labelContainerCSS = css`
  margin-left: 16px;
`;

export const titleCSS = css`
  display: block;
  font-weight: 700;
`;

export const subtitleCSS = css`
  display: block;
  ${text14};
  color: ${colors.dove};
`;

export const imageCSS = (checked: boolean): SerializedStyles => css`
  margin-left: 16px;
  opacity: ${checked ? 1 : 0.4};
  transition: opacity 0.3s linear;
`;
