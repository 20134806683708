import { css } from '@emotion/react';
import { text12 } from 'src/theme/typography';
import { colors as c, colors } from 'src/theme/variables';

// TODO: Change react tooltip to mui tooltip. Remove this style after it
export const tooltip = css`
  border: 1px solid ${colors.silver} !important;
  background-color: ${colors.white} !important;
  border-radius: 4px !important;
  box-shadow: 0 4px 0 rgba(0, 0, 0, 0.09);
  color: ${colors.black} !important;
  max-width: 400px !important;
  opacity: 1 !important;
  z-index: 2;
`;

export const tooltipArrowHelpCSS = css`
  && > div::before {
    border-top: transparent !important;
    height: 10px !important;
  }
  && > div::after {
    background-color: white !important;
    height: 5px !important;
  }
`;

/**
 * MUI Tooltip styles
 * */
export const tooltipCSS = {
  backgroundColor: c.white,
  maxWidth: '360px',
  boxShadow: '0 4px 15px rgb(0 0 0 / 20%)',
  padding: 0
};

export const tooltipArrowCSS = {
  color: `${c.white}`
};

export const tooltipContentCSS = css`
  color: ${colors.warmGray};
  padding: 16px;
  ${text12};
  a {
    color: ${colors.azure};
  }
  p:not(:last-of-type) {
    margin-bottom: 8px;
  }
`;
