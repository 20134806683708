/** @jsxImportSource @emotion/react */
import React, { useEffect, Fragment } from 'react';

import { css } from '@emotion/react';
import gsap from 'gsap';
import { useLocation } from 'react-router-dom';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

import Container from 'src/kit/Container/Container';
import Hero from './Hero/Hero';
import FAQ from 'src/components/FAQ/FAQ';
import GoogleReviews from './GoogleReviews/GoogleReviews';
import AgencyInfo from './AgencyInfo/AgencyInfo';
import Steps from './Steps/Steps';
import AgencyContact from './AgencyContact/AgencyContact';
import CarriersList from 'src/components/CarriersList/CarriersList';
import SEGMENT from 'src/constants/segment';
import analytics from 'src/utils/analytics';
import { Flows } from 'src/interfaces/IPage';
import {
  landingCSS,
  headingCSS,
  subHeadingCSS,
  heroHeadingCSS,
  stepsAndContactWrapperCSS,
  stepsWrapperCSS,
  contactWrapperCSS,
  separatorCSS,
  separatorOrCSS,
  carrierListWrapperCSS,
  googleReviewsSectionCSS,
  cardsContainerCSS,
  stepsCSS
} from './Landing.style';
import LandingFlowCards from './LandingFlowCards/LandingFlowCards';
import { useCustomNavigate } from 'src/hooks/useCustomNavigate';
import queryString from 'query-string';
import useLanding from 'src/api/landing/useLanding';
import { accordionItems } from './Landing.utils';
import SERVICERS from 'src/constants/servicers';

const Landing: React.FC = () => {
  const navigate = useCustomNavigate();
  const { search } = useLocation();
  const params = queryString.parse(search?.replace('?', ''));
  const { data, isLoading } = useLanding();
  const flows = data?.flows;

  useEffect(() => {
    analytics.page(SEGMENT.PAGES.LANDING, {
      partner_key: data?.partner_key
    });
  }, [data]);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
  }, []);

  useEffect(() => {
    !isLoading && gsap.to('.action-cards', { y: 0, opacity: 1, stagger: 0.1 });
    !isLoading && gsap.to('#steps', { y: 0, opacity: 1, stagger: 0.1 });
  }, [isLoading]);

  const onGoogleReviewsClick = () =>
    analytics.track(SEGMENT.GOOGLE_REVIEWS_CLICKED, undefined, undefined, {
      location: SEGMENT.PAGES_KEY.LANDING
    });

  const onFlowSelect = (flowType: Flows, url: string) => {
    analytics.track(SEGMENT.FLOW_SELECTED, undefined, undefined, { flow: flowType });
    navigate({
      pathname: url,
      search
    });
  };

  return (
    <Fragment>
      <div css={landingCSS}>
        <Hero flowsLength={flows?.length}>
          <h1 css={heroHeadingCSS}>
            Are you getting the best insurance rates? <br />
            Compare in seconds.
          </h1>
          {flows && flows.length > 1 && <div css={subHeadingCSS}>Select a product to get your quotes:</div>}
          <div css={cardsContainerCSS}>
            <LandingFlowCards
              isLoading={isLoading}
              flows={flows}
              animationCardSelector="action-cards"
              onFlowSelect={onFlowSelect}
              params={params}
            />
          </div>
        </Hero>

        <Container>
          <section css={stepsCSS} id="steps">
            <h2 css={headingCSS}>It&apos;s as easy as 1, 2, 3.</h2>

            <div css={stepsAndContactWrapperCSS}>
              <div css={stepsWrapperCSS}>
                <Steps />
              </div>

              <div css={separatorCSS}>
                <div css={separatorOrCSS} />
              </div>

              <div css={contactWrapperCSS}>
                <AgencyContact />
              </div>
            </div>
          </section>
        </Container>

        <section css={carrierListWrapperCSS}>
          <Container>
            <CarriersList />
          </Container>
        </section>

        <Container
          customCSS={css`
            position: relative;
          `}
        >
          <AgencyInfo />
          <section css={googleReviewsSectionCSS}>
            <GoogleReviews onGoogleReviewsClick={onGoogleReviewsClick} />
          </section>
          <FAQ
            page={SEGMENT.PAGES.LANDING}
            accordionItems={data?.partner_key === SERVICERS.SONIC ? accordionItems : []}
          />
        </Container>
      </div>
    </Fragment>
  );
};

export default Landing;
