import { css, SerializedStyles } from '@emotion/react';

import { borderRadius, boxShadow, colors as c, mq } from 'src/theme/variables';
import { text24 } from 'src/theme/typography';

export const cardCSS = css`
  box-shadow: ${boxShadow.container};
  text-align: center;
  width: 100%;
  transition: box-shadow 0.2s ease;
  background-color: ${c.white};
  border: 1px solid ${c.concrete};
  border-radius: ${borderRadius}px;

  &:hover {
    box-shadow: ${boxShadow.containerHovered};
  }
`;

const horizontalCardCSS = css`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const actionCardContentCSS = (horizontal: boolean): SerializedStyles => css`
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  text-align: center;
  padding: 20px 16px;
  ${horizontal && horizontalCardCSS};

  ${mq[0]} {
    padding: 32px;
  }
`;

export const linkCSS = css`
  color: inherit;
  text-decoration: none;
  background-color: transparent;
  border: none;
  width: 100%;
  padding: 0;

  &:hover {
    background-color: transparent;
    color: inherit;
  }
`;

export const imgWrapperCSS = (horizontal: boolean): SerializedStyles => css`
  margin-bottom: ${horizontal ? 0 : 16}px;
  margin-right: ${horizontal ? 24 : 0}px;
  font-size: 0;
  ${mq[0]} {
    margin-bottom: 0;
  }
`;

const horizontalTextCSS = css`
  display: block;
  height: auto;
  flex-grow: 1;
`;

export const textCSS = (horizontal: boolean): SerializedStyles => css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  ${horizontal && horizontalTextCSS};
`;

export const buttonCSS = (horizontal: boolean): SerializedStyles => css`
  ${mq[0]} {
    min-width: ${horizontal ? '230px' : 'auto'};
  }
  ${mq[1]} {
    min-width: auto;
  }
`;

export const actionCardImgCSS = css`
  display: inline-block;
  max-width: 100%;
  min-width: 95px;
  ${mq[0]} {
    width: 130px;
  }
  ${mq[1]} {
    width: 160px;
  }
`;

export const actionCardHeadingCSS = css`
  margin-bottom: 8px;
  font-weight: 700;
  ${mq[0]} {
    ${text24};
    margin-bottom: 20px;
  }
`;
