import React from 'react';
import DefaultFormPage from 'src/questionsForm/components/DefaultFormPage/DefaultFormPage';
import EntityFormPage from 'src/questionsForm/components/EntityFormPage/EntityFormPage';
import ConsentFormPage from 'src/questionsForm/components/ConsentFormPage/ConsentFormPage';
import { QuestionsFormPageProps, QuestionsFormPages } from 'src/questionsForm/types/questionsFormPage.types';

export const QuestionsFormPageMap: Record<QuestionsFormPages, React.ComponentType<QuestionsFormPageProps>> = {
  [QuestionsFormPages.Default]: DefaultFormPage,
  [QuestionsFormPages.Entity]: EntityFormPage,
  [QuestionsFormPages.Consent]: ConsentFormPage
};
