import { SerializedStyles, css } from '@emotion/react';
import { text, text24, text14 } from 'src/theme/typography';
import { mq, colors } from 'src/theme/variables';

export const wrapperCSS = css`
  border-radius: 8px;
  border: 1px solid #b3b3b3;
  ${text};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
`;

export const questionsCSS = css`
  padding: 20px 16px;
  ${text};
  text-align: center;
  align-items: center;

  ${mq[0]} {
    padding: 32px 32px 44px 32px;
  }
`;

export const headingCSS = css`
  ${text};
  display: flex;
  text-align: left;
  align-items: center;
  font-weight: 700;
  margin-bottom: 22px;

  img {
    margin-right: 20px;
  }

  ${mq[0]} {
    ${text24};
    justify-content: space-around;
  }
`;

export const basedBlockWrapperCSS = (isSuccess = true, backgroundColor: string): SerializedStyles => css`
  background-color: ${backgroundColor};
  color: ${colors[isSuccess ? 'dove' : 'mineshaft']};
  padding: 16px;
  ${text14};
  display: flex;
  justify-content: center;
  align-items: center;

  ${mq[0]} {
    ${text};
  }
`;

export const iconCSS = (isWarning = false): SerializedStyles => css`
  margin-right: 12px;
  height: 16px;
  width: 16px;
  flex-shrink: 0;

  ${isWarning &&
  css`
    path:first-of-type {
      fill: ${colors.sunsetOrange};
    }
  `}
`;

export const wrapperBottomCSS = css`
  overflow: hidden;
  width: 100%;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
`;

export const phoneCSS = css`
  font-weight: 700;
`;
