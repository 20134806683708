/** @jsxImportSource @emotion/react */
import React from 'react';

import { SerializedStyles } from '@emotion/react';

import { card } from './Card.style';

interface CardProps {
  customCSS?: SerializedStyles | (SerializedStyles | undefined)[];
  children: React.ReactNode;
}

export const Card: React.FC<CardProps> = ({ customCSS, children }) => <div css={[card, customCSS]}>{children}</div>;
