/** @jsxImportSource @emotion/react */
import React, { useEffect, Fragment } from 'react';
import SEGMENT from 'src/constants/segment';
import analytics from 'src/utils/analytics';

import useLanding from 'src/api/landing/useLanding';
import Container from 'src/kit/Container/Container';
import {
  headerCSS,
  bgImgCSS,
  buttonCSS,
  carrierCarouselWrapperCSS,
  descriptionCSS,
  carrierGridWrapperCSS,
  carrierCSS,
  bundleBlockCSS,
  buttonWrapperCSS,
  googleReviewsWrapperCSS,
  cardsWrapperCSS,
  cardWrapperCSS,
  cardsRowWrapperCSS,
  cardLabelCSS,
  dividerCSS,
  cardSectionCSS,
  carouselWrapperCSS,
  cardSectionLabelCSS,
  subHeaderCSS,
  imageDivCSS
} from './CustomizedCarrierLanding.style';
import Button from 'src/kit/Button/Button';
import DividerIcon from 'src/assets/svg/curvy-divider.svg';
import SirvImage from 'src/kit/SirvImage/SirvImage';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useCustomNavigate } from 'src/hooks/useCustomNavigate';
import FAQ from 'src/components/FAQ/FAQ';
import GoogleReviews from 'src/components/Landing/GoogleReviews/GoogleReviews';
import noop from 'src/utils/noop';
import BundleImg from 'src/assets/svg/bundle-images.svg';

import CarriersCarousel from 'src/components/CarriersCarousel/CarriersCarousel';
import useResponsive from 'src/hooks/useResponsive';
import {
  landingCarriersLogoList,
  customizedCarrierLandingDisclosure,
  carrierLandingCards,
  getFAQItems
} from 'src/components/Landing/Landing.utils';

const CustomizedCarrierLanding: React.FC = () => {
  const { search } = useLocation();
  const { isMobile } = useResponsive();
  const navigate = useCustomNavigate();
  const urlParams = queryString.parse(search?.replace('?', ''));
  const { data } = useLanding(urlParams?.carrier ? (urlParams?.carrier as string) : '');
  const carrierName = data?.carrier_name || '';

  useEffect(() => {
    analytics.page(SEGMENT.PAGES.LANDING, {
      partner_key: data?.partner_key
    });
  }, [data]);

  const onFlowSelect = () => {
    analytics.track(SEGMENT.LANDING_COMPARE_QUOTES_CLICKED, undefined, undefined, {
      carrier_name: carrierName
    });
    navigate({
      pathname: '/home',
      search
    });
  };

  return (
    <Fragment>
      <div css={bgImgCSS}>
        <Container>
          <div css={headerCSS}>Compare home insurance quotes in minutes</div>
          <div css={subHeaderCSS}>
            We’ll shop our network of 40+ A-rated carriers (including <b> {carrierName}</b>) to help you find the policy
            that’s right for you.
          </div>
          <Button css={buttonCSS} onClick={onFlowSelect}>
            Compare Quotes Now
          </Button>
        </Container>
      </div>
      {isMobile && <div css={imageDivCSS} />}

      <div>
        <Container>
          <div css={carrierCarouselWrapperCSS}>
            <div>Our network of A-rated insurance carriers</div>

            {isMobile ? (
              <div css={carouselWrapperCSS}>
                <CarriersCarousel />
              </div>
            ) : (
              <div css={carrierGridWrapperCSS}>
                {landingCarriersLogoList.map((carrier, i) => (
                  <div key={i}>
                    <SirvImage css={carrierCSS} url={carrier.url} width={130} alt={carrier.alt} />
                  </div>
                ))}
              </div>
            )}
          </div>
        </Container>

        <div css={cardsWrapperCSS}>
          <Container>
            <div css={cardSectionCSS}>
              <img css={dividerCSS} src={DividerIcon} alt="Divider" />
              <div css={cardSectionLabelCSS}>The independent agency difference</div>
              <div>
                With Matic you get an unbiased approach to insurance that combines a powerful digital experience with an
                agency of trusted advisors.
              </div>

              <div css={cardsRowWrapperCSS}>
                {carrierLandingCards.map((card, i) => (
                  <div css={cardWrapperCSS} key={i}>
                    <img src={card.imgSrc} alt={card.alt} />
                    <div css={cardLabelCSS}>{card.label}</div>
                    <div>{card.description}</div>
                  </div>
                ))}
              </div>

              <Button css={buttonCSS} onClick={onFlowSelect}>
                Compare Quotes Now
              </Button>
            </div>
          </Container>
        </div>

        <div css={googleReviewsWrapperCSS}>
          <Container>
            <GoogleReviews heading="What our customers think" onGoogleReviewsClick={noop} />
          </Container>
        </div>

        <Container>
          <FAQ page={SEGMENT.PAGES.LANDING} accordionItems={getFAQItems()} />

          {false && (
            <Fragment>
              <div css={bundleBlockCSS}>
                <img src={BundleImg} alt="Bundle offer" />
                <div>Bundle and save up to 20% today!*</div>
                <div css={buttonWrapperCSS}>
                  <Button css={buttonCSS} onClick={onFlowSelect}>
                    Start a Quote Now
                  </Button>

                  <Button css={buttonCSS} onClick={onFlowSelect}>
                    Talk to an Advisor
                  </Button>
                </div>
              </div>
              <div css={descriptionCSS}>{customizedCarrierLandingDisclosure}</div>
            </Fragment>
          )}
        </Container>
      </div>
    </Fragment>
  );
};

export default CustomizedCarrierLanding;
