import { css } from '@emotion/react';
import { mq } from 'src/theme/variables';

export const wrapperCSS = css`
  margin: auto;
  box-shadow: 10px 10px 5px #aaaaaa;
  position: relative;
  background-color: white;
  break-after: page;
  margin-bottom: 160px;

  ${mq[0]} {
    width: 794px;
    height: 1123px;
    margin-bottom: 0;
  }

  @media print {
    box-shadow: none;
    width: 794px;
    height: 1123px;
    margin-bottom: 0;
  }
`;

export const headingCSS = css`
  font-size: 30px;
  font-weight: bold;

  ${mq[0]} {
    font-size: 44px;
  }

  @media print {
    font-size: 44px;
  }
`;

export const coveragesWrapperCSS = css`
  padding: 0 24px;
`;

export const headerCopyCSS = css`
  margin-top: 8px;
  font-size: 18px;
  color: #333333;

  ${mq[0]} {
    font-size: 20px;
  }

  @media print {
    font-size: 20px;
  }
`;

export const coverageWrapperCSS = css`
  display: flex;
  align-items: flex-start;
  padding: 12px 0;

  img {
    width: 35px;

    ${mq[0]} {
      width: unset;
    }

    @media print {
      width: unset;
    }
  }
`;

export const descriptionWrapperCSS = css`
  margin-left: 24px;
  font-size: 14px;

  ${mq[0]} {
    font-size: 16px;
  }

  @media print {
    font-size: 16px;
  }
`;

export const labelCSS = css`
  font-size: 22px;
  font-weight: bold;
`;

export const descriptionCSS = css`
  color: #333333;
`;
