import docs from 'src/assets/svg/benefit_documents.svg';
import house from 'src/assets/svg/benefit_house.svg';
import auto from 'src/assets/svg/benefit_auto.svg';
import shield from 'src/assets/svg/benefit_shield.svg';
import { Flows, Greeting } from 'src/interfaces/IPage';
import { Benefit } from 'src/shared/components/Benefits/Benefits.type';
import { ExtendedGreetingProps } from './Greeting.type';

export const extendGreetingProps = (greeting: Greeting, flow: Flows): ExtendedGreetingProps => {
  return {
    ...greeting,
    benefits: greeting.softLanding ? getBenefits(greeting, flow) : []
  };
};

const getBenefits = (greeting: Greeting, flow: Flows): Benefit[] => {
  return [
    {
      title: greeting.column1_title!,
      description: greeting.column1_body!,
      image: docs,
      imageAlt: 'Documents'
    },
    {
      title: greeting.column2_title!,
      description: greeting.column2_body!,
      image: flow === Flows.Auto ? auto : house,
      imageAlt: flow === Flows.Auto ? 'Auto' : 'House'
    },
    {
      title: greeting.column3_title!,
      description: greeting.column3_body!,
      image: shield,
      imageAlt: 'Shield'
    }
  ];
};
