/** @jsxImportSource @emotion/react */
import React from 'react';
import { advisorCardCSS, cardsWrapperCSS, containerCustomCSS } from './FloridaNoCallLanding.style';
import TalkToAdvisorCard from 'src/components/Quotes/TalkToAdvisorSection/TalkToAdvisorCard/TalkToAdvisorCard';
import { useParams } from 'react-router-dom';
import { DefaultLocation } from 'src/interfaces/IPage';
import SEGMENT from 'src/constants/segment';
import analytics from 'src/utils/analytics';
import { Flows } from 'src/interfaces/IPage';

import useConfig from 'src/api/config/useConfig';
import SIRV from 'src/constants/sirv';
import { DEFAULT_PHONE_NUMBER } from 'src/constants/contacts';
import FloridaSpecialistNote from 'src/components/FloridaSpecialistNote/FloridaSpecialistNote';

export interface NoQuotesPageProps {
  userName: string;
  contactPhone: string;
}

const FloridaNoCallLanding: React.FC<NoQuotesPageProps> = ({ userName = '', contactPhone = '' }) => {
  const { gid, flow } = useParams() as DefaultLocation;
  const { data: config } = useConfig(gid);

  const contactPhoneNumber = contactPhone || config?.partner?.agent_phone || DEFAULT_PHONE_NUMBER;

  const onDSAutoRedirect = () => {
    window.location.replace('https://autoinsure.matic.com/auto');
  };

  const onPhoneNumberClick = () => {
    analytics.track(SEGMENT.PHONE_CONVERSATION_INITIATED, gid, flow, {
      session_gid: gid,
      location: SEGMENT.LOCATIONS.AGENT_CARD,
      flow_type: Flows.AllFunnel,
      page: SEGMENT.PAGES_KEY.LANDING
    });
  };

  const cardsArray = (phoneNumber: string) => [
    {
      hasContactPhone: false,
      imgSrc: SIRV.CAR_ON_THE_ROAD,
      imgAlt: 'car-on-the-road-icon',
      title: 'We have a wide variety of auto insurance',
      carriersArray: [
        { src: SIRV.CARRIERS.SAFECO.url, alt: SIRV.CARRIERS.SAFECO.alt },
        { src: SIRV.CARRIERS.PROGRESSIVE.url, alt: SIRV.CARRIERS.PROGRESSIVE.alt },
        { src: SIRV.CARRIERS.TRAVELERS.url, alt: SIRV.CARRIERS.TRAVELERS.alt }
      ],
      buttonLabel: 'Save on My Car Insurance',
      buttonClick: onDSAutoRedirect,
      description:
        'Our auto insurance options in the Miami-Dade area are much more abundant and we’ll ' +
        'automatically re-shop for you every year if you find a policy through us.'
    },
    {
      hasContactPhone: true,
      imgSrc: SIRV.FLORIDA_SIGN,
      imgAlt: 'florida-sign',
      title: 'Upgrade your coverage',
      carriersArray: [
        { src: SIRV.CARRIERS.TOWERHILL.url, alt: SIRV.CARRIERS.TOWERHILL.alt },
        { src: SIRV.CARRIERS.SAGE_SURE.url, alt: SIRV.CARRIERS.SAGE_SURE.alt }
      ],
      buttonLabel: `Call us at ${phoneNumber}`,
      buttonClick: onPhoneNumberClick,
      description:
        'If you’re able to a find minimal home insurance policy we can help supplement to make ' +
        'sure you’re covered for any situation. Whether it’s flood, hurricane or umbrella we can boost your coverage '
    }
  ];

  return (
    <React.Fragment>
      <div css={containerCustomCSS}>
        <FloridaSpecialistNote userName={userName} isNoQuotesVariation />
        <div css={cardsWrapperCSS}>
          {cardsArray(contactPhoneNumber).map((item, index) => (
            <TalkToAdvisorCard
              key={index}
              submitText={item.buttonLabel}
              heading={item.title}
              contactPhone={(item.hasContactPhone && contactPhoneNumber) || ''}
              carriersArray={item.carriersArray}
              onTalkToAdvisorClick={item.buttonClick}
              description={item.description}
              img={item.imgSrc}
              imgAlt={item.imgAlt}
              customCSS={advisorCardCSS}
            />
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default FloridaNoCallLanding;
