import VehicleDetails from './VehicleDetails/VehicleDetails';
import DriverDetails from './DriverDetails/DriverDetails';
import HomeDetails from './HomeDetails/HomeDetails';
import UserDetails from './UserDetails/UserDetails';
import { NavigationSectionDetails } from './Navigation.types';

export const NavigationDetails = {
  [NavigationSectionDetails.Vehicles]: VehicleDetails,
  [NavigationSectionDetails.Drivers]: DriverDetails,
  [NavigationSectionDetails.HomeDetails]: HomeDetails,
  [NavigationSectionDetails.UserDetails]: UserDetails
};
