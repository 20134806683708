import { css, SerializedStyles } from '@emotion/react';
import { colors as c, mq } from 'src/theme/variables';
import { text, text12, text14, text20, text32, text40, text48 } from 'src/theme/typography';

export const carrierSectionCSS = (isPreScoreVariation?: boolean): SerializedStyles => css`
  padding: 20px 24px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: ${c.white};

  ${mq[0]} {
    background: ${c.grayFive};
  }
  ${mq[1]} {
    padding: 24px;
  }

  ${isPreScoreVariation &&
  css`
    background-color: ${c.grayFive};

    ${mq[0]} {
      border-bottom: 1px solid ${c.silver};
    }
  `}
`;

export const carrierRowCSS = (isPreScoreVariation?: boolean): SerializedStyles => css`
  align-items: center;
  display: flex;
  justify-content: space-between;
  ${isPreScoreVariation &&
  css`
    flex-direction: column;

    ${mq[0]} {
      flex-direction: row;
    }
  `}
`;

export const carrierLogoCSS = css`
  max-width: 160px;
  width: 100%;
`;

export const carrierDesktopPremiumsCSS = (isPreScoreVariation?: boolean): SerializedStyles => css`
  align-items: center;
  display: none;
  flex-direction: column;
  padding-top: 16px;

  ${mq[0]} {
    display: flex;
  }

  ${isPreScoreVariation &&
  css`
    display: flex;
    ${text14};
    align-items: center;
    padding-top: 12px;

    ${mq[0]} {
      align-items: start;
    }

    div {
      margin: 2px 0;
    }
  `}
`;

export const carrierMobilePremiumsCSS = css`
  display: flex;
  flex-direction: column;
  ${mq[0]} {
    flex-direction: row;
    display: none;
  }
`;

export const carrierOldPremiumCSS = css`
  ${text12};
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 5px 0;
  font-weight: 400;
  ${mq[0]} {
    ${text14};
    align-items: center;
  }
`;

export const oldPremiumValueCSS = css`
  ${text};
  ${mq[0]} {
    ${text14};
  }
`;

export const carrierUpdatedPremiumCSS = css`
  ${carrierOldPremiumCSS};
  ${text};
  align-items: flex-end;
  ${mq[0]} {
    ${text20};
    align-items: center;
  }
`;

export const dottedLineCSS = css`
  flex: 1;
  border-bottom: 1px dashed ${c.nobel};
`;

export const rectangleIconCSS = css`
  margin-right: 4px;
`;

export const carrierSavingMsgCSS = css`
  ${text14};
  font-weight: normal;
  text-align: center;

  ${mq[0]} {
    ${text};
  }
`;

export const carrierSavingCSS = css`
  ${text32};
  color: ${c.christi};
  font-weight: 700;

  ${mq[0]} {
    ${text40};
    line-height: 56px;
  }
`;

export const saveTextCSS = css`
  font-weight: 400;
  ${text14};

  ${mq[0]} {
    ${text};
    font-weight: 700;
    line-height: 24px;
  }
`;

export const saveTextExperimentStyleCSS = css`
  color: ${c.christi};
`;

export const preScoreSavingCSS = css`
  color: ${c.darkGreen};
  font-weight: 700;
  ${text40};
  margin-top: 12px;

  ${mq[0]} {
    margin-top: 0;
  }
`;

export const savingsCSS = css`
  font-weight: 900;
  ${text48};
`;

export const descriptionCSS = css`
  ${text14};
`;

export const scrollLinkCSS = css`
  ${text12};
  cursor: pointer;
  margin-top: 12px;
  color: ${c.azure};
  text-decoration: underline !important;
  text-transform: none;
`;
