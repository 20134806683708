/** @jsxImportSource @emotion/react */
import React from 'react';

import { footer, copyright, logo as logoCSS, subtitle, disclosures, footerRow, confettiCSS } from './Footer.style';
import Container from 'src/kit/Container/Container';
import FooterLinks from './FooterLinks/FooterLinks';

import logo from 'src/assets/svg/logo.svg';
import { FooterProps } from './types';
import noop from 'src/utils/noop';

const Footer: React.FC<FooterProps> = ({ copy, phone, withConfetti = false, onSocialLinkClick = noop, customCSS }) => {
  return (
    <footer css={[customCSS, footer]}>
      {withConfetti && <div data-testid="footer-confetti" css={confettiCSS} />}
      <Container>
        {copy && <div css={disclosures}>{copy}</div>}
        <img src={logo} css={logoCSS} alt="Matic Insurance" loading="lazy" />
        <div css={subtitle}>
          We are a Digital Insurance Agency That Empowers You to Simplify Your Whole World of Insurance
        </div>
        <div css={footerRow}>
          <div css={copyright}>© 2014 – {new Date().getFullYear()} Matic Insurance Services, Inc.</div>
          <div className="c-footer__links">
            <FooterLinks phone={phone} onSocialLinkClick={onSocialLinkClick} />
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
