import { Fact } from './WhyTrustMatic.props';
import Format from 'src/utils/format';

export const getWhyMaticFacts = (savings: number | null, isNewRez?: boolean): Fact[] => [
  {
    heading: '40+ A-Rated Carriers',
    description: ['We only partner with the best because we ', 'know they’ll be there for you.']
  },
  {
    heading: isNewRez ? 'Zero hidden fees' : 'Unbiased Support',
    description: ['We’re not tied to one carrier, so you can get price from our network of over 40 A-rated carriers.']
  },
  {
    heading: `${Format.money(savings ? savings : 321, 0, '$')}* per year`,
    description: ['The average amount of money Matic ', 'saved homeowners last year.']
  },
  {
    heading: '5M+ Homes',
    description: ['Trusted by the largest mortgage ', 'companies in the U.S.']
  }
];
