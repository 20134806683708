import { UseBaseMutationResult, useMutation, useQueryClient } from 'react-query';
import { ResponseError, ResponseSuccess } from 'src/interfaces/IResponse';
import api from 'src/api/api';
import { QUERY_CACHE_KEYS } from 'src/constants/queryCacheKeys';
import { API_URLS } from 'src/constants/api';
import { getApiUrl } from 'src/utils/api.helpers';

const useMutatePersonPhone = (
  gid: string,
  refetchQueries: string[][] = []
): UseBaseMutationResult<ResponseSuccess, ResponseError, string> => {
  const client = useQueryClient();
  return useMutation((phone: string) => api.put(getApiUrl(API_URLS.AF_MUTATE_PERSON, gid), { body: { phone } }), {
    onSuccess: async () =>
      await Promise.all([QUERY_CACHE_KEYS.QUESTIONS, ...refetchQueries].map(key => client.refetchQueries(key)))
  });
};

export default useMutatePersonPhone;
