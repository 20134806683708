/** @jsxImportSource @emotion/react */
import React from 'react';

import {
  inputWrapperCSS,
  consentCSS,
  underlineCSS,
  errorCSS,
  wrapperCSS,
  disclosuresCheckboxCSS
} from './DisclosureCheckBox.style';
import Consent from 'src/components/Consent/Consent';
import { QUESTION_KEYS } from 'src/constants/hardcoded';
import Checkbox from 'src/shared/components/Checkbox/Checkbox';
import { DisclosureCheckBoxProps } from './DisclosureCheckBox.types';
import ValidationError from 'src/shared/components/ValidationError/ValidationError';
import noop from 'src/utils/noop';
import { Tooltip } from '@mui/material';
import { tooltipArrowCSS, tooltipCSS } from 'src/shared/components/Tooltip/Tooltip.style';
import useResponsive from 'src/hooks/useResponsive';
import { isTouchEnabled } from 'src/utils/isTouchEnabled';

const DisclosureCheckBox: React.FC<DisclosureCheckBoxProps> = ({
  customCSS = {},
  CTAText,
  hasError,
  onClick,
  isAccepted,
  onTermsOfServiceClicked = noop,
  consentNode,
  customErrorMessage = 'This field is required'
}) => {
  const { isMobile } = useResponsive();
  const isTouch = isTouchEnabled();

  const handleTermsOfUseClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    onTermsOfServiceClicked();
  };

  // TODO: Need to find a better way how to render consent. Consent component use specific API.
  //  We need to avoid it inside Question Form components
  const getConsentBlock = () =>
    consentNode || <Consent customCSS={[consentCSS, customCSS.consent]} CTAText={CTAText} />;

  const label = (): JSX.Element => {
    const termsButton = (
      <button css={underlineCSS} onClick={handleTermsOfUseClick}>
        Terms of Service
      </button>
    );
    return (
      <div css={customCSS.label}>I agree with {isMobile || isTouch ? termsButton : termsWithTooltip(termsButton)}</div>
    );
  };

  const termsWithTooltip = (termsButton: JSX.Element) => (
    <Tooltip
      title={getConsentBlock()}
      arrow
      componentsProps={{
        tooltip: { sx: { ...tooltipCSS, maxWidth: '550px' } },
        arrow: { sx: { ...tooltipArrowCSS } }
      }}
    >
      {termsButton}
    </Tooltip>
  );

  return (
    <div css={[wrapperCSS, customCSS.wrapper]}>
      <Checkbox
        customCSS={{ label: inputWrapperCSS, container: disclosuresCheckboxCSS }}
        key={QUESTION_KEYS.TERMS_OF_SERVICE}
        label={label()}
        alt="Terms of service checkbox"
        checked={isAccepted}
        hasError={hasError}
        onChange={event => {
          onClick(event.target.checked);
        }}
      />
      <ValidationError customCSS={errorCSS} heading="Terms of Service" visible={hasError}>
        {customErrorMessage}
      </ValidationError>
    </div>
  );
};

export default DisclosureCheckBox;
