import { Flows } from 'src/interfaces/IPage';

export const AUTOMATION_TEST_IDS = {
  LANDING_HOME_GET_QUOTES: 'landing-home-get-quotes',
  LANDING_AUTO_GET_QUOTES: 'landing-auto-get-quotes',
  LANDING_AUTO_BUNDLE_GET_QUOTES: 'landing-auto-bundle-get-quotes',
  PATHFINDER_INTERSTITIAL_SLIDER_PRICE_OF_POLICY: 'pathfinder-interstitial-slider-price-of-policy',
  PATHFINDER_INTERSTITIAL_SLIDER_DIGITAL_EXPERIENCE: 'pathfinder-interstitial-slider-digital-experience',
  PATHFINDER_INTERSTITIAL_SLIDER_CUSTOMER_SERVICE: 'pathfinder-interstitial-slider-customer-service',
  PATHFINDER_INTERSTITIAL_SLIDER_CLAIM_PROCESS: 'pathfinder-interstitial-slider-claim-process',
  AUTO_BUNDLE_QUOTE_ANNUAL_PREMIUM_VALUE: 'auto-bundle-quote-annual-premium-value',
  AUTO_BUNDLE_QUOTE_MONTHS_PREMIUM_VALUE: 'auto-bundle-quote-months-premium-value',
  INTERSTITIAL_SAVINGS_GET_QUOTES_BUTTON: 'interstitial-savings-get-quotes-button',
  LOOKUP_DIALOG_SUBMIT_BUTTON: 'lookup-dialog-submit',
  DRAG_AND_DROP_INPUT: 'drag-and-drop',
  HOME_BUNDLE_OFFER_HOME_QUOTES_BUTTON: 'home-bundle-offer-home-quotes-button',
  HOME_BUNDLE_OFFER_BUNDLE_QUOTES_BUTTON: 'home-bundle-offer-bundle-quotes-button',
  QUOTES_PAGE: {
    HEADING: 'quotes-page-heading',
    ADDRESS: 'quotes-page-address'
  },
  SKIP_TO_HOME_QUOTES_BUTTON: 'skip-to-home-quotes-button',
  SINGLE_QUOTE: {
    CARD: 'single-quote-card',
    CHOOSE_QUOTE_BUTTON_ON_CARD: 'choose-quote-button-on-single-quote-card',
    SEE_OTHER_QUOTES_BUTTON: 'see-other-quotes-button',
    NEW_PREMIUM_VALUE: 'new-premium-value',
    NEW_PREMIUM: 'new-premium',
    CURRENT_PREMIUM_VALUE: 'current-premium-value',
    CURRENT_PREMIUM: 'current-premium',
    LEAD_NAME: 'lead-name',
    LEAD_ADDRESS: 'lead-address',
    COVERAGE_DETAILS_BUTTON: 'coverage-details-button',
    CHOOSE_QUOTE_BUTTON_ON_COVERAGE_POPUP: 'choose-quote-button-on-coverage-popup',
    CLOSE_BUTTON_ON_COVERAGE_POPUP: 'close-button-on-coverage-popup'
  },
  QUOTE: {
    CARD: 'quote-card',
    SELECT_BUTTON: 'quote-card-action-button',
    PREMIUM: 'premium-value'
  },
  DISCLOSURES: 'disclosures',
  DISCLAIMERS: 'disclaimers',
  CALL_MATIC_ADVISOR: 'call-matic-advisor',
  USER_PROFILE: 'user-profile'
};

export const getGetQuotesLandingTestId = (flow: Flows): string => {
  let testId = '';
  switch (flow) {
    case Flows.Home:
      testId = AUTOMATION_TEST_IDS.LANDING_HOME_GET_QUOTES;
      break;
    case Flows.Auto:
      testId = AUTOMATION_TEST_IDS.LANDING_AUTO_GET_QUOTES;
      break;
    case Flows.AutoBundle:
      testId = AUTOMATION_TEST_IDS.LANDING_AUTO_BUNDLE_GET_QUOTES;
      break;
  }
  return testId;
};
