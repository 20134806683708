/** @jsxImportSource @emotion/react */
import { AreaChart, YAxis, ResponsiveContainer } from 'recharts';
import { CustomComponentArea, useStaticTooltip } from './addon';
import CustomChartTooltip, { CustomTooltipTypes } from './CustomChartTooltip';
import CustomizedDot from './CustomizedDot';
import { ChartDataDto, PremiumTypeDto } from 'src/interfaces/session.types';
import useResponsive from 'src/hooks/useResponsive';
import { chartLabelCSS } from 'src/components/HistoricalPremium/HistoricalPremium.style';
import { historicalChartFakePaddingCSS, historicalPremiumChartWrapperCSS } from './HistoricalPremiumChart.style';

const HistoricalPremiumChart: React.FC<{ chartData?: ChartDataDto; premiumType?: PremiumTypeDto }> = ({
  chartData,
  premiumType
}) => {
  const initialTooltip = useStaticTooltip();
  const { isMobile } = useResponsive();

  if (!chartData) return null;

  const values = chartData.map(item => ({ value: item[1] }));
  const labels = chartData.map(item => item[0]);
  const getMaxValue = () => values.reduce((max, obj) => (+obj.value > +max ? obj.value : max), values[0].value);
  const getMinValue = () => values.reduce((min, obj) => (+obj.value < +min ? obj.value : min), values[0].value);

  const maxValue = getMaxValue();
  const minValue = getMinValue();

  const getPointType = (i: number, value: string): CustomTooltipTypes => {
    let type = i === 0 ? 'first' : i === initialTooltip.staticPointItem.length - 1 ? 'last' : 'default';
    if (value === maxValue) type += 'Highest';

    return type as CustomTooltipTypes;
  };

  return (
    <div css={historicalPremiumChartWrapperCSS}>
      {initialTooltip.staticPointItem &&
        initialTooltip.staticPointItem.map((item, index) => (
          <CustomChartTooltip
            data={item}
            isEstimatedValue={initialTooltip.staticPointItem.length - 1 === index}
            key={`tooltip-${index}`}
            type={getPointType(index, item.payload.value)}
            premiumType={premiumType}
          />
        ))}
      {initialTooltip.staticPointItem &&
        !isMobile &&
        initialTooltip.staticPointItem.map((item, index) => (
          <div css={chartLabelCSS(item.x - 15)} key={`label-${index}`}>
            {labels[index]}
          </div>
        ))}
      <ResponsiveContainer width="100%" height={295}>
        <AreaChart
          width={730}
          height={250}
          data={values}
          margin={{ top: 50, left: 0, right: 0, bottom: 0 }}
          {...{
            overflow: 'visible'
          }}
        >
          <YAxis type="number" domain={[+minValue, +maxValue]} hide />
          <CustomComponentArea
            type="linear"
            dataKey="value"
            stroke="rgb(21, 110, 234)"
            fill="#156EEA"
            fillOpacity="0.2"
            strokeWidth={2}
            dot={<CustomizedDot />}
            onUpdatePoints={initialTooltip.setPoints}
          />
          <CustomizedDot />
        </AreaChart>
      </ResponsiveContainer>
      <div css={historicalChartFakePaddingCSS} />
    </div>
  );
};

export default HistoricalPremiumChart;
