import { setKey } from 'react-geocode';
import { googleApiKey } from 'src/constants/services';
import { Loader } from '@googlemaps/js-api-loader';

setKey(googleApiKey);

const loader = new Loader({
  apiKey: googleApiKey,
  version: 'weekly',
  libraries: ['places', 'geometry']
});

export default {
  loader
};
