import { css } from '@emotion/react';
import { text, text14, text20, text24, text32, text40 } from 'src/theme/typography';
import { mq, colors as c } from 'src/theme/variables';

export const cardCSS = css`
  border-radius: 8px;
  position: relative;
  border: 1px solid ${c.mercury};
  text-align: center;

  ${mq[0]} {
    border-radius: 8px;
    border-right-width: 1px;
    border-left-width: 1px;
    margin-left: 0;
    margin-right: 0;
    padding: 24px;
    padding-top: 0;
  }
`;

export const submitButtonCSS = css`
  display: block;
  width: 90%;
  margin: auto;
  font-variant-numeric: lining-nums;
  ${text};
  height: 44px;
  margin-bottom: 24px;

  img {
    margin-right: 6px;
    width: 16px;
  }

  ${mq[0]} {
    height: 60px;
    ${text20};
    width: 100%;
    margin-bottom: 0;

    img {
      width: 24px;
    }
  }
`;

export const privacyMattersCSS = css`
  div {
    text-align: center;
    color: ${c.mineshaft};
  }
  padding: 0 24px 24px;
  ${mq[0]} {
  }
`;

export const savingsBlockCSS = css`
  background-color: ${c.honeydew};
  padding: 16px 20px;
  margin-top: 24px;
  text-align: left;
  ${text};

  ${mq[0]} {
    ${text20};
  }
`;

export const savingsValueCSS = css`
  font-weight: 700;
  color: ${c.darkGreen};
`;

export const coveragesCSS = css`
  margin-top: 16px;
  padding: 0 24px;
  ${mq[0]} {
    padding: 0;
  }
`;

export const coverageCSS = css`
  &:first-of-type {
    margin-bottom: 16px;
  }
  margin-bottom: 24px;
  ${text};

  ${mq[0]} {
    ${text20};
  }
`;

export const estimationCSS = css`
  color: ${c.dove};
  text-align: center;
  margin-top: 5px;
  ${text};

  ${mq[0]} {
    ${text20};
  }
`;

export const premiumBlockCSS = css`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const premiumValueCSS = css`
  font-weight: 700;
  ${text32};

  ${mq[0]} {
    ${text40};
  }
`;

export const perYearCSS = css`
  font-weight: 700;
  ${text24};

  ${mq[0]} {
    ${text32};
  }
`;

export const coveragesButtonCSS = css`
  font-weight: 700;
  ${text};

  ${mq[0]} {
    ${text20};
  }
`;

export const incompleteAutoBlockCSS = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  padding: 20px;
  background-color: ${c.babyBlue};
  margin-top: 24px;
  ${text14};
`;

export const incompleteAutoHeadingCSS = css`
  margin-bottom: 8px;

  ${mq[0]} {
    ${text};
  }
`;

export const autoIconCSS = css`
  margin-right: 15px;
`;

export const houseIconCSS = css`
  margin-right: 8px;
  padding-top: 5px;
`;

export const elipseCSS = css`
  background-color: ${c.white};
  height: 40px;
  width: 100%;
  clip-path: ellipse(53% 66% at 50% 100%);

  ${mq[0]} {
    clip-path: ellipse(50% 82% at 50% 100%);
  }
`;

export const cardImageContainerCSS = css`
  position: relative;
  background-color: ${c.babyBlue};
  margin-bottom: -12px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  ${mq[0]} {
    margin: 0 -24px;
  }
`;

export const carrierLogoCSS = css`
  width: 100%;
  margin-top: 30px;
  margin-bottom: 15px;
  max-width: 100px;

  ${mq[0]} {
    max-width: 120px;
  }
`;

export const headerImagesContainerCSS = css`
  display: flex;
  justify-content: center;
`;

export const bundleCarIconCSS = css`
  width: 90px;
  bottom: -3px;
  position: absolute;
  transform: translateX(120%);

  ${mq[0]} {
    bottom: -2px;
    width: 115px;
  }
`;
export const bundleHomeIconCSS = css`
  width: 80px;
  bottom: 5px;
  position: absolute;
  transform: translateX(-135%);

  ${mq[0]} {
    width: 100px;
  }
`;
