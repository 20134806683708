/** @jsxImportSource @emotion/react */
import React, { Fragment } from 'react';
import { QuotesUserProfileHomeDetails, QuotesUserProfileProps } from './QuotesUserProfile.types';
import {
  addressCSS,
  carrierCSS,
  containerCSS,
  currentPolicyWrapperCSS,
  detailsItemCSS,
  detailsWrapperCSS,
  headingCSS,
  homeDetailsWrapperCSS,
  spaceCSS,
  userCSS,
  policyExtendedWrapperCSS,
  profileHeadingCSS,
  heartCSS,
  lineCSS,
  bracketsCSS,
  tooltipCSS,
  smallHeadingCSS,
  headerWrapperCSS
} from './QuotesUserProfile.style';
import { Card } from 'src/shared/components/Card/Card';
import { FS_SENSITIVE_DATA_CLASS } from 'src/constants/fullStory';
import UserProfileDetailsItem from './UserProfileDetailsItem/UserProfileDetailsItem';
import useResponsive from 'src/hooks/useResponsive';
import { getReadableAddress } from 'src/utils/homeDetails';
import Format from 'src/utils/format';
import UserProfileDetailsIconList from './UserProfileDetailsIconList/UserProfileDetailsIconList';
import { ICurrentPolicy } from 'src/interfaces/IQuotes';
import Heart from 'src/assets/svg/life.svg';
import Brackets from 'src/assets/svg/brackets.svg';
import Tooltip from 'src/assets/svg/tooltip.svg';
import { AUTOMATION_TEST_IDS } from 'src/constants/automationTestIds';

const QuotesUserProfile: React.FC<QuotesUserProfileProps> = ({
  customCSS,
  firstName,
  lastName,
  carrierName,
  address,
  currentPolicy,
  homeDetails,
  propertyInformation = [],
  discounts = [],
  shouldRenderCallMeNow = false,
  shouldRenderStepsExtended = false,
  shouldRenderNA = false
}) => {
  const { isDesktop } = useResponsive();

  const renderCurrentPolicyDetails = ({ premium, deductible, dwelling, expiration_date }: ICurrentPolicy) => (
    <Fragment>
      {(!!premium || shouldRenderNA) && (
        <UserProfileDetailsItem
          className={FS_SENSITIVE_DATA_CLASS.MASK}
          customCSS={detailsItemCSS}
          fullWidth
          label="Premium"
          value={!premium ? 'n/a' : Format.money(premium!, 0, '$')}
          valueSuffix={!premium ? '' : '/yr'}
        />
      )}
      {(!!deductible || shouldRenderNA) && (
        <UserProfileDetailsItem
          className={FS_SENSITIVE_DATA_CLASS.MASK}
          customCSS={detailsItemCSS}
          fullWidth
          label="Deductible"
          value={!deductible ? 'n/a' : Format.money(deductible!, 9, '$')}
        />
      )}
      {(!!dwelling || shouldRenderNA) && (
        <UserProfileDetailsItem
          className={FS_SENSITIVE_DATA_CLASS.MASK}
          customCSS={detailsItemCSS}
          fullWidth
          label="Dwelling"
          value={!dwelling ? 'n/a' : Format.money(dwelling!, 9, '$')}
        />
      )}

      {!!expiration_date && !shouldRenderCallMeNow && (
        <UserProfileDetailsItem
          customCSS={detailsItemCSS}
          fullWidth
          label="Renewal"
          value={Format.date(expiration_date as string)}
        />
      )}
    </Fragment>
  );

  const renderHomeDetails = ({ yearBuilt, squareFeet, stories }: QuotesUserProfileHomeDetails) => (
    <Fragment>
      {(!!yearBuilt || shouldRenderNA) && (
        <UserProfileDetailsItem customCSS={detailsItemCSS} fullWidth label="Year Built" value={yearBuilt ?? 'n/a'} />
      )}
      {(!!squareFeet || shouldRenderNA) && (
        <UserProfileDetailsItem customCSS={detailsItemCSS} fullWidth label="Square Feet" value={squareFeet ?? 'n/a'} />
      )}
      {(!!stories || shouldRenderNA) && (
        <UserProfileDetailsItem
          customCSS={detailsItemCSS}
          fullWidth
          label="Stories"
          value={!stories && shouldRenderNA ? 'n/a' : Number(stories!)}
        />
      )}
    </Fragment>
  );

  return (
    <Card customCSS={[containerCSS, customCSS]}>
      <div data-testid={AUTOMATION_TEST_IDS.USER_PROFILE}>
        {shouldRenderStepsExtended ? (
          <div>
            <img css={tooltipCSS} src={Tooltip} alt="Tooltip" />
            {isDesktop && <img css={bracketsCSS} src={Brackets} alt="Brackets" />}
            <div css={profileHeadingCSS}>
              <img css={heartCSS} src={Heart} alt="Heart" />

              <div>
                {isDesktop && <div>Your Profile</div>}
                <div css={smallHeadingCSS} className={FS_SENSITIVE_DATA_CLASS.MASK}>{`${firstName} ${lastName}`}</div>
              </div>
            </div>

            <hr css={lineCSS} />
            <div>{carrierName}</div>
            <div>
              <div css={policyExtendedWrapperCSS} className={FS_SENSITIVE_DATA_CLASS.MASK}>
                {!!currentPolicy?.premium && renderCurrentPolicyDetails(currentPolicy)}
              </div>
            </div>
          </div>
        ) : (
          <Fragment>
            {shouldRenderCallMeNow ? (
              <Fragment>
                <div css={headerWrapperCSS}>
                  <span css={headingCSS}>Your Profile</span>
                  <span
                    className={FS_SENSITIVE_DATA_CLASS.MASK}
                    css={userCSS(shouldRenderCallMeNow)}
                  >{`${firstName} ${lastName}`}</span>
                </div>
                <hr />
              </Fragment>
            ) : (
              <Fragment>
                <div css={headingCSS}>Your Profile</div>
                <div className={FS_SENSITIVE_DATA_CLASS.MASK} css={userCSS()}>{`${firstName} ${lastName}`}</div>
              </Fragment>
            )}

            <div css={detailsWrapperCSS(shouldRenderCallMeNow)}>
              {!!currentPolicy?.premium && (
                <div css={currentPolicyWrapperCSS(shouldRenderCallMeNow)}>
                  <div css={carrierCSS}>{carrierName}</div>
                  <div className={FS_SENSITIVE_DATA_CLASS.MASK}>{renderCurrentPolicyDetails(currentPolicy)}</div>
                </div>
              )}
              <div css={homeDetailsWrapperCSS(shouldRenderCallMeNow)}>
                <div
                  className={FS_SENSITIVE_DATA_CLASS.MASK}
                  css={addressCSS}
                  data-testid={AUTOMATION_TEST_IDS.QUOTES_PAGE.ADDRESS}
                >
                  <span role="img" aria-label="House">
                    🏡
                  </span>{' '}
                  {getReadableAddress(address)}
                </div>
                {renderHomeDetails(homeDetails)}
              </div>
            </div>

            <div css={spaceCSS(!!discounts?.length || !!propertyInformation?.length)} />
            {!!propertyInformation?.length && (
              <UserProfileDetailsIconList header="Property Information" list={propertyInformation} />
            )}
            {!!discounts?.length && <UserProfileDetailsIconList header="Discounts" list={discounts} />}
          </Fragment>
        )}
      </div>
    </Card>
  );
};

export default QuotesUserProfile;
