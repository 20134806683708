import { useDetectAdBlock } from 'adblock-detect-react';
import { useState, useEffect, useRef } from 'react';
import adBlockerService from 'src/api/adBlocker/adBlocker.service';

const useTrackAdBlocker = (sessionGid?: string): void => {
  const adBlockDetected = useDetectAdBlock();
  const adBlockReported = useRef(false);
  const [adBlockStatus, setAdBlockStatus] = useState<null | boolean>(null);

  setTimeout(() => {
    setAdBlockStatus(adBlockDetected);
  }, 3000);

  useEffect(() => {
    if (typeof adBlockStatus === 'boolean' && sessionGid && !adBlockReported.current) {
      try {
        adBlockerService.trackAdBlockerUsage(sessionGid, adBlockDetected);
      } catch (error) {}
      adBlockReported.current = true;
    }
  }, [adBlockStatus, adBlockDetected, sessionGid]);
};

export default useTrackAdBlocker;
