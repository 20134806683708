import { SerializedStyles } from '@emotion/react';

export enum QuoteLogoSize {
  SMALL = 'small',
  DEFAULT = 'default',
  BIG = 'big',
  AVERAGE = 'average'
}

export interface QuoteLogoProps {
  customCSS?: SerializedStyles;
  size?: QuoteLogoSize;
  logoUrl: string;
  logoLabel: string;
}
