/** @jsxImportSource @emotion/react */
import React from 'react';

import { benefits as benefitsCSS, benefitsItem, description, img, imgWrapper, info, title } from './Benefits.style';
import { BenefitsProps } from './Benefits.type';

const Benefits: React.FC<BenefitsProps> = ({ benefits }) => (
  <div css={benefitsCSS}>
    {benefits.map((b, i) => (
      <div css={benefitsItem} key={i}>
        <div css={imgWrapper}>
          <img css={img} src={b.image} alt={b.imageAlt} />
        </div>
        <div css={info}>
          <div css={title}>{b.title}</div>
          <div css={description}>{b.description}</div>
        </div>
      </div>
    ))}
  </div>
);

export default Benefits;
