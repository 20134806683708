import React from 'react';
import { FormQuestionProps } from 'src/interfaces/IQuestion';

import { QuestionWrapper } from 'src/questionsForm/components/QuestionWrapper/QuestionWrapper';
import QuestionInput from 'src/questionsForm/components/Question/QuestionInput/QuestionInput';
import DriverLicenseInput from 'src/questionsForm/components/Question/DriverLicenseInput/DriverLicenseInput';

const TextInputQuestion: React.FC<FormQuestionProps> = props => {
  const shouldRenderDriverLicenseInput = props.key_name.includes('driver_license_number');

  return (
    <QuestionWrapper {...props} inputComponent={shouldRenderDriverLicenseInput ? DriverLicenseInput : QuestionInput} />
  );
};

export default TextInputQuestion;
