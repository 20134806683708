import { Flows } from 'src/interfaces/IPage';

const getFSCurrentSessionURL = (): string | null => {
  if (!(window as any).FS?.getCurrentSessionURL || typeof (window as any).FS.getCurrentSessionURL !== 'function') {
    return null;
  }
  return (window as any).FS?.getCurrentSessionURL(false);
};

export default {
  init: (segmentKey: string): void =>
    (window as any).analytics.initialized || (window as any).analytics.load(segmentKey),
  identify: (...args: any[]): void => (window as any).analytics && (window as any).analytics.identify(...args),
  track: (
    eventName: string,
    gid?: string,
    flowType?: Flows,
    config: Record<string, any> = {},
    ...args: any[]
  ): void => {
    const configWithGeneric = { session_gid: gid, flow_type: flowType, ...config };

    return (window as any).analytics && (window as any).analytics.track(eventName, configWithGeneric, ...args);
  },
  page: (pageName: string, ...args: any[]): void =>
    (window as any).analytics && (window as any).analytics.page(pageName, ...args),
  getFSUrl: (): Promise<string | null> => {
    return new Promise(resolve => {
      const timeout = setTimeout(() => {
        resolve(null);
      }, 10000);

      const url = getFSCurrentSessionURL();

      if (url) {
        clearTimeout(timeout);
        resolve(url);
      } else {
        (window as any)._fs_ready = () => {
          const readyUrl = getFSCurrentSessionURL();
          clearTimeout(timeout);
          resolve(readyUrl);
        };
      }
    });
  },
  generateTrackEmail: (personGid: string): string => {
    return `${personGid}@matic.gid`;
  }
};
