import React from 'react';

import { Controller, RegisterOptions } from 'react-hook-form';

import AsyncSelect from './AsyncSelect';
import { QuestionWrapper } from 'src/questionsForm/components/QuestionWrapper/QuestionWrapper';
import { FormQuestionProps, Option } from 'src/interfaces/IQuestion';

const validateAsyncOption = (option: Option) => (option && !!option.value) || 'This field is required';
const rules = { validate: validateAsyncOption };

const AsyncSelectQuestion: React.FC<FormQuestionProps> = props => (
  <Controller
    name={props.key_name}
    rules={rules as RegisterOptions}
    render={({ field: { onChange, value, onBlur, ref } }) => {
      const onSelectChange = (v: Option) => {
        onChange(v);
        onBlur();
      };

      return (
        <QuestionWrapper
          {...props}
          inputComponent={AsyncSelect}
          onValidEntry={onSelectChange}
          value={value}
          componentRef={ref}
          onBlur={onBlur}
        />
      );
    }}
  />
);
export default AsyncSelectQuestion;
