import { css, SerializedStyles } from '@emotion/react';
import { mq, colors } from 'src/theme/variables';
import { text20, text40, text24, text } from 'src/theme/typography';

export const containerCSS = css`
  max-width: 1056px;
  padding: 32px 16px;

  margin: auto;
  ${text};

  ${mq[0]} {
    ${text20};
    text-align: center;
    padding: 80px 40px;
  }
`;

export const rowWrapperCSS = css`
  display: flex;
  margin-bottom: 32px;
  flex-direction: column;
  align-items: center;
  position: relative;

  ${mq[0]} {
    position: unset;
    margin-bottom: 100px;
    flex-direction: row;
  }
`;

export const headingWrapperCSS = css`
  min-width: 61%;
  font-weight: bold;
  text-align: left;
  ${text24};
`;

export const subHeaderCSS = css`
  ${text20};

  ${mq[0]} {
    ${text24};
    max-width: 550px;
  }
`;

export const headingCSS = css`
  ${text24};
  font-weight: 700;
  margin-bottom: 12px;

  ${mq[0]} {
    ${text40};
    margin-bottom: 20px;
  }
`;

export const dividerCSS = css`
  width: 100%;
  margin: 20px 0;

  ${mq[0]} {
    width: unset;
    margin: 0 52px 0 32px;
  }
`;

export const descriptionWrapperCSS = css`
  color: ${colors.dove};

  ${mq[0]} {
    margin-top: 0;
    text-align: center;
    max-width: 260px;
  }
`;

export const cardWrapperCSS = css`
  flex-direction: column-reverse;
  margin-top: 12px;
  padding: 16px;
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid ${colors.nobel};
  box-shadow: 2px 4px 0px 0px rgba(0, 0, 0, 0.12);

  ${mq[0]} {
    flex-direction: row;
    padding: 40px;
  }

  img {
    max-width: 86%;
    margin-bottom: 10px;

    ${mq[0]} {
      max-width: 45%;
      margin-bottom: 0;
    }

    ${mq[1]} {
      max-width: unset;
    }
  }
`;

export const itemCSS = css`
  margin-bottom: 20px;
  ${text};
  color: ${colors.dove};

  ${mq[0]} {
    ${text20};
    margin-bottom: 32px;
    max-width: 520px;
    margin-right: 40px;
  }
`;

export const itemHeadingCSS = (isSecond?: boolean): SerializedStyles => css`
  color: ${colors.black};
  margin-bottom: 12px;
  font-weight: bold;
  ${text20};

  ${mq[0]} {
    ${text24};
  }

  ${isSecond &&
  css`
    ${text};

    ${mq[0]} {
      ${text20};
    }
  `};
`;

export const subHeadingCSS = css`
  font-weight: bold;
`;
