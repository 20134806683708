/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from 'react';
import useResponsive from 'src/hooks/useResponsive';
import { cardCSS, sessionTimeoutWrapperCSS } from './SessionTimeout.style';
import SIRV from 'src/constants/sirv';
import { useParams } from 'react-router-dom';
import { DefaultLocation } from 'src/interfaces/IPage';
import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';
import { useCustomNavigate } from 'src/hooks/useCustomNavigate';
import { UNLOCK_ATTEMPTS_LIMIT } from './SessionTimeout.utils';
import { sessionService } from 'src/api/session';
import { SessionUnlock } from 'src/interfaces/session.types';
import ForeverLockedCardContent from './ForeverLockedCardContent/ForeverLockedCardContent';
import UnlockCardContent from './UnlockCardContent/UnlockCardContent';
import { Card } from 'src/shared/components/Card/Card';
import useTrackFullStorySession from 'src/hooks/useTrackFullStorySession';
import useTrackAdBlocker from 'src/hooks/useTrackAdBlocker';

const SessionTimeout: React.FC = () => {
  const { flow, gid } = useParams() as DefaultLocation;
  const navigate = useCustomNavigate();
  const { isMobile } = useResponsive();
  const [foreverLocked, setForeverLocked] = useState<SessionUnlock>();

  useTrackFullStorySession(gid);
  useTrackAdBlocker(gid);

  const onStartNewSessionConfirmed = async () => {
    analytics.track(SEGMENT.INACTIVE_SESSION_NEW_STARTED, gid, flow);

    const result = await sessionService.startOver(gid);

    navigate(`/${result.flow}/${result.gid}`);
  };

  useEffect(() => {
    (async () => {
      const result = await sessionService.lockStatus(gid);

      result?.locked_attempts === UNLOCK_ATTEMPTS_LIMIT && setForeverLocked(result);
    })();
  }, [gid]);

  return (
    <div css={sessionTimeoutWrapperCSS(isMobile ? SIRV.BLURRED_MOBILE_BACKGROUND : SIRV.BLURRED_DESKTOP_BACKGROUND)}>
      <Card customCSS={cardCSS}>
        {foreverLocked ? (
          <ForeverLockedCardContent locked={foreverLocked} startOver={onStartNewSessionConfirmed} />
        ) : (
          <UnlockCardContent startOver={onStartNewSessionConfirmed} setForeverLocked={setForeverLocked} />
        )}
      </Card>
    </div>
  );
};

export default SessionTimeout;
