import { mq } from 'src/theme/variables';
import { css } from '@emotion/react';
import { indicatorLine, indicatorNegativeLine } from 'src/theme/shared-styles';

export const consentFormPage = css`
  position: relative;
  scroll-margin-top: var(--header-height);

  &::before {
    ${indicatorLine};
    content: '';
  }
`;

export const consentPageGreeting = css`
  padding-bottom: 40px;
  position: relative;
  ${mq[0]} {
    padding-bottom: 60px;
  }
  &::before {
    ${indicatorNegativeLine};
    content: '';
  }
`;
