/** @jsxImportSource @emotion/react */
import React from 'react';
import { footerCSS, logoFooterCSS, contentWrapperCSS, smallLogoCSS } from './PagesFooter.style';
import MaticLogo from 'src/assets/svg/matic-logo.svg';
import useResponsive from 'src/hooks/useResponsive';

interface PagesFooterProps {
  currentPage: number;
  variant?: 'default' | 'white-styled';
  pagesCount: number;
  disclaimer?: string;
  footerCopy?: string;
}

const PagesFooter: React.FC<PagesFooterProps> = ({
  currentPage,
  pagesCount,
  disclaimer,
  variant = 'default',
  footerCopy
}) => {
  const { isMobile } = useResponsive();

  return (
    <div css={footerCSS(variant)}>
      <div css={contentWrapperCSS}>
        {disclaimer && <div>{disclaimer}</div>}
        {footerCopy && isMobile && <div>{footerCopy}</div>}
        <div css={logoFooterCSS(variant)}>
          <img src={MaticLogo} css={smallLogoCSS} alt="Matic logo" />
          {footerCopy && !isMobile && <div>{footerCopy}</div>}
          <div>
            {currentPage} of {pagesCount}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PagesFooter;
