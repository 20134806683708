import { css } from '@emotion/react';
import { mq } from 'src/theme/variables';
import { text32, text20, text24 } from 'src/theme/typography';

export const defaultContainerCSS = css`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

export const headingCSS = css`
  ${text24};
  margin-top: 0;
  text-align: center;

  ${mq[0]} {
    ${text32};
    text-align: left;
  }
`;

export const factsWrapperCSS = css`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-wrap: wrap;

  ${mq[0]} {
    flex-direction: row;
  }
`;

export const factWrapperCSS = css`
  flex-basis: 25%;
  text-align: center;
  position: relative;
  margin-bottom: 40px;

  ${mq[0]} {
    max-width: 50%;
    flex-basis: 50%;
  }

  ${mq[1]} {
    max-width: 23%;
    flex-basis: 25%;
    padding: 0 8px;
  }

  &::after {
    background-color: black;
    bottom: -20px;
    content: '';
    height: 1px;
    left: calc(50% - 70px);
    margin: 0;
    position: absolute;
    width: 140px;

    ${mq[0]} {
      bottom: 5px;
      height: auto;
      left: auto;
      right: 0;
      top: 5px;
      width: 1px;
    }
  }

  &:last-child::after {
    content: none;
  }
`;

export const factHeadingCSS = css`
  ${text20};
  font-weight: 600;
  margin-bottom: 8px;
`;
