/** @jsxImportSource @emotion/react */
import React, { Fragment, useRef, useState } from 'react';

import { QuestionLabelProps, QuestionLabelWrapper } from './QuestionLabel.types';
import { TooltipRefProps } from 'react-tooltip';
import { useParams } from 'react-router-dom';
import Dialog from 'src/kit/Dialog/Dialog';
import Button from 'src/kit/Button/Button';
import Wrapper from 'src/kit/Wrapper/Wrapper';
import useResponsive from 'src/hooks/useResponsive';
import Tooltip from 'src/shared/components/Tooltip/Tooltip';
import { DefaultLocation, Flows } from 'src/interfaces/IPage';
import InfoIcon from 'src/assets/svg/info.svg';
import { labelCSS, tooltipCloseBtnCSS, tooltipCSS } from './QuestionLabel.style';

const QuestionLabel: React.FC<QuestionLabelProps> = ({
  children,
  wrapperElement = QuestionLabelWrapper.Label,
  keyName,
  tooltipText
}) => {
  const tooltipRef = useRef<TooltipRefProps & HTMLButtonElement>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const responsive = useResponsive();
  const { flow } = useParams() as DefaultLocation;
  const isLifeOriented = flow === Flows.Life;

  const wrapperComponentProps = wrapperElement === QuestionLabelWrapper.Label ? { htmlFor: keyName } : {};

  const renderDesktopTooltipButton = () => (
    <Fragment>
      <button
        data-testid="tooltip"
        css={tooltipCSS(isLifeOriented)}
        ref={tooltipRef}
        data-tooltip-content=""
        aria-label={tooltipText}
        data-tooltip-id={`tooltip-${keyName}`}
      >
        {isLifeOriented ? <img src={InfoIcon} alt="Information" /> : '?'}
      </button>
      <Tooltip id={`tooltip-${keyName}`}>{tooltipText}</Tooltip>
    </Fragment>
  );

  const renderMobileTooltipButton = () => (
    <Fragment>
      <button type="button" css={tooltipCSS(isLifeOriented)} data-testid="tooltip" onClick={() => setIsModalOpen(true)}>
        {isLifeOriented ? <img src={InfoIcon} alt="Information" /> : '?'}
      </button>
      <Dialog isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)}>
        <div>{tooltipText}</div>
        <Button onClick={() => setIsModalOpen(false)} customCSS={tooltipCloseBtnCSS} data-testid="modal-close-btn">
          Close
        </Button>
      </Dialog>
    </Fragment>
  );

  return (
    <Wrapper
      tag={wrapperElement === QuestionLabelWrapper.Label ? 'label' : 'div'}
      {...wrapperComponentProps}
      css={labelCSS}
    >
      {children}
      {tooltipText && (responsive.isDesktop ? renderDesktopTooltipButton() : renderMobileTooltipButton())}
    </Wrapper>
  );
};

export default QuestionLabel;
