/** @jsxImportSource @emotion/react */
import React from 'react';

import {
  floridaImgWrapperCSS,
  floridaTextWrapperCSS,
  floridaWrapperCSS,
  specialistCSS,
  verticalDividerCSS
} from 'src/components/FloridaNoCallLanding/FloridaNoCallLanding.style';
import SIRV from 'src/constants/sirv';
import SirvImage from 'src/kit/SirvImage/SirvImage';

interface FloridaSpecialistNoteProps {
  userName: string;
  isNoQuotesVariation?: boolean;
}

const FloridaSpecialistNote: React.FC<FloridaSpecialistNoteProps> = ({ userName, isNoQuotesVariation = false }) => (
  <div css={floridaWrapperCSS}>
    <div css={floridaImgWrapperCSS}>
      <SirvImage url={SIRV.TEAM_IMG} alt="Florida team" width={140} />
      <h4 css={specialistCSS}>Matic’s Florida Team</h4>
    </div>
    <img css={verticalDividerCSS} src={SIRV.VERTICAL_DIVIDER} alt="Vertical divider" />
    <div css={floridaTextWrapperCSS}>
      <h3>
        {isNoQuotesVariation
          ? `Hey ${userName}, unfortunately, we don’t have any home insurance options for your home ` +
            'in Florida at this time.'
          : `Hey ${userName}, looks like we have a few options for you.`}
      </h3>
      <p>Please note:</p>
      <ul>
        {!isNoQuotesVariation && (
          <li>{'If you have a valid wind mitigation inspection, it can greatly reduce your premium'}</li>
        )}

        <li>
          {isNoQuotesVariation
            ? 'Our advisors can still help you with Auto, Flood, Hurricane and Umbrella coverage'
            : 'Our specialists can help you adjust policies to reduce initial premiums shown'}
        </li>

        <li>
          {isNoQuotesVariation
            ? 'State run insurance providers or local agencies may be able to better find you home insurance'
            : 'Providing your current home insurance declaration page when talking with an Advisor can speed ' +
              'up the process of finding more quotes.'}
        </li>
      </ul>
    </div>
  </div>
);

export default FloridaSpecialistNote;
