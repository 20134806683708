import { css } from '@emotion/react';
import { text40 } from 'src/theme/typography';
import { mq } from 'src/theme/variables';

export const wrapperCSS = css`
  padding-top: 20px;
  ${text40};
`;

export const mainContentWrapperCSS = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: 40px;
  margin-bottom: 40px;

  ${mq[0]} {
    flex-direction: row;
    margin-bottom: 120px;
  }
`;
