import queryString from 'query-string';

export enum QuotesViewFlowType {
  Home = 'home',
  Auto = 'auto'
}

type UseQuotesViewParamsResponse = {
  flow: QuotesViewFlowType | null;
  personGid: stringParam;
  assetsGids: arrayParam;
  quotesRequestGid: stringParam;
  engagementGid: stringParam;
  proposedQuoteGid: stringParam | null; // temporary param used as a part of Proposal Builder experiment by AMP.
};

type stringParam = string | null;
type arrayParam = string[] | null;

const useQuotesViewParams = (): UseQuotesViewParamsResponse => {
  const searchParams = queryString.parse(window.location.search, { arrayFormat: 'bracket' });

  return {
    flow: searchParams.flow as QuotesViewFlowType | null,
    personGid: searchParams.person_gid as stringParam,
    assetsGids: searchParams.assets_gids as arrayParam,
    quotesRequestGid: searchParams.quotes_request_gid as stringParam,
    engagementGid: searchParams.engagement_gid as stringParam,
    proposedQuoteGid: searchParams.proposed_quote_gid as stringParam
  };
};

export default useQuotesViewParams;
