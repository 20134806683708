import { css, SerializedStyles } from '@emotion/react';

import { actionColors, colors, mq } from 'src/theme/variables';

const heartDot = (backgroundColor: string): SerializedStyles => css`
  background-color: ${backgroundColor};
  display: inline-block;
  height: 8px;
  margin: 0 8px;
  position: relative;
  top: 3px;
  left: -6px;
  transform: rotate(-45deg);
  width: 8px;
  border-radius: inherit;
  &:before,
  &:after {
    content: '';
    background-color: ${backgroundColor};
    border-radius: 50%;
    height: 8px;
    position: absolute;
    width: 8px;
  }
  &:before {
    top: -4px;
    left: 0;
  }
  &:after {
    left: 4px;
    top: 0;
  }
`;

export const container = css`
  .slick-dots {
    width: 100%;
    height: 12px;
    padding: 0;
    margin: 24px 0 0;
    list-style: none;
    text-align: center;
    ${mq[0]} {
      margin-top: 32px;
    }
    li {
      position: relative;
      display: inline-block;
      width: 12px;
      height: 12px;
      margin: 0 10px;
      padding: 0;
      &.carousel-play-control button {
        background-color: transparent;
      }
      button {
        font-size: 0;
        line-height: 0;
        border: unset;
        border-radius: 50%;
        background-color: ${colors.silver};
        width: 12px;
        height: 12px;
        padding: 0;
        vertical-align: top;
        cursor: pointer;
      }
      &.slick-active button {
        ${heartDot(actionColors.primary)}
      }
    }
  }
`;
