import { Flows } from 'src/interfaces/IPage';
import { QueryObserverResult, useQuery } from 'react-query';
import { HomeDetailsResponse } from 'src/components/HomeDetails/HomeDetailsWrapper/HomeDetailsWrapper.types';
import { QUERY_CACHE_KEYS } from 'src/constants/queryCacheKeys';
import { homeDetailsService } from './index';

const useHomeDetails = (gid: string): QueryObserverResult<HomeDetailsResponse> => {
  return useQuery([QUERY_CACHE_KEYS.HOME_DETAILS, Flows.AllFunnel, gid], () => homeDetailsService.getHomeDetails(gid));
};

export default useHomeDetails;
