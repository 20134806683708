import Format from './format';

const calcPercentage = (base: number, value: number, precision = 0): number =>
  Math.round((value / base) * 10 ** (2 + precision)) / 10 ** precision;

const calcDiffPercentage = (base: number, value: number, precision = 0): number =>
  calcPercentage(base, value - base, precision);

const calcAbsDiffPercentage = (base: number, value: number): number => Math.abs(calcDiffPercentage(base, value));

const calcBestDiff = (base: number, values: number[]): number => base - Math.min(...values);

const convertPercentToDecimal = (percent: number): number => percent / 100;

const calcValue = (value: number, percent: number): number => value * convertPercentToDecimal(percent);

const getPercentRangeValue = (value: number, above: number, below: number): string =>
  `${Format.money(value - calcValue(value, below), 0, '$')} - ${Format.money(value + calcValue(value, above), 0, '$')}`;

export {
  calcPercentage,
  calcDiffPercentage,
  calcAbsDiffPercentage,
  calcBestDiff,
  calcValue,
  convertPercentToDecimal,
  getPercentRangeValue
};
