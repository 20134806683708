import { QueryObserverResult, useQuery } from 'react-query';
import { QUERY_CACHE_KEYS } from 'src/constants/queryCacheKeys';
import api from 'src/api/api';
import { API_URLS } from 'src/constants/api';
import { getApiUrl } from 'src/utils/api.helpers';
import { Questions, QuestionsNoGidResponse } from 'src/interfaces/questions.types';

const useQuestions = (gid?: string): QueryObserverResult<Questions | QuestionsNoGidResponse> =>
  useQuery([QUERY_CACHE_KEYS.QUESTIONS, gid || 'no_id'], () => {
    if (!gid) return { data: {}, isLoading: false, answers: {}, schema: { pages: [] }, step: '' };

    return api.get(getApiUrl(API_URLS.GET_QUESTIONS, gid));
  });

export default useQuestions;
