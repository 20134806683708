import React from 'react';

import { QuestionsFormPageMap } from './FormPage.utils';
import { QuestionsFormPageProps } from 'src/questionsForm/types/questionsFormPage.types';

const FormPage: React.FC<QuestionsFormPageProps> = props => {
  const FormPageComponent = QuestionsFormPageMap[props.type];
  return <FormPageComponent {...props} />;
};

export default FormPage;
