/** @jsxImportSource @emotion/react */
import React, { Fragment } from 'react';

import Button from 'src/kit/Button/Button';
import { Card } from 'src/shared/components/Card/Card';
import {
  actionCardContentCSS,
  actionCardImgCSS,
  imgWrapperCSS,
  linkCSS,
  actionCardHeadingCSS,
  cardCSS,
  textCSS,
  buttonCSS
} from './FlowActionCard.style';
import { hiddenMD, hiddenSM } from 'src/theme/typography';
import { visuallyHidden } from 'src/theme/helpers';
import { FlowActionCardProps } from './FlowActionCard.types';
import { button } from 'src/kit/Button/Button.style';

const FlowActionCard: React.FC<FlowActionCardProps> = ({
  horizontal = false,
  imgSrc,
  imgAlt,
  text,
  onClick,
  actionButtonTestId
}) => {
  return (
    <Card customCSS={cardCSS}>
      <Button css={linkCSS} data-testid="action-card-wrapper" onClick={onClick}>
        <div css={actionCardContentCSS(horizontal)}>
          <div css={imgWrapperCSS(horizontal)}>
            <img src={imgSrc} width={105} alt={imgAlt} css={actionCardImgCSS} loading="lazy" />
          </div>
          <div css={textCSS(horizontal)}>
            <div css={actionCardHeadingCSS}>{text}</div>
            <div css={[button(true, 'default', false), buttonCSS(horizontal)]} data-testid={actionButtonTestId}>
              <Fragment>
                <span css={hiddenMD}>Get quotes</span>
                <span css={hiddenSM}>Get quotes now</span>
                <span css={visuallyHidden}> (opens a new window)</span>
              </Fragment>
            </div>
          </div>
        </div>
      </Button>
    </Card>
  );
};

export default FlowActionCard;
