/** @jsxImportSource @emotion/react */
import React, { Fragment } from 'react';

import { QuoteTagsProps } from './QuoteTags.types';
import {
  tagCSS,
  tagsCSS,
  tagWrapperCSS,
  quoteCardDisclaimerCSS
} from 'src/components/Quotes/QuoteCard/QuoteCard.style';
import Tag from 'src/shared/components/Tag/Tag';
import { QUOTE_TAGS } from 'src/constants/quoteTag';

const QuoteTags: React.FC<QuoteTagsProps> = ({ customCSS, tags }) => {
  if (!tags?.length) {
    return null;
  }

  return (
    <div css={[tagsCSS, customCSS]}>
      <div css={tagWrapperCSS}>
        {tags.map(tag => (
          <Fragment key={tag.label}>
            <Tag customCSS={tagCSS} color={tag.color}>
              {tag.label}
            </Tag>
            {tag.label === QUOTE_TAGS.BUNDLE_OPTION.label && (
              <div css={quoteCardDisclaimerCSS}>*Average savings for users that bundle</div>
            )}
            {tag.color === QUOTE_TAGS.BUNDLE_MESSAGE.color && (
              <div css={quoteCardDisclaimerCSS}>If you buy home + auto</div>
            )}
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default QuoteTags;
