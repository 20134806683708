import { css } from '@emotion/react';

export const quantityWrapper = css`
  display: flex;
`;

export const button = css`
  background-color: transparent;
  border: 0;
  cursor: pointer;
  vertical-align: middle;
`;

export const quantityInputWrapper = css`
  margin-left: 17px;
  margin-right: 17px;
  width: 60px;
`;

export const quantityInput = css`
  text-align: center;
`;
