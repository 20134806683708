import { QueryObserverResult, useQuery } from 'react-query';
import { Landing } from 'src/interfaces/landing.types';
import { QUERY_CACHE_KEYS } from 'src/constants/queryCacheKeys';
import api from 'src/api/api';
import { API_URLS } from 'src/constants/api';

const useLanding = (carrierName?: string): QueryObserverResult<Landing> =>
  useQuery([QUERY_CACHE_KEYS.LANDING], () =>
    api.get(API_URLS.LANDING, {
      params: {
        carrier: carrierName || ''
      }
    })
  );

export default useLanding;
