import { SerializedStyles } from '@emotion/react';

import { Entity } from './IPage';
import { PreferencesResponseValues } from './IAnswer';
import { UploadFilesComponentProps } from 'src/components/UploadFilesComponent/UploadFilesComponent.types';
import { Rule } from 'src/questionsForm/utils/ruleValidator.utils';

export interface Option {
  value: string;
  label: string;
  disabled?: boolean;
}

export interface VehicleOption {
  gid: string | null;
  make: string;
  model: string;
  year: number;
}

export interface AddressAnswer {
  line1: string;
  line2?: string;
  city: string;
  state: string;
  zip: string;
}

export interface SliderAnswer {
  sliderValue: string;
  clicksCount: number;
}

export type AnswerValue =
  | string
  | boolean
  | number
  | Partial<Option>
  | Partial<VehicleOption>
  | Entity[]
  | AddressAnswer
  | SliderAnswer
  | PreferencesResponseValues;

export interface FieldProps {
  key_name: string;
  url?: string;
  placeholder?: string;
  isDisabled?: boolean;
  hasError?: boolean;
  inputId?: string;
  ariaLabel?: string;
  answer_options?: Option[];
  default_options?: Option[];
  type?: string;
  mask?: string;
  validation?: Validation;
  tracking_forbidden?: boolean;
  icon?: string;
  componentRef?: (instance: HTMLInputElement | null) => void;
}

export enum DynamicValidationTypes {
  YearNowOrOlder = 'year_now_or_older',
  DateOfBirth = 'date_of_birth',
  DateNowOrFuture = 'date_now_or_future',
  DateOfBirthLife = 'date_of_birth_life'
}

export enum QuestionTypes {
  TextInput = 'fields_input',
  Radio = 'fields_radio',
  Quantity = 'fields_quantity',
  Date = 'fields_date',
  Select = 'fields_select_with_options',
  AsyncSelect = 'fields_select_with_async_options',
  VehicleSelect = 'fields_vehicle_select',
  Address = 'fields_address',
  TypicalMileage = 'fields_typical_mileage',
  NumberInput = 'fields_number_input',
  MaskedInput = 'fields_masked_input',
  MoneyInput = 'fields_money_input',
  Checkbox = 'fields_checkbox',
  Slider = 'fields_slider',
  UploadFileInputFlorida = 'fields_upload_file_input_florida'
}

export enum FormQuestionDirections {
  horizontal = 'horizontal',
  vertical = 'vertical'
}

export enum FormQuestionVariants {
  withImages = 'with_images',
  withoutImages = 'without_images'
}

interface QuestionProps extends FieldProps {
  label?: string;
  label_description?: string;
  hint?: string;
  max_visible_options?: number;
  validation?: Validation;
}

export interface FormFieldProps extends FieldProps {
  onValidEntry?(value: AnswerValue | null): void;
  value?: AnswerValue;
  defaultValue?: AnswerValue;
  onBlur?: () => void;
  onFocus?: () => void;
  heading?: string;
  uploadFilesWrapperParams?: UploadFilesComponentProps;
}

export interface FormQuestionProps extends QuestionProps, FormFieldProps {}

export interface RadioFormQuestionProps extends FormQuestionProps {
  direction?: FormQuestionDirections;
  variant?: FormQuestionVariants;
  customOptionCSS?: SerializedStyles;
}

type ValidationOption = {
  value: string | number;
  message: string;
};

type RegExLikeOption = {
  value: string | RegExp;
  message: string;
};

interface StaticValidation extends Record<string, string | undefined | ValidationOption | RegExLikeOption> {
  required?: string;
  min?: ValidationOption;
  max?: ValidationOption;
  pattern?: RegExLikeOption;
}

export interface Validation {
  static?: StaticValidation;
  dynamic?: DynamicValidationTypes;
}

export interface QuestionSwitchProps extends QuestionProps {
  type: QuestionTypes;
  visibility_conditions?: Rule[];
}

export interface SliderQuestionProps extends FormQuestionProps {
  disclaimer?: string;
  customCSS?: SerializedStyles;
}
