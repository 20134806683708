import { css } from '@emotion/react';
import { text, text20, text32 } from 'src/theme/typography';
import { mq } from 'src/theme/variables';

export const advisorContainerCSS = css`
  margin: 0 auto 80px;
  display: flex;
  border-radius: 8px;
  justify-content: space-between;
  flex-direction: column-reverse;
  align-items: center;
  gap: 32px;
  max-width: 960px;

  h2 {
    margin: 0 0 12px;
    ${text20};
  }

  p {
    ${text};
    margin-bottom: 20px;
  }

  div {
    max-width: 440px;
  }

  img {
    width: 260px;
    height: 274px;
  }

  ${mq[0]} {
    flex-direction: row;
    gap: 20px;

    h2 {
      ${text32};
      line-height: 40px;
      margin-bottom: 20px;
    }

    p {
      ${text20};
      margin-bottom: 32px;
    }

    img {
      width: unset;
      height: unset;
    }
  }
`;
