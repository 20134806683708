import brokenHome from 'src/assets/svg/home-coverages/broken-home.svg';
import home from 'src/assets/svg/home-coverages/home.svg';
import armChair from 'src/assets/svg/home-coverages/armchair.svg';
import garage from 'src/assets/svg/home-coverages/garage.svg';
import brokenLeg from 'src/assets/svg/home-coverages/broken-leg.svg';
import bandaids from 'src/assets/svg/home-coverages/bandaids.svg';
import wind from 'src/assets/svg/home-coverages/wind.svg';

export const whyMaticArray = [
  {
    label: 'Save time',
    description:
      'We use your current policy or property info to compare quotes from our network of 40+ A-rated carriers.'
  },
  {
    label: 'Honest advice',
    description: 'Our licensed advisors work for you, not for commission, so you`ll get answers without an agenda. '
  },
  {
    label: 'Sit back and relax',
    description: 'We take care of the paperwork and set up your new policy for you.'
  },
  {
    label: 'No hidden fees',
    description:
      'Matic is a free insurance marketplace. There are no extra fees to work with us; only pay the price of ' +
      'the premium. '
  }
];

export const experimentalWhyMaticArray = [
  {
    label: 'Unbiased Advisors',
    description:
      'Our Matic agents are here to get the best policy for you and are not paid by premiums sold. ' +
      'We get paid when you are happy.'
  },
  {
    label: 'Advocates for Life',
    description:
      'Matic will never stop working for you, which is why we’ll continually shop the best policy for you, ' +
      'year-round with our Rate Review™ monitoring service.'
  },
  {
    label: 'Double Support',
    description:
      'Let our Matic agents help you navigate any challenges with your carrier. Leave the hard work to Matic.'
  },
  {
    label: 'Permanent rate review',
    description: 'Matic engines constantly check your rates every 6 months to ensure you’re in the best spot.'
  }
];

export const ALMOST_DONE_DISCLAIMER =
  'Quotes are estimates only. Quotes are subject to change without notice. Your actual rate, payment and coverage ' +
  'may be different. Quotes do not constitute an offer of insurance, nor is any contract, agreement, or insurance ' +
  'coverage implied, formed or bound by the provision of quotes. Insurability, final insurance premium quotes ' +
  'and an offer of insurance, if any, will be determined by the insurance company providing your insurance policy.';

export const HOME_COVERAGES_DISCLAIMER =
  'This information explains what home insurance typically covers, but it`s not specific to this policy. Always ' +
  'read the terms of the policy you select, as certain limits apply.';

export const homeCoverages = [
  {
    imgSrc: brokenHome,
    imgAlt: 'Broken Home',
    label: 'Dwelling',
    description:
      'If your house is damaged by a covered event, which could include a fire, fallen tree, lightning, or another ' +
      'hazard, this coverage can help you pay to repair or rebuild your home. '
  },
  {
    imgSrc: home,
    imgAlt: 'Other Structures',
    label: 'Other structures',
    description:
      'This can help pay for the cost to repair damages to a fence, shed, or other structure that isn’t attached ' +
      'when a covered event occurs.'
  },
  {
    imgSrc: armChair,
    imgAlt: 'Armchair',
    label: 'Personal property',
    description:
      'If your furniture, clothing, or other belongings are damaged during a covered incident, this coverage can ' +
      'help you pay to repair or replace them. '
  },
  {
    imgSrc: garage,
    imgAlt: 'Garage',
    label: 'Loss of use',
    description:
      'If you`re unable to live in your home after a covered claim, this coverage can help you pay for a hotel ' +
      'or rental while your home is repaired or rebuilt.'
  },
  {
    imgSrc: brokenLeg,
    imgAlt: 'Broken Leg',
    label: 'Personal liability',
    description:
      'If someone sues you because they injure themselves or because their property gets damaged at your home, ' +
      'this coverage can help you pay for expenses like legal fees, medical bills, and replacement items.'
  },
  {
    imgSrc: bandaids,
    imgAlt: 'Band-Aid',
    label: 'Medical payments',
    description:
      'If another person gets a minor injury on your property, this coverage can help pay for their ' +
      'medical expenses regardless of who is at fault.'
  },
  {
    imgSrc: wind,
    imgAlt: 'Wind Icon',
    label: 'Wind and hail',
    description:
      'If your home is damaged by a covered wind or hail event, this coverage can help you pay for ' +
      'repairs. In most states, wind and hail protection are included in the policy, but some states ' +
      'and carriers may require separate coverage and deductibles so ask your insurance Advisor for ' +
      'more information.'
  }
];
