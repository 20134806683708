/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';

import { useParams } from 'react-router-dom';
import useConfig from 'src/api/config/useConfig';
import { DefaultLocation, Flows } from 'src/interfaces/IPage';

import { container, containerInnerCSS, widgetCSS } from './ScheduleCall.style';
import { ScheduleCallProps } from './ScheduleCall.types';
import useInitCalendly from 'src/hooks/useInitCalendly';
import ScheduleCallTeam from './ScheduleCallTeam/ScheduleCallTeam';
import ScheduleCallHeading from './ScheduleCallHeading/ScheduleCallHeading';
import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';
import useQuestions from 'src/api/questions/useQuestions';

const ScheduleCall: React.FC<ScheduleCallProps> = ({
  customHeading = null,
  shouldHideFinalHeading = false,
  shouldHideHeading = false,
  shouldHideTeam = false,
  customCSS = {},

  isPolicyHolderVariation,
  selectedCarrier
}) => {
  const { flow, gid } = useParams() as DefaultLocation;
  const { data: config } = useConfig(gid);
  const { data: questions, isLoading } = useQuestions(gid);

  const { agent_email = '', agent_phone = '', cst_phone = '' } = config?.partner || {};
  const { url } = config?.integrations?.calendly || {};

  const first_name = questions?.answers?.person_first_name as string;
  const last_name = questions?.answers?.person_last_name as string;
  const phone = questions?.answers?.person_phone as string;
  const email = questions?.answers?.person_email as string;

  const { step, calendlyInit } = useInitCalendly({
    gid,
    flow,
    url,
    name: `${first_name} ${last_name}`,
    phone,
    email
  });

  useEffect(() => {
    if (!isLoading) {
      calendlyInit();
    }
  }, [calendlyInit, isLoading]);

  const onPhoneNumberClicked = () => {
    analytics.track(SEGMENT.PHONE_CONVERSATION_INITIATED, gid, flow, {
      location: SEGMENT.LOCATIONS.MATIC_TEAM_BLOCK,
      page: SEGMENT.PAGES_KEY.CONFIRMATION
    });
  };

  return (
    <div css={[container, customCSS.container]}>
      <div css={containerInnerCSS}>
        {isPolicyHolderVariation && selectedCarrier && <p>You’ve selected {selectedCarrier}</p>}
        <ScheduleCallHeading
          step={step}
          customHeading={customHeading}
          shouldHideFinalHeading={shouldHideFinalHeading}
          shouldHideHeading={shouldHideHeading}
          isPolicyHolderVariation={isPolicyHolderVariation}
          isLifeSimplified={flow === Flows.Life}
        />
      </div>

      <div id="calendly-widget" data-testid="calendly-widget" css={[widgetCSS(step), customCSS.widget]} />

      {!shouldHideTeam && (
        <ScheduleCallTeam
          agentPhone={isPolicyHolderVariation ? cst_phone : agent_phone}
          agentEmail={agent_email}
          onPhoneNumberClick={onPhoneNumberClicked}
        />
      )}
    </div>
  );
};

export default ScheduleCall;
