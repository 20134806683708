/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import {
  questionHeadingCSS,
  radioCSS,
  radioRowCSS,
  errorCSS,
  radioWrapperCSS,
  emptyBorder
} from 'src/components/EditInfoSideModal/EditInfoSideModal.style';
import { PropertyTypeAnswers, PropertyType } from './../EditInfoScheme';
import useResponsive from 'src/hooks/useResponsive';
import { RadioButtonsProps } from './../EditInfoSideModal.types';

export const ClaimsRadioButtons: React.FC<RadioButtonsProps> = ({ onPropertyUpdate, isValidationError }) => {
  const { isDesktop } = useResponsive();

  const handleChange = (value: string) => {
    onPropertyUpdate(PropertyType.OpenClaims, value);
  };

  return (
    <FormControl css={radioRowCSS}>
      <div css={questionHeadingCSS}>Do you have any open claims?</div>
      <RadioGroup row css={radioRowCSS} onChange={(_event, value) => handleChange(value)}>
        <FormControlLabel
          css={radioCSS(isDesktop ? '44%' : '42%')}
          value={PropertyTypeAnswers.Yes}
          control={<Radio />}
          label="Yes"
        />
        <FormControlLabel
          css={radioCSS(isDesktop ? '44%' : '42%')}
          value={PropertyTypeAnswers.No}
          control={<Radio />}
          label="No"
        />
        {isValidationError && <span css={errorCSS}>This filed is required</span>}
      </RadioGroup>
    </FormControl>
  );
};

export const ExtendedClaimsRadioButtons: React.FC<RadioButtonsProps> = ({ onPropertyUpdate, isValidationError }) => {
  const [answer, setAnswer] = useState<boolean>(false);
  const [shouldRenderExtendedQuestion, setShouldRenderExtendedQuestion] = useState(false);

  const handleChange = (value: string) => {
    if (value === PropertyTypeAnswers.Yes) {
      onPropertyUpdate(PropertyType.HasDog, value);
      setAnswer(true);
    } else if (value === PropertyTypeAnswers.No) {
      onPropertyUpdate(PropertyType.BiteHistory, value);
      onPropertyUpdate(PropertyType.HasDog, value);

      setAnswer(false);
    }
  };

  useEffect(() => {
    setShouldRenderExtendedQuestion(!!answer);
  }, [answer]);

  return (
    <FormControl css={radioRowCSS}>
      <div css={questionHeadingCSS}>Do you have a dog?</div>
      <RadioGroup css={radioRowCSS} onChange={(_event, value) => handleChange(value)}>
        <div css={[radioWrapperCSS]}>
          <FormControlLabel
            css={radioCSS('95%')}
            value={PropertyTypeAnswers.Yes}
            control={<Radio css={emptyBorder} />}
            label="Yes"
          />
          {shouldRenderExtendedQuestion && (
            <FormControlLabel
              onChange={(_event, value) =>
                onPropertyUpdate(PropertyType.BiteHistory, value ? PropertyTypeAnswers.Yes : PropertyTypeAnswers.No)
              }
              control={<Checkbox />}
              value={PropertyTypeAnswers.Yes}
              label="My dog has a bite history"
            />
          )}
        </div>

        <FormControlLabel css={radioCSS('95%')} value={PropertyTypeAnswers.No} control={<Radio />} label="No" />
        {isValidationError && <span css={errorCSS}>This filed is required</span>}
      </RadioGroup>
    </FormControl>
  );
};
