/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';

import Page from 'src/components/Page/Page';
import TermsAndConditionsPage from 'src/components/TermsAndConditionsPage/TermsAndConditionsPage';
import Container from 'src/kit/Container/Container';
import {
  termsAndConditionsLayoutContentCSS,
  termsAndConditionsLayoutFooterCSS,
  termsAndConditionsLayoutLogoCSS
} from './TermsAndConditionsLayout.style';
import Logo from 'src/kit/Logo/Logo';
import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';

const PAGE_TITLE = 'Matic Insurance | Terms and Conditions';
const TermsAndConditionsLayout: React.FC = () => {
  useEffect(() => {
    document.title = PAGE_TITLE;
    analytics.page(SEGMENT.PAGES.TERMS_AND_CONDITIONS);
  }, []);

  return (
    <Page fullHeight>
      <Logo customCSS={termsAndConditionsLayoutLogoCSS} />
      <main css={termsAndConditionsLayoutContentCSS}>
        <Container>
          <TermsAndConditionsPage />
        </Container>
      </main>
      <footer data-testid="terms-conditions-footer" css={termsAndConditionsLayoutFooterCSS} />
    </Page>
  );
};

export default TermsAndConditionsLayout;
