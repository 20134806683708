import { css, SerializedStyles } from '@emotion/react';
import { text, text20, text32, text48 } from 'src/theme/typography';
import { colors, mq } from 'src/theme/variables';

export const container = (): SerializedStyles => css`
  text-align: center;
  margin-bottom: 24px;
  ${mq[0]} {
    margin-bottom: 40px;
  }
  ${mq[1]} {
    margin-bottom: 56px;
  }
`;

export const header = (): SerializedStyles => css`
  ${text32};
  font-weight: 900;
  margin-bottom: 8px;
  ${mq[0]} {
    ${text48};
  }
  ${mq[1]} {
    margin-bottom: 12px;
  }
`;

export const subheader = (): SerializedStyles => css`
  ${text};
  margin-bottom: 16px;
  ${mq[0]} {
    ${text20};
  }
`;

export const preHeaderText = css`
  ${text20};
  ${mq[0]} {
    font-weight: 500;
  }
`;

export const params = (): SerializedStyles => css`
  list-style: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-left: 0;
  color: ${colors.dove};
  font-weight: bold;
  margin: 0;
  ${text};
  ${mq[0]} {
    ${text20};
  }
  li {
    line-height: 20px;
    white-space: nowrap;
    &:after {
      content: '';
      width: 2px;
      height: 38px;
      background-color: ${colors.silver};
      display: inline-block;
      margin: 0 16px;
      vertical-align: middle;
    }
    &:first-of-type {
      width: 100%;
      margin-bottom: 8px;
      &:after {
        display: none;
      }
    }
    &:last-child:after {
      width: 0;
      margin: 0;
    }
    ${mq[0]} {
      &:first-of-type {
        width: auto;
        margin-bottom: 0;
        &:after {
          display: inline-block;
        }
      }
    }
  }
`;
