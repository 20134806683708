import { css, SerializedStyles } from '@emotion/react';
import { mq } from 'src/theme/variables';
import { button } from 'src/kit/Button/Button.style';
import { headingL } from 'src/theme/typography';

export const quotesContainer = css`
  padding-top: 20px;
  padding-bottom: 32px;

  ${mq[0]} {
    padding-top: 40px;
    padding-bottom: 56px;
  }
`;

export const quotesHeaderEditLink = css`
  ${button(false, 'text')};
  text-decoration: none;
`;

export const quotesBanner = css`
  margin-top: 32px;
  ${mq[1]} {
    margin-top: 40px;
  }
`;

export const quoteCard = (dense?: boolean): SerializedStyles => css`
  margin-bottom: 12px;
  ${dense &&
  css`
    padding-top: 20px;
    padding-bottom: 20px;
    ${mq[0]} {
      padding: 24px;
    }
  `}
`;

export const coverageOptions = css`
  margin-bottom: 16px;
  ${mq[0]} {
    margin-bottom: 40px;
  }
`;

export const faq = css`
  margin-top: 40px;
  ${mq[0]} {
    margin-top: 108px;
  }
  h2 {
    ${headingL};
  }
`;

export const dividerCSS = css`
  display: none;
  margin: 60px auto 48px;
  width: 114px;
  ${mq[0]} {
    display: block;
  }
`;

export const talkToAdvisor = css`
  margin-top: 40px;
  ${mq[0]} {
    margin-top: 0;
  }
`;

export const carriersList = css`
  margin-top: 40px;
  ${mq[1]} {
    margin-top: 0;
  }
`;
