/** @jsxImportSource @emotion/react */
import React from 'react';
import { contentWrapperCSS } from './../AlmostDone/AlmostDone.style';
import {
  coverageWrapperCSS,
  coveragesWrapperCSS,
  descriptionWrapperCSS,
  labelCSS,
  headerCopyCSS,
  descriptionCSS,
  headingCSS,
  wrapperCSS
} from './HomeCoverage.style';

import PagesFooter from './../PagesFooter/PagesFooter';
import { homeCoverages } from './../quotesViewUtils';
import { HOME_COVERAGES_DISCLAIMER } from './../quotesViewUtils';
import { HomeCoveragesProps } from './HomeCoverages.types';

const MOCK_PROPS = {
  currentPage: 3,
  pagesCount: 4
};

const HomeCoverages: React.FC<HomeCoveragesProps> = ({ proposalBuilderExperiment }) => {
  return (
    <div css={wrapperCSS}>
      <div css={contentWrapperCSS}>
        <div css={headingCSS}>A deep dive into home insurance</div>
        <div css={headerCopyCSS}>
          Not sure what’s really covered in your policy? We’ve got you. Take a look at this rundown of coverage
          definitions.
        </div>
      </div>

      <div css={coveragesWrapperCSS}>
        {homeCoverages.map((coverage, i) => (
          <div css={coverageWrapperCSS} key={i}>
            <img src={coverage.imgSrc} alt={coverage.imgAlt} />
            <div css={descriptionWrapperCSS}>
              <div css={labelCSS}>{coverage.label}</div>
              <div css={descriptionCSS}>{coverage.description}</div>
            </div>
          </div>
        ))}
      </div>

      <PagesFooter
        disclaimer={proposalBuilderExperiment ? '' : HOME_COVERAGES_DISCLAIMER}
        currentPage={MOCK_PROPS.currentPage}
        pagesCount={MOCK_PROPS.pagesCount}
        variant={proposalBuilderExperiment ? 'white-styled' : 'default'}
      />
    </div>
  );
};

export default HomeCoverages;
