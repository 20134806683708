import gsap from 'gsap';

const getAnimationConfig = (trigger: string, y: number): gsap.TweenVars => ({
  y,
  scrollTrigger: {
    trigger,
    start: 'top bottom',
    end: 'bottom top',
    scrub: 1
  }
});

export default {
  getAnimationConfig
};
