/** @jsxImportSource @emotion/react */
import React from 'react';
import { SerializedStyles } from '@emotion/react';

import { textHighlightCSS } from './TextHighlight.style';

interface Props {
  customCSS?: SerializedStyles;
  children?: React.ReactNode;
}

const TextHighlight: React.FC<Props> = ({ children, customCSS }) => {
  return (
    <div css={[textHighlightCSS, customCSS]} data-testid="text-highlight">
      {children}
    </div>
  );
};

export default TextHighlight;
