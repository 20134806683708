/** @jsxImportSource @emotion/react */
import React from 'react';

import SIRV from 'src/constants/sirv';
import SirvImage from 'src/kit/SirvImage/SirvImage';
import BBB_WHITE_ICON from 'src/assets/svg/bbb-accredited-business-white.svg';
import BBB_GREY_ICON from 'src/assets/svg/bbb-accredited-business-grey.svg';
import { BBBIconVariation, BBBIconProps } from './BBBIcon.types';
import useResponsive from 'src/hooks/useResponsive';
import Anchor from 'src/kit/Anchor/Anchor';

import { bbbRatingCSS, bbbRatingLinkCSS } from './BBBIcon.style';

const BBBIcon: React.FC<BBBIconProps> = ({
  colorVariation = BBBIconVariation,
  isSimplifiedVersion = false,
  onClick,
  shouldHideText = false,
  customCSS
}) => {
  const { isDesktop } = useResponsive();

  return (
    <div css={[bbbRatingCSS(colorVariation === BBBIconVariation.White), customCSS]}>
      <a
        href="https://www.bbb.org/us/oh/columbus/profile/insurance-agency/matic-insurance-services-inc-0302-70097885"
        rel="noopener noreferrer"
        target="_blank"
        onClick={onClick}
      >
        {colorVariation === BBBIconVariation.Blue ? (
          <SirvImage url={SIRV.BBB_ACCREDITED_BUSINESS.url} width={75} alt={SIRV.BBB_ACCREDITED_BUSINESS.alt} />
        ) : colorVariation === BBBIconVariation.Grey ? (
          <img src={BBB_GREY_ICON} width={75} alt={SIRV.BBB_ACCREDITED_BUSINESS.alt} />
        ) : (
          <img src={BBB_WHITE_ICON} width={75} alt={SIRV.BBB_ACCREDITED_BUSINESS.alt} />
        )}
      </a>
      {!shouldHideText && (
        <div css={bbbRatingLinkCSS(colorVariation === BBBIconVariation.White, isSimplifiedVersion)}>
          Matic is a BBB accredited business with an {isDesktop && !isSimplifiedVersion && <br />}
          <Anchor
            anchorType="single-color"
            href="https://www.bbb.org/us/oh/columbus/profile/insurance-agency/matic-insurance-services-inc-0302-70097885"
            rel="noopener noreferrer"
            target="_blank"
            onClick={onClick}
          >
            A+ rating.
          </Anchor>
        </div>
      )}
    </div>
  );
};

export default BBBIcon;
