import { QueryObserverResult, useQuery } from 'react-query';
import { Config } from 'src/interfaces/config.types';
import { QUERY_CACHE_KEYS } from 'src/constants/queryCacheKeys';
import api from 'src/api/api';
import { API_URLS } from 'src/constants/api';

const useConfig = (gid?: string): QueryObserverResult<Config> =>
  useQuery([QUERY_CACHE_KEYS.CONFIG, gid || 'no_id'], () => api.get(API_URLS.CONFIG, { params: { gid } }));

export default useConfig;
