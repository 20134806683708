const HistoricalBarCustomLabel: React.FC<any> = ({ x, y, index, values, viewBox }) => {
  return (
    <svg
      x={viewBox.width / 2 + x - 38}
      y={y - 49}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 53 39"
      fill="none"
      height="48px"
      preserveAspectRatio="xMinYMin meet"
    >
      <path
        d={`M62 0H2C0.895431 0 0 0.89543 0 2V27.3635C0 28.468 0.89543 29.3635 2 29.3635H25.5L31.5 
          36L37.5 29.3635H62C63.1046 29.3635 64 28.468 64 27.3635V2C64 0.89543 63.1046 0 62 0Z`}
        fill="#156EEA"
      />
      <text
        x="60%"
        y="40%"
        dominantBaseline="middle"
        textAnchor="middle"
        fill="white"
        lengthAdjust="spacingAndGlyphs"
        fontFamily="freight-sans-pro, sans-serif"
        fontSize="10px"
        fontWeight="700"
      >
        +{values[index].value}% {index === values.length - 1 ? '(est)' : ''}
      </text>
    </svg>
  );
};

export default HistoricalBarCustomLabel;
