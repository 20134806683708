/** @jsxImportSource @emotion/react */
import React from 'react';

import { loaderContainerCss } from './FullPageLoader.style';
import LoadingDots from 'src/shared/components/LoadingDots/LoadingDots';

const FullPageLoader: React.FC = () => (
  <div css={loaderContainerCss} data-testid="full-page-loader">
    <LoadingDots />
  </div>
);

export default FullPageLoader;
