import { css, SerializedStyles } from '@emotion/react';
import { actionColors, colors } from 'src/theme/variables';
import { AnchorType } from './types';

const getLinkColor = (type: AnchorType): SerializedStyles => {
  switch (type) {
    case 'underline-active':
      return css`
        color: inherit;
        border-bottom-color: ${actionColors.primary};
      `;
    case 'single-color':
      return css`
        color: inherit;
        border-bottom-color: inherit;
      `;
    default:
      return css`
        color: ${actionColors.primary};
        border-bottom-color: ${actionColors.primary};
      `;
  }
};

const getLinkHoverColor = (type: AnchorType, inverted: boolean): SerializedStyles => {
  switch (type) {
    case 'underline-active':
      return css`
        color: ${actionColors.hover};
        border-bottom-color: ${actionColors.hover};
      `;
    case 'single-color':
    default:
      return css`
        color: ${inverted ? colors.lightBlue : actionColors.hover};
        border-bottom-color: ${inverted ? colors.lightBlue : actionColors.hover};
      `;
  }
};

const getLinkActiveColor = (type: AnchorType, inverted: boolean): SerializedStyles => {
  switch (type) {
    case 'underline-active':
      return css`
        color: ${actionColors.active};
        border-bottom-color: ${actionColors.active};
      `;
    case 'single-color':
    default:
      return css`
        color: ${inverted ? colors.cornflower : actionColors.active};
        border-bottom-color: ${inverted ? colors.cornflower : actionColors.active};
      `;
  }
};

const getLinkVisitedColor = (type: AnchorType): SerializedStyles => {
  switch (type) {
    case 'underline-active':
      return css`
        border-bottom-color: ${actionColors.visited};
      `;
    case 'single-color':
      return css`
        color: inherit;
        border-bottom-color: inherit;
      `;
    default:
      return css`
        color: ${actionColors.visited};
        border-bottom-color: ${actionColors.visited};
      `;
  }
};

export const linkCSS = (type: AnchorType, inverted = false): SerializedStyles => css`
  border-bottom: 2px solid ${actionColors.primary};
  ${getLinkColor(type)}
  cursor: pointer;
  text-decoration: none;
  transition:
    color 0.2s ease,
    border-bottom-color 0.2s ease;
  &:visited {
    ${getLinkVisitedColor(type)}
  }
  &:focus,
  &:active {
    ${getLinkActiveColor(type, inverted)}
  }
  &:hover {
    ${getLinkHoverColor(type, inverted)}
  }
`;
