import { css } from '@emotion/react';
import { colors as c, mq } from 'src/theme/variables';
import { text, text14, text20, text24, text32, text40 } from 'src/theme/typography';

const baseDisplayCSS = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const homeBundleOfferContainerCSS = css`
  ${baseDisplayCSS};
  padding: 0 39px;

  & h1 {
    margin-bottom: 12px;
    text-align: center;
    ${text24};
    line-height: 32px;
  }

  & h1 span {
    color: ${c.darkGreen};
  }

  & p {
    font-weight: 500;
    text-align: center;
    ${text20}
  }

  ${mq[0]} {
    & h1 {
      ${text40};
    }
  }

  ${mq[1]} {
    margin-top: 22px;
  }
`;

export const cardWrapperCSS = css`
  ${baseDisplayCSS};
  width: 187px;
  padding: 20px 0;
  border: 1px solid ${c.silver};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  position: relative;
  background-color: white;

  & > img {
    height: 86px;
  }

  & > span {
    ${text20};
    font-weight: 700;
    padding: 5px 0 24px 0;
  }

  ${mq[0]} {
    padding: 40px 0;
    width: 293px;

    & > span {
      ${text32};
      line-height: 40px;
      padding: 16px 0 32px 0;
    }

    & > img {
      height: auto;
    }
  }
`;

export const customButtonCSS = css`
  ${mq[0]} {
    ${text};
  }
`;

export const roundedBackgroundCSS = css`
  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 263px;
    height: 263px;
    border-radius: 50%;
    background-color: rgba(21, 110, 234, 0.03);
    top: -27px;
    z-index: -1;

    ${mq[0]} {
      width: 469px;
      height: 469px;
      top: -50px;
    }
  }
`;

export const cardsWrapperCSS = css`
  margin-top: 52px;
  display: flex;
  gap: 24px;
  flex-direction: column-reverse;
  z-index: 1;

  ${mq[1]} {
    flex-direction: row;
    gap: 40px;
  }
`;

export const checkIconCSS = css`
  position: absolute;
  top: 16px;
  right: 16px;
`;

export const labelCSS = css`
  padding: 0 18px;
  border-radius: 50px;
  background-color: ${c.violet};
  color: ${c.white};
  font-weight: 700;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  top: -14px;
  ${text14};

  ${mq[0]} {
    height: 40px;
    top: -20px;
    ${text20};
  }
`;
