import { QueryObserverResult, useQuery } from 'react-query';
import { DisclosuresResponse } from 'src/interfaces/disclosures.types';
import { QUERY_CACHE_KEYS } from 'src/constants/queryCacheKeys';
import api from 'src/api/api';
import { API_URLS } from 'src/constants/api';

const useDisclosures = (gid?: string, accepted_disclosures?: string[]): QueryObserverResult<DisclosuresResponse> =>
  useQuery([QUERY_CACHE_KEYS.DISCLOSURES, gid || 'no_id'], () =>
    api.get(API_URLS.DISCLOSURES, { params: { gid, accepted_disclosures } })
  );

export default useDisclosures;
