import queryString from 'query-string';

import api from 'src/api/api';
import { API_URLS } from 'src/constants/api';
import { Flows } from 'src/interfaces/IPage';
import { Session, SessionUnlock } from 'src/interfaces/session.types';
import { getApiUrl } from 'src/utils/api.helpers';

const sessionService = {
  createSession(flow: Flows): Promise<Session> {
    return api.post(getApiUrl(API_URLS.SESSION_FLOW, flow), {
      body: { flow, url_params: queryString.parse(window.location.search) }
    });
  },
  getSession(flow: Flows, gid: string): Promise<Session> {
    return api.get(getApiUrl(API_URLS.SESSION, flow, gid, window.location.search));
  },
  startQuoting(flow: Flows, gid: string): Promise<Session> {
    return api.post(getApiUrl(API_URLS.START_QUOTING, flow), {
      body: { gid }
    });
  },
  unlockSession(gid: string, queryData: string): Promise<SessionUnlock> {
    return api.post(getApiUrl(API_URLS.UNLOCK_SESSION, gid, queryData), {}, false);
  },
  lockStatus(gid: string): Promise<SessionUnlock> {
    return api.get(getApiUrl(API_URLS.LOCK_STATUS, gid), {}, false);
  },
  startOver(gid: string): Promise<Session> {
    return api.post(getApiUrl(API_URLS.START_OVER, gid), {}, false);
  }
};

export default sessionService;
