import { css } from '@emotion/react';
import { text24, text48, text40, text32, text20, text14 } from 'src/theme/typography';
import { mq, colors } from 'src/theme/variables';

export const imageCSS = css`
  display: block;
  margin: 0 auto 10px;
  width: 300px;

  ${mq[0]} {
    position: absolute;
    width: 557px;
    right: -100px;
    bottom: -90px;
  }

  ${mq[1]} {
    width: 605px;
    right: -127px;
    bottom: -144px;
  }
`;

export const iconBundleCSS = css`
  display: block;
  position: absolute;
  top: -25px;
  right: 0;

  ${mq[0]} {
    top: 112px;
  }

  @media only screen and (min-width: 1050px) {
    height: 470px;
    width: 640px;
    right: -203px;
    bottom: 0px;
    top: unset;
  }

  ${mq[2]} {
    right: -73px;
  }
`;

export const savingsValueCSS = css`
  ${text48};
  line-height: 1;
  font-weight: 900;
  margin-bottom: 10px;

  ${mq[0]} {
    font-size: 64px;
  }
`;

export const savingsValueSuffixCSS = css`
  ${text24};
  font-weight: 700;
`;

export const headingCSS = css`
  ${text32};
  font-weight: 700;
  margin-bottom: 12px;

  ${mq[0]} {
    ${text40};
    margin-bottom: 12px;
    max-width: 290px;
  }

  ${mq[1]} {
    ${text48};
    line-height: 52px;
    max-width: 600px;
    margin-bottom: 12px;
  }
`;

export const descriptionCSS = css`
  margin-top: 20px;

  ${mq[0]} {
    max-width: 300px;
  }

  ${mq[1]} {
    max-width: 545px;
    margin-top: 26px;
  }
`;

export const phoneIconCSS = css`
  height: 20px;
  margin-right: 12px;
  color: ${colors.azure};

  ${mq[0]} {
    display: none;
  }

  ${mq[1]} {
    display: block;
  }
`;

export const iconButtonWrapperCSS = css`
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const callButtonCSS = css`
  display: block;
  border: none;
  width: 100%;
  margin-bottom: 12px;
  padding: 16px 24px;
  ${text20};

  ${mq[0]} {
    width: 200px;
    margin-bottom: 0;
    margin-top: 30px;
  }
`;

export const descriptionCopyCSS = css`
  ${text14};
  font-style: italic;
`;
