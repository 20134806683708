import { css } from '@emotion/react';
import { text, text20 } from 'src/theme/typography';
import { mq, colors } from 'src/theme/variables';

export const wrapperCSS = css`
  border-radius: 8px;
  border: 1px solid ${colors.nobel};
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: fit-content;
  flex-shrink: 0;
  margin-bottom: 20px;

  ${text};

  ${mq[0]} {
    margin-bottom: 0;
    width: 280px;
  }

  img {
    width: 16px;
    margin-right: 8px;
  }
`;

export const itemWrapperCSS = css`
  display: flex;
`;

export const disclosuresCSS = css`
  color: ${colors.dove};
  margin-top: 16px;
  font-size: 12px;
  font-weight: 500;
`;

export const headingCSS = css`
  ${text20};
  font-weight: bold;
  margin-bottom: 16px;

  ${mq[0]} {
    margin-bottom: 20px;
  }
`;

export const userCSS = css`
  font-weight: bold;
  margin-bottom: 12px;
`;

export const itemCSS = css`
  margin: 6px 0;
  width: 100%;
`;

export const dividerCSS = css`
  border-top: none;
  width: 100%;
  border-bottom: 1px solid ${colors.nobel};
  margin: 16px 0;

  ${mq[0]} {
    margin: 36px 0 16px;
  }
`;
