/** @jsxImportSource @emotion/react */

import Dialog from 'src/kit/Dialog/Dialog';
import QuoteCoverages from 'src/components/Quotes/QuoteCoverages/QuoteCoverages';
import Button from 'src/kit/Button/Button';
import { ButtonSize } from 'src/kit/Button/types';
import { customDialogCSS, groupTitleCSS, popupButtonWrapperCSS } from './CoveragePopup.style';
import CloseIcon from 'src/assets/svg/close.svg?react';
import { detailsCSS } from 'src/components/Quotes/QuoteCard/HomeCard/HomeCard.style';
import { getAfSingleQuoteCoveragesGroups } from 'src/utils/quotesPage';
import useResponsive from 'src/hooks/useResponsive';
import { CoveragePopupProps } from './CoveragePopup.types';
import { AUTOMATION_TEST_IDS } from 'src/constants/automationTestIds';

const IDS = AUTOMATION_TEST_IDS.SINGLE_QUOTE;

const CoveragePopup: React.FC<CoveragePopupProps> = ({ onClose, isPopupOpen, bestQuote, selectQuote }) => {
  const { isMobile } = useResponsive();

  const coverages = [
    {
      ...getAfSingleQuoteCoveragesGroups(bestQuote.coverages)[0],
      title: (
        <div css={groupTitleCSS}>
          Policy Coverage <CloseIcon role="button" onClick={onClose} data-testid={IDS.CLOSE_BUTTON_ON_COVERAGE_POPUP} />
        </div>
      )
    }
  ];

  return (
    <Dialog
      isOpen={isPopupOpen}
      customCSS={{ body: customDialogCSS, dialogInline: { overlay: isMobile ? { marginTop: 0 } : {} } }}
      fullscreen={isMobile}
    >
      <QuoteCoverages customCSS={detailsCSS} groups={coverages} shouldRenderDescription />
      <div css={popupButtonWrapperCSS}>
        <Button
          size={ButtonSize.Large}
          onClick={selectQuote}
          fullWidth={isMobile}
          data-testid={IDS.CHOOSE_QUOTE_BUTTON_ON_COVERAGE_POPUP}
        >
          Choose Quote
        </Button>
      </div>
    </Dialog>
  );
};

export default CoveragePopup;
