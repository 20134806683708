/** @jsxImportSource @emotion/react */
import React from 'react';

import { GenericErrorContentProps } from './GenericErrorContent.types';
import { containerCSS, headingCSS, imageCSS } from './GenericErrorContent.style';
import SirvImage from 'src/kit/SirvImage/SirvImage';
import format from 'src/utils/format';
import { DEFAULT_PHONE_NUMBER } from 'src/constants/contacts';
import Anchor from 'src/kit/Anchor/Anchor';
import SIRV from 'src/constants/sirv';

const GenericErrorContent: React.FC<GenericErrorContentProps> = ({
  customCSS,
  contactPhone = DEFAULT_PHONE_NUMBER
}) => {
  return (
    <div css={[containerCSS, customCSS]}>
      <h2 css={headingCSS}>Oops! Something Went Wrong</h2>
      <SirvImage css={imageCSS} alt="Cat surrounded by a cable" url={SIRV.ERROR_PAGE_ILLUSTRATION} width={536} />
      <div>
        <b>It’s Confusing, We Know</b>
      </div>
      <div>
        Please, call us <Anchor href={`tel:${format.phone(contactPhone)}`}>{format.phone(contactPhone)}</Anchor> to ask
        for help, or try again later.
      </div>
    </div>
  );
};

export default GenericErrorContent;
