import { css, SerializedStyles } from '@emotion/react';
import { borderRadius, colors, sizes, typography, mq } from 'src/theme/variables';
import { formInputFocusOutline } from 'src/theme/shared-styles';

export const selectCSS = (
  hasError = false,
  isLifeFlowVariant?: boolean,
  isExtendedSelect?: boolean,
  shouldHighlightError?: boolean
): SerializedStyles => css`
  .select {
    &__control {
      background-color: ${colors.white};
      border: 1px solid ${colors.black};
      border-radius: ${borderRadius}px;
      cursor: pointer;
      font-size: ${typography.fontSize};
      min-height: ${sizes.controlHeight}px;

      ${shouldHighlightError &&
      css`
        border: 2px solid ${colors.red};
      `}

      ${isLifeFlowVariant &&
      css`
        min-height: unset;
        height: 44px;
        width: 100%;
        text-align: left;

        &[aria-disabled='true'] {
          background-color: ${colors.mercury};
        }

        ${mq[0]} {
          min-width: ${isExtendedSelect ? '180px' : '150px'};
        }
      `}
      position: relative;
      transition: border-color 0.2s ease;

      &:hover {
        border-color: ${colors.azure};
        box-shadow: none;

        .select__indicators {
          border-color: ${colors.azure};
        }
      }

      &--is-focused,
      &--is-focused:hover {
        border-width: 1px;
        border-color: ${colors.black};
        box-shadow: none;
        &::before {
          ${formInputFocusOutline};
          border-color: ${hasError ? colors.red : colors.azure};
        }

        .select__indicators {
          border-color: ${colors.black};
        }
      }

      &--menu-is-open {
        .select__dropdown-indicator {
          transform: rotate(180deg);
        }
      }
    }

    &__dropdown-indicator {
      transition: transform 0.1s ease-in;
      svg {
        color: ${colors.black};
        height: 12px;
        width: 24px;

        ${isLifeFlowVariant &&
        css`
          width: 16px;
        `}
      }
    }

    &__loading-indicator {
      position: absolute;
      right: 70px;
      z-index: 1;
    }

    &__menu {
      border: 1px solid ${colors.silver};
      box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.09);
      z-index: 3;
    }

    &__placeholder {
      color: ${colors.dove};
      font-size: ${typography.fontSize};
      font-style: italic;
    }

    &__indicators {
      border-left: 1px solid ${colors.black};
      justify-content: center;
      width: 56px;

      ${shouldHighlightError &&
      css`
        border-left: 2px solid ${colors.red};
      `}

      ${isLifeFlowVariant &&
      css`
        width: 44px;
      `}
    }

    &__indicator-separator {
      display: none;
    }

    &__value-container {
      padding-left: 18px;
      ${isLifeFlowVariant &&
      css`
        padding-left: 12px;
      `}

      &--is-multi {
        padding-top: 15px;
        padding-bottom: 15px;
      }
    }

    &__single-value {
      color: ${colors.black};
    }

    &__multi-value {
      &__remove {
        &:hover {
          color: ${colors.white};
          background-color: ${colors.cornflower};
        }
      }
    }

    &__option {
      cursor: pointer;

      &--is-focused,
      &--is-focused:active {
        background-color: ${colors.mercury};
      }

      &--is-selected,
      &--is-selected:active {
        background-color: ${colors.azure};
        color: ${colors.white};
      }
    }
  }
`;
