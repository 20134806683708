import { css } from '@emotion/react';

import { mq, colors } from 'src/theme/variables';
import { headingXL, text32, text, text20 } from 'src/theme/typography';

export const heading = css`
  ${headingXL};
  margin-top: 0;
  margin-bottom: 12px;
  ${mq[0]} {
    margin-bottom: 32px;
  }
`;

export const faq = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const headingWrapper = css`
  flex: 1 0 100%;
  order: 1;

  ${mq[0]} {
    order: 0;
  }
`;

export const accordionWrapper = css`
  flex: 0 0 100%;
  order: 2;

  ${mq[0]} {
    order: 0;
    flex-basis: 57%;
  }

  ${mq[1]} {
    flex-basis: 50%;
    max-width: 575px;
  }
`;

export const sceneWrapper = css`
  width: 100%;
  ${mq[0]} {
    max-width: 259px;
    order: 1;
  }
  ${mq[1]} {
    flex-basis: 40%;
    max-width: 450px;
    position: relative;
    top: -70px;
  }
`;

export const scene = css`
  padding-top: 115%;
  position: relative;
  width: 100%;
  flex: 1 0 100%;
  margin-bottom: 30px;
  ${mq[1]} {
    margin-bottom: 0;
  }
`;

export const sceneInner = css`
  overflow: hidden;
  background-color: #ffc3a8;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

export const ballOne = css`
  position: absolute;
  top: 26%;
  right: -16%;
  width: 149px;
  height: 149px;
  border-radius: 50%;
  background: #fa7032;
  ${mq[1]} {
    top: 14%;
    width: 259px;
    height: 259px;
  }
`;

export const ballTwo = css`
  position: absolute;
  bottom: 2%;
  left: -50%;
  width: 314px;
  height: 314px;
  border-radius: 50%;
  background: ${colors.cherise};
  ${mq[0]} {
    width: 180px;
    height: 180px;
  }
  ${mq[1]} {
    left: -30%;
    width: 314px;
    height: 314px;
  }
`;

export const img = css`
  position: absolute;
  display: block;
  bottom: 0;
  max-width: 85%;
  margin-left: 3%;

  ${mq[0]} {
    max-width: 97%;
  }
`;

export const curl2 = css`
  position: absolute;
  top: 25%;
  left: 76%;
  width: 12%;
`;

export const arrowCurved = css`
  position: absolute;
  top: 68%;
  left: 56%;
  width: 50%;
  transform: rotate(-145deg);
  ${mq[0]} {
    transform: none;
    width: 30%;
    left: -20%;
    top: 42%;
  }
`;

export const idea = css`
  position: absolute;
  top: 5%;
  left: 12%;
  width: 28%;
`;

export const twoLines = css`
  position: absolute;
  top: 65%;
  left: 89%;
  width: 4%;
`;

export const contentWrapperCSS = css`
  display: flex;
  text-align: left;
  padding-bottom: 16px;
  flex-direction: column;
  width: 100%;

  img {
    width: 100%;
    margin-bottom: 12px;

    ${mq[0]} {
      max-width: 55%;
      max-height: 240px;
      margin-right: 14px;
    }

    ${mq[1]} {
      margin-bottom: 0;
      margin-right: 40px;
    }
  }

  ${mq[0]} {
    flex-direction: row;
  }
`;

export const headingCSS = css`
  text-align: left;
  ${text20};

  ${mq[0]} {
    ${text32};
    margin-bottom: 24px;
  }
`;

export const accordionCSS = css`
  ${text};

  span {
    ${text};
  }
`;

export const accordionKinWrapper = css`
  order: 2;

  ${mq[0]} {
    order: 0;
    flex-basis: 100%;
  }

  ${mq[1]} {
    flex-basis: 50%;
    max-width: 575px;
  }
`;
