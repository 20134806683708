import { css, SerializedStyles } from '@emotion/react';
import { text, text14 } from 'src/theme/typography';
import { mq, colors as c } from 'src/theme/variables';

export const fullHeightPageCSS = css`
  ${mq[0]} {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
`;

export const additionalBrowserUpdateSpaceCSS = (additionalSpace: number): SerializedStyles => css`
  :root {
    --browser-update-height: ${additionalSpace}px;
  }

  body .buorg {
    background-color: ${c.white};
    box-shadow: none;
    border-bottom: 1px solid ${c.nobel};
  }

  body #buorgig,
  body #buorgul {
    color: ${c.azure};
    background-color: ${c.white};
    font-weight: 700;
    ${text};
    padding: 12px 17px;
    box-shadow: none;
    border: 2px solid ${c.white};
    opacity: 1;
    transition: opacity 0.2s ease;
  }

  body .buorg .buorg-pad {
    display: grid;
    grid-template-areas:
      'icon mainmsg'
      'empty moremsg'
      'empty buttons';
    grid-template-columns: 29px;
    width: max-content;
    margin: 0 auto;
    text-align: start;
    align-items: center;
    padding: 16px 16px 20px 12px;
  }

  body #buorgig:hover,
  body #buorgul:hover {
    opacity: 0.7;
  }

  body #buorgul {
    border-color: ${c.azure};
  }

  body .buorg-icon {
    grid-area: icon;
  }

  body .buorg-mainmsg {
    grid-area: mainmsg;
    font-weight: 700;
    ${text};
  }

  body .buorg-moremsg {
    grid-area: moremsg;
    font-weight: 500;
    ${text14};
    line-height: 20px;
  }

  body .buorg-buttons {
    grid-area: buttons;
    padding-top: 16px;
  }

  body .buorg-fadeout {
    display: none;
    height: 0;
  }

  ${mq[0]} {
    body .buorg .buorg-pad {
      grid-template-areas:
        'icon mainmsg buttons'
        'empty moremsg buttons';
      padding-top: 24px;
      padding-bottom: 26px;
    }

    body .buorg-buttons {
      padding-top: 0;
      padding-left: 24px;
    }
  }
`;
