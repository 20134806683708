/** @jsxImportSource @emotion/react */
import CarriersList from 'src/components/CarriersList/CarriersList';
import GoogleReviews from 'src/components/Landing/GoogleReviews/GoogleReviews';
import {
  carrierContentCSS,
  googleReviewHeadingCSS,
  googleReviewsCSS,
  sectionWrapperCSS
} from './SavingsMainContent.style';
import {
  getAccordionItems,
  getCarriersListHeader,
  getCarriersListSubheader,
  getSavingsBenefitsSteps
} from './SavingsMainContent.util';
import HowItWorksSteps from 'src/components/HowItWorksSteps/HowItWorksSteps';
import { faqHorizontalScrollFixContainer } from 'src/theme/shared-styles';
import FAQ from 'src/components/FAQ/FAQ';
import SEGMENT from 'src/constants/segment';
import analytics from 'src/utils/analytics';
import { useParams } from 'react-router-dom';
import { DefaultLocation } from 'src/interfaces/IPage';
import { useConfig } from 'src/api/config';
import SERVICERS, { getServicerNameToDisplay } from 'src/constants/servicers';
import useResponsive from 'src/hooks/useResponsive';
import Container from 'src/kit/Container/Container';

const SavingsMainContent = () => {
  const { gid, flow } = useParams() as DefaultLocation;

  const { data: config } = useConfig(gid);

  const { isMobile } = useResponsive();

  const agentContactPhone = config?.partner?.agent_phone;
  const isPhhNewRezServicer = config?.servicer_key === SERVICERS.PHH_NRZ;
  const servicerName = getServicerNameToDisplay(config?.servicer_key, config?.servicer_name);

  const onGoogleReviewsClick = () =>
    analytics.track(SEGMENT.GOOGLE_REVIEWS_CLICKED, gid, flow, {
      layout: isMobile ? 'mobile' : 'desktop',
      location: SEGMENT.PAGES_KEY.SAVINGS
    });

  if (!config) {
    return null;
  }

  return (
    <>
      <section css={sectionWrapperCSS(true)}>
        <Container>
          <CarriersList
            customCSS={{ textContent: carrierContentCSS }}
            header={getCarriersListHeader(config.servicer_key, servicerName)}
            subheader={getCarriersListSubheader(isPhhNewRezServicer, servicerName)}
          />
        </Container>
      </section>

      <section>
        <Container customCSS={sectionWrapperCSS(false)}>
          <HowItWorksSteps heading="How it works" steps={getSavingsBenefitsSteps(servicerName)} />
        </Container>
      </section>

      <section css={sectionWrapperCSS(true)}>
        <Container>
          <GoogleReviews
            customCSS={{
              wrapper: googleReviewsCSS,
              heading: googleReviewHeadingCSS
            }}
            heading="Why homeowners trust Matic"
            withControls
            onGoogleReviewsClick={onGoogleReviewsClick}
          />
        </Container>
      </section>
      <section css={[sectionWrapperCSS(false), faqHorizontalScrollFixContainer]}>
        <Container>
          <FAQ
            page={SEGMENT.PAGES.SAVINGS}
            contactPhone={agentContactPhone}
            accordionItems={getAccordionItems(servicerName)}
          />
        </Container>
      </section>
    </>
  );
};

export default SavingsMainContent;
