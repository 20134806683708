import { css } from '@emotion/react';
import { mq, colors } from 'src/theme/variables';
import { text20, text, text24 } from 'src/theme/typography';

export const wrapperCSS = css`
  border: 2px solid ${colors.mercury};
  display: flex;
  flex-direction: column;
  text-align: center;
  border-radius: 4px;
  padding: 40px;
  align-items: center;
  position: relative;
`;

export const titleCSS = css`
  ${text20};
  margin-bottom: 12px;
  font-weight: 700;

  ${mq[0]} {
    ${text24};
  }
`;
export const descriptionCSS = css`
  ${text};

  ${mq[0]} {
    ${text20};
  }
`;

export const orangeTriangleCSS = css`
  position: absolute;
  border-radius: 2px;
  top: -20px;
  right: 16px;
  width: 0;
  height: 0;
  border-top: 40px solid ${colors.orange};
  border-left: 40px solid transparent;
`;

export const yellowTriangleCSS = css`
  position: absolute;
  border-radius: 2px;
  bottom: -20px;
  left: 16px;
  width: 0;
  height: 0;

  border-bottom: 40px solid ${colors.yellow};
  border-right: 40px solid transparent;
`;

export const anchorWhiteCSS = css`
  display: flex;
  border: 4px solid ${colors.azure};
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin: 16px auto 0 auto;
  box-sizing: border-box;

  ${text20};
  padding: 10px 12px;
  font-weight: bold;

  ${mq[0]} {
    width: 100%;
    margin: 0 0 0 12px;
  }

  img {
    width: 25px;
  }
`;
