/** @jsxImportSource @emotion/react */

import { foreverLockedIconCSS, startOverMessageCSS } from './ForeverLockedCardContent.style';
import foreverLockIcon from 'src/assets/svg/forever-lock-session.svg';
import { phoneNumberCSS } from 'src/kit/Header/HeaderContacts/HeaderContacts.style';
import Anchor from 'src/kit/Anchor/Anchor';
import Format from 'src/utils/format';
import Button from 'src/kit/Button/Button';
import SEGMENT from 'src/constants/segment';
import analytics from 'src/utils/analytics';
import { useParams } from 'react-router-dom';
import { DefaultLocation } from 'src/interfaces/IPage';
import { ButtonSize } from 'src/kit/Button/types';
import { ForeverLockedCardProps } from './ForeverLockedCardContent.types';

const ForeverLockedCardContent: React.FC<ForeverLockedCardProps> = ({ locked, startOver }) => {
  const { flow, gid } = useParams() as DefaultLocation;

  const onPhoneClick = () =>
    analytics.track(SEGMENT.PHONE_CONVERSATION_INITIATED, gid, flow, {
      location: SEGMENT.LOCATIONS.SESSION_FOREVER_LOCKED_CARD,
      page: SEGMENT.PAGES_KEY.SESSION_TIMEOUT
    });

  const parsedLockMessage = locked?.message!.split('{phone}');

  return (
    <>
      <img src={foreverLockIcon} alt="Session locked forever icon" css={foreverLockedIconCSS} />
      <h2>Let’s start Over!</h2>
      <div css={startOverMessageCSS}>
        {parsedLockMessage?.[0]}
        <Anchor
          anchorType="underline-active"
          href={`tel:${locked?.phone}`}
          onClick={onPhoneClick}
          customCSS={phoneNumberCSS}
        >
          {Format.phone(locked.phone!)}
        </Anchor>
        {parsedLockMessage?.[1]}
      </div>
      <Button fullWidth size={ButtonSize.Large} onClick={startOver}>
        Start Over
      </Button>
    </>
  );
};

export default ForeverLockedCardContent;
