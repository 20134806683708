const ONLY_FLOW_KEY = '/:flow';
const FLOW = `${ONLY_FLOW_KEY}/:gid`;

const ROUTES = {
  ONLY_FLOW_KEY,
  FLOW,
  QUESTIONS: `${FLOW}/questions`,
  INTERSTITIAL: `${FLOW}/interstitial`,
  QUOTES: `${FLOW}/quotes`,
  CONFIRMATION: `${FLOW}/confirmation`,
  FLOW_PROBLEM: `${FLOW}/problem`,
  PROBLEM: '/problem',
  ALL_FUNNEL: '/all_funnel/:gid',
  SAVINGS: '/savings/:gid',
  NO_OPTIONS: `${FLOW}/no-options`,
  LANDING: '/landing',
  LANDING_V2: '/landing/v2',
  LANDING_V1: '/landing/v1',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  INVALID_ROUTE: '/*',
  SESSION_TIMEOUT: `${FLOW}/session-timeout`,
  QUOTES_VIEW: 'quotes-view'
} as const;

export default ROUTES;
