/** @jsxImportSource @emotion/react */
import {
  wrapperCSS,
  disclosuresCSS,
  userCSS,
  headingCSS,
  itemCSS,
  dividerCSS,
  itemWrapperCSS
} from './UserProfile.style';
import Format from 'src/utils/format';
import UserProfileDetailsItem from 'src/components/Quotes/StandardHouseQuotes/QuotesUserProfile/UserProfileDetailsItem/UserProfileDetailsItem';
import CalendarIcon from 'src/assets/svg/mini-calendar.svg';
import CakeIcon from 'src/assets/svg/cake.svg';
import LocationIcon from 'src/assets/svg/location.svg';
import MoneyIcon from 'src/assets/svg/money.svg';
import { FS_SENSITIVE_DATA_CLASS } from 'src/constants/fullStory';

export interface UserProfileProps {
  firstName: string;
  lastName: string;
  state?: string;
  age?: number | string;
  loan_balance?: number;
  loan_duration?: number;
}

const UserProfile: React.FC<UserProfileProps> = ({
  firstName = '',
  lastName = '',
  state,
  age,
  loan_balance,
  loan_duration
}) => {
  return (
    <div css={wrapperCSS} data-testid="life-profile">
      <div css={headingCSS}>Your Profile</div>
      <div css={userCSS} className={FS_SENSITIVE_DATA_CLASS.MASK}>{`${firstName} ${lastName}`}</div>
      {!!state && (
        <div css={itemWrapperCSS}>
          <img src={LocationIcon} alt="Location Marker" />
          <UserProfileDetailsItem customCSS={itemCSS} fullWidth label="Your State" value={state} valueSuffix="" />
        </div>
      )}
      {!!age && (
        <div css={itemWrapperCSS}>
          <img src={CakeIcon} alt="Birthday Cake" />
          <UserProfileDetailsItem customCSS={itemCSS} fullWidth label="Age" value={age} valueSuffix="" />
        </div>
      )}
      <hr css={dividerCSS} />
      {!!loan_balance && (
        <div css={itemWrapperCSS} className={FS_SENSITIVE_DATA_CLASS.MASK}>
          <img src={MoneyIcon} alt="Money" />
          <UserProfileDetailsItem
            customCSS={itemCSS}
            fullWidth
            label="Loan Balance"
            value={Format.money(loan_balance!, 9, '$')}
            valueSuffix=""
          />
        </div>
      )}
      {!!loan_duration && (
        <div css={itemWrapperCSS}>
          <img src={CalendarIcon} alt="Mini Calendar" />
          <UserProfileDetailsItem
            customCSS={itemCSS}
            fullWidth
            label="Remaining Loan Duration (yrs.)"
            value={loan_duration}
            valueSuffix=""
          />
        </div>
      )}

      <div css={disclosuresCSS}>
        Your mortgage may be your family&apos;s largest monthly bill. Consider a life insurance policy that lasts as
        long as your mortgage payments, to help ensure monthly costs are covered.
      </div>
    </div>
  );
};

export default UserProfile;
