/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo, useRef } from 'react';

import { useForm, FormProvider } from 'react-hook-form';

import { useParams } from 'react-router-dom';

import QuestionsForm from 'src/components/Life/QuestionsForm/QuestionsForm';
import { DefaultLocation, Flows } from 'src/interfaces/IPage';
import { Answers } from 'src/interfaces/IAnswer';
import SecurityIcon from 'src/assets/svg/check-shield.svg';
import useQuestions from 'src/api/questions/useQuestions';
import { useLifeQuotesStateData, useLifeQuotesStatesActions } from 'src/contexts/life-flow-context';
import useResponsive from 'src/hooks/useResponsive';
import UnlockImg from 'src/assets/svg/unlocked.svg';
import LockImg from 'src/assets/svg/locked-lock.svg';
import ErrorIcon from 'src/assets/svg/error.svg?react';
import { PersonLifeProperties } from 'src/components/LifeQuotes/LifeQuotes.types';

const emptyObject = {};
import {
  wrapperCSS,
  headingCSS,
  basedBlockWrapperCSS,
  questionsCSS,
  iconCSS,
  wrapperBottomCSS,
  phoneCSS
} from './QuestionsWrapper.style';
import { getLifeFormErrorMessage, getWarningMessage, inRange } from 'src/utils/lifeFlow.utils';
import { colors } from 'src/theme/variables';
import { getAge } from 'src/utils/date';
import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';
import patternReplacer from 'src/utils/patternReplacer';
import Anchor from 'src/kit/Anchor/Anchor';
import Format from 'src/utils/format';

const QuestionsWrapper: React.FC<{ agentPhone: string }> = ({ agentPhone }) => {
  const { gid, flow } = useParams() as DefaultLocation;
  const { isMobile } = useResponsive();
  const { data: questions } = useQuestions(gid);
  const answers = questions?.answers || (emptyObject as Answers);
  const { isBasedBlockVisible, shouldShowWarning, currentOptionsRange } = useLifeQuotesStateData();
  const { setShowWarning, setIsButtonDisabled } = useLifeQuotesStatesActions();
  const ageErrorReported = useRef(false);
  const shouldCheckAddress = useRef(true);

  const defaultValues: Answers = useMemo(
    () => ({ ...answers, ...(shouldCheckAddress.current && { property_address: emptyObject }) }),
    [answers]
  );

  const methods = useForm({
    defaultValues: answers,
    shouldUnregister: false,
    shouldFocusError: false
  });

  const errorsKeys = Object.keys(methods.formState.errors);
  const shouldShowError = !!errorsKeys.length;
  const tobaccoUse = methods.watch(PersonLifeProperties.TobaccoUse);
  const birth = methods.watch(PersonLifeProperties.DateOfBirth);
  const tobaccoState = methods.getFieldState(PersonLifeProperties.TobaccoUse);
  const birthState = methods.getFieldState(PersonLifeProperties.DateOfBirth);
  const termLength = methods.watch(PersonLifeProperties.TermLength);

  useEffect(() => {
    if (!errorsKeys.length) {
      setIsButtonDisabled(false);
    }
  }, [errorsKeys.length, setIsButtonDisabled]);

  useEffect(() => {
    if (errorsKeys.length) {
      analytics.track(SEGMENT.LIFE_SCHEMA.VALIDATION_SHOWN, gid, flow);
    }
  }, [errorsKeys.length, flow, gid]);

  useEffect(() => {
    methods.reset(defaultValues);
  }, [defaultValues, methods]);

  useEffect(() => {
    const isTermInRange = inRange(termLength as number, currentOptionsRange);

    if (tobaccoUse && birth && (tobaccoState.isTouched || birthState.isTouched) && !isTermInRange) {
      setShowWarning(true);
      analytics.track(SEGMENT.LIFE_SCHEMA.SELECT_TERM_WARNING_SHOWN, gid, flow);
      methods.setValue(PersonLifeProperties.TermLength, null);
      methods.trigger(PersonLifeProperties.DateOfBirth);
    }
  }, [
    flow,
    gid,
    birth,
    birthState.isTouched,
    currentOptionsRange,
    methods,
    setShowWarning,
    termLength,
    tobaccoState.isTouched,
    tobaccoUse
  ]);

  useEffect(() => {
    !!termLength && setShowWarning(false);
  }, [setShowWarning, termLength]);

  useEffect(() => {
    if (errorsKeys.includes(PersonLifeProperties.DateOfBirth) && !ageErrorReported.current) {
      analytics.track(SEGMENT.LIFE_SCHEMA.AGE_ERROR_SHOWN, gid, Flows.Life);
      ageErrorReported.current = true;
    }
  }, [errorsKeys, gid]);

  const onPhoneClick = () =>
    analytics.track(SEGMENT.PHONE_CONVERSATION_INITIATED, gid, flow, {
      location: SEGMENT.LOCATIONS.FORM_VALIDATION_MESSAGE,
      page: SEGMENT.PAGES_KEY.LIFE
    });

  const phoneLink = () => (
    <Anchor anchorType="underline-active" href={`tel:${agentPhone}`} css={phoneCSS} onClick={onPhoneClick}>
      {Format.phone(agentPhone)}
    </Anchor>
  );

  const getBottomBlockContent = () => {
    switch (true) {
      case !!isBasedBlockVisible:
        return (
          <div css={basedBlockWrapperCSS(true, colors.honeydew)}>
            <img src={SecurityIcon} alt="Security" css={iconCSS(false)} />
            <span>Based on your answers, the life insurance quotes below most closely match your needs.</span>
          </div>
        );
      case !!shouldShowError:
        return (
          <div css={basedBlockWrapperCSS(false, colors.blush)}>
            <ErrorIcon css={iconCSS(false)} />
            <span>
              {patternReplacer(getLifeFormErrorMessage(errorsKeys, tobaccoUse as string), {
                phone: phoneLink()
              })}
            </span>
          </div>
        );
      case !!shouldShowWarning && !isNaN(getAge(birth as string) as number):
        return (
          <div css={basedBlockWrapperCSS(false, colors.peachCream)}>
            <ErrorIcon css={iconCSS(true)} />
            <span>{getWarningMessage(currentOptionsRange)}</span>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div css={wrapperCSS}>
      <div css={questionsCSS}>
        <div css={headingCSS}>
          {isMobile && <img src={isBasedBlockVisible ? UnlockImg : LockImg} alt="Unlocked lock" height={17} />}
          <span>Answer a few questions to unlock your term life insurance quotes</span>
        </div>
        <div>
          <FormProvider {...methods}>
            <QuestionsForm />
          </FormProvider>
        </div>
      </div>
      <div css={wrapperBottomCSS}>{getBottomBlockContent()}</div>
      <div></div>
    </div>
  );
};

export default QuestionsWrapper;
