import { BaseForm } from './form.types';
import { Rule } from 'src/questionsForm/utils/ruleValidator.utils';
import { QuestionSwitchProps } from './IQuestion';
import { QuestionsFormPages } from 'src/questionsForm/types/questionsFormPage.types';

export enum Flows {
  Home = 'home',
  Auto = 'auto',
  AutoBundle = 'bundle',
  Savings = 'savings',
  AllFunnel = 'all_funnel',
  Lookup = 'lookup',
  Florida = 'florida',
  Accord = 'accord',
  Life = 'life'
}

export interface DefaultLocation extends Record<string, string> {
  flow: Flows;
  gid: string;
}

export interface Greeting {
  heading?: string;
  heading_icon_path?: string;
  fixed_on_scroll?: boolean;
  subheading?: string;
  paragraph?: string;
  tracking_forbidden?: boolean;
  softLanding?: boolean;
  column1_title?: string;
  column1_body?: string;
  column2_title?: string;
  column2_body?: string;
  column3_title?: string;
  column3_body?: string;
}

export type EntityBase = Record<string, string | boolean | number | null | Record<string, string>>;

export interface Entity extends EntityBase {
  gid: string;
}

export interface HookFormFieldEntity extends Entity {
  id: string;
}

interface Action {
  label: string;
  visibility_conditions?: Rule[];
}

interface Skip extends Action {
  keys?: string[];
}

export interface PageAction extends Action {
  skip?: Skip;
  label: string;
  visibility_conditions?: Rule[];
  disable_conditions?: Rule[];
  with_phone_input?: boolean;
  with_disclosures_checkbox?: boolean;
}

export interface EntityList {
  heading?: string;
  add_action: string;
  item_label: string;
  key_name: string;
  template: string;
  tracking_forbidden?: boolean;
  fields: QuestionSwitchProps[];
}

export interface EntityForm {
  greeting?: Greeting;
  questions?: BaseForm[];
}

export interface Page {
  heading?: string;
  key_name: string;
  questions?: BaseForm[];
  greeting?: Greeting;
  action: PageAction;
  type: QuestionsFormPages;
  list?: EntityList;
  form?: EntityForm;
  visibility_conditions?: Rule[];
}
