export enum CoverageType {
  Basic = 'basic',
  Recommended = 'recommended',
  Premium = 'premium'
}

export interface CoverageTypeOption {
  value: CoverageType;
  label: string;
  description: string;
}
