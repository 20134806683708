/** @jsxImportSource @emotion/react */
import React, { Fragment } from 'react';
import { ScheduleCallHeadingProps } from './ScheduleCallHeading.types';
import { headingContainerCSS, headingCSS, subheadingCSS } from './ScheduleCallHeading.style';

const ScheduleCallHeading: React.FC<ScheduleCallHeadingProps> = ({
  step,
  customHeading = null,
  shouldHideFinalHeading = false,
  shouldHideHeading = false,
  isPolicyHolderVariation = false,
  isLifeSimplified = false
}) => {
  const finalHeading = !shouldHideFinalHeading && (
    <Fragment>
      <p css={headingCSS()}>You’re All Set!</p>
      <p css={subheadingCSS}>A confirmation email with your appointment details has been sent.</p>
    </Fragment>
  );

  const defaultHeading =
    customHeading ||
    (!shouldHideHeading && (
      <Fragment>
        <p css={headingCSS(isLifeSimplified)}>You’re Almost Done!</p>
        <p css={subheadingCSS}>
          {isPolicyHolderVariation ? (
            <Fragment>
              <p>A Licensed Customer Service Specialist will reach out to you shortly.</p>
              <p css={subheadingCSS}>Prefer to chat at a time that’s convenient for you? Please select a time below.</p>
            </Fragment>
          ) : isLifeSimplified ? (
            <Fragment>
              <p> Schedule a call to review the details of your quote and finalize your policy. </p>
            </Fragment>
          ) : (
            <Fragment>
              <p> A Matic Advisor will reach out to you shortly to review details and finalize your policy. </p>
              <p css={subheadingCSS}>Prefer to chat at a time that’s convenient for you? Please select a time below.</p>
            </Fragment>
          )}
        </p>
      </Fragment>
    ));

  return <section css={headingContainerCSS}>{step === 3 ? finalHeading : defaultHeading}</section>;
};

export default ScheduleCallHeading;
