import { QueryObserverResult, useQuery } from 'react-query';
import queryString from 'query-string';
import api from 'src/api/api';
import { IAssets } from 'src/interfaces/quotesView/IAssets';

const useAssets = (personGid: string | null, assetsGids: string[] | null): QueryObserverResult<IAssets> => {
  return useQuery(
    ['quotes_view_assets', personGid, assetsGids],
    () =>
      api.get(
        `/api/quote_pdf/people/${personGid}/assets?${queryString.stringify(
          { assets_gids: assetsGids },
          { arrayFormat: 'bracket' }
        )}`
      ),
    {
      enabled: !!personGid && !!assetsGids
    }
  );
};

export default useAssets;
