/** @jsxImportSource @emotion/react */
import React from 'react';

import { linkCSS } from './Anchor.style';
import { AnchorProps } from './types';
import { visuallyHidden } from 'src/theme/helpers';

const Anchor: React.FC<AnchorProps> = ({
  href,
  target = '_self',
  anchorType = 'default',
  inverted = false,
  customCSS,
  children,
  ...props
}) => {
  const openNewTab = target === '_blank';
  return (
    <a
      css={[linkCSS(anchorType, inverted), customCSS]}
      {...props}
      href={href}
      target={target}
      rel={openNewTab ? 'noopener noreferrer' : ''}
    >
      {children}
      {openNewTab && <span css={visuallyHidden}>(opens a new window)</span>}
    </a>
  );
};

export default Anchor;
