/** @jsxImportSource @emotion/react */
import React from 'react';
import MobileHouse from 'src/assets/svg/bndl-house.svg';
import MobileCar from 'src/assets/svg/bndl-car.svg';
import {
  bndlMsgBannerCSS,
  accordBndlWrapperCSS,
  tagCSS,
  bndlImgsWrapperCSS,
  bndlHeaderCSS,
  phoneNumberCSS
} from 'src/components/Quotes/StandardHouseQuotes/StandardHouseQuotes.style';
import Anchor from 'src/kit/Anchor/Anchor';
import Format from 'src/utils/format';

interface AccordBundleMsgProps {
  contactPhone: string;
  onContactPhoneClick: () => void;
}

export const AccordBundleMsg: React.FC<AccordBundleMsgProps> = ({ contactPhone, onContactPhoneClick }) => {
  return (
    <div css={[bndlMsgBannerCSS, accordBndlWrapperCSS]}>
      <div css={bndlImgsWrapperCSS}>
        <img src={MobileHouse} alt="House plus car icon" />
        <div>
          <div css={tagCSS}>Up to 20% Off</div>
          <img src={MobileCar} alt="House plus car icon" />
        </div>
      </div>
      <div>
        <div css={bndlHeaderCSS}>Did you know? Matic can find you savings on auto, too.</div>
        <span>
          If you buy home + auto you can save up to 20%. If you are interested, you can discuss your options with our
          agents.{' '}
          <Anchor
            anchorType="underline-active"
            href={`tel:${contactPhone}`}
            onClick={onContactPhoneClick}
            customCSS={phoneNumberCSS}
          >
            {Format.phone(contactPhone)}
          </Anchor>
        </span>
      </div>
    </div>
  );
};
