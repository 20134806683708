import { css } from '@emotion/react';
import { text48, text24, text20, text32, text } from 'src/theme/typography';
import { colors, mq, onlyMobile } from 'src/theme/variables';

export const containerCSS = css`
  margin-top: 10px;
  ${onlyMobile} {
    padding: 0;
  }
`;

export const wrapperCSS = css`
  background: ${colors.lemonChiffon};
  position: relative;
  min-height: 470px;
  padding: 20px;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  max-width: 1344px;

  ${mq[0]} {
    padding: 40px;
    margin-bottom: 40px;
    border-radius: 12px;
  }

  ${mq[1]} {
    padding: 80px;
  }
`;

export const triangleRelativeWrapperCSS = css`
  position: absolute;
  right: 0;
  bottom: 0;
  height: 465px;
  width: 300px;
  overflow: hidden;

  ${mq[0]} {
    height: 300px;
  }
`;

export const headingCSS = css`
  ${text32};
  font-weight: 700;
  line-height: 40px;
  padding-bottom: 12px;

  ${mq[0]} {
    ${text48};
    font-weight: 900;
    line-height: 60px;
    padding-bottom: 4px;
  }
`;

export const descriptionCSS = css`
  ${text20};
  font-weight: 700;
  max-width: 520px;

  ${mq[0]} {
    ${text24};
  }
`;

export const textWrapperCSS = css`
  max-width: 88%;

  ${mq[1]} {
    max-width: 60%;
  }
`;

export const contactLinkWrapperCSS = css`
  ${text};
  margin-top: 30px;
  max-width: 300px;

  ${mq[0]} {
    ${text20}

    max-width: 44%;
  }

  ${mq[1]} {
    margin-top: 40px;
    margin-left: 150px;
  }

  @media screen and (min-width: 1100px) {
    max-width: 380px;
  }
`;

export const familyImgCSS = css`
  position: absolute;
  right: 0px;
  bottom: 0;

  @media screen and (max-width: 440px) {
    width: 60%;
  }

  width: 45%;

  ${mq[0]} {
    right: 11px;
    bottom: 0;
    width: 40%;
  }
`;

export const orangeTriangleCSS = css`
  position: absolute;
  width: 22%;
  right: 0;

  ${mq[0]} {
    width: 55%;
  }
`;

export const yellowTriangleCSS = css`
  position: absolute;
  left: 16px;
  bottom: 26px;
  width: 116px;

  ${mq[1]} {
    left: 46px;
    bottom: 26px;
    width: unset;
  }
`;

export const checkmarkCSS = css`
  display: none;

  ${mq[0]} {
    display: block;
    position: absolute;
    left: 175px;
    bottom: 82px;
  }
`;

export const glassCSS = css`
  position: absolute;
  width: 110px;
  transform: rotate(40deg);
  left: 19px;
  bottom: 0;

  line,
  circle {
    stroke-width: 3px;
  }

  ${mq[0]} {
    transform: none;
    left: 25px;
    bottom: -25px;
    width: 170px;

    line,
    circle,
    path {
      stroke-width: 2px;
    }
  }

  ${mq[1]} {
    width: unset;
  }
`;

export const anchorCSS = css`
  display: inline;
  font-weight: bold;
  border-bottom: 3px solid ${colors.azure};
  cursor: pointer;
`;

export const mobilePaddingCSS = css`
  height: 142px;

  ${mq[0]} {
    display: none;
  }
`;

export const heartCSS = css`
  position: absolute;
  width: 20px;
  bottom: 20%;
  right: 5%;

  path {
    stroke-width: 4px;
  }

  ${mq[0]} {
    width: 47px;
    right: 5%;
    bottom: 45%;

    path {
      stroke-width: 2px;
    }
  }
`;
