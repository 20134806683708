import { css } from '@emotion/react';
import { typography } from 'src/theme/variables';

export const wrapperCSS = css`
  font-family: ${typography.fontFamily};
`;

export const containerCSS = css`
  text-align: center;
`;

export const logoCSS = css`
  display: inline-block;
  margin: 40px 0;
`;
