import api from 'src/api/api';
import { API_URLS } from 'src/constants/api';
import { getApiUrl } from 'src/utils/api.helpers';

const adBlockerService = {
  trackAdBlockerUsage(gid: string, isUsed: boolean): Promise<any> {
    return api.put(getApiUrl(API_URLS.AD_BLOCKER, gid), { body: { active_ad_blocker: isUsed } });
  }
};

export default adBlockerService;
