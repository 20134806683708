import { css, SerializedStyles } from '@emotion/react';
import { colors, mq } from 'src/theme/variables';
import { text, text14, text24, text32, text40 } from 'src/theme/typography';

export const containerCSS = css`
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  ${mq[0]} {
    min-width: 315px;
  }
  ${mq[1]} {
    min-width: auto;
  }
`;

export const metadataWrapperCSS = css`
  margin: 0 auto;
  text-align: center;
  min-width: 140px;
  color: 'black';
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${mq[1]} {
    min-width: 115px;
  }
`;

export const valueRowCSS = css`
  ${text24};
  line-height: 44px;
  font-weight: 700;
  vertical-align: middle;
`;

export const premiumRowCSS = (
  highlightPremium?: boolean,
  directSalesToDigitalProfileTestVariationA?: boolean
): SerializedStyles => css`
  ${text40};
  ${mq[0]} {
    color: ${highlightPremium ? colors.darkGreen : 'inherit'};
  }

  ${mq[1]} {
    ${text32};

    ${directSalesToDigitalProfileTestVariationA &&
    css`
      width: 205px;
      margin-right: 16px;
    `}
  }
`;

export const labelRowCSS = (highlightPremium?: boolean): SerializedStyles => css`
  ${text14};
  font-weight: 500;
  color: ${highlightPremium ? colors.darkGreen : 'inherit'};
  padding-top: 2px;
  ${mq[1]} {
    ${text};
  }
`;

export const bndlMsqPremiumValueCSS = css`
  ${text14};
  color: ${colors.dove} !important;
  text-decoration: line-through;
  position: absolute;
  top: -15px;
`;
