/** @jsxImportSource @emotion/react */
import React, { Fragment } from 'react';

import TextHighlight from 'src/components/TextHighlight/TextHighlight';
import {
  termsAndConditionsSubtitleCSS,
  termsAndConditionsTextCSS,
  termsAndConditionsTitleCSS
} from './TermsAndConditionsPage.style';

const TermsAndConditionsPage: React.FC = () => {
  return (
    <Fragment>
      <h2 css={termsAndConditionsTitleCSS}>Terms and Conditions</h2>
      <TextHighlight customCSS={termsAndConditionsTextCSS}>
        <h3 css={termsAndConditionsSubtitleCSS}>Customer Referral Rewards Program (“Referral Program”)</h3>
        <p>Matic Insurance Services Inc. (“Matic”)</p>
        <p>
          Matic’s Referral Program allows Matic policyholders, residing in eligible states and who hold policies through
          eligible insurance carriers (an “Eligible Policyholder”), to earn rewards for Qualified Referrals.  By making
          such a referral, an Eligible Policyholder agrees to these Referral Program Terms and Conditions (“Ts&Cs”), as
          well as Matic’s Terms of Use and Privacy Policy.
        </p>

        <h4>Definitions</h4>
        <p>
          “Eligible Policyholder” means a Matic policyholder residing in an eligible state and who holds a policy
          through an eligible insurance carrier.
        </p>
        <p>
          “Qualified Referral” means a referral through Matic’s Referral Program that results in an eligible individual
          obtaining a quote for insurance products and services from Matic. A referral does not need to purchase or
          renew an insurance policy to become a Qualified Referral.
        </p>
        <p>“Referrer” means an Eligible Policyholder that makes a Qualified Referral.</p>
        <p>
          “Reward for a Qualified Referral” or “Reward(s)” means a $15 Amazon e-gift card (subject to other requirements
          set forth in the “Eligibility” and “Rewards” sections of these Ts&Cs).
        </p>

        <h4>Referral Program Overview</h4>
        <p>
          Matic will provide Eligible Policyholders with a unique Referral Program link (“Link”) to a Matic website to
          enable submission and matching of referrals.
        </p>
        <p>
          By participating in Matic’s Referral Program, you agree not to: use automated systems or bots to distribute
          your Link; pay to advertise your Link; provide false information or instruct referrals to provide false
          information on an insurance application; provide your Link to referrals without a legitimate interest in
          applying for such insurance; promote Matic’s Referral Program as a money-making scheme, including without
          limitation posting about Matic’s Referral Program in any online forum or group related to making money via
          online reward programs; engage in any activity related to Matic’s Referral Program that may be considered
          fraudulent, invasive, or spamming; represent that you are an Advisor or representative of Matic; discuss
          specific insurance terms or conditions or otherwise engage in the sale, solicitation, or negotiation of
          insurance; or represent yourself as an insurance professional or expert; or act in any way that would require
          an insurance license in you or your referral’s state.
        </p>
        <h4>Eligibility</h4>
        <p>
          Matic’s Referral Program is subject to applicable local, state, and federal laws and regulations and is void
          where prohibited.
        </p>
        <p>To participate in Matic’s Referral Program, your referral must be a legal resident of an eligible state.</p>
        <p>
          Ineligible referrals that will not result in a Reward are: a referral with no insurable interest (i.e., does
          not own a home and/or automobile); current Matic clients; a referral that resides in the same residence as the
          Referrer; a referral that does not reside in an eligible state, and a Referrer that holds a policy through an
          ineligible insurance carrier.
        </p>

        <h4>Eligible States</h4>
        <p>
          Matic’s Referral Program is only available to Matic policyholders in the following states: California;
          Colorado; Connecticut; Georgia; Idaho; Illinois; Iowa; Indiana; Maryland; Minnesota; Missouri; Nevada; North
          Carolina; Ohio; Oregon; Tennessee; Vermont; Virginia; Washington; Washington D.C.; Wisconsin.
        </p>

        <h4>Ineligible Insurance Carriers</h4>
        <p>Matic’s Referral Program is not available to Mercury Insurance policyholders.</p>

        <h4>Rewards</h4>
        <p>
          A Referrer will not receive more than one (1) Reward during any twelve (12) month period. A Referrer will not
          receive a Reward for a referral that resides in the same residence as the Referrer. You will be notified via
          the email address you provide through your Link when you have earned a Reward. Notice will be provided within
          four (4) weeks of submission of a Qualified Referral. You will claim your Reward via a link in the email.
          Rewards are administered by a third-party.
        </p>
        <p>
          You consent to sharing your personal information with the third-party administrator to participate in Matic’s
          Referral Program.
        </p>
        <p>Matic will not replace a Reward for any reason.</p>

        <h4>Taxes and Fees</h4>
        <p>
          You are responsible for all applicable taxes and fees resulting from any Reward you receive through Matic’s
          Referral Program.
        </p>

        <h4>Release of Liability</h4>
        <p>
          By participating in Matic’s Referral Program, you release Matic and its directors, officers, employees,
          affiliates, representatives, advisors, consultants and legal and financial advisors from all liability,
          including without limitation all liability with respect to Matic’s Referral Program and Rewards.
        </p>

        <h4>Severability</h4>
        <p>
          If any part of these Ts&Cs is held to be invalid, void, or unenforceable, such part (or the part of it that is
          making it invalid, void, or unenforceable) will be struck and will not affect the validity and enforceability
          of the remaining parts.
        </p>

        <h4>Rights Reserved</h4>
        <p>
          Matic may update these Ts&Cs, including without limitation the Eligibility, Eligible States, and Rewards
          sections at any time and for any reason without prior notice. Such modification is effective upon posting of
          the modified Ts&Cs on the applicable Matic website. Continued participation in Matic’s Referral Program after
          any modification shall constitute consent to such modification. Matic may terminate its Referral Program or
          any participant’s use of its Referral Program at any time for any reason without notice. Matic will review
          referrals to confirm their validity as specified under the “Eligibility” section of these Ts&Cs.
        </p>

        <h4>Contact Us</h4>
        <p>
          If you have any questions about Matic’s Referral Program, please contact Matic by email at{' '}
          <a href="mailto:customerservice@matic.com">customerservice@matic.com</a>.
        </p>
      </TextHighlight>
    </Fragment>
  );
};

export default TermsAndConditionsPage;
