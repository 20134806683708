import { css } from '@emotion/react';

import { mq, colors as c } from 'src/theme/variables';
import { text12, text14 } from 'src/theme/typography';
import SIRV from 'src/constants/sirv';

export const termsAndConditionsLayoutContentCSS = css`
  padding-bottom: 40px;
  padding-top: 8px;

  ${mq[0]} {
    padding-bottom: 40px;
    padding-top: 20px;
  }

  ${mq[1]} {
    padding-bottom: 88px;
  }
`;

export const termsAndConditionsLayoutFooterCSS = css`
  color: ${c.warmGray};
  ${text12};
  text-align: center;
  background: url(${SIRV.CONFETTI}) no-repeat;
  background-size: cover;
  height: 85px;
  width: 90%;
  margin: 0 auto;

  ${mq[1]} {
    ${text14};
  }
`;

export const termsAndConditionsLayoutLogoCSS = css`
  justify-content: center;
  padding-top: 44px;
`;
