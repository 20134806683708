import { css, SerializedStyles } from '@emotion/react';
import { mq } from 'src/theme/variables';
import { QuoteLogoSize } from './QuoteLogo.types';
import Constants from './QuoteLogo.constants';

export const container = (size: QuoteLogoSize): SerializedStyles => css`
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  min-width: 120px;
  ${mq[0]} {
    min-width: ${Constants.styleLimits[size].container.desktop.minWidth}px;
  }
  img {
    max-height: ${Constants.styleLimits[size].image.mobile.maxHeight}px;
    max-width: ${Constants.styleLimits[size].image.mobile.maxWidth}px;

    ${mq[0]} {
      max-width: ${Constants.styleLimits[size].image.desktop.maxWidth}px;
      max-height: ${Constants.styleLimits[size].image.desktop.maxHeight}px;
    }
  }
`;
