const SEGMENT = {
  PAGES: {
    QUOTES: 'Quotes Page',
    LANDING: 'Landing Page',
    CONFIRMATION: 'Confirmation Page',
    QUESTIONS: 'Questions Page',
    DISCLOSURES: 'Disclosures Page',
    TERMS_AND_CONDITIONS: 'Terms and Conditions Page',
    SAVINGS: 'Savings Page',
    All_FUNNEL: 'All Funnel Page',
    NO_OPTIONS: 'No Options Page',
    ERROR: 'Error Page',
    INTERSTITIAL: 'Interstitial Page',
    LOOKUP: 'Customer Look Up Page By Zip',
    LOOKUP_FROM_DIRECT_MAIL: 'Customer Lookup Page from Direct Mail',
    SESSION_TIMEOUT: 'Session Timeout Page',
    QUOTES_VIEW: 'Quotes View Page',
    LIFE: 'Life Quotes Page'
  },
  PAGES_KEY: {
    QUOTES: 'quotes',
    LANDING: 'landing',
    CONFIRMATION: 'confirmation',
    QUESTIONS: 'questions',
    DISCLOSURES: 'disclosures',
    ALL_FUNNEL: 'all_funnel',
    SAVINGS: 'savings',
    NO_OPTIONS: 'no_options',
    LOOKUP: 'lookup',
    ERROR: 'error',
    INTERSTITIAL: 'interstitial',
    SESSION_TIMEOUT: 'session_timeout',
    LIFE: 'life'
  },
  LOCATIONS: {
    HEADER: 'header',
    FOOTER: 'footer',
    AGENT_CARD: 'agent_card',
    MANUAL_QUOTE_CARD: 'manual_quote_card',
    BUNDLE_AUTO_ONLY_MESSAGE: 'bundle_auto_only_message',
    BUNDLE_HOME_ONLY_MESSAGE: 'bundle_home_only_message',
    QUOTES_BANNER: 'quotes_banner',
    MATIC_TEAM_BLOCK: 'matic_team_block',
    QUOTES_HERO: 'quotes_hero',
    NO_QUOTES_CARRIERS_LIST: 'no_quotes_carriers_list',
    QUOTES_SAVINGS_BUNDLE_BANNER: 'savings_bundle_offer',
    SESSION_FOREVER_LOCKED_CARD: 'session_forever_locked_card',
    AF_PRIVACY_MATTERS: 'af_privacy_matters',
    SF_PRIVACY_MATTERS: 'sf_privacy_matters',
    CALL_ME_NOW_CARD: 'call_me_now_card',
    BUNDLE_MESSAGE_ACCORD: 'accord_bundle_message',
    FORM_VALIDATION_MESSAGE: 'form_validation_message',
    LIFE_FORM: 'life_form'
  },
  CHANGE_ATTEMPTED_RESOLUTION: {
    SUCCESS: 'success',
    ERROR: 'validation error'
  },
  SUBMIT_RESOLUTION: {
    NOT_FOUND: 'not found',
    SUCCESS: 'success'
  },
  BROWSER_UPDATE: {
    SHOWN: 'Browser Update Notification Shown',
    UPDATE_BUTTON_CLICKED: 'Browser Update Update Button Clicked',
    IGNORE_BUTTON_CLICKED: 'Browser Update Ignore Button Clicked'
  },
  PHONE_INPUT_MODAL: {
    OPENED: 'Phone Input Modal Opened',
    CLOSED: 'Phone Input Modal Closed'
  },
  SAVINGS_PAGE_DEFAULT_QUOTE_TYPE: 'auto',
  EXPERIMENT_VIEWED: 'Experiment Viewed',
  FLOW_STARTED: 'Flow Started',
  FLOW_SELECTED: 'Flow Selected',
  GOOGLE_REVIEWS_CLICKED: 'See All Google Reviews Clicked',
  PAGE_COMPLETED: 'Page Completed',
  AUTOCOMPLETE_FAILED: 'Autocomplete Failed',
  COVERAGE_OPTIONS_SELECTED: 'Coverage Options Selected',
  COVERAGE_OPTIONS_REVIEWED: 'Coverage Options Reviewed',
  QUOTE_SELECTED: 'Quote Selected',
  QUOTE_REVIEWED: 'Quote Reviewed',
  CONVERSATION_SCHEDULING_INITIATED: 'Conversation Scheduling Initiated',
  CONVERSATION_SCHEDULED: 'Conversation Scheduling Finished',
  PHONE_CONVERSATION_INITIATED: 'Phone Conversation Initiated',
  TALK_TO_AN_ADVISOR_CLICKED: 'Talk To An Advisor Clicked',
  PHONE_CHANGE_ATTEMPTED: 'Phone Change Attempt',
  FAQ_CLICKED: 'Faq Clicked',
  ONLINE_BIND_OFFERED: 'Online Bind Offered',
  EMAIL_CHANGE_ATTEMPTED: 'Email Change Attempt',
  QUOTES_LIST_VIEWED: 'Quotes List Viewed',
  TERMS_OF_SERVICE_ACCEPTED_FROM_MODAL: 'Terms Of Service Accepted From Modal',
  QUOTES_REQUESTED: 'Quotes Requested',
  SCHEDULE_CALL_TIME_SELECTED: 'Schedule A Call Time Selected',
  DISCLOSURE_ACCEPTED: 'Disclosure Accepted',
  CUSTOMER_IDENTIFIED: 'Identity Lookup Discovered',
  LOAN_NUMBER_LOCKED_POPUP: 'Loan Number Locked Popup Viewed',
  REDIRECT_FROM_LOOKUP_TO_LANDING: 'User From Lookup To Landing Redirected',
  HOMEOWNERS_INSURANCE_ANSWERED: 'Homeowners Insurance Answered',
  NO_OPTIONS_INELIGIBILITY_TYPE_REDIRECTED: 'No Options Ineligibility Type Redirected',
  PRIMARY_CTA_CLICKED: 'Primary CTA Clicked',
  HOME_QUOTES_BUTTON_CLICKED: 'Home Quotes Button Clicked',
  BUNDLE_QUOTES_BUTTON_CLICKED: 'Bundle Quotes Button Clicked',
  SKIP_TO_HOME_BUTTON_CLICKED: 'Skip To Home Quotes Button Clicked',
  TO_HOME_NAVIGATED: 'To Home Navigated',
  HOME_BUNDLE_OFFER_VIEWED: 'Home Bundle Offer Viewed',
  INACTIVE_SESSION_NEW_STARTED: 'Inactive Session New Started',
  INACTIVE_SESSION_UNLOCKED: 'Inactive Session Unlocked',
  INACTIVE_SESSION_UNSUCCESS_ATTEMPT: 'Inactive Session Unsuccessful Attempt',
  SAVINGS_BUNDLE: {
    POSITION_SUBMITTED: 'Savings Toggle Position Submitted',
    TOGGLED_CLICKED: 'Savings Toggle Clicked',
    INFO_ICON_CLICKED: 'Savings Bundle Info Icon Clicked'
  },
  AF_BUNDLE: {
    POSITION_SUBMITTED: 'All Funnel Toggle Position Submitted',
    TOGGLED_CLICKED: 'All Funnel Toggle Clicked',
    INFO_ICON_CLICKED: 'All Funnel Bundle Info Icon Clicked',
    MPD_QUOTE_SHOWED: 'Multi Policy Discount Quote Shown'
  },
  COMPARISON: {
    MODAL_OPENED: 'Comparison Modal Opened',
    MODAL_CLOSED: 'Comparison Modal Closed',
    OPTIONS_SHOWN: 'Comparison Options Shown'
  },
  QUESTIONS_SIDE_BARD_EDIT_CLICKED: 'Questions Side Bar Edit Details Clicked',
  AF_QUOTES_BUNDLE_CLICKED: 'All Funnel Quotes Bundle Toggle Clicked',
  SAVINGS_INVALID_PHONE_SUBMIT_ATTEMPTED: 'Savings Invalid Phone Submit Attempted',
  CALL_ME_NOW_SUBMITTED: 'Call Me Now Submitted',
  PERSONALIZED_QUOTE_BUTTON_CLICKED: 'Personalized Quote Button Click',
  PERSONALIZED_QUOTE_MODAL_CLOSED: 'Personalized Quote Modal Closed',
  PERSONALIZED_QUOTE_INFO_UPDATED: 'Personalized Quote Info Updated',
  PERSONALIZED_QUOTE_SHOW_QUOTES_CLICKED: 'Personalized Quote Show Quotes Clicked',
  DIGITAL_PROFILE_URL_REDIRECTED: 'Digital Profile Url Redirected',
  OTHER_QUOTES_SHOWN: 'Other Quotes Shown',
  QUOTE_RECAP: 'Quotes Shown',
  LIVE_HELP_CLICKED: 'Live Help Clicked',
  HOME_QUOTE_PAGE_VARIATION_SEEN: 'Home Quote Page Variation Seen',
  HISTORICAL_PREMIUM_DATA_TYPE_USED: 'Historical Premium Data Type Used',
  LANDING_COMPARE_QUOTES_CLICKED: 'Landing Compare Quotes Click',
  LANDING_CONTINUE_TO_CARRIER_CLICKED: 'Landing Continue To Carrier Click',
  PH_QUESTION_SHOWN: 'Policy Holder Question Shown',
  PH_QUESTION_ANSWERED: 'Policy Holder Question Answered',
  PH_INFO_SUBMITTED: 'Policy Holder Info Submitted',
  PH_IDENTIFIED: 'Policy Holder Identified',
  PH_COMPONENT_SEE_QUOTES: 'Policy Holder Component See Quotes Clicked',
  PH_COMPONENT_CONTACT_US: 'Policy Holder Component Contact Us Clicked',
  PH_COMPONENT_PHONE_LINK: 'Policy Holder Component Phone Link Clicked',
  PH_COMPONENT_SCHEDULE_A_CALL: 'Policy Holder Component Schedule A Call Clicked',
  USER_SUCCESSFULLY_COMPLETES_THE_CAPTCHA: 'User Successfully Completes The Captcha',
  USER_SAW_THE_CAPTCHA: 'User Saw The Captcha',
  MAP_API_CALLED: 'Map API Called',
  MARKER_MAP_API_CALLED: 'Marker Map API Called',
  USER_IDENTIFIED_AS_POLICYHOLDER: 'User Identified As Policyholder',
  HOME_TO_FLORIDA_REDIRECT: 'Home To Florida Redirected',
  ADDRESS_INPUT_CHANGED: 'Address Input Changed',
  MAPS_VISIBILITY_CHANGED: 'Maps Visibility Changed',
  NO_QUOTES_STOP_PAGE_REDIRECTED: 'No Quotes Stop Page Redirected',
  SIDE_BY_SIDE_COMPARISON_CLICKED: 'Side By Side Comparison Clicked',
  FEATURED_QUOTE_SHOWED: 'Featured Quote Showed',
  LIFE_SCHEMA: {
    UNLOCK_QUOTES_CLICKED: 'Unlock My Quotes Clicked',
    SELECT_TERM_WARNING_SHOWN: 'Select Term Length Warning Shown',
    VALIDATION_SHOWN: 'Schema Validation Shown',
    QUOTES_REQUESTED: 'Life Quotes Requested',
    AGE_ERROR_SHOWN: 'Age Error Shown'
  },
  KIN_INSURANCE_INTERESTED_CLICKED: 'Kin Insurance Interested Clicked',
  KIN_INSURANCE_OPTION_VIEWED: 'Kin Insurance Option Viewed',
  DISCLOSURES_MODAL_SHOWN: 'Disclosures Modal Shown',
  BUNDLE_ONLY: {
    HOME_QUOTE_SELECTED: 'Bundle Home Quote Selected',
    COVERAGES_OPENED: 'Bundle Only Coverages Opened',
    SEE_HOME_QUOTES_CLICKED: 'Bundle Only See Home Quotes Clicked',
    NO_HOME_QUOTES_PRESENTED: 'Bundle Only No Home Quotes Presented'
  }
};

export default SEGMENT;
