import { css, SerializedStyles } from '@emotion/react';
import { colors, mq, typography } from 'src/theme/variables';
import { text12, text14 } from 'src/theme/typography';
export const labelCSS = css`
  padding-bottom: 4px;
`;

export const tooltipCSS = (isLifeOriented?: boolean): SerializedStyles => css`
  background-color: ${colors.dove};
  border: none;
  border-radius: 50%;
  color: ${colors.white};
  cursor: pointer;
  display: inline-block;
  font-family: ${typography.fontFamily};
  ${text12};
  line-height: 18px;
  font-style: normal;
  font-weight: normal;
  height: 16px;
  margin-left: 6px;
  padding: 0;
  text-align: center;
  vertical-align: middle;
  width: 16px;
  position: relative;
  bottom: 3px;

  ${mq[0]} {
    ${text14};
    line-height: 24px;
    height: 24px;
    margin-left: 8px;
    width: 24px;
  }

  ${!isLifeOriented &&
  css`
    &:hover {
      background-color: ${colors.cornflower};
    }
  `}

  ${isLifeOriented &&
  css`
    background-color: transparent;
    display: inline-flex;
    align-items: center;
  `}

  img {
    margin-top: 4px;
  }
`;

export const tooltipCloseBtnCSS = css`
  margin-top: 10px;

  img {
    width: 50%;
    margin: auto;
  }
`;
