import React from 'react';

const SERVICERS = {
  MR_COOPER_NRZ: 'mr_cooper_nrz',
  MR_COOPER: 'mr_cooper',
  ROUNDPOINT: 'roundpoint',
  FREEDOM: 'freedom',
  NEW_REZ: 'newrez',
  PHH: 'phh',
  PHH_NRZ: 'phh_nrz',
  NRZ: 'nrz',
  NEW_REZ_NRZ: 'newrez_nrz',
  SONIC: 'sonic'
};

export default SERVICERS;

export const getServicerNameToDisplay = (servicerKey = '', servicerName = ''): string | React.ReactElement => {
  switch (true) {
    case [SERVICERS.MR_COOPER, SERVICERS.MR_COOPER_NRZ].includes(servicerKey):
      return (
        <>
          Mr. Cooper<sup>®</sup>
        </>
      );
    default:
      return servicerName;
  }
};
