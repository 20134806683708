import { css } from '@emotion/react';
import { text24 } from 'src/theme/typography';

export const titleCSS = css`
  margin-bottom: 24px;
  ${text24};
  font-weight: 700;
`;

export const benefitCSS = css`
  position: relative;
  padding-left: 46px;
  min-height: 30px;
  margin-bottom: 21px;
  display: flex;
  align-items: center;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const iconCSS = css`
  position: absolute;
  left: 0;
  top: 0;
  width: 30px;
  height: 30px;
`;
