import { Flows } from 'src/interfaces/IPage';
import { UseBaseMutationResult, useMutation, useQueryClient } from 'react-query';
import { Session, SessionUpdatePayload } from 'src/interfaces/session.types';
import { ResponseError } from 'src/interfaces/IResponse';
import { QUERY_CACHE_KEYS } from 'src/constants/queryCacheKeys';
import api from 'src/api/api';
import { API_URLS } from 'src/constants/api';
import { getApiUrl } from 'src/utils/api.helpers';

const useMutateSession = (
  flow: Flows,
  gid: string,
  refetchQueries: string[][] = []
): UseBaseMutationResult<Partial<Session>, ResponseError, SessionUpdatePayload> => {
  const client = useQueryClient();

  return useMutation(
    (data: SessionUpdatePayload): Promise<Session> =>
      api.put(getApiUrl(API_URLS['SESSION_FLOW_GID'], flow, gid), {
        body: data
      }),
    {
      onSuccess: async (data: Partial<Session>) => {
        await Promise.all([QUERY_CACHE_KEYS.QUESTIONS, ...refetchQueries].map(key => client.refetchQueries(key)));
        client.setQueryData([QUERY_CACHE_KEYS.SESSION, flow, gid], data);
      }
    }
  );
};

export default useMutateSession;
