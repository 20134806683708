import { css } from '@emotion/react';
import { mq } from 'src/theme/variables';

export const buttonWrapperCSS = css`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 24px;
  position: relative;
`;

export const coverageCSS = css`
  width: 100%;
  margin-top: 12px;

  ${mq[0]} {
    width: auto;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(50% - 33px);
    margin-top: 16px;
  }
`;

export const coveragesContainerCSS = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
