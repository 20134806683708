import { SerializedStyles, css } from '@emotion/react';

import { borderRadius, colors, mq, onlyMobile } from 'src/theme/variables';
import { formInputFocusOutline } from 'src/theme/shared-styles';

export const buttonCSS = css`
  background-color: ${colors.white};
  border: 1px solid ${colors.black};
  border-radius: ${borderRadius}px;
  box-sizing: border-box;
  content: '';
  display: block;
  height: var(--height);
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;

  &::before {
    ${formInputFocusOutline};
  }
`;

export const buttonDotCSS = (hasError?: boolean): SerializedStyles => css`
  border: 1px solid ${colors.black};
  border-radius: 50%;
  box-sizing: border-box;
  content: '';
  display: inline-block;
  height: var(--radio-dimension);
  position: absolute;
  width: var(--radio-dimension);
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  left: var(--radio-dot-left);

  ${hasError &&
  css`
    border-color: ${colors.red};
  `}

  &::before {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid transparent;
    border-radius: 50%;
  }
`;

export const radioButtonCSS = (
  isChecked: boolean,
  isLifeFlowVariant?: boolean,
  shouldHighlightError?: boolean
): SerializedStyles => css`
  align-items: center;
  display: flex;
  height: var(--height);
  padding-left: 20px;
  padding-right: 20px;
  position: relative;

  --height: 64px;
  --radio-dimension: 20px;
  --radio-dot-left: 16px;

  ${shouldHighlightError &&
  css`
    &::before {
      border: 2px solid ${colors.red};
    }
  `}

  + .radio-button-label {
    margin-top: 8px;
  }

  ${isLifeFlowVariant &&
  css`
    ${onlyMobile} {
      --height: 34px;
      --radio-dot-left: 0px;
    }

    padding: 0;
    padding-left: 8px;

    .radio-button {
      border: none;

      &::before {
        border: none;
      }
    }
  `}

  &:hover {
    cursor: pointer;

    .radio-button {
      border-width: ${isChecked ? '2' : '1'}px;
      border-color: ${colors.azure62};
    }

    .radio-button-dot {
      left: calc(var(--radio-dot-left) - 1px);
      border-color: ${colors.black};

      &::before {
        border-color: ${colors.cornflower};
      }
    }
  }
`;

export const imageCSS = css`
  display: none;
  flex: 0 0 auto;
  opacity: 0.5;
  position: relative;
  transition: opacity 0.2s ease;
  z-index: 1;

  ${mq[0]} {
    display: flex;
  }
`;

export const labelCSS = (isLifeFlowVariant?: boolean): SerializedStyles => css`
  ${onlyMobile} {
    ${isLifeFlowVariant &&
    css`
      padding-left: 10px;
    `}
  }

  flex: 1 1 auto;
  padding-left: 30px;
  position: relative;
  z-index: 1;
`;

export const inputCSS = (hasError?: boolean, isLife?: boolean): SerializedStyles => css`
  height: 0;
  outline: none;
  position: absolute;
  width: 0;

  ${hasError &&
  !isLife &&
  css`
    + .radio-button {
      border: 2px solid ${colors.red};
    }
  `}

  &:focus,
  &:focus:checked {
    + .radio-button {
      border-width: 1px;
      border-color: ${colors.black};

      &::before {
        border-color: ${colors.azure};
      }

      .radio-button-dot {
        left: var(--radio-dot-left);
      }
    }
  }

  &:checked {
    + .radio-button {
      border-width: 2px;
      border-color: ${colors.azure};

      .radio-button-dot {
        left: calc(var(--radio-dot-left) - 1px);
        &::before {
          background-color: ${colors.azure};
          border-color: ${colors.azure};
        }
      }
    }
  }
`;

export const radioWrapperCSS = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
