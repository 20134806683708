export const borderWidth = 4;
export const borderRadius = 4;
export const boxShadow = {
  container: '2px 4px 0px 0px #0000001F',
  containerHovered: '4px 8px 0px 0px #0000001F'
};

export const colors = {
  black: '#000000',
  white: '#FFFFFF',
  azure: '#156EEA',
  azure62: '#408DF7',
  cornflower: '#66A4F7',
  cornflowerLight: '#D9E9FF',
  lightBlue: '#B3D2FF',
  blue5: '#E8F1FF',
  blue50: '#428CF7',
  denim: '#1362D1',
  darkBlue: '#5064AA',
  violet: '#5920CE',
  mercury: '#E6E6E6',
  grayFive: '#F2F2F2',
  dove: '#666666',
  silver: '#B2B2B2',
  warmGray: '#726969',
  warmGray100: '#4D4C4B',
  concrete: '#F3F1F1',
  cherise: '#E41B4A',
  cheriseDark: '#B3002A',
  cheriseLight: '#EA4D72',
  peachpink: '#ffa7a7',
  orange: '#FA7032',
  mineshaft: '#333333',
  nobel: '#B3B3B3',
  green: '#3A9A0A',
  darkGreen: '#2F8802',
  red: '#C80000',
  yellow: '#F9E14B',
  tundora: '#444444',
  tradewind: '#59BAAF',
  mintTulip: '#BBF0EA',
  aquamarineBlue: '#6EE4D6',
  swissCoffee: '#E0DDDc',
  steelBlue: '#4076b2',
  christi: '#32B45E',
  springWood: '#FCFAF9',
  lightSeaGreen: '#11A694',
  zambezi: '#585858',
  blueFife: '#EFF6FF',
  cornflowerBlue: '#5099FF',
  prussian: '#0A3066',
  denimDark: '#135BBF',
  powderBlue: '#E1F1FF',
  paleBlue: '#E8F1FD',
  babyBlue: '#E5F1FF',
  lemonChiffon: '#FFF8CC',
  honeydew: '#E8F8E8',
  blush: '#FFECEC',
  sunsetOrange: '#FC951D',
  peachCream: '#FFF1E0'
};

export const actionColors = {
  primary: colors.azure,
  active: colors.denim,
  hover: colors.cornflower,
  visited: colors.violet
};

// TODO Move to typography
export const typography = {
  fontFamily: 'freight-sans-pro',
  fontSize: '16px',
  fontSizeLarge: '20px',
  lineHeight: 1.25,
  lineHeightLarge: 1.4
};
export const sizes = {
  containerMaxWidth: 1280,
  paddingBodyHorizontalMobile: 24,
  paddingBodyHorizontalMobileDense: 16,
  paddingBodyHorizontalTablet: 32,
  inputPaddingHorizontal: 20,
  controlHeight: 60
};

export const header = {
  headerHeightDesktop: 78,
  headerHeightMobile: 60,
  headerButtonBorderWidth: borderWidth,
  demoFlowPadding: 34
};

export const navigationWidthDesktop = 350 - sizes.paddingBodyHorizontalTablet;

export const questionsForm = {
  formHolderBottomSpaceMobile: 100 / 2,
  formHolderBottomSpaceDesktop: 100
};

export const breakpoints = [750, 1050, 1200];

export const mq = breakpoints.map(bp => `@media only screen and (min-width: ${bp}px)`);
export const onlyMobile = '@media only screen and (max-width: 750px)';

export const indicators = {
  sizeDesktop: 16,
  sizeMobile: 8
};

export const labelWidth = 227;

export const confettiHeight = [66, 78, 98];
