import { QueryObserverResult, useQuery } from 'react-query';
import sessionService from './session.service';
import { Flows } from 'src/interfaces/IPage';
import { Session } from 'src/interfaces/session.types';
import { QUERY_CACHE_KEYS } from 'src/constants/queryCacheKeys';

const useSession = (flow: Flows, gid?: string): QueryObserverResult<Session | null> => {
  return useQuery([QUERY_CACHE_KEYS.SESSION, flow, gid], () => {
    if (!gid) {
      return null;
    }
    return sessionService.getSession(flow, gid);
  });
};

export default useSession;
