/** @jsxImportSource @emotion/react */
import React from 'react';

import {
  Accordion as AccordionComponent,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion';

import { itemCSS, buttonCSS, arrowCSS, panelCSS, titleCSS } from './Accordion.style';
import { AccordionProps } from './Accordion.types';

const Accordion: React.FC<AccordionProps> = ({ customCSS, items, onChange }) => {
  const onAccordionChange = ([ordinal]: string[]) =>
    items[+ordinal - 1] && onChange && onChange(items[+ordinal - 1], +ordinal);

  return (
    <AccordionComponent allowZeroExpanded css={customCSS} onChange={onAccordionChange}>
      {items.map(({ key, title, description }) => (
        <AccordionItem key={key} uuid={key} css={itemCSS}>
          <AccordionItemHeading>
            <AccordionItemButton css={buttonCSS}>
              <span css={titleCSS}>{title}</span>
              <div css={arrowCSS} />
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel css={panelCSS}>{description}</AccordionItemPanel>
        </AccordionItem>
      ))}
    </AccordionComponent>
  );
};

export default Accordion;
