/** @jsxImportSource @emotion/react */
import React, { Fragment } from 'react';

import {
  actionButtonCSS,
  closeButtonCSS,
  cardCSS,
  cardHeadingCSS,
  containerCSS,
  priceCSS,
  premiumCSS,
  premiumPeriodCSS
} from './QuoteDetailsDialog.style';
import Button from 'src/kit/Button/Button';
import QuoteLogo from 'src/components/Quotes/QuoteCard/components/QuoteLogo/QuoteLogo';
import { QuoteDetailsPageProps } from './QuoteDetailsDialog.types';
import noop from 'src/utils/noop';
import { Dialog } from '@mui/material';
import CloseIcon from 'src/assets/svg/close.svg?react';

const QuoteDetailsDialog: React.FC<QuoteDetailsPageProps> = ({
  customCSS,
  logo = { url: '', label: '' },
  price,
  customHeading,
  contentComponent,
  actionLabel = 'Choose Quote',
  onClose = noop,
  onActionClick = noop,
  isOpen = false,
  shouldHideMblChoose = false
}) => {
  const renderDefaultHeading = () => {
    return (
      <Fragment>
        <QuoteLogo logoUrl={logo.url} logoLabel={logo.label} />
        {price && (
          <div css={priceCSS}>
            <div css={premiumCSS}>{price.value}</div>
            <div css={premiumPeriodCSS}>{price.label}</div>
          </div>
        )}
      </Fragment>
    );
  };

  return (
    <Dialog open={isOpen}>
      <div css={[containerCSS, customCSS]}>
        <Button css={closeButtonCSS} variant="text" onClick={onClose}>
          <CloseIcon />
        </Button>

        <div css={cardCSS}>
          <div css={cardHeadingCSS}>{customHeading ? customHeading : renderDefaultHeading()}</div>
          {contentComponent}
          {!shouldHideMblChoose && (
            <Button css={actionButtonCSS} onClick={onActionClick}>
              {actionLabel}
            </Button>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default QuoteDetailsDialog;
