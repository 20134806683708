/** @jsxImportSource @emotion/react */

import React from 'react';
import { demoFlowIndicatorCSS, demoFlowUpdatedGlobalStylesCSS } from './DemoFlowIndicator.style';
import { Global } from '@emotion/react';

const DemoFlowIndicator: React.FC = () => {
  return (
    <>
      <Global styles={demoFlowUpdatedGlobalStylesCSS} />
      <div css={demoFlowIndicatorCSS}>Shop 40+ A-rated carriers.</div>
    </>
  );
};

export default DemoFlowIndicator;
