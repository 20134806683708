import { css, SerializedStyles } from '@emotion/react';
import { borderRadius, colors } from 'src/theme/variables';

export const checkboxIconHovered = css`
  background-color: ${colors.cornflower};
`;

export const container = (checked: boolean, disabled: boolean): SerializedStyles => css`
  position: relative;
  display: inline-block;
  width: 18px;
  height: 18px;
  background-color: ${checked ? colors.azure : colors.white};
  border: 1px solid ${checked ? colors.azure : colors.black};
  border-radius: ${borderRadius}px;
  &:hover {
    ${!disabled && checkboxIconHovered};
  }
`;

export const icon = (checked: boolean): SerializedStyles => css`
  display: ${checked ? 'block' : 'none'};
  padding: 4px;
  color: ${colors.white};
  width: 10px;
  height: 10px;
`;
