/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { Link, useParams } from 'react-router-dom';
import format from 'src/utils/format';
import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';
import useConfig from 'src/api/config/useConfig';
import { DefaultLocation } from 'src/interfaces/IPage';
import useSession from 'src/api/session/useSession';

import {
  agent,
  agentCircle,
  agentPhoto,
  button,
  circleIcon as circleIconCSS,
  contacts,
  container,
  curveIcon as curveIconCSS,
  curveIconDesktop,
  footer,
  header,
  heading as headingCSS,
  linesIcon as linesIconCSS,
  mailIcon as mailIconCSS,
  message as messageCSS,
  phone as phoneCSS,
  quotesBannerAnimationOffsets,
  workingHours
} from './QuotesBanner.style';
import Anchor from 'src/kit/Anchor/Anchor';
import mailIcon from 'src/assets/svg/mail-banner.svg';
import linesIcon from 'src/assets/svg/quotes-banner-lines.svg';
import curveIcon from 'src/assets/svg/quotes-banner-curve.svg';
import circleIcon from 'src/assets/svg/quotes-banner-circle.svg';
import { QuotesBannerProps } from './QuotesBanner.types';
import { DEFAULT_PHONE_NUMBER } from 'src/constants/contacts';
import userEventDataService, { UserEvents } from 'src/api/userEventsData/userEventsData';

const getQuotesBannerCopy = (hasNoQuotes: boolean, currentQuoteLength: number) => {
  if (hasNoQuotes || currentQuoteLength < 1) {
    return {
      heading: 'Looks like we need additional information',
      message: 'No worries. A licensed Matic Advisor can put together a custom policy to fit your needs.'
    };
  }

  return {
    heading: 'Don`t see a quote that works for you?',
    message: 'No worries. A licensed Matic Advisor can put together a custom policy to fit your needs.'
  };
};

const getAnimationConfig = (trigger: string, y: number) => ({
  y: y * 1.2,
  scrollTrigger: {
    trigger,
    start: 'top bottom',
    end: 'bottom top',
    scrub: 1
  }
});

const QuotesBanner: React.FC<QuotesBannerProps> = ({
  customCSS,
  hasNoQuotes,
  currentQuoteLength = 0,
  phone = DEFAULT_PHONE_NUMBER
}) => {
  const { heading, message } = getQuotesBannerCopy(hasNoQuotes, currentQuoteLength);
  const { flow, gid } = useParams() as DefaultLocation;
  const { data: config } = useConfig(gid);
  const { data: session } = useSession(flow, gid);
  const agentPhone = config?.partner.agent_phone || phone;

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.to('#qb-filled-circle', getAnimationConfig('#qb-filled-circle', -quotesBannerAnimationOffsets.filledCircle));
    gsap.to('#qb-curve-desktop', getAnimationConfig('#qb-curve-desktop', -quotesBannerAnimationOffsets.curveDesktop));
    gsap.to('#qb-curve', getAnimationConfig('#qb-curve', -quotesBannerAnimationOffsets.curve));
    gsap.to('#qb-lines', getAnimationConfig('#qb-lines', -quotesBannerAnimationOffsets.lines));
  }, []);

  const onScheduleChatClicked = async () => {
    analytics.track(SEGMENT.CONVERSATION_SCHEDULING_INITIATED, gid, session?.flow, {
      page: SEGMENT.PAGES_KEY.QUOTES
    });

    try {
      await userEventDataService.callUserEventReport(UserEvents.ScheduleCall, gid);
    } catch {}
  };

  const onPhoneClick = () =>
    analytics.track(SEGMENT.PHONE_CONVERSATION_INITIATED, gid, session?.flow, {
      location: SEGMENT.LOCATIONS.QUOTES_BANNER,
      page: SEGMENT.PAGES_KEY.QUOTES
    });

  return (
    <div css={[customCSS, container]}>
      <img id="qb-lines" css={linesIconCSS} src={linesIcon} alt="Line" />

      <div css={header}>
        <div css={headingCSS}>{heading}</div>
        {message && <div css={messageCSS}>{message}</div>}
      </div>

      <div css={agent}>
        <div id="qb-filled-circle" css={agentCircle} />
        <img
          css={agentPhoto}
          src="https://maticinsurance.sirv.com/customer-experience/agent_lady.png"
          alt="Agent lady"
        />
        <img id="qb-curve-desktop" css={curveIconDesktop} src={curveIcon} alt="Curve" />
      </div>

      <div css={footer}>
        <Link css={button} to={`/${flow}/${gid}/confirmation`} onClick={onScheduleChatClicked}>
          Schedule a Chat
        </Link>

        <div css={contacts}>
          <Anchor
            css={phoneCSS}
            anchorType="single-color"
            href={`tel:${agentPhone}`}
            onClick={onPhoneClick}
            data-testid="phone"
          >
            {format.phone('' + agentPhone)}
          </Anchor>
          <div css={workingHours} data-testid="working-hours">
            Working hours: 9AM - 8PM ET
          </div>
        </div>

        <img id="qb-curve" css={curveIconCSS} src={curveIcon} alt="Curve" />
      </div>

      <img css={mailIconCSS} src={mailIcon} alt="Mail" />
      <img css={circleIconCSS} src={circleIcon} alt="Circle" />
    </div>
  );
};

export default QuotesBanner;
