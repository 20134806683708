import { useEffect, useState, useRef, useMemo } from 'react';

const MOBILE = 'MOBILE';
const TABLET = 'TABLET';
const DESKTOP = 'DESKTOP';

const MOBILE_EDGE = 750;
const TABLET_EDGE = 1050;
const DESKTOP_EDGE = 1051;

const defaultThresholds = {
  [MOBILE]: MOBILE_EDGE,
  [TABLET]: TABLET_EDGE,
  [DESKTOP]: DESKTOP_EDGE
};

const getBreakpoint = (breakpointThresholds: Record<string, number>): string =>
  Object.keys(breakpointThresholds).find(breakpointName => window.innerWidth < breakpointThresholds[breakpointName]) ||
  DESKTOP;

type Breakpoints = {
  breakpoint: string;
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
};

/**
 * You can pass custom Breakpoint Threshold to override default one.
 *
 * **Note:** value should be in growing order.
 *
 * **Example:** `{ mobile: 500, tablet: 1000, desktop: 2000 }`
 */
const useResponsive = (breakpointThresholds = defaultThresholds): Breakpoints => {
  const [breakpoint, setBreakpoint] = useState(getBreakpoint(breakpointThresholds));
  const savedBreakpoint = useRef(getBreakpoint(breakpointThresholds));

  useEffect(() => {
    const onResize = () => {
      const newBreakpoint = getBreakpoint(breakpointThresholds);

      if (savedBreakpoint.current !== newBreakpoint) {
        setBreakpoint(newBreakpoint);
        savedBreakpoint.current = newBreakpoint;
      }
    };

    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, [breakpointThresholds]);

  return useMemo(
    () => ({
      breakpoint: breakpoint,
      isMobile: MOBILE === breakpoint,
      isTablet: TABLET === breakpoint,
      isDesktop: DESKTOP === breakpoint
    }),
    [breakpoint]
  );
};

export default useResponsive;
